var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'table-outer-wrapper',
    _vm.hasControlSection && 'top-edge-straight'
  ],on:{"mouseleave":_vm.handleTableMouseLeave}},[_c('div',{ref:"right-table-shadow",class:['table-shadow', 'right'],style:({
      boxShadow: _vm.hasRightTableShadow ? ("inset -15px 0 14px -16px " + (this.$arStyle.color.blueGrey700)) : null,
    })}),_vm._v(" "),_c('div',{ref:"left-table-shadow",class:['table-shadow', 'left'],style:({
      left: _vm.showTicks ? '48px' : 0,
      borderTopLeftRadius: _vm.showTicks ? null : '5px',
      boxShadow: _vm.hasLeftTableShadow ? ("inset 15px 0 14px -16px " + (this.$arStyle.color.blueGrey700)) : null,
    })}),_vm._v(" "),(_vm.isNoRows)?_c('div',{staticClass:"no-content"},[_c('ar-text',{style:({
        color: _vm.$arStyle.color.blueGrey600,
      }),attrs:{"size":"xs","text":_vm.emptyText,"align":"center"}})],1):_vm._e(),_vm._v(" "),_c('div',{ref:"table-container",staticClass:"table-container",style:({
      borderBottom: _vm.loading ? 'none' : null,
    })},[_c('div',{directives:[{name:"ar-sticky-top",rawName:"v-ar-sticky-top",value:({
        disabled: !_vm.hasStickyHeader,
        priority: 1,
      }),expression:"{\n        disabled: !hasStickyHeader,\n        priority: 1,\n      }"}],class:[
        'table-header-outer-container',
        _vm.hasControlSection && 'top-edge-straight'
      ],style:({
        width: '100%',
        transition: 'top 0.2s',
      })},[(_vm.showTicks)?_c('div',{class:[
          "table-header-container",
          "checkboxes-container" ],style:({
          width: "50px",
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: _vm.$arStyle.zIndex.highest,
        })},[_c('thead',{ref:"theadCheckboxes",staticClass:"thead",style:({
            width: "39px",
          })},[_c('div',{staticClass:"tr"},[_c('div',{staticClass:"th",style:({
                display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                width: (_vm.tickColumnWidth + "px"),
                cursor: _vm.locked ? 'not-allowed' : 'pointer',
              }),attrs:{"data-test-id":"table-header-checkbox-th"},on:{"click":_vm.handleTickAllCheckboxClick}},[_c('ar-checkbox',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isNoRows),expression:"!isNoRows"}],ref:"tick-all-checkbox",style:({
                  pointerEvents: 'none',
                }),attrs:{"data-test-id":"table-header-checkbox","value":_vm.tickAllRows}})],1)])])]):_vm._e(),_vm._v(" "),_c('div',{ref:"table-header-container",class:[
          "table-header-container",
          _vm.hasControlSection && "spacious"
        ],style:({
          width: '100%',
        })},[_c('thead',{ref:"thead",staticClass:"thead",style:({
            minWidth: (_vm.minTableWidth + "px"),
          })},[_c('div',{staticClass:"tr"},[(_vm.showTicks)?_c('div',{staticClass:"th",style:({
                display: 'flex', alignItems: 'center', justifyContent: 'center',
                width: (_vm.tickColumnWidth + "px"),
                cursor: _vm.locked ? 'not-allowed' : 'pointer',
              })}):_vm._e(),_vm._v(" "),_vm._l((_vm.heads),function(head,idx){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                content: head.lock && head.lockReason ? head.lockReason : null,
              }),expression:"{\n                content: head.lock && head.lockReason ? head.lockReason : null,\n              }",modifiers:{"top":true}}],key:head.key,staticClass:"th",style:({
                cursor: _vm.resizingColumn !== null ? 'col-resize' : head.sortKey ? 'pointer' : null,
                width: ((_vm.headWidths[idx] || 150) + "px"),
                justifyContent: head.align === 'left' ? 'flex-start' : 'flex-end',
                padding: _vm.customHeadPadding ? _vm.customHeadPadding : null,
              }),on:{"click":function (event) { return _vm.handleSortClick(head.sortKey, event); },"mouseenter":function($event){return _vm.handleCellMouseEnter(head)},"mouseleave":function($event){return _vm.handleCellMouseLeave(head)}}},[((_vm.showTicks || idx > 0) && _vm.sortBy.key === head.sortKey && !!head.sortKey)?_c('ar-icon',{staticClass:"arrow",style:({
                  marginLeft: "-16px",
                  marginRight: "7px"
                }),attrs:{"name":"arrow","color":_vm.$arStyle.color.blueGrey700,"rotate":_vm.sortBy.order === 'asc' ? 180 : 0}}):_vm._e(),_vm._v(" "),_c('ar-text',{style:({
                  paddingRight: head.align === 'right' && head.lock ? '10px' : null,
                }),attrs:{"size":"xs","text":head.name,"weight":"bold"}}),_vm._v(" "),(!_vm.showTicks && _vm.sortBy.key === head.sortKey && !!head.sortKey && idx == 0)?_c('ar-icon',{staticClass:"arrow",style:({
                  marginLeft: "7px",
                  marginRight: "0px"
                }),attrs:{"name":"arrow","color":_vm.$arStyle.color.blueGrey700,"rotate":_vm.sortBy.order === 'asc' ? 180 : 0}}):_vm._e(),_vm._v(" "),(head.lock)?_c('ar-icon',{staticClass:"lock",attrs:{"name":"lock","color":_vm.$arStyle.color.blueGrey600,"height":"14px"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"table-header-edge",style:({
                  backgroundColor: _vm.resizingXPosition !== null && idx === _vm.resizingColumn ? _vm.$arStyle.color.blueGrey800 : null,
                  right: _vm.resizingXPosition !== null && idx === _vm.resizingColumn ? (_vm.resizingXPosition + "px") : null,
                })})],1)})],2)])])]),_vm._v(" "),_c('div',{on:{"mouseleave":function($event){return _vm.handleRowMouseLeave()}}},[(_vm.showTicks)?_c('div',{staticClass:"table-body-container checkboxes-container",style:({
          width: '49px',
          position: 'absolute',
          left: '0',
          top: '52px',
          overflow: 'visible',
          zIndex: _vm.$arStyle.zIndex.regular,
          marginLeft: '2px',
        })},[_c('div',{class:['tbody', _vm.locked && 'locked']},[_c('div',{staticClass:"tr-container",style:({
              paddingTop: ((_vm.topHiddenRowsCount * _vm.rowHeight) + "px"),
              paddingBottom: ((_vm.bottomHiddenRowsCount * _vm.rowHeight) + "px")
            })},_vm._l((_vm.visibleRows),function(row,rowIndex){return _c('div',{key:rowIndex,staticClass:"tr-wrapper"},[_c('div',{class:['tr', row.class],style:({
                  cursor: _vm.enableRowClick ? 'pointer' : null,
                  background: _vm.checkRowActivity(row, rowIndex) ? _vm.$arStyle.color.purple100 : null,
                }),on:{"mouseenter":function($event){return _vm.handleRowMouseEnter(rowIndex)}}},[_c('div',{staticClass:"td",style:({
                    minHeight: (_vm.rowHeight + "px"),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: (_vm.tickColumnWidth + "px"),
                    cursor: 'pointer',
                    zIndex: _vm.$arStyle.zIndex.regular,
                  }),on:{"click":function($event){$event.stopPropagation();return _vm.handleRowTick(row)}}},[_c('ar-checkbox',{ref:"checkbox",refInFor:true,style:({ pointerEvents: 'none' }),attrs:{"value":_vm.tickedRowsMap[row[_vm.rowKeyName]]}})],1)])])}),0)])]):_vm._e(),_vm._v(" "),_c('div',{ref:"table-body-container",class:[
        "table-body-container",
        _vm.hasControlSection && "spacious" ],style:({
          width: '100%',
        })},[_c('div',{ref:"tbody",class:['tbody', _vm.locked && 'locked'],style:({ minWidth: (_vm.minTableWidth + "px") })},[_c('div',{staticClass:"gradient-mask"}),_vm._v(" "),(!_vm.loading && _vm.isNoRows)?_c('div',{staticClass:"tr-container",style:({ minHeight: '50px' })},[_c('div',{staticClass:"tr-wrapper"},[_c('div',{staticClass:"tr",style:({ minHeight: '50px' })})])]):_vm._e(),_vm._v(" "),(!_vm.isNoRows)?_c('div',{staticClass:"tr-container",style:({
              paddingTop: ((_vm.topHiddenRowsCount * _vm.rowHeight) + "px"),
              paddingBottom: ((_vm.bottomHiddenRowsCount * _vm.rowHeight) + "px")
            })},_vm._l((_vm.visibleRows),function(row,rowIndex){return _c('div',{key:rowIndex,staticClass:"tr-wrapper"},[_c('div',{class:['tr', row.class],style:({
                  cursor: _vm.enableRowClick ? 'pointer' : null,
                  background: _vm.checkRowActivity(row, rowIndex) ? _vm.$arStyle.color.purple100 : null,
                }),on:{"mouseenter":function($event){return _vm.handleRowMouseEnter(rowIndex)},"click":function($event){return _vm.handleRowClick(row)}}},[(_vm.showTicks)?_c('div',{staticClass:"td",style:({ width: (_vm.tickColumnWidth + "px"), minHeight: (_vm.rowHeight + "px") })}):_vm._e(),_vm._v(" "),_vm._l((_vm.heads),function(head,idx){return _c('div',{key:head.key,class:[
                    'td',
                    head.lock && 'blur'
                  ],style:({
                    width: ((_vm.headWidths[idx] || 150) + "px"),
                    minHeight: (_vm.rowHeight + "px"),
                  }),on:{"mouseenter":function($event){return _vm.handleCellMouseEnter(head)},"mouseleave":function($event){return _vm.handleCellMouseLeave(head)}}},[_vm._t(head.format,null,{"data":row,"index":_vm.getRowIndex(row),"head":head,"active":_vm.activeRowIndex === rowIndex})],2)})],2)])}),0):_vm._e(),_vm._v(" "),(_vm.loading)?_c('div',_vm._l((12),function(n){return _c('div',{key:n,staticClass:"tr"},[(_vm.showTicks)?_c('div',{staticClass:"td",style:({
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: (_vm.tickColumnWidth + "px"),
                  minHeight: (_vm.rowHeight + "px"),
                  marginLeft: '1px',
                })},[_c('am2-typography-skeleton',{style:({ width: '20px' }),attrs:{"size":"md","level":n}})],1):_vm._e(),_vm._v(" "),_vm._l((_vm.heads),function(head,idx){return _c('div',{key:head.key,staticClass:"td",style:({
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: head.align === 'left' ? 'flex-start' : 'flex-end',
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  width: ((_vm.headWidths[idx] || 150) + "px"),
                  minHeight: (_vm.rowHeight + "px"),
                })},[_c('am2-typography-skeleton',{style:({ width: "45%", height: '12px', }),attrs:{"size":"md","level":n}})],1)})],2)}),0):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }