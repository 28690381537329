<template>
  <div 
    class="ticket-sales-heading-wrapper"
    :class="[!hasData ? 'disabled' : '']"
    v-click-outside="handleHeadingClickOutside"
    tabindex="0" 
    @keydown.esc="handleHeadingClickOutside"
  >
    <div class="ticket-sales-heading-button" @click="toggleDropdown" data-value="sales-chart-heading-button">
      <ar-text
        class="compare-events-text"
        size="md"
        :text="selectedHeading.name"
        multiple-lines
        :max-lines="1"
        line-height="20px"
      />
      <ar-icon
        width="10px"
        name="arrow"
        class="icon-arrow"
        :rotate="headingOptionsOpen ? 180 : 0"
      />
    </div>

    <div v-if="headingOptionsOpen" class="heading-options-wrapper">
      <div class="triangle">
        <div class="triangle-outer"></div>
        <div class="triangle-inner"></div>
      </div>
      <template v-for="(heading, index) in options">
        <ar-text
          :key="index"
          class="heading-option"
          :class="[isSelectedHeading(heading.name) ? 'active' : '']"
          size="xs"
          :text="heading.name"
          multiple-lines
          :max-lines="1"
          line-height="20px"
          :weight="isSelectedHeading(heading.name) ? 'bold' : 'normal'"
          @click.native="handleHeadingSelect(heading)"
          :data-test-id="heading.value"
        />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SalesChartHeadingDropdown',
  props: {
    hasData: {
      type: Boolean,
      default: false,
    },
    selectedHeading: {
      type: Object,
      default: null
    },
    options: {
      type: Array,
      default: null,
    },
  },
  model: {
    prop: 'selectedHeading',
    event: 'select',
  },
  data() {
    return {
      headingOptionsOpen: false,
    }
  },
  methods: {
    isSelectedHeading(name) {
      return this.selectedHeading.name === name ? true : false
    },

    toggleDropdown() {
      this.headingOptionsOpen = !this.headingOptionsOpen
    },

    handleHeadingClickOutside() {
      if (this.headingOptionsOpen) {
        this.headingOptionsOpen = false
      }
    },

    handleHeadingSelect(heading) {
      this.$emit('select', heading)
      this.headingOptionsOpen = false
    }
  }
}
</script>
<style lang="scss" scoped>

$borderColor: $blueGrey500;
$disabledColor: $blueGrey700;

@mixin flexRow {
  display: flex;
  flex-flow: row nowrap;
}

@mixin dropdownOptionsStyle {
  background: white;
  border: 1px solid $borderColor;
  border-radius: 5px; 
  box-shadow: 0px 1px 11px -2px rgba(0, 0, 0, 0.2);
}

.ticket-sales-heading-wrapper {
  padding: 8px 10px;
  cursor: pointer;
  position: relative;
  outline: none;

  &.disabled {
    pointer-events: none;

    .ticket-sales-heading-button {
      .compare-events-text {
        color: $disabledColor;
      }

      .icon-arrow {
        margin-top: 4px;
        color: $disabledColor;
      } 
    }
  }

  .ticket-sales-heading-button {
    @include flexRow;
    align-items: center;
    justify-content: flex-start;

    .compare-events-text {
      margin-right: 8px;
    }

    .icon-arrow {
      margin-top: 4px;
    }
  }

  .heading-options-wrapper {
    @include dropdownOptionsStyle;
    padding: 6px 0;
    position: absolute;
    top: 42px;
    left: 148px;
    width: 196px;
    z-index: 20;

    .triangle {
      position: absolute;
      width: 10px;
      top: -5px;
      left: 25px;

      .triangle-outer {
        position: absolute;
        top: 0;
        left: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #d8dde3;
      }

      .triangle-inner {
        position: absolute;
        top: 1px;
        left: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid white;
        transform: translateY(10%);
      }
    }

    .heading-option {
      padding: 10px 16px;

      &:hover {
        background: $purple100;
        color: $purple500;
      }

      &.active {
        background: $purple100;
        color: $purple500;
      }
    }
  }
}
</style>