var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.fetchMoreStaffAccounts),expression:"fetchMoreStaffAccounts"}],staticClass:"ar-arep-accounts-page",attrs:{"infinite-scroll-disabled":false,"infinite-scroll-distance":10}},[_c('div',{staticClass:"accounts-content"},[_c('am2-top',{staticClass:"top-wrapper",attrs:{"button":_vm.topButton,"search":{
            action: _vm.serverSideSearch,
            placeholder: 'Search accounts',
            value: _vm.searchString,
            style: {
              width: '330px'
            }
          },"title":"Audience Republic Accounts"}}),_vm._v(" "),_c('ar-divider',{style:({
        marginTop: '0',
        marginBottom: '22px',
      })}),_vm._v(" "),_c('ar-checkbox',{attrs:{"label":"Filter by Audience Republic Email Address","value":_vm.onlyAREmailAddress},on:{"input":_vm.handleIsStaffAccountToggle}}),_vm._v(" "),_vm._l((_vm.accounts),function(account){return _c('am2-card-container',{key:account.oid,staticClass:"account-card",style:({ margin: '20px 0' })},[_c('div',{staticClass:"title-section"},[_c('am2-heading',{attrs:{"type":"h2","size":"sm","title":((account.firstName) + " " + (account.lastName) + " (" + (account.emailAddress) + ")")}}),_vm._v(" "),_c('p',{staticClass:"time-text"},[_vm._v("\n          "+_vm._s(_vm.generateTimeInformation(account.sysCtime, account.promoterAccount))+"\n        ")])],1),_vm._v(" "),_c('am2-icon-button-dropdown',{attrs:{"icon-props":{
              name: 'menu',
            },"items":[
              {
                name: 'Modify',
                key: 'modify',
              },
              {
                name: 'Delete',
                key: 'delete',
              } ]},on:{"select":function (item) { return _vm.handleStaffAccountActionSelect(account, item); }}})],1)}),_vm._v(" "),(_vm.isFetchingAccounts)?_c('am2-loading-section',{staticClass:"loading-section"}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }