var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-list-advanced-targeting-section"},[(_vm.showToggle)?_c('div',{staticClass:"toggle-module u-margin-top-3"},[_c('ar-link-button',{attrs:{"text":_vm.toggleText,"has-underline":"","data-test-id":"message-list-targeting-toggle"},on:{"click":_vm.handleToggle}})],1):_vm._e(),_vm._v(" "),(_vm.showToggle && _vm.toggle)?_c('div',{staticClass:"targeting-module u-padding-6 u-margin-top-5"},[_c('ar-field',{style:({}),attrs:{"label":"Filter"}},[_c('ar-simple-select',{staticClass:"select-filter-type",attrs:{"items":_vm.filterTypeItems,"default-select-index":_vm.selectedTypeIndex,"data-test-id":"message-targeting-type-select"},on:{"select":_vm.handleTypeSelect}})],1),_vm._v(" "),(_vm.displayedSubsection === 'tag')?_c('div',{class:['subsection',
             'tag-subsection',
             _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-min',
             _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']},[_c('ar-field',{staticClass:"field-condition",style:({
          minWidth: '184px'
        }),attrs:{"label":"Send only to people"}},[_c('ar-simple-select',{staticClass:"select-filter-type",attrs:{"items":_vm.tagSelectConditionItems,"default-select-index":_vm.selectedConditionIndex,"data-test-id":"message-targeting-condition-select"},on:{"select":_vm.handleConditionSelect}})],1),_vm._v(" "),_c('ar-field',{staticClass:"field-value u-width-100-percent",attrs:{"label":"Tag"}},[_c('am2-fan-tag-input',{staticClass:"tag-input",style:({
            backgroundColor:' #FFF',
            paddingTop: '5px',
            paddingBottom: '6px'
          }),attrs:{"placeholder":"Select tag...","allow-custom-input":false,"show-tag-count":false,"data-test-id":"message-targeting-tag-input"},on:{"input":_vm.handleTagInput},model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}}),_vm._v(" "),_c('am2-link-button-dropdown',{style:({
            marginTop: '8px',
            float: 'right',
          }),attrs:{"items":_vm.tagSubdropdownItems,"item-key":_vm.selectedSubConditionKey,"dropdown-style":{

          },"data-test-id":"message-targeting-tag-subcondition-select"},on:{"select":_vm.handleTagSubdropdownItemSelect}})],1)],1):_vm._e(),_vm._v(" "),(_vm.displayedSubsection === 'segment')?_c('div',{class:['subsection',
             'segment-subsection',
             _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-min',
             _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']},[_c('ar-field',{staticClass:"field-condition",style:({
          minWidth: '184px'
        }),attrs:{"label":"Send only to people"}},[_c('ar-simple-select',{staticClass:"select-filter-type",attrs:{"items":_vm.segmentSelectConditionItems,"default-select-index":_vm.selectedConditionIndex,"data-test-id":"message-targeting-condition-select"},on:{"select":_vm.handleConditionSelect}})],1),_vm._v(" "),_c('ar-field',{staticClass:"field-value u-width-100-percent",attrs:{"label":"Segment"}},[_c('ar-simple-select',{staticClass:"u-margin-top-3 segment-select u-width-100-percent",attrs:{"items":_vm.savedSegmentList,"default-select-index":_vm.selectedSegmentIndex,"enable-filter":"","placeholder":"Select segment...","data-test-id":"message-targeting-segment-select"},on:{"select":_vm.handleSegmentSelect}})],1)],1):_vm._e(),_vm._v(" "),(_vm.displayedSubsection === 'messages_opened')?_c('div',{class:['subsection',
             'message-subsection',
             _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-min',
             _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']},[_c('ar-field',{staticClass:"field-condition",style:({
          minWidth: '184px'
        }),attrs:{"label":"Send only to people"}},[_c('ar-simple-select',{staticClass:"select-filter-type",attrs:{"items":_vm.openedSelectConditionItems,"default-select-index":_vm.selectedConditionIndex,"data-test-id":"message-targeting-condition-select"},on:{"select":_vm.handleConditionSelect}})],1),_vm._v(" "),_c('ar-field',{staticClass:"field-value u-width-100-percent",attrs:{"label":"Message"}},[_c('am2-message-select',{staticClass:"u-margin-top-3 segment-select u-width-100-percent",attrs:{"messages":_vm.messagesListOpened,"value":_vm.messageOid,"search-string":_vm.messageSearchString,"data-test-id":"message-targeting-message-select"},on:{"filterStringInput":_vm.handleMessageFilterStringInput,"select":_vm.handleMessageSelect}})],1)],1):_vm._e(),_vm._v(" "),(_vm.displayedSubsection === 'messages_clicked')?_c('div',{class:['subsection',
             'message-subsection',
             _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-min',
             _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']},[_c('ar-field',{staticClass:"field-condition",style:({
          minWidth: '184px'
        }),attrs:{"label":"Send only to people"}},[_c('ar-simple-select',{staticClass:"select-filter-type",attrs:{"items":_vm.clickedSelectConditionItems,"default-select-index":_vm.selectedConditionIndex,"data-test-id":"message-targeting-condition-select"},on:{"select":_vm.handleConditionSelect}})],1),_vm._v(" "),_c('ar-field',{staticClass:"field-value u-width-100-percent",attrs:{"label":"Message"}},[_c('am2-message-select',{staticClass:"u-margin-top-3 segment-select u-width-100-percent",attrs:{"messages":_vm.messagesListClicked,"value":_vm.messageOid,"search-string":_vm.messageSearchString,"data-test-id":"message-targeting-message-select"},on:{"filterStringInput":_vm.handleMessageFilterStringInput,"select":_vm.handleMessageSelect}})],1)],1):_vm._e(),_vm._v(" "),(_vm.displayedSubsection === 'events')?_c('div',{class:['subsection',
             'message-subsection',
             _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-min',
             _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']},[_c('ar-field',{staticClass:"field-condition",style:({
          minWidth: '184px'
        }),attrs:{"label":"Send only to people"}},[_c('ar-simple-select',{staticClass:"select-filter-type",attrs:{"items":_vm.eventSelectConditionItems,"default-select-index":_vm.selectedConditionIndex,"data-test-id":"message-targeting-condition-select"},on:{"select":_vm.handleConditionSelect}})],1),_vm._v(" "),_c('ar-field',{staticClass:"field-value u-width-100-percent",attrs:{"label":"Event"}},[_c('am2-event-select',{staticClass:"u-margin-top-3 segment-select u-width-100-percent",attrs:{"value":_vm.eventOid,"search-string":_vm.eventSearchString,"data-test-id":"message-targeting-event-select"},on:{"filterStringInput":_vm.handleEventFilterStringInput,"select":_vm.handleEventSelect}})],1)],1):_vm._e(),_vm._v(" "),(_vm.displayedSubsection === 'campaigns')?_c('div',{class:['subsection',
             'message-subsection',
             _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-min',
             _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']},[_c('ar-field',{staticClass:"field-condition",style:({
          minWidth: '184px'
        }),attrs:{"label":"Send only to people"}},[_c('ar-simple-select',{staticClass:"select-filter-type",attrs:{"items":_vm.campaignSelectConditionItems,"default-select-index":_vm.selectedConditionIndex,"data-test-id":"message-targeting-condition-select"},on:{"select":_vm.handleConditionSelect}})],1),_vm._v(" "),_c('ar-field',{staticClass:"field-value u-width-100-percent",attrs:{"label":"Campaign"}},[_c('am2-campaign-select',{staticClass:"u-margin-top-3 segment-select u-width-100-percent",attrs:{"value":_vm.campaignOid,"search-string":_vm.campaignSearchString,"data-test-id":"message-targeting-campaign-select"},on:{"filterStringInput":_vm.handleCampaignFilterStringInput,"select":_vm.handleCampaignSelect}})],1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"recipients-count u-margin-top-6"},[(_vm.isFetchingFilteredRecipientListCount && _vm.currentAdvancedTargetingIsValid)?_c('am2-loading-spinner',{style:({
          width: '1.5em',
          height: '1.5em'
        })}):_c('ar-text',{staticClass:"recipients-text",attrs:{"size":"md","data-test-id":"message-targeting-recipient-count","text":_vm.recipientCountText}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }