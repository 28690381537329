<template>
  <div class="ar-dropzone">
    <am2-resize-image-modal
      v-if="imageOptions.dimensions"
      :is-show="displayResizeImageModal"
      :target-dimensions="imageOptions.dimensions"
      :file="tempFile"
      @resize="handleImageResize"
      @cancel="handleResizeImageCancel"
    />
    <!-- Dropdown on top-right when you have file -->
    <div
      v-if="feedback"
      :class="[
        'setting-dropdown-wrapper',
        loading && 'disabled',
      ]">
      <am2-icon-button-dropdown
        :icon-props="{
          name: 'edit',
        }"
        color="white"
        :hovered-color="`white`"
        :items="actions"
        :dropdown-style="{
          width: '155px',
        }"
        @select="handleSettingOptionSelect"
      />
    </div>
    <div
      ref="dropzone"
      :style="{
        height: dropzoneHeight,
      }"
      :class="[
        'content-section',
        errorMsg && 'has-error',
        dragOver && 'is-dragover',
        `${feedbackType}-feedback`,
        feedback && 'is-complete',
        loading && 'disabled',
      ]"
      @dragover.prevent="dragOver = true"
      @dragenter.prevent="dragOver = true"
      @dragleave.prevent="dragOver = false"
      @dragend.prevent="dragOver = false"
      @drop.prevent="chooseFile"
      @click="handleDropzoneClick"
    >
      <!-- We have invisible label & input, don't take them out, cuz it works -->
      <label
        v-show="false"
        ref="fileBtn"
        :for="'dzone_'+_uid"
      />
      <!-- We have invisible label & input, don't take them out, cuz it works -->
      <input
        v-show="false"
        ref="fileCtrl"
        :id="'dzone_'+_uid"
        :accept="fileTypeInclusive"
        type="file"
        @change="chooseFile"
        @focus="resetFocus"
      />
      <template v-if="loading">
        <am2-loading-spinner size="48px" />
        <ar-text
          v-if="isProcessingCsvFile"
          class="u-margin-top-3"
          size="xs"
          :text="`Processing CSV file (${processCsvFileProgress}%)`"
          weight="bold"
          :style="{
            color: $arStyle.color.skyBlueGrey800,
          }"
        />
      </template>
      <template v-else-if="feedback">
        <div
          v-if="feedbackType === 'default'"
          class="feedback-section default"
        >
          <div v-if="!errorMsg" class="tick-container">
            <ar-icon
              name="check"
              height="12px"
              color="white"
            />
          </div>
          <ar-text
            size="xs"
            :text="feedback"
            allow-html
            class="text u-margin-top-2"
          />
        </div>
        <div
          v-else-if="feedbackType === 'image'"
          class="feedback-section image"
          :style="{
            backgroundImage: feedback ? `url('${feedback}')` : null,
            border: `1px solid ${$arStyle.color.blueGrey500}`,
          }"
        />
      </template>
      <template v-else>
        <div class="placeholder-section">
          <ar-icon
            v-bind="decoratedPlaceholderIcon"
          />
          <ar-text
            size="xs"
            :text="placeholderText"
            allow-html
            multiple-lines
            line-height="25px"
            align="center"
            class="placholder-text"
          />
        </div>
      </template>
    </div>
    <ar-state-message v-if="errorMsg" type="error" :text="errorMsg" />
  </div>
</template>

<script>
// import VueNotifications from 'vue-notifications';
import { mapActions } from 'vuex';
import * as Papa from 'papaparse';
import { urlsRegex, csvFileNameRegex } from '@/utils/regex/';
import { createTextFile } from '@/utils/html-element/';
import { AR_SHORT_URL_PATTERN } from '@/utils/constants';

export default {
  name: 'Dropzone',
  props: {
    fileType: {
      type: [String, Array],
      default: null,
    },
    fileTypeAlias: {
      type: String,
      default: '',
    },
    fileSize: {
      type: Number,
      default: null,
    },
    placeholderIcon: {
      type: Object,
      default: () => ({}),
    },
    placeholderTextTemplate: {
      type: String,
      default: 'Drag and drop {{fileTypeAlias}} <br> or <a>click here</a> to browse.',
    },
    videoOptions: {
      type: Object,
      default: () => ({
        duration: null, // Number, seconds
      }),
    },
    imageOptions: {
      type: Object,
      default: () => ({
        dimensions: null // { width: null, height: null },
      }),
    },
    csvOptions: {
      type: Object,
      default: () => ({
        shortUrl: false,
      }),
    },
    ratio: { // Ratio of height / width, sometimes you need it for image dropzone
      type: Number,
      default: null,
    },
    feedbackType: { // Will probably need video, csv, etc.
      type: String,
      default: 'default',
      validator: (val) => ['default', 'image'].indexOf(val) > -1,
    },
    feedback: {
      default: null,
    },
    enableArS3BucketUpload: {
      type: Boolean,
      default: false,
    },
    arS3BucketUploadOptionsList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      dragOver: false,
      errorMsg: '',
      dropzoneHeight: null,
      isUploadingAssetToS3: false,
      isProcessingCsvFile: false,
      processCsvFileProgress: 0,
      displayResizeImageModal: false,
      tempFile: null,
    };
  },

  computed: {
    actions() {
      return [
        {
          name: `Upload new ${this.fileTypeAlias || 'file'}`,
          class: 'bold',
          action: () => {
            this.handleUploadNewFileClick();
          },
        },
        {
          name: `Delete ${this.fileTypeAlias || 'file'}`,
          class: 'bold',
          action: () => {
            this.handleDeleteFileClick();
          },
        },
      ];
    },
    // Fixes the filetype prop so that it appends additional fileTypes if required.
    // Typically occurs because Chrome likes to use the OS to determine MIME types on Windows, which can cause issues
    // on Windows systems with Excel installed
    fileTypeInclusive(){
      if(!this.fileType) return null;

      const inclusiveFileTypes = [...this.fileType];

      if(this.fileType.includes('text/csv') && !this.fileType.includes('.csv')) inclusiveFileTypes.push('.csv');
      if(this.fileType.includes('text/csv') && !this.fileType.includes('application/vnd.ms-excel')) inclusiveFileTypes.push('application/vnd.ms-excel');

      return inclusiveFileTypes;
    },
    decoratedPlaceholderIcon() {
      return {
        width: '32px',
        height: '32px',
        color: this.$arStyle.color.blueGrey700,
        ...this.placeholderIcon,
      };
    },
    placeholderText() {
      return this.placeholderTextTemplate.replace(/{{fileTypeAlias}}/g, this.fileTypeAlias ? this.fileTypeAlias : 'file');
    },
    loading() {
      return this.isUploadingAssetToS3 || this.isProcessingCsvFile;
    },
  },

  mounted() {
    this.calculateDropzoneHeight();

    addEventListener('resize', this.handleWindowResize);
  },

  beforeDestroy() {
    removeEventListener('resize', this.handleWindowResize);
  },

  methods: {
    ...mapActions([
      'UPLOAD_ASSET',
    ]),

    calculateDropzoneHeight() {
      if (!this.ratio) {
        return;
      }
      this.dropzoneHeight = `${parseInt(this.$refs.dropzone.offsetWidth * this.ratio, 10)}px`;
    },

    selectFile(e) {
      if (e && e.target.nodeName === 'LABEL') return;
      this.$refs.fileBtn.click();
    },

    resetFocus() {
      setTimeout(() => {
        if (this.$refs.fileBtn) {
          this.$refs.fileBtn.focus();
        }
      }, 180);
    },

    chooseFile(event) {
      this.errorMsg = null;
      this.dragOver = false;
      const files = (event.dataTransfer || event.target || event.path[0]).files;
      const file = files ? (files[0] || null) : null;
      if (file) {
        this.processFile(file);
      }
    },
    /**
     * Call this method only when the file is not we want, e.x: Size error or Dimensions error
     */
    clearFileInput() {
      const ctrl = this.$refs.fileCtrl;
      try {
        ctrl.value = null;
        /* eslint-disable no-empty */
      } catch (ex) {}
      if (ctrl.value) ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    },

    processImage(file) {
      const image = new Image();

      // Need to do this to check the dimensions of the image
      image.src = window.URL.createObjectURL(file);
      image.onload = async () => {
        if (
          this.imageOptions.dimensions &&
          (image.width !== this.imageOptions.dimensions.width
          || image.height !== this.imageOptions.dimensions.height)
        ) {
          // Let's delegate works to ar-resize-image-modal
          this.tempFile = file;
          this.displayResizeImageModal = true;
        } else {
          this.uploadFile(file, image);
        }
      };
    },

    processVideo(file) {
      const video = document.createElement('video');
      const source = document.createElement('source');
      source.src = window.URL.createObjectURL(file);
      video.appendChild(source);
      video.onloadeddata = () => {
        const { duration } = video;
        if (this.videoOptions.duration && duration > this.videoOptions.duration) {
          this.displayError('videoDuration');
          this.clearFileInput();
          return;
        }
        this.uploadFile(file, video);
      };
    },

    processCsv(file) {
      const fileSize = file.size;

      const maxColumnWidthMap = {};
      let headers = null;
      let body = [];
      let hasEmptyCell = false;

      Papa.LocalChunkSize = 1024 * 1024;
      Papa.parse(file, {
        header: true,
        worker: false,
        skipEmptyLines: true,
        beforeFirstChunk: () => {
          this.isProcessingCsvFile = true;
          this.processCsvFileProgress = 0;
        },
        transformHeader: (h) => {
          return h.trim();
        },
        chunk: (results) => {
          if (!headers) {
            headers = results.meta.fields;
            // Initialize max column width map
            headers.forEach(header => { maxColumnWidthMap[header] = 0; });
          }

          results.data.forEach(cellMap => {
            const newRow = [];
            headers.forEach(header => {
              let cell = cellMap[header];
              // We have to trim the cells
              if (typeof cell === 'string') {
                cell = cell.trim();
              }
              newRow.push(cell);
              // Check if cell is empty
              if (!cell) { hasEmptyCell = true; }
              // Get the length of cell and update max column width map if necessary
              if (cell) {
                let shortenCell = cell;
                // Shorten URL if shortUrl is turned on
                if (this.csvOptions.shortUrl) {
                  shortenCell = cell.replace(urlsRegex, AR_SHORT_URL_PATTERN);
                }
                if (shortenCell.length > maxColumnWidthMap[header]) {
                  maxColumnWidthMap[header] = shortenCell.length;
                }
              }
            });
            body.push(newRow);
          });
          this.processCsvFileProgress = parseInt(100 * results.meta.cursor / fileSize, 10);
        },
        complete: (_, oldFile) => {
          // Generate CSV text with trimmed headers and cells
          const newCsvText = Papa.unparse({
            fields: headers,
            data: body,
          });
          // Generate new file with generated CSV text
          const newFile = createTextFile({
            text: newCsvText,
            type: 'text/csv',
            name: oldFile.name,
          });
          this.isProcessingCsvFile = false;
          this.processCsvFileProgress = 0;
          this.uploadFile(newFile, {
            headers,
            body,
            hasEmptyCell,
            maxColumnWidthMap,
          });
        },
      });
    },

    processOthers(file) {
      this.uploadFile(file, null);
    },

    /*
     * IMPORTANT NOTE
     * --------------
     * Windows introduces all sorts of nonsense with file pickers, particularly around the CSV file type. On any Windows-based
     * browser, the file picker will defer to the OS for file-type associations. These associations are stored in the Windows
     * registry, and has little to do with the actual file extension.
     *
     * Also worth noting is that Windows does not officially recognise the text/csv MIME type. In fact, without Excel installed
     * it doesn't even assign a type to .csv files (leaving it blank). With Excel installed, CSV files are assigned the really
     * bizarre MIME type application/vnd.ms-excel
     *
     * Because of this, we need to take some special precautions around CSV file types, to ensure that we're able to correctly
     * read them on Windows systems.
     *
     */
    async processFile(file) {
      this.$emit('uploadStart');

      // Windows won't infer MIME type from
      // extension, so we'll have to do it ourselves.

      const fileExtension = file.name.indexOf('.') > -1 ? file.name.split('.').pop() : null;

      // First, check that the MIME type/extension matches what we allow
      // The file is considered valid if:
          // fileTypeInclusive contains the file.type value OR
          // fileExtension is set and fileTypeInclusive contains the fileExtension value

      if (this.fileTypeInclusive && !(this.fileTypeInclusive.includes(file.type) || (!!fileExtension && this.fileTypeInclusive.includes(`.${fileExtension}`)))) {
        this.displayError('type');
        this.clearFileInput();
        return;
      }
      if (this.fileSize && file.size > this.fileSize) {
        this.displayError('size');
        this.clearFileInput();
        return;
      }

      if (file.type.toString().includes('image')) {
        this.processImage(file);
      } else if (file.type.toString().includes('video')) {
        this.processVideo(file);
      } else if (fileExtension === 'csv' || file.type.toString().includes('csv')) {
        try {
          this.processCsv(file);
        } catch(e) {
          this.displayError('type');
          this.clearFileInput();
        }
      } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
        // By allowing the application/vnd.ms-excel MIME type, we also allow xls and xlsx files through. This prevents our parser from trying to read them
        this.displayError('type');
        this.clearFileInput();
      } else {
        this.processOthers(file);
      }
    },
    async displayError(type) {
      this.$emit('uploadEnd');
      if (type === 'size' && this.fileSize) {
        this.errorMsg = `The file can be no larger than ${this.fileSize / 1000000} MB`;
      } else if (type === 'videoDuration' && this.videoOptions.duration) {
        const minutes = parseInt(this.videoOptions.duration / 60, 10);
        const seconds = parseInt(this.videoOptions.duration % 60, 10);
        this.errorMsg = `
          The video duration can not be longer than
          ${minutes ? ` ${minutes} minutes` : ''}
          ${minutes && seconds ? ' and' : ''}
          ${seconds ? ` ${seconds} seconds` : ''}
        `;
      } else if (type === 's3UploadFailed') {
        this.errorMsg = `Failed to upload ${this.fileTypeAlias}`;
      } else {
        this.errorMsg = `Please check the ${type} of the file`;
      }
      this.$emit('error', this.errorMsg);
    },

    async uploadAssetToS3(file, arS3BucketUploadOptions) {
      try {
        const asset = await this.UPLOAD_ASSET({
          assetType: arS3BucketUploadOptions.assetType || null,
          contentType: file.type,
          file,
          campaignOid: arS3BucketUploadOptions.campaignOid || null,
          eventOid: arS3BucketUploadOptions.eventOid || null,
        });
        return asset;
      } catch (error) {
        return null;
      }
    },

    async uploadAssetToS3Bucket(file) {
      this.isUploadingAssetToS3 = true;
      const assets = await Promise.all(
        this.arS3BucketUploadOptionsList.map(async (arS3BucketUploadOptions) => {
          return await this.uploadAssetToS3(file, arS3BucketUploadOptions);
        }),
      );
      this.isUploadingAssetToS3 = false;
      return assets;
    },

    async uploadFile(file, additionalInfo) {
      let arS3BucketResources = [];
      if (this.enableArS3BucketUpload) {
        arS3BucketResources = await this.uploadAssetToS3Bucket(file);
        console.log(arS3BucketResources);
        const uploadedResourcesCount = arS3BucketResources.filter(resource => !!resource).length;

        // If not all file were uploaded, return failed
        if (uploadedResourcesCount !== this.arS3BucketUploadOptionsList.length) {
          this.$arNotification.push({ type: 'error', message: `Failed to upload ${this.fileTypeAlias}` });
          this.displayError('s3UploadFailed');
          this.clearFileInput();
          return;
        }
      }
      this.$emit('uploadEnd');
      this.$emit('upload', {
        file,
        additionalInfo,
        arS3BucketResources,
      });
    },

    handleImageResize(resizedImageFile) {
      this.displayResizeImageModal = false;
      const newImage = new Image();
      newImage.src = window.URL.createObjectURL(resizedImageFile);
      newImage.onload = async () => {
        this.uploadFile(resizedImageFile, newImage);
      };
    },

    handleResizeImageCancel() {
      this.displayResizeImageModal = false;
      this.clearFileInput();
      this.$emit('uploadEnd');
    },

    handleWindowResize() {
      this.calculateDropzoneHeight();
    },

    async handleDeleteFileClick() {
      this.clearFileInput();
      this.$emit('delete');
    },

    handleSettingOptionSelect(item) {
      item.action();
    },
    handleDropzoneClick() {
      this.selectFile();
    },

    handleUploadNewFileClick() {
      this.selectFile();
    },
  },
};
</script>
<style lang="scss" scoped>
.ar-dropzone {
  position: relative;
  .setting-dropdown-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: $zIndexHigh;
    background: $blueGrey900;

    &.disabled {
      pointer-events: none;
    }
  }
  .content-section {
    width: 100%;
    height: 100%;
    background-color: white;
    border: 2px dashed $skyBlueGrey500;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;

    &.is-dragover,
    &:hover {
      background-color: $purple100;
      border: 2px solid $purple500;
    }

    &.has-error {
      border: 2px solid $red500;
      background-color: $red400;
    }

    &.disabled {
      pointer-events: none;
    }

    &.is-complete {
      &.image-feedback {
        border: none;
      }
      &.default-feedback {
        border: 2px solid $skyBlueGrey500;
      }
    }

    .feedback-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      &.default {
        padding: 16px;
        .tick-container {
          height: 32px;
          width: 32px;
          border-radius: 50%;
          background-color: $purple500;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .text {
          color: $blueGrey700;
        }
      }
      &.image {
        background-size: cover;
        background-position: center center;
        border-radius: 6px;
      }
    }

    .placeholder-section {
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 16px;

      .placholder-text {
        margin-top: 7px;
        color: $blueGrey700;
      }
    }
  }
}
</style>
