<template>
  <section class="ar-features-editor">
    <am2-heading
      type="h1"
      :title="title"
      size="md"
      :style="{
        marginBottom: '7px',
      }"
    />
    <ar-text
      size="xs"
      :text="description"
      multiple-lines
    />
    <ar-divider
      :style="{ margin: '20px 0 20px' }"
    />
    <div v-if="editPromoterPlan" class="permissions-section">
      <div class="permissions-sub-section">
        <am2-card-container
          class="container"
          :style="{ width: '100%' }"
        >
          <ar-checkbox
            label="Disable Promoter"
            align="right"
            :style="{ width: '100%' }"
            v-model="editPromoterPlan.promoterDisabled"
            :disabled="disabled"
          />
        </am2-card-container>
      </div>
    </div>
    <!--
      XXX/TODO: this template is annoying because whenever a new permission is added on the server,
      we HAVE to manually add an entry for it here as well. We need to properly break this down
      into a hierarchical structure so 'main' features like campaigns, events,
      audience, integrations, insights and messages are headings AND toggle-able with
      additional features shown underneath. That way for example, when you disable 'Messages'
      permission, FBM, Email and SMS also get promoterDisabled. It also makes for a nicer presentation.

      If our template was modelled like this, it would be much easier to template in the future -
      as long as the promoter's plan was correctly setup on the DB, we could simply iterate on
      demoPromoterPlan.features to construct our feature toggles and support future features without having to
      edit this component.

      What we should also have is an API endpoint for the admin to get ALL available payment_features to which
      a promoter can have enabled. Again this is difficult at the moment because of how features are structured
      in the database.
      -->
    <div v-if="editPromoterPlan" class="permissions-section">
      <div class="permissions-sub-section">
        <am2-card-container
          class="container"
          :style="{ width: '100%' }"
        >
          <ar-checkbox
            label="Campaigns"
            align="right"
            :style="{ width: '100%' }"
            v-model="editPromoterPlan.features.campaigns.enabled"
            :disabled="disabled"
          />
        </am2-card-container>
        <am2-card-container
          class="container"
          :style="{ width: '100%' }"
        >
          <ar-checkbox
            label="Events"
            align="right"
            :style="{ width: '100%' }"
            v-model="editPromoterPlan.features.events.enabled"
            :disabled="disabled"
          />
        </am2-card-container>
        <am2-card-container
          class="container"
          :style="{ width: '100%' }"
        >
          <ar-checkbox
            label="Audience"
            align="right"
            :style="{ width: '100%' }"
            v-model="editPromoterPlan.features.audience.enabled"
            :disabled="disabled"
          />
        </am2-card-container>
        <am2-card-container
          class="container"
          :style="{ width: '100%' }"
        >
          <ar-checkbox
            label="Messaging"
            align="right"
            :style="{ width: '100%' }"
            v-model="editPromoterPlan.features.messages.enabled"
            :disabled="disabled"
          />
        </am2-card-container>
        <am2-card-container
          class="container"
          :style="{ width: '100%' }"
        >
          <ar-checkbox
            label="Insights"
            align="right"
            :style="{ width: '100%' }"
            v-model="editPromoterPlan.features.insights.enabled"
            :disabled="disabled"
          />
        </am2-card-container>
        <am2-card-container
          v-if="arEnableAutomation"
          class="container"
          :style="{ width: '100%' }"
        >
          <ar-checkbox
            label="Automation"
            align="right"
            :style="{ width: '100%' }"
            v-model="editPromoterPlan.features.automation.enabled"
            :disabled="disabled"
          />
        </am2-card-container>
      </div>
      <div class="permissions-sub-section">
        <am2-card-container
          class="container"
          :style="{ width: '100%' }"
        >
          <ar-checkbox
            label="Integrations"
            align="right"
            :style="{ width: '100%' }"
            v-model="editPromoterPlan.features.integrations.enabled"
            :disabled="disabled"
          />
        </am2-card-container>
        <am2-card-container
          class="container"
          :style="{ width: '100%' }"
        >
          <ar-checkbox
            label="Facebook Custom Audience"
            align="right"
            :style="{ width: '100%' }"
            v-model="editPromoterPlan.features.integrations.components.facebookCustomAudience.enabled"
            :disabled="disabled"
          />
        </am2-card-container>
        <am2-card-container
          class="container"
          :style="{ width: '100%' }"
        >
          <ar-checkbox
            label="Facebook Messenger"
            align="right"
            :style="{ width: '100%' }"
            v-model="editPromoterPlan.features.messages.components.facebookMessenger.enabled"
            :disabled="disabled"
          />
        </am2-card-container>
        <am2-card-container
          class="container"
          :style="{ width: '100%' }"
        >
          <ar-checkbox
            label="SMS"
            align="right"
            :style="{ width: '100%' }"
            v-model="editPromoterPlan.features.messages.components.sms.enabled"
            :disabled="disabled"
          />
        </am2-card-container>
        <am2-card-container
          class="container"
          :style="{ width: '100%' }"
        >
          <ar-checkbox
            label="Email"
            align="right"
            :style="{ width: '100%' }"
            v-model="editPromoterPlan.features.messages.components.email.enabled"
            :disabled="disabled"
          />
        </am2-card-container>
        <am2-card-container
          class="container"
          :style="{ width: '100%' }"
        >
          <ar-checkbox
            label="Customer Profile"
            align="right"
            :style="{ width: '100%' }"
            v-model="editPromoterPlan.features.audience.components.customerProfiles.enabled"
            :disabled="disabled"
          />
        </am2-card-container>
      </div>
    </div>
    <ar-simple-button
      v-if="editable && editPromoterPlan"
      text="Update Demo Plan"
      :loading="loading"
      :style="{ margin: '15px 0 30px' }"
      @click="handleUpdateClick"
    />
  </section>
</template>

<script>
import merge from 'deepmerge';
import { clone } from '@/utils/helpers/';

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;
const defaultPromoterPlan = {
  features: {
    campaigns: {
      id: "campaigns",
      enabled: false,
      components: { campaignSetup: { id: "campaign-setup", enabled: false } }
    },
    audience: {
      id: "audience",
      enabled: false,
      components: {
        filters: { id: "filters", enabled: false },
        saveFiltersAndTags: { id: "save-filters-and-tags", enabled: false },
        customerProfiles: { id: "customer-profiles", enabled: false }
      }
    },
    messages: {
      id: "messages",
      enabled: false,
      components: {
        facebookMessenger: { id: "facebook-messenger", enabled: false },
        sms: { id: "sms", enabled: false },
        email: { id: "email", enabled: false }
      }
    },
    integrations: {
      id: "integrations",
      enabled: false,
      components: { facebookCustomAudience: { id: "facebook-custom-audience", enabled: false } }
    },
    insights: { id: "insights", enabled: false, components: {} },
    events: { id: "events", enabled: false, components: {} },
    automation: { id: "automation", enabled: false, components: {} }
  },
  promoterDisabled: false
};

export default {

  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    promoterPlan: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      editPromoterPlan: null,
      arEnableAutomation: process.env.arEnableAutomation,
    };
  },

  watch: {
    promoterPlan: {
      handler: function (val) {
        // Remove automation feature when automation feature is not turned on.
        if (!this.arEnableAutomation) {
          delete defaultPromoterPlan.features.automation;
        }
        this.editPromoterPlan = merge(defaultPromoterPlan, val || {}, { arrayMerge: overwriteMerge });
      },
      immediate: true,
    },
  },

  methods: {
    handleUpdateClick() {
      this.$emit('update', this.editPromoterPlan);
    },
  }
};
</script>
<style lang="scss" scoped>
.ar-features-editor {
  .permissions-section {
    display: flex;
    justify-content: space-between;

    .permissions-sub-section {
      display: inline-flex;
      flex-direction: column;
      width: calc(50% - 20px);

      .container {
        padding: 10px;
        margin: 0 0 20px;
        width: 400px;
        text-transform: capitalize;
      }
    }
  }
}
</style>
