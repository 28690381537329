<template>
  <div class="warning-modal-body">
    <div class="exclamation-mark">
      <ar-icon
        name="alert-exclamation-mark"
        height="70px"
      />
    </div>
    <ar-text
      class="warning-modal-message"
      text="Audience Republic is down for maintenance. Please come back later."
      align="center"
      size="sm"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MaintenancePage',
  
  layout: 'edit',
};
</script>

<style lang="scss" scoped>
.warning-modal-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 250px;
  .exclamation-mark {
    display: flex;
    justify-content: center;
    margin-bottom: 19px;
  }
  .warning-modal-title {
    margin-bottom: 16px;
  }
  .warning-modal-message {
    color: $blueGrey800;
  }
}
</style>
