var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ar-select',{staticClass:"select-wrapper",attrs:{"items":_vm.selectableOptions,"default-select-index":_vm.selectedIntegrationIndex,"hide-arrow":false,"dropdown-width":300},on:{"select":_vm.handleSelect,"actionClick":_vm.handleNewAccount},scopedSlots:_vm._u([{key:"selected-item",fn:function(selectedItemScope){return [_c('div',{class:[
        'u-display-flex',
        'u-align-items-center',
        'u-padding-x-4' ]},[_c('ar-avatar',{attrs:{"image-path":selectedItemScope.item.imageSrc,"name":selectedItemScope.item.name,"size":"30px"}}),_vm._v(" "),_c('ar-text',{class:[
          'u-margin-left-2' ],attrs:{"text":selectedItemScope.item.name,"size":"xs"}})],1)]}},{key:"item",fn:function(itemScope){return [(itemScope.item.isAction)?_c('div',{class:[
        'u-display-flex',
        'u-align-items-center',
        'u-padding-x-4' ],style:({
        cursor: 'pointer',
        height: '50px',
        background: itemScope.active ? _vm.$arStyle.color.purple100 : null,
      })},[_c('div',{class:[
          'u-display-flex',
          'u-justify-content-center' ],style:({
          width: '30px',
        })},[_c('ar-icon',{style:({
            color: _vm.$arStyle.color.purple500,
          }),attrs:{"name":itemScope.item.iconName}})],1),_vm._v(" "),_c('ar-text',{class:[
          'u-margin-left-2' ],style:({
          color: _vm.$arStyle.color.purple500,
          lineHeight: 1,
        }),attrs:{"text":itemScope.item.name,"size":"xs","weight":"bold"}})],1):_c('div',{class:[
        'u-display-flex',
        'u-align-items-center',
        'u-padding-x-4' ],style:({
        cursor: 'pointer',
        height: '50px',
        background: itemScope.active ? _vm.$arStyle.color.purple100 : null,
      })},[_c('ar-avatar',{attrs:{"image-path":itemScope.item.imageSrc,"name":itemScope.item.name,"size":"30px","color":itemScope.active ? 'white' : 'grey'}}),_vm._v(" "),_c('ar-text',{class:[
          'u-margin-left-2' ],style:({
          color: itemScope.active ? _vm.$arStyle.color.purple500 : null,
          maxWidth: '240px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }),attrs:{"text":itemScope.item.email,"size":"xs"}})],1)]}}])},[_vm._v(" "),_vm._v(" "),_c('template',{slot:"no-item"},[_c('div',{class:[
        'u-display-flex',
        'u-align-items-center',
        'u-padding-x-4',
        'u-padding-y-2' ]},[_c('ar-text',{style:({
          color: _vm.$arStyle.color.$blueGrey800,
        }),attrs:{"size":"xs","text":"No accounts"}})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }