<template>
  <section class="admin-message-tasks">
    <div
      class="u-margin-top-6"
      v-infinite-scroll="fetchMoreData"
      infinite-scroll-disabled="disableFetchMoreMessages"
      :infinite-scroll-distance="10"
      :style="{
        width: '100%',
      }"
    >
      <am2-top
        :dropdown="messageTypeDropdown"
        :search="{
          action: serverSideSearch,
          placeholder: 'Search Task Oid or Promoter Oid',
          value: searchString,
          style: {
            width: searchBoxWidth
          }
        }"

        title="Message Tasks Admin Panel"
        :class="[
          'top-wrapper',
          'u-margin-bottom-8',
          $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        ]"
      />

      <!-- We'll just build the table component here directly, rather than creating an admin-only component -->
      <am2-table
        :heads="tableHeadings"
        class="message-table"
        :rows="rows"
        :loading="adminState.isFetchingFanMessageTasks"
        has-sticky-header
      >
        <div
          slot="message"
          slot-scope="{ data }"
          class="message-cell"
          :style="{
            padding: $arMediaQuery.pageContent.minWidth('sm') ? '17px 14.5px 16.5px 22px' : '10px 14.5px 10px 22px',
          }"
        >
          <div class="type-container">
            <ar-icon
              class="icon"
              :name="getIcon(data.provider).name"
              :color="getIcon(data.provider).color"
              width="19px"
            />
          </div>
          <div class="body-container">
            <ar-text
              v-if="data.provider === 'email'"
              :class="['main', getMessageUiStatus(data) === 'completed' && 'sent']"
              size="xs"
              :text="data.meta.messageBody.subject"
              weight="bold"
            />
            <ar-text
              v-else
              :class="['main', getMessageUiStatus(data) === 'completed' && 'sent']"
              size="xs"
              :text="data.meta.messageBody"
              weight="bold"
            />
            <ar-text
              class="subtitle"
              size="xxxs"
              :text="data.lastActionTimeText"
              v-tooltip.top="{
                content: data.lastActionTimeTooltip ? data.lastActionTimeTooltip : null
              }"
            />
          </div>
          <div
            class="status-action-container"
          >
            <div class="status">
              <div
                v-if="data.uiStatus === 'in-progress' && data.statusDetails"
                class="progress"
              >
                {{ sentPercentage(data.statusDetails) }}%
                <am2-gauge
                  class="gauge"
                  fill-color="#7344c0"
                  :value="numSent(data.statusDetails)"
                  :max="data.statusDetails.totalMessages"
                />
              </div>
              <div>
                <am2-tag
                  :text="generateStatusCopy(data.status)"
                  :type="statusToTagColor(data.status)"
                  :style="{
                border: '1px solid white'
              }"
                />
              </div>
            </div>
            <am2-icon-button-dropdown
              v-if="messageOptions(data).length > 0"
              :icon-props="{
                name: 'menu',
              }"
              :items="messageOptions(data)"
              @select="(item) => handleMessageOptionSelect(item.eventName, data)"
            />
          </div>
        </div>
        <div
          slot="promoter"
          slot-scope="{ data }"
          class="promoter"
          :style="{
            padding: '12px',
          }"
        >
          <div
            class="stats-container"
            v-tooltip.top="{
                content: `${data.promoterAccount.name} (${data.promoterAccount.promoterOid})<br/>${data.promoterAccount.emailAddress}`,
              }"
          >
            <ar-text
              size="xs"
              :text="`${data.promoterAccount.name} (${data.promoterAccount.promoterOid})`"
              weight="normal"
              class="u-margin-bottom-2"
            />
            <ar-text
              size="xxs"
              :text="`${data.promoterAccount.emailAddress}`"
              weight="normal"
              :style="{
                color: $arStyle.color.skyBlueGrey700,
              }"
            />
          </div>
        </div>
        <div
          slot="task-oid"
          slot-scope="{ data }"
          class="task-oid"
          :style="{
            padding: '12px',
          }"
        >
          <div class="stats-container">
            <ar-text
              size="xs"
              :text="data.oid"
              weight="normal"
            />
          </div>
        </div>
        <div
          slot="stats"
          slot-scope="{ data }"
          class="stats"
          :style="{
            padding: '12px',
          }"
        >
          <div
            class="stats-container"
            v-if="data.status === 'completed' || data.status === 'in-progress' || data.status === 'failed'"
          >
            <div
              v-if="data.statusDetails"
              v-tooltip.top="{
                content: `Total:  ${data.statusDetails.totalMessages}<br/>
                          Sent:  ${numSent(data.statusDetails)}<br/>
                          Delivered:  ${data.statusDetails.delivered || 0}<br/>
                          Sending:  ${data.statusDetails.inProgress || 0}<br/>
                          Opened:  ${data.statusDetails.opened || 0}<br/>
                          Clicks:  ${data.statusDetails.clicked || 0}<br/>
                          Failed:  ${data.statusDetails.failed || 0}<br/>
                          Bounced:  ${data.statusDetails.bounced || 0}<br/>
                          Deferred:  ${data.statusDetails.deferred || 0}<br/>
                          Spam:  ${data.statusDetails.markedSpam || 0}<br/>
                          Unsubscribed:  ${data.statusDetails.unsubscribed || 0}<br/>`,
                classes: 'align-left',
              }"
            >
              <div class="stat">
                <ar-text
                  size="xxs"
                  allow-html
                  :text="`Sent:`"
                  weight="normal"
                />
                <ar-text
                  size="xxs"
                  allow-html
                  :text="`${numSent(data.statusDetails) || '-'}`"
                  weight="bold"
                  :style="{
                    color: numSent(data.statusDetails) ? $arStyle.color.green500 : null,
                  }"
                />
              </div>

              <div
                v-if="data.statusDetails && data.statusDetails.failed !== null"
                class="stat"
              >
                <ar-text
                  size="xxs"
                  allow-html
                  :text="`Failed:`"
                  weight="normal"
                />
                <ar-text
                  size="xxs"
                  allow-html
                  :text="`${data.statusDetails.failed || '-'}`"
                  :style="{
                    color: data.statusDetails.failed ? $arStyle.color.red500 : null,
                  }"
                  weight="bold"
                />
              </div>

              <div
                v-if="data.statusDetails && data.statusDetails.inProgress !== null"
                class="stat"
              >
                <ar-text
                  size="xxs"
                  allow-html
                  :text="`Sending:`"
                  weight="normal"
                />
                <ar-text
                  size="xxs"
                  allow-html
                  :text="`${data.statusDetails.inProgress || '-'}`"
                  :style="{
                    color: data.statusDetails.inProgress ? $arStyle.color.purple500 : null,
                  }"
                  weight="bold"
                />
              </div>
            </div>
          </div>
        </div>
      </am2-table>
    </div>
  </section>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
  import { convertToTitleCase } from '~/utils/helpers';
  import { generateMessageCenterDateCopy } from '~/utils/date';

  export default {
    name: 'AdminMessageTasks',
    layout: 'default',

    data: () => ({
      searchString: '',
      activeFilter: 'all',
      filterOptions: [
        {
          name: 'All messages',
          count: 0,
          key: 'all',
          types: ['completed', 'in-progress', 'scheduled', 'failed'],
        },
        {
          name: 'All Finished',
          count: 0,
          key: 'all-finished',
          types: ['completed', 'failed'],
        },
        {
          name: 'All Not Finished',
          count: 0,
          key: 'all-not-finished',
          types: ['in-progress', 'scheduled'],
        },
        {
          name: 'Sent',
          count: 0,
          key: 'completed',
          types: ['completed'],
        },
        {
          name: 'Sending',
          count: 0,
          key: 'in-progress',
          types: ['in-progress'],
        },
        {
          name: 'Scheduled',
          count: 0,
          key: 'scheduled',
          types: ['scheduled'],
        },
        {
          name: 'Failed',
          count: 0,
          key: 'failed',
          types: ['failed'],
        },
        {
          type: 'divider',
        },
        {
          name: 'Archived',
          count: 0,
          key: 'archived',
          types: ['archived'],
        },
        {
          name: 'Cancelled',
          count: 0,
          key: 'cancelled',
          types: ['cancelled'],
        },
      ],
    }),

    watch: {
      activeFilter: {
        handler(val) {
          this.$nextTick( () => {
            this.fetchData();
          })
        },
      },
    },

    computed: {
      ...mapState({
        adminState: state => state.admin,
        isFetchingFanMessageTasks: state => state.admin.isFetchingFanMessageTasks,
        noMoreFanMessageTasks: state => state.admin.noMoreFanMessageTasks,
        hasFailedFetchingFanMessageTasks: state => state.admin.hasFailedFetchingFanMessageTasks,
      }),
      ...mapGetters({
        getMessageUiStatus: 'message/getMessageUiStatus',
        isAdminAccount: 'auth/isAdminAccount',
      }),

      disableFetchMoreMessages() {
        return this.isFetchingFanMessageTasks || this.noMoreFanMessageTasks || this.hasFailedFetchingFanMessageTasks;
      },

      searchBoxWidth() {
        if (this.$arMediaQuery.pageContent.minWidth('md')) {
          return '330px';
        } else if (this.$arMediaQuery.pageContent.minWidth('sm')) {
          return 'calc(100% - 160px)'
        } else {
          return 'calc(100% - 80px)'
        }
      },

      messageTypeDropdown() {
        return {
          activeItemKey: this.activeFilter,
          items: this.filterOptions.map((o, index) => {
            if(o.name) {
              return {
                ...o,
                action: () => this.setMessageFilter(o.key),
                style: {
                  fontWeight: this.activeFilter === o.key ? 'bold' : 'normal'
                },
              };
            } else {
              return {...o};
            }
          }),
        };
      },

      tableHeadings() {
        return [
          {
            name: 'Task',
            key: 'task-oid',
            format: 'task-oid',
            align: 'left',
            width: 75,
          },
          {
            name: 'Promoter',
            key: 'promoter',
            format: 'promoter',
            align: 'left',
            width: 233,
          },
          {
            name: 'Stats',
            key: 'stats',
            format: 'stats',
            align: 'left',
            width: 162,
          },
          {
            name: 'Message Details',
            key: 'details',
            format: 'message',
            align: 'left',
            width: 600,
          }
        ];
      },

      rows() {
        return this.adminState.fanMessageTasks.map(m => {
          const { opened = 0, clicked = 0, bounced = 0, deferred = 0, failed = 0, totalMessages } = m.statusDetails;
          const successfulSends = (totalMessages || 0)
            - (bounced || 0)
            - (deferred || 0)
            - (failed || 0);
          let lastActionTimeText;

          let usersTimeZone = null;
          try {
            usersTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          } catch(e) {
            //
          }

          const uiStatus = this.getMessageUiStatus(m);
          const timezone = m.meta && m.meta.presentation ? m.meta.presentation.timeZone : null;


          // Only use 'last updated time' when the message is a draft
          let lastActionTimeTooltip = null;
          if (uiStatus === 'completed' || uiStatus === 'in-progress') {
            const startedTime = m.started || m.scheduledAt || m.sysCtime;
            lastActionTimeText = `${this.actionStartingWord(uiStatus)} ${generateMessageCenterDateCopy(startedTime, (timezone || usersTimeZone))}`;
            lastActionTimeTooltip = timezone && timezone !== usersTimeZone ? `${generateMessageCenterDateCopy(startedTime)} local time` : null;
          } else if (uiStatus === 'scheduled') {
            lastActionTimeText = `${this.actionStartingWord(uiStatus)} ${generateMessageCenterDateCopy(m.scheduledAt, (timezone || usersTimeZone))}`;
            lastActionTimeTooltip = timezone && timezone !== usersTimeZone ? `${generateMessageCenterDateCopy(m.scheduledAt)} local time` : null;
          } else {
            lastActionTimeText = `${this.actionStartingWord(uiStatus)} ${generateMessageCenterDateCopy(m.sysMtime, (timezone || usersTimeZone))}`;
            lastActionTimeTooltip = timezone && timezone !== usersTimeZone ? `${generateMessageCenterDateCopy(m.sysMtime)} local time` : null;
          }

          if (m.meta.senderFriendlyName) {
            lastActionTimeText += ` from ${m.meta.senderFriendlyName}`;
          }

          let hideActions = false;
          if (m.provider === 'email' && !this.enableEmailSending) {
            hideActions = true;
          }

          // clicks should count as opens because one needs to open a message before
          // they can click a link inside a message
          // click rate should be a % of opens, rather than sends.
          const opensCount = opened + clicked;
          let opens = successfulSends > 0 ? ((100*(opensCount))/successfulSends) : 0;
          if (opens < 1) {
            opens = opens.toFixed(2);
          } else if (opens > 99 && opens < 100) {
            opens = 99;
          } else {
            opens = Math.round(opens);
          }

          let clicks = opensCount > 0 ? ((100*clicked)/opensCount) : 0;
          if (clicks < 1) {
            clicks = clicks.toFixed(2);
          } else if (clicks > 99 && clicks < 100) {
            clicks = 99;
          } else {
            clicks = Math.round(clicks);
          }

          const promoterAccount = {
            name: 'Promoter name unknown',
            emailAddress: 'Promoter email address unknown',
            promoterOid: m.promoterOid || '',
          };
          if (m.promoterAccount) {
            promoterAccount.name = `${m.promoterAccount.firstName} ${m.promoterAccount.lastName}`;
            promoterAccount.emailAddress = m.promoterAccount.emailAddress;
            promoterAccount.promoterOid = m.promoterAccount.promoterOid;
          }

          return {
            ...m,
            uiStatus,
            opens: opens,
            opensRaw: opensCount,
            clicks: clicks,
            clicksRaw: clicked,
            lastActionTimeText,
            lastActionTimeTooltip,
            hideActions,
            promoterAccount,
          }
        });
      }
    },

    mounted() {
      if (!this.isAdminAccount) {
        this.$router.push({
          path: '/audience',
        });
      }
      this['admin/RESET_ADMIN_FAN_MESSAGES']();
      this.fetchData();
    },

    methods: {
      ...mapActions([
        'admin/FETCH_ADMIN_MESSAGE_TASKS',
        'admin/CANCEL_ADMIN_MESSAGE_TASK',
        'admin/ARCHIVE_ADMIN_MESSAGE_TASK',
        'admin/RETRY_ADMIN_MESSAGE_TASK',
      ]),
      ...mapMutations(['admin/RESET_ADMIN_FAN_MESSAGES']),

      async serverSideSearch(search) {
        this.searchString = search;
        this.fetchData();
      },

      setMessageFilter(keyword) {
        this.activeFilter = keyword;
      },

      fetchData(options = {}) {
        if (this.disableFetchMoreMessages) return;
        let activeFilter = this.filterOptions.find( item => item.key === this.activeFilter);
        if (!activeFilter) activeFilter = this.filterOptions[0];

        const searchQuery = this.searchString || null;

        this['admin/FETCH_ADMIN_MESSAGE_TASKS']({
          ...options,
          searchQuery,
          types: activeFilter.types
        });
      },

      fetchMoreData() {
        if (this.disableFetchMoreMessages) return;
        this.fetchData({
          append: true,
        })
      },

      getIcon(provider) {
        if (provider === 'facebook') {
          return {
            name: 'messenger',
            color: this.$arStyle.color.messenger,
          };
        } else if (provider === 'sms') {
          return {
            name: 'sms',
            color: this.$arStyle.color.sms,
          };
        } else {
          // Email
          return {
            name: 'email',
            color: this.$arStyle.color.email,
          };
        }
      },

      numSent(details) {
        if (details.totalMessages) {
          const sent = details.sent || 0;
          const delivered = details.delivered || 0;
          const bounced = details.bounced || 0;
          const failed = details.failed || 0;
          const clicked = details.clicked || 0;
          const opened = details.opened || 0;
          const spam = details.markedSpam || 0;
          const unsubscribed = details.unsubscribed || 0;
          const deferred = details.deferred || 0;

          return sent + delivered + bounced + failed + opened + clicked + spam + unsubscribed + deferred;
        } else {
          return 0;
        }
      },
      sentPercentage(details) {
        if (details.totalMessages) {
          return Math.min(
            Math.round((this.numSent(details)/details.totalMessages)*100),
            100
          );
        } else {
          return 0;
        }
      },


      generateStatusCopy(word) {
        if (word === 'completed') {
          return 'Sent';
        } else if (word === 'in-progress') {
          return 'Sending';
        } else if (!word) {
          return 'Unknown';
        } else {
          return convertToTitleCase(word);
        }
      },

      statusToTagColor(status){
        if (!status) return 'grey';
        switch (status.toLowerCase()) {
          case 'in-progress':
            return 'purple';
          case 'completed':
            return 'green';
          case 'failed':
            return 'red';
          default:
            return 'grey';
        }
      },
      actionStartingWord(status) {
        if (!status) return 'Unknown Status';
        if (status ===  'draft') {
          return 'Edited';
        } else if (status ===  'scheduled') {
          return 'Scheduled';
        } else if (status ===  'archived') {
          return 'Archived';
        } else if (status ===  'cancelled') {
          return 'Cancelled';
        } else if (status ===  'failed') {
          return 'Failed';
        } else {
          return 'Sent';
        }
      },

      messageOptions(message) {
        const options = [];
        if (message.status === 'failed' || message.status === 'completed') {
          options.push({
            name: 'Retry',
            eventName: 'retry',
          });
        }
        if (message.status === 'scheduled') {
          options.push({
            name: 'Cancel',
            eventName: 'cancel',
          });
        }
        if (message.status === 'failed' || message.status === 'completed') {
          options.push({
            name: 'Archive',
            eventName: 'archive',
          });
        }
        return options;
      },

      handleMessageOptionSelect(event, message) {
        if (event === 'retry') {
          this['admin/RETRY_ADMIN_MESSAGE_TASK']({taskOid: message.oid});
        } else if (event === 'archive') {
          this['admin/ARCHIVE_ADMIN_MESSAGE_TASK']({taskOid: message.oid});
        } else if (event === 'cancel') {
          this['admin/CANCEL_ADMIN_MESSAGE_TASK']({taskOid: message.oid});
        }
      },

    }
  };
</script>

<style lang="scss" scoped>
  .admin-message-tasks {
    display: flex;

    .message-table {
      .message-cell {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &:hover {
          .type-container {
            box-shadow: 0px 0px 1px rgba(0,0,0,0.5);
          }
        }

        .type-container {
          border: 2px #EBEBEB solid;
          border-radius: 20px;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 23px;
          background-color: white;
          flex-shrink: 0;

          .icon {
            position: relative;
            left: 0.5px;
          }
        }

        .status-action-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          .status {
            display: flex;
            flex-direction: row;
            margin-right: 4px;

            .progress {
              text-align: right;
              margin-right: 14px;

              .gauge {
                width: 80px;
              }
            }
          }
        }
      }

      // TODO - Need a better solution than referencing the row within the parent.
      /deep/.tr {
        .percentage-cell {
          &__container {
            .active-only {
              display: none;
            }
            .inactive-only {
              display: block;
            }
          }
        }
        &:hover {
          .percentage-cell {
            &__container {
              .active-only {
                display: block;
              }
              .inactive-only {
                display: none;
              }
            }
          }
        }
        .td {
          align-items: center;
        }
      }

      .body-container {
        flex-grow: 1;
        font-weight: bold;
        overflow: hidden;
        padding-right: 16px;

        .main {
          color: rgba-to-rgb(rgba($blueGrey800, 0.5));

          &.sent {
            color: $blueGrey800;
          }
        }

        .subtitle {
          color: rgba-to-rgb(rgba($blueGrey800, 0.5));
          margin-top: 7px;
        }

      }

      .percentage-cell {
        padding: 25.5px 25.5px 16.5px 25.5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        &__container {
          width: 100%;
          text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .value {
          margin-bottom: 6px;
        }

        .subtitle {
          color: rgba-to-rgb(rgba($blueGrey800, 0.5));
        }

        .empty-dash {
          align-self: flex-end;
          width: 14px;
          border-bottom: 1px solid $blueGrey600;
        }
      }
    }

    .stats {
      width: 100%;
      .stat {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
      }
    }
  }
</style>
