<template>
  <div
    class="integration-halo"
    v-tooltip.top="{ content: `This event is connected to <span style='text-transform: capitalize'>${ integration }</span>. Your ticket sales are automatically being synced.` }"
  >
    <ar-icon
      v-if="integration"
      :name="integration"
      :color="iconColor"
      height="12px"
    />
  </div>
</template>

<script>
  export default {
    name: 'IntegrationIndicator',
    props: {
      integration: {
        type: String,
        required: true
      },
      iconColor: {
        type: String,
        default: null,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .integration-halo {
    border: $skyBlueGrey500 1px solid;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
</style>
