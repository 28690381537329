<template>
  <portal
    to="modal"
    :disabled="!isOpen"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isOpen"
      class="ar-change-payment-source-modal"
      hide-header
      hide-footer
      width="400px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-5',
        ]"
      >
        <div
          class="title-section"
        >
          <ar-icon
            v-bind="planIconProps"
          />
          <ar-text
            size="md"
            weight="bold"
            text="Change Payment Method"
            class="u-margin-top-7"
          />
          <ar-text
            size="xs"
            :text="description"
            :style="{
              color: $arStyle.color.skyBlueGrey700,
            }"
            class="u-margin-top-2"
          />
        </div>
        <am2-payment-source-section
          class="u-margin-top-9"
          :payment-source-puid="selectedPaymentSourcePuid"
          add-payment-source-on="expansion"
          keep-card-list-open
          @select="handlePaymentSourceSelect"
          @addPaymentSourceToggle="handleAddPaymentSourceToggle"
        />
        <div
          v-if="!isAddingPaymentSource"
          class="u-margin-top-7"
        >
          <ar-text
            size="xs"
            text="Once changed, future payments for this product will be charged to the selected card"
            multiple-lines
            align="center"
          />
          <ar-simple-button
            type="gradient-purple"
            text="Change Payment Method"
            class="u-width-100-percent u-margin-top-5"
            :loading="isChangingPaymentSource"
            @click="handleChangePaymentMethodClick"
          />
        </div>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { hexToRGBA } from '@/utils/helpers/';

export default {
  name: 'ChangePaymentSourceModal',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    paymentPlan: {
      type: Object,
      default: () => null,
    },
    paymentSubscriptions: {
      type: Array,
      default: () => [],
    },
    paymentSourcePuid: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      selectedPaymentSourcePuid: null,
      selectedPaymentSourceOid: null,
      isAddingPaymentSource: false,
      isChangingPaymentSource: false,
    }
  },
  computed: {
    planIconProps() {
      const iconName = this.paymentPlan && this.paymentPlan.additionalInfo.metaData.presentation ? this.paymentPlan.additionalInfo.metaData.presentation.iconName : null;
      return {
        name: iconName,
        color: this.$arStyle.color[iconName],
        width: '30px',
        height: '30px',
        wrapperStyle: {
          display: 'inline-flex',
          alignItem: 'center',
          justifyContent: 'center',
          width: '60px',
          height: '60px',
          border: `1px solid ${this.$arStyle.color.skyBlueGrey400}`,
          boxShadow: `0 0 15px 0 ${hexToRGBA(this.$arStyle.color.blueGrey800, 0.1)}`,
          borderRadius: '50%',
          background: 'white',
        },
      };
    },
    description() {
      if (!this.paymentPlan) {
        return null;
      }
      return `for ${this.paymentPlan.additionalInfo.invoiceName || this.paymentPlan.name}`;
    },
  },

  watch: {
    isOpen(val) {
      if (val) {
        this.selectedPaymentSourcePuid = this.paymentSourcePuid;
      }
    },
  },

  methods: {
    ...mapActions([
      'UPDATE_PAYMENT_SUBSCRIPTION',
    ]),
    async changePaymentMethod(paymentSubscription) {
      try {
        await this.UPDATE_PAYMENT_SUBSCRIPTION({
          subscriptionOid: paymentSubscription.oid,
          paymentSourceOid: this.selectedPaymentSourceOid,
          planQuantity: paymentSubscription.additionalInfo.planQuantity,
          planOid: this.paymentPlan.oid,
        });

        this.$arNotification.push({
          type: 'success',
          message: 'Successfully changed payment method',
        });
        this.$emit('change');
      } catch (error) {
        console.error(error);
        if(error.response && error.response.status === 422) {
          this.$arNotification.push({
            type: 'error',
            message: 'Your selected payment method has been declined. Please update it and try again',
          });
        } else {
          this.$arNotification.push({
            type: 'error',
            message: 'Failed to change payment method',
          });
        }

      }
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
    handlePaymentSourceSelect({ puid, oid }) {
      this.selectedPaymentSourcePuid = puid;
      this.selectedPaymentSourceOid = oid;
    },
    handleAddPaymentSourceToggle(val) {
      this.isAddingPaymentSource = val;
    },
    async handleChangePaymentMethodClick() {
      // Could be multiple subscriptions, so let's use Promise.all
      const promises = this.paymentSubscriptions.map(paymentSubscription => {
        return this.changePaymentMethod(paymentSubscription);
      });

      try {
        this.isChangingPaymentSource = true;
        await Promise.all(promises);
      } finally {
        this.isChangingPaymentSource = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-change-payment-source-modal {
  .modal-body {
    padding: 40px;
    .title-section {
      display: flex;
      flex-flow: column;
      align-items: center;
    }
  }
}
</style>
