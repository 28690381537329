<template>
  <div
    :class="[
      'no-content-section-wrapper',
      type,
    ]"
  >
    <div class="top-right-section">
      <slot name="top-right" />
    </div>
    <ar-icon
      v-if="iconName"
      v-bind="iconPropsDecorated"
      :name="iconName"
    />
    <ar-text
      v-if="header"
      ref="header"
      :text="header"
      allow-html
      multiple-lines
      :size="headerSize"
      align="center"
      :style="{
        marginTop: iconName ? '24px' : null,
      }"
    />
    <ar-text
      v-if="body"
      ref="body"
      :text="body"
      allow-html
      multiple-lines
      size="xs"
      align="center"
      :style="{
        color: $arStyle.color.skyBlueGrey700,
        marginTop: '13px'
      }"
      @anchorClick="(idx) => handleTypographyAnchorClick('body', idx)"
    />
    <ar-simple-button
      v-if="buttonText"
      v-bind="decoratedButtonProps"
      class="u-margin-top-7"
      :style="{
        marginTop: body ? '30px' : '41px',
      }"
      :text="buttonText"
      @click="handleButtonClick"
    />
    <div v-if="$slots.footer" class="footer-section">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import { mergeObjects } from '@/utils/helpers/';

export default {
  name: 'NoContentSection',

  props: {
    type: {
      type: String,
      default: 'white',
      validator: (val) => ['white', 'grey'].indexOf(val) > -1,
    },
    iconName: {
      type: String,
      default: null,
    },
    iconProps: {
      type: Object,
      default: () => ({}),
    },
    enableSectionClick: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: null,
    },
    headerSize: {
      type: String,
      default: 'md',
      validator: (val) => ['md', 'sm'].indexOf(val) > -1,
    },
    body: {
      type: String,
      default: null,
    },
    footer: {
      type: String,
      default: null,
    },
    buttonText: {
      type: String,
      default: null,
    },
    buttonProps: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    iconPropsDecorated() {
      return mergeObjects({
        width: '80px',
      }, this.iconProps);
    },
    decoratedButtonProps() {
      return mergeObjects({
        iconName: 'circle-plus',
        iconDistance: '9px',
      }, this.buttonProps);
    },
  },

  methods: {
    handleButtonClick() {
      this.$emit('buttonClick');
    },
    handleTypographyAnchorClick(section, anchorIndex) {
      this.$emit(`${section}LinkClick`, anchorIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
.no-content-section-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 44px 55px;
  border: 2px solid white;
  background: white;
  border-radius: 4px;
  box-shadow: 2px 5px 10px 0 rgba(67,81,107,0.05);

  .top-right-section {
    position: absolute;
    top: 15px;
    right: 16px;
    display: inline-block;
  }

  .footer-section {
    width: 100%;
    margin-top: 48px;
  }
}
</style>
