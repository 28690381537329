var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    'wrapper',
    'content',
    'content--centered',
    _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
    _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[(_vm.showOrganizationsSelectorModal)?_c('am2-multi-select-modal',{attrs:{"title":"Select your organizations","subtitle":"Eventbrite Organizations","description":"Select the Eventbrite organizations you'd like to sync data from","is-show":_vm.showOrganizationsSelectorModal,"items":_vm.availableOrganizationsForModal,"value":_vm.newConnectedOrganizationIds},on:{"input":_vm.handleOrganizationsSelection,"close":_vm.handleOrgSelectClose}}):_vm._e(),_vm._v(" "),_c('am2-step-link',{staticClass:"step-back-link",attrs:{"text":"Back to integrations"},on:{"click":_vm.handleBackLinkClick}}),_vm._v(" "),_c('div',{class:[
      'title-section',
      _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ]},[_c('div',{staticClass:"title-section-left"},[_c('am2-icon-title-section',{attrs:{"title":"Eventbrite","description":"Automatically sync your ticket sales, events & attendees from Eventbrite","icon-props":{
          name: 'eventbrite',
          color: null,
          height: '40px',
          width: '41px',
        }}})],1),_vm._v(" "),(!_vm.loadingOrganizations && _vm.integrationsSummary.length > 0)?_c('div',{staticClass:"title-section-right"},[(_vm.integrations && _vm.integrationOid)?_c('IntegrationAccountSelect',{style:({ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }),attrs:{"default-selected-integration-oid":_vm.integrationOid,"available-integrations":_vm.integrationsSummary},on:{"select":_vm.handleIntegrationChange,"new-account":_vm.handleAddNewIntegration}}):_vm._e(),_vm._v(" "),_c('am2-expand-button-dropdown',{attrs:{"align":"left","button-props":{ iconName: 'settings' },"items":[
          {
            name: 'Open Eventbrite',
            value: 'goToEventbriteSite',
          },
          {
            name: 'Remove Account',
            value: 'removeIntegration',
          } ]},on:{"select":_vm.handleSettingOptionSelect}})],1):_vm._e()]),_vm._v(" "),(_vm.isConnectingAnotherAccount || _vm.loadingOrganizations)?_c('section',{staticClass:"integration-loading-container"},[_c('am2-loading-bubble')],1):_c('section',[_c('section',[_c('am2-tabs',{staticClass:"tab-options",attrs:{"items":[
          { title: 'Organizations' },
          { title: 'Status' } ],"selected-tab-index":_vm.selectedTabIndex},on:{"select":function (selected, idx) { return _vm.selectedTabIndex = idx; }}}),_vm._v(" "),(_vm.selectedTabIndex === 0)?_c('div',{staticClass:"organizations-container"},[_c('div',{staticClass:"integration-errors-container"},_vm._l((_vm.integrationErrors),function(error,idx){return _c('ar-snackbar',{key:idx,staticClass:"u-margin-bottom-6",style:({ width: '100%' }),attrs:{"type":"error","message":error.userErrorString}})}),1),_vm._v(" "),_c('ar-text',{staticClass:"header",attrs:{"text":"Connected organizations","size":"xs","weight":"bold"}}),_vm._v(" "),(_vm.newConnectedOrganizationIds.length === 0)?_c('am2-card-container',{staticClass:"no-connected-orgs"},[_c('am2-heading',{staticClass:"header",attrs:{"size":_vm.$arMediaQuery.pageContent.minWidth('sm') ? 'lg' : 'md',"type":"h2","title":"No organizations have been selected"}}),_vm._v(" "),_c('p',{staticClass:"subtitle"},[_vm._v("\n            Select the Eventbrite organizations you'd like to sync events & orders from\n          ")])],1):_c('div',_vm._l((_vm.newConnectedOrganizations),function(org){return _c('div',{key:org.id,class:[
              'org-list-item',
              _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[_c('div',{staticClass:"name-container"},[_c('ar-avatar',{style:({
                  minWidth: '40px',
                }),attrs:{"name":org.name,"image-path":org.image ? org.image.url : null}}),_vm._v(" "),_c('ar-text',{staticClass:"label",attrs:{"text":org.name,"size":"xs","multiple-lines":""}})],1),_vm._v(" "),_c('div',[(!!org.error)?_c('am2-tag',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                  content: org.error.userErrorString ? org.error.userErrorString : null
                }),expression:"{\n                  content: org.error.userErrorString ? org.error.userErrorString : null\n                }",modifiers:{"top":true}}],staticClass:"u-margin-right-3",attrs:{"type":"red","text":"Error","text-size":"xxxs"}}):_vm._e(),_vm._v(" "),_c('ar-simple-button',{staticStyle:{"height":"40px"},attrs:{"text":"Remove","outlined":""},on:{"click":function () { return _vm.handleOrganizationRemove(org.id); }}})],1)])}),0),_vm._v(" "),(_vm.unconnectedOrganizations.length > 0)?_c('div',{staticStyle:{"margin-top":"30px"}},[_c('ar-text',{staticClass:"header",attrs:{"text":"Eventbrite organizations","size":"xs","weight":"bold"}}),_vm._v(" "),_c('div',_vm._l((_vm.unconnectedOrganizations),function(org){return _c('div',{key:org.id,staticClass:"org-list-item"},[_c('div',{staticClass:"name-container"},[_c('ar-avatar',{style:({
                    minWidth: '40px',
                  }),attrs:{"name":org.name,"image-path":org.image ? org.image.url : null}}),_vm._v(" "),_c('ar-text',{staticClass:"label",attrs:{"text":org.name,"size":"xs","multiple-lines":""}})],1),_vm._v(" "),_c('div',[(!!org.error)?_c('am2-tag',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                    content: org.error.userErrorString ? org.error.userErrorString : null
                  }),expression:"{\n                    content: org.error.userErrorString ? org.error.userErrorString : null\n                  }",modifiers:{"top":true}}],staticClass:"u-margin-right-3",attrs:{"type":"red","text":"Error","text-size":"xxxs"}}):_vm._e(),_vm._v(" "),_c('ar-simple-button',{staticStyle:{"height":"40px"},attrs:{"text":"Add organization"},on:{"click":function () { return _vm.handleOrganizationAdd(org.id); }}})],1)])}),0)],1):_vm._e()],1):_c('div',[_c('div',{staticClass:"integration-errors-container"},_vm._l((_vm.integrationErrors),function(error,idx){return _c('ar-snackbar',{key:idx,staticClass:"u-margin-bottom-3",style:({ width: '100%' }),attrs:{"type":"error","message":error.userErrorString}})}),1),_vm._v(" "),_c('am2-card-container',{staticClass:"sync-block-container"},[(_vm.isFetchingIntegrationTask)?_c('am2-loading-section',{style:({
            height: '475px',
          })}):(_vm.isSyncing)?_c('div',{staticClass:"sync-block"},[_c('am2-loading-bubble',{staticClass:"sync-animation"}),_vm._v(" "),_c('div',{staticClass:"text-block"},[_c('am2-heading',{staticClass:"header",attrs:{"size":_vm.$arMediaQuery.pageContent.minWidth('sm') ? 'lg' : 'md',"type":"h2","title":_vm.blockTitle}}),_vm._v(" "),_c('p',{staticClass:"subtitle"},[_vm._v("\n                Syncing the events & orders from Eventbrite may take some time.\n              ")]),_vm._v(" "),_c('div',{staticClass:"button-block"},[_c('ar-simple-button',{staticClass:"button",attrs:{"text":"Go to events"},on:{"click":function () { _vm.$router.push({ path: '/events' }) }}}),_vm._v(" "),_c('ar-simple-button',{staticClass:"button sync-btn",attrs:{"text":"Stop sync","outlined":""},on:{"click":function () { return _vm.syncAction('stop'); }}})],1)],1)],1):_c('div',{staticClass:"sync-block"},[_c('ar-icon',{staticClass:"eventbrite-tick-icon",attrs:{"name":"event-sync-confirmed"}}),_vm._v(" "),_c('div',{staticClass:"text-block"},[_c('am2-heading',{staticClass:"header",attrs:{"size":_vm.$arMediaQuery.pageContent.minWidth('sm') ? 'lg' : 'md',"type":"h2","title":"Sync has been completed"}}),_vm._v(" "),_c('p',{staticClass:"subtitle"},[_vm._v("\n                Your events and attendees have been synced from Eventbrite\n              ")]),_vm._v(" "),_c('div',{staticClass:"button-block"},[_c('ar-simple-button',{staticClass:"button",attrs:{"text":"Go to events"},on:{"click":function () { _vm.$router.push({ path: '/events' }) }}}),_vm._v(" "),_c('ar-simple-button',{staticClass:"button sync-btn",style:({
                    backgroundColor: _vm.integrationFailed ? _vm.$arStyle.color.skyBlueGrey400 : '#fff0',
                  }),attrs:{"text":"Sync now","outlined":"","loading":_vm.actionLoading,"disabled":_vm.integrationFailed},on:{"click":function () { return _vm.syncAction('start'); }}})],1),_vm._v(" "),(_vm.hasSyncedBefore)?_c('div',{staticClass:"last-sync-time"},[_vm._v("\n                Last synced "+_vm._s(_vm.timeSinceLastSync)+"\n              ")]):_vm._e()],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }