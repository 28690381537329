var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"ar-segment-dropdown"},[_c('am2-edit-segment-or-tag-modal',{attrs:{"is-show":_vm.displayEditSegmentModal,"title":"Edit Segment","input-label":"Segment name","delete-button-text":"Delete Segment","default-name":_vm.savedFilterToEdit ? _vm.savedFilterToEdit.name : '',"is-deleting":_vm.isDeletingSegment,"is-renaming":_vm.isPatchingSegment},on:{"close":function($event){_vm.displayEditSegmentModal = false},"delete":function($event){return _vm.handleDeleteSegment(_vm.savedFilterToEdit)},"rename":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleSegmentRename.apply(void 0, [ _vm.savedFilterToEdit ].concat( argsArray ))}}}),_vm._v(" "),_c('am2-edit-segment-or-tag-modal',{attrs:{"is-show":_vm.displayEditTagModal,"title":"Edit Tag","input-label":"Tag name","delete-button-text":"Delete Tag","default-name":_vm.tagToEdit ? _vm.tagToEdit.name : '',"is-deleting":_vm.isDeletingTag,"is-renaming":_vm.isPatchingTag},on:{"close":function($event){_vm.displayEditTagModal = false},"delete":function($event){return _vm.handleDeleteTag(_vm.tagToEdit)},"rename":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleTagRename.apply(void 0, [ _vm.tagToEdit ].concat( argsArray ))}}}),_vm._v(" "),_c('am2-link-button-dropdown',{attrs:{"items":_vm.items,"loading":_vm.isDropdownLoading,"tabs":_vm.tabs,"tab-key":_vm.selectedTab.key,"placeholder":_vm.placeholder,"dropdown-style":{
      width: _vm.size === 'small' ? '300px' : '400px',
    },"search-placeholder":("Search " + (_vm.selectedTab.key)),"has-search":"","no-option-text":_vm.noOptionText,"data-test-id":"segments-link-button-dropdown"},on:{"tabSelect":_vm.handleTabSelect,"select":_vm.handleFilterSelect,"searchStringChange":_vm.handleSearchStringChange,"controlClick":_vm.handleControlClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }