<template>
  <section class="campaign-edit-invite">
    <ar-text
      class="step-heading"
      size="xs"
      text="STEP 5"
      :style="{
        color: '#9FA8B5',
        marginBottom: '8px',
      }"
    />
    <am2-heading
      type="h1"
      size="lg"
      title="Social: Invite friends"
      :style="{marginBottom: '38.5px'}"
    />

    <p class="large smaller-margins">
      {{copy}}
    </p>

    <am2-field
      name="social-invite-friends"
      :class="[
        'social-invite-friends',
        $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
      ]"
    >
      <div
        v-for="(channel, index) in campaignViewModel.inviteChannels"
        :key="channel.key"
      >
        <ar-checkbox
          v-model="channel.selected"
          :icon-name="channel.iconName"
          :icon-props="{ color: channel.iconColor }"
          :label="channel.name"
          :comment="channel.mandatory ? 'Mandatory' : null"
          align="right"
          :disabled="channel.mandatory || false"
          :style="{ width: '100%', height: '52px' }"
        />
        <ar-divider
          v-if="index !== campaignViewModel.inviteChannels.length - 1"
        />
      </div>

    </am2-field>
  </section>
</template>

<script>
import { copyGenerator } from '@/utils/campaign';

export default {
  name: 'EditInvite',

  data: () => ({
    campaignViewModel: {
      inviteChannels: {}
    }
  }),

  props: {
    editCampaign: {
      type: Object
    },
    setEditCampaign: {
      type: Function
    }
  },

  watch: {
    campaignViewModel: {
      handler(value) {
        const { inviteChannels } = value
        const inviteMap = inviteChannels.reduce((accum, item, index) => {
          accum[item.key] = item.selected
          return accum
        }, {})

        const socialActions = {
          invites: inviteMap
        }

        this.setEditCampaign({ socialActions });
      },
      deep: true
    }
  },

  computed: {
    copy() {
      return copyGenerator('invite', this.editCampaign.type);
    },
  },

  mounted() {
    this.initializeCampaignViewModel();
  },

  methods: {
    initializeCampaignViewModel() {
      const invites = this.editCampaign.socialActions ? this.editCampaign.socialActions.invites || {} : {};

      this.campaignViewModel = {
        inviteChannels: [
        {
            name: 'Email',
            key: 'email',
            iconName: 'email',
            iconColor: this.$arStyle.color.email,
            selected: invites.email !== false, // default true,
            mandatory: true,
          },
          {
            name: 'Copy URL',
            key: 'copy',
            iconName: 'link',
            iconColor: null,
            selected: invites.link !== false, // default true,
            mandatory: true,
          },
          {
            name: 'Facebook Messenger',
            key: 'messenger',
            iconName: 'messenger',
            iconColor: this.$arStyle.color.messenger,
            selected: invites.messenger !== false, // default true,
          },
          {
            name: 'Instagram Direct Message',
            key: 'instagram',
            iconName: 'instagram-direct-message',
            iconColor: this.$arStyle.color.instagramDirectMessage,
            selected: invites.instagram !== false, // default true,
          },
          {
            name: 'WhatsApp',
            key: 'whatsapp',
            iconName: 'whatsapp',
            iconColor: this.$arStyle.color.whatsapp,
            selected: invites.whatsapp !== false, // default true,
          },
          {
            name: 'SMS',
            key: 'sms',
            iconName: 'sms',
            iconColor: this.$arStyle.color.sms,
            selected: invites.sms !== false, // default true,
          },
        ],
      }
    },
  },
};
</script>


<style lang="scss">
  @import '../../../assets/styles/base/mixins';
.campaign-edit-invite {
  .social-invite-friends {
    border-radius: 4px;
    padding: 1rem 3rem;
    border: 1px solid $blueGrey500;

    &.xs-max {
      padding: 18px 12px;
    }
  }
  .smaller-margins {
    margin-bottom: -15px;
  }
}
</style>
