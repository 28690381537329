<template>
  <div :class="['ar-field', asRow && 'as-row']">
    <label
      :for="name"
      :class="['ar-field__label', asRow && 'col-lg-3 col-md-12']"
    >
      {{ label }}
    </label>
    <div :class="['ar-field__body', asRow && 'col-lg-9 col-md-12']">
      <ar-link-button
        v-if="collapsible"
        :text="description ? description : 'Show/Hide'"
        :style="{
          margin: '0 0 15px',
          userSelect: 'none',
        }"
        :active="!collapsed"
        @click="handleCollapseToggle"
      />
      <ar-text
        v-else-if="description"
        size="xs"
        multiple-lines
        :text="description"
        :style="{
          margin: '0 0 15px',
        }"
      />
      <slot
        v-if="!collapsed"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Field',
  props: {
    asRow: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    collapsible: {
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleCollapseToggle() {
      this.$emit('collapseToggle');
    },
  }
};
</script>

<style lang="scss" scoped>
.ar-field {
  margin-top: 44px;

  &.as-row {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .ar-field__body {
    padding: 0;
  }

  .ar-field__label {
    line-height: 1.3;
    font-weight: bold;
    margin-bottom: 12px;
    display: block;
    padding: 0;
  }
}
</style>
