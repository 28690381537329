<template>
  <portal
    to="modal"
    :disabled="!opened"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="opened"
      class="select-email-template-modal"
      header="Select a template"
      width="1200px"
      height="100%"
      hide-footer
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        ref="body-section"
        class="body-section"
        :style="{
          minHeight: $arMediaQuery.window.maxWidth('xs') ? '100%' : '500px',
        }"
      >
        <am2-tabs
          :selected-tab-index="selectedTemplateSourceIndex"
          @select="handleTemplateSourceSelect"
          :items="templateSourceItems"
        />
        <ar-divider />
        <am2-no-content-section
          v-if="hasNoEmailTemplates"
          header="No templates found"
          header-size="sm"
          :style="{
            marginTop: '22px',
          }"
        />
        <div
          :class="[
            'templates-wrapper',
            $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
          ]"
        >
          <am2-template-card
            v-for="emailTemplate of emailTemplates"
            ref="template-card"
            :key="emailTemplate.oid"
            v-show="selectedTemplateSource === 'bucket'"
            :class="[
              'template-card',
              $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
              $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
              $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
            ]"
            :name="emailTemplate.meta.name"
            :description="emailTemplate.meta.description"
            :template="emailTemplate.meta.template"
            :template-html="getEmailTemplateHtml(emailTemplate)"
            :type="emailTemplate.meta.templateType"
            :has-dropdown="false"
            :disabled="!getEmailTemplateHtml(emailTemplate)"
            @click="handleTemplateCardClick(emailTemplate, getEmailTemplateHtml(emailTemplate))"
          />
          <am2-template-card
            v-for="emailTemplate of unlayerTemplates"
            ref="template-card"
            :key="emailTemplate.id"
            v-show="selectedTemplateSource === 'unlayer'"
            :class="[
              'template-card',
              $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
              $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
              $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
            ]"
            :name="emailTemplate.name"
            :template="emailTemplate.design"
            :template-html="getUnlayerHtml(emailTemplate.design)"
            type="unlayer"
            :has-dropdown="false"
            :disabled="!getUnlayerHtml(emailTemplate.design)"
            @click="handleUnlayerTemplateCardClick(emailTemplate, getUnlayerHtml(emailTemplate.design))"
          />
          <am2-card-skeleton
            v-for="n in skeletonCardNumber"
            :key="n"
            type="email-template"
            :class="[
              'template-card',
              $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
              $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
              $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
            ]"
          />
        </div>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';

export default {
  name: 'SelectEmailTemplateModal',
  components: {},
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasFetchedTemplates: false,
      templateSourceItems: [
        {
          title: 'Your templates',
          value: 'bucket',
        },
        {
          title: 'Library',
          value: 'unlayer',
        },
      ],
      selectedTemplateSource: 'bucket',
    };
  },

  computed: {
    ...mapState({
      unlayerTemplates: state => state.emailTemplate.unlayerTemplates,
      isFetchingUnlayerTemplates: state => state.emailTemplate.isFetchingUnlayerTemplates,
      emailTemplates: state => state.emailTemplate.emailTemplates,
      isFetchingEmailTemplates: state => state.emailTemplate.isFetchingEmailTemplates,
    }),
    ...mapGetters({
      getUnlayerHtml: 'emailTemplate/getUnlayerHtml',
    }),
    hasNoEmailTemplates() {
      return this.emailTemplates.length === 0
        && !this.isFetchingEmailTemplates
        && this.selectedTemplateSource === 'bucket';
    },
    selectedTemplateSourceIndex() {
      return this.templateSourceItems.findIndex(item => item.value === this.selectedTemplateSource);
    },
    skeletonCardNumber() {
      if (this.selectedTemplateSource === 'bucket' && !this.isFetchingEmailTemplates) {
        return 0;
      }
      if (this.selectedTemplateSource === 'unlayer' && !this.isFetchingUnlayerTemplates) {
        return 0;
      }
      // Add number of skeleton cards to fill a new row the current row
      if (this.$arMediaQuery.pageContent.maxWidth('xs')) {
        return 3;
      } else if (this.$arMediaQuery.pageContent.maxWidth('md')) {
        return 6 + this.emailTemplates.length % 2;
      } else if (this.$arMediaQuery.pageContent.maxWidth('lg')) {
        return 9 + this.emailTemplates.length % 3;
      } else {
        return 12 + this.emailTemplates.length % 4;
      }
    },
  },

  watch: {
    opened(isOpened) {
      if (isOpened && !this.hasFetchedTemplates) {
        this.hasFetchedTemplates = true;
        this['emailTemplate/FETCH_ALL_EMAIL_TEMPLATES']();
        this['emailTemplate/FETCH_UNLAYER_TEMPLATES']();
      }
    },
  },

  methods: {
    ...mapActions([
      'emailTemplate/FETCH_ALL_EMAIL_TEMPLATES',
      'emailTemplate/FETCH_UNLAYER_TEMPLATES',
    ]),
    getEmailTemplateHtml(emailTemplate) {
      if (emailTemplate.meta.templateType === 'unlayer') {
        return this.getUnlayerHtml(emailTemplate.meta.template);
      }
      return emailTemplate.meta.template;
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
    handleTemplateCardClick(emailTemplate, emailHtml) {
      this.$emit(
        'select',
        emailTemplate.meta.template,
        emailTemplate.meta.templateType,
        emailHtml,
      );
    },
    handleUnlayerTemplateCardClick(emailTemplate, emailHtml) {
      this.$emit(
        'select',
        emailTemplate.design,
        'unlayer',
        emailHtml,
      );
    },
    handleTemplateSourceSelect(templateTypeItem) {
      this.selectedTemplateSource = templateTypeItem.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-email-template-modal {
  .body-section {
    background: $skyBlueGrey300;
    padding: 30px 40px;
    .templates-wrapper {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 22px;
      margin-right: -19px;

      &.sm-max {
        padding: 20px;
      }

      .template-card {
        flex-grow: 0;
        width: calc(25% - 20px);
        margin-right: 20px;
        margin-bottom: 20px;
        &.lg-max {
          width: calc(33.3% - 20px);
          margin-right: 20px;
        }

        &.md-max {
          width: calc(50% - 20px);
          margin-right: 20px;
        }

        &.xs-max {
          width: 100%;
        }
      }
    }
  }
}
</style>
