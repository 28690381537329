import { AdvancedMessageListTargeting } from '@/store/modules/messageList/types';
import { RecipientFilter } from '@/store/modules/message/types';
import { clone } from '@/utils/helpers';

export const getInitialAdvancedMessageListTargeting = function (): AdvancedMessageListTargeting {
  return {
    type: 'all',
    condition: 'include',
    subCondition: null,
    values: [],
  }
};

export const convertTargetingToFilterExpression =
  function(currentTargetingType:string | null,
           currentTargetingCondition:string | null,
           currentTargetingSubCondition:string | null,
           currentTargetingValues:string[]):RecipientFilter | null {

  if (currentTargetingType === 'all') return null;
  if (!currentTargetingType ||
      !currentTargetingCondition ||
      // !currentTargetingSubCondition ||   // Purposefully commenting this out as the subcondition may be null
      !currentTargetingValues ||
      currentTargetingValues.length === 0) {
    return null;
  }
  if (["include", "exclude"].indexOf(currentTargetingCondition) < 0) {
    return null;
  }

  let conditionSearchPickerValue;

  // If a subcondition is present, then use that. Otherwise, infer a value from the condition.
  if (currentTargetingSubCondition) {
    conditionSearchPickerValue = currentTargetingSubCondition;
  } else {
    conditionSearchPickerValue = currentTargetingCondition === 'include' ? 'true_to_all' : 'false_to_all';
  }
  const anyOrAll = ["true_to_any", "false_to_any"].indexOf(conditionSearchPickerValue) > -1 ? "any" : "all";

  switch(currentTargetingType) {
    case "tag":
      return {
        "conditions": [{
          "data": {
            "condition": conditionSearchPickerValue,
            "values": clone(currentTargetingValues),
          },
          "name": "tags",
          "type": "condition-search-picker",
        }],
        "logic": [],
      };
    case "events":
      return {
        "conditions": [{
          "data": {
            "condition": conditionSearchPickerValue,
            "values": clone(currentTargetingValues),
          },
          "name": "events",
          "type": "condition-search-picker",
        }],
        "logic": [],
      };
    case "campaigns":
      return {
        "conditions": [{
          "data": {
            "condition": conditionSearchPickerValue,
            "values": clone(currentTargetingValues),
          },
          "name": "campaigns",
          "type": "condition-search-picker",
        }],
        "logic": [],
      };
    case "segment":
      return null; // We'll be adding this at a later date
    case "recent_send":
      return null; // We'll be adding this at a later date
    case "messages_clicked":

      // @ts-ignore
      return {
        "conditions": [{
          "data": {
            "condition": conditionSearchPickerValue,
            "values": clone(currentTargetingValues),
          },
          "name": "messages_clicked",
          "type": "condition-search-picker",
        },{
          "data": {
            "condition": anyOrAll === "any" ? "true_to_any" : "true_to_all", // This ensures that the messages being tested were also received by the fan
            "values": clone(currentTargetingValues),
          },
          "name": "messages_sent",
          "type": "condition-search-picker",
        }],
        "logic": ["and"],
      };
    case "messages_opened":
      // @ts-ignore
      return {
        "conditions": [{
          "data": {
            "condition": conditionSearchPickerValue,
            "values": clone(currentTargetingValues),
          },
          "name": "messages_opened",
          "type": "condition-search-picker",
        },{
          "data": {
            "condition": anyOrAll === "any" ? "true_to_any" : "true_to_all", // This ensures that the messages being tested were also received by the fan
            "values": clone(currentTargetingValues),
          },
          "name": "messages_sent",
          "type": "condition-search-picker",
        }],
        "logic": ["and"],
      };
    default:
      return null;
  }
};

