<template>
  <am2-dropdown-binder
    :align="align"
    button-name="ar-simple-button"
    :button-props="buttonProps"
    :dropdown-props="{
      items,
      itemStyle: { padding: '9px 15px' },
      hasSearch,
      maxHeight: dropdownMaxHeight,
      style: dropdownStyle,
      placeholder: searchPlaceholder,
    }"
    @select="handleItemSelect"
    @searchStringChange="handleSearchStringChange"
    @button-click="$emit('button-click', $event)"
  />
</template>
<script>
export default {
  name: 'AvatarButtonDropdown',
  props: {
    align: {
      type: String,
      default: 'left',
      validator: (val) => ['left', 'right'].indexOf(val) > -1,
    },
    items: {
      type: Array,
      default: () => [],
    },
    buttonProps: {
      type: Object,
      default: () => ({}),
    },
    dropdownStyle: {
      type: Object,
      default: () => ({}),
    },
    dropdownMaxHeight: {
      type: String,
      default: null,
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: null,
    },
  },
  methods: {
    handleItemSelect(item) {
      this.$emit('select', item);
    },
    handleSearchStringChange(newSearchString) {
      this.$emit('searchStringChange', newSearchString);
    },
  },
}
</script>

