<template>
  <SimpleCardSkeleton
    :show-as="showAs"
    :level="level"
    v-if="type === 'simple'" />
  <SignupFormCardSkeleton
    :show-as="showAs"
    :level="level"
    v-else-if="type === 'signup-form'" />
  <MessageListCardSkeleton
    :show-as="showAs"
    :level="level"
    v-else-if="type === 'message-list'" />
  <CampaignCardSkeleton
    :show-as="showAs"
    :level="level"
    v-else-if="type === 'campaign'" />
  <EventCardSkeleton
    :show-as="showAs"
    :level="level"
    v-else-if="type === 'event'" />
  <ActivityCardSkeleton
    :show-as="showAs"
    :level="level"
    v-else-if="type === 'activity'" />
  <EmailTemplateCardSkeleton
    :show-as="showAs"
    :level="level"
    v-else-if="type === 'email-template'" />
</template>

<script>
import CampaignCardSkeleton from './campaign-card-skeleton/CampaignCardSkeleton';
import EventCardSkeleton from './event-card-skeleton/EventCardSkeleton';
import SimpleCardSkeleton from './simple-card-skeleton/SimpleCardSkeleton';
import SignupFormCardSkeleton from './signup-form-card-skeleton/SignupFormCardSkeleton';
import MessageListCardSkeleton from './message-list-card-skeleton/MessageListCardSkeleton';
import ActivityCardSkeleton from './activity-card-skeleton/ActivityCardSkeleton';
import EmailTemplateCardSkeleton from './email-template-card-skeleton/';

export default {
  name: 'CardSkeleton',

  components: {
    CampaignCardSkeleton,
    SimpleCardSkeleton,
    SignupFormCardSkeleton,
    EventCardSkeleton,
    MessageListCardSkeleton,
    ActivityCardSkeleton,
    EmailTemplateCardSkeleton,
  },

  props: {
    type: {
      type: String,
      default: 'simple',
      validator: (value) =>
        ['simple', 'campaign', 'event', 'signup-form', 'message-list', 'activity', 'email-template'].indexOf(value) !== -1,
    },
    showAs: {
      type: String,
      default: 'list',
      validator: (value) =>
        ['list', 'tiles'].indexOf(value) !== -1,
    },
    level: {
      type: Number,
      default: 1,
      validator: (val) => val > 0,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
