<template>
  <div class="action-item-container" :data-parent-outbox-id="action.parentOutBoxId" :data-block-id="action.block.id">
    <div
      :id="action.block.id"
      class="action-item-wrapper border-green"
      :class="isActiveElement ? 'active' : ''"
      :data-key="action.block.key"
      @click="handleActionClick(action.block.id, $event)"
    >
      <div id="action-close" class="action-close-icon-wrapper" @click="handleDelete">
        <CloseCrossIcon class="action-close-icon"/>
      </div>

      <div class="planner-item-container">
        <div class="auto-card-heading">
          <div
            v-html="require(`~/assets/icons/${action.block.icon}.svg?raw`)"
            :class="`auto-card-icon ${action.block.icon}-icon`"
          />
          <ar-text
            class="auto-item-text"
            size="14px"
            :text="action.block.name"
            weight="bold"
            multiple-lines
            :max-lines="1"
            line-height="2.14"
          />
        </div>

        <div class="card-sub-wrapper">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CloseCrossIcon from '~/assets/icons/close-cross-icon.svg?inline'
export default {
  name: 'ActionBlock',
  components: {
    CloseCrossIcon
  },
  props: {
    action: {
      type: Object,
      default: null
    },
  },
  computed: {
    ...mapGetters({
      editableId: 'automation/getEditableId',
    }),
    isActiveElement() {
      return this.editableId === this.action.block.id
    },
  },
  methods: {
    handleActionClick(id, event) {
      this.$emit('click', {id, event})
    },
    handleDelete() {
      this.$emit('action-delete')
    },
  }
}
</script>
<style lang="scss" scoped>
.action-item-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 100%;

  .action-item-wrapper {
    border-radius: 3px;
    border: solid 0.2px rgba(194, 194, 194, 0.3);
    background-color: white;
    padding: 10px 20px;
    width: 325px;
    margin-left: 9px;
    margin-right: 9px;
    cursor: pointer;
    position: relative;
    min-height: 51px;

    &:hover {
      .action-close-icon-wrapper {
        display: flex;
      }
    }

    &.active {
      border: 1px solid $green500;
      box-shadow: 0 0 0 3px $green200;
    }

    &.border-green {
      border-left: 6px solid $green500;
    }

    .action-close-icon-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      padding: 7px;
      display: none;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      .action-close-icon {
        pointer-events: none;
        width: 10px;
        height: 10px;
        color: #a8aebb;
      }
    }

    .planner-item-container {
      pointer-events: none;

      .auto-card-heading {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        pointer-events: none;

        .auto-card-icon {
          margin-right: 8px;
          margin-bottom: 0.2rem;
        }

        .clock-icon {
          width: 1.5rem;
          height: 1.5rem;
        }
        
        .message-icon {
          margin-bottom: 0.1rem;
        }
      }
    }
  }
}
</style>