<template>
  <div
    :class="[
      'drop-target-wrapper',
      setDropTargetHeightClass,
    ]"
    @dragover.prevent="dragOver"
    @dragleave="dragLeave"
    @drop="handleDrop"
    :data-parent-outbox-id="box.parentOutBoxId"
    :style="{height: box.type && '125px'}"
  >
    <div 
      v-if="active || box.type === 'action-prompt'" 
      :id="`prompt-for-${box.parentOutBoxId}`"
      :class="[
        'drop-target-inner',
        active && 'active'
      ]"
      :style="{marginTop: box.type && 'auto'}"
    >
      <ar-text
        class="drop-target-text"
        size="14px"
        :text="box.type ? 'Drag a new action here' : 'Move action to here'"
        weight="normal"
        multiple-lines
        :max-lines="1"
      />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  name: 'DragPlaceholder',
  props: {
    box: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      active: false,
    }
  },
  watch: {
    active() {
      this.$emit('active', this.active)
    }
  },
  computed: {
    ...mapState({
      dragOverId: state => state.automation.dragOverId,
      draggedBox: state => state.automation.draggedBox,
    }),
    ...mapGetters({
      isPendingState: 'automation/isPendingState'
    }),
    setDropTargetHeightClass() {
      if (this.active) {
        return 'h-100'
      } else {
        return 'h-80'
      }
    }
  },
  methods: {
    ...mapActions({
      setDragOverId: 'automation/SET_DRAG_OVER_ID',
      clearDragOverId: 'automation/CLEAR_DRAG_OVER_ID',
      reorderActions: 'automation/REORDER_ACTIONS',
      addAction: 'automation/ADD_ACTION',
      setScrollVals: 'automation/SET_SCROLL_VALS',
    }),
    async handleDrop(e) {
      if (!e.dataTransfer.types.includes('box')) {
        this.active = false
        this.$arNotification.push({ type: 'error', message: 'Triggers must be placed at the top of your Automation' })
        return
      }

      if (this.isPendingState) {
        this.active = false
        this.$arNotification.push({ type: 'error', message: 'Please wait for status to update before modifying Automation' })
        return
      }

      let draggedBox = JSON.parse(e.dataTransfer.getData('box'))
      if (draggedBox.isFromSidebar) {
        this.active = false
        await this.addAction({box: draggedBox, parentId: this.box.parentOutBoxId})
        document.getElementById('editable-wrapper').focus()
      } else {
        let outIds = this.draggedBox.block.key === 'if-else' ? [this.draggedBox.id.outElse, this.draggedBox.id.outThen] : [this.draggedBox.id.out]
        if (outIds.includes(this.box.parentOutBoxId)) {
          this.active = false
          return
        }
        if (this.draggedBox?.block?.id === this.box?.block?.id) {
          this.active = false
          return
        }
        this.active = false
        await this.reorderActions({parentOutboxId: this.dragOverId, box: this.draggedBox})
      }
      this.active = false
    },
    dragOver() {
      if (this.active) return
      if (!this.draggedBox) {
        // dragged from sidebar
        this.active = true
        this.setDragOverId(this.box.parentOutBoxId)
        return
      }

      let outIds = this.draggedBox.block.key === 'if-else' ? [this.draggedBox.id.outElse, this.draggedBox.id.outThen] : [this.draggedBox.id.out]
      if (this.draggedBox.parentOutBoxId === this.box.parentOutBoxId || outIds.includes(this.box.parentOutBoxId)) return
      this.active = true
      this.setDragOverId(this.box.parentOutBoxId)
    },
    dragLeave() {
      if (!this.active) return
      this.clearDragOverId()
      this.active = false
    },
  }
}
</script>
<style lang="scss" scoped>
.drop-target-wrapper {
  width: 325px;
  height: 100px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  transition: height 0.15s ease;

  &.h-100 {
    height: 100px;
  }
  
  &.h-80 {
    height: 80px;
  }

  .drop-target-inner {
    border-radius: 3px;
    border: solid 0.2px #c2c2c2;
    width: 100%;
    padding: 12px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    opacity: 0.3;
    pointer-events: none;
    background: aliceblue;
    box-shadow: 0 2px 6px 0 $shadow;

    &.active {
      opacity: 0.5;
      border: 1px solid $green500;
      box-shadow: 0 0 0 3px $green200;
    }
  }
}
</style>