<template>

  <ar-modal
    :is-open="isVisible"
    class="import-data-modal"
    :header="modalTitle"
    :has-back-link="step > 1"
    :hide-footer="step < 2"
    width="590px"
    :mobile="$arMediaQuery.window.maxWidth('xs')"
    @close="onClose"
    @back="() => step -= 1"
  >
    <!-- BODY -->
    <!-- File upload -->
    <div
      v-if="step===1"
      slot="body"
      :class="[
        'import-data-modal__wrapper',
        $arMediaQuery.window.maxWidth('xs') && 'xs-max',
      ]">
      <am2-dropzone
        :file-type="['text/csv']"
        :file-size="50000000"
        file-type-alias="CSV file"
        :placeholder-icon="{
          name: 'upload',
          width: '16px',
          height: '16px',
          color: $arStyle.color.purple500,
          wrapperStyle: {
            background: mouseOverCsvDropdown ? `white` : $arStyle.color.skyBlueGrey400,
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: mouseOverCsvDropdown ? '2px solid white' : null,
          },
        }"
        :ratio="0.525"
        @upload="handleCSVUploaded"
        @mouseover.native="mouseOverCsvDropdown = true"
        @mouseleave.native="mouseOverCsvDropdown = false"
      />
    </div>
    <!-- CSV Column Values -> Contacts Field mapping -->
    <div v-else-if="step===2" slot="body">
      <div class="mapping-instructions">
        <p>
          Map the fields to import into Audience Republic from your CSV file
        </p>
        <p class="mapping-instructions__subtitle">
          * indicates a compulsory field
        </p>
      </div>
      <div :class="[
        'import-data-modal__wrapper',
        $arMediaQuery.window.maxWidth('xs') && 'xs-max',
      ]">
        <p class="column-text">
          <span>Audience Republic</span><span>CSV File</span>
        </p>

        <div class="form">
          <div v-for="(arTableHeader, index) in arToPromoterMap" :key="index" class="form-row">
            <p class="col-xs-12 col-sm-4 form-row__label">{{ `${arTableHeader.name}${arTableHeader.required ? '*' : ''}` }}
              <ar-icon
              v-if="arTableHeader.hint && $arMediaQuery.window.minWidth('sm')"
              v-tooltip.top="{
                content: arTableHeader.hint,
              }"
              name="tooltip-question-mark"
              class="icon"
              height="15px"
              width="15px"
            /></p>

            <div class="col-xs-12 col-sm-8 form-row__input">
              <ar-simple-select
                class="selector"
                enable-filter
                :items="csvHeadersOptions(arTableHeader.mappedTo)"
                placeholder="Please select..."
                enable-clear
                :default-select-index="arTableHeader.mappedTo === null ? null : 0"
                @select="val => setMapping(val, arTableHeader)"
                @clear="() => setMapping({ index: null }, arTableHeader)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--
      Additional actions to perform once fans
      have been imported:
        1. Add fans to a list
        2. Add tags to fans

     -->
    <div
      v-else-if="step===3"
      slot="body"
      :class="[
        'import-data-modal__wrapper',
        $arMediaQuery.window.maxWidth('xs') && 'xs-max',
      ]">
      <ar-text
        :text="`<b>${numberOfNewFans}</b> contacts have been mapped`"
        size="xs"
        allow-html
      />
      <ar-divider
        :style="{
          margin: '15px 0 20px 0',
          height: '0.5px',
          background: $arStyle.color.$bluegrey500
        }"
      />
      <div class="form-row">
        <ar-text
          text="Add to list"
          size="xs"
          weight="bold"
          :style="{
            marginBottom: '4px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
          }"
          class="col-xs-12 col-sm-4 form-row__label"
        />

        <div class="message-list-select col-xs-12 col-sm-8">
          <am2-message-list-select
            v-model="messageListSettings.oid"
            @select="handleMessageListChange"
            :channels="mobileNumberMapped ? ['email', 'sms'] : ['email']"
            message-list-type="manual"
            :show-stats="false"
            :disabled="!canChangeMessageList"
          />

          <ar-text
            :text="`Imported contacts can only be subscribed to lists with Email${mobileNumberMapped ? ' or SMS' : ''} enabled`"
            size="xxxs"
            multiple-lines
            class="message-list-subtitle"
            line-height="20px"
          />

          <div
            v-show="messageListSettings.oid !== null"
            class="channel-choices"
          >
            <ar-checkbox
              v-show="messageListSettings.listContainsEmail"
              v-model="messageListSettings.emailEnabled"
              class="tick-section-checkbox"
              :disabled="!messageListSettings.emailCanBeEnabled"
              v-tooltip.top="{
                content: messageListSettings.emailCanBeEnabled ? null : `One or more of your imported contacts does not have a valid email address`
              }"
              label="Email"
              icon-name="email"
              :icon-props="{
                width: '18px',
                color: $arStyle.color.email,
              }"
            />
            <ar-checkbox
              v-show="messageListSettings.listContainsSms"
              v-model="messageListSettings.smsEnabled"
              :disabled="!messageListSettings.smsCanBeEnabled"
              v-tooltip.top="{
                content: messageListSettings.smsCanBeEnabled ? null : `One or more of your imported contacts does not have a valid phone number`
              }"
              class="tick-section-checkbox"
              label="SMS"
              icon-name="sms"
              :icon-props="{
                width: '18px',
                color: $arStyle.color.sms,
              }"
            />
          </div>
        </div>

      </div>
      <ar-divider
        :style="{
          margin: '26px 0 21px 0',
          height: '0.5px',
          background: $arStyle.color.$bluegrey500
        }"
      />
      <div class="form-row">
        <ar-text
          text="Add Tags"
          size="xs"
          weight="bold"
          :style="{
            marginBottom: '4px',
            height: '50px'
          }"
          class="col-xs-12 col-sm-4 form-row__label"
        />
        <div class="col-xs-12 col-sm-8">
          <am2-fan-tag-input v-model="newFanTags" />
        </div>
      </div>
    </div>
    <!-- Import complete -->
    <div
      v-else-if="step===4"
      slot="body"
      :class="[
        'import-data-modal__wrapper',
        $arMediaQuery.window.maxWidth('xs') && 'xs-max',
      ]">
      <p class="column-text">
        <span>Audience Republic</span><span>CSV File</span>
      </p>

      <p class="import-data-modal__wrapper__import-message">
        {{ importCompleteMessage }}
      </p>
    </div>

    <!-- FOOTER -->
    <div
      v-if="step >= 2"
      slot="footer"
      :class="[
        'import-data-modal__footer',
        $arMediaQuery.window.maxWidth('xs') && 'u-padding-3',
      ]">
      <!--
        NOTE/RK: Uncomment this when integrations is in production
        <div v-if="step===1">
          <p class="header">Want to easily add your contacts?</p>
          <p class="body">Our integrations help you seamlessly adds your contacts.
            <a
              target="_blank"
              href="https://audiencerepublic.com/audience-manager/audience/">
              Learn more
            </a>
          </p>
        </div>
      -->
      <div>
        <div v-if="error" class="error-message">
          {{ error }}
        </div>
        <div>
          <ar-simple-button
            :text="step === 3 ? 'Import contacts' : 'Continue'"
            :loading="isImportingAudienceCsv"
            @click="handleNextStep"
            :style="{ float: 'right' }"
          />
        </div>
      </div>
    </div>
  </ar-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { initialContactsCSVMap } from '~/utils/constants';
import { formatInteger } from '~/utils/helpers';
export default {
  name: 'ImportContactsModal',

  data() {
    return {
      isVisible: false,
      step: 1,
      type: 'fan',
      arToPromoterMap: initialContactsCSVMap(),
      csvHeaders: [],
      csvRows: [],
      error: '',
      newFanTags: [],
      messageListSettings: {
        oid: null,
        smsCanBeEnabled: false, // Do all items in the contact import satisfy requirements to allow SMS?
        emailCanBeEnabled: false, // Do all items in the contact import satisfy requirements to allow Email?
        smsEnabled: false, // Has SMS been checked?
        emailEnabled: false, // Has Email been checked?
        listContainsSms: false, // Does the list even contain SMS?
        listContainsEmail: false, // Does the list even contain Email?
      },
      mouseOverCsvDropdown: false,
      canChangeMessageList: true,
      onConfirm: () => {},
      onClose: () => {},
    };
  },
  computed: {
    ...mapState({
      isImportingAudienceCsv: state => state.audience.isImportingAudienceCsv,
    }),
    disabledList() {
      return this.arToPromoterMap.filter(a => a.mappedTo !== null);
    },
    importCompleteMessage() {
      return this.$store.state.importMessage;
    },
    modalTitle() {
      let title = 'Upload a CSV';

      switch (this.step) {
        case 3:
          title = 'Confirm import'
          break;
        default:
          break;
      }

      return title;
    },
    numberOfNewFans() {
      return this.csvRows.length;
    },
    mobileNumberMapped() {
      const mapping = this.arToPromoterMap.find(m => m.serverHeaderName === 'mobileNumber');

      const columnIdx = mapping.mappedTo;

      return columnIdx !== null && this.csvRows.every(row => !!row[columnIdx]);
    }
  },
  created() {
    this.arToPromoterMap = initialContactsCSVMap();
    window.addEventListener('arModalOpen', this.handleModalOpen);
  },

  beforeDestroy() {
    window.removeEventListener('arModalOpen', this.handleModalOpen);
  },

  methods: {
    ...mapActions([
      'audience/IMPORT_AUDIENCE_CSV',
    ]),
    handleModalOpen({ detail: { action, payload } }) {
      if (action !== 'OPEN_IMPORT_CONTACTS_MODAL') return;

      this.startModalAction(payload);
    },
    respondModalAction(payload) {
      window.dispatchEvent(new CustomEvent('arModalResponse', { detail: { action: 'OPEN_IMPORT_CONTACTS_MODAL', payload } }));
    },
    startModalAction(payload = { messageList: null, canChangeMessageList: true }) {
      this.isVisible = true;

      const { messageList, canChangeMessageList } = payload;
      this.canChangeMessageList = canChangeMessageList;

      if (messageList) {
        this.messageListSettings = {
          oid: messageList.oid,
          emailCanBeEnabled: messageList.email,
          emailEnabled: messageList.email,
          listContainsEmail: messageList.email,

          smsCanBeEnabled: messageList.sms,
          smsEnabled: false, // We need to set this to false in case any of the mapped rows are missing mobile numbers
          listContainsSms: messageList.sms,
        }
      } else {
        this.messageListSettings = {
          oid: null,
          smsCanBeEnabled: false,
          emailCanBeEnabled: false,
          smsEnabled: false,
          emailEnabled: false,
          listContainsSms: false,
          listContainsEmail: false,
        };
      }
      this.onConfirm = () => {
        this.isVisible = false;
        this.respondModalAction(true);
        this.step = 1;
        this.csvHeaders = [];
        this.csvRows = [];
        this.newFanTags = [];
        this.isVisible = false;
        this.arToPromoterMap = initialContactsCSVMap();
        this.error = '';
      };
      this.onClose = () => {
        this.step = 1;
        this.csvHeaders = [];
        this.csvRows = [];
        this.newFanTags = [];
        this.isVisible = false;
        this.arToPromoterMap = initialContactsCSVMap();
        this.error = '';

        this.respondModalAction(false);
      };
    },
    handleCSVUploaded({ additionalInfo: { headers, body } }) {
      this.csvHeaders = headers;
      this.csvRows = body;
      this.step = 2;
    },
    setMapping(val, arTableHeader) {
      const arTableHeaderIndex = this.arToPromoterMap.indexOf(arTableHeader);
      this.arToPromoterMap[arTableHeaderIndex].mappedTo = val.index;
      this.arToPromoterMap[arTableHeaderIndex].error = undefined;
    },
    handleMessageListChange(messageList){
      this.messageListSettings.oid = messageList.oid;
      this.messageListSettings.selectedChannels = [];

      this.messageListSettings.emailCanBeEnabled = messageList.email;
      this.messageListSettings.emailEnabled = messageList.email;
      this.messageListSettings.listContainsEmail = messageList.email;

      this.messageListSettings.smsCanBeEnabled = messageList.sms && this.mobileNumberMapped;
      this.messageListSettings.smsEnabled = false; // Jared specifically asked that we DO NOT automatically pre-check SMS opt-in. But if he ever changes his mind, uncomment the below...
      // this.messageListSettings.smsEnabled = messageList.sms && this.mobileNumberMapped;
      this.messageListSettings.listContainsSms = messageList.sms;
    },
    csvHeadersOptions(selectedHeaderIndex) {
      if (selectedHeaderIndex !== null) {
        return [
          {
            name: this.csvHeaders[selectedHeaderIndex],
            index: selectedHeaderIndex,
          },
        ];
      }

      return this.csvHeaders
        .map((c, index) => ({
          name: c,
          index,
        })).filter(c => {
          return this.arToPromoterMap.find(arpm => arpm.mappedTo === c.index) === undefined;
        });
    },
    async handleNextStep() {
      if (this.step !== 3) {
        if (
          this.step === 2 &&
          (!this.mobileNumberMapped || !this.messageListSettings.listContainsSms)
        ) {
          // Forcefully disable SMS if it can't be enabled for any particular reason
          this.$set(this.messageListSettings, 'smsCanBeEnabled', false);
          this.$set(this.messageListSettings, 'smsEnabled', false);
        } else if (this.step === 2 &&
          this.mobileNumberMapped &&
          this.messageListSettings.listContainsSms) {
          // If the list contains SMS and all numbers are correctly mapped, then make sure that smsCanBeEnabled is set to true
          this.$set(this.messageListSettings, 'smsCanBeEnabled', true);
        }

        this.step += 1;
        return;
      }

      const incompleteRows = [];
      this.arToPromoterMap.forEach((a, index) => {
        if (a.required && (a.mappedTo === null || a.mappedTo === -1)) {
          incompleteRows.push(index);
        }
      });

      let subscriptions = {};
      if (this.messageListSettings.smsEnabled) {
        subscriptions.sms = true;
      }

      if (this.messageListSettings.emailEnabled) {
        subscriptions.email = true;
      }

      if (
        this.messageListSettings.oid != null &&
        (!subscriptions.sms && !subscriptions.email)
      ) {
        this.error = 'You must choose at least one channel if you wish to add contacts to a list';
      } else if (incompleteRows.length > 0) {
        const mapWithErrors = this.arToPromoterMap.map((a, index) => {
          if (incompleteRows.includes(index)) {
            a.error = 'Hold up! This field is required.';
          }

          return a;
        });

        this.arToPromoterMap = mapWithErrors;
        this.error = 'Please go back and map all required fields.';
      } else {
        const cleanedColumnMap = {};

        this.arToPromoterMap.filter(a => a.mappedTo !== null).forEach(a => {
          cleanedColumnMap[a.mappedTo] = a.serverHeaderName;
        });

        const payload = {
          csvHeaders: this.csvHeaders,
          columnMap: cleanedColumnMap,
          rows: this.csvRows,
          tags: this.newFanTags,
          importType: 'audience',
        };

        if (this.messageListSettings.oid) {
          payload.messageList = {
            oid: this.messageListSettings.oid,
            subscriptions,
          }
        }

        await this['audience/IMPORT_AUDIENCE_CSV'](payload);
        this.onConfirm();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.import-data-modal {
  .mapping-instructions {
    background-color: $skyBlueGrey300;
    padding: 20px;

    &__subtitle {
      font-size: 12px;
      color: $blueGrey700;
    }
  }

  .message-list-select {
    .message-list-subtitle {
      color: $blueGrey700;
      margin-top: 5px;
    }

    .channel-choices {
      margin-top: 10px;
      display: flex;
      flex-direction: row;

      .tick-section-checkbox {
        margin-right: 25px;
      }
    }
  }


  .column-chooser-tabs {
    background-color: $skyBlueGrey300;
    display: flex;
    flex-direction: row;
    padding: 0px 20px;

    &__option {
      margin-right: 20px;
      font-size: 14px;
      padding-right: 5px;
      padding-bottom: 10px;

      &:hover {
        cursor: pointer;
      }

      &.active {
        border-bottom: solid 2px $purple500;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    padding: 28px 28px;
    overflow: auto;

    &.xs-max {
      padding: 28px 12px;
    }

    &.generic {
      padding: 0px;
    }

    > .mass-import-option {
      min-height: 86px;
      border-top: 1px solid $blueGrey500;
      padding: 1.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;

      > div {
        padding: 0.75rem;

        > p.heading {
          color: #43516b;
          font-size: 16px;
        }

        > p.body {
          color: #8e97a6;
        }

        &:last-child {
          position: absolute;
          right: 2.5rem;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: darken(white, 2%);
      }
    }

    p.column-text {
      color: $blueGrey700;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 13px;
      border-bottom: 1px solid $skyBlueGrey500;
      padding-bottom: 13px;
      span:last-child {
        float: right;
      }
    }

    &__import-message {
      font-size: 1.2em;
      padding-top: 1em;
    }

    &_preamble {
      margin-bottom: 1em;
    }

    .form-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 0.75em;

      &__label {
        width: 40%;
        height: 50px;
        display: flex;
        align-items: center;
        position:relative;

        .ar-icon-wrapper {
          position: absolute;
          right: 8px;
        }
      }

      &__input {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 60%;

        .selector {
          width: 100%;
        }
      }

      > :first-child {
        flex: 1;
      }
      > :nth-child(2) {
        flex: 3;
      }

      .secondary-dropdown {
        margin-left: 15px;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    padding: 28px 28px;
    background-color: $skyBlueGrey300;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    p {
      text-align: center;
      color: $blueGrey800;

      &.header {
        font-family: Graphik-Medium;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0;
      }

      &.body {
        font-size: 14px;
        letter-spacing: 0;
      }
    }

    div.error-message {
      border: 1px solid $orange500;
      background: $orange-lite;
      transition: all 0.3s ease;
      width: 100%;
      color: #42516b;
      border-radius: 5px;
      padding: 12px 20px;
      text-align: left;
      margin-bottom: 10px;
    }

    .tick-container {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: $green500;
      display: flex;
      justify-content: center;
      align-items: center;

      g {
        fill: white;
      }
    }
  }

  &__type,
  &__action,
  &__get-started {
    width: 50%;
  }

  &__type,
  &__action,
  &__get-started {
    padding: 25px 35px;
  }

  &__type {
    background-color: $skyBlueGrey300;
    height: 100%;
  }

  &__button {
    width: 100%;
    margin-top: 42px;
  }

  &__checkbox {
    margin-top: 15px;
  }
  &__footer-wrapper {
    display: flex;
    height: 50px;
    box-shadow: 0 3px 10px;
    padding: 50px;
    align-items: center;
    justify-content: flex-end;
  }

  &__eventbrite {
    text-decoration: underline;
    margin-top: 15px;
  }

  div.ticketing-provider {
    display: flex;
    align-items: center;

    > div {
      margin-right: 5px;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  .upload-area {
    &:hover {
      .icon-container {
        border: 2px solid white;
      }
    }

    .icon-container {
      background: $skyBlueGrey400;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

}
</style>
