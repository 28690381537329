import { Module } from "vuex";
import { RootState } from '@/store/modules/types';
import { clone, mergeObjects } from '@/utils/helpers/';
import { IntegrationState } from './types';
import { integrationActions } from './actions';
import {
  Integration,
  EventbriteIntegration,
  FacebookMessengerIntegration,
  FacebookCustomAudiencesIntegration,
} from '@/api/integration/types';
import {
  getEventbriteIntegrations,
  getFacebookMessengerIntegrations,
  getFacebookCustomAudiencesIntegrations,
  getShopifyIntegrations,
  getZoomIntegrations,
  getPatreonIntegrations,
  getEventixIntegrations,
  getMemberfulIntegrations,
  getUniverseIntegrations,
  getStripeIntegrations,
  getTicketekIntegrations,
  getMoshtixIntegrations,
} from './utils';

export const initialIntegrationState = (): IntegrationState => ({
  integrations: [],
  isFetchingIntegrations: false,
});

const integrationModule: Module<IntegrationState, RootState> = {
  namespaced: true,
  state: initialIntegrationState(),
  actions: integrationActions,
  mutations: {
    SET_INTEGRATIONS(state, integrations: Integration[]) {
      state.integrations = clone(integrations);
    },
    RESET_INTEGRATIONS(state) {
      state.integrations = initialIntegrationState().integrations;
    },
    SET_IS_FETCHING_INTEGRATIONS(state, isFetching: boolean) {
      state.isFetchingIntegrations = isFetching;
    },
  },
  getters: {
    firstEventbriteIntegration(state): EventbriteIntegration | null {
      return getEventbriteIntegrations(state.integrations)[0] || null;
    },
    firstFacebookMessengerIntegration(state): FacebookMessengerIntegration | null {
      return getFacebookMessengerIntegrations(state.integrations)[0] || null;
    },
    firstFacebookCustomAudiencesIntegration(state): FacebookCustomAudiencesIntegration | null {
      return getFacebookCustomAudiencesIntegrations(state.integrations)[0] || null;
    },
    hasEventbriteIntegrations(state): boolean {
      return getEventbriteIntegrations(state.integrations).length > 0;
    },
    hasFacebookMessengerIntegrations(state): boolean {
      return getFacebookMessengerIntegrations(state.integrations).length > 0;
    },
    hasFacebookCustomAudiencesIntegrations(state): boolean {
      return getFacebookCustomAudiencesIntegrations(state.integrations).length > 0;
    },
    hasShopifyIntegrations(state): boolean {
      return getShopifyIntegrations(state.integrations).length > 0;
    },
    hasZoomIntegrations(state): boolean {
      return getZoomIntegrations(state.integrations).length > 0;
    },
    hasPatreonIntegrations(state): boolean {
      return getPatreonIntegrations(state.integrations).length > 0;
    },
    hasEventixIntegrations(state): boolean {
      return getEventixIntegrations(state.integrations).length > 0;
    },
    hasMemberfulIntegrations(state): boolean {
      return getMemberfulIntegrations(state.integrations).length > 0;
    },
    hasUniverseIntegrations(state): boolean {
      return getUniverseIntegrations(state.integrations).length > 0;
    },
    hasStripeIntegrations(state): boolean {
      return getStripeIntegrations(state.integrations).length > 0;
    },
    hasTicketekIntegrations(state): boolean {
      return getTicketekIntegrations(state.integrations).length > 0;
    },
    hasMoshtixIntegrations(state): boolean {
      return getMoshtixIntegrations(state.integrations).length > 0;
    },
  },
};

export default integrationModule;
