<template>
  <section class="event-campaigns">
    <am2-card-skeleton
      v-if="isFetchingCampaigns"
      class="base-plan-card"
      type="campaign"
      :show-as="$arMediaQuery.pageContent.minWidth('sm') ? 'list' : 'tiles'"
      :style="{
        height: $arMediaQuery.pageContent.maxWidth('xs') ? '370px' : '192px',
        marginBottom: '10px',
      }"
    />
    <am2-no-content-section
      v-else-if="Object.keys(campaigns).length === 0"
      icon-name="ar-campaign-in-circle"
      header="Create a campaign"
      body="Gamified campaigns to reward fans for referring friends & subscribing"
      button-text="New campaign"
      @buttonClick="goToCampaignSetup"
    />
    <div
      v-else
      v-for="campaignKey in sortedCampaignsOid"
      :key="campaignKey"
      class="list"
    >
      <am2-campaign-card
        :show-as="$arMediaQuery.pageContent.minWidth('sm') ? 'list' : 'tiles'"
        :campaign="campaigns[campaignKey]"
        @delete="handleDeleteCampaign"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import { campaignBaseUri, campaignImage } from '@/utils/campaign';

export default {
  name: 'EventCampaigns',

  data: () => ({
    isFetchingCampaigns: false,
    campaigns: {},
  }),

  computed: {
    sortedCampaignsOid() {
      return Object.keys(this.campaigns).sort((a, b) => {
        const isActiveA = this.isCampaignActive(this.campaigns[a].endDate);
        const isActiveB = this.isCampaignActive(this.campaigns[b].endDate);

        if (isActiveA && isActiveB) {
          if (moment(this.campaigns[a].endDate).isAfter(moment(this.campaigns[b].endDate)))
            return 1;
        }
        if (isActiveA) {
          return -1;
        }
        if (isActiveB) {
          return 1;
        }
        if (moment(this.campaigns[b].endDate).isAfter(moment(this.campaigns[a].endDate))) {
          return 1;
        }

        return 1;
      });
    },
  },

  mounted() {
    this.fetchCampaigns();
  },

  methods: {
    ...mapActions([
      'FETCH_EVENT_CAMPAIGNS',
    ]),

    async fetchCampaigns() {
      try {
        this.isFetchingCampaigns = true;
        this.campaigns = await this.FETCH_EVENT_CAMPAIGNS(this.$route.params.oid);
      } catch (e) {
        console.error(e);
        this.$arNotification.push({ type: 'error', message: 'Failed to fetch campaigns.' });
      } finally {
        this.isFetchingCampaigns = false;
      }
    },

    async handleDeleteCampaign() {
      this.campaigns = await this.$store.dispatch('FETCH_EVENT_CAMPAIGNS', this.$route.params.oid);
    },

    getCampaignImage(oid) {
      return campaignImage(this.campaigns[oid])
    },
    isCampaignActive(campaignEndDate) {
      return moment.utc(campaignEndDate).isAfter(moment.utc());
    },
    previewURL({ type, urlSlug }) {
      return `${campaignBaseUri(type)}${urlSlug}`;
    },
    goToCampaignSetup() {
      this.$emit('navbarAction', 'CREATE_CAMPAIGN');
    },
  },
};
</script>

<style lang="scss" scoped>
.event-campaigns {
  .list {
    /deep/ .index-card {
      margin-bottom: 10px;
    }
  }
}
</style>
