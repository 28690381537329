var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AuthenticateFrame',[_c('ar-text',{style:({
      marginBottom: '40px',
    }),attrs:{"text":"Reset password","size":"lg","multiple-lines":""}}),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.doResetPassword($event)}}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|arPasswordLength'),expression:"'required|arPasswordLength'"}],attrs:{"data-vv-name":"password","placeholder":"New password","autocomplete":"new-password","type":"password","has-error":_vm.veeErrors.has('password')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),_c('ar-state-message',{directives:[{name:"show",rawName:"v-show",value:(_vm.veeErrors.has('password')),expression:"veeErrors.has('password')"}],style:({ marginTop: '8px' }),attrs:{"type":"error","text":_vm.veeErrors.first('password')}}),_vm._v(" "),_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:({
        required: true,
        matchPassword: _vm.password,
        arPasswordLength: true,
      }),expression:"{\n        required: true,\n        matchPassword: password,\n        arPasswordLength: true,\n      }"}],style:({ marginTop: '10px' }),attrs:{"data-vv-name":"confirmPassword","placeholder":"Confirm password","autocomplete":"new-password","type":"password","has-error":_vm.veeErrors.has('confirmPassword')},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_vm._v(" "),_c('ar-state-message',{directives:[{name:"show",rawName:"v-show",value:(_vm.veeErrors.has('confirmPassword')),expression:"veeErrors.has('confirmPassword')"}],style:({ marginTop: '8px' }),attrs:{"type":"error","text":_vm.veeErrors.first('confirmPassword')}}),_vm._v(" "),_c('ar-simple-button',{style:({
        marginTop: '40px',
        width: '100%',
      }),attrs:{"loading":_vm.isResettingPassword,"type":"gradient-purple","shape":"pill","text":"Reset Password"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }