var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreData),expression:"loadMoreData"}],staticClass:"event-attendees-container",attrs:{"infinite-scroll-disabled":"infiniteScrollDisabled","infinite-scroll-distance":"10"}},[_c('div',{directives:[{name:"ar-sticky-top",rawName:"v-ar-sticky-top",value:({
      priority: 1,
    }),expression:"{\n      priority: 1,\n    }"}],staticClass:"u-display-flex u-justify-content-space-between",style:({
      padding: '22px 0 22px',
      backgroundColor: '#F7F9FC',
    })},[_c('am2-heading',{style:({
        marginRight: '12px',
      }),attrs:{"type":"h1","size":"md","title":"Attendees"}}),_vm._v(" "),(_vm.currentIntegration)?_c('div',{staticClass:"attendees-integration-wrapper"},[_c('div',{staticClass:"attendees-integration-logo u-margin-right-2"},[_c('ar-icon',{attrs:{"name":'zoom',"color":_vm.$arStyle.color[_vm.currentIntegration],"height":"16px","width":"16px"}})],1),_vm._v(" "),_c('ar-text',{attrs:{"text":"Attendees have been synced.","size":"xs"}})],1):_vm._e()],1),_vm._v(" "),_c('am2-table-control-section',{directives:[{name:"ar-sticky-top",rawName:"v-ar-sticky-top",value:({
      priority: 1,
    }),expression:"{\n      priority: 1,\n    }"}],style:({
      marginTop: '8px',
      backgroundColor: '#f6f9fc',
    }),attrs:{"fans-search":{
      action: _vm.serverSideSearch,
    },"column-settings":{
      selectedColumnKeys: _vm.selectedColumnKeys,
      availableColumns: _vm.availableColumns,
      action: _vm.handleSelectedColumnKeysUpdate,
    },"loading":(_vm.isFetchingAudience) && _vm.audience.length === 0,"show-checked-rows-options-bar":"","show-filter-and-search-bar":"","showFilterButton":false,"csvExport":{
      action: _vm.handleExportClick,
    }}}),_vm._v(" "),(_vm.currentIntegration == 'zoom')?_c('am2-zoom-attendance-table',{attrs:{"head":_vm.displayedTableHeadings,"body":_vm.tableBody,"loading":_vm.isFetchingAudience && _vm.tableBody.length === 0,"has-index":"","empty-text":"No attendee data","enable-row-click":_vm.customerProfilesEnabled,"has-sticky-header":"","has-control-section":""},on:{"sortBy":_vm.handleSortChange,"rowClick":_vm.handleRowClick}}):_c('am2-fan-table',{attrs:{"head":_vm.displayedTableHeadings,"body":_vm.tableBody,"loading":_vm.isFetchingAudience && _vm.tableBody.length === 0,"has-index":"","empty-text":"No attendee data","enable-row-click":_vm.customerProfilesEnabled,"has-sticky-header":"","has-control-section":""},on:{"sortBy":_vm.handleSortChange,"rowClick":_vm.handleRowClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }