<template>
<div>
  <InfoBranch
    v-if="!!item.config"
    :content="delayString"
    :style="{
      marginTop: '2px',
    }"
  />
  </div>
</template>
<script>
import InfoBranch from '../utils/InfoBranch';
export default {
  name: 'WaitUntil',
  components: {
    InfoBranch
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    delayString() {
      let val = this.item.meta.delay.val
      let duration = this.item.meta.delay['selected-key'] || this.item.meta.delay['selectedKey']

      return val === '1' ? `${val} ${duration.split("").splice(0, duration.length - 1).join("")}` : `${val} ${duration}`
    }
  },
}
</script>