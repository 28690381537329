<template>
  <div class="tags-dropdown-wrapper">
    <DropdownButton
      data-attr="automation-add-tag-dropdown"
      :options="options"
      v-model="selected"
      @open="(val) => $emit('open', val)"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import DropdownButton from '../utils/DropdownButton.vue'
export default {
  name: 'TagsDropdown',
  components: {
    DropdownButton,
  },
  props: {
    selectedTag: {
      type: Object,
      default: null
    }
  },
  model: {
    prop: 'selectedTag',
    event: 'selection'
  },
  data() {
    return {
      options: null,
      selectedTagLocal: { key: 'placeholder', name: 'Select a tag...'  }
    }
  },
  watch: {
    selectedTag() {
      if (!this.selectedTag) {
        this.selectedTagLocal = { key: 'placeholder', name: 'Select a tag...'  }
      } else {
        this.selectedTagLocal = this.selectedTag
      }
    }
  },
  computed: {
    selected: {
      get: function() {
        return this.selectedTagLocal
      },
      set: function(val) {
        this.$emit('selection', val)
      }
    }
  },
  methods: {
    ...mapActions({
      fetchTags: 'automation/FETCH_TAGS',
    }),
    async fetchTagsList() {
      let data = await this.fetchTags()
      
      this.options = data.map(item => {
        return {
          oid: item.oid,
          name: item.name,
        }
      })
    },
  },
  mounted() {
    this.fetchTagsList()
  }
}
</script>