var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"message-sender-modal",attrs:{"is-open":_vm.isShow,"header":"Email Settings","width":"600px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{staticClass:"body",attrs:{"slot":"body"},slot:"body"},[_c('div',{class:[
        'modal-content',
          _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ]},[_c('div',{staticClass:"settings-card"},[(!_vm.emailVerified && !_vm.isMessageSenderCreation)?_c('section',{staticClass:"email-unverified"},[_c('ar-snackbar',{style:({ width: '100%' }),attrs:{"type":"error","message":"Please verify this email address. <a>Resend verification email</a>."},on:{"anchorClick":_vm.retryEmailVerification}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"additional-info-section"},[_c('ar-field',{staticClass:"u-margin-bottom-6",attrs:{"label":"From Email","error-messages":_vm.veeErrors.first('emailAddress')}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required: true,
                  email: true,
                }),expression:"{\n                  required: true,\n                  email: true,\n                }"}],attrs:{"value":_vm.scratchEmailSender.property,"placeholder":"example@email-provider.com","disabled":!_vm.isMessageSenderCreation,"autocomplete":"email","data-vv-name":"emailAddress","data-vv-as":"email address"},on:{"input":function (val) { _vm.handleScratchEmailSenderPatch({ property: val }) }}})],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-bottom-6",attrs:{"label":"From Name","error-messages":_vm.veeErrors.first('senderName')}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:("required"),expression:"`required`"}],attrs:{"value":_vm.scratchEmailSender.additionalInfo.senderName,"placeholder":"","autocomplete":"organization","auto-focus":_vm.autoFocusOn === 'sender-name',"data-vv-name":"senderName","data-vv-as":"sender name"},on:{"input":function (val) { _vm.handleScratchEmailSenderPatch({ additionalInfo: { senderName: val } }) }}})],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-bottom-6",attrs:{"label":"Business Address","error-messages":_vm.veeErrors.first('businessAddress')}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:("required"),expression:"`required`"}],attrs:{"value":_vm.scratchEmailSender.additionalInfo.businessAddress,"placeholder":"","autocomplete":"address","auto-focus":_vm.autoFocusOn === 'business-address',"data-vv-name":"businessAddress","data-vv-as":"business address"},on:{"input":function (val) { _vm.handleScratchEmailSenderPatch({ additionalInfo: { businessAddress: val } }) }}})],1)],1),_vm._v(" "),(_vm.messageSender)?_c('ar-state-message',{staticClass:"u-margin-y-5",attrs:{"text-props":{
              weight: 'bold',
            },"has-icon":"","disable-text-color":"","type":"information","text":"Note: Updates are applied to all lists where email address is used."}}):_vm._e()],1)])]),_vm._v(" "),_c('div',{class:[
      'footer',
      _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-3' ],attrs:{"slot":"footer"},slot:"footer"},[(!_vm.isDeletingMessageSender && !_vm.isCreatingMessageSender && !_vm.isPatchingMessageSenders)?_c('ar-link-button',{staticClass:"u-margin-right-5",attrs:{"text":"Cancel"},on:{"click":_vm.handleCloseClick}}):_vm._e(),_vm._v(" "),(!_vm.isMessageSenderCreation && _vm.enableDelete)?_c('ar-simple-button',{staticClass:"u-margin-right-3",style:({ width: '117px', height: '42px', }),attrs:{"text":"Delete","type":"red","outlined":"","text-weight":"normal","disabled":_vm.isPatchingMessageSenders,"loading":_vm.isDeletingMessageSender},on:{"click":_vm.handleDeleteClick}}):_vm._e(),_vm._v(" "),(_vm.isMessageSenderCreation)?_c('ar-simple-button',{style:({ width: '117px', height: '42px', }),attrs:{"text":"Create","disabled":!_vm.isScratchEmailSenderComplete,"loading":_vm.isCreatingMessageSender},on:{"click":_vm.handleCreateClick}}):_c('ar-simple-button',{style:({ width: '117px', height: '42px', }),attrs:{"text":"Update","disabled":!_vm.isScratchEmailSenderComplete,"loading":_vm.isPatchingMessageSenders},on:{"click":_vm.handleUpdateClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }