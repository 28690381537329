var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('am2-heading',{attrs:{"type":"h1","size":"lg","title":"Setup the basics"}}),_vm._v(" "),_c('ar-field',{style:({
      marginTop: '40px',
    }),attrs:{"label":"To"}},[_c('div',[_c('am2-message-list-select',{attrs:{"channels":['email'],"value":_vm.scratchEmailMessage.meta.messageListOid,"campaign-oid":_vm.scratchEmailMessageCampaignOid,"event-oid":_vm.scratchEmailMessageEventOid,"data-test-id":"message-list-select"},on:{"select":_vm.handleMessageListChange}}),_vm._v(" "),(_vm.currentMessageListHasNoEmailContacts)?_c('ar-snackbar',{staticClass:"u-width-100-percent",style:({ marginTop: '10px' }),attrs:{"type":"error","message":"There are no valid email addresses in this list."}}):_vm._e(),_vm._v(" "),(_vm.currentSelectedMessage && _vm.currentMessageListIsForAllCampaignRegistrants)?_c('ar-snackbar',{staticClass:"u-margin-top-5 u-width-100-percent",attrs:{"type":"warning","message":"Changing from all registrants to a tier will disable scheduling."}}):_vm._e(),_vm._v(" "),(_vm.currentCampaignIsActive && _vm.currentMessageListIsForCampaignTier)?_c('ar-state-message',{staticClass:"u-margin-top-5",attrs:{"text-props":{
          weight: 'bold',
        },"has-icon":"","disable-text-color":"","type":"information","text":"Note: It's recommended to only message individual tiers once the campaign is ended."}}):_vm._e()],1)]),_vm._v(" "),(_vm.showAdvancedTargetingModule)?_c('am2-message-list-advanced-targeting-section',{attrs:{"channel":"email","show-toggle":""},on:{"updateAdvancedTargeting":_vm.handleAdvancedTargetingUpdate}}):_vm._e(),_vm._v(" "),_c('ar-field',{style:({
      marginTop: '40px',
    }),attrs:{"label":"From"}},[_c('div',[_c('am2-message-sender-select',{ref:"messageSenderSelect",attrs:{"value":_vm.scratchEmailMessage.meta.email && _vm.scratchEmailMessage.meta.email.promoterPropertyOid},on:{"select":_vm.handleSenderSelect,"clear":_vm.handleMessageSenderClear}})],1),_vm._v(" "),(_vm.currentMessageSenderNotVerified)?_c('ar-snackbar',{staticClass:"u-width-100-percent",style:({ marginTop: '10px' }),attrs:{"type":"error","message":"Please verify this email address. <a>Resend verification email</a>."},on:{"anchorClick":_vm.handleResendVerificationEmailAnchorClick}}):_vm._e()],1),_vm._v(" "),_c('ar-field',{style:({
      marginTop: '40px',
    }),attrs:{"label":"Subject"}},[_c('ar-input',{attrs:{"value":_vm.scratchEmailMessage.meta.messageBody.subject},on:{"input":_vm.handleSubjectInput}})],1),_vm._v(" "),_c('ar-field',{style:({
      marginTop: '40px',
    }),attrs:{"label":"Preview line"}},[_c('ar-input',{attrs:{"value":_vm.scratchEmailMessage.meta.messageBody.previewText,"autocomplete":"preview-line"},on:{"input":_vm.handlePreviewTextInput}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }