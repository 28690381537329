<template>
<div class="freq-options-wrapper">
  <ar-text
    class="freq-heading"
    size="14px"
    text="Frequency"
    weight="normal"
    multiple-lines
    :max-lines="1"
  />
  <div class="freq-copy-wrapper">
    <ar-text
      class="freq-copy"
      size="14px"
      text="Don’t trigger the automation if user has been through previously."
      weight="normal"
      multiple-lines
      :max-lines="2"
    />

    <am2-switch
      class="freq-switch"
      v-model="freqOptionLocal"
    />
  </div>
</div>  
</template>
<script>
export default {
  name: 'FrequencyOption',
  props: {
    freqOption: {
      type: Boolean,
      deault: false
    }
  },
  model: {
    prop: 'freqOption',
    event: 'selection',
  },
  computed: {
    freqOptionLocal: {
      get: function() {
        return this.freqOption
      },
      set: function(val) {
        this.$emit('selection', val)
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.freq-options-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 22px;

  .freq-heading {
    color: $blueGrey700;
    margin-bottom: 5px;
  }

  .freq-copy-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .freq-copy {
      width: 252px;
    }

    .freq-switch {
      width: 48px !important;        

      &.off {
        background: rgba(120, 120, 128, 0.16) !important;
      }
      &.on {
        background: $purple400 !important;
      }

      /deep/ .ball {
        box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.2) !important;
      }

      /deep/ .off-text {
        display: none !important;
      }
      
      /deep/ .on-text {
        display: none !important;
      }
    }
  }
}
</style>