var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'audience-page-menu-wrapper',
    _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[(_vm.columnSettings)?_c('am2-multi-select-modal',{attrs:{"title":"Edit columns","is-show":_vm.showColumnSwitchModal,"items":_vm.columnSettings.availableColumns,"value":_vm.columnSettings.selectedColumnKeys},on:{"input":_vm.handleMultiSelectModalConfirm,"close":function($event){_vm.showColumnSwitchModal = false}}}):_vm._e(),_vm._v(" "),_c('section',[(_vm.tickedFansCount === 0 && !_vm.hideFansCountCopy && _vm.initialLoadFinished)?_c('ar-text',{attrs:{"size":"sm","allow-html":"","text":_vm.fansCountCopy}}):(_vm.tickedFansCount === 0 && _vm.loading)?_c('am2-typography-skeleton',{style:({
        width: '124px',
        marginTop: '12px',
        marginBottom: '12px',
      }),attrs:{"size":"sm"}}):_vm._e(),_vm._v(" "),(_vm.tickedFansCount !== 0 && !_vm.hideFansCountCopy)?_c('section',{staticClass:"audience-page-menu-caption"},[_c('ar-text',{attrs:{"size":"sm","text":(_vm.tickedFansCountText + " " + _vm.fanAppellation + (_vm.tickedFansCount === 1 ? '' : 's') + " selected"),"weight":"bold"}}),_vm._v(" "),(_vm.selectClear)?_c('section',{staticClass:"clear-text-section"},[_c('ar-text',{attrs:{"size":"sm","text":"("}}),_vm._v(" "),_c('ar-link-button',{style:({
            position: 'relative',
          }),attrs:{"text":"clear selection","has-underline":false,"has-underline-on-hover":""},on:{"click":_vm.handleClearSelectionClick}}),_vm._v(" "),_c('ar-text',{attrs:{"size":"sm","text":")"}})],1):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('div',{class:[
      'audience-page-menu-function-buttons-wrapper',
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[(_vm.$arMediaQuery.pageContent.minWidth('md'))?_c('div',{style:({
        marginBottom: _vm.tickedFansCount !== 0 ? '-10px' : null
      })},[(_vm.tickedFansCount !== 0 && _vm.addFansToMessageList)?_c('ar-simple-button',{directives:[{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
        mode: 'transparent',
        show: !_vm.isFeatureEnabled(['messages']),
        title: _vm.messagesFeatureLockedCopy.title,
        message: _vm.messagesFeatureLockedCopy.message,
      }),expression:"{\n        mode: 'transparent',\n        show: !isFeatureEnabled(['messages']),\n        title: messagesFeatureLockedCopy.title,\n        message: messagesFeatureLockedCopy.message,\n      }"}],style:({
          height: '40px',
          margin: '0 0 10px 10px',
        }),attrs:{"transparent":"","text":"Add to list"},on:{"click":_vm.handleAddFanToMessageList}}):_vm._e(),_vm._v(" "),(_vm.tickedFansCount !== 0 && _vm.massEditFanTag)?_c('am2-simple-button-dropdown',{attrs:{"align":"right","button-props":{
          text: 'Tags',
          transparent: true,
          style: { height: '40px', margin: '0 0 10px 10px' }
        },"items":[
          {
            key: 'add',
            name: 'Add tags',
          },
          {
            key: 'remove',
            name: 'Remove tags',
          } ],"dropdown-style":{
          width: '120px',
        }},on:{"select":_vm.handleTagOption}}):_vm._e(),_vm._v(" "),(_vm.tickedFansCount !== 0 && _vm.massFansEdit)?_c('ar-simple-button',{style:({
        height: '40px',
        margin: '0 0 10px 10px',
      }),attrs:{"transparent":"","text":"Edit"},on:{"click":_vm.handleFanEditClick}}):_vm._e(),_vm._v(" "),(_vm.tickedFansCount !== 0 && _vm.massFansDelete)?_c('ar-simple-button',{style:({
        height: '40px',
        margin: '0 0 10px 10px',
      }),attrs:{"transparent":"","text":"Delete"},on:{"click":_vm.handleFanDeleteClick}}):_vm._e(),_vm._v(" "),(_vm.fansSearch)?_c('am2-search',{style:({
        width: '300px',
        height: '40px',
      }),attrs:{"placeholder":("Search " + _vm.fansCountText + " " + _vm.fanAppellation + "s")},on:{"enter":_vm.handleFansSearchEnter,"clear":_vm.handleFansSearchClear},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.$arMediaQuery.pageContent.maxWidth('sm') && _vm.tickedFansCount !== 0)?_c('ar-simple-button',{style:({
        marginLeft: '10px',
        height: '40px',
      }),attrs:{"icon-name":"edit","text":_vm.optionsPaneVisible ? 'Hide Options' : 'Show Options',"type":"grey"},on:{"click":_vm.handleOptionsClick}}):_vm._e(),_vm._v(" "),(_vm.importContacts)?_c('ar-simple-button',{style:({
        marginLeft: '10px',
        height: '40px',
      }),attrs:{"transparent":"","text":"Import contacts"},on:{"click":_vm.handleImportClick}}):_vm._e(),_vm._v(" "),(_vm.csvExport && _vm.fansCount && _vm.fansCount > 0 && _vm.csvExport.expanded)?_c('ar-simple-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
        content: _vm.csvExport.disabled ? 'Upgrade your plan to export data.' : null
      }),expression:"{\n        content: csvExport.disabled ? 'Upgrade your plan to export data.' : null\n      }",modifiers:{"top":true}}],style:({
        marginLeft: '10px',
        maxHeight: '40px',
      }),attrs:{"type":"grey","icon-name":"export","text":"Export","icon-props":{
        width: '20px',
        height: '20px',
      },"sideLength":"40px","disabled":_vm.csvExport.disabled},on:{"click":_vm.handleExportClick}}):(_vm.csvExport && _vm.fansCount && _vm.fansCount > 0 && !_vm.csvExport.expanded)?_c('am2-expand-icon-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
        content: _vm.csvExport.disabled ? 'Upgrade your plan to export data.' : null
      }),expression:"{\n        content: csvExport.disabled ? 'Upgrade your plan to export data.' : null\n      }",modifiers:{"top":true}}],style:({
        marginLeft: '10px',
        maxHeight: '40px',
      }),attrs:{"icon-name":"export","text":"Export","sideLength":"40px","disabled":_vm.csvExport.disabled},on:{"click":_vm.handleExportClick}}):_vm._e(),_vm._v(" "),(_vm.genericSettingsButton)?_c('am2-expand-icon-button',{style:({
        marginLeft: '10px',
        maxHeight: '40px',
      }),attrs:{"icon-name":"settings","text":"Settings","sideLength":"40px"},on:{"click":_vm.handleGenericSettingsClick}}):(_vm.columnSettings)?_c('am2-expand-icon-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
        content: _vm.columnSettings.disabled ? 'Upgrade your plan to edit columns.' : null
      }),expression:"{\n        content: columnSettings.disabled ? 'Upgrade your plan to edit columns.' : null\n      }",modifiers:{"top":true}}],style:({
        marginLeft: '10px',
        maxHeight: '40px',
      }),attrs:{"icon-name":"settings","text":"Settings","disabled":_vm.columnSettings.disabled,"sideLength":"40px"},on:{"click":_vm.handleSettingsClick}}):_vm._e()],1),_vm._v(" "),(_vm.$arMediaQuery.pageContent.maxWidth('sm'))?_c('div',{class:[
      'dropdown-pane',
      _vm.optionsPaneVisible && 'is-visible' ]},[(_vm.tickedFansCount !== 0 && _vm.addFansToMessageList)?_c('ar-simple-button',{directives:[{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
        mode: 'transparent',
        show: !_vm.isFeatureEnabled(['messages']),
        title: _vm.messagesFeatureLockedCopy.title,
        message: _vm.messagesFeatureLockedCopy.message,
      }),expression:"{\n        mode: 'transparent',\n        show: !isFeatureEnabled(['messages']),\n        title: messagesFeatureLockedCopy.title,\n        message: messagesFeatureLockedCopy.message,\n      }"}],style:({
        height: '40px',
        fontSize: '12px',
        margin: '10px 0 0 10px',
      }),attrs:{"transparent":"","text":"Add to list"},on:{"click":_vm.handleAddFanToMessageList}}):_vm._e(),_vm._v(" "),(_vm.tickedFansCount !== 0 && _vm.massEditFanTag)?_c('am2-simple-button-dropdown',{attrs:{"align":"right","button-props":{
        text: 'Tags',
        transparent: true,
        style: { height: '40px', margin: '10px 0 0 10px' }
      },"items":[
        {
          key: 'add',
          name: 'Add tags',
        },
        {
          key: 'remove',
          name: 'Remove tags',
        } ],"dropdown-style":{
        width: '120px',
      }},on:{"select":_vm.handleTagOption}}):_vm._e(),_vm._v(" "),(_vm.tickedFansCount !== 0 && _vm.massFansEdit)?_c('ar-simple-button',{style:({
        height: '40px',
        margin: '10px 0 0 10px',
      }),attrs:{"transparent":"","text":"Edit"},on:{"click":_vm.handleFanEditClick}}):_vm._e(),_vm._v(" "),(_vm.tickedFansCount !== 0 && _vm.massFansDelete)?_c('ar-simple-button',{style:({
        height: '40px',
        margin: '10px 0 0 10px',
      }),attrs:{"transparent":"","text":"Delete"},on:{"click":_vm.handleFanDeleteClick}}):_vm._e(),_vm._v(" "),(_vm.fansSearch)?_c('am2-search',{style:({
        width: '300px',
        height: '40px',
        margin: '10px 0 0 10px'
      }),attrs:{"placeholder":("Search " + _vm.fansCountText + " " + _vm.fanAppellation + "s")},on:{"enter":_vm.handleFansSearchEnter,"clear":_vm.handleFansSearchClear},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }