<template>
  <div class="event-list__wrapper">
    <div
      v-for="event in events"
      :key="event.oid"
      :class="[
        'event-list__item-wrapper',
        $arMediaQuery.pageContent.minWidth('sm') && 'u-padding-y-4 u-padding-x-6',
      ]"
      @click="handleItemClick(event)">
      <div
        :class="[
          'event-list__item-image',
          $arMediaQuery.pageContent.minWidth('sm') && 'u-margin-right-5',
        ]"
      >
        <img
          :src="eventImage(event)"
        />
      </div>

      <div :class="[
        'event-list__info-wrapper',
      ]">
        <p class="event-list__item-name">{{ event.name }}</p>
        <p class="event-list__item-date">{{ dateRange(event) }}</p>
      </div>
      <div
        :class="[
          'event-list__right-button',
          $arMediaQuery.pageContent.minWidth('sm') && 'sm-min',
        ]">
        <ar-icon
          v-if="event.ticked"
          name="notification-success"
          width="20px"
          height="20px"
          :color="$arStyle.color.green500"
        />
        <div v-else class="event-list__button"/>
      </div>
    </div>

    <p v-if="!events || events.length <= 0" class="event-list__hint">No events found.</p>
</div></template>

<script>
import { displayDateRange } from '@/utils/date/';
import { mapGetters } from 'vuex';

export default {
  name: 'Event',

  props: {
    eventsArray: {
      type: Array,
      required: true,
    },
    query: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      events: this.eventsArray,
    };
  },

  computed: {
  },

  watch: {
    query(newQuery) {
      this.events = this.eventsArray.filter(event =>
        event.name.toLowerCase().includes(newQuery.toLowerCase())
      );
    },
  },

  methods: {
    eventImage(event) {
      const eventImage = event.resources.find(resource => resource.assetType === 'event-image');
      return eventImage ? eventImage.url : '/images/placeholders/image-default.png';
    },
    dateRange(event) {
      return displayDateRange(event.startDate, event.endDate, event.timeZone);
    },
    handleDragOver() {
      this.dragOver = true;
    },

    handleDragLeave() {
      this.dragOver = false;
    },

    handleItemClick(event) {
      if (event.ticked === true) {
        this.$set(event, 'ticked', false);
        this.$emit('eventDeselected', event);
      } else {
        // const events = this.events.map(value => {
        //   if (value === event) {
        //     value.ticked = true;
        //   } else {
        //     value.ticked = false;
        //   }
        //   return value;
        // });
        this.events.forEach((element, index) => {
          if (element === event) {
            element.ticked = true;
          } else {
            element.ticked = false;
          }
          this.$set(this.events, index, element);
        });
        // this.$set(this, 'events', events);
        // this.$set(this.events[this.events.indexOf(event)], 'ticked', true);
        this.$emit('eventSelected', event);
      }
    },
  },
};
</script>

<style lang="scss">
.event-list {
  &__wrapper {
    padding:0;
    height: 500px;
    @media (max-height: 768px) {
      height: 300px;
    }
    &.col-xs-12 {
      padding:0;
    }
  }
  &__item-wrapper {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $blueGrey500;
    position: relative;
    cursor: pointer;
    padding: 16px 12px;

    &.sm-min {
      padding: 25px 28px;
    }

  }
  &__item-image {
    margin-right: 12px;
    border-radius: 5px;
    width: 70px;
    min-width: 70px;
    height: 50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    img {
      object-fit: cover;
      max-height: 100%;
    }
  }
  &__item-name {
    font-size: 16px;
  }
  &__item-date {
    font-size: 12px;
    color: $blueGrey600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__info-wrapper {
    display: flex;
    flex-direction: column;
    padding-right:30px;
    overflow: hidden;
  }
  &__right-button {
    position: absolute;
    right: 12px;
    &.sm-min {
      right: 30px;
    }
  }
  &__button {
    border: 1px solid $blueGrey500;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
  &__hint {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    height: 500px;
  }
}
</style>
