<template>
  <EditableFrame
    class="registers-for-campaign-options-wrapper"
    title="Edit Trigger"
    :subtitle="item.name"
    has-save-button
    @close="$emit('close')"
    @save="handleSave"
  >
    <ar-text
      class="option-heading"
      size="13px"
      text="CAMPAIGN"
      weight="bold"
      multiple-lines
      :max-lines="1"
      letter-spacing="1px"
    />
    <CampaignsDropdown
      v-model="selectedCampaign"
    />

    <div class="options-wrapper">
      <ar-text
        class="option-heading"
        size="13px"
        text="OPTIONS"
        weight="bold"
        multiple-lines
        :max-lines="1"
        letter-spacing="1px"
      />

      <FrequencyOption v-model="freqOption" />
      <FilterOption v-model="filterOption" />
    </div>
  </EditableFrame>
</template>
<script>
import CampaignsDropdown from './CampaignsDropdown'
import FrequencyOption from '../utils/FrequencyOption.vue'
import FilterOption from '../utils/FilterOption.vue'
import EditableFrame from '../utils/EditableFrame.vue'
import { mapActions } from 'vuex'
export default {
  name: 'RegistersForCampaign',
  components: {
    CampaignsDropdown,
    FrequencyOption,
    FilterOption,
    EditableFrame,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selectedCampaign: { key: 'any', name: 'Any campaign' },
      freqOption: false,
      filterOption: false  
    }
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    }
  },
  methods: {
    ...mapActions({
      updateTriggerContextData: 'automation/UPDATE_TRIGGER_CONTEXT_DATA'
    }),
    handleSave() {
      let contextData = {
        config: {
          'campaign-oid': this.selectedCampaign.oid,
        },
        meta: {
          name: this.selectedCampaign.name,
          // is converted to snake-case by the backend,
          // so doing it here also for simplicity
          'limit-frequency': this.freqOption,
        }
      }
      
      this.updateTriggerContextData({id: this.item.id, data: contextData})
      this.$emit('close')
    },
    setItemVals() {
      if (this.item.config?.['campaign-oid']) {
        this.selectedCampaign = { name: this.item.meta?.name }
      } else {
        this.selectedCampaign = { key: 'any', name: 'Any campaign' }
      }
      
      this.freqOption = this.item.meta?.['limit-frequency']
    }
  },
  mounted() {
    this.setItemVals()
  }
}
</script>
<style lang="scss" scoped>
.registers-for-campaign-options-wrapper {
  width: 100%;
  height: 100%;
  .option-heading {
    margin-bottom: 14px;
  }

  .options-wrapper {
    margin-top: 52px;
  }
}
</style>