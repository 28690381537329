<template>
  <am2-mobile-container
    :website-url="previewURL"
    :size="size"
  >
    <!-- src CANNOT BE CHANGED, THAT WILL BREAK OUR C2 previeBridge LOGIC! -->
    <am2-loading-section
      v-if="!previewIsReady"
      :style="{
        height: '100%',
      }"
    />
    <iframe
      v-if="initialPreviewData"
      ref="previewiframe"
      width="100%"
      height="100%"
      :src="`${c2ClientDomain}/preview-page/tour${initialPreviewData.previewPage}?preview=true&initialData=${encodeURIComponent(JSON.stringify(initialPreviewData))}`" frameborder="0">
    </iframe>
  </am2-mobile-container>
</template>

<script>
import { clone } from '@/utils/helpers';

let updateTimeout;

export default {
  name: 'TourPreview',

  props: {
    hasAccount: {
      type: Boolean,
      default: false,
    },
    hasFan: {
      type: Boolean,
      default: false,
    },
    previewPage: {
      type: String,
      default: '',
    },
    tour: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      default: 'fixed',
      validator: (val) =>
        ['fixed', 'fullHeight', 'fullWidth'].indexOf(val) > -1,
    },
  },
  data() {
    return {
      c2ClientDomain: process.env.arCampaignClientDomain,
      initialPreviewData: null,
      previewIsReady: false,
    };
  },

  computed: {
    previewData() {
      return {
        hasAccount: this.hasAccount,
        hasFan: this.hasFan,
        previewPage: this.previewPage,
        tour: this.tour,
      };
    },
    previewURL() {
      if (!this.tour) {
        return;
      }
      return `${this.c2ClientDomain}/t/${this.tour.urlSlug}`;
    },
    previewScale() {
      const previewSize = 780;
      const margin = 160;
      const offset = 90;
      const totalPreviewFrameSize = previewSize + margin + offset;
      // diff

      if(document.documentElement.clientHeight >= totalPreviewFrameSize) {
        return 1;
      }

      return  document.documentElement.clientHeight / totalPreviewFrameSize
    },
  },
  watch: {
    previewData: {
      handler(val) {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(() => {
          if (!this.$refs.previewiframe) {
            return;
          }
          this.$refs.previewiframe.contentWindow.postMessage(JSON.stringify(val), this.c2ClientDomain);
        }, 500);
      },
      deep: true
    },
  },
  methods: {
    /**
     * Our Preview mechanism requires AM2 to get Tour data ready in advance to previewing Tour,
     * So whoever uses ar-tour-preview should take in charge of calling this method after Tour data's ready.
     */
    setupInitialPreviewData() {
      this.previewIsReady = true;
      this.initialPreviewData = clone(this.previewData);
    },
  }
};
</script>

