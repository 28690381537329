var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-table',{ref:"message-table",staticClass:"message-table",attrs:{"heads":_vm.decoratedHead,"rows":_vm.tableRows,"loading":_vm.loading,"row-height":74,"empty-text":"No messages available","enable-row-click":"","has-sticky-header":_vm.hasStickyHeader,"custom-head-padding":"0 22px"},on:{"rowClick":_vm.handleMessageRowClick},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var data = ref.data;
return _c('div',{staticClass:"message-cell",style:({
      padding: _vm.$arMediaQuery.pageContent.minWidth('sm') ? '17px 14.5px 16.5px 22px' : '10px 14.5px 10px 22px',
    })},[_c('div',{staticClass:"type-container"},[_c('ar-icon',{staticClass:"icon",attrs:{"name":_vm.getIcon(data.provider).name,"color":_vm.getIcon(data.provider).color,"width":"19px"}})],1),_vm._v(" "),_c('div',{staticClass:"body-container"},[(data.provider === 'email')?_c('ar-text',{class:['main', _vm.getMessageUiStatus(data) === 'completed' && 'sent'],attrs:{"size":"xs","text":data.meta && data.meta.messageBody ? data.meta.messageBody.subject : null,"weight":"bold"}}):_c('ar-text',{class:['main', _vm.getMessageUiStatus(data) === 'completed' && 'sent'],attrs:{"size":"xs","text":data.meta ? data.meta.messageBody : null,"weight":"bold"}}),_vm._v(" "),_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: data.lastActionTimeTooltip ? data.lastActionTimeTooltip : null
        }),expression:"{\n          content: data.lastActionTimeTooltip ? data.lastActionTimeTooltip : null\n        }",modifiers:{"top":true}}],staticClass:"subtitle",attrs:{"size":"xxxs","text":data.lastActionTimeText,"multiple-lines":"","max-lines":1,"line-height":"16px"}})],1),_vm._v(" "),_c('div',{staticClass:"status-action-container"},[_c('div',{staticClass:"status"},[(data.uiStatus === 'in-progress' && data.statusDetails)?_c('div',{staticClass:"progress"},[_vm._v("\n          "+_vm._s(_vm.sentPercentage(data.statusDetails))+"%\n          "),_c('am2-gauge',{staticClass:"gauge",attrs:{"fill-color":"#7344c0","value":_vm.numSent(data.statusDetails),"max":data.statusDetails.totalMessages}})],1):_vm._e(),_vm._v(" "),_c('div',[_c('am2-tag',{style:({
              border: '1px solid white'
            }),attrs:{"text":_vm.generateStatusCopy(data.uiStatus),"type":_vm.statusToTagColor(data.uiStatus)}})],1)]),_vm._v(" "),(!data.hideActions)?_c('am2-icon-button-dropdown',{attrs:{"icon-props":{
          name: 'menu',
        },"items":_vm.messageOptions(data)},on:{"select":function (item) { return _vm.handleMessageOptionSelect(item.eventName, data); }}}):_vm._e()],1)])}},{key:"opens",fn:function(ref){
        var data = ref.data;
        var head = ref.head;
return _c('div',{class:[
      'percentage-cell',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
    ]},[_c('div',{staticClass:"percentage-cell__container"},[(data[head.key] > 0 && data.provider !== 'sms')?[_c('ar-text',{staticClass:"value inactive-only",attrs:{"size":"xs","text":((data[head.key]) + "%"),"align":"right","weight":"bold"}}),_vm._v(" "),_c('ar-text',{staticClass:"value active-only",attrs:{"size":"xs","text":data.opensRaw,"align":"right","weight":"bold"}}),_vm._v(" "),_c('ar-text',{staticClass:"subtitle",attrs:{"size":"xs","text":head.name,"align":"right"}})]:_c('span',{staticClass:"empty-dash"})],2)])}},{key:"clicks",fn:function(ref){
    var data = ref.data;
    var head = ref.head;
return _c('div',{class:[
      'percentage-cell',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
    ]},[_c('div',{staticClass:"percentage-cell__container"},[(data[head.key] > 0)?[_c('ar-text',{staticClass:"value inactive-only",attrs:{"size":"xs","text":((data.clicks) + "%"),"align":"right","weight":"bold"}}),_vm._v(" "),_c('ar-text',{staticClass:"value active-only",attrs:{"size":"xs","text":("" + (data.clicksRaw)),"align":"right","weight":"bold"}}),_vm._v(" "),_c('ar-text',{staticClass:"subtitle",attrs:{"size":"xs","text":head.name,"align":"right"}})]:_c('span',{staticClass:"empty-dash"})],2)])}}])})}
var staticRenderFns = []

export { render, staticRenderFns }