<template>
  <article class="stats-list">
    <header>
      <div class="heading-container">
        <am2-heading
          type="h1"
          size="md"
          :title="heading"
        />
        <ar-icon
          v-if="tooltip"
          name="tooltip-question-mark"
          class="u-margin-left-2 tooltip-icon"
          :color="$arStyle.color.blueGrey500"
          height="16px"
          width="16px"
          v-tooltip.top="{
            content: tooltip
          }"
        />
      </div>
      <nav v-if="showSeeAll">
        <a class="see-all" @click.prevent="handleSeeAllClick">See all</a>
      </nav>
    </header>
    <!-- If there is no row data supplied then show the emptyText -->
    <div :class="['stats-content', $arMediaQuery.pageContent.minWidth('sm')&& 'sm-min']">
      <table :class="!hasRowData ? 'empty' : null">
        <thead>
          <tr>
            <th class="label" :style="{ paddingLeft: hasRowData ? '33px' : '9px' }">{{columnNames[0]}}</th>
            <th class="value">{{columnNames[1]}}</th>
          </tr>
        </thead>
        <tbody v-if="hasRowData && !loading && !error">
          <tr
            v-for="(row, index) in topData"
            :class="[row.href || row.internalHref ? 'clickable' : null]"
            :key="`${index}`"
            @mouseenter="handleRowMouseEnter(index)"
            @mouseleave="handleRowMouseLeave(index)"
            @click="handleRowClick(row, index)" >
            <td>
              <div class="audience-info">
                <span class="number">{{index + 1}}</span>
                <ar-avatar
                  :class="['avatar', locked && 'blurred']"
                  :image-path="isSpotifyUser(row) && row.avatar || null"
                  :color="activeRow === index ? 'white' : 'grey'"
                  :name="row.name"
                />
                <span :class="['name', locked && 'blurred']">{{ row.name }}</span>
              </div>
            </td>
            <td class="right">
              <ar-text
                :class="[locked && 'blurred']"
                size="xs"
                :text="displayData(row.total)"
                align="right"
              />
            </td>
          </tr>
        </tbody>
        <tbody v-else>
        </tbody>
      </table>
      <div class="jacker-section" v-if="loading">
        <am2-loading-bubble
          class="jacker-content"
        />
      </div>
      <div class="jacker-section" v-else-if="error">
        <ar-state-message
          :style="{ justifyContent: 'center' }"
          class="jacker-content"
          type="error"
          text="Error loading data."
        />
      </div>
      <div class="jacker-section" v-else-if="!hasRowData">
        <ar-state-message
          class="jacker-content"
          has-icon
          disable-text-color
          type="general"
          :text="emptyText"
        />
      </div>
    </div>
  </article>
</template>

<script>
import accounting from 'accounting';
import { mapGetters } from 'vuex';

export default {
  name: 'InsightsSmallTable',
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    locked: {
      type: Boolean,
      default: true
    },
    data: {
      type: Array,
      default: () => []
    },
    error: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
      default: "Default heading"
    },
    tooltip: {
      type: String,
      default: null,
    },
    columnNames: {
      type: Array,
      default: () => [],
    },
    emptyText: {
      type: String,
      default: "No data is available"
    },
    isValueCurrency: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      seeAllActive: false,
      topLimit: 5,
      activeRow: null,
    };
  },

  computed: {
    topData() {
      if(!this.data) return [];
      return this.data.slice(0, this.topLimit)
    },
    showSeeAll() {
      if(!this.data) return false;
      return this.data.length > this.topLimit;
    },
    hasRowData() {
      if(!this.data) return false;
      return (this.data && this.data.length > 0)
    },
  },

  methods: {
    displayData(data) {
      if (!isNaN(parseInt(data))) {
        if (this.isValueCurrency) {
          return accounting.formatMoney(data / 100);
        }
        return accounting.formatNumber(data);
      }
      return data;
    },
    isSpotifyUser(row) {
      return row.href && row.href.indexOf('spotify.com') > -1;
    },
    handleSeeAllClick() {
      this.$emit('seeAll');
    },
    handleRowMouseEnter(rowIndex) {
      this.activeRow = rowIndex;
    },
    handleRowMouseLeave() {
      this.activeRow = null;
    },
    handleRowClick(row, index) {
      if (row.href) {
        window.open(row.href, '__blank');
      }
      if (row.internalHref) {
        this.$router.push({
          path: row.internalHref
        });
      }
      this.$emit('rowClick', row, index);
    },
  }
};
</script>

<style lang="scss" scoped>
.stats-list {
  .blurred {
    filter: blur(3px);
  }
  .see-all {
    text-decoration: underline;
    cursor: pointer;
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
    .heading-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .tooltip-icon {
        margin-top:2px;
      }
    }
    nav {
      cursor: pointer;
    }
  }

  .stats-content {
    height: calc(100% - 75px);
    min-height: 348px;
    overflow:scroll;

    &.sm-min {
      overflow:visible;
    }
    table {
      border-spacing: 0;
      border-top: 1px solid $skyBlueGrey400;
      thead {
        border-bottom: 1px solid $skyBlueGrey400;
        th {
          color: $blueGrey700;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-size: 13px;
          line-height: 14px;
          font-weight: normal;
          text-align: left;
          padding: 19px 0;
          &.label {
            position: relative;
            width: 100%;
          }
          &.value {
            padding-right: 9px;
            white-space: nowrap;
            text-align: right;
          }
        }
      }
      tbody {
        tr {
          &.clickable {
            cursor: pointer;
            &:hover {
              background: $purple100;
            }
          }
          td {
            border-top: 1px solid $skyBlueGrey400;
            font-size: 14px;
            line-height: 15px;
            color: $blueGrey800;
            padding: 9px 0;
            .audience-info {
              display: flex;
              align-items: center;
              .number {
                position: relative;
                top: -2px;
                display: inline-block;
                vertical-align: middle;
                width: 27px;
                text-align: center;
                line-height: 1;
              }
              .avatar {
                margin-right: 9px;
              }
              .name {
                display: flex;
                width: calc(100% - 84px);
                line-height: 16px;
              }
            }
            .label {
              display: inline-block;
              vertical-align: middle;
              width: 100%;
              white-space: pre-wrap;
            }

            &.right {
              padding-right: 9px;
            }
          }
        }
      }
    }

    .jacker-section {
      display: flex;
      height: calc(100% - 53px);
      align-items: center;
      justify-content: center;
      border-top: 1px solid $skyBlueGrey400;

      // Make them look like center-aligned
      .jacker-content {
        position: relative;
        top: 120px;
      }
    }
  }
}
</style>
