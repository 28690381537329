var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navigation"},[_c('div',{class:[
    'content',
    'content--centered',
    _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
    _vm.$arMediaQuery.pageContent.minWidth('sm') && 'sm-min',
    _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-min' ]},[_c('div',{staticClass:"menu-tab"},[_c('am2-tabs',{attrs:{"items":_vm.menuItems,"selected-tab-index":_vm.selectedMenuIndex,"item-style":{
          height: '48px',
        }},on:{"select":_vm.handleMenuSelect}})],1),_vm._v(" "),_c('div',{staticClass:"navigation__controls"},[_vm._t("controls")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }