<template>
  <section class="active-memebership-list">
    <am2-heading
      class="list-heading"
      type="h1"
      size="md"
      title="Active Memberships"
      :style="{
        marginBottom: '24px',
      }"
    />
    <div v-if="fanLoyaltyMemberships.length === 0 && !isFetchingLoyaltyMemberships">
      <ar-text
        size="xs"
        text="No active memberships"
        :style="{
          color: $arStyle.color.skyBlueGrey700,
        }"
      />
    </div>
    <template v-if="isFetchingLoyaltyMemberships">
      <div
        v-for="n in 1"
        :key="n"
        :style="{
          marginBottom: '20px',
        }"
      >
        <ar-skeleton
          :level="3"
          :style="{
            height: '16px',
            width: '150px',
            borderRadius: '3px',
          }"
        />
      </div>
    </template>
    <div
      v-for="membership of fanLoyaltyMemberships"
      :key="membership.oid"
      class="member-item-wrapper"
    >
      <div
        class="member-item"
        v-tooltip.top="{
          content: getLoyaltyTierTitle(membership),
        }"
      >
        <div class="icon-wrapper">
          <ar-icon
            :name="membership.provider"
            width="16px"
            :color="$arStyle.color[membership.provider]"
          />
        </div>
        <ar-text
          size="14px"
          :text="getMembershipProviderCopy(membership)"
        />
      </div>
    </div>
  </section>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
import template from '~/pages/message-center/modify/email/template.vue';

  export default {
  components: { template },
    name: 'ActiveMembership',
    computed: {
      ...mapState({
        customer: state => state.customer.customer,
        fanLoyaltyMemberships: state => state.customer.fanLoyaltyMemberships,
        isFetchingLoyaltyMemberships: state => state.customer.isFetchingLoyaltyMemberships,
        loyaltyProgramTiersMap: state => state.customer.loyaltyProgramTiersMap,
      }),
    },
    methods: {
      ...mapActions([]),
      getMembershipProviderCopy({ loyaltyProgram, provider }) {
        switch (provider) {
          case 'patreon':
            return 'Patreon';
          case 'memberful':
            return loyaltyProgram.name
          default:
            return null;
        }
      },
      getLoyaltyTierTitle(loyaltyMembership) {
        const loyaltyTier = this.loyaltyProgramTiersMap[loyaltyMembership.fanLoyaltyTier.loyaltyTierOid];
        switch (loyaltyMembership.provider) {
          case 'patreon':
            return `${this.customer.name} is a Patreon member — ${loyaltyTier ? loyaltyTier.title : 'Custom pledge'}`;
          case 'memberful':
            return `${this.customer.name} is a Memberful member`;
          default:
            return null;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.active-memebership-list {
  padding: 32px;

  .list-heading {
    margin-bottom: 24px;
  }

  .member-item-wrapper {
    margin-bottom: 10px;
    .member-item {
      display: inline-flex;
      align-items: center;

      .icon-wrapper {
        width: 32px;
      }
    }
  }
}
</style>
