import { render, staticRenderFns } from "./CampaignCard.vue?vue&type=template&id=33ff1322&scoped=true&"
import script from "./CampaignCard.vue?vue&type=script&lang=js&"
export * from "./CampaignCard.vue?vue&type=script&lang=js&"
import style0 from "./CampaignCard.vue?vue&type=style&index=0&id=33ff1322&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33ff1322",
  null
  
)

export default component.exports