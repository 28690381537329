<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="custom-field-modal"
      :header="title"
      width="600px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div slot="body" class="body">
        <div :class="[
          'modal-content',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
          <ar-field
            label="Custom field label"
            :error-messages="[veeErrors.first('custom-field-label')]"
          >
            <ar-input
              v-validate="'required|max:150'"
              :autocomplete="`custom-field-label`"
              :data-vv-name="`custom-field-label`"
              :data-vv-as="`custom field label`"
              placeholder="Custom field label"
              type="text"
              @input="handleFieldLabelInput"
              :has-error="veeErrors.has('custom-field-label')"
              :value="fieldInputLabel"
            />
          </ar-field>


          <ar-field
            label="Field type"
            class="u-margin-top-6" 
            :error-messages="[veeErrors.first('field-type')]"
          >
              <!--
              v-model="selectedTimezone"
                v-else-if="arTableHeader.value === 'Timezone'"
                -->
            <am2-field-type-select
              placeholder="Select field type..."
              class="selector"
              @input="handleFieldTypeInput"
              v-validate="'required'"
              :data-vv-name="`field-type`"
              :data-vv-as="`field type`"
              :value="fieldType"

            />
          </ar-field>

          <div
            v-if="enableOptions">
          
          <ar-field
            label="Options"
            class="u-margin-top-6" 
          />
          <draggable
            tag="div"
            :list="options"
            class="list-group"
            handle=".handle"
            @change="handleMove"
          >
            <div
              v-for="(element, idx) in options"
              :key="element.name"
            >
              <ar-field
                :error-messages="[veeErrors.first(`option-field-label-${idx}`)]"
              >
                <div 
                class="list-group-item">
                <ar-icon
                  name="menu"
                  :class="[
                    'u-margin-right-3',
                    'handle',
                  ]"
                  :color="$arStyle.color.skyBlueGrey600"
                />
                <!-- 
                  placeholder="Option"
                  -->
                <ar-input
                  :value="element.name"
                  v-validate="'required|max:150'"
                  :autocomplete="`option-field-label-${idx}`"
                  :data-vv-name="`option-field-label-${idx}`"
                  data-vv-as="text in this option"
                  type="text"
                  @input="handleInputOption(idx, ...arguments)"
                  @blur="handleBlurOption"
                />
                </div>
              </ar-field>

            </div>

            </draggable>
            <ar-link-button
              text="+ Add option"
              @click="handleAddOptionClick"
              :class="[
                'u-margin-left-4',
                'u-margin-top-2',
              ]"
            />
          <!-- </ar-field> -->
          </div>
        </div>
      </div>
      <div slot="footer" :class="[
        'footer',
        $arMediaQuery.window.maxWidth('xs') && 'u-padding-3',
      ]">
        <ar-simple-button
          text="Add custom field"
          @click="handleConfirmClick"
          :style="{ width: '160px' }"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from "vuedraggable";

const defaultOptionViewModel = {
  name: null,
  index: null
};

export default {
  name: 'CustomFieldModal',
  components: {
    draggable
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectValueMap: {},
      enableOptions: false,
      fieldInputLabel: "",
      options: [
        {
          name: "",
        }
      ],
      optionModel: defaultOptionViewModel,
      fieldType: null
    };
  },

  computed: {
  },

  mounted() {
    this.initializeSelectValueMap();
  },

  watch: {
    value() {
      this.initializeSelectValueMap();
    },
  },

  methods: {
    initializeSelectValueMap() {
      const newSelectValueMap = {};
      for (let i = 0; i < this.value.length; i += 1) {
        newSelectValueMap[this.value[i]] = true;
      }
      this.selectValueMap = newSelectValueMap;
    },
    async handleConfirmClick() {
      const isValid = await this.$validator.validateAll();
      if(!isValid) return;

      this.$emit('input', {
        // Fields native to Field type
        name: this.fieldInputLabel,
        key: this.getKey(),
        optional: false,
        predefined: false,
        // Fields for multiple option type of field (e.g. Multiple Checkboxes, Radio buttons)
        hasOptions: this.enableOptions,
        options: this.options,
        fieldType: this.fieldType,
      });
    },
    async handleCloseClick() {
      this.$emit('close');
    },
    getKey() {
      return this.fieldInputLabel.split(" ")[0].toLowerCase()
    },
    getSelectValues() {
      const selectValueMapKeys = Object.keys(this.selectValueMap);
      return selectValueMapKeys.filter(key => this.selectValueMap[key]);
    },
    handleFieldTypeInput(item) {
      this.enableOptions = item.enableOptions
      this.fieldType = item
    },
    handleFieldLabelInput(item) {
      this.fieldInputLabel = item
    },
    handleAddOptionClick(item) {
      this.options.push({ name: "" })
    },
    handleMove(item) {
      const tempElement = this.options[item.moved.newIndex]
      this.options[item.moved.newIndex] = this.options[item.moved.oldIndex]
      this.options[item.moved.oldIndex] = tempElement
    },
    handleInputOption(index, item) {
      this.optionModel.name = item
      this.optionModel.index = index
    },
    handleBlurOption() {
      this.options[this.optionModel.index].name = this.optionModel.name
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-field-modal {
  .header {
    font-size: 20px;
  }
  .body {
    .description {
      padding: 26px;
      background: $skyBlueGrey300;
      border-bottom: 1px solid $skyBlueGrey500;
      color: $blueGrey800;
      text-align: center;
    }

    .modal-content {
       padding: 29px 30px;
      .checkbox-section {
        border: 1px solid $skyBlueGrey500;
        border-radius: 4px;
        margin-top: 27px;
        .checkbox {
          display: flex;
          align-items: center;
          height: 51px;

          &:not(:last-child){
            border-bottom: 1px solid $skyBlueGrey500;
          }
          padding: 0 15px;

          .label {
            line-height: 1;
            margin-left: 10px;
          }
        }
      }
    }


    // .handle {
    //   float: left;
    //   padding-top: 8px;
    //   padding-bottom: 8px;
    // }

    .list-group-item {
      display: flex;
      margin-bottom: 10px;
    }


  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 25px;
  }
}
</style>
