<template>
  <div class="campaign-setup-type-page">
    <ar-text
      class="step-heading"
      size="xs"
      text="SETUP"
      :style="{
        color: '#9FA8B5',
        marginBottom: '8px',
      }"
    />
    <am2-heading
      type="h1"
      size="lg"
      title="Choose a campaign..."
      :style="{marginBottom: '24px'}"
    />

    <am2-category-tabs
      v-model="selectedCategory"
      :items="campaignCategories"
      :style="{
        marginBottom: '30px',
      }"
    />

    <am2-list-view
      :fetch-data-adapter="() => campaignTypes"
      :initial-selected="selected"
      :on-selected="selectCampaignType"
      layout="compact"
      :selected-category="selectedCategory"
    >

      <div class="info" slot="template" slot-scope="option">
        <ar-text
          size="sm"
          :text="option.name"
          weight="bold"
        />
      </div>

    </am2-list-view>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
  name: 'CampaignSetupType',

  data: () => ({
    stage: 1,
    selected: [],
    selectedCategory: "popular",
    campaignCategories: [
      { key: "popular", text: "Popular" },
      { key: "events", text: "Events" },
      { key: "artists", text: "Artists" },
      { key: "creators", text: "Creators" },
      { key: "brands", text: "Brands" },
      { key: "all", text: "See All" },
    ],
    competitionCategory: [
       {
        oid: 'competition',
        name: 'Competition',
        key: 'competition',
        title: 'Competition campaign',
        subtitle: 'Put up a great prize for your fans',
        description: 'Fans earn points for sharing, inviting their friends, and following you on social media. Reward the highest point scorers with cheaper presale tickets, free tickets or a prize.',
        features: ['Sell more tickets, faster', 'Build your audience', 'Collect powerful insights'],
        iconName: 'competition',
        iconWidth: '20',
      },
    ],
    popularCampaignTypes: [
      {
        oid: 'presale',
        name: 'Presale',
        key: 'presale',
        title: 'Presale campaign',
        subtitle: 'Make your presale go viral',
        description:
          'Fans register for presale access, and earn points for sharing, inviting their friends to register, and following you on social media. Reward the highest point scorers with cheaper presale tickets, free tickets or a prize. When registrations end, those who registered are sent their links to purchase presale tickets.',
        features: [
          'Get up to 263% more presale registrations',
          'Sell more tickets through building your audience',
          'Collect valuable data & insights',
        ],
        iconName: 'presale',
        iconWidth: '27',
      }, {
        oid: 'ballot',
        name: 'Ballot',
        key: 'ballot',
        title: 'Ballot campaign',
        subtitle: 'Reward your top supporters',
        description: 'Fans earn points for sharing, inviting their friends, and following you on social media. Reward the highest point scorers with cheaper presale tickets, free tickets or a prize.',
        features: ['Sell more tickets, faster', 'Build your audience', 'Collect powerful insights'],
        iconName: 'ballot',
        iconWidth: '23',
      }, {
        oid: 'competition',
        name: 'Competition',
        key: 'competition',
        title: 'Competition campaign',
        subtitle: 'Put up a great prize for your fans',
        description: 'Fans earn points for sharing, inviting their friends, and following you on social media. Reward the highest point scorers with cheaper presale tickets, free tickets or a prize.',
        features: ['Sell more tickets, faster', 'Build your audience', 'Collect powerful insights'],
        iconName: 'competition',
        iconWidth: '20',
      },
      {
        oid: 'waitlist',
        name: 'Waitlist',
        key: 'waitlist',
        title: 'Waiting list campaign',
        subtitle: 'Fans can join the waiting list for any extra tickets that become available',
        description: 'Fans earn points for sharing, inviting their friends, and following you on social media. Reward the highest point scorers with cheaper presale tickets, free tickets or a prize.',
        features: ['Sell more tickets, faster', 'Build your audience', 'Collect powerful insights'],
        iconName: 'waitlist',
        iconWidth: '22',
      },
    ],
    otherCampaignTypes: [
      // {
      //   oid: 'livestream',
      //   name: 'Live stream',
      //   key: 'livestream',
      //   title: 'Live stream campaign',
      //   subtitle: 'Drive views to your event\'s live stream',
      //   description: 'Fans can register their interest in your event\'s live stream, or even purchase access to it.',
      //   features: ['Sell more tickets, faster', 'Build your audience', 'Collect powerful insights'],
      //   iconName: 'video',
      //   iconWidth: '22',
      // },
      {
        oid: 'donate',
        name: 'Donate',
        key: 'donate',
        title: 'Donate campaign',
        subtitle: 'Fans and supporters can donate to your cause',
        description: 'Give your fans the ability to donate and show their support. Supporters can earn points for sharing, inviting their friends and following you on social media. Reward the highest point scorers with prizes.',
        features: ['Collect donations from fans and supporters', 'Build your audience', 'Collect powerful insights'],
        iconName: 'gift',
        iconWidth: '22',
      },
      {
        oid: 'preorder',
        name: 'Pre-Order',
        key: 'preorder',
        title: 'Pre-Order campaign',
        subtitle: 'Gather pre-orders for your product or event',
        description: 'Collect paid pre-orders before your product or event goes on sale. Fans earn points for sharing, inviting their friends, and following you on social media. Reward the highest point scorers with cheaper products, free products or a prize.',
        features: ['Sell more products, faster', 'Build your audience', 'Collect powerful insights'],
        iconName: 'tag',
        iconWidth: '22',
      }
    ],
  }),

  computed: {
    ...mapState({
      query: state => state.route.query,
    }),

    heading() {
      return this.stage === 1 ?
        'Create a new campaign' :
        `Create a ${this.template} campaign`;
    },
    hasNextPage(){
      return this.stage === 1;
    },
    campaignTypes() {
      if (this.selectedCategory == "popular" || this.selectedCategory == "events") {
        return this.popularCampaignTypes;
      } else if (this.selectedCategory == "all") {
        return this.popularCampaignTypes.concat(this.otherCampaignTypes)
      } else {
        return this.competitionCategory;
      }
    },
  },

  created() {
    if(this.query.type) {
      this.selected = [this.query.type]
    }
  },

  methods: {
    selectCampaignType(selectedCampaigns) {
      this.$emit('change', { type: selectedCampaigns[0] });
    },
  }
};
</script>
<style lang="scss" scoped>
.campaign-setup-type-page {
  .info {
    .icon-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 14px;
      width: 27px;
      .icon {
        color: $blueGrey700;
      }
    }
  }  
}

</style>
