var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-card-container',{class:[
    'base-plan-card-wrapper',
    _vm.showAs,
    _vm.hasNoPlan && 'no-plan' ]},[_c('am2-change-payment-source-modal',{attrs:{"is-open":_vm.displayChangePaymentSourceModal,"payment-plan":_vm.paymentPlan,"payment-source-puid":_vm.defaultPaymentSourcePuid,"payment-subscriptions":[_vm.paymentSubscription]},on:{"change":_vm.handlePaymentSourceChange,"cancel":_vm.handlePaymentSourceCancel}}),_vm._v(" "),_c('div',{class:['right-top-fix-section', _vm.showAs]},[(!_vm.hasNoPlan && !_vm.subscriptionIsNotRenewing)?_c('am2-icon-button-dropdown',{attrs:{"align":"left","items":_vm.actionItems,"dropdown-style":{
        width: '200px',
      }},on:{"select":_vm.handleActionClick}}):_vm._e()],1),_vm._v(" "),_c('ar-icon',_vm._b({},'ar-icon',_vm.planIconProps,false)),_vm._v(" "),_c('div',{staticClass:"text-section"},[_c('div',{staticClass:"text-section-upper-section"},[_c('ar-text',{style:({
          color: _vm.hasNoPlan ? 'white' : null,
        }),attrs:{"size":"md","text":_vm.planName,"weight":"bold"}}),_vm._v(" "),(_vm.subscriptionIsNotRenewing)?_c('am2-tag',{staticClass:"tag",attrs:{"type":"red","text":"Not renewing"}}):_vm._e()],1),_vm._v(" "),_c('ar-text',{staticClass:"description",style:({
        color: _vm.hasNoPlan ? 'white' : _vm.$arStyle.color.skyBlueGrey700,
      }),attrs:{"size":"xs","text":_vm.planDescription,"multiple-lines":""}})],1),_vm._v(" "),_c('div',{staticClass:"button-section"},[(_vm.hasNoPlan)?_c('ar-simple-button',{style:({
        border: 'none',
      }),attrs:{"text":"Select a plan","outlined":""},on:{"click":_vm.handleSelectPlanButtonClick}}):(_vm.subscriptionIsNotRenewing)?_c('ar-simple-button',{attrs:{"text":"Renew","loading":_vm.undoSubscriptionCancellationStatusMap[_vm.paymentSubscription.oid]},on:{"click":_vm.handleRenewClick}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }