/**
 * DELETE_SOCIAL_ACCOUNT
 *
 * @param { object } context - Store context (state, rootstate, getters, commit, dispatch)
 * @param { number } campaignOid
 */

export async function DELETE_SOCIAL_ACCOUNT({ state }, socialAccountOid) {
  if (!state.auth.account) { return null; }
  const { promoterOid } = state.auth.account;

  try {
    await this.$axios.delete(`/promoter/${promoterOid}/social-accounts/${socialAccountOid}`);
  } catch (error) {
    // TODO: Notify client
  }
}
