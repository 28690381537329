<template>
  <EditableFrame
    class="wait-until-sidebar-wrapper"
    title="Edit Action"
    :subtitle="item.name"
    has-save-button
    :save-button-disabled="savedButtonDisabled"
    @close="$emit('close')"
    @save="handleSave"
  >
    <ar-text
      class="wait-until-heading"
      size="13px"
      text="DELAY BY"
      weight="bold"
      multiple-lines
      :max-lines="1"
      letter-spacing="1px"
    />
    <div class="wait-until-wrapper">
      <ar-input
        class="wait-until-input"
        :style="{
          height: '50px',
          width: '163px',
          marginRight: '7px',
        }"
        v-model="waitUntilVal"
        type="number"
        data-test-id="wait-until-input"
      />
      <ar-simple-select
        class="order-dropdown"
        :items="waitUntilOptions"
        :default-select-index="selectedIndex"
        :style="{
          height: '50px',
          width: '100%',
          marginBottom: '20px'
        }"
        :dropdown-style="{
          padding: '8px 0',
          maxHeight: '400px',
        }"
        @select="handleDropdownItemSelect"
        data-test-id="wait-until-select"
      />
    </div>
  </EditableFrame>
</template>
<script>
import EditableFrame from '../utils/EditableFrame.vue'
import { mapActions } from 'vuex'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)
export default {
  name: 'WaitUntil',
  components: {
    EditableFrame
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      waitUntilVal: null,
      selectedTimeOption: 'minutes',
      selectedIndex: 0,
      waitUntilOptions: [
        { name: 'Minute', key: 'minutes'},
        { name: 'Hour', key: 'hours'},
        { name: 'Day', key: 'days'},
        { name: 'Week', key: 'weeks'},
        { name: 'Month', key: 'months'},
      ],
    }
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    }
  },
  computed: {
    savedButtonDisabled() {
      return !this.waitUntilVal
    },
  },
  methods: {
    ...mapActions({
      updateActionConfig: 'automation/UPDATE_ACTION_CONFIG'
    }),
    getDelaySeconds() {
      return dayjs.duration(this.waitUntilVal, this.selectedTimeOption).asSeconds()
    },
    handleDropdownItemSelect(item) {
      this.selectedTimeOption = item.key
    },
    setSelectedIndex() {
      let duration = this.item.config.frontend.delay['selected-key'] || this.item.config.frontend.delay['selectedKey']
      this.selectedIndex = this.waitUntilOptions.findIndex(item => item.key === duration)
      this.selectedTimeOption = duration
    },
    handleSave() {
        let contextData = {
          config: {
            'delay-sec': this.getDelaySeconds(),
            frontend: {
              delay: {
                val: this.waitUntilVal,
                selectedKey: this.selectedTimeOption
              }
            }
          },
          meta: {
            delay: {
              val: this.waitUntilVal,
              selectedKey: this.selectedTimeOption
            }
          }

        }
        this.updateActionConfig({id: this.item.id, data: contextData})
        this.$emit('close')
    },
    setItemVals() {
      if (this.item.config?.['delay-sec']) {
        this.waitUntilVal = this.item.config.frontend.delay.val
        this.setSelectedIndex()
      }
    }
  },
  mounted() {
    this.setItemVals()
  }
}
</script>
<style lang="scss" scoped>
.wait-until-sidebar-wrapper {
  width: 100%;
  height: 100%;

  .wait-until-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 16px;
  }
}
</style>