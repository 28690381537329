<template>
  <header class="ar-hero">
    <div
      :class="['hero-image', image && 'has-filter']"
      :style="{'backgroundImage': image ? `url(${image}` : null}"
    />
    <div class="ar-hero__wrapper content content--centered">
      <!-- Back to, navigation link -->
      <am2-step-link
        class="back-link"
        :text="stepBackText"
        @click="handleStepLinkClick"
      />
      <!-- <div
        v-if="!isCustomer"
        :class="[
          'title-container',
          isEvents && 'event'
        ]">

        <div class="page-tag-container">
          <div v-if="isTours" class="page-tag">
            <span class="page-tag-type">Tour</span>
          </div>
          <div v-else-if="!isEvents && !isCustomer" class="page-tag">
            <span class="page-tag-type">{{ type }}</span><router-link :to="`/events/${eventOid}/view/campaigns`"><button class="page-tag-title">{{ eventName }}</button></router-link>
          </div>
        </div>

        <am2-heading
          type="h1"
          size="xl"
          :title="title"
          :style="{ color: 'white' }"
        />
      </div>

      <div
        v-if="isCustomer"
        class="customer-data-container">

        <ar-avatar
          :image-path="avatarImage"
          :name="title"
          :size="$arMediaQuery.pageContent.maxWidth('xs') ? '60px' : '80px'"
          color="grey"
          text-size="lg"
        />

        <div class="customer-data-information">
          <am2-heading
            type="h1"
            :size="$arMediaQuery.pageContent.maxWidth('xs') ? 'lg' : 'xl'"
            multiple-lines
            :title="title"
            :style="{ color: 'white' }"
          />
          <div class="customer-data-information__subheader">
            <ar-text
              v-if="customerAge"
              :text="customerAge"
              size="xs"
              :style="{
                color: 'white',
                marginRight: '7px',
              }"
            />
            <ar-text
              v-if="customerAge && location"
              text="•"
              size="xxxs"
              :style="{
                color: 'white',
                marginRight: '7px',
              }"
            />
            <ar-link-button
              v-if="location"
              :text="location"
              color="#ffffff"
              @click="handleLocationClick"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div :class="['col-xs-12', 'info', $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']">
          <p v-if="isEvents && location" class="info-cell location">
            <ar-icon
              height="16px"
              name="location"
              color="white"
              :style="{
                marginRight: '7px',
              }"
            />
            <ar-text
              :text="location"
              size="xs"
              :style="{
                color: 'white',
              }"
            />
          </p>
          <p v-else class="info-cell location">
            <ar-icon
              height="16px"
              name="location"
              color="white"
              :style="{
                marginRight: '7px',
              }"
            />
            <ar-text
              text="Online"
              size="xs"
              :style="{
                color: 'white',
              }"
            />
          </p>
          <p v-if="timeframe && timeframe" class="info-cell timeframe">
            <ar-icon
              height="16px"
              name="clock"
              color="white"
              :style="{
                marginRight: '7px',
              }"
            />
            <ar-text
              :text="timeframe"
              size="xs"
              :style="{
                color: 'white',
              }"
            />
          </p>
          <a v-if="!isEvents && !isCustomer" :href="previewURL(urlSlug)" target="_blank" class="info-cell url">
            <ar-icon
              height="16px"
              name="link"
              color="white"
              :style="{
                marginRight: '7px',
              }"
            />
            <ar-text
              :text="previewURL(urlSlug)"
              allow-html
              size="xs"
              :style="{
                color: 'white',
              }"
            />
          </a>
        </div>
      </div> -->


      <div
        :class="[
          'u-display-flex',
          'event-wrapper'
        ]"

      >
        <div
          class="left-container"
        >
          <div
            v-if="!isCustomer"
            :class="[
              'title-container',
              isEvents && 'event'
            ]">

            <div class="page-tag-container">
              <div v-if="isTours" class="page-tag">
                <span class="page-tag-type">Tour</span>
              </div>
              <div v-else-if="!isEvents && !isCustomer" class="page-tag">
                <span class="page-tag-type">{{ type }}</span><router-link :to="`/events/${eventOid}/view/campaigns`"><button class="page-tag-title">{{ eventName }}</button></router-link>
              </div>
            </div>

            <am2-heading
              type="h1"
              size="xl"
              :title="title"
              :style="{ color: 'white' }"
            />
          </div>

          <div
            v-if="isCustomer"
            class="customer-data-container">

            <ar-avatar
              :image-path="avatarImage"
              :name="title"
              :size="$arMediaQuery.pageContent.maxWidth('xs') ? '60px' : '80px'"
              color="grey"
              text-size="lg"
            />

            <div class="customer-data-information">
              <div class="u-display-flex u-align-items-center">
                <am2-heading
                  type="h1"
                  :size="$arMediaQuery.pageContent.maxWidth('xs') ? 'lg' : 'xl'"
                  multiple-lines
                  :title="title"
                  :style="{ color: 'white' }"
                />
                <div
                  class="u-display-flex"
                  :style="{
                    position: 'relative',
                    marginLeft: '30px',
                  }"
                >
                  <ar-icon
                    v-for="(membershipAvatar, idx) of membershipAvatars"
                    :key="idx"
                    :name="membershipAvatar.iconName"
                    height="15px"
                    :wrapper-style="{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '29px',
                      height: '29px',
                      borderRadius: '50%',
                      background: 'white',
                      boxShadow: '0 0 13px 1px rgba(255,255,255,0.55)',
                    }"
                    v-tooltip.bottom="{
                      content: membershipAvatar.tooltip,
                    }"
                    :style="{
                      marginRight: '10px',
                    }"
                    :color="membershipAvatar.iconColor || $arStyle.color.blueGrey800"
                  />
                </div>
              </div>
              <div class="customer-data-information__subheader">
                <ar-text
                  v-if="customerAge"
                  :text="customerAge"
                  size="xs"
                  :style="{
                    color: 'white',
                    marginRight: '7px',
                  }"
                />
                <ar-text
                  v-if="customerAge && location"
                  text="•"
                  size="xxxs"
                  :style="{
                    color: 'white',
                    marginRight: '7px',
                  }"
                />
                <ar-link-button
                  v-if="location"
                  :text="location"
                  color="#ffffff"
                  @click="handleLocationClick"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div :class="['col-xs-12', 'info', $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']">
              <p v-if="isEvents && location" class="info-cell location">
                <ar-icon
                  height="16px"
                  name="location"
                  color="white"
                  :style="{
                    marginRight: '7px',
                  }"
                />
                <ar-text
                  :text="location"
                  size="xs"
                  :style="{
                    color: 'white',
                  }"
                />
              </p>
              <p v-else-if="isEvents" class="info-cell location">
                <ar-icon
                  height="16px"
                  name="location"
                  color="white"
                  :style="{
                    marginRight: '7px',
                  }"
                />
                <ar-text
                  text="Online"
                  size="xs"
                  :style="{
                    color: 'white',
                  }"
                />
              </p>
              <p v-if="timeframe && timeframe" class="info-cell timeframe">
                <ar-icon
                  height="16px"
                  name="clock"
                  color="white"
                  :style="{
                    marginRight: '7px',
                  }"
                />
                <ar-text
                  :text="timeframe"
                  size="xs"
                  :style="{
                    color: 'white',
                  }"
                />
              </p>
              <a v-if="!isEvents && !isCustomer" :href="previewURL(urlSlug)" target="_blank" class="info-cell url">
                <ar-icon
                  height="16px"
                  name="link"
                  color="white"
                  :style="{
                    marginRight: '7px',
                  }"
                />

                <ar-text
                  :text="previewURL(urlSlug)"
                  allow-html
                  size="xs"
                  :style="{
                    color: 'white',
                  }"
                />
              </a>
            </div>
          </div>
        </div>

        <div
          class="right-container"
          v-if="isEvents && urlSlug"
        >
          <div>
            <ar-text
              text="Your Event URL"
              size="xxs"
              :style="{
                color: 'white',
              }"
            />
          </div>
          <div class="slug-url-wrapper">
            <a class="slug-url" :href="previewURL(urlSlug)" target="_blank">
              <ar-icon
                height="12px"
                name="link"
                color="white"
                :style="{
                  marginRight: '7px',
                }"
              />
              <ar-text
                :text="previewURL(urlSlug)"
                allow-html
                class="url"
                size="xxs"
                :style="{
                  color: 'white',
                  cursor: 'pointer',
                  height: 'default',
                }"
              />
            </a>
            <div class="divider"></div>
            <div class="copy-link" @click="handleInputLabelClick">
              <ar-text
                :text="copyLinkLabel"
                allow-html
                size="xxs"
                align="center"
                :style="{
                  color: 'white',
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  </header>
</template>

<script>
import { campaignBaseUri } from '@/utils/campaign';
import { copyToClipboard } from '@/utils/html-element';
import { mapGetters } from 'vuex';
import template from '~/pages/message-center/modify/email/template.vue';

export default {
  components: { template },
  props: {
    image: {
      type: String,
      default: null
    },
    avatarImage: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    membershipAvatars: {
      type: Array,
      default: () => [],
    },
    timeframe: {
      type: String,
      default: ''
    },
    location: {
      type: String,
      default: ''
    },
    urlSlug: {
      type: String,
      default: ''
    },
    backPath: {
      type: String,
      default: ''
    },
    eventOid: {
      type: Number,
    },
    eventName: {
      type: String,
      default: ''
    },
    customerAge: {
      type: String,
      default: '',
    },
    isEvents: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCampaigns: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTours: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCustomer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      c2ClientDomain: process.env.arCampaignClientDomain,
      hasCopied: false,
    };
  },
  computed: {
    stepBackText() {
      if (this.isEvents) {
        return `Back to events`
      } else if (this.isTours) {
        return `Back to tours`
      } else if (this.isCustomer) {
        if ((this.backPath || "").startsWith("/message-center")) {
          return "Back to message"
        } else if ((this.backPath || "").startsWith("/events")) {
          return "Back to event"
        } else if ((this.backPath || "").startsWith("/campaigns")) {
          return "Back to campaign"
        }
        return `Back to audience`
      } else if (this.isCampaigns) {
        return `Back to campaigns`
      }
      return "Back"
    },
    mapsUrl() {
      if(!this.location) return false;
      return `https://maps.google.com/maps?q=${encodeURIComponent(this.location.replace(/ /g, '+'))}`
    },

    copyLinkLabel() {
      if (this.hasCopied) {
        return 'Copied';
      } else {
        return 'Copy';
      }
    },
  },
  methods: {
    previewURL(urlSlug) {
      if (this.isEvents && urlSlug) {
        return `${this.c2ClientDomain}/r/${encodeURIComponent(urlSlug)}`;
      } else if (this.isCampaigns) {
        return `${campaignBaseUri(this.type)}${encodeURIComponent(urlSlug)}`;
      } else if (this.isTours) {
        return `${this.c2ClientDomain}/t/${encodeURIComponent(urlSlug)}`;
      }
    },
    handleStepLinkClick() {
      this.$router.push({
        path: this.backPath || this.$route.from.fullPath,
      });
    },
    handleLocationClick() {
      window.open(this.mapsUrl, '_blank');
    },
    handleInputLabelClick() {
      copyToClipboard(this.previewURL(this.urlSlug));
      this.hasCopied = true;

      // Reset copy status
      setTimeout(() => {
        this.hasCopied = false;
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-hero {
  position: relative;
  height: 320px;
  overflow: hidden;

  .back-link {
    color: white;
    position: relative;
    margin-top: 25px;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 177px;

    &.event {
      justify-content: flex-end;
    }
  }

  .customer-data-container {
    display: flex;
    padding-top: 80px;

    .customer-data-information {
      position: relative;
      top: -2px;
      display: flex;
      flex-direction: column;
      margin-left: 22px;

      &__subheader {
        display: flex;
        flex-direction: row;
        margin-top: 4px;
      }
    }
  }

  .page-tag {
    display: inline-block;
    border-radius: 5px;
    border: 1px solid #ffffff44;
    line-height: 24px;
    margin-bottom: 10px;
    overflow: hidden;
    height: 30px;
  }
  .page-tag-type {
    display: inline-block;
    padding: 3px 13px 1px 13px;
    background-color: #ffffff33;
    text-transform: capitalize;
    font-weight: bold;
  }
  .page-tag-title {
    padding: 0 13px 0 13px;
    text-overflow: ellipsis;
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
    &:focus { outline: 0; }
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    color: white;
    padding-top: 19px;
    padding-bottom: 19px;
  }

  .info {
    position: relative;
    display: flex;
    // margin-bottom: 1rem;
    &.sm-max {
      flex-direction:column;

      .info-cell {
        margin-bottom:12px;
      }
    }
    .info-cell {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-right: 30px;
      }
      &.location, &.url {
        min-width: 0;
        flex-shrink: 1;
      }
      &.timeframe {
        flex-shrink: 0;
      }
    }
  }
  .url:hover {
    text-decoration: underline;
  }

  a {
    color: white;
    text-decoration: none;
  }
}

.hero-image {
  object-fit: cover;
  margin: -15px 0px 0px -15px;
  width: calc(100% + 30px);
  height: calc(100% + 30px);
  background: $purple800;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 430px;
  position: absolute;

  &.has-filter {
    filter: blur(15px) brightness(80%);
  }
}

.event-wrapper {
  height: 100%;
  justify-content: space-between;

  .left-container {
    overflow: hidden;
    align-items: flex-end;
    .title-container {
      margin-bottom: 22px;
    }
  }

  .right-container {
    display: flex;
    align-items: flex-end;
    // height: 100%;
    width: 100%;
    justify-content: flex-end;
    flex-direction: column;
    margin-bottom: 18px;
    max-width: 300px;

    .slug-url-wrapper {
      margin-top: 14px;
      background-color: rgba(255, 255, 255, 0.2);
      height: 36px;
      display: flex;
      flex-direction: row;
      // justify-content: center;
      align-items: center;
      border-radius: 3px;
      padding: 0px 13px;

      .slug-url {
        display: flex;
        flex-direction: row;
        max-width: 220px;

        .url {
          max-width: 230px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .divider {
        width: 1px;
        height: 17px;
        background-color: #fff;
        margin: 0 15px;
      }

      .copy-link {
        width: 45px;
        cursor: pointer;
      }
    }
  }
}

</style>
