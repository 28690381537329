var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ar-modal',{staticClass:"import-data-modal",attrs:{"is-open":_vm.isVisible,"header":_vm.header,"has-back-link":_vm.step > 1 || (_vm.step === 1 && _vm.isMassImport),"hide-footer":_vm.step === 1,"width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.onClose,"back":_vm.previousStep}},[(_vm.step===0)?_c('div',{class:[
      'import-data-modal__wrapper',
      'generic',
      _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"body"},slot:"body"},_vm._l((_vm.massImportOptions),function(option,index){return _c('div',{key:index,class:[
        'mass-import-option',
        _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],on:{"click":function () { return _vm.selectType(option); }}},[_c('div',{class:[
        'icon-container',
        _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[(option === 'events and orders')?_c('ar-icon',{style:({
            position: 'relative',
            top: _vm.$arMediaQuery.pageContent.maxWidth('xs') ? '2px' : '3px',
            left: _vm.$arMediaQuery.pageContent.maxWidth('xs') ? '2px' : '3px',
          }),attrs:{"name":"calendar-ticket-combined","width":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? '30px' : '38px',"height":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? '24px' : '31px',"color":"#7344c0"}}):(option === 'events')?_c('ar-icon',{attrs:{"name":"calendar","width":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? '20px' : '25px',"height":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? '20px' : '25px',"color":"#7344c0"}}):_c('ar-icon',{attrs:{"name":"ticket","width":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? '24px' : '30px',"height":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? '24px' : '30px',"color":"#7344c0"}})],1),_vm._v(" "),_c('div',{class:[
        'mass-import-option__description',
        _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-right-7' ]},[_c('p',{staticClass:"heading"},[_vm._v("Import "+_vm._s(option))]),_vm._v(" "),_c('p',{staticClass:"body"},[_vm._v("Import multiple "+_vm._s(option)+" from your ticketing provider")])]),_vm._v(" "),_c('div',{class:_vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max'},[_c('span',[_vm._v("❯")])])])}),0):(_vm.step===1)?_c('div',{class:[
      'import-data-modal__wrapper',
      _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"body"},slot:"body"},[_c('p',{staticClass:"import-data-modal__wrapper_preamble"},[_vm._v("\n      Upload "+_vm._s(_vm.type === 'audience' || _vm.type === 'orders' ? 'orders' :
        _vm.type === 'events' ? 'multiple events' : 'multiple events and orders')+" from your ticketing provider.\n    ")]),_vm._v(" "),_c('am2-dropzone',{staticClass:"u-margin-top-5",attrs:{"file-type":['text/csv'],"file-size":50000000,"file-type-alias":"CSV file","placeholder-icon":{
        name: 'upload',
        width: '16px',
        height: '16px',
        color: _vm.$arStyle.color.purple500,
        wrapperStyle: {
          background: _vm.mouseOverCsvDropdown ? "white" : _vm.$arStyle.color.skyBlueGrey400,
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: _vm.mouseOverCsvDropdown ? '2px solid white' : null,
        },
      },"ratio":0.525},on:{"upload":_vm.handleCSVUploaded},nativeOn:{"mouseover":function($event){_vm.mouseOverCsvDropdown = true},"mouseleave":function($event){_vm.mouseOverCsvDropdown = false}}})],1):(_vm.step===2)?_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',{class:[
        'mapping-instructions',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-3'
      ]},[_c('p',[_vm._v("\n        Map the fields to import into Audience Republic from your CSV file\n      ")]),_vm._v(" "),_c('p',{staticClass:"mapping-instructions__subtitle"},[_vm._v("\n        * indicates a compulsory field\n      ")])]),_vm._v(" "),(_vm.type === 'events and orders')?_c('am2-tabs',{staticClass:"tabs",attrs:{"items":[{
        id: 'events',
        title: 'Event information',
      }, {
        id: 'orders',
        title: 'Order information'
      }]},on:{"select":_vm.changeMapping}}):_vm._e(),_vm._v(" "),_c('div',{class:[
        'import-data-modal__wrapper',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'xs-max',
        _vm.type === 'events and orders' ? 'eventsAndOrders' : 'notEventAndOrders' ]},[_c('p',{staticClass:"column-text"},[_c('span',[_vm._v("Audience Republic")]),_c('span',[_vm._v("CSV File")])]),_vm._v(" "),(_vm.type === 'events and orders')?_c('div',{staticClass:"form"},_vm._l((_vm.visibleHeaderSelections),function(arTableHeader,index){return _c('div',{key:index,staticClass:"form-row"},[_c('p',{staticClass:"col-xs-12 col-sm-5 form-row__label"},[_vm._v(_vm._s(("" + (arTableHeader.value) + (arTableHeader.required ? '*' : '')))+"\n            "),(arTableHeader.hint && _vm.$arMediaQuery.window.minWidth('sm'))?_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                content: arTableHeader.hint,
              }),expression:"{\n                content: arTableHeader.hint,\n              }",modifiers:{"top":true}}],staticClass:"icon",attrs:{"name":"tooltip-question-mark","height":"15px","width":"15px"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"col-xs-12 col-sm-7 form-row__input"},[(arTableHeader.value === 'Currency')?_c('ar-simple-select',{staticClass:"selector",attrs:{"enable-filter":"","items":_vm.currencyOptions,"placeholder":"Please select...","enable-clear":"","default-select-index":_vm.selectedCurrencyIndex},on:{"select":_vm.selectCurrency,"clear":function () { return _vm.selectCurrency(null); }}}):(arTableHeader.value === 'Timezone')?_c('am2-timezone-select',{staticClass:"selector",attrs:{"placeholder":"Please select..."},model:{value:(_vm.selectedTimezone),callback:function ($$v) {_vm.selectedTimezone=$$v},expression:"selectedTimezone"}}):_c('ar-simple-select',{staticClass:"selector",attrs:{"enable-filter":"","items":_vm.csvHeadersOptions(arTableHeader.mappedTo),"placeholder":"Please select...","enable-clear":"","default-select-index":arTableHeader.mappedTo === null ? null : 0},on:{"select":function (val) { return _vm.setMapping(val, arTableHeader); },"clear":function () { return _vm.setMapping({ index: null }, arTableHeader); }}}),_vm._v(" "),(arTableHeader.error)?_c('div',{staticClass:"error"},[_vm._v("\n              "+_vm._s(arTableHeader.error)+"\n            ")]):_vm._e()],1)])}),0):_c('div',{staticClass:"form"},_vm._l((_vm.arToPromoterMap),function(arTableHeader,index){return _c('div',{key:index,staticClass:"form-row"},[_c('p',{staticClass:"col-xs-12 col-sm-5 form-row__label"},[_vm._v(_vm._s(("" + (arTableHeader.value) + (arTableHeader.required ? '*' : '')))+"\n            "),(arTableHeader.hint && _vm.$arMediaQuery.window.minWidth('sm'))?_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                content: arTableHeader.hint,
              }),expression:"{\n                content: arTableHeader.hint,\n              }",modifiers:{"top":true}}],staticClass:"icon",attrs:{"name":"tooltip-question-mark","height":"15px","width":"15px"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"col-xs-12 col-sm-7 form-row__input"},[(arTableHeader.value === 'Currency')?_c('ar-simple-select',{staticClass:"selector",attrs:{"enable-filter":"","items":_vm.currencyOptions,"default-select-index":_vm.selectedCurrencyIndex,"placeholder":"Please select...","enable-clear":""},on:{"select":_vm.selectCurrency,"clear":function () { return _vm.selectCurrency(null); }}}):(arTableHeader.value === 'Timezone')?_c('am2-timezone-select',{staticClass:"selector",attrs:{"placeholder":"Please select..."},model:{value:(_vm.selectedTimezone),callback:function ($$v) {_vm.selectedTimezone=$$v},expression:"selectedTimezone"}}):_c('ar-simple-select',{staticClass:"selector",attrs:{"enable-filter":"","items":_vm.csvHeadersOptions(arTableHeader.mappedTo),"placeholder":"Please select...","enable-clear":"","default-select-index":arTableHeader.mappedTo === null ? null : 0},on:{"select":function (val) { return _vm.setMapping(val, arTableHeader); },"clear":function () { return _vm.setMapping({index: null}, arTableHeader); }}}),_vm._v(" "),(arTableHeader.error)?_c('div',{staticClass:"error"},[_vm._v("\n              "+_vm._s(arTableHeader.error)+"\n            ")]):_vm._e()],1)])}),0)])],1):(_vm.step===3)?_c('div',{class:[
      'import-data-modal__wrapper',
      _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"body"},slot:"body"},[_c('p',{staticClass:"column-text"},[_c('span',[_vm._v("Audience Republic")]),_c('span',[_vm._v("CSV File")])]),_vm._v(" "),_c('p',{staticClass:"import-data-modal__wrapper__import-message"},[_vm._v("\n      "+_vm._s(_vm.importCompleteMessage)+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.step >= 2)?_c('div',{staticClass:"import-data-modal__footer",attrs:{"slot":"footer"},slot:"footer"},[(_vm.step===2)?_c('div',{staticClass:"footer-container"},[_c('div',[(_vm.error)?_c('div',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.error)}}):_vm._e(),_vm._v(" "),_c('div',{class:[
          'footer-controls',
          _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-3' ]},[_c('div',{class:[
            'ticketing-provider',
            _vm.$arMediaQuery.window.maxWidth('xs') && 'xs-max' ],staticStyle:{"display":"flex"}},[(_vm.selectedProvider)?_c('div',{staticClass:"tick-container"},[_c('ar-icon',{attrs:{"name":"check","height":"9px","color":"white"}})],1):_vm._e(),_vm._v(" "),_c('ar-text',{style:({
                marginLeft: _vm.$arMediaQuery.window.maxWidth('xs') ? '5px' : '10px',
                marginBottom: _vm.$arMediaQuery.window.maxWidth('xs') ? '12px' : '0',
              }),attrs:{"size":"xs","text":"Ticketing provider:"}}),_vm._v(" "),_c('ProviderChooser',{staticClass:"provider-chooser",attrs:{"items":_vm.availableProviders,"default-selected-index":_vm.selectProviderIndex},on:{"select":_vm.selectProvider,"create-provider":_vm.createProvider}})],1),_vm._v(" "),_c('ar-simple-button',{style:({ float: 'right' }),attrs:{"text":"Import","loading":_vm.isImportingData},on:{"click":_vm.beginImport}})],1)])]):(_vm.step===3)?_c('div',{staticClass:"footer-container"},[(_vm.error)?_c('p',[_vm._v("\n        "+_vm._s(_vm.error)+"\n      ")]):_vm._e(),_vm._v(" "),_c('ar-simple-button',{style:({ float: 'right' }),attrs:{"text":"Close"},on:{"click":_vm.onClose}})],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }