<template>
  <section>
    <am2-heading
      type="h1"
      size="lg"
      title="Setup the basics"
    />
    <ar-field
      label="To"
      :style="{
        marginTop: '40px',
      }"
    >
      <div>
        <am2-message-list-select
          :channels="['email']"
          :value="scratchEmailMessage.meta.messageListOid"
          :campaign-oid="scratchEmailMessageCampaignOid"
          :event-oid="scratchEmailMessageEventOid"
          @select="handleMessageListChange"
          data-test-id="message-list-select"
        />
        <ar-snackbar
          v-if="currentMessageListHasNoEmailContacts"
          class="u-width-100-percent"
          type="error"
          message="There are no valid email addresses in this list."
          :style="{ marginTop: '10px' }"
        />
        <ar-snackbar
          v-if="currentSelectedMessage && currentMessageListIsForAllCampaignRegistrants"
          class="u-margin-top-5 u-width-100-percent"
          type="warning"
          message="Changing from all registrants to a tier will disable scheduling."
        />
        <ar-state-message
          v-if="currentCampaignIsActive && currentMessageListIsForCampaignTier"
          class="u-margin-top-5"
          :text-props="{
            weight: 'bold',
          }"
          has-icon
          disable-text-color
          type="information"
          text="Note: It's recommended to only message individual tiers once the campaign is ended."
        />

      </div>
    </ar-field>

    <am2-message-list-advanced-targeting-section
      v-if="showAdvancedTargetingModule"
      channel="email"
      show-toggle
      @updateAdvancedTargeting="handleAdvancedTargetingUpdate"
    />

    <ar-field
      label="From"
      :style="{
        marginTop: '40px',
      }"
    >
      <div>
        <am2-message-sender-select
          ref="messageSenderSelect"
          :value="scratchEmailMessage.meta.email && scratchEmailMessage.meta.email.promoterPropertyOid"
          @select="handleSenderSelect"
          @clear="handleMessageSenderClear"
        />
      </div>
      <ar-snackbar
        v-if="currentMessageSenderNotVerified"
        class="u-width-100-percent"
        type="error"
        message="Please verify this email address. <a>Resend verification email</a>."
        :style="{ marginTop: '10px' }"
        @anchorClick="handleResendVerificationEmailAnchorClick"
      />
    </ar-field>
    <ar-field
      label="Subject"
      :style="{
        marginTop: '40px',
      }"
    >
      <ar-input
        :value="scratchEmailMessage.meta.messageBody.subject"
        @input="handleSubjectInput"
      />
    </ar-field>
    <ar-field
      label="Preview line"
      :style="{
        marginTop: '40px',
      }"
    >
      <ar-input
        :value="scratchEmailMessage.meta.messageBody.previewText"
        @input="handlePreviewTextInput"
        autocomplete="preview-line"
      />
    </ar-field>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';

export default {
  name: 'Basic',

  data() {
    return {
      arEnableMessageAdvancedTargeting: process.env.arEnableMessageAdvancedTargeting,
    }
  },

  computed: {
    ...mapState({
      messageOid: (state) => parseInt(state.route.params.oid, 10),
      isFetchingMessage: state => state.message.isFetchingMessage,
      currentCampaign: state => state.campaign.currentCampaign,
      currentMessageList: state => state.messageList.currentMessageList,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      scratchEmailMessage: state => state.message.scratchEmailMessage,
      currentMessageSender: state => state.messageSender.currentMessageSender,
    }),
    ...mapGetters({
      scratchEmailMessageCampaignOid: 'message/scratchEmailMessageCampaignOid',
      scratchEmailMessageEventOid: 'message/scratchEmailMessageEventOid',
      currentCampaignIsActive: 'campaign/currentCampaignIsActive',
      currentMessageSenderNotVerified: 'messageSender/currentMessageSenderNotVerified',
      currentMessageListHasNoEmailContacts: 'messageList/currentMessageListHasNoEmailContacts',
      currentMessageListIsForAllCampaignRegistrants: 'messageList/currentMessageListIsForAllCampaignRegistrants',
      currentMessageListIsForCampaignTier: 'messageList/currentMessageListIsForCampaignTier',
      currentMessageListCanBeSchedued: 'messageList/currentMessageListCanBeSchedued',
      getCurrentFilterExpression: 'messageList/getCurrentFilterExpression',
    }),

    // We don't want to show the adv targeting module on campaign sends, if no message list is selected
    // or while a draft message is loading
    showAdvancedTargetingModule() {
      if (!this.arEnableMessageAdvancedTargeting) return false;
      if (this.scratchEmailMessageCampaignOid) return false;
      if (this.scratchEmailMessageEventOid) return false;
      if (this.messageOid && !this.currentSelectedMessage) return false;
      if (this.isFetchingMessage) return false;
      if (!this.scratchEmailMessage) return false;
      if (!this.scratchEmailMessage.meta.messageListOid) return false;
      return true;
    },
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'messageSender/RESEND_EMAIL_VERIFICATION',
      'messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT',
    ]),
    ...mapMutations([
      'messageSender/SET_CURRENT_MESSAGE_SENDER',
      'messageSender/RESET_CURRENT_MESSAGE_SENDER',
      'messageList/SET_CURRENT_MESSAGE_LIST',
      'message/PATCH_SCRATCH_EMAIL_MESSAGE',
      'message/REMOVE_DYNAMIC_TAGS_IN_SCRATCH_EMAIL_MESSAGE',
      'message/PUT_FILTERING_IN_SCRATCH_EMAIL_MESSAGE',
    ]),
    clearScheduleTime() {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          presentation: {
            timeZone: null,
          },
        },
        scheduledAt: null,
      });
    },
    handleMessageSenderClear() {
      this['messageSender/RESET_CURRENT_MESSAGE_SENDER']();
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          email: {
            promoterPropertyOid: null,
          },
        },
      });
    },
    handleSenderSelect(sender) {
      this['messageSender/SET_CURRENT_MESSAGE_SENDER'](sender);
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          email: {
            promoterPropertyOid: sender.oid,
          },
        },
      });
    },
    handleSubjectInput(subject) {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          messageBody: {
            subject,
          },
        },
        name: subject,
      });
    },
    handlePreviewTextInput(previewText) {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          messageBody: {
            previewText,
          },
        },
      });
    },
    handleMessageListChange(messageList) {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          messageListOid: messageList.oid,
        },
      });
      // The old dynamic tags file was mapped to old list, so we have to clean it up
      this['message/REMOVE_DYNAMIC_TAGS_IN_SCRATCH_EMAIL_MESSAGE']();
      this['messageList/SET_CURRENT_MESSAGE_LIST'](messageList);
      // RESET ADVANCED FILTERING
      if (!this.currentMessageListCanBeSchedued) {
        this.clearScheduleTime();
      }
    },
    handleResendVerificationEmailAnchorClick(anchorIdx) {
      if (anchorIdx === 0) {
        this['messageSender/RESEND_EMAIL_VERIFICATION'](this.currentMessageSender.oid);
      }
    },
    handleAdvancedTargetingUpdate() {
      const targetingFilter = this.getCurrentFilterExpression;
      this['message/PUT_FILTERING_IN_SCRATCH_EMAIL_MESSAGE'](targetingFilter);
      this['messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT']({
        channel: 'email',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
