<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="multi-select-modal"
      :header="title"
      width="600px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div slot="body" class="body">
        <p v-if="description" :class="[
          'description',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-y-3',
        ]">
          {{ description }}
        </p>
        <div :class="[
          'modal-content',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
          <am2-heading
            v-if="subtitle"
            class="subtitle"
            type="h2"
            size="xs"
            :title="subtitle"
            weight="bold"
          />
          <div
            class="checkbox-section"
            :style="{
              marginTop: !subtitle && !description ? '1px' : null,
            }"
          >
            <div
              v-for="item of items"
              :key="item.value"
              class="checkbox"
            >
              <ar-checkbox
                v-model="selectValueMap[item.value]"
                :disabled="item.disabled || false"
                :label="item.name"
              />
              <am2-tag
                v-if="!!item.error"
                type="red"
                text="Error"
                v-tooltip.top="{
                  content: item.error && item.error.userErrorString ? item.error.userErrorString : null
                }"
                text-size="xxxs"
                class="u-margin-left-3"
              />
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" :class="[
        'footer',
        $arMediaQuery.window.maxWidth('xs') && 'u-padding-3',
      ]">
        <ar-simple-button
          text="Save"
          @click="handleConfirmClick"
          :style="{ width: '130px' }"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MultiSelectModal',
  components: {},
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectValueMap: {},
    };
  },

  computed: {
  },

  mounted() {
    this.initializeSelectValueMap();
  },

  watch: {
    value() {
      this.initializeSelectValueMap();
    },
  },

  methods: {
    initializeSelectValueMap() {
      const newSelectValueMap = {};
      for (let i = 0; i < this.value.length; i += 1) {
        const correspondingItem = this.items.find(item => item.value === this.value[i]);
        const itemHasError = correspondingItem && !!correspondingItem.error;
        newSelectValueMap[this.value[i]] = !itemHasError;
      }
      this.selectValueMap = newSelectValueMap;
    },
    handleConfirmClick() {
      this.$emit('input', this.getSelectValues());
    },
    handleCloseClick() {
      this.$emit('close');
    },
    getSelectValues() {
      const selectValueMapKeys = Object.keys(this.selectValueMap);
      return selectValueMapKeys.filter(key => this.selectValueMap[key]);
    },
  },
};
</script>

<style lang="scss" scoped>
.multi-select-modal {
  .header {
    font-size: 20px;
  }
  .body {
    .description {
      padding: 26px;
      background: $skyBlueGrey300;
      border-bottom: 1px solid $skyBlueGrey500;
      color: $blueGrey800;
      text-align: center;
    }

    .modal-content {
       padding: 29px 30px;
      .checkbox-section {
        border: 1px solid $skyBlueGrey500;
        border-radius: 4px;
        margin-top: 27px;
        .checkbox {
          display: flex;
          align-items: center;
          height: 51px;

          &:not(:last-child){
            border-bottom: 1px solid $skyBlueGrey500;
          }
          padding: 0 15px;

          .label {
            line-height: 1;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 25px;
  }
}
</style>
