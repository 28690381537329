<template>
  <section>
    <am2-heading
      type="h1"
      size="lg"
      title="Admin Permissions Setup"
      class="u-margin-top-6 u-margin-bottom-8" />

    <div class="permissions-page-wrapper">

      <am2-heading
        type="h3"
        title="Select account"
        size="md"
        :style="{ marginBottom: '10px' }" />

      <am2-promoter-select
        class="select"
        :value="125"
        @input="handlePromoterSelect"
        :style="{ width: '500px', marginBottom: '50px' }"
      />

      <am2-loading-section
        v-if="isFetchingPaymentPlans"
        :style="{
          height: '300px',
        }"
      />
      <div v-else-if="hasFetchedFirstPromoterPlan">
        <FeaturesEditor
          title="Demo Plan"
          description="You can manually turn on features for a promoter, even if they didn't pay for them. IMPORTANT: To enable SMS, a promoter MUST have a Chargebee subscription for SMS too (even a free one)."
          :promoter-plan="demoPromoterPlan"
          editable
          :loading="isUpdatingPromoterPlan"
          @update="updateDemoPlan"
        />
        <FeaturesEditor
          title="Chargebee Plan"
          description="These are the features which the promoter has paid for"
          :promoter-plan="chargebeePromoterPlan"
          disabled
        />
      </div>
    </div>

  </section>

</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  import FeaturesEditor from './components/features-editor';

  export default {
    name: 'AdminPermissions',
    layout: 'default',

    components: {
      FeaturesEditor,
    },

    data: () => ({
      selectedPromoterOid: null,
      chargebeePromoterPlan: null,
      demoPromoterPlan: null,
      isUpdatingPromoterPlan: false,
      hasFetchedFirstPromoterPlan: false,
      isFetchingPaymentPlans: false,
    }),

    computed: {
      ...mapGetters ({
        isAdminAccount: 'auth/isAdminAccount',
      }),
    },

    created() {
      if (!this.isAdminAccount) {
        this.$router.push({
          path: '/audience',
        });
      }
    },

    methods: {
      ...mapActions(['UPDATE_PAYMENT', 'FETCH_PROMOTER_PAYMENT']),

      async updateDemoPlan(demoPromoterPlan) {
        this.isUpdatingPromoterPlan = true;
        try {
          await this.UPDATE_PAYMENT({
            promoterOid: this.selectedPromoterOid,
            demoPromoterPlan: demoPromoterPlan,
          });
          this.$arNotification.push({ type: 'success', message: `Permissions successfully updated` });
        } catch (e) {
          this.$arNotification.push({ type: 'error', message: `Failed to update paymentInfo of promoter #${demoPromoterPlan.oid}` });
        } finally {
          this.isUpdatingPromoterPlan = false;
        }
      },

      async fetchPaymentInfoForPromoter(promoterOid, planType){
        try {
          let promoterPlan = await this.FETCH_PROMOTER_PAYMENT({ promoterOid, planType }) || null;
          if (promoterPlan) {
            // Replace all "null" with "false"
            // TODO: Remove it once server solves the issue that, disabled should only have true or false.
            promoterPlan = JSON.parse(JSON.stringify(promoterPlan).replace(/"([^"]+)":null/g, '"$1":false'));
            promoterPlan.promoterDisabled = !!promoterPlan.promoterDisabled;
          }

          this[`${planType}PromoterPlan`] = promoterPlan;
        } catch (e) {
          console.error(e);
          this.$arNotification.push({
            type: 'error',
            message: `Failed to fetch ${planType} promoter plan`,
          });
        }
      },

      async handlePromoterSelect(promoterOid) {
        this.hasFetchedFirstPromoterPlan = true;
        this.selectedPromoterOid = promoterOid;

        this.isFetchingPaymentPlans = true;
        await Promise.all([
          this.fetchPaymentInfoForPromoter(promoterOid, 'chargebee'),
          this.fetchPaymentInfoForPromoter(promoterOid, 'demo'),
        ]);
        this.isFetchingPaymentPlans = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .permissions-page-wrapper {
    margin: 30px;

    .permissions-section {
      display: flex;
      justify-content: space-between;

      .permissions-sub-section {
        display: inline-flex;
        flex-direction: column;
        width: calc(50% - 20px);
      }

      .container {
        padding: 10px;
        margin: 0 0 20px;
        width: 400px;
        text-transform: capitalize;
      }
    }
  }
</style>
