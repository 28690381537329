/**
 * CREATE_SOCIAL_ACCOUNT
 *
 facebook:"https://www.facebook.com/kevinyipeio"

 */
export async function CREATE_SOCIAL_ACCOUNTS({ state }, { followAccounts }) {
  if (!state.auth.account) { return null; }
  const { promoterOid } = state.auth.account;

  const socialAccounts = await this.$axios.get(`/promoter/${promoterOid}/social-accounts`);

  followAccounts.forEach(async account => {
    if (account.save) {
      const accountName = account.name;

      // Remove unnecessary data in account to prepare for sending
      delete account.name;
      delete account.save;

      const postData = {
        promoterOid,
        name: accountName,
        accounts: JSON.stringify(account), // account has all the urls of an account e.g. facebook instagram etc.
      };

      if (socialAccounts.data) {
        const index = socialAccounts.data.findIndex(acct => acct.name === accountName);
        if (index >= 0) {
          try {
            await this.$axios.patch(
              `/promoter/${promoterOid}/social-accounts/${socialAccounts.data[index].oid}`,
              postData
            );

            // return true so component knows it was successful and can notify user of successful form post.
            return true;
          } catch (error) {
            console.error({ message: `Error: ${error}` });
            return {};
          }
        }
      }

      try {
        await this.$axios.post(`/promoter/${promoterOid}/social-accounts/`, postData);

        // return true so component knows it was successful and can notify user of successful form post.
        return true;
      } catch (error) {
        console.error({ message: `Error: ${error}` });
        return {};
      }
    }

    return true;
  });
}
