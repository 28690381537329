<template>
  <am2-dropdown-binder
    :align="align"
    button-name="ar-link-button"
    :pixel-shift="15"
    :button-props="buttonPropsDecorated"
    :disabled="disabled"
    :loading="loading"
    :dropdown-props="{
      items: filterItems,
      itemStyle,
      dividerStyle,
      style: dropdownStyle,
      hasSearch,
      maxHeight,
      tabs,
      tabKey,
      noOptionText,
      placeholder: searchPlaceholder,
    }"
    @select="handleItemSelect"
    @tabSelect="handleTabSelect"
    @searchStringChange="handleSearchStringChange"
    @controlClick="handleControlClick"
    :hasFixedFooter="hasFixedFooter"
    @fixedFooterClick="() => { $emit('fixedFooterClick') }"
  />
</template>
<script>
export default {
  name: 'LinkButtonDropdown',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: 'right',
      validator: (val) => ['left', 'right'].indexOf(val) > -1,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: [String, Number],
      default: null,
    },
    buttonProps: {
      type: Object,
      default: () => ({
        hasArrow: true,
        hasUnderline: false,
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemStyle: {
      type: Object,
      default: () => ({
        height: '32px',
        padding: '0 15px',
      }),
    },
    dividerStyle: {
      type: Object,
      default: () => ({}),
    },
    maxHeight: {
      type: String,
      default: '340px',
    },
    dropdownStyle: {
      type: Object,
      default: () => ({
        width: '170px',
      }),
    },
    placeholder: {
      type: String,
      default: 'Nothing selected',
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: 'Search option',
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    tabKey: {
      type: String,
      default: null,
    },
    noOptionText: {
      type: String,
      default: null,
    },
    hasFixedFooter: {
      type: Boolean,
      default: false,
    },
    handleCustomFieldClick: {
      type: Function,
      default: null,
    },
  },
  computed: {
    selectedItemIdx() {
      for (let i = 0; i < this.items.length; i += 1) {
        if (this.items[i].key === this.itemKey) {
          return i;
        }
      }
      return -1;
    },
    filterItems() {
      return this.items.map((item, idx) => {
        if (idx === this.selectedItemIdx) {
          return {
            ...item,
            typography: {
              ...item.typography,
              weight: 'bold',
            },
          };
        } else {
          return item;
        }
      });
    },
    buttonText() {
      return this.selectedItemIdx > -1 ? this.items[this.selectedItemIdx].name : this.placeholder;
    },
    buttonPropsDecorated() {
      return {
        ...this.buttonProps,
        text: this.buttonText,
        disabled: this.disabled,
      };
    },
  },
  methods: {
    handleTabSelect(tab) {
      this.$emit('tabSelect', tab);
    },
    handleItemSelect(item) {
      this.$emit('select', item);
    },
    handleControlClick(item, control) {
      this.$emit('controlClick', item, control);
    },
    handleSearchStringChange(val) {
      this.$emit('searchStringChange', val)
    },
  },
}
</script>

