<template>
  <div
    :class="[
      'ar-switch',
      realVal ? 'on' : 'off',
      disabled && 'disabled',
    ]"
    @click="handleSwitchClick"
  >
    <div :class="['ball', realVal ? 'right-align' : 'left-align']" />
    <ar-text
      :class="['on-text', realVal && 'display']"
      size="xxxs"
      text="ON"
      weight="bold"
    />
    <ar-text
      :class="['off-text', !realVal && 'display']"
      size="xxxs"
      text="OFF"
      weight="bold"
    />
  </div>
</template>

<script>
export default {
  name: 'ARSwitch',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      realVal: this.value,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.realVal = val;
      },
      immediate: true,
    },
  },
  methods: {
    handleSwitchClick() {
      if (this.disabled) {
        return;
      }
      this.realVal = !this.realVal;
      this.$emit('input', this.realVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  border-radius: 15px;
  user-select: none;
  cursor: pointer;

  &.on {
    background: $green500;
  }

  &.off {
    background: $skyBlueGrey600;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .ball {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: white;
    position: absolute;
    top: 50%;
    transition: all 0.075s linear;

    &.left-align {
      left: 3px;
      transform: translateX(0%) translateY(-50%);
    }

    &.right-align {
      left: calc(100% - 3px);
      transform: translateX(-100%) translateY(-50%);
    }
  }

  .on-text {
    color: white;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateX(0%) translateY(-50%);
    opacity: 0;

    &.display {
      opacity: 1;
    }
  }

  .off-text {
    color: white;
    position: absolute;
    top: 50%;
    left: calc(100% - 6px);
    transform: translateX(-100%) translateY(-50%);
    opacity: 0;

    &.display {
      opacity: 1;
    }
  }
}
</style>
