<template>
  <section
    :class="['campaigns-wrapper', 
      $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
    ]"
    v-infinite-scroll="loadMoreData"
    infinite-scroll-disabled="infiniteScrollDisabled"
    infinite-scroll-distance="10"
  >
    <am2-unlock-prompt
      v-if="!isCampaignFeatureEnabled"
      :title="$arMediaQuery.pageContent.minWidth('sm') ? `Unlock Campaigns` : `Campaigns`"
      :subtitle="$arMediaQuery.pageContent.minWidth('sm') ? `Sell more tickets through increasing word of mouth, and capturing data. <a href='https://audiencerepublic.com/campaigns' target='_blank' style='color: #FFF;'>Learn more</a>` : `Sell more tickets through increasing word of mouth. <a href='https://audiencerepublic.com/campaigns' target='_blank' style='color: #FFF;'>Learn more</a>`"
      :button-text="$arMediaQuery.pageContent.minWidth('sm') ? `Contact Sales` : `Contact`"
      @click="goToContactSales"
      :size="$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'medium'"
      v-ar-sticky-top="{
        priority: 1,
      }"
    />

    <div
      class="campaigns">
      <div
        :class="[
          'campaigns-page-top',
          $arMediaQuery.window.minWidth('md') && 'window-md-min',
        ]"
      >
        <am2-top
          :dropdown="dropdown"
          :button="{
            action: goToCampaignSetup,
            text: createButtonText,
            iconName: 'circle-plus',
            iconDistance: '9px',
            classes: ['top-create-button'],
          }"
          :search="{ action: serverSideSearch, value: queryParams.searchString, placeholder: searchPlaceholderText }"
          title="Campaigns"
        />
      </div>

      <div
        v-if="displayCampaignControls"
        class="campaigns-controls"
        :style="{
          marginTop: '18px',
        }"
      >
        <div class="campaigns-controls__subcontrol">
          <am2-button-group-tabs
            :active-index="queryParams.showAs === 'list' ? 1 : 0"
            :items="groupButtonsSelectItems"
            :style="{
              width: '100px',
              maxHeight: $arMediaQuery.pageContent.minWidth('sm') ? null : 0,
              marginBottom: $arMediaQuery.pageContent.minWidth('sm') ? 'auto' : '50px',
              border: $arMediaQuery.pageContent.minWidth('sm') ? null : 0,
            }"
            @select="handleGroupButtonSelectClick"
            data-test-id="list-type-tabs"
          />
        </div>
      </div>
      <div
        :style="{
          marginTop: displayCampaignControls ? (promoterHasTours ? '-13px' : '17px') : ($arMediaQuery.window.minWidth('md') ? '30px' : '22px'),
          marginBottom: '22px',
        }"
      >
        <am2-tabs
          v-if="promoterHasTours"
          class="tabs"
          :selected-tab-index="selectedTabIndex"
          @select="changeTab"
          :items="tabs"
        />
        <ar-divider />
      </div>

      <div
        v-if="queryParams.tab === 'campaign'"
        :style="{
          marginTop: $arMediaQuery.pageContent.minWidth('sm') ? '28px' : '0',
        }"
        class="cards-container-wrapper"
        :class="[
          queryParams.showAs === 'tiles' && 'tiles',
          $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
          $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
          $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max tiles',
        ]"
      >
        <template v-if="hasNoData">
          <am2-no-content-section
            v-if="hasSearchConditions"
            class="campaigns-no-content-wrapper"
            header="No campaigns found"
            header-size="sm"
          />
          <am2-no-content-section
            v-else
            class="campaigns-no-content-wrapper"
            icon-name="ar-campaign-in-circle"
            header="Create a campaign"
            body="Gamified campaigns to reward fans for referring friends & subscribing"
            button-text="Create campaign"
            @buttonClick="goToCampaignSetup"
          />
        </template>
        <div
          v-else
          :class="[
            'cards-container',
            $arMediaQuery.pageContent.minWidth('sm') ? queryParams.showAs : 'tiles',
            $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
            $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
            $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
          ]"
        >
          <am2-campaign-card
            class="item-card"
            :class="[
              $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
              $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
              $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
            ]"
            v-for="campaignKey in sortedCampaignsOid"
            :key="campaignKey"
            :show-as="$arMediaQuery.pageContent.minWidth('sm') ? queryParams.showAs : 'tiles'"
            :card-size="$arMediaQuery.pageContent.maxWidth('sm') ? 'xs' : 'regular'"
            :campaign="rows[campaignKey]"
            @delete="handleCampaignDelete"
          />

          <am2-card-skeleton
            v-for="n in isFetchingData ? 6 : 0"
            :key="n"
            class="base-plan-card--campaigns"
            :class="[
              $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
              $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
            ]"
            type="campaign"
            :show-as="$arMediaQuery.pageContent.minWidth('sm') ? queryParams.showAs : 'tiles'"
            :level="$arMediaQuery.pageContent.maxWidth('xs') || queryParams.showAs === 'list' ? (2 * n - 1) : (2 * Math.ceil(n / 3) - 1)"
          />
        </div>
      </div>

      <div
        v-if="queryParams.tab === 'tour'"
        :style="{
          marginTop: $arMediaQuery.pageContent.minWidth('sm') ? '28px' : '0',
        }"
        class="cards-container-wrapper"
        :class="[
          queryParams.showAs === 'tiles' && 'tiles',
          $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
          $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
          $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
        ]"
      >
        <template v-if="hasNoData">
          <am2-no-content-section
            class="tours-no-content-wrapper"
            v-if="hasSearchConditions"
            header="No tours found"
            header-size="sm"
          />
          <am2-no-content-section
            v-else
            class="tours-no-content-wrapper"
            header="Create a tour"
            button-text="Create tour"
            @buttonClick="goToCampaignSetup"
          />
        </template>
        <div
          v-else
          :class="[
            'cards-container',
            $arMediaQuery.pageContent.minWidth('sm') ? queryParams.showAs : 'tiles',
            $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
          ]"
        >
          <am2-card-skeleton
            v-for="n in isFetchingData ? 6 : 0"
            :key="n"
            class="base-plan-card--tours"
            :class="[
              $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
              $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
            ]"
            type="campaign"
            :show-as="$arMediaQuery.pageContent.minWidth('sm') ? queryParams.showAs : 'tiles'"
            :level="$arMediaQuery.pageContent.maxWidth('xs') || queryParams.showAs === 'list' ? (2 * n - 1) : (2 * Math.ceil(n / 3) - 1)"
          />
          <am2-tour-card
            class="item-card"
            v-for="tour in rows"
            :key="tour.oid"
            :show-as="$arMediaQuery.pageContent.minWidth('sm') ? queryParams.showAs : 'tiles'"
            :card-size="$arMediaQuery.pageContent.maxWidth('sm') ? 'xs' : 'regular'"
            :tour="tour"
            @delete="handleTourDelete" />
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { isCampaignActive } from '@/utils/campaign';
import checkoutPageAccessibility from '@/mixins/checkoutPageAccessibility';

import moment from 'moment';
import accounting from 'accounting';
import { clone } from '@/utils/helpers';

const defaultQueryParams = {
  tab: 'campaign',
  filter: 'all',
  showAs: 'list',
  top: 10
}

const fetchDataAction = {
  'campaign': 'FETCH_CAMPAIGNS',
  'tour': 'FETCH_TOURS'
}

const fetchData = async (store, fetchParams, skip) => {

  const { rows, count } = await store.dispatch(fetchDataAction[fetchParams.tab], {
    ...fetchParams,
    ...skip ? { skip } : null
  });

  return {
    rows,
    count
  }
}

export default {
  title: 'Campaigns',
  layout: 'default',
  mixins: [
    checkoutPageAccessibility({ featureKeys: ['campaigns'], featureName: 'Campaigns' }),
  ],

  data: () => ({
    rows: [],
    count: 0,
    isFetchingData: false,
    skip: 0,
    queryParams: defaultQueryParams,
    groupButtonsSelectItems: [
      {
        iconName: 'tile-view',
        showAs: 'tiles',
      },
      {
        iconName: 'list-view',
        showAs: 'list',
      },
    ],
  }),

  computed: {
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      promoterHasTours: 'tour/promoterHasTours',
    }),

    isCampaignFeatureEnabled() {
      return this.isFeatureEnabled(['campaigns']);
    },

    displayCampaignControls() {
      return this.$arMediaQuery.pageContent.minWidth('sm') && !this.hasNoData;
    },

    hasSearchConditions() {
      return this.queryParams.searchString || this.queryParams.filter !== 'all';
    },

    hasNoData() {
      if (this.isFetchingData) {
        return false;
      }
      return this.sortedCampaignsOid.length === 0;
    },

    tabs() {
      const tabs = [
        {
          value: 'campaign',
          iconName: 'campaign',
          title: 'Campaigns',
        },
        {
          value: 'tour',
          iconName: 'tour',
          title: 'Tours',
        }
      ];

      // if (this.promoterHasTours) {
      //   tabs[1].disabled = true;
      //   tabs[1].disabledTooltip = this.isFetchingData ? '' : 'You need to create a campaign before you can set up a tour'
      // }

      return tabs;
    },

    createButtonText() {
      if (!this.$arMediaQuery.pageContent.minWidth('sm')) return 'Create';
      if (this.queryParams && this.queryParams.tab === 'tour') {
        return "Create tour";
      }
      return 'Create campaign';
    },

    searchPlaceholderText() {
      if(this.queryParams && this.queryParams.tab === 'tour') {
        return this.count && this.count !== 1 ? `Search ${accounting.format(this.count)} tours` : `Search tours`;
      } else {
        return this.count && this.count !== 1 ? `Search ${accounting.format(this.count)} campaigns` : `Search campaigns`;
      }
    },

    infiniteScrollDisabled() {
      return this.rows.length === this.count;
    },

    selectedTabIndex() {
      return this.tabs.findIndex(t => t.iconName === this.queryParams.tab)
    },

    sortedCampaignsOid() {
      const activeOids = Object.keys(this.rows).filter(oid => isCampaignActive(this.rows[oid]));
      const inactiveOids = Object.keys(this.rows).filter(oid => !isCampaignActive(this.rows[oid]));

      const sortedActiveOids = activeOids.sort((a, b) => {
        const endDateA = moment(this.rows[a].endDate)
        const endDateB = moment(this.rows[b].endDate)
        if (endDateA.isAfter(endDateB))
          return 1;

        if (endDateA.isBefore(endDateB))
          return -1;

        return 0;
      })

      const sortedInactiveOids = inactiveOids.sort((a, b) => {
        const endDateA = moment(this.rows[a].endDate)
        const endDateB = moment(this.rows[b].endDate)
        if (endDateA.isAfter(endDateB))
          return -1;

        if (endDateA.isBefore(endDateB))
          return 1;

        return 0;
      })

      if (this.queryParams.filter === 'active') {
        return sortedActiveOids;
      }

      if (this.queryParams.filter === 'inactive') {
        return sortedInactiveOids;
      }

      return sortedActiveOids.concat(sortedInactiveOids);
    },

    dropdown() {
      return {
        title: 'All campaigns',
        activeItemKey: this.queryParams.filter || 'all',
        items: [
          {
            name: 'All campaigns',
            key: 'all',
            action: () => this.setCampaignFilter('all'),
          },
          {
            name: 'Active campaigns',
            key: 'active',
            action: () => this.setCampaignFilter('active'),
          },
          {
            name: 'Inactive campaigns',
            key: 'inactive',
            action: () => this.setCampaignFilter('inactive'),
          },
        ],
      };
    },
  },

  async mounted() {
    this.fetchPromoterTourCount();
    try {
      if (Object.keys(this.$route.query).length > 0) {
        this.queryParams = { ...this.$route.query };

        // any top value less than our default may cause an
        // unnecessary amount of extra FETCH_CAMPAIGNS actions to
        // be called; or worse, none at all.
        if (this.queryParams.top < defaultQueryParams.top) {
          this.queryParams.top = defaultQueryParams.top;
        }
      }

      await this.fetchData();
    } catch(e) {
      console.error(e);
    }
  },

  methods: {
    ...mapActions([
      'tour/FETCH_PROMOTER_TOURS_COUNT',
      'FETCH_CAMPAIGN_REGISTRATIONS'
    ]),
    fetchPromoterTourCount() {
      this['tour/FETCH_PROMOTER_TOURS_COUNT']();
    },
    changeTab(tab) {
      if(this.isFetchingData) {
        return;
      }
      this.rows = [];
      this.count = 0;
      this.skip = 0;
      this.setQueryParams({
        top: null,
        searchString: null,
        tab: tab.value,
        filter: 'all',
      });
      this.fetchData()
    },

    async handleCampaignDelete() {
      await this.fetchData();
    },

    async handleTourDelete() {
      await this.fetchData();
    },

    goToCampaignSetup() {
      if (this.queryParams && this.queryParams.tab === 'tour') {
        this.$router.push('tours/setup');
      } else {
        this.$router.push('campaigns/setup/type');
      }
    },

    goToContactSales() {
      window.open('https://audiencerepublic.com/contact-us');
    },

    handleGroupButtonSelectClick(item) {
      this.setQueryParams({
        showAs: item.showAs,
      });
    },

    setQueryParams(newParams) {

      let newQueryParams = clone({
       ...this.queryParams,
       ...newParams
      });

      for(let k in newQueryParams) {
        if(!newQueryParams[k]) {
          delete newQueryParams[k]
        }
      }

      this.queryParams = newQueryParams

      this.$router.push({
        query: {
          ...this.queryParams
        }
      }).catch(err => {})
    },

    async fetchData(append, skip) {

      if (this.isFetchingData)
        return;

      this.isFetchingData = true;

      if (!append) {
        this.rows = [];
      }

      const { rows, count } = await fetchData(this.$store, this.queryParams, skip);

      if(append) {
        this.rows = [...this.rows, ...rows];
      } else {
        this.rows = rows;
      }

      this.$router.push({
        query: {
          ...this.queryParams,
          top: this.rows.length
        }
      }).catch(err => {})

      this.count = count;
      this.isFetchingData = false;
    },

    setCampaignFilter(filter) {

      // XXX/JNM: "filter" is a bad name, however, leave it at the moment, the value of
      // filter will be: 'all', 'active', 'inactive'.

      if (filter === 'all') {
        this.setQueryParams({
          top: 20,
          skip: 0,
          filter
        });
      } else {
        this.setQueryParams({
          top: 20,
          skip: 0,
          filter,
          tab: 'campaign',
        });
      }

      this.fetchData()
    },

    async loadMoreData() {
      await this.fetchData(true, this.rows.length);
    },

    async serverSideSearch(searchString) {

      this.setQueryParams({
        top: null,
        searchString
      });

      this.fetchData()
    },
  },
};
</script>

<style lang="scss" scoped>
.campaigns-wrapper {
  max-width: 1260px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 0 30px 100px;

  &.sm-max {
    padding: 0 30px 30px;
  }

  .campaigns {
    width: 100%; 

    .cards-container-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      .campaigns-no-content-wrapper {
        width: 100%;
      }
      
      .tours-no-content-wrapper {
        width: 100%;
      }
    }

    .cards-container-wrapper.tiles {      
      &.md-max {
        .cards-container {
          grid-gap: 18px 12px;
        }
      }
      
      &.sm-max {
        .cards-container {
          grid-template-columns: 360px;
        }
      }
    }
  }

  .cards-container.tiles {
    display: grid;
    grid-template-columns: 380px 380px 380px;
    grid-gap: 30px;

    @media screen and (max-width: 1350px) {
      grid-template-columns: 360px 360px;
    }

    &.md-max {
      grid-gap: 18px 12px;
    }

    &.xs-max {
      grid-template-columns: 360px;
    }

    .base-plan-card--campaigns {
      height: 376px;

      /deep/ .left-section {
        padding-bottom: 57%;
      }      
    }

    .base-plan-card--tours {
      /deep/ .left-section {
        padding-bottom: 52%;
      }
    }
  }

  .cards-container.list {
    width: 100%;

    .base-plan-card--tours {
      margin-bottom: 10px;
      height: 188px;

      &.md-max {
        /deep/ .left-section {
          width: 71%;
          max-width: unset;
        }
      }
    }

    .base-plan-card--campaigns {
      height: 188px;
      margin-bottom: 10px;

      &.md-max {
        /deep/ .left-section {
          width: 44%;
        }
      }

      &.sm-max {
        /deep/ .left-section {
          width: 72%;
        }
      }
    }    
    
    .item-card {
      height: 190px;
      margin-bottom: 10px;
    }
  }

  .overall-details {
    height: 19px;
    line-height: 1;
  }

  .is-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tabs {
    min-height: 30px;

    // TODO: Definitely take this away later, either provide more options on ar-tabs or update ui
    /deep/ .option {
      color: #B4B9C4;
      &.active {
        color: #7344c0;
      }
    }
  }

  .campaigns {
    &-page-top {
      margin: 30px 0 0;
      &.window-md-min {
        margin: 50px 0 0;
      }
    }
    &-controls {
      display: flex;
      justify-content: space-between;
      & .dropdown {
        flex: 2 1 auto;
        text-align: right;
        margin-right: 8px;
      }
      .overall-details {
        position: relative;
        font-weight: 500;
        margin-top: 10px;
      }

      &__subcontrol {
        margin-left: auto;
        display: inherit;
        justify-content: inherit;
      }
    }
    &-wrapper {
      margin-top: 27px;
    }
  }
}

</style>
