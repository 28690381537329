

export async function FETCH_PREVIEW_CAMPAIGN({  }, obj) {

  try {
    const { data } = await this.$axios.get(
      "/campaign-preview?campaign=" + encodeURIComponent(JSON.stringify(obj))
    );

    return data;

  } catch (error) {
    //console.error(error);
    return {};
  }
}
