<template>
  <div class="action-box-wrapper">
    <template v-if="box.id">
      <DragPlaceholder
        :box="box"
        @active="handleActive"
      />
      <div
        draggable
        ref="draggable"
        :class="[
          'actions-draggable-wrapper',
          'leader-line-target',
          leaderSelfOutboxClass,
        ]"
        :data-block-id="box.block.id"
        @dragstart="handleStart"
        @dragend="handleEnd"
      >
        <ActionBlock
          class="draggable-action"
          :action="box"
          @click="handleActionClick"
          @action-delete="handleDelete"
        >
          <component
            to="card-sub-details"
            :is="cardType(box.block)"
            :item="box.block"
          />
        </ActionBlock>
      </div>
    </template>

    <template v-if="box.type === 'action-prompt'">
      <DragPlaceholder
        :box="box"
      />
    </template>
  </div>
</template>
<script>
import DragPlaceholder from '../DragPlaceholder'
import ActionBlock from '../ActionBlock'
import { automationCardType } from '../../utils'
import { mapState, mapActions } from 'vuex'
import SendSMS from '../AutomationPlanner/SendSMS'
import TagAdd from '../AutomationPlanner/TagAdd'
import SendEmail from '../AutomationPlanner/SendEmail'
import IfElse from '../AutomationPlanner/IfElse'
import WaitUntil from '../AutomationPlanner/WaitUntil'
export default {
  name: 'ActionBox',
  components: {
    DragPlaceholder,
    ActionBlock,
    sendSMS: SendSMS,
    tagAdd: TagAdd,
    sendEmail: SendEmail,
    ifElse: IfElse,
    waitUntil: WaitUntil,
  },
  props: {
    box: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      lines: []
    }
  },
  watch: {
    isScrolling() {
      if (this.isScrolling) {
        this.rePositionLeaderLines()
      }
    },
    zoomVal() {
      this.rePositionLeaderLines()
    },
    isFullScreen() {
      this.rePositionLeaderLines()
    },
  },
  computed: {
    ...mapState({
      isScrolling: state => state.automation.isScrolling,
      zoomVal: state => state.automation.zoomVal,
      isFullScreen: state => state.automation.isFullScreen,
    }),
    leaderSelfOutboxClass() {
      let classes = this.box.block.key === 'if-else' ? [`leader-self-${this.box.id.outThen}`, `leader-self-${this.box.id.outElse}`] : [`leader-self-${this.box.id.out}`]
      return classes
    },
  },
  methods: {
    ...mapActions({
      setDraggedBox: 'automation/SET_DRAGGED_BOX',
      clearDraggedBox: 'automation/CLEAR_DRAGGED_BOX',
      deleteAction: 'automation/DELETE_ACTION',
      findAndSetEditableAction: 'automation/FIND_AND_SET_EDITABLE_ACTION',
      setScrollVals: 'automation/SET_SCROLL_VALS',
    }),
    handleActive(isActive) {
        let i = this.lines.length
        while (i--) {
          isActive ? this.lines[i].hide() : this.lines[i].show()
        }
    },
    rePositionLeaderLines() {
      requestAnimationFrame(() => {
        let i = this.lines.length
        while (i--) {
          this.lines[i].position()
        }
      })
    },
    handleDelete() {
      let scrollParentDiv = document.getElementById('automation-planner')
      let parentScrollLeftPos = scrollParentDiv.scrollLeft
      let windowScrollY = window.scrollY
      this.setScrollVals({parentX: parentScrollLeftPos, windowY: windowScrollY})
      this.deleteAction(this.box)
    },
    handleStart(e) {
      e.dataTransfer.setData("box", JSON.stringify({...this.box, isFromSidebar: false}))
      this.setDraggedBox(this.box)
    },
    handleEnd() {
      this.clearDraggedBox()
    },
    handleActionClick({id, event}) {
      if (event.target.id === "action-close") return
      this.findAndSetEditableAction(id)
    },
    isSubPlaceholder(block) {
      if (block?.type === 'placeholder') return 'hidden'
    },
    cardType(item) {
      return automationCardType(item)
    },
    setLines() {
      if (this.box.type === 'placeholder') return

      let parents, target, branchLabel
      branchLabel = !!this.box.branch && this.box.branch
      let options = { color: '#615bdb', size: 2.5, startSocket: 'bottom', endSocket: 'top', startSocketGravity: 50, endSocketGravity: 50 }
      
      if (!!branchLabel) {
        options['middleLabel'] = this.$leaderLine.captionLabel(branchLabel, {fontSize: 12, fontWeight: 'bold'})
      }

      if (this.box.type === 'action-prompt')  {
        parents = document.getElementsByClassName(`leader-self-${this.box.parentOutBoxId}`)
        target = document.getElementById(`prompt-for-${this.box.parentOutBoxId}`)
        options['dash'] = true
      } else {
        parents = document.getElementsByClassName(`leader-self-${this.box.parentOutBoxId}`)
        target = this.$refs.draggable
      }

      let i = parents.length
      while (i--) {
        let line = new this.$leaderLine(
          this.$leaderLine.pointAnchor(parents[i], {x: '50%', y: '105%'}),
          this.$leaderLine.pointAnchor(target, {x: '50%', y: -5}),
          options,
        )

        this.lines.push(line)
      }
    }
  },
  mounted() {
    this.setLines()
  },
  beforeDestroy() {
    let i = this.lines.length
    while (i--) {
      this.lines[i].remove()
    }
  }
}
</script>