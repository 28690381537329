import { clone } from '@/utils/helpers';
import { ScratchEventRSVPFormCampaign } from '@/types/resources/eventRSVPFormCampaign';

export const initializeScratchEventRSVPForm = function (): ScratchEventRSVPFormCampaign {
  return { // It's 100% same as the real campaign object returned from backend, we have some attributes that just to make front-end's life easier
    name: 'Register for event',
    urlSlug: '',
    eventOid: null,
    type: 'rsvp',
    defaults: {},
    presentation: {
      successMessage: null,
      description: null,
      color: '#7344c0',
      headHtml: null,
      bodyHtml: null,
      metaTitle: null,
      metaDescription: null,
      noPoints: true,
      flow: ["register"],
    },
    settings: {
      reminders: [
        {
          enabled: true,
          text: null,
          value: 5,
          unit: 'minutes',
        },
        {
          enabled: true,
          text: null,
          value: 24,
          unit: 'hours',
        }
      ],
    },
    startDate: null,
    endDate: null,
    promoterOid: null,
    registrations: {
      fields: {
        emailAddress: {
          name: 'Email address',
          optional: false,
          predefined: true,
          enabled: true,
          order: 0,
        },
        firstName: {
          name: 'First name',
          optional: false,
          predefined: true,
          enabled: true,
          order: 1,
        },
        lastName: {
          name: 'Last name',
          optional: false,
          predefined: true,
          enabled: true,
          order: 2,
        },
        mobileNumber: {
          name: 'Mobile number',
          optional: false,
          predefined: true,
          enabled: false,
          order: -1,
        },
        dob: {
          name: 'Date of birth',
          optional: false,
          predefined: true,
          enabled: false,
          order: -1,
        },
        gender: {
          name: 'Gender',
          optional: false,
          predefined: true,
          enabled: false,
          order: -1,
        },
        country: {
          name: 'Country',
          optional: false,
          predefined: true,
          enabled: false,
          order: -1,
        },
        postcode: {
          name: 'Zip/Post code',
          optional: false,
          predefined: true,
          enabled: false,
          order: -1,
        },
        city: {
          name: 'City',
          optional: false,
          predefined: true,
          enabled: false,
          order: -1,
        },
        state: {
          name: 'State',
          optional: false,
          predefined: true,
          enabled: false,
          order: -1,
        },
        streetAddress: {
          name: 'Street address',
          optional: false,
          predefined: true,
          enabled: false,
          order: -1,
        },
      },
      providers: {
        email: true,
        spotify: false,
        facebook: false,
      },
    },
    resources: [],
  }
};

/**
 * This is the place that we can implement missing fileds or adjusting old fields
 * for RSVP Event.
 * @param eventRSVP 
 */
export const correctScratchEventRSVPForm = function (eventRSVPForm: ScratchEventRSVPFormCampaign) {
  const scratchEventRSVPForm: ScratchEventRSVPFormCampaign = clone(eventRSVPForm);
  // Event RSVP always has no points
  scratchEventRSVPForm.presentation.noPoints = true;
  // Event RSVP always has email provider as true
  scratchEventRSVPForm.registrations.providers = {
    email: true,
    spotify: false,
  };
  return scratchEventRSVPForm;
}

export const patchAdvancedSettingsInEventRSVP = function (
  scratchEventRSVPForm: ScratchEventRSVPFormCampaign,
  advancedSettings: any,
) {
  const newScratchEventRSVPForm: ScratchEventRSVPFormCampaign = clone(scratchEventRSVPForm);

  if (advancedSettings && advancedSettings != {} && Object.keys(advancedSettings).length > 0) {
    newScratchEventRSVPForm.settings = {
      ...newScratchEventRSVPForm.settings,
      ...advancedSettings
    };
  }

  return newScratchEventRSVPForm;
}