<template>
  <EditableFrame
    class="send-sms-wrapper"
    title="Edit Action"
    :subtitle="item.name"
    has-save-button
    @close="$emit('close')"
    @save="handleSave"
  >
    <am2-message-preview-modal
      :opened="displayPreviewModal"
      provider="sms"
      :message-body="previewMessage || ''"
      @close="handlePreviewModalClose"
    />
    <!-- <ar-text
      class="recipient-heading"
      size="13px"
      text="RECIPIENT"
      weight="bold"
      multiple-lines
      :max-lines="1"
      letter-spacing="1px"
    />
    <RecipientDropdown
      v-model="recipientData"
    /> -->

    <ar-field
      :error-messages="[
        veeErrors.first('message'),
      ]"
    >
      <ar-text
        class="message-heading"
        size="13px"
        text="MESSAGE"
        weight="bold"
        multiple-lines
        :max-lines="1"
        letter-spacing="1px"
      />
      <ar-textarea
        :rows="6"
        value=""
        placeholder="Enter your message..."
        v-model="message"
        v-validate="'required'"
        data-vv-name="message"
        data-vv-as="message"
      />
    </ar-field>
    <div class="preview-button-wrapper">
      <ar-simple-button
        icon-name="preview"
        text="Preview"
        type="grey"
        @click="handlePreviewClick"
      />
    </div>
  </EditableFrame>
</template>
<script>
import RecipientDropdown from './RecipientDropdown'
import EditableFrame from '../utils/EditableFrame.vue'
import { mapState, mapActions } from 'vuex'
import { debounce } from 'debounce';

export default {
  name: 'SendSMS',
  components: {
    RecipientDropdown,
    EditableFrame,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      displayPreviewModal: false,
      message: null,
      previewMessage: null,
    }
  },
  computed: {
    ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
      promoterAccountOid: state => state.auth.account.oid
    }),
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    },
    message: {
      handler(newVal) {
        this.debounceFetchPreviewMessage(newVal);
      },
    },
  },
  methods: {
    ...mapActions({
      updateActionConfig: 'automation/UPDATE_ACTION_CONFIG',
    }),
    async validateInputs() {
      const isValid = await this.$validator.validateAll();
      return isValid;
    },
    async fetchPreviewMessage(newMsg) {
      const {
        message,
      } = await this.$api.messages.fetchSmsMessagePreviewWithoutStats(this.promoterOid, newMsg);
      this.previewMessage = message;
    },
    handlePreviewClick() {
      this.displayPreviewModal = true;
    },
    handlePreviewModalClose() {
      this.displayPreviewModal = false;
    },
    async handleSave() {
      const valid = await this.validateInputs();
      if (!valid) {
        return;
      }

      const contextData = {
        config: {
          message: this.message,
          promoterAccountOid: this.promoterAccountOid
        },
        meta: {
        },
      };
      this.updateActionConfig({
        id: this.item.id,
        data: contextData,
      });
      this.$emit('close');
    },
    setItemVals() {
      this.message = this.item.config?.message
    }
  },
  created() {
    this.debounceFetchPreviewMessage = debounce(this.fetchPreviewMessage, 1000);
  },
  mounted() {
    this.setItemVals()
  }
}
</script>
<style lang="scss" scoped>
.send-sms-wrapper {
  width: 100%;
  height: 100%;
  .recipient-heading, .message-heading {
    margin-bottom: 14px;
  }

  .preview-button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
}
</style>