import { Module } from "vuex";
import { ApplicationState } from './types';
import { RootState } from '@/store/modules/types';

export const initialApplicationState = (): ApplicationState => ({
  applicationTime: 0,
});

const applicationModule: Module<ApplicationState, RootState> = {
  namespaced: true,
  state: initialApplicationState(),
  mutations: {
    SET_APPLICATION_TIME(state, newApplicationTime: number) {
      state.applicationTime = newApplicationTime;
    },
  },
};

export default applicationModule;
