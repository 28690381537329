var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fan-table-wrapper"},[_c('am2-table',{ref:"table",staticClass:"fan-table",attrs:{"heads":_vm.decoratedHead,"rows":_vm.data,"tick-all-rows":_vm.audienceSelection.useScratchSegment,"ticked-rows-map":_vm.audienceSelection.partlySelectedAudienceMap,"locked":_vm.locked,"loading":_vm.loading,"disable-sorting":_vm.disableSorting,"default-sort-by":_vm.defaultSortBy,"enable-row-tick":_vm.enableRowTick,"enable-row-click":_vm.enableRowClick,"has-sticky-header":_vm.hasStickyHeader,"has-control-section":_vm.hasControlSection},on:{"sortBy":_vm.handleSortByChange,"rowTick":_vm.handleRowTick,"tickAllRows":_vm.handleTickAllRows,"rowClick":_vm.handleRowClick,"cellMouseEnter":_vm.handleCellMouseEnter,"cellMouseLeave":_vm.handleCellMouseLeave,"columnResize":_vm.handleColumnResize,"tableMouseLeave":_vm.handleTableMouseLeave},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var ref_data = ref.data;
var avatar = ref_data.avatar;
var name = ref_data.name;
var firstName = ref_data.firstName;
var lastName = ref_data.lastName;
var emailAddress = ref_data.emailAddress;
var oid = ref_data.oid;
var index = ref.index;
var active = ref.active;
return _c('div',{staticClass:"cell cell-left"},[(_vm.hasIndex)?_c('ar-text',{style:({
          marginRight: '10px',
        }),attrs:{"size":"xs","text":index + 1}}):_vm._e(),_vm._v(" "),_c('ar-avatar',{ref:("avatar-" + index),style:({
          marginRight: '10px',
          cursor: _vm.customerProfilesEnabled ? 'pointer' : null,
        }),attrs:{"image-path":avatar || null,"name":(name || firstName) ? (name || (firstName + " " + lastName)) : emailAddress,"color":_vm.audienceSelection.partlySelectedAudienceMap[oid] || active ? 'white' : 'grey'},nativeOn:{"click":function($event){return _vm.handleUserClick(("/audience/" + oid + "/view/overview"))}}}),_vm._v(" "),_c('ar-text',{staticClass:"name-text",style:({
          cursor: _vm.customerProfilesEnabled ? 'pointer' : null,
        }),attrs:{"size":"xs","allow-html":"","text":_vm.determineName(name, firstName, lastName, emailAddress),"multiple-lines":"","max-lines":1},nativeOn:{"click":function($event){return _vm.handleUserClick(("/audience/" + oid + "/view/overview"))}}})],1)}},{key:"registrationTime",fn:function(ref){
        var registrationTime = ref.data.registrationTime;
return _c('div',{staticClass:"cell"},[_c('ar-text',{attrs:{"size":"xs","text":_vm.calculateTimeElapsed(registrationTime)}})],1)}},{key:"dob",fn:function(ref){
        var dob = ref.data.dob;
return _c('div',{staticClass:"cell"},[_c('ar-text',{attrs:{"size":"xs","text":_vm.transformTimestringToDate(dob)}})],1)}},{key:"status",fn:function(ref){
        var status = ref.data.status;
return _c('div',{staticClass:"cell"},[_c('ar-text',{attrs:{"size":"xs","text":_vm.capitalizeFirstLetter(status)}})],1)}},{key:"sysMtime",fn:function(ref){
        var sysMtime = ref.data.sysMtime;
return _c('div',{staticClass:"cell"},[_c('ar-text',{attrs:{"size":"xs","text":_vm.formatDateModified(sysMtime)}})],1)}},{key:"country",fn:function(ref){
        var country = ref.data.country;
return _c('div',{staticClass:"cell"},[_c('ar-text',{attrs:{"size":"xs","text":_vm.getCountryName(country)}})],1)}},{key:"points",fn:function(ref){
        var data = ref.data;
        var head = ref.head;
return _c('div',{staticClass:"cell",style:({
      justifyContent: 'flex-end',
    })},[_c('am2-tag',{style:({ width: '83px' }),attrs:{"text":data[head.key],"type":"green","shape":"round"}})],1)}},{key:"currency",fn:function(ref){
    var data = ref.data;
    var head = ref.head;
return _c('div',{staticClass:"cell",style:({
        justifyContent: 'flex-end',
      })},[(data[head.key] !== null)?_c('ar-text',{attrs:{"size":"xs","text":_vm.renderRow(data[head.key], 'currency')}}):_vm._e()],1)}},{key:"number",fn:function(ref){
      var data = ref.data;
      var head = ref.head;
return _c('div',{staticClass:"cell",style:({
        justifyContent: 'flex-end',
      })},[_c('ar-text',{attrs:{"size":"xs","text":_vm.renderRow(data[head.key], 'number')}})],1)}},{key:"text",fn:function(ref){
      var data = ref.data;
      var head = ref.head;
return _c('div',{staticClass:"cell",style:({
        justifyContent: 'flex-end',
      })},[_c('ar-text',{attrs:{"size":"xs","text":data[head.key]}})],1)}},{key:"emailAddress",fn:function(ref){
      var data = ref.data;
      var head = ref.head;
return _c('div',{staticClass:"cell cell-left"},[_c('ar-text',{attrs:{"size":"xs","text":data[head.key]}})],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }