<template>
  <div class="triggers-wrapper">
    <template v-for="(contextType, triggerType) in options">
      <div class="trigger-context-wrapper" :key="randomisedKey(triggerType)">
        <div class="trigger-heading-wrapper">
          <div 
            v-html="require(`~/assets/icons/${contextType.icon}.svg?raw`)"
            :class="`sidebar-icon ${contextType.icon}-icon`"
          />
          <ar-text
            class="sidebar-type-heading"
            size="13px"
            :text="`${contextType.text.toUpperCase()}`"
            weight="bold"
            multiple-lines
            :max-lines="1"
          />
        </div>
        <draggable
          :group="{name: 'triggersDraggable', pull: 'clone', put: false}"
          :list="contextType.children"
          class="draggable-wrapper"
        >
          <template v-for="trigger in contextType.children">
            <div class="trigger-item-wrapper sidebar-item" :key="trigger.key" :data-attr="trigger.type" :data-type="trigger.key" :id="addRandomId(trigger)">
                <div class="trigger-draggable">
                  <ar-text
                    class="trigger-item-text"
                    size="14px"
                    :text="trigger.name"
                    weight="normal"
                    multiple-lines
                    :max-lines="1"
                  />
                </div>
                <div class="add-trigger-placeholder">
                  <ar-text
                    class="trigger-placeholder-text"
                    size="14px"
                    text="Drag a new trigger here"
                    weight="normal"
                    multiple-lines
                    :max-lines="1"
                  />
                </div>
            </div>
          </template>
        </draggable>
      </div>
    </template>
  </div>
</template>
<script>
import { generateRandomString } from '@/utils/helpers'
export default {
  name: 'AutoTriggers',
  props: {
    options: {
      type: Object,
      default: null,
    }
  },
  methods: {
    addRandomId(trigger) {
      let id = this.randomisedKey(trigger.key)
      trigger['id'] = id
      return id
    },
    randomisedKey(text) {
      return `${text}-${generateRandomString()}`
    }
  },
  
  mounted() {}
}
</script>
<style lang="scss" scoped>
$autoItemWidth: 325px;
.trigger-context-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 100%;

  &:first-child {
    .trigger-heading-wrapper {
      margin-top: 0;
    }
  }  

  .trigger-heading-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 12px;
    margin-top: 20px;    

    .sidebar-icon {
      margin-right: 8px;
    }
  }

  .draggable-wrapper {
    width: 100%;

    .trigger-item-wrapper {
      width: 100%;
      position: relative;
      margin-bottom: 8px;
      border-radius: 3px;
      border: solid 0.2px rgba(194, 194, 194, 0.3);
      background-color: white;
      border-left: 6px solid $purple500;
      padding: 14px 22px;
      cursor: pointer;
      width: 100%;

      .trigger-draggable {
        width: 100%;

        .trigger-item-text {
          pointer-events: none;
        }
      }

      .add-trigger-placeholder {
        width: 100%;
        background: lightblue;
        padding: 9px 11px;
        border-radius: 3px;
        box-shadow: 0 2px 6px 0 $shadow;
        border: solid 0.2px #c2c2c2;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: none;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.triggers-draggable-wrapper {
  .sortable-ghost {
    padding: 12px 16px;
    border-radius: 3px;
    border: solid 0.2px #c2c2c2;
    width: 325px;
    opacity: 0.3;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin-left: 9px;
    margin-right: 9px;
    height: inherit;

    .trigger-draggable {
      display: none;
    }
  }
}

</style>