<template>
  <section class="search-input" @keyup.esc="handleEscKey($event)" tabindex="0">
    <ar-input
      ref="input"
      class="input"
      :value="realValue"
      :placeholder="placeholder"
      :auto-focus="autoFocus"
      :enable-clear="enableClear"
      icon-name="search"
      autocomplete="search"
      @enter="handleEnterPress"
      @blur="handleSearchBlur"
      @input="handleInputChange"
      data-test-id="search-input"
    />
  </section>
</template>

<script>
export default {
  name: 'Search',

  props: {
    placeholder: {
      type: String,
      default: '',
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    enableClear: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      /**
       * Allows you to use ar-search without two-way binding by v-model, otherwise text inside ar-input
       * will disappear once it's blurred if you use value as ar-input's value
       */
      realValue: null,
    };
  },

  watch: {
    value: {
      handler: function (val) {
        this.realValue = val;
      },
      immediate: true,
    },
  },

  methods: {
    handleEscKey(e) {
      this.$emit('escape', e)
    },
    focus() {
      this.$refs.input.focus();
    },
    handleEnterPress() {
      this.$emit('enter');
    },
    handleSearchBlur() {
      this.$emit('blur');
    },
    handleInputChange(val) {
      this.realValue = val;
      this.$emit('input', val);
      if (val === '') {
        this.$emit('clear', '');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.search-input {
  height: 50px;

  .input {
    height: 100%;
  }
}
</style>
