var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-card-container',{staticClass:"activity-card",style:({
    cursor: _vm.link || _vm.path ? 'pointer' : null,
  }),attrs:{"layout":"soft"},nativeOn:{"click":function($event){return _vm.handleCardClick($event)}}},[_c('div',{staticClass:"activity-card__main"},[(_vm.imagePath)?_c('ar-avatar',{attrs:{"image-path":_vm.imagePath,"name":_vm.title}}):(_vm.icon.name)?_c('ar-icon',{class:[
        'activity-card-icon',
        _vm.types.join(' ') ],attrs:{"type":"secondary","name":_vm.icon.name,"color":_vm.icon.iconColor ? _vm.icon.iconColor : '#000',"width":_vm.icon.width,"height":_vm.icon.height,"wrapper-style":{
        backgroundColor: _vm.icon.iconBackground ? _vm.icon.iconBackground : null,
        height: '40px',
        minWidth: '40px',
        borderRadius: '50%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
      }}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-details"},[_c('div',{staticClass:"card-details__top"},[(_vm.combinedText)?_c('ar-text',{staticClass:"text text-title",attrs:{"size":"xs","allow-html":"","multiple-lines":"","text":_vm.combinedText,"weight":"normal"}}):_vm._e(),_vm._v(" "),(_vm.children.length > 0)?_c('ar-text',{staticClass:"text show-activity-text",attrs:{"size":"xxxs","weight":"normal","text":_vm.cardIsExpanded ? "<a>Hide Activity</a>" : "<a>Show Activity</a>","allow-html":""},on:{"anchorClick":_vm.toggleCardExpand}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"text-timeago-wrapper"},[(_vm.datetime)?_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
            content: _vm.calculateLongTimeString(_vm.datetime),
          }),expression:"{\n            content: calculateLongTimeString(datetime),\n          }",modifiers:{"bottom":true}}],staticClass:"text-timeago",attrs:{"size":"xxxs","text":_vm.calculateTimeElapsed(_vm.datetime),"weight":"normal"}}):_vm._e()],1)])],1),_vm._v(" "),(_vm.text)?_c('div',{staticClass:"activity-card__details"},[_c('ar-text',{staticClass:"text-body",attrs:{"size":"xs","text":_vm.text,"multiple-lines":"","weight":"normal"}})],1):_vm._e(),_vm._v(" "),_c('div',{class:[
    'activity-card__sub',
    _vm.cardIsExpanded && 'is-expanded'
  ]},[_vm._l((_vm.children),function(child){return _c('am2-activity-card',{key:child.oid,attrs:{"image-path":child.imagePath,"title":child.title,"subtitle":child.subtitle,"datetime":child.datetime,"text":child.text,"icon":child.icon,"children":[]}})}),_vm._v(" "),_c('div',{style:({
      backgroundColor: _vm.$arStyle.color.skyBlueGrey400,
      width: '2px',
      height: 'calc(100% - 48px)',
      position: 'absolute',
      top: '24px',
      left: '39px',
    })})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }