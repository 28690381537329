import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../client/store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../client/store/actions/index.js'), 'actions/index.js')
  resolveStoreModules(require('../client/store/modules/types.ts'), 'modules/types.ts')
  resolveStoreModules(require('../client/store/modules/zoomIntegration/index.ts'), 'modules/zoomIntegration/index.ts')
  resolveStoreModules(require('../client/store/modules/universeIntegration/index.ts'), 'modules/universeIntegration/index.ts')
  resolveStoreModules(require('../client/store/modules/tour/index.ts'), 'modules/tour/index.ts')
  resolveStoreModules(require('../client/store/modules/ticketekIntegration/index.ts'), 'modules/ticketekIntegration/index.ts')
  resolveStoreModules(require('../client/store/modules/tag/index.ts'), 'modules/tag/index.ts')
  resolveStoreModules(require('../client/store/modules/subscriber/index.ts'), 'modules/subscriber/index.ts')
  resolveStoreModules(require('../client/store/modules/stripeIntegration/index.ts'), 'modules/stripeIntegration/index.ts')
  resolveStoreModules(require('../client/store/modules/signupForm/index.ts'), 'modules/signupForm/index.ts')
  resolveStoreModules(require('../client/store/modules/shopifyIntegration/index.ts'), 'modules/shopifyIntegration/index.ts')
  resolveStoreModules(require('../client/store/modules/segment/index.ts'), 'modules/segment/index.ts')
  resolveStoreModules(require('../client/store/modules/recipient/index.ts'), 'modules/recipient/index.ts')
  resolveStoreModules(require('../client/store/modules/privacyPortal/index.ts'), 'modules/privacyPortal/index.ts')
  resolveStoreModules(require('../client/store/modules/payment/index.ts'), 'modules/payment/index.ts')
  resolveStoreModules(require('../client/store/modules/patreonIntegration/index.ts'), 'modules/patreonIntegration/index.ts')
  resolveStoreModules(require('../client/store/modules/orders/index.ts'), 'modules/orders/index.ts')
  resolveStoreModules(require('../client/store/modules/moshtixIntegration/index.ts'), 'modules/moshtixIntegration/index.ts')
  resolveStoreModules(require('../client/store/modules/messageSender/index.ts'), 'modules/messageSender/index.ts')
  resolveStoreModules(require('../client/store/modules/messageList/index.ts'), 'modules/messageList/index.ts')
  resolveStoreModules(require('../client/store/modules/message/index.ts'), 'modules/message/index.ts')
  resolveStoreModules(require('../client/store/modules/memberfulIntegration/index.ts'), 'modules/memberfulIntegration/index.ts')
  resolveStoreModules(require('../client/store/modules/layout/index.ts'), 'modules/layout/index.ts')
  resolveStoreModules(require('../client/store/modules/integration/index.ts'), 'modules/integration/index.ts')
  resolveStoreModules(require('../client/store/modules/insights/index.ts'), 'modules/insights/index.ts')
  resolveStoreModules(require('../client/store/modules/facebookMessengerIntegration/index.ts'), 'modules/facebookMessengerIntegration/index.ts')
  resolveStoreModules(require('../client/store/modules/facebookCAIntegration/index.ts'), 'modules/facebookCAIntegration/index.ts')
  resolveStoreModules(require('../client/store/modules/eventRSVPForm/index.ts'), 'modules/eventRSVPForm/index.ts')
  resolveStoreModules(require('../client/store/modules/eventixIntegration/index.ts'), 'modules/eventixIntegration/index.ts')
  resolveStoreModules(require('../client/store/modules/eventbriteIntegration/index.ts'), 'modules/eventbriteIntegration/index.ts')
  resolveStoreModules(require('../client/store/modules/event/index.ts'), 'modules/event/index.ts')
  resolveStoreModules(require('../client/store/modules/emailTemplate/index.ts'), 'modules/emailTemplate/index.ts')
  resolveStoreModules(require('../client/store/modules/customer/index.ts'), 'modules/customer/index.ts')
  resolveStoreModules(require('../client/store/modules/campaign/index.ts'), 'modules/campaign/index.ts')
  resolveStoreModules(require('../client/store/modules/automation/index.ts'), 'modules/automation/index.ts')
  resolveStoreModules(require('../client/store/modules/auth/index.ts'), 'modules/auth/index.ts')
  resolveStoreModules(require('../client/store/modules/audience/index.ts'), 'modules/audience/index.ts')
  resolveStoreModules(require('../client/store/modules/application/index.ts'), 'modules/application/index.ts')
  resolveStoreModules(require('../client/store/modules/admin/index.ts'), 'modules/admin/index.ts')
  resolveStoreModules(require('../client/store/modules/accessibility/index.ts'), 'modules/accessibility/index.ts')
  resolveStoreModules(require('../client/store/actions/modals/index.js'), 'actions/modals/index.js')
  resolveStoreModules(require('../client/store/actions/account/deleteAsset.js'), 'actions/account/deleteAsset.js')
  resolveStoreModules(require('../client/store/actions/account/editProfile.js'), 'actions/account/editProfile.js')
  resolveStoreModules(require('../client/store/actions/account/getAsset.js'), 'actions/account/getAsset.js')
  resolveStoreModules(require('../client/store/actions/account/putAsset.js'), 'actions/account/putAsset.js')
  resolveStoreModules(require('../client/store/actions/account/uploadAsset.js'), 'actions/account/uploadAsset.js')
  resolveStoreModules(require('../client/store/actions/application/checkPageAccessibility.js'), 'actions/application/checkPageAccessibility.js')
  resolveStoreModules(require('../client/store/actions/application/resetPageAccessibility.js'), 'actions/application/resetPageAccessibility.js')
  resolveStoreModules(require('../client/store/actions/audience/massEditFan.js'), 'actions/audience/massEditFan.js')
  resolveStoreModules(require('../client/store/actions/campaigns/cloneCampaign.js'), 'actions/campaigns/cloneCampaign.js')
  resolveStoreModules(require('../client/store/actions/campaigns/createCampaign.js'), 'actions/campaigns/createCampaign.js')
  resolveStoreModules(require('../client/store/actions/campaigns/createSocialAccounts.js'), 'actions/campaigns/createSocialAccounts.js')
  resolveStoreModules(require('../client/store/actions/campaigns/deleteCampaign.js'), 'actions/campaigns/deleteCampaign.js')
  resolveStoreModules(require('../client/store/actions/campaigns/deleteSocialAccount.js'), 'actions/campaigns/deleteSocialAccount.js')
  resolveStoreModules(require('../client/store/actions/campaigns/fetchCampaignRegistrations.js'), 'actions/campaigns/fetchCampaignRegistrations.js')
  resolveStoreModules(require('../client/store/actions/campaigns/fetchCampaignRegistrationsLatest.js'), 'actions/campaigns/fetchCampaignRegistrationsLatest.js')
  resolveStoreModules(require('../client/store/actions/campaigns/fetchCampaignRegistrationsSeries.js'), 'actions/campaigns/fetchCampaignRegistrationsSeries.js')
  resolveStoreModules(require('../client/store/actions/campaigns/fetchCampaigns.js'), 'actions/campaigns/fetchCampaigns.js')
  resolveStoreModules(require('../client/store/actions/campaigns/fetchCampaignsByOidAndSearch.js'), 'actions/campaigns/fetchCampaignsByOidAndSearch.js')
  resolveStoreModules(require('../client/store/actions/campaigns/fetchPreviewCampaign.js'), 'actions/campaigns/fetchPreviewCampaign.js')
  resolveStoreModules(require('../client/store/actions/campaigns/fetchSavedSocialAccounts.js'), 'actions/campaigns/fetchSavedSocialAccounts.js')
  resolveStoreModules(require('../client/store/actions/campaigns/fetchShareAnalyticsLink.js'), 'actions/campaigns/fetchShareAnalyticsLink.js')
  resolveStoreModules(require('../client/store/actions/campaigns/updateCampaign.js'), 'actions/campaigns/updateCampaign.js')
  resolveStoreModules(require('../client/store/actions/events/deleteTicketSales.js'), 'actions/events/deleteTicketSales.js')
  resolveStoreModules(require('../client/store/actions/events/fetchEventCampaigns.js'), 'actions/events/fetchEventCampaigns.js')
  resolveStoreModules(require('../client/store/actions/events/fetchEvents.js'), 'actions/events/fetchEvents.js')
  resolveStoreModules(require('../client/store/actions/events/fetchEventsByOidAndSearch.js'), 'actions/events/fetchEventsByOidAndSearch.js')
  resolveStoreModules(require('../client/store/actions/events/fetchPromoterTicketStats.js'), 'actions/events/fetchPromoterTicketStats.js')
  resolveStoreModules(require('../client/store/actions/events/importEventData.js'), 'actions/events/importEventData.js')
  resolveStoreModules(require('../client/store/actions/events/startEventSync.js'), 'actions/events/startEventSync.js')
  resolveStoreModules(require('../client/store/actions/events/stopEventSync.js'), 'actions/events/stopEventSync.js')
  resolveStoreModules(require('../client/store/actions/integrations/connectToIntegration.js'), 'actions/integrations/connectToIntegration.js')
  resolveStoreModules(require('../client/store/actions/integrations/createCustomProvider.js'), 'actions/integrations/createCustomProvider.js')
  resolveStoreModules(require('../client/store/actions/integrations/createIntegration.js'), 'actions/integrations/createIntegration.js')
  resolveStoreModules(require('../client/store/actions/integrations/deleteCustomAudienceIntegration.js'), 'actions/integrations/deleteCustomAudienceIntegration.js')
  resolveStoreModules(require('../client/store/actions/integrations/deleteCustomProvider.js'), 'actions/integrations/deleteCustomProvider.js')
  resolveStoreModules(require('../client/store/actions/integrations/deleteIntegration.js'), 'actions/integrations/deleteIntegration.js')
  resolveStoreModules(require('../client/store/actions/integrations/facebookIntegration.js'), 'actions/integrations/facebookIntegration.js')
  resolveStoreModules(require('../client/store/actions/integrations/fetchCustomProviders.js'), 'actions/integrations/fetchCustomProviders.js')
  resolveStoreModules(require('../client/store/actions/integrations/fetchFacebookPages.js'), 'actions/integrations/fetchFacebookPages.js')
  resolveStoreModules(require('../client/store/actions/integrations/fetchIntegration.js'), 'actions/integrations/fetchIntegration.js')
  resolveStoreModules(require('../client/store/actions/integrations/fetchIntegrationByAppProvider.js'), 'actions/integrations/fetchIntegrationByAppProvider.js')
  resolveStoreModules(require('../client/store/actions/integrations/fetchIntegrationEvents.js'), 'actions/integrations/fetchIntegrationEvents.js')
  resolveStoreModules(require('../client/store/actions/integrations/fetchIntegrations.js'), 'actions/integrations/fetchIntegrations.js')
  resolveStoreModules(require('../client/store/actions/integrations/fetchIntegrationTasks.js'), 'actions/integrations/fetchIntegrationTasks.js')
  resolveStoreModules(require('../client/store/actions/integrations/startIntegrationSync.js'), 'actions/integrations/startIntegrationSync.js')
  resolveStoreModules(require('../client/store/actions/integrations/stopIntegrationSync.js'), 'actions/integrations/stopIntegrationSync.js')
  resolveStoreModules(require('../client/store/actions/integrations/updateIntegration.js'), 'actions/integrations/updateIntegration.js')
  resolveStoreModules(require('../client/store/actions/messages/fetchMessageListsByFan.js'), 'actions/messages/fetchMessageListsByFan.js')
  resolveStoreModules(require('../client/store/actions/messages/fetchMessageStatsByOid.js'), 'actions/messages/fetchMessageStatsByOid.js')
  resolveStoreModules(require('../client/store/actions/payments/createPaymentSubscription.js'), 'actions/payments/createPaymentSubscription.js')
  resolveStoreModules(require('../client/store/actions/payments/fetchPromoterPayment.js'), 'actions/payments/fetchPromoterPayment.js')
  resolveStoreModules(require('../client/store/actions/payments/updatePayment.js'), 'actions/payments/updatePayment.js')
  resolveStoreModules(require('../client/store/actions/payments/updatePaymentSubscription.js'), 'actions/payments/updatePaymentSubscription.js')
  resolveStoreModules(require('../client/store/actions/staff-accounts/createStaffAccount.js'), 'actions/staff-accounts/createStaffAccount.js')
  resolveStoreModules(require('../client/store/actions/staff-accounts/deleteStaffAccount.js'), 'actions/staff-accounts/deleteStaffAccount.js')
  resolveStoreModules(require('../client/store/actions/staff-accounts/fetchPromoterAccount.js'), 'actions/staff-accounts/fetchPromoterAccount.js')
  resolveStoreModules(require('../client/store/actions/staff-accounts/fetchStaffAccounts.js'), 'actions/staff-accounts/fetchStaffAccounts.js')
  resolveStoreModules(require('../client/store/actions/staff-accounts/updatePromoterAccount.js'), 'actions/staff-accounts/updatePromoterAccount.js')
  resolveStoreModules(require('../client/store/actions/tours/createTour.js'), 'actions/tours/createTour.js')
  resolveStoreModules(require('../client/store/actions/tours/deleteTour.js'), 'actions/tours/deleteTour.js')
  resolveStoreModules(require('../client/store/actions/tours/fetchTourByOid.js'), 'actions/tours/fetchTourByOid.js')
  resolveStoreModules(require('../client/store/actions/tours/fetchTours.js'), 'actions/tours/fetchTours.js')
  resolveStoreModules(require('../client/store/actions/tours/updateTour.js'), 'actions/tours/updateTour.js')
  resolveStoreModules(require('../client/store/modules/admin/actions.ts'), 'modules/admin/actions.ts')
  resolveStoreModules(require('../client/store/modules/admin/types.ts'), 'modules/admin/types.ts')
  resolveStoreModules(require('../client/store/modules/admin/utils.ts'), 'modules/admin/utils.ts')
  resolveStoreModules(require('../client/store/modules/application/plugin.ts'), 'modules/application/plugin.ts')
  resolveStoreModules(require('../client/store/modules/application/types.ts'), 'modules/application/types.ts')
  resolveStoreModules(require('../client/store/modules/audience/actions.ts'), 'modules/audience/actions.ts')
  resolveStoreModules(require('../client/store/modules/audience/types.ts'), 'modules/audience/types.ts')
  resolveStoreModules(require('../client/store/modules/audience/utils.ts'), 'modules/audience/utils.ts')
  resolveStoreModules(require('../client/store/modules/auth/actions.ts'), 'modules/auth/actions.ts')
  resolveStoreModules(require('../client/store/modules/auth/types.ts'), 'modules/auth/types.ts')
  resolveStoreModules(require('../client/store/modules/auth/utils.ts'), 'modules/auth/utils.ts')
  resolveStoreModules(require('../client/store/modules/automation/actions.ts'), 'modules/automation/actions.ts')
  resolveStoreModules(require('../client/store/modules/automation/types.ts'), 'modules/automation/types.ts')
  resolveStoreModules(require('../client/store/modules/automation/utils.ts'), 'modules/automation/utils.ts')
  resolveStoreModules(require('../client/store/modules/campaign/actions.ts'), 'modules/campaign/actions.ts')
  resolveStoreModules(require('../client/store/modules/campaign/types.ts'), 'modules/campaign/types.ts')
  resolveStoreModules(require('../client/store/modules/campaign/utils.ts'), 'modules/campaign/utils.ts')
  resolveStoreModules(require('../client/store/modules/customer/actions.ts'), 'modules/customer/actions.ts')
  resolveStoreModules(require('../client/store/modules/customer/types.ts'), 'modules/customer/types.ts')
  resolveStoreModules(require('../client/store/modules/customer/utils.ts'), 'modules/customer/utils.ts')
  resolveStoreModules(require('../client/store/modules/emailTemplate/actions.ts'), 'modules/emailTemplate/actions.ts')
  resolveStoreModules(require('../client/store/modules/emailTemplate/types.ts'), 'modules/emailTemplate/types.ts')
  resolveStoreModules(require('../client/store/modules/emailTemplate/utils.ts'), 'modules/emailTemplate/utils.ts')
  resolveStoreModules(require('../client/store/modules/event/actions.ts'), 'modules/event/actions.ts')
  resolveStoreModules(require('../client/store/modules/event/types.ts'), 'modules/event/types.ts')
  resolveStoreModules(require('../client/store/modules/event/utils.ts'), 'modules/event/utils.ts')
  resolveStoreModules(require('../client/store/modules/eventbriteIntegration/actions.ts'), 'modules/eventbriteIntegration/actions.ts')
  resolveStoreModules(require('../client/store/modules/eventbriteIntegration/types.ts'), 'modules/eventbriteIntegration/types.ts')
  resolveStoreModules(require('../client/store/modules/eventixIntegration/actions.ts'), 'modules/eventixIntegration/actions.ts')
  resolveStoreModules(require('../client/store/modules/eventixIntegration/types.ts'), 'modules/eventixIntegration/types.ts')
  resolveStoreModules(require('../client/store/modules/eventRSVPForm/actions.ts'), 'modules/eventRSVPForm/actions.ts')
  resolveStoreModules(require('../client/store/modules/eventRSVPForm/types.ts'), 'modules/eventRSVPForm/types.ts')
  resolveStoreModules(require('../client/store/modules/eventRSVPForm/utils.ts'), 'modules/eventRSVPForm/utils.ts')
  resolveStoreModules(require('../client/store/modules/facebookCAIntegration/actions.ts'), 'modules/facebookCAIntegration/actions.ts')
  resolveStoreModules(require('../client/store/modules/facebookCAIntegration/types.ts'), 'modules/facebookCAIntegration/types.ts')
  resolveStoreModules(require('../client/store/modules/facebookMessengerIntegration/actions.ts'), 'modules/facebookMessengerIntegration/actions.ts')
  resolveStoreModules(require('../client/store/modules/facebookMessengerIntegration/types.ts'), 'modules/facebookMessengerIntegration/types.ts')
  resolveStoreModules(require('../client/store/modules/insights/actions.ts'), 'modules/insights/actions.ts')
  resolveStoreModules(require('../client/store/modules/insights/types.ts'), 'modules/insights/types.ts')
  resolveStoreModules(require('../client/store/modules/insights/utils.ts'), 'modules/insights/utils.ts')
  resolveStoreModules(require('../client/store/modules/integration/actions.ts'), 'modules/integration/actions.ts')
  resolveStoreModules(require('../client/store/modules/integration/types.ts'), 'modules/integration/types.ts')
  resolveStoreModules(require('../client/store/modules/integration/utils.ts'), 'modules/integration/utils.ts')
  resolveStoreModules(require('../client/store/modules/memberfulIntegration/actions.ts'), 'modules/memberfulIntegration/actions.ts')
  resolveStoreModules(require('../client/store/modules/memberfulIntegration/types.ts'), 'modules/memberfulIntegration/types.ts')
  resolveStoreModules(require('../client/store/modules/message/actions.ts'), 'modules/message/actions.ts')
  resolveStoreModules(require('../client/store/modules/message/types.ts'), 'modules/message/types.ts')
  resolveStoreModules(require('../client/store/modules/message/utils.ts'), 'modules/message/utils.ts')
  resolveStoreModules(require('../client/store/modules/messageList/actions.ts'), 'modules/messageList/actions.ts')
  resolveStoreModules(require('../client/store/modules/messageList/types.ts'), 'modules/messageList/types.ts')
  resolveStoreModules(require('../client/store/modules/messageList/utils.ts'), 'modules/messageList/utils.ts')
  resolveStoreModules(require('../client/store/modules/messageSender/actions.ts'), 'modules/messageSender/actions.ts')
  resolveStoreModules(require('../client/store/modules/messageSender/types.ts'), 'modules/messageSender/types.ts')
  resolveStoreModules(require('../client/store/modules/messageSender/utils.ts'), 'modules/messageSender/utils.ts')
  resolveStoreModules(require('../client/store/modules/moshtixIntegration/actions.ts'), 'modules/moshtixIntegration/actions.ts')
  resolveStoreModules(require('../client/store/modules/moshtixIntegration/types.ts'), 'modules/moshtixIntegration/types.ts')
  resolveStoreModules(require('../client/store/modules/orders/actions.ts'), 'modules/orders/actions.ts')
  resolveStoreModules(require('../client/store/modules/orders/types.ts'), 'modules/orders/types.ts')
  resolveStoreModules(require('../client/store/modules/orders/utils.ts'), 'modules/orders/utils.ts')
  resolveStoreModules(require('../client/store/modules/patreonIntegration/actions.ts'), 'modules/patreonIntegration/actions.ts')
  resolveStoreModules(require('../client/store/modules/patreonIntegration/types.ts'), 'modules/patreonIntegration/types.ts')
  resolveStoreModules(require('../client/store/modules/payment/actions.ts'), 'modules/payment/actions.ts')
  resolveStoreModules(require('../client/store/modules/payment/types.ts'), 'modules/payment/types.ts')
  resolveStoreModules(require('../client/store/modules/payment/utils.ts'), 'modules/payment/utils.ts')
  resolveStoreModules(require('../client/store/modules/privacyPortal/actions.ts'), 'modules/privacyPortal/actions.ts')
  resolveStoreModules(require('../client/store/modules/privacyPortal/types.ts'), 'modules/privacyPortal/types.ts')
  resolveStoreModules(require('../client/store/modules/privacyPortal/utils.ts'), 'modules/privacyPortal/utils.ts')
  resolveStoreModules(require('../client/store/modules/recipient/actions.ts'), 'modules/recipient/actions.ts')
  resolveStoreModules(require('../client/store/modules/recipient/types.ts'), 'modules/recipient/types.ts')
  resolveStoreModules(require('../client/store/modules/segment/actions.ts'), 'modules/segment/actions.ts')
  resolveStoreModules(require('../client/store/modules/segment/types.ts'), 'modules/segment/types.ts')
  resolveStoreModules(require('../client/store/modules/segment/utils.ts'), 'modules/segment/utils.ts')
  resolveStoreModules(require('../client/store/modules/shopifyIntegration/actions.ts'), 'modules/shopifyIntegration/actions.ts')
  resolveStoreModules(require('../client/store/modules/shopifyIntegration/types.ts'), 'modules/shopifyIntegration/types.ts')
  resolveStoreModules(require('../client/store/modules/signupForm/actions.ts'), 'modules/signupForm/actions.ts')
  resolveStoreModules(require('../client/store/modules/signupForm/types.ts'), 'modules/signupForm/types.ts')
  resolveStoreModules(require('../client/store/modules/signupForm/utils.ts'), 'modules/signupForm/utils.ts')
  resolveStoreModules(require('../client/store/modules/stripeIntegration/actions.ts'), 'modules/stripeIntegration/actions.ts')
  resolveStoreModules(require('../client/store/modules/stripeIntegration/types.ts'), 'modules/stripeIntegration/types.ts')
  resolveStoreModules(require('../client/store/modules/subscriber/actions.ts'), 'modules/subscriber/actions.ts')
  resolveStoreModules(require('../client/store/modules/subscriber/types.ts'), 'modules/subscriber/types.ts')
  resolveStoreModules(require('../client/store/modules/tag/actions.ts'), 'modules/tag/actions.ts')
  resolveStoreModules(require('../client/store/modules/tag/types.ts'), 'modules/tag/types.ts')
  resolveStoreModules(require('../client/store/modules/ticketekIntegration/actions.ts'), 'modules/ticketekIntegration/actions.ts')
  resolveStoreModules(require('../client/store/modules/ticketekIntegration/types.ts'), 'modules/ticketekIntegration/types.ts')
  resolveStoreModules(require('../client/store/modules/tour/actions.ts'), 'modules/tour/actions.ts')
  resolveStoreModules(require('../client/store/modules/tour/types.ts'), 'modules/tour/types.ts')
  resolveStoreModules(require('../client/store/modules/universeIntegration/actions.ts'), 'modules/universeIntegration/actions.ts')
  resolveStoreModules(require('../client/store/modules/universeIntegration/types.ts'), 'modules/universeIntegration/types.ts')
  resolveStoreModules(require('../client/store/modules/zoomIntegration/actions.ts'), 'modules/zoomIntegration/actions.ts')
  resolveStoreModules(require('../client/store/modules/zoomIntegration/types.ts'), 'modules/zoomIntegration/types.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
