var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"campaign-modify-layout"},[_c('ChangeEventModal',{attrs:{"is-change-event-modal-visible":_vm.isChangeEventModalVisible,"is-new-campaign":_vm.isNewCampaign,"campaign-oid":_vm.editCampaign.oid},on:{"change":function (val) { return _vm.isChangeEventModalVisible = val; },"changeEvent":_vm.handleChangeEvent}}),_vm._v(" "),_c('am2-top-create',{ref:"campaign-top",attrs:{"total-steps":6,"current-step":_vm.currentStep,"loading":_vm.isFetchingCampaign,"exit-path":"/campaigns"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('am2-heading',{style:({
          marginBottom: '4px',
        }),attrs:{"type":"h1","size":"sm","weight":"bold","title":_vm.editCampaign.name}}),_vm._v(" "),_c('div',{style:({
          display: 'flex',
          alignItems: 'center',
        })},[(_vm.campaignType)?_c('am2-tag',{style:({
            position: 'relative',
            height: '25px',
            marginRight: '10px',
            padding: '0 10px',
          }),attrs:{"text":_vm.campaignType,"shape":"rectangle"}}):_vm._e(),_vm._v(" "),_c('ar-text',{style:({
            color: '#9fa8b5',
            maxWidth: 'calc(100vw - 292px)',
          }),attrs:{"text":((_vm.editCampaign.event.name) + " " + _vm.titleDateText + " -"),"size":"xs"}}),_vm._v(" "),_c('ar-link-button',{style:({
            marginLeft: '4px',
          }),attrs:{"text":"Change event"},on:{"click":function($event){_vm.isChangeEventModalVisible = true}}})],1)],1)]),_vm._v(" "),_c('div',{class:[
    'wrapper',
    _vm.$arMediaQuery.window.maxWidth('sm') && 'sm-max'
  ]},[_c('div',{staticClass:"left"},[_c('div',{ref:"inner",staticClass:"inner"},[(_vm.isFetchingCampaign)?_c('am2-loading-section',{style:({
            height: '100%',
          })}):_vm._e(),_vm._v(" "),_c('nuxt-child',{ref:"campaign-edit",style:({
            overflow: _vm.isFetchingCampaign ? 'hidden' : null,
            height: _vm.isFetchingCampaign ? '0' : null,
          }),attrs:{"is-new":_vm.isNewCampaign,"editCampaign":_vm.editCampaign,"setEditCampaign":_vm.setEditCampaign}})],1),_vm._v(" "),_c('nav',{staticClass:"navigation",style:({
          justifyContent: _vm.isNewCampaign || (!_vm.isNewCampaign && _vm.currentEditStepIndex > 0) ? 'space-between' : 'flex-end',
        })},[(_vm.isNewCampaign || (!_vm.isNewCampaign && _vm.currentEditStepIndex > 0))?_c('ar-simple-button',{style:({ width: _vm.$arMediaQuery.window.minWidth('sm') ? '120px' : '90px' }),attrs:{"text":"Back","outlined":""},on:{"click":_vm.handlePreviousStep}}):_vm._e(),_vm._v(" "),_c('div',{style:({
            display: 'flex',
            justifyContent: 'flex-end',
          })},[(_vm.$arMediaQuery.window.maxWidth('sm'))?_c('ar-simple-button',{style:({
              marginRight: '12px',
              width: _vm.$arMediaQuery.window.minWidth('sm') ? '135px' : '90px'
            }),attrs:{"text":_vm.previewIsOpen ? 'Edit' : 'Preview',"outlined":"","icon-name":(_vm.previewIsOpen || _vm.$arMediaQuery.window.maxWidth('xs')) ? null : 'mobile-preview',"icon-distance":"10px","icon-props":{
              height: '26px',
              color: _vm.$arStyle.color.purple200,
            }},on:{"click":_vm.handlePreviewClick}}):_vm._e(),_vm._v(" "),_c('ar-simple-button',{directives:[{name:"ar-hotkey",rawName:"v-ar-hotkey",value:({
              general: ['Control', 'Enter'],
              mac: ['Meta', 'Enter'],
              disabled: false,
              action: _vm.handleNextStep,
            }),expression:"{\n              general: ['Control', 'Enter'],\n              mac: ['Meta', 'Enter'],\n              disabled: false,\n              action: handleNextStep,\n            }"}],style:({ width: _vm.$arMediaQuery.window.minWidth('sm') ? '135px' : '90px' }),attrs:{"text":_vm.nextPage ? 'Next' : 'Finish',"loading":_vm.isUpdatingCampaign || _vm.isFetchingCampaign},on:{"click":_vm.handleNextStep}})],1)],1)]),_vm._v(" "),_c('div',{class:[
      'right',
      _vm.$arMediaQuery.window.maxWidth('sm') && _vm.previewIsOpen && 'is-visible'
    ]},[_c('am2-campaign-preview',{ref:"campaign-preview",staticClass:"preview",attrs:{"has-account":_vm.previewHasAccount,"has-fan":_vm.previewHasFan,"preview-page":_vm.previewPage,"campaign":_vm.campaignForReview,"size":"fullHeight"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }