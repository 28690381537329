var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.withAnnotations)?_c('div',{staticClass:"annotations-wrapper",style:(Object.assign({}, Object.assign({}, _vm.styles,
      _vm.annotationsWrapperStyle)))},_vm._l((_vm.adjustedAnnotations),function(annotation,index){return _c('div',{key:index,staticClass:"annotation-wrapper"},[(annotation.visible)?_c('div',{staticClass:"annotation-info-wrapper",class:annotation.active ? 'active' : 'hidden',style:({
        left: ((_vm.getAnnotationInfoStyle(annotation)) + "px"),
        width: ((annotation.infoWrapperWidth) + "px")
      })},[_vm._t("default",[_c('div',{staticClass:"triangle",style:({
            left: ((annotation.width / 2 - 5) + "px")
          })},[_c('div',{staticClass:"triangle-outer"}),_vm._v(" "),_c('div',{staticClass:"triangle-inner"})]),_vm._v(" "),(annotation.status === 3)?_c('div',{staticClass:"annotation-edit-wrapper"},[_c('div',{staticClass:"annotation-edit-container"},[_c('div',{staticClass:"annotation-header-wrapper"},[_c('div',{staticClass:"annotation-header-left-wrapper"},[_c('div',{staticClass:"annotation-circle"}),_vm._v(" "),_c('ar-text',{staticClass:"annotation-heading",attrs:{"size":"12px","text":"Annotation","multiple-lines":"","max-lines":1,"line-height":"12px","weight":"bold"}}),_vm._v(" "),_c('ar-text',{staticClass:"annotation-date",attrs:{"size":"12px","text":("-  " + (_vm.monthDayYear(annotation.timeMillis))),"multiple-lines":"","max-lines":1,"line-height":"12px"}})],1),_vm._v(" "),_c('div',{staticClass:"close-icon-wrapper",attrs:{"data-test-id":"cancel-update-annotation-button"},on:{"click":function($event){return _vm.cancelAnnoEdit(annotation)}}},[_c('div',{staticClass:"close-icon"})])]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(annotation.body),expression:"annotation.body"}],staticClass:"edit-annotation-textarea",attrs:{"rows":"3","placeholder":"Enter your annotation here…","data-test-id":"edit-annotation-textarea"},domProps:{"value":(annotation.body)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(annotation, "body", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"edit-actions-wrapper"},[_c('ar-simple-button',{staticClass:"update-annotation-button",style:({
                  padding: '13px 16px',
                  height: 'unset',
                  width: '102px',
                  marginRight: '8px'
                }),attrs:{"text":"Save","data-test-id":"update-annotation-button"},on:{"click":function($event){return _vm.updateAnnotation(annotation)}}}),_vm._v(" "),_c('ar-link-button',{staticClass:"annotation-delete-button",attrs:{"text":"Delete","has-underline":true,"color":_vm.$arStyle.color.$purple500,"data-test-id":"delete-annotation-button"},on:{"click":function($event){return _vm.deleteAnnotation(annotation)}}})],1)])]):_vm._e(),_vm._v(" "),(annotation.status === 2)?_c('div',{staticClass:"saved-annotation-wrapper",on:{"mouseleave":function($event){return _vm.removeActiveState(annotation.oid)}}},[_c('div',{staticClass:"annotation-container",attrs:{"data-test-id":"update-annotation-trigger-div"},on:{"click":function($event){return _vm.updateToEditState(annotation)}}},[_c('div',{staticClass:"annotation-header-wrapper"},[_c('div',{staticClass:"annotation-header-left-wrapper"},[_c('div',{staticClass:"annotation-circle"}),_vm._v(" "),_c('ar-text',{staticClass:"annotation-heading",attrs:{"size":"12px","text":"Annotation","multiple-lines":"","max-lines":1,"line-height":"12px","weight":"bold"}}),_vm._v(" "),_c('ar-text',{staticClass:"annotation-date",attrs:{"size":"12px","text":("-  " + (_vm.monthDayYear(annotation.timeMillis))),"multiple-lines":"","max-lines":1,"line-height":"12px"}})],1),_vm._v(" "),_c('ar-link-button',{staticClass:"annotation-edit-button",attrs:{"text":"edit","has-underline":true,"color":_vm.$arStyle.color.$purple500,"data-test-id":"update-annotation-trigger-button","text-props":{
                  size: '12px'
                }},on:{"click":function($event){return _vm.updateToEditState(annotation)}}})],1),_vm._v(" "),_c('ar-text',{staticClass:"annotation-description",attrs:{"size":"14px","text":annotation.body,"multiple-lines":"","line-height":"14px","weight":"bold"}})],1)]):_vm._e(),_vm._v(" "),(annotation.status === 1)?_c('div',{staticClass:"create-annotation-wrapper"},[_c('div',{staticClass:"close-icon-wrapper",attrs:{"data-test-id":"close-annotation-create-button"},on:{"click":function($event){return _vm.closeAnnoCreate(index)}}},[_c('div',{staticClass:"close-icon"})]),_vm._v(" "),_c('div',{staticClass:"create-annotation-container"},[_c('div',{staticClass:"create-annotation-header-wrapper"},[_c('div',{staticClass:"annotation-circle"}),_vm._v(" "),_c('ar-text',{staticClass:"annotation-heading",attrs:{"size":"12px","text":"Annotation","multiple-lines":"","max-lines":1,"line-height":"12px","weight":"bold"}}),_vm._v(" "),_c('ar-text',{staticClass:"annotation-date",attrs:{"size":"12px","text":("-  " + (_vm.monthDayYear(annotation.timeMillis))),"multiple-lines":"","max-lines":1,"line-height":"12px"}})],1),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(annotation.body),expression:"annotation.body"}],staticClass:"create-annotation-textarea",attrs:{"rows":"3","placeholder":"Enter your annotation here…","data-test-id":"create-annotation-textarea"},domProps:{"value":(annotation.body)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(annotation, "body", $event.target.value)}}}),_vm._v(" "),_c('ar-simple-button',{staticClass:"create-annotation-button",style:({
                padding: '13px 16px',
                height: 'unset',
              }),attrs:{"text":"Save","data-test-id":"create-annotation-button"},on:{"click":function($event){return _vm.createAnnotationHandler(annotation)}}})],1)]):_vm._e(),_vm._v(" "),(annotation.status === 0)?_c('div',{staticClass:"confirm-create-annotation-wrapper"},[_c('div',{staticClass:"close-icon-wrapper",attrs:{"data-test-id":"cancel-create-annotation-button"},on:{"click":function($event){return _vm.closeAnnoCreate(index)}}},[_c('div',{staticClass:"close-icon"})]),_vm._v(" "),_c('div',{staticClass:"confirm-create-copy-wrapper",attrs:{"data-test-id":"confirm-create-annotation-div"},on:{"click":function($event){return _vm.confirmAddAnno(annotation)}}},[_c('ar-text',{staticClass:"confirm-create-date",attrs:{"size":"14px","text":_vm.monthDayYear(annotation.timeMillis),"multiple-lines":"","max-lines":1,"line-height":"20px"}}),_vm._v(" "),_c('ar-text',{staticClass:"confirm-create-copy",attrs:{"size":"12px","text":"Click to add annotation","multiple-lines":"","max-lines":1,"line-height":"20px"}})],1)]):_vm._e()],{"annotation":annotation})],2):_vm._e(),_vm._v(" "),(annotation.visible)?_c('am2-apex-annotation-marker',{attrs:{"styles":{
        left: ((annotation.leftCalc) + "px"),
        background: _vm.handleMarkerBgColor(annotation)
      }},on:{"mouseover":function($event){return _vm.addActiveState(index)}}}):_vm._e()],1)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }