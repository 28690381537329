<template>
    <ar-select
      ref="select"
      enable-filter
      :items="computedMessages"
      :default-select-index="selectedMessageIndex"
      :default-filter-string="searchString"
      :placeholder="messagePlaceholder"
      :filter-placeholder="filterPlaceholder"
      @select="handleSelect"
      @filterStringInput="handleFilterStringInput"
      :disabled="disabled"
      @clear="() => { $emit('clear') }"
      @close="() => { $emit('close') }"
    >
      <template
        slot="selected-item"
        slot-scope="selectedItemScope"
      >
        <div
          v-if="selectedItemScope.item"
          :style="{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          padding: '0 14px',
        }"
          :data-test-id="`message-${selectedItemScope.item.meta && selectedItemScope.item.meta.name ? selectedItemScope.item.meta.name : selectedItemScope.item.name}`"
        >
          <ar-text
            size="xs"
            :text="selectedItemScope.item.meta && selectedItemScope.item.meta.name ? selectedItemScope.item.meta.name : selectedItemScope.item.name"
            :style="{
            color: $arStyle.color.skyBlueGrey800,
          }"
          />
        </div>
        <div
          v-else
          :style="{
          display: 'flex',
          height: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '0 14px',
        }"
        >
          <ar-text
            size="xs"
            :text="messagePlaceholder"
            :style="{
            color: $arStyle.color.blueGrey600,
          }"
          />
        </div>
      </template>

      <template
        slot="item"
        slot-scope="itemScope"
      >
        <div
          :style="{
          display: 'flex',
          alignItems: 'center',
          height: '55px',
          padding: '0 15px',
          cursor: 'pointer',
          background: itemScope.active ? $arStyle.color.purple100 : null,
          borderBottom: '1px solid #dcdee4',
        }"
          :data-test-id="`message-${itemScope.item.meta && itemScope.item.meta.name ? itemScope.item.meta.name : itemScope.item.name}`"
        >
          <ar-text
            size="xs"
            :text="itemScope.item.meta && itemScope.item.meta.name ? itemScope.item.meta.name : itemScope.item.name"
            :style="{
            width: 'auto',
            color: itemScope.active ? '#7344c0' : '#43516b',
          }"
            :weight="itemScope.selected ? 'bold' : 'normal'"
          />
        </div>
      </template>

      <template
        slot="no-item"
      >
        <div
          :style="{
          display: 'flex',
          alignItems: 'center',
          height: '55px',
          padding: '0 15px',
        }"
        >
          <ar-text
            size="xs"
            text="No available messages"
          />
        </div>
      </template>
    </ar-select>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex';

  export default {
    name: 'MessageSelect',

    props: {
      value: { // Value equals to message-list-oid
        type: Number,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      messages: {
        type: Array,
        default: () => [],
      },
      searchString: {
        type: String,
        default: null,
      }
    },

    data() {
      return {
        selectedMessageOid: this.value,
      };
    },

    computed: {
      computedMessages() {
        return this.messages;
      },

      selectedMessageIndex() {
        return this.messages.findIndex(message => message.value === this.selectedMessageOid);
      },

      messagePlaceholder() {
        return this.searchString || "Select a message..."
      }
    },

    watch: {
      value(val) {
        this.selectedMessageOid = val;
      }
    },

    mounted() {

    },

    methods: {
      filterPlaceholder(value) {
        return this.messagePlaceholder;
      },
      // For external uses, don't delete it
      openDropdown() {
        this.$refs.select.toggleDropdown(true);
      },
      handleFilterStringInput(val) {
        this.$emit('filterStringInput', val);
      },
      handleSelect(item, idx) {
        this.selectedMessageOid = item.oid;
        this.$emit('input', item.oid);
        this.$emit('select', item);
      },
    }

  };
</script>
