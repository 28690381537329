<template>
  <section class="event-insights">
    <am2-tabs
      :items="tabItems"
      @select="handleMenuSelect"
      class="u-margin-top-3"
    />
    <ar-divider
      class="u-margin-bottom-10"
    />
    <EventInsightsPage
      v-show="activeTab == 'event'"
      :event-oid="eventOid"
      insight-type="event"
      feature-type="event-insights"
    />
    <am2-insights-section
      enable-see-all-table-route-change
      v-show="activeTab == 'audience'"
      :event-oid="eventOid"
      insight-type="event"
      feature-type="event-insights"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import checkoutPageAccessibility from '@/mixins/checkoutPageAccessibility';
import EventInsightsPage from './components/event-insights/'

export default {
  name: 'EventInsights',

  mixins: [
    checkoutPageAccessibility({ featureKeys: ['insights'], featureName: 'Insights', skip: process.env.arEnableActivationState }),
  ],

  data () {
    return {
      activeTab: 'event',
      tabItems: [
        {
          value: 'event',
          title: 'Event Insights',
        },
        {
          value: 'audience',
          title: 'Audience Insights',
        },
      ],
    }
  },

  components: {
    EventInsightsPage,
  },

  computed: {
    ...mapState({
      eventOid: state => state.route.params.oid,
    }),
  },

  created() {
    this.$store.registerModule("event-view-child", {
      mutations: {
        FORCED_EVENT_UPDATE(state) {
          this.loadInsights(this.eventOid);
        }
      }
    })
  },

  methods: {
    handleMenuSelect(menu) {
      this.activeTab = menu.value;
    },

  },

  beforeDestroy() {
    // this.$store.unregisterModule("event-view-child");
  },
};
</script>

<style lang="scss" scoped>
.event-insights {
}
</style>
