<template>
  <div class="auto-page-container">
    <div class="automation-listing-page-wrapper">
      <AutomationHeadingSection
        v-model="selectedListType"
        :options="headingOptions"
      />

      <div class="automation-listing-items-wrapper">
        <template v-for="item in automations">
          <AutomationCard
            :key="item.oid"
            :item="item"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import AutomationHeadingSection from './components/AutomationHeadingSection'
import { mapState, mapActions } from 'vuex'
import AutomationCard from './components/AutomationList/AutomationCard'
export default {
  components: {
    AutomationHeadingSection,
    AutomationCard
  },
  data() {
    return {
      filter: '',
      queryParams: {},
      selectedListType: { name: 'All automations', key: 'all' },
      headingOptions: [
        {
          name: 'All automations',
          key: 'all',
        },
        {
          name: 'Active automations',
          key: 'active',
        },
        {
          name: 'Paused automations',
          key: 'paused',
        },
        {
          name: 'Inactive automations',
          key: 'inactive',
        },
      ],
      poller: null,
    }
  },
  computed: {
    ...mapState({
      automations: state => state.automation.automationsList
    }),
  },
  methods: {
    ...mapActions({
      fetchAutomations: 'automation/FETCH_AUTOMATIONS',
      clearAutomationsList: 'automation/CLEAR_AUTOMATIONS_LIST'
    })
  },
  beforeRouteLeave(_, __, next) {
    clearInterval(this.poller)
    this.clearAutomationsList()
    next()
  },
  fetch() {
    this.fetchAutomations()
  }
}
</script>
<style lang="scss" scoped>
.auto-page-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.automation-listing-page-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
  max-width: 1260px;
  padding: 0 30px 50px;

  .automation-listing-items-wrapper {
    width: 100%;
    margin-top: 25px;
  }
}
</style>