<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="edit-audience-filter-modal"
      :header="title"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'edit-audience-filter-modal-body',
        ]">
        <ar-text
          size="xs"
          :text="inputLabel"
        />
        <ar-input
          class="u-margin-top-3"
          v-if="isShow"
          v-model="newName"
          :placeholder="placeholder"
        />
      </div>
      <div
        slot="footer"
        :class="[
          'edit-audience-filter-modal-footer',
        ]">
        <ar-simple-button
          type="red"
          outlined
          :text="deleteButtonText"
          :loading="isDeleting"
          :disabled="disableDeleteButton"
          @click="handleDeleteClick"
        />
        <ar-simple-button
          class="u-margin-left-2"
          text="Rename Segment"
          :loading="isRenaming"
          :disabled="disableSaveButton"
          @click="handleSaveClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
export default {
  name: 'EditSegmentModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    inputLabel: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    deleteButtonText: {
      type: String,
      default: null,
    },
    defaultName: {
      type: String,
      default: '',
    },
    isDeleting: {
      type: Boolean,
      default: false,
    },
    isRenaming: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newName: null,
    };
  },
  computed: {
    disableDeleteButton() {
      return this.isRenaming;
    },
    disableSaveButton() {
      return !this.newName || (this.defaultName === this.newName) || this.isDeleting;
    },
  },

  watch: {
    isShow(val) {
      if (val) {
        this.newName = this.defaultName;
      }
    },
  },

  methods: {
    handleSaveClick() {
      this.$emit('rename', this.newName);
    },
    handleDeleteClick() {
      this.$emit('delete');
    },
    handleCloseClick() {
      this.$emit('close');
      this.newName = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-audience-filter-modal {
  .edit-audience-filter-modal-body {
    padding: 20px 30px 30px;
  }
  .edit-audience-filter-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
