var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-card-container',{class:[
    'messaging-list-card',
    _vm.showAs,
    _vm.messagingList.filterGroupOid && 'dynamic',
    _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ],nativeOn:{"click":function($event){return _vm.handleContentSectionClick($event)}}},[_c('am2-message-list-settings-modal',{attrs:{"title":"List Settings","is-show":_vm.showMessageListSettingsModal,"message-list":_vm.messagingList},on:{"close":function($event){_vm.showMessageListSettingsModal = false},"update":_vm.handleMessageListSettingsConfirm}}),_vm._v(" "),_c('div',{staticClass:"basic-info-section"},[_c('div',{staticClass:"basic-info-wrapper"},[_c('am2-heading',{staticClass:"message-list-heading",attrs:{"type":"h1","size":"sm","title":_vm.messagingList.name}}),_vm._v(" "),_c('div',{staticClass:"u-display-flex u-align-items-center u-margin-top-3"},[_c('ar-icon',{attrs:{"width":"17px","name":"contacts-alt-two","color":_vm.$arStyle.color.blueGrey700}}),_vm._v(" "),_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.contactsCopyMap.tooltip,
          }),expression:"{\n            content: contactsCopyMap.tooltip,\n          }"}],staticClass:"u-margin-left-2",style:({
            color: _vm.$arStyle.color.blueGrey700,
          }),attrs:{"size":"xs","text":_vm.contactsCopyMap.text}})],1)],1),_vm._v(" "),_c('div',{staticClass:"funtion-section"},[(_vm.messagingList.filterGroupOid)?_c('am2-tag',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: 'Existing and future contacts that match your segment will automatically be added to this list',
        }),expression:"{\n          content: 'Existing and future contacts that match your segment will automatically be added to this list',\n        }",modifiers:{"top":true}}],staticClass:"tag",style:({ marginRight: '30px' }),attrs:{"text":"Dynamic","type":"purple"}}):_vm._e(),_vm._v(" "),_c('am2-icon-button-dropdown',{attrs:{"icon-props":{
          name: 'menu',
        },"items":_vm.actions,"dropdown-style":{
          width: '200px',
        },"dropdown-item-style":{
          height: '41px',
          padding: '0 20px',
        },"data-test-id":("message-list-card-dropdown-" + _vm.messageListNameInKebabCase)},on:{"select":_vm.handleActionSelect}})],1)]),_vm._v(" "),_c('div',{staticClass:"stats-section"},[_c('div',{staticClass:"stats-group"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.emailCopyMap.tooltip,
        }),expression:"{\n          content: emailCopyMap.tooltip,\n        }"}],staticClass:"stats"},[_c('ar-text',{attrs:{"text":_vm.emailCopyMap.text,"width":"18px","size":"sm"}}),_vm._v(" "),_c('ar-icon',{style:({
            marginLeft: '9px',
          }),attrs:{"name":"email","width":"18px","color":_vm.$arStyle.color.email}})],1)]),_vm._v(" "),_c('div',{staticClass:"stats-group"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.smsCopyMap.tooltip,
        }),expression:"{\n          content: smsCopyMap.tooltip,\n        }"}],staticClass:"stats"},[_c('ar-text',{attrs:{"text":_vm.smsCopyMap.text,"width":"18px","size":"sm"}}),_vm._v(" "),_c('ar-icon',{style:({
            marginLeft: '9px',
          }),attrs:{"name":"sms","width":"18px","color":_vm.$arStyle.color.sms}})],1)]),_vm._v(" "),_c('div',{staticClass:"stats-group"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.facebookMessengerCopyMap.tooltip,
        }),expression:"{\n          content: facebookMessengerCopyMap.tooltip,\n        }"}],staticClass:"stats"},[_c('ar-text',{attrs:{"text":_vm.facebookMessengerCopyMap.text,"width":"18px","size":"sm"}}),_vm._v(" "),_c('ar-icon',{style:({
            marginLeft: '9px',
          }),attrs:{"name":"messenger","width":"18px","color":_vm.$arStyle.color.messenger}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }