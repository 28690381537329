/**
 * FETCH_SAVED_SOCIAL_ACCOUNTS
 *
 * @param { object } context - Store context (state, rootstate, getters, commit, dispatch)
 * @param { number } campaignOid
 */

export async function FETCH_SAVED_SOCIAL_ACCOUNTS({ state }) {
  if (!state.auth.account) { return null; }
  const { promoterOid } = state.auth.account;

  try {
    const accounts = await this.$axios.get(`/promoter/${promoterOid}/social-accounts/`);

    return accounts.data;
  } catch (error) {
    // TODO: Notify client
  }
}
