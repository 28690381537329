<template>
  <section
    :class="[
      'customer-profile-overview',
      $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
    ]"
  >

    <am2-select-date-range-modal
      :is-show="showSelectDateRangeModal"
      @select="handleDateRangeSelect"
      @cancel="handleDateRangeCancel"
    />

    <div :class="[
      'left-side',
      $arMediaQuery.pageContent.only('xs') && 'xs-only',
      $arMediaQuery.pageContent.between('sm', 'md') && 'between-sm-md',
    ]">
      <am2-card-container layout="soft">
        <template>
          <am2-data-list
            class="data-list-container"
            title="Profile"
            :items="userData"
            :delete-data="deleteData"
            :save-data="saveData"
          />
        </template>
        <template>
          <ar-divider type="soft" />
          <am2-tags-list
            class="tags-list-container"
            :loading="!customerState.customer"
            :fan-oid="!!customerState.customer ? customerState.customer.oid : null"
            :input-styles="{
              height: '40px',
            }"
          />
        </template>
        <template v-if="arEnableFanActiveMembership">
          <ar-divider type="soft" />
          <am2-active-membership-list
            class="active-membership-list"
          />
        </template>
        <template>
          <ar-divider type="soft" />
          <am2-messaging-lists-list
            :class="[
              'messaging-lists-list',
              !arEnableFanActiveMembership && 'not-enable-fan-active-membership',
            ]"
            :fan-oid="!!customerState.customer ? customerState.customer.oid : null"
          />
        </template>
      </am2-card-container>
    </div>
    <div class="right-side">
      <am2-card-container
        ref="overviewContainer"
        :locked="!isCustomerProfileFeatureEnabled"
        v-ar-feature-mask="{
          show: !isCustomerProfileFeatureEnabled,
          showUpgradeOn: 'hover',
          iconName: 'audience',
          title: lockedTitle.overview,
          message: 'Unlock customer profiles to get an overview for each fan.',
        }"
        layout="soft"
        class="cell"
        :style="{
          position: 'relative',
          padding: '6px',
          paddingBottom: !isCustomerProfileFeatureEnabled ? '54px' : null,
        }"
      >
        <am2-overview-bar
          :values="overviewBarValues"
          title="Overview"
          layout="box-layout"
          :loading="isFetchingCustomer || isFetchingPurchases || isFetchingActivity"
          :dropdown-width="220"
          :dropdown="computedDateRangeFiltersOverview"
          :dropdown-index="salesDataOverviewIndex"
          :blur-values="!isCustomerProfileFeatureEnabled"
          :style="{
            paddingBottom: '6px',
          }"
          @select="(item) => this.setSalesDataFilter(item.key, 'overview')"
        >
        </am2-overview-bar>
      </am2-card-container>

      <am2-card-container
        layout="soft"
        v-ar-feature-mask="{
          show: !isCustomerProfileFeatureEnabled,
          showUpgradeOn: 'hover',
          iconName: 'audience',
          title: lockedTitle.salesHistory,
          message: 'Unlock customer profiles to see sales over time for each fan.',
        }"
        class="cell sales-chart">
        <div class="dropdown">
          <ar-simple-select
            :items="computedDateRangeFiltersChart.items"
            :default-select-index="salesDataChartIndex"
            :placeholder="$arMediaQuery.pageContent.maxWidth('xs') ? 'Date' : 'Date range'"
            :dropdown-width="$arMediaQuery.pageContent.minWidth('sm') ? 220 : 152"
            align="right"
            @select="(item) => this.setSalesDataFilter(item.key, 'chart')" />
        </div>

        <am2-multiple-column-charts
          ref="salesChart"
          v-if="salesChartData"
          hide-switch-buttons
          :title="$arMediaQuery.pageContent.maxWidth('xs') ? '' : 'Sales by'"
          :loading="isFetchingCustomer || !customer"
          :topics="salesChartData.topics"
          :selected-title-topic="salesDataChartSelectedTitleTopic"
          :title-dropdown-options="salesDataChartTitleOptions"
          :chart-datas="salesChartData.chartDatas"
          :chart-height="400"
          :topic-index="0"
          :locked="!isCustomerProfileFeatureEnabled"
          :style="{
            height: '400px',
            margin: '30px',
          }"
          @titleTopicChange="handleSalesChartTitleTopicChange"
        />

      </am2-card-container>
    </div>
  </section>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import { formatSalesData, aggregateSalesData, filledSalesData, dummySalesData } from '@/utils/audience';
  import accounting from 'accounting';
  import moment from 'moment'

  export default {
    name: 'CustomerProfileOverview',
    props: {
    },

    watch: {
      fanOid() {
        this.fetchMessageLists();
      },
    },

    data() {
      return {
        arEnableFanActiveMembership: process.env.arEnableFanActiveMembership,
        dummySalesData: dummySalesData(),

        selectedWidget: null,

        showSelectDateRangeModal: false,

        salesDataOverviewPeriod: 'all',
        salesDataOverviewIndex: 0,
        salesDataOverviewCustomStart: null,
        salesDataOverviewCustomEnd: null,

        salesDataChartPeriod: 'all',
        salesDataChartIndex: 0,
        salesDataChartCustomStart: null,
        salesDataChartCustomEnd: null,

        salesDataChartTitleOptions: [
          {name: 'Day', key: 'day'},
          {name: 'Month', key: 'month'},
          {name: 'Year', key: 'year'},
        ],
        salesDataChartSelectedTitleTopic: 'month',


        dateRangeFilters: {
          title: 'Timeframe',
          items: [
            {
              name: 'All time',
              key: 'all',
            },
            {
              name: 'Last 30 days',
              key: 1,
            },
            {
              name: 'Last 6 months',
              key: 6,
            },
            {
              name: 'Last 12 months',
              key: 12,
            },
            {
              name: 'Last 18 months',
              key: 18,
            },
            {
              name: 'Last 24 months',
              key: 24,
            },
            {
              name: 'Custom range',
              key: 'custom',
            },
          ],
        }
      }
    },

    watch: {
      customer: {
        handler(val, oldVal) {
          if (val && val.oid && !oldVal && !this.isFetchingPurchases && !this.isFetchingActivity) {
            this['customer/FETCH_CUSTOMER_ACTIVITY']({
              top: 'all',
              skip: 0,
              types: ['event-attendance', 'campaign-registration', 'event-purchase', 'ecommerce-purchase'],
            });
            this['customer/FETCH_CUSTOMER_PURCHASES']({
              top: 'all',
            })
            this['customer/FETCH_CUSTOMER_LOYALTY_MEMBERSHIPS'](this.$route.params.oid);
          }
        },
        immediate: true,
      }
    },

    computed: {
      ...mapState({
        customerState: state => state.customer,
        customer: state => state.customer.customer,
        isFetchingCustomer: state => state.customer.isFetchingCustomer,
        isFetchingActivity: state => state.customer.isFetchingActivity,
        isFetchingPurchases: state => state.customer.isFetchingPurchases,
      }),
      ...mapGetters({
        isFeatureEnabled: 'auth/isFeatureEnabled',
      }),

      lockedTitle() {
        return {
          "overview": this.customerState.customer?.firstName ? `${this.customerState.customer.name}'s Overview` : `Overview`,
          "salesHistory": this.customerState.customer?.firstName ? `${this.customerState.customer.name}'s Sales History` : `Sales History`,
        }
      },

      isCustomerProfileFeatureEnabled() {
        return this.isFeatureEnabled(['audience', 'customerProfiles']);
      },

      computedDateRangeFiltersOverview() {
        if (this.salesDataOverviewPeriod === 'custom') {
          return {
            title: 'Timeframe',
            items: this.dateRangeFilters.items.map( item => {
              if (item.key !== 'custom') return item;
              if (!this.salesDataOverviewCustomStart || !this.salesDataOverviewCustomEnd) return item;
              const startDate = moment(this.salesDataOverviewCustomStart).format('DD MMM YYYY');
              const endDate = moment(this.salesDataOverviewCustomEnd).format('DD MMM YYYY');
              return {
                name: `Custom range (${startDate} - ${endDate})`,
                key: 'custom',
              }
            })
          }
        }
        return this.dateRangeFilters;
      },
      computedDateRangeFiltersChart() {
        if (this.salesDataChartPeriod === 'custom') {
          return {
            title: 'Timeframe',
            items: this.dateRangeFilters.items.map( item => {
              if (item.key !== 'custom') return item;
              if (!this.salesDataChartCustomStart || !this.salesDataChartCustomEnd) return item;
              const startDate = moment(this.salesDataChartCustomStart).format('DD MMM YYYY');
              const endDate = moment(this.salesDataChartCustomEnd).format('DD MMM YYYY');
              return {
                name: `Custom range (${startDate} - ${endDate})`,
                key: 'custom',
              }
            })
          }
        }
        return this.dateRangeFilters;
      },

      overviewBarValues() {
        let timePeriod = this.dateRangeFilters.items.find( item => item.key === this.salesDataOverviewPeriod);
        let timePeriodString = '';
        if (!timePeriod || timePeriod.key === 'all') {
          timePeriodString = '.';
        } else if (timePeriod.key === 'custom') {
          const startDate = moment(this.salesDataOverviewCustomStart).format('DD MMM YYYY');
          const endDate = moment(this.salesDataOverviewCustomEnd).format('DD MMM YYYY');
          timePeriodString = `, between ${startDate} and ${endDate}`
        } else {
          timePeriodString = `, in the ${timePeriod.name.toLowerCase()}.`;
        }

        const purchaseWord = this.salesOverviewData.totalPurchases === 1 ? 'purchase' : 'purchases';
        const eventWord = this.salesOverviewData.totalEvents === 1 ? 'event' : 'events';
        const campaignWord = this.salesOverviewData.totalCampaigns === 1 ? 'campaign' : 'campaigns';


        return [
          {
            label: 'Sales',
            type: 'CURRENCY',
            showDecimal: false,
            amount: this.salesOverviewData && this.salesOverviewData.totalTicketSales,
            tooltip: `${this.customerState?.customer?.name} has spent $${accounting.formatNumber(Math.floor(this.salesOverviewData.totalTicketSales / 100))} with you${timePeriodString}`,
            clickAction: this.handlePurchasesClick,
          },
          {
            label: 'Purchases',
            type: 'NUMBER',
            amount: this.salesOverviewData && this.salesOverviewData.totalPurchases,
            tooltip: `${this.customerState?.customer?.name} has made ${accounting.formatNumber(this.salesOverviewData.totalPurchases)} ${purchaseWord} with you${timePeriodString}`,
            clickAction: this.handlePurchasesClick,
          },
          {
            label: 'Events',
            type: 'NUMBER',
            amount: this.salesOverviewData && this.salesOverviewData.totalEvents,
            tooltip: `${this.customerState?.customer?.name} has attended ${accounting.formatNumber(this.salesOverviewData.totalEvents)} ${eventWord}${timePeriodString}`,
            clickAction: this.handleEventsClick,
          },
          {
            label: 'Campaigns',
            type: 'NUMBER',
            amount: this.salesOverviewData && this.salesOverviewData.totalCampaigns,
            tooltip: `${this.customerState?.customer?.name} has participated in ${accounting.formatNumber(this.salesOverviewData.totalCampaigns)} ${campaignWord}${timePeriodString}`,
            clickAction: this.handleCampaignsClick,
          },
        ];
      },

      userData() {
        // Format's customer data into a more editable format
        // We'll then be passing this in to our dataList

        return [
          {
            icon: 'user-circle',
            title: 'Name',
            modifiable: true,
            outputAs: 'String',
            inputs: [{
              type: 'String',
              name: 'firstName',
              placeholder: 'First Name',
              savedData: this.customerState?.customer?.firstName
            },{
              type: 'String',
              name: 'lastName',
              placeholder: 'Last Name',
              savedData: this.customerState?.customer?.lastName
            }],
          },
          {
            icon: 'email-inverted',
            title: 'Email',
            modifiable: false,
            multipleLines: false,
            outputAs: 'String',
            inputs: [{
              type: 'String',
              name: 'emailAddress',
              placeholder: 'Email Address',
              savedData: this.customerState?.customer?.emailAddress
            }],
          },
          {
            icon: 'gift',
            title: 'Date of Birth',
            modifiable: true,
            sameLine: true,
            outputAs: 'DateOfBirth',
            inputs: [
              {
                type: 'Date',
                name: 'dob',
                savedData: this.customerState?.customer?.dob ?
                  this.customerState.customer.dob.substring(0, 10) : '',
              },
            ],
          },
          {
            icon: 'user-alt',
            title: 'Gender',
            buttonText: this.customerState?.customer?.gender,
            modifiable: true,
            outputAs: 'Select',
            inputs: [{
              type: 'Gender',
              name: 'gender',
              placeholder: 'Select One',
              savedData: this.customerState?.customer?.gender,
              options: [
                {
                  name: 'Male',
                  key: 'Male',
                },
                {
                  name: 'Female',
                  key: 'Female',
                },
                {
                  name: 'Other',
                  key: 'Other',
                },
              ]
            }],
          },
          {
            icon: 'phone',
            title: 'Mobile Number',
            modifiable: true,
            outputAs: 'Phone',
            inputs: [{
              type: 'Mobile',
              name: 'mobileNumber',
              savedData: this.customerState?.customer?.mobileNumber,
            }],
          },
          {
            icon: 'location',
            title: 'Location',
            modifiable: true,
            outputAs: 'Location',
            inputs: [{
              type: 'String',
              name: 'streetAddress',
              placeholder: 'Street address',
              savedData: this.customerState?.customer?.streetAddress,
            },{
              type: 'String',
              name: 'city',
              placeholder: 'City',
              savedData: this.customerState?.customer?.city,
            },
            {
              type: 'Country',
              name: 'country',
              placeholder: 'Country',
              savedData: this.customerState?.customer?.country,
            },
            {
              type: 'State',
              name: 'state',
              placeholder: 'State/province',
              countryIso: this.customerState?.customer?.country,
              savedData: this.customerState?.customer?.state,
            },{
              type: 'String',
              name: 'postcode',
              placeholder: 'Zip/post code',
              savedData: this.customerState?.customer?.postcode,
            },],
          },
        ];
      },


      // Formats the sales data into a multidimensional array, broken down by YYYY -> MM
      formattedSalesData() {
        if (!this.customerState.customer) return {};
        if (!this.isCustomerProfileFeatureEnabled) return this.dummySalesData;

        return formatSalesData(this.customerState.fanActivity, this.customerState.fanPurchases);
      },


      // Organises the sales data into a format which can be processed by the Sales Overview component
      salesOverviewData() {
        const timePeriod = {
          customStart: this.salesDataOverviewCustomStart,
          customEnd: this.salesDataOverviewCustomEnd,
          period: this.salesDataOverviewPeriod,
        };

        return aggregateSalesData(this.formattedSalesData, timePeriod);
      },

      salesChartData() {
        const timePeriod = {
          customStart: this.salesDataChartCustomStart,
          customEnd: this.salesDataChartCustomEnd,
          period: this.salesDataChartPeriod,
        };

        const chartData = filledSalesData(this.formattedSalesData, timePeriod, this.salesDataChartSelectedTitleTopic);

        return {
          topics: [
            {
              name: 'Sales',
              valueGetters:[{
                name: 'Sales',
                getter: ({ prettyName, moneyValue }) => [prettyName, moneyValue],
              }],
              format: '${value}',
              hasYLabel: true,
              yAxisMinRange: 100, // TODO - Change
              xAxisRotation: chartData.length < 10 ? 0 : -50,
              hasDataLabels: false,
              hasTooltip: true,
              scrollableMinWidthEnabled: false,
              toolkitValueFormatter(data) {
                return `$${accounting.formatNumber(data.moneyValue)}`;
              },
              toolkitLabelFormatter(data) {
                return `Sales for ${data.longName}`;
              },
              xAxisLabelFormatter(index, chartData) {
                if (!chartData) return '';
                return chartData.prettyName;
              },
              colors: [
                this.$arStyle.color.green500,
                this.$arStyle.color.green500,
              ],
            }
          ],
          chartDatas: chartData,
        };
      },
    },


    mounted() {
      window.scrollTo(0, 0);
    },

    methods: {
      ...mapActions([
        'customer/FETCH_CUSTOMER_ACTIVITY',
        'customer/FETCH_CUSTOMER_PURCHASES',
        'customer/FETCH_CUSTOMER_LOYALTY_MEMBERSHIPS',
      ]),

      // Actions a delete from the dataList component
      deleteData(item) {
        if(item.title === "Date of Birth") {
          this.$emit('fan-data-change', {
            'dob': null
          });
        } else {
          const changes = {};
          item.inputs.forEach( (inputItem, key) => {
            const keyName = inputItem.name;
            changes[keyName] = null;
          });
          this.$emit('fan-data-change', changes);
        }
      },

      // Actions a save click from the dataList component
      saveData(item, temporaryValues) {
        if(item.title === "Date of Birth") {
          const changes = {
            dob: temporaryValues.dob ? `${temporaryValues.dob}T00:00:00Z` : null,
          };
          this.$emit('fan-data-change', changes);
        } else {
          const changes = {};
          item.inputs.forEach( (inputItem, key) => {
            const keyName = inputItem.name;
            const newValue = temporaryValues[keyName];
            changes[keyName] = newValue;
          });
          this.$emit('fan-data-change', changes);
        }
      },

      handleDateRangeCancel() {
        this.showSelectDateRangeModal = false;
        this.setSalesDataFilter('all', this.selectedWidget);
      },

      handleDateRangeSelect({ startDate, endDate }) {
        this.showSelectDateRangeModal = false;
        if (this.selectedWidget === 'chart') {
          this.salesDataChartCustomStart = startDate;
          this.salesDataChartCustomEnd = endDate;
        } else {
          this.salesDataOverviewCustomStart = startDate;
          this.salesDataOverviewCustomEnd = endDate;
        }
      },

      async setSalesDataFilter(newPeriod, widget) {
        this.selectedWidget = widget;
        if (newPeriod === 'custom') {
          this.showSelectDateRangeModal = true;
        }

        // Assuming we want to have the overview and sales charts date select drop-downs work separately
        const newSelectIndex = this.dateRangeFilters.items.findIndex( item => item.key === newPeriod);
        if (this.selectedWidget === 'chart') {
          this.salesDataChartPeriod = newPeriod;
          this.salesDataChartIndex = newSelectIndex;
        } else {
          this.salesDataOverviewPeriod = newPeriod;
          this.salesDataOverviewIndex = newSelectIndex;
        }
      },

      // When changing the title topic, we need to flush the chartPeriod and reflow the chart. Otherwise the chart shows incorrect data
      // TODO - Figure out why this happens and implement a more elegant fix
      handleSalesChartTitleTopicChange(val) {
        if (!val || !val.key) return;
        this.salesDataChartSelectedTitleTopic = val.key;
      },

      handlePurchasesClick() {
        this.$router.push({
          path: `/audience/${this.$route.params.oid}/view/purchases`,
        });
      },

      handleEventsClick() {
        this.$router.push({
          path: `/audience/${this.$route.params.oid}/view/events`,
        });
      },

      handleCampaignsClick() {
        this.$router.push({
          path: `/audience/${this.$route.params.oid}/view/campaigns`,
        });
      },
    }
  };
</script>

<style lang="scss" scoped>
  .customer-profile-overview {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    .left-side {
      grid-column: 1;
      grid-row: 1;
      max-width: 370px;

      &.xs-only {
        max-width: none;
      }

      &.between-sm-md {
        max-width: none;
        .ar-card-container {
          display:grid;
          grid-template-columns: repeat(2, 1fr);
          .divider {
            display: none;
          }
          .data-list-container {
            grid-row: 1;
          }
          .tags-list-container {
            grid-row: 1;
            border-left: 1px solid $skyBlueGrey500;
          }
          .active-membership-list {
            grid-row: 2;
            border-top: 1px solid $skyBlueGrey500;
          }
          .messaging-lists-list {
            grid-row: 2;
            border-top: 1px solid $skyBlueGrey500;
            border-left: 1px solid $skyBlueGrey500;

            &.not-enable-fan-active-membership {
              grid-row: 2 / 3;
              grid-column: 1 / 3;
            }
          }
        }
      }
    }

    .right-side {
      grid-column: 2 / 4;
      grid-row: 1;

      .cell {
        margin-bottom: 20px;
      }
    }

    &.md-max {
      display: flex;
      flex-direction: column;
      padding: 12px;

      .left-side {
        margin-bottom: 20px;
      }
    }

    .sales-chart {
      position:relative;
      padding-top: 16px;
      .dropdown {
        position: absolute;
        right: 30px;
        top: 30px;
      }
    }
  }


</style>
