var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"message-list-layout",style:({
    padding: _vm.$arMediaQuery.pageContent.maxWidth('sm') ? '30px 18px' : '47px 59px',
  })},[(_vm.currentMessageList)?_c('am2-message-list-settings-modal',{attrs:{"title":"List Settings","is-show":_vm.showMessageListSettingsModal,"message-list":_vm.currentMessageList},on:{"close":function($event){_vm.showMessageListSettingsModal = false},"update":_vm.handleMessageListSettingsConfirm}}):_vm._e(),_vm._v(" "),_c('am2-step-link',{attrs:{"text":"Back to Lists"},on:{"click":_vm.handleBackLinkClick}}),_vm._v(" "),_c('div',{class:[
      'message-list-top-wrapper',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[_c('div',{class:[
      'message-list-top-left-section',
      _vm.$arMediaQuery.pageContent.minWidth('sm') && 'sm-min' ]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showMessageListNameEdit),expression:"!showMessageListNameEdit"}],staticClass:"title-section"},[_c('am2-heading',{staticClass:"title",attrs:{"type":"h1","size":"lg","title":_vm.messageListName},nativeOn:{"click":function($event){return _vm.handleEditNameInputOpen($event)}}}),_vm._v(" "),_c('ar-icon',{staticClass:"icon u-margin-left-10",attrs:{"name":"edit"},on:{"click":_vm.handleEditNameInputOpen}}),_vm._v(" "),(_vm.isAutoMessageList)?_c('am2-tag',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: 'Existing and future contacts that match your segment will automatically be added to this list',
          }),expression:"{\n            content: 'Existing and future contacts that match your segment will automatically be added to this list',\n          }",modifiers:{"top":true}}],staticClass:"tag",attrs:{"text":"Dynamic","type":"purple"}}):_vm._e()],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMessageListNameEdit),expression:"showMessageListNameEdit"}],staticClass:"edit-section"},[_c('div',{staticClass:"edit-section-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.messageListNameEdit),expression:"messageListNameEdit"}],ref:"edit-name-input",staticClass:"edit-name-input",attrs:{"data-vv-name":"messageListNameEdit","input-font-size":"lg"},domProps:{"value":(_vm.messageListNameEdit)},on:{"blur":_vm.handleEditNameInputBlur,"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.handleEditNameInputBlur($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEditNameInputEnter($event)}],"input":function($event){if($event.target.composing){ return; }_vm.messageListNameEdit=$event.target.value}}}),_vm._v(" "),_c('ar-text',{staticClass:"edit-name-input-done",attrs:{"size":"sm","text":"<a>Done</a>","allow-html":""},on:{"anchorMouseDown":_vm.handleEditNameInputEnter}})],1)])]),_vm._v(" "),_c('div',{staticClass:"message-list-top-right-section"},[(_vm.showNewMessageButton)?_c('am2-split-dropdown',{staticClass:"u-margin-left-2",style:({
          minWidth: '195px',
        }),attrs:{"items":_vm.newMessageButtonOptions,"text":"New Message","icon-name":"dialog","icon-distance":"12px","toggle-dropdown-on-button-click":""},on:{"select":function (item) { return item.action(); },"buttonClick":function () {null}}}):_vm._e(),_vm._v(" "),(_vm.currentMessageList)?_c('am2-expand-icon-button',{staticClass:"u-margin-left-2",attrs:{"icon-name":"settings"},on:{"click":_vm.handleSettingsClick}}):_vm._e()],1)]),_vm._v(" "),(_vm.noFacebookIntegration)?_c('ar-snackbar',{style:({ marginTop: '10px' }),attrs:{"type":"error","message":"Integration error - <a>please reconnect your Facebook account</a>"},on:{"anchorClick":_vm.handleIntegrationClick}}):_vm._e(),_vm._v(" "),_c('ar-divider',{style:({
      marginTop: '22px',
      marginBottom: '22px',
    })}),_vm._v(" "),(_vm.isFetchingMessageList)?_c('div',{staticClass:"loading-section"},[_c('am2-loading-bubble')],1):(_vm.currentMessageList)?_c('nuxt-child',{on:{"contactsCountUpdate":_vm.handleContactsCountUpdate}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }