var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-control-section"},[_c('div',{staticClass:"table-control-wrapper"},[_c('div',{staticClass:"table-control-container"},[_vm._t("tablecontrol",[_c('am2-search',{staticClass:"item fans-search",style:({
            height: '40px',
          }),attrs:{"fan-appellation":"recipient","placeholder":_vm.searchPlaceholder},on:{"enter":_vm.handleSearch,"clear":_vm.handleSearchClear},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_vm._v(" "),_c('am2-simple-button-dropdown',{staticClass:"item dropdown-button",attrs:{"button-props":{
              type: 'grey',
              outlined: true,
              style: {
                height: '40px',
                width: '100%'
              },
              iconName: 'ellipsis',
              iconDistance: '0px',
              iconProps: {
                height: '4px',
                width: '18px',
                color: _vm.$arStyle.color.skyBlueGrey800,
              },
              customStyleAttributes: {
                padding: '0 10px',
              },
            },"items":_vm.dropdownItems,"dropdown-max-height":_vm.$arMediaQuery.window.maxWidth('xs') ? '50vh' : 'calc(100vh - 207px)',"dropdown-style":{
              width: '180px',
            }},on:{"select":_vm.handleDropdownItemClick}})])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }