import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { isErrorStatus } from '../utils';
import { CustomApiError } from '../types';
import { ExternalApiSetting } from './types';

export default (axios: NuxtAxiosInstance) => ({
  async fetchSmsMessagePreviewWithoutStats(
    promoterOid: number,
    message: string | null,
  ): Promise<{ message: string, charactersLeft: number, optOutMessage: string }> {
    const { data, status }: { data: SMSMessagePreview, status: number } = await axios.get(`/promoter/${promoterOid}/message-preview/`, {
      params: {
        $provider: 'sms',
        $body: message || '',
        $cost: false,
      },
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Fetch SMS message preview',
        message: `Error fetching SMS message preview`,
        status,
      };
      throw apiError;
    }

    return {
      message: data.body,
      charactersLeft: data.charactersLeft,
      optOutMessage: data.optOutMessage,
    };
  },
  /**
   * Fetch default dynamic tags that you can use in email setup.
   * @param promoterOid 
   * @returns 
   */
  async fetchDefaultDynamicTags(
    promoterOid: number,
  ): Promise<string[]> {
    const { data } = await axios.get(`/promoter/${promoterOid}/external-api-settings`);
    const externalApiSetting: ExternalApiSetting = data[0];
    if (!!externalApiSetting) {
      return externalApiSetting.defaultDynamicTags;
    } else {
      return [];
    }
  },
});
