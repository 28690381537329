<template>
  <ar-select
    class="select-wrapper"
    :items="selectableOptions"
    :default-select-index="selectedIntegrationIndex"
    :hide-arrow="false"
    :dropdown-width="300"
    @select="handleSelect"
    @actionClick="handleNewAccount"
  >
    <template slot="selected-item" slot-scope="selectedItemScope">
      <div
        :class="[
          'u-display-flex',
          'u-align-items-center',
          'u-padding-x-4',
        ]"
      >
        <ar-avatar
          :image-path="selectedItemScope.item.imageSrc"
          :name="selectedItemScope.item.name"
          size="30px"
        />
        <ar-text
          :class="[
            'u-margin-left-2',
          ]"
          :text="selectedItemScope.item.name"
          size="xs"
        />
      </div>
    </template>
    <template slot="item" slot-scope="itemScope">
      <div
        v-if="itemScope.item.isAction"
        :class="[
          'u-display-flex',
          'u-align-items-center',
          'u-padding-x-4',
        ]"
        :style="{
          cursor: 'pointer',
          height: '50px',
          background: itemScope.active ? $arStyle.color.purple100 : null,
        }"
      >
        <div
          :class="[
            'u-display-flex',
            'u-justify-content-center',
          ]"
          :style="{
            width: '30px',
          }"
        >
          <ar-icon
            :name="itemScope.item.iconName"
            :style="{
              color: $arStyle.color.purple500,
            }"
          />
        </div>
        <ar-text
          :class="[
            'u-margin-left-2',
          ]"
          :text="itemScope.item.name"
          size="xs"
          weight='bold'
          :style="{
            color: $arStyle.color.purple500,
            lineHeight: 1,
          }"
        />
      </div>
      <div
        v-else
        :class="[
          'u-display-flex',
          'u-align-items-center',
          'u-padding-x-4',
        ]"
        :style="{
          cursor: 'pointer',
          height: '50px',
          background: itemScope.active ? $arStyle.color.purple100 : null,
        }"
      >
        <ar-avatar
          :image-path="itemScope.item.imageSrc"
          :name="itemScope.item.name"
          size="30px"
          :color="itemScope.active ? 'white' : 'grey'"
        />
        <ar-text
          :class="[
            'u-margin-left-2',
          ]"
          :text="itemScope.item.email"
          size="xs"
          :style="{
            color: itemScope.active ? $arStyle.color.purple500 : null,
            maxWidth: '240px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }"
        />
      </div>
    </template>
    <template slot="no-item">
      <div
        :class="[
          'u-display-flex',
          'u-align-items-center',
          'u-padding-x-4',
          'u-padding-y-2',
        ]"
      >
        <ar-text
          size="xs"
          text="No accounts"
          :style="{
            color: $arStyle.color.$blueGrey800,
          }"
        />
      </div>
    </template>
  </ar-select>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'IntegrationAccountSelect',
  props: {
    defaultSelectedIntegrationOid: {
      type: Number,
      default: null,
    },
    availableIntegrations: {
      type: Array,
      default: null,
    }
  },
  data() {
    const initialIndex = this.availableIntegrations.findIndex(i =>
      i.oid === this.defaultSelectedIntegrationOid
    );
    return {
      selectedIntegrationOid: this.defaultSelectedIntegrationOid,
      selectedIntegrationIndex: initialIndex + 1,
    };
  },
  watch: {
    defaultSelectedIntegrationOid(val) {
      this.selectedIntegrationOid = val;
      this.calculateSelectedIndex();
    },
  },
  computed: {
    selectableOptions() {
      return [{
        isAction: true,
        iconName: 'circle-plus',
        name: 'Add new account',
        key: 'ADD_NEW_ACCOUNT',
      },...this.availableIntegrations];
    }
  },
  methods: {
    calculateSelectedIndex() {
      this.selectedIntegrationIndex = this.availableIntegrations.findIndex(integrations => integrations.oid === this.selectedIntegrationOid) + 1;
    },
    handleNewAccount(action) {
      if (action.key === 'ADD_NEW_ACCOUNT') {
        this.$emit('new-account');
      }
    },
    handleSelect(integration, idx) {
      if (idx > 0) {
        this.selectedIntegrationOid = integration.oid;
        this.$emit('select', integration, idx - 1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select-wrapper {
  width: 300px;
}
</style>
