<template>
  <div class="name-input-wrapper">
    <ar-text
      v-if="!inputActive"
      class="automation-name-heading"
      size="24px"
      :text="localName"
      weight="normal"
      multiple-lines
      :max-lines="1"
      line-height="40px"
    />
    <ar-input
      v-else
      ref="createNameInput"
      class="create-auto-name-input"
      :style="{
        height: '40px'
      }"
      :value="localName"
      @enter="handleCreate"
      @blur="handleCreate"
      @input="handleNameInputChange"
      auto-focus
    />
    <PencilIcon
      class="pencil-icon"
      @click="onEditClick"
    />
  </div>
</template>
<script>
import PencilIcon from '~/assets/icons/pencil-icon.svg?inline'
import { mapActions } from 'vuex'
export default {
  name: 'AutomationName',
  components: {
    PencilIcon
  },
  props: {
    name: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      inputActive: false,
      createdName: null,
      localName: ''
    }
  },
  watch: {
    name() {
      this.localName = this.name
      this.inputActive = false
    }
  },
  methods: {
    ...mapActions({
      newAutomation: 'automation/NEW_AUTOMATION',
      renameAutomation: 'automation/RENAME_AUTOMATION'
    }),
    async handleCreate() {
      if (this.localName === this.name) {
        this.inputActive = false
        return
      }
      let newName = !!this.localName ? this.localName : 'Untitled automation'
      this.$emit('update', newName)
      this.inputActive = false
    },
    onEditClick() {
      this.inputActive = true
    },
    handleNameInputChange(value) {
      this.localName = value
    }
  },  
  created() {
    if (this.$route.path === '/automation/create') {
      this.inputActive = true
    }
  }
}
</script>
<style lang="scss" scoped>
.name-input-wrapper {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  min-height: 38px;

  .automation-name-heading {
    cursor: default;
    max-width: 90%;
  }

  .pencil-icon {
    margin-left: 16px;
    opacity: 0.3;
    cursor: pointer;
  }
}
</style>