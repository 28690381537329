var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-subscriptions"},[_c('div',[_c('div',{staticClass:"first-title-section"},[_c('ar-text',{attrs:{"size":"md","text":"Your Plan"}})],1),_vm._v(" "),(_vm.displayPlanCardSkeletons)?_c('am2-card-skeleton',{staticClass:"base-plan-card",style:({
        height: '122px',
      }),attrs:{"show-as":_vm.$arMediaQuery.pageContent.maxWidth('sm') ? 'tiles' : 'list'}}):(_vm.advancedPaymentPlanSubscriptionInformation.basePaymentSubscription)?_vm._l((_vm.advancedPaymentPlanSubscriptionInformation.basePaymentPlans),function(basePlan){return _c('div',{key:basePlan.puid,staticClass:"base-plan-card"},[(basePlan.paymentSubscriptions[0])?_c('am2-base-plan-card',{attrs:{"show-as":_vm.$arMediaQuery.pageContent.maxWidth('sm') ? 'tiles' : 'list',"payment-plan":basePlan,"payment-subscription":basePlan.paymentSubscriptions[0],"add-on-subscriptions":_vm.advancedPaymentPlanSubscriptionInformation.addOnPaymentSubscriptions,"primary-payment-source":_vm.primaryPaymentSource},on:{"subscriptionStateUpdate":_vm.handleSubscriptionStateUpdate}}):_vm._e()],1)}):_c('div',{staticClass:"base-plan-card"},[_c('am2-base-plan-card',{attrs:{"base-plan":null,"show-as":_vm.$arMediaQuery.pageContent.maxWidth('sm') ? 'tiles' : 'list'}})],1),_vm._v(" "),_c('div',{staticClass:"second-title-section"},[_c('ar-text',{attrs:{"size":"md","text":"Additional options"}})],1),_vm._v(" "),(_vm.displayPlanCardSkeletons)?_c('div',{staticClass:"add-on-cards-section"},_vm._l((5),function(n){return _c('am2-card-skeleton',{key:n,class:[
          'add-on-card',
          _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'full-width' ],style:({
          height: '309px',
        }),attrs:{"show-as":"tiles","level":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? 2 * n : 2 * Math.ceil(n / 2)}})}),1):_c('div',{staticClass:"add-on-cards-section"},_vm._l((_vm.advancedPaymentPlanSubscriptionInformation.addOnPaymentPlans),function(addOnPlan,idx){return _c('am2-add-on-plan-card',{key:idx,class:[
          'add-on-card',
          _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'full-width' ],attrs:{"payment-plan":addOnPlan,"primary-payment-source":_vm.primaryPaymentSource,"base-subscription":_vm.advancedPaymentPlanSubscriptionInformation.basePaymentSubscription,"size":_vm.$arMediaQuery.pageContent.maxWidth('sm') ? 'small' : 'regular'},on:{"subscriptionStateUpdate":_vm.handleSubscriptionStateUpdate}})}),1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }