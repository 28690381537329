<template>
  <div class="events-dropdown-wrapper">
    <div class="events-dropdown-button" :class="{ active: eventsOpen }" @click="toggleOptions" data-test-id="editable-search-for-event-toggle">
      <input
        class="search-events-input"
        ref="searchEventsInput"
        placeholder="Search for an event..."
        v-model="eventInputText"
      />
      <ArrowHead class="search-for-event-dropdown-arrowhead" height="8px" width="10px" fill="#8492a6" />
    </div>

    <div v-if="eventsOpen" class="event-dropdown-options-wrapper">
      <template v-for="(option, index) in dropdownOptions">
        <div class="event-dropdown-option" :key="index" @click="handleSelectedEvent(option)">
          <ar-text
            class="option-name"
            :class="{ selected: isSelected(option) }"
            size="14px"
            :text="option.name"
            weight="normal"
            multiple-lines
            :max-lines="1"
            line-height="20px"
          />
          <ar-text
            class="option-dateString"
            size="14px"
            :text="option['date-string']"
            weight="normal"
            multiple-lines
            :max-lines="1"
            line-height="20px"
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import ArrowHead from '~/assets/icons/arrow-head.svg?inline'
import { mapActions } from 'vuex'
import { displayDateRangeUSNoDay } from '@/utils/date/'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)
export default {
  name: 'EventsDropdown',
  components: {
    ArrowHead,
  },
  props: {
    selectedEvent: {
      type: Object,
      default: null,
    },
    inputText: {
      type: String,
      default: null,
    }
  },
  model: {
    prop: 'selectedEvent',
    event: 'selection',
  },
  data() {
    return {
      eventsOpen: false,
      initialDropdownOptions: null,
      dropdownOptions: null,
    }
  },
  computed: {
    eventInputText: {
      get: function() {
        return this.inputText
      },
      set: function(val) {
        this.$emit('input-text-update', val)
      }
    }
  },
  watch: {
    eventsOpen() {
      !this.eventsOpen && this.$refs.searchEventsInput.blur()
    },
  },
  methods: {
    ...mapActions({
      fetchEvents: 'FETCH_EVENTS',
    }),
    handleSelectedEvent(option) {
      this.$emit('selection', option)
      this.eventsOpen = false
    },
    toggleOptions() {
      this.eventsOpen = !this.eventsOpen
    },
    isSelected(option) {
      if (!this.selectedEvent) return false
      
      return option.name === this.selectedEvent.name ? true : false
    },
    async fetchDropdownEvents() {
      let { rows } = await this.fetchEvents({ selectString: 'name,startDate' })

      let localTimezone = dayjs.tz.guess()
      
      let dropdownOptions = rows.map(item => {
        return {
          oid: item.oid,
          name: item.name,
          'date-string': displayDateRangeUSNoDay(dayjs(item.startDate).tz(localTimezone).valueOf(), null, localTimezone),
        }
      })

      this.initialDropdownOptions = dropdownOptions
      this.dropdownOptions = dropdownOptions
    },
  },
  mounted() {
    this.fetchDropdownEvents()
  }
}
</script>
<style lang="scss" scoped>
.events-dropdown-wrapper {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  position: relative;

  .events-dropdown-button {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: solid 1px $blueGrey500;
    background-color: #fff;
    height: 48px;
    padding-bottom: 3px;
    padding-left: 14px;
    padding-right: 16px;
    margin-bottom: 4px;;
    cursor: pointer;

    &.active {
      border: 1px solid $green500;
      box-shadow: 0 0 0 3px $green200;

      .search-for-event-dropdown-arrowhead {
        transform: rotate(180deg);
      }
    }

    .search-events-input {
      border: none;
      outline: none;
      cursor: pointer;
      height: 35px;
      width: 258px;
      font-family: Graphik;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.43;
      color: $blueGrey800;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      ::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: $blueGrey600;
      }
      :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: $blueGrey600;
      }
      ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: $blueGrey600;
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $blueGrey600;
      }
      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $blueGrey600;
      }
    }
  }

  .event-dropdown-options-wrapper {
    width: 100%;
    padding: 9px 0 18px 0;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 $shadow;
    border: solid 1px #d4d8e1;
    background-color: #fff;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    max-height: 202px;
    overflow: scroll;
    position: absolute;
    top: 54px;
    z-index: 10;

    .event-dropdown-option {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      width: 100%;
      padding: 10px 16px;
      cursor: pointer;

      &:hover {
        background-color: #f2edff;

        .option-name {
          color: $purple500;          
        }
      }

      .option-name {
        color: $blueGrey800;
        max-width: 275px;

        &.selected {
          color: $purple500;
        }
      }

      .option-dateString {
        color: $blueGrey700;
      }
    }
  }
}
</style>