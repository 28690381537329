<template>
  <section class="renderer-frame">
    <div v-if="!isReadonly" class="renderer-frame-right-top-section">
      <ar-icon
        name="cross"
        height="12px"
        class="renderer-frame-right-top-section-icon"
        data-test-id="filter-close-button"
        @click.native="handleCancelClick"
      />
    </div>
    <div v-else class="renderer-frame-right-top-section">
      <am2-icon-button-dropdown
        :class="{
          'renderer-frame-dropdown-menu': true,
          open: isMenuOpen,
        }"
        :icon-props="{
          name: 'menu',
        }"
        :items="actions"
        :style="{ marginBottom: '10px' }"
        @select="handleActionSelect"
        @toggle="handleMenuIconToggle"
      />
    </div>
    <div
      :class="{
        'renderer-frame-content-wrapper': true,
        'is-readonly': isReadonly,
      }"
      @click="openChangeMode"
    >
      <div :class="['renderer-frame-title-section', isReadonly && title.length > 20 && 'vertical-aligned']">
        <am2-heading
          :class="['renderer-frame-title', isReadonly && 'shrink']"
          type="legend"
          size="xs"
          :title="title"
          weight="bold"
        />
        <ar-icon
          v-if="filterTooltip"
          name="tooltip-question-mark"
          :color="$arStyle.color.skyBlueGrey600"
          width="16px"
          v-tooltip.top="{
              content: filterTooltip,
            }"
          :style="{
              marginLeft: '10px',
            }"
        />
        <ar-text
          v-if="isReadonly && !!subtitle"
          class="renderer-frame-subtitle"
          :text="subtitle"
          size="xs"
        />
      </div>
      <div class="renderer-frame-content">
        <div v-if="loading" class="loading-bubble-wrapper">
          <am2-loading-bubble />
        </div>
        <slot v-else-if="!isReadonly" name="content" />
        <div v-else>
          <div v-if="predicateAsValue">
            <div class="renderer-frame-answer-section">
              <ar-icon
                name="tree-branch"
                width="7px"
                height="11px"
                class="renderer-frame-branch-icon"
              />
              <div class="renderer-frame-answer">
                <span :class="['renderer-frame-answer-title']">{{predicateAsValue}}</span>
              </div>
            </div>
          </div>
          <div v-else-if="!hasNoValue">
            <div
              v-for="(readonlyItem, index) of readonlyItems"
              :key="index"
              class="renderer-frame-answer-section"
            >
              <ar-icon
                name="tree-branch"
                width="7px"
                height="11px"
                class="renderer-frame-branch-icon"
              />
              <div
                class="renderer-frame-answer"
              >
                <span :class="['renderer-frame-answer-title', !readonlyItem.name && 'no-item']">{{ readonlyItem.name || 'Has been removed' }}</span>
                <span v-if="readonlyItem.date" class="renderer-frame-answer-date">
                  {{ readonlyItem.date }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-else
            class="renderer-frame-answer-section"
          >
            <ar-icon
              name="tree-branch"
              width="7px"
              height="11px"
              class="renderer-frame-branch-icon"
            />
            <div class="renderer-frame-answer">
              <span class="renderer-frame-answer-title error">
                Please complete the filter
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isReadonly && !loading" class="renderer-frame-buttons-wrapper">
        <ar-simple-button
          v-if="!hideButton"
          :text="conditionButtonText"
          :disabled="applyDisabled"
          @click="handleConfirmClick"
          :style="{ height: '40px' }"
          data-test-id="filter-apply-filter-button"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  title: 'RangeFrame',
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    filterTooltip: {
      type: String,
      default: null,
    },
    isReadonly: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readonlyItems: {
      type: Array,
      default: null,
    },
    applyDisabled: {
      type: Boolean,
      default: false,
    },
    predicateAsValue: {
      type: String,
      default: null,
    },
    conditionButtonText: {
      type: String,
      default: "Apply filter",
    },
    hideButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMenuOpen: false,
      actions: [
        {
          name: 'Edit',
          action: this.openChangeMode,
        },
        {
          name: 'Remove',
          action: this.handleDeleteClick,
        },
      ],
    };
  },
  computed: {
    hasNoValue() {
      if (!this.applyDisabled) return false;
      return !this.readonlyItems || !this.readonlyItems.length === 0;
    },
  },

  methods: {
    handleActionSelect(item) {
      item.action();
    },
    handleMenuIconToggle(isOpen) {
      this.isMenuOpen = isOpen;
    },
    handleConfirmClick() {
      this.$emit('confirm');
    },
    handleDeleteClick() {
      this.$emit('delete');
    },
    handleCancelClick() {
      this.$emit('cancel');
    },
    openChangeMode() {
      if (!this.isReadonly) {
        return;
      }
      this.$emit('openChangeMode');
    },
  },
};
</script>

<style lang="scss" scoped>
.renderer-frame {
  position: relative;

  .renderer-frame-right-top-section {
    position: absolute;
    display: inline-flex;
    top: 20px;
    right: 20px;
    z-index: $zIndexHigh;
    cursor: pointer;

    .renderer-frame-right-top-section-icon {
      color: $blueGrey700;
    }

    .renderer-frame-dropdown-menu {
      position: relative;
      left: 16px;
      bottom: 11px;
      display: none;
      &.open {
        display: inline-block;
      }
    }
  }

  // Put if after .renderer-frame-right-top-section
  &:hover {
    .renderer-frame-dropdown-menu {
      display: inline-block;
    }
  }

  .renderer-frame-content-wrapper {
    position: relative;
    padding: 20px;
    background: $skyBlueGrey300;
    border-radius: 3px;

    &.is-readonly {
      &:hover {
        background: $skyBlueGrey400;
        cursor: pointer;
      }
    }

    .renderer-frame-title-section {
      display: flex;
      align-items: center;
      &.vertical-aligned {
        align-items: baseline;
        flex-direction: column;

        .renderer-frame-subtitle {
          margin-left: 0;
          margin-top: 6px;
        }
      }
      .renderer-frame-title {
        letter-spacing: 0;

        &.shrink {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .renderer-frame-subtitle {
        margin-left: 6px;
      }
    }

    .renderer-frame-content {
      position: relative;
      margin-top: 12px;
      min-height: 16px;

      .loading-bubble-wrapper {
        display: flex;
        justify-content: center;
      }

      .wrapper {
        display: flex;
        flex-direction: column;
      }
    }

    .renderer-frame-buttons-wrapper {
      margin-top: 10px;
      display: flex;
      align-items: center;
    }

    .renderer-frame-answer-section {
      display: flex;
      align-items: top;
      font-size: 14px;
      color: $blueGrey800;
      letter-spacing: 0;
      line-height: 1em;
      margin-top: 12px;
      min-height: 20px;

      .renderer-frame-branch-icon {
        position: relative;
        top: -4px;
      }

      .renderer-frame-answer {
        margin-left: 8px;

        .renderer-frame-answer-title {
          display: block;
          font-size: 14px;
          color: $blueGrey800;

          &.error, &.no-item {
            color: $red500;
          }
        }

        .renderer-frame-answer-date {
          display: block;
          font-size: 12px;
          color: $blueGrey700;
          letter-spacing: 0;
          margin-top: 7px;
        }
      }
    }
  }
}
</style>
