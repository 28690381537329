<template>
  <section
    :class="['subscription-block', blockSize]"
    :style="{
      height: enable || blockSize === 'xs' ? 'unset' : '96px',
    }"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
    @click="subscriptionBlockClick"
  >
    <div
      class="subscription-info"
    >

      <div class="left">
        <ar-checkbox
          ref="checkbox"
          :value="enable"
          @change="handleSubscriptionToggle"
          :disabled="enableCheckboxDisabled"
          :style="{
            pointerEvents: 'none',
          }"
        />

        <div class="subscription-type">
          <ar-icon
            :name="iconName"
            width="25px"
            height="25px"
            :class="['icon', iconName]"
          />

          <div class="identifiers">
            <ar-text
              :text="channelName"
              size="xs"
              weight="bold"
            />

            <ar-text
              v-if="identifier"
              :text="identifier"
              :style="{
                marginTop: '7px',
                color: $arStyle.color.blueGrey700,
                maxWidth: blockSize === 'xs' ? 'calc(100% - 10px)' : '200px',
                width: blockSize === 'xs' ? '100%' : 'auto',
              }"
              size="xs"
            />
          </div>
        </div>
      </div>

      <div class="right">
        <div
          ref="right-section-tooltip-area"
          v-tooltip.top="{
            content: contactsTooltipText,
            show: isHovered,
            trigger: 'manual',
          }"
          :style="{
            height: '20px',
          }"
        >
          <ar-text
            size="xxxs"
            :text="cta"
            class="subscribe-cta"
          />
        </div>
        <am2-gauge
          :max="loading ? null : totalCount"
          :fill-values="gaugeValues"
          :class="[
            'subscriber-gauge',
            enableAll && 'enable-all',
            !(hasSubscribeAllButton) && 'no-subscribe-all']
          "
        />
      </div>
    </div>

    <div
      class="subscribe-all-warning"
      v-if="enable && enableAll && notSubscribedYetCount > 0"
      @click="handleUnsubcribeAll"
    >
      <ar-snackbar
        type="warning"
        :message="excludeContactsMessage"
      />
    </div>
    <div
      class="subscribe-all-warning"
      v-else-if="enable && !enableAll && notSubscribedYetCount > 0"
      @click="handleSubscribeAll"
    >
      <ar-snackbar
        type="warning"
        :message="undoExcludeContactsMessage"
      />
    </div>

  </section>
</template>

<script>
import { formatInteger } from '@/utils/helpers'

export default {
  name: 'SubscribeBlock',
  props: {
    channel: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    identifier: {
      type: String,
      default: null,
    },
    totalOptIns: {
      type: Number,
      required: true,
    },
    totalAvailable: {
      type: Number,
      required: true
    },
    totalCount: {
      type: Number,
      required: true,
    },
    enableAll: {
      type: Boolean,
      required: true
    },
    enable: {
      type: Boolean,
      required: true,
    },
    blockSize: {
      type: String,
      default: 'regular',
      validator: item => {
        return ['xs', 'regular'].indexOf(item) > -1;
      },
    }
  },
  data() {
    return {
      isHovered: false,
    }
  },
  computed: {
    enableCheckboxDisabled() {
      return this.totalAvailable + this.totalOptIns === 0;
    },
    iconName(){
      if (this.channel === 'facebookMessenger') {
        return 'messenger';
      }
      return this.channel;
    },
    contactsTooltipText() {
      if (this.loading) return null;
      // If the checkbox is disabled, we need to let the promoter know why
      if (this.enableCheckboxDisabled) {
        if (this.channel === 'sms') {
          return "No contacts able to be subscribed. This is because they don't have mobile numbers.";
        }
        if (this.channel === 'facebookMessenger') {
          return 'Contacts are required to subscribe via a Campaign or Signup Form';
        }
        if (this.notSubscribedYetCount === 0) {
          return 'There are no more contacts selected to subscribe';
        }
        return "No contacts available to be subscribed";
      }

      let text;
      if (this.enableAll) {
        text = `${formatInteger(this.totalAvailable)} out of ${formatInteger(this.totalCount)} selected contacts have `;
        switch (this.channel) {
          case 'sms':
            text += 'valid mobile numbers'
            break;
          case 'email':
            text += 'valid email addresses'
            break;
          default:
            text += 'previously subscribed to this facebook page'
            break;
        }
      } else {
        text = `${formatInteger(this.totalOptIns)} out of ${formatInteger(this.totalCount)} selected contacts have previously opted in to receive `;
        switch (this.channel) {
          case 'sms':
            text += 'SMS messages'
            break;
          case 'email':
            text += 'emails'
            break;
          default:
            text += 'Facebook messages'
            break;
        }

        text += ' from you';
      }
      return text;
    },
    hasSubscribeAllButton() {
      return !this.enableAll && ((this.totalAvailable > 0 && this.totalOptIns === 0 )|| this.enable);
    },
    notSubscribedYetCount() {
      return this.totalAvailable - this.totalOptIns;
    },
    excludeContactsMessage() {
      return `${formatInteger(this.notSubscribedYetCount)} contact${this.notSubscribedYetCount === 1 ? ' has' : 's have'} not opted in to receive ${this.channelName} messages previously using Audience Republic. Would you like to exclude ${this.notSubscribedYetCount === 1 ? 'this contact' : 'these contacts'}? <a style="font-weight: bold; color: #ff8b25;">Exclude contact${this.notSubscribedYetCount === 1 ? '' : 's'}</a>.`
      // return `By subscribing these additional ${formatInteger(this.notSubscribedYetCount)} contacts to ${this.channelName} you verify that you have permission to contact these users via ${this.channelName}. <a style="font-weight: bold; color: #ff8b25;">Undo</a>`;
    },
    undoExcludeContactsMessage() {
      return `Excluding ${formatInteger(this.notSubscribedYetCount)} contacts that have not previously opted in to receive ${this.channelName} messages. <a style="font-weight: bold; color: #ff8b25;">Undo</a>.`
    },
    channelName() {
      return {
        sms: 'SMS',
        email: 'Email',
        facebookMessenger: 'Facebook Messenger'
      }[this.channel];
    },
    cta() {
      if (this.loading) return 'Calculating...';
      return `Subscribe ${this.enableAll ? this.simplifyNumber(this.totalAvailable) : this.simplifyNumber(this.totalOptIns)} of ${this.simplifyNumber(this.totalCount)}`;
    },
    gaugeValues() {
      const fillValues = [
        {
          color: this.$arStyle.color.blueGrey700,
          value: this.totalAvailable
        }
      ];
      if (this.enable) {
        fillValues[0].color = this.$arStyle.color.purple500;
        fillValues[0].value = this.enableAll ? this.totalAvailable : this.totalOptIns;
      }

      return fillValues;
    },
  },
  methods: {
    resetScroll() {
      this.$refs['items-section'].scrollTop = 0;
    },
    handleSubscriptionToggle(val) {
      this.$emit('change', val);
      // we also want to turn off 'subscribe all'
      // if the promoter toggles off 'subscribe'
      if (!val) {
        this.$emit('optInAll', false);
      } else {
        this.$emit('optInAll', true);
      }
    },
    simplifyNumber(num) {
      if (num > 1000) {
        return `${Math.floor(num/1000)}.${Math.floor((num % 1000)/100)}k`;
      } else {
        return `${num}`;
      }
    },
    handleUnsubcribeAll(event, index) {
      this.$emit('optInAll', false);
      event.stopPropagation(); // Stop propagating so that we don't trigger subscriptionBlockClick
    },
    handleSubscribeAll(event){
      // In case enable hasn't been set to true,
      // do it now. This is possible when there are 0 opt ins.
      if (!this.enable) {
        this.$emit('change', true);
      }
      this.$emit('optInAll', true);
      event.stopPropagation(); // Stop propagating so that we don't trigger subscriptionBlockClick
    },
    subscriptionBlockClick() {
      if (this.loading) return;
      this.$refs.checkbox.toggle();
    },
  },
};
</script>
<style lang="scss">
.subscribe-tooltip {
  width: 300px;
  z-index: $zIndexHighest;
}
</style>
<style lang="scss" scoped>
.subscription-block {
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid $skyBlueGrey500;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  min-height: 96px;
  cursor: pointer;

  .subscribe-all-warning {
    background-color: $orange-lite;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 22px;
    margin-bottom: 17px;
    border-radius: 3px;

    .warning-message {
      width: 100%;
    }
  }

  .subscription-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;

      .subscription-type {
        margin-left: 27px;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 35px;

        .identifiers {
          display: flex;
          flex-direction: column;
        }

        .icon {
          margin-right: 12px;
          &.messenger {
            color: #0086FF;
          }

          &.sms {
            color: $green500;
          }

          &.email {
            color: $purple500;
          }
        }
      }
    }


    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .subscriber-gauge {
        width: 80px;
        margin-top: 4px;
        margin-bottom: 5px;
      }

      .subscribe-cta {
        cursor: default;
      }
    }
  }

  &.xs {
    padding: 16px 12px 0;

    .subscription-info {
      flex-direction: column;

      .left {
        width: 100%;
        .subscription-type {
          margin-left: 12px;
          width:100%;
          .identifiers {
            width: calc(100% - 38px);
          }
        }
      }
      .right {
        width: 100%;
        padding-top:12px;
      }
    }

  }
}
</style>
