var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"campaign-edit-share"},[_c('ar-text',{staticClass:"step-heading",style:({
      color: '#9FA8B5',
      marginBottom: '8px',
    }),attrs:{"size":"xs","text":"STEP 4"}}),_vm._v(" "),_c('am2-heading',{staticClass:"title",style:({marginBottom: '38.5px'}),attrs:{"type":"h1","size":"lg","title":"Social: Share"}}),_vm._v(" "),_c('p',{staticClass:"large"},[_vm._v("\n    "+_vm._s(_vm.copy)+"\n  ")]),_vm._v(" "),(_vm.eventPaymentInfo === 'free')?_c('div',{class:[
      'campaign-edit-share__preview',
      'locked',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[_vm._v("\n    Allow fans to earn points by inviting their friends\n    "),_c('ar-simple-button',{attrs:{"icon-name":"circle-plus","text":"Upgrade now"},on:{"click":_vm.chooseFree}})],1):_vm._e(),_vm._v(" "),(_vm.eventPaymentInfo === 'premium')?_c('div',{class:[
      'campaign-edit-share__preview',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[_c('div',[_c('div',{staticClass:"campaign-edit-share__link"},[_c('div',{staticClass:"circle"}),_vm._v(" "),_c('div',{staticClass:"title-section"},[_c('ar-text',{staticClass:"share-top-heading",attrs:{"text":"<b>You</b> shared a <b>link.</b>","allow-html":"","size":"xs"}}),_vm._v(" "),_c('p',{staticClass:"share-top-time-text"},[_vm._v("3 mins")])],1),_vm._v(" "),_c('ar-icon',{staticClass:"menu-icon",attrs:{"name":"menu","rotate":90}})],1),_vm._v(" "),_c('div',{staticClass:"campaign-edit-share__card"},[_c('img',{attrs:{"src":_vm.campaignImage}}),_vm._v(" "),_c('div',{staticClass:"campaign-edit-share__content"},[_c('ar-text',{style:({
              color: '#464950',
              marginBottom: '2px',
            }),attrs:{"size":"xxxs","text":_vm.campaignDomain}}),_vm._v(" "),_c('ar-text',{staticClass:"campaign-edit-share__content-title",style:({
              color: '#1c2028',
              marginBottom: '2px',
            }),attrs:{"size":"xs","text":_vm.campaignViewModel.presentation.metaTitle,"multiple-lines":"","max-lines":2,"line-height":"18px","weight":"bold"}}),_vm._v(" "),_c('ar-text',{style:({
              color: '#464950',
            }),attrs:{"size":"xxxs","text":_vm.campaignViewModel.presentation.metaDescription}})],1)]),_vm._v(" "),_c('div',{staticClass:"campaign-edit-share__bottom"},[_c('div',{staticClass:"share-bottom__group"},[_c('ar-icon',{class:[
              'share-bottom-icon',
              _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"name":"like"}}),_vm._v(" "),_c('span',{staticClass:"share-bottom-text"},[_vm._v("Like")])],1),_vm._v(" "),_c('div',{staticClass:"share-bottom__group"},[_c('ar-icon',{class:[
              'share-bottom-icon',
              _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"name":"dialog"}}),_vm._v(" "),_c('span',{staticClass:"share-bottom-text"},[_vm._v("Comment")])],1),_vm._v(" "),_c('div',{staticClass:"share-bottom__group"},[_c('ar-icon',{class:[
              'share-bottom-icon',
              _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"name":"share"}}),_vm._v(" "),_c('span',{staticClass:"share-bottom-text"},[_vm._v("Share")])],1)])]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('am2-field',{staticClass:"share-media-title",attrs:{"name":"share-media-title","label":"Share title","description":"Write a call to action that will get people to click."}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"input--block",attrs:{"autocomplete":"campaign-headline","data-vv-name":"shareMediaTitle","type":"text"},model:{value:(_vm.campaignViewModel.presentation.metaTitle),callback:function ($$v) {_vm.$set(_vm.campaignViewModel.presentation, "metaTitle", $$v)},expression:"campaignViewModel.presentation.metaTitle"}}),_vm._v(" "),(_vm.veeErrors.has('shareMediaTitle'))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"type":"error","text":_vm.veeErrors.first('shareMediaTitle')}}):_vm._e()],1),_vm._v(" "),_c('am2-field',{staticClass:"share-media-description",attrs:{"name":"share-media-description","label":"Share description","description":"Tell people why they should register, include the incentives and create urgency."}},[_c('ar-textarea',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"rows":3,"data-vv-name":"shareMediaDescription"},model:{value:(_vm.campaignViewModel.presentation.metaDescription),callback:function ($$v) {_vm.$set(_vm.campaignViewModel.presentation, "metaDescription", $$v)},expression:"campaignViewModel.presentation.metaDescription"}}),_vm._v(" "),(_vm.veeErrors.has('shareMediaDescription'))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"type":"error","text":_vm.veeErrors.first('shareMediaDescription')}}):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(_vm.eventPaymentInfo === 'premium')?_c('am2-field',{class:[
      'social-media-channels',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max'
    ],attrs:{"name":"social-media-channels","label":"Social media channels"}},[_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('hasShareChannel'),expression:"'hasShareChannel'"}],style:({ display: 'none' }),attrs:{"data-vv-name":"shareChannels"},domProps:{"value":JSON.stringify(_vm.campaignViewModel.shareChannels)}}),_vm._v(" "),(_vm.veeErrors.has('shareChannels'))?_c('ar-state-message',{attrs:{"text":_vm.veeErrors.first('shareChannels'),"type":"error"}}):_vm._e(),_vm._v(" "),_c('p',{staticClass:"hint-text"},[_vm._v("Choose how your audience can share across social media:")]),_vm._v(" "),_c('transition-group',{attrs:{"name":"overlay-fade","tag":"div"}},_vm._l((_vm.visibleShareChannels),function(channel,index){return _c('div',{key:channel.key},[_c('ar-checkbox',{style:({ height: '52px', width: '100%' }),attrs:{"icon-name":channel.key,"icon-props":{ color: channel.iconColor },"label":channel.name,"align":"right"},model:{value:(channel.selected),callback:function ($$v) {_vm.$set(channel, "selected", $$v)},expression:"channel.selected"}}),_vm._v(" "),(index !== _vm.campaignViewModel.shareChannels.length - 1)?_c('ar-divider'):_vm._e()],1)}),0),_vm._v(" "),(_vm.showToggleFieldsButton)?_c('a',{staticClass:"toggle-fields",on:{"click":function($event){_vm.hideExtraFields = !_vm.hideExtraFields}}},[_vm._v("\n      "+_vm._s(_vm.hideExtraFields ? 'Show all platforms' : 'Show fewer platforms')+"\n    ")]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }