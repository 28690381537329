<template>
  <div
    class="img-container"
    :style="{
      width,
      height,
    }"
  >
    <div
      class="background"
      :style="{'backgroundImage': `url(${src}`}"
    />
    <div
      class="foreground"
      :style="{'backgroundImage': `url(${src}`}"
    />

  </div>
</template>

<script>
  export default {
    name: 'ImageBlurredExcess',
    props: {
      width: {
        type: String,
        required: true,
      },
      height: {
        type: String,
        required: true,
      },
      src: {
        type: String,
        required: true,
      }
    },
  }
</script>

<style lang="scss" scoped>
$blur-factor: 30px;
.img-container {
  position: relative;
  overflow: hidden;
  transform: translateZ(0); // forces hardware acceleration on this element, which should prevent flickering of blurred BG image

  .background {
    position: absolute;
    top: 0 - ($blur-factor / 2);
    left: 0 - ($blur-factor / 2);
    width: calc(100% + #{$blur-factor}*2);
    height: calc(100% + #{$blur-factor}*2);;
    filter: blur($blur-factor - 10px) brightness(85%);

    background-size: contain;
  }

  .foreground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
</style>
