
export function generateRandomName() {
  const fNameList = ['Martin', 'Armin', 'Calvin', 'Flux', 'Major', 'Alison', 'Katy', 'Ellie', 'Nina'];
  const lNameList = ['Garrix', 'van Buuren', 'Harris', 'Pavillion', 'Lazer', 'Wonderland', 'Perry', 'Goulding', 'Las Vegas'];
  return `${fNameList[Math.floor(Math.random()*fNameList.length)]} ${lNameList[Math.floor(Math.random()*lNameList.length)]}`;
}

export function generateRandomCity() {
  const cityList = ['Sydney', 'Vancouver', 'New York', 'Las Vegas', 'Melbourne', 'London', 'Manchester', 'Edinburgh', 'Brisbane', 'San Francisco', 'Paris', 'Miami', 'Chicago'];
  return cityList[Math.floor(Math.random()*cityList.length)];
}

export function generateRandomCountry() {
  const countryList = ['Australia', 'United States', 'United Kingdom', 'New Zealand', 'France'];
  return countryList[Math.floor(Math.random()*countryList.length)];
}

export function generateRandomNumber({minMajor = 1, maxMajor = 2, minor = 0, min = null, max = null}) {
  const major = minMajor === maxMajor ? maxMajor : Math.random() * (maxMajor - minMajor) + minMajor;
  if(min && max) {
    return (Math.random()*(max-min+1)+min).toFixed(minor);
  } else {
    return (Math.random() * (Math.pow(10, major))).toFixed(minor);
  }
}

export function generateRandomString(len = 5) {
  return Math.random().toString(36).substring(0, len);
};

export function returnTestCardNumber(card) {
  let cardNumber = '';
  switch(card) {
    case 'visa':
      cardNumber = '4242424242424242';
      break;
    case 'visa-debit':
      cardNumber = '4000056655665556';
      break;
    case 'mastercard':
      cardNumber = '5555555555554444';
      break;
    case 'mastercard-debit':
      cardNumber = '5200828282828210';
      break;
    case 'american-express':
      cardNumber = '378282246310005';
      break;
    case 'declined':
      cardNumber = '4000000000000002';
      break;
    case 'fraud':
      cardNumber = '4100000000000019';
      break;
    case 'expired':
      cardNumber = '4000000000000069';
      break;
    case 'bad-number':
      cardNumber = '4242424242424241';
      break;
    case 'bad-cvc':
      cardNumber = '4000000000000127';
      break;
    default:
      cardNumber = '4242424242424242';
      break;
  }
  return cardNumber;
}
