var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreData),expression:"loadMoreData"}],staticClass:"event-orders-container",attrs:{"infinite-scroll-disabled":"infiniteScrollDisabled","infinite-scroll-distance":"10"}},[_c('div',{directives:[{name:"ar-sticky-top",rawName:"v-ar-sticky-top",value:({
      priority: 1,
    }),expression:"{\n      priority: 1,\n    }"}],staticClass:"orders-header",style:({
      padding: '20px 0 22px',
      backgroundColor: '#F7F9FC',
    })},[_c('am2-heading',{style:({
        marginRight: '15px',
      }),attrs:{"type":"h1","size":"md","title":"Orders"}}),_vm._v(" "),_c('div',{staticClass:"filter-wrapper"},[_c('am2-link-button-dropdown',{style:({
          marginTop: '8px',
        }),attrs:{"items":_vm.dropdown,"item-key":_vm.activeDropdownFilter},on:{"select":_vm.handleDropdownItemSelect}})],1)],1),_vm._v(" "),_c('am2-table-control-section',{directives:[{name:"ar-sticky-top",rawName:"v-ar-sticky-top",value:({
      priority: 1,
    }),expression:"{\n      priority: 1,\n    }"}],style:({
      marginTop: '8px',
      backgroundColor: '#f6f9fc',
    }),attrs:{"column-settings":{
      selectedColumnKeys: _vm.selectedColumnKeys,
      availableColumns: _vm.availableColumns,
      action: _vm.handleSelectedColumnKeysUpdate,
    },"fans-search":{
      action: _vm.serverSideSearch,
    },"loading":(_vm.isFetchingOrders) && _vm.orders.length === 0,"show-checked-rows-options-bar":"","show-filter-and-search-bar":"","showFilterButton":false,"fan-appellation":"order"}}),_vm._v(" "),_c('am2-orders-table',{attrs:{"head":_vm.displayedTableHeadings,"body":_vm.tableBody,"loading":_vm.isFetchingOrders && _vm.tableBody.length === 0,"empty-text":"No order data","enable-row-click":_vm.customerProfilesEnabled,"has-sticky-header":"","tableType":"orders"},on:{"sortBy":_vm.handleSortChange,"rowClick":_vm.handleRowClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }