<template>
  <EditableFrame
    class="if-else-wrapper"
    title="Edit Action"
    :subtitle="item.name"
    has-save-button
    :save-button-disabled="savedButtonDisabled"
    @close="$emit('close')"
    @save="handleSave"
  >

    <ar-text
      class="condition-heading"
      size="13px"
      text="CONDITION"
      weight="bold"
      multiple-lines
      :max-lines="1"
      letter-spacing="1px"
    />
    <div class="conditions-wrapper">
       <ar-simple-select
          :key="simpleSelectKey"
          class="order-dropdown"
          :items="dropdownItems"
          :default-select-index="selectedIndex"
          :style="{
            width: '100%',
            marginBottom: '20px'
          }"
          :dropdown-style="{
            padding: '8px 0',
            maxHeight: '400px',
          }"
          @select="handleDropdownItemSelect"
          data-test-id="if-else-select"
        />
    </div>
    <template v-if="selectedCondition === 'savedSegments'">
      <div class="saved-segments-menu-wrapper">
        <div class="saved-segments-header-wrapper">
          <am2-heading
            type="legend"
            size="xs"
            title="Saved segments"
            weight="bold"
          />
          <ar-icon
            name="cross"
            height="12px"
            class="saved-segments-menu-close-cross"
            data-test-id="saved-segments-close-button"
            @click.native="handleSegmentsCancelClick"
          />
        </div>
        <div class="segments-menu-wrapper">
          <am2-search
            ref="saved-segments-search"
            class="saved-segments-search"
            v-model="segmentsSearchText"
            placeholder="Search segments..."
            data-test-id="saved-segments-search"
          />
          <div class="saved-segments-wrapper">
            <div class="overflow-top"></div>
            <div
              v-for="segment in filteredSegmentList"
              :key="segment.oid"
              class="segments-menu-item"
            >
              <ar-checkbox
                class="if-else-segment-radio"
                type="radio"
                :label="segment.name"
                :ticked-value="segment.oid"
                v-model="selectedSegment"
                :data-test-id="`if-else-segment-radio-${segment.oid}`"
              />
            </div>
            <div class="overflow-bottom"></div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        ref="if-else-condition-renderer"
        v-for="(condition, index) in conditionsPlanner"
        :key="index"
      >
        <am2-filter-condition-renderer
          :key="conditionKey"
          :filter-criteria="segmentCriteriaMap[condition.name]"
          :filter-condition="condition"
          hide-button
          disable-read-mode
          @apply="($event) => applyCondition(index, $event)"
          @delete="setNullCondition"
        />
      </div>
    </template>
  </EditableFrame>
</template>
<script>
import EditableFrame from '../utils/EditableFrame.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { generateRandomString } from '@/utils/helpers'
export default {
  name: 'IfElse',
  components: {
    EditableFrame,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      conditionsPlanner: null,
      conditionKey: '',
      simpleSelectKey: '',
      selectedCondition: null,
      currentCondition: null,
      selectedSegment: null,
      selectedIndex: null,
      segmentsSearchText: '',
      filteredSegmentList: []
    }
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    },
    conditionsPlanner() {
      if (!this.conditionsPlanner) return
      this.conditionKey = generateRandomString()
    },
    segmentsSearchText() {
      this.filterSegments(this.segmentsSearchText)
    }
  },
  computed: {
    ...mapState({
      segmentCriteriaMap: state => state.segment.segmentCriteriaMap,
      segmentList: state => state.segment.savedSegmentList,
    }),
    ...mapGetters({
      audienceConditions: 'segment/audienceFilterCriteria',
    }),
    savedButtonDisabled() {
      return !this.selectedCondition
    },
    dropdownItems() {
      let audienceConditions =  this.audienceConditions.map(item => {
        return {
          name: item.title,
          key: item.resource,
        }
      })
      if (!!this.segmentList.length) {
        audienceConditions.push({ name: 'Saved segments', key: 'savedSegments' })
      }
      return audienceConditions
    },
  },
  methods: {
    ...mapActions({
      updateFilterCondition: 'automation/UPDATE_FILTER_CONDITION',
      setSavedSegmentAsCondition: 'automation/SET_SAVED_SEGMENT_AS_CONDITION',
      setAudienceFilterAsCondition: 'automation/SET_AUDIENCE_FILTER_AS_CONDITION',
      resetIfElseConfig: 'automation/RESET_IF_ELSE_CONFIG',
    }),
    filterSegments(text) {
      let filteredSegmentList = []
      let i = 0
      while (i < this.segmentList.length) {
        if (this.$arFuzzySearch(this.segmentList[i].name, text)) {
          filteredSegmentList.push(this.segmentList[i])
        }

        i++
      }

      this.filteredSegmentList = filteredSegmentList
    },
    setSelectedIndex() {
      if (!!this.item.meta?.filter) {
        let selectedItem = this.item.meta.filter.name
        this.selectedIndex = this.dropdownItems.findIndex(item => item.key === selectedItem)
      } else{
        this.selectedIndex = null
      }
    },
    handleSegmentsCancelClick() {
      this.setNullCondition()
    },
    applyCondition(_, $event) {
      this.currentCondition = $event
    },
    resetLocal(){
      this.conditionsPlanner = null
      this.selectedCondition = null
      this.currentCondition = null
      this.selectedIndex = null
      this.selectedSegment = null
    },
    setNullCondition() {
      this.simpleSelectKey = generateRandomString()
      this.resetLocal()
      this.resetIfElseConfig(this.item.id)
    },
    handleDropdownItemSelect(selected) {
      if (selected.key !== 'savedSegments') {
        let selectedItem = this.segmentCriteriaMap[selected.key]
        let transformedCondition = this.$arUtils.segment.generateSegmentCondition(selectedItem)
        this.conditionsPlanner = new Array(transformedCondition)
      }

      this.selectedCondition = selected.key
    },
    handleSave() {
      if (this.selectedCondition === 'savedSegments') {
        this.setSavedSegmentAsCondition({id: this.item.id, segmentId: this.selectedSegment})
      } else {
        if (!this.item.config?.['filter-group-oid'] || !!this.item.config?.frontend.segment) {
          this.setAudienceFilterAsCondition({id: this.item.id, filter: this.currentCondition})
        } else {
          this.updateFilterCondition({id: this.item.id, filterId: this.item.config['filter-group-oid'], filter: this.currentCondition})
        }
      }
      this.$emit('close')
    },
    setItemVals() {
      this.resetLocal()

      if (this.item.config?.['filter-group-oid']) {
        if (this.item.config.frontend.segment) {
          this.selectedSegment = this.item.config['filter-group-oid']
          this.selectedIndex = this.dropdownItems.findIndex(item => item.key === 'savedSegments')
          this.selectedCondition = 'savedSegments'
        } else {
          if (this.item.meta?.filter) {
            this.conditionsPlanner = new Array(this.item.meta.filter)
            this.selectedCondition = this.item.meta.filter
            this.currentCondition = this.conditionsPlanner[0]
            this.setSelectedIndex()
          }
        }
      }
    },
  },
  mounted() {
    this.setItemVals()
    this.filteredSegmentList = this.segmentList
  }
}
</script>

<style lang="scss" scoped>
.if-else-wrapper {
  width: 100%;
  height: 100%;

  .condition-heading {
    margin-bottom: 14px;
  }

  .saved-segments-menu-wrapper {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 24px 20px;
    background: $skyBlueGrey300;
    border-radius: 3px;

    .saved-segments-header-wrapper {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .saved-segments-menu-close-cross {
        cursor: pointer;
      }
    }

    .segments-menu-wrapper {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;

      .saved-segments-search {
        height: 40px;
        width: 100%;
        margin-bottom: 20px;

        /deep/ input {
          padding-right: 40px !important;
        }
      }

      .saved-segments-wrapper {
          width: 100%;
          max-height: 270px;
          overflow: scroll;
          position: relative;

          .overflow-top {
            background: linear-gradient(180deg, $skyBlueGrey300, transparent);
            width: 100%;
            height: 14px;
            position: sticky;
            top: 0;
            z-index: 2;
            margin-bottom: -12px;
          }

          .overflow-bottom {
            background: linear-gradient(0deg, $skyBlueGrey300, transparent);
            width: 100%;
            height: 14px;
            position: sticky;
            bottom: 0;
            z-index: $zIndexHigh;
          }
        }

      .segments-menu-item {
        padding: 7px 0;

        /deep/ .label-section {
          width: 240px;
        }
      }
    }
  }

  .conditions-wrapper {
    width: 100%;
    background: aliceblue;

    .if-else-dropdown-button {
      width: 100%;
    }
  }
}
</style>