<template>
    <section class="tags-list-container">
      <div class="top-section">
        <am2-heading
          type="h1"
          size="md"
          class="section-heading"
          :title="title"
        />
        <ar-link-button
          :text="isAddingTag ? 'Cancel' : '+ Add tag'"
          :has-underline="false"
          color="purple"
          :text-props="{
            weight: 'bold',
          }"
          data-test-id="tag-list-top-button"
          @click="setIsAddingTag(!isAddingTag)"
        />
      </div>
      <ar-auto-complete-input
        ref="input"
        v-if="isAddingTag"
        :value="tagSearchString"
        @input="handleTagSearchStringInput"
        :suggestions="suggestedTags"
        placeholder="Add tag"
        class="add-tag-input"
        new-suggestion-template="Create new tag {suggestion}"
        data-test-id="tag-list-auto-complete-input"
        @suggestSelect="handleSuggestionClick"
        :style="inputStyles"
        @blur="handleInputBlur"
        :disabled="isProcessingNewTag"
      />
      <div v-if="loading || customerState.isFetchingFanTags">
        <am2-tag-skeleton class="tag" :style="{ width: '60px' }" :level="15" />
        <am2-tag-skeleton class="tag" :style="{ width: '70px' }" :level="15" />
        <am2-tag-skeleton class="tag" :style="{ width: '150px' }" :level="15" />
      </div>
      <am2-tag
        v-for="(tag, index) in customerState.fanTags"
        :text="tag.name"
        :tagOid="tag.oid"
        :key="index"
        type="light-grey"
        has-cross
        @remove="handleRemoveAction(tag)"
        :data-test-id="`tag-list-tag-${tag.name}`"
        text-weight="normal"
        class="tag"
      />
      <div v-if="customerState.fanTags.length === 0 && !loading && !customerState.isFetchingFanTags && !isAddingTag">
        <ar-text
          size="xs"
          text="No tags"
          data-test-id="tag-list-no-tags"
          :style="{
            color: $arStyle.color.skyBlueGrey700,
          }"
        />
      </div>
    </section>
</template>

<script>

  import { mapActions, mapMutations, mapState } from 'vuex';
  import { debounce } from "debounce";

  export default {
    name: 'TagsList',
    props: {
      title: {
        type: String,
        default: 'Tags',
      },
      removeAction: {
        type: Function,
        default: null,
      },
      fanOid: {
        type: Number,
        default: null,
      },
      inputStyles: {
        type: Object,
        default: () => {},
      },
      loading: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        tagSearchString: '',
        isAddingTag: false,
        isProcessingNewTag: false,
      }
    },

    computed: {
      ...mapState({
        tagState: state => state.tag,
        customerState: state => state.customer,
      }),
      customerStateFanTagsMap() {
        const res = {};
        this.customerState.fanTags.forEach(tag => {
          res[tag.oid] = tag;
        });
        return res;
      },
      suggestedTags() {
        return this.tagState.tags
          .filter(tag => {
            return !this.customerStateFanTagsMap[tag.oid];
          })
          .map(item => item.name);
      },
    },

    watch: {
      fanOid: {
        handler: function(fanOid) {
          if (fanOid) this.fetchFanTags();
        },
        immediate: true,
      },
    },

    methods: {
      ...mapActions([
        'customer/FETCH_CUSTOMER_TAGS',
        'MASS_EDIT_FAN',
        'tag/FETCH_MORE_TAGS',
      ]),

      ...mapMutations([
        'tag/RESET_TAGS',
        'customer/RESET_CUSTOMER_TAGS',
        'customer/REMOVE_FROM_CUSTOMER_TAGS'
      ]),

      setIsAddingTag(setting) {
        this.isAddingTag = setting;
        if (setting) {
          this.$nextTick(() => {
            if (!!this.$refs.input) {
              this.$refs.input.focusSelectBox();
            }
          });
        }
      },

      handleInputBlur() {
        this.tagSearchString = '';
        this['tag/RESET_TAGS']();
      },

      handleTagSearchStringInput(newSearchString) {
        this.tagSearchString = newSearchString;

        this.debounceFetchMoreTags({
          top: 20,
          reload: true,
          searchString: newSearchString,
        });
      },

      async handleSuggestionClick(newTagName) {
        if (!this.fanOid) return;

        try {
          const payload = {
            fanOids: [this.fanOid],
            tags: {'add': [newTagName]},
          };
          await this.MASS_EDIT_FAN(payload);
          this.tagSearchString = '';
          this.isProcessingNewTag = true;

          // the mass edit is async, so we need to give the server time to process the request before asking for tags again
          setTimeout( () => {
            this.fetchFanTags();
            this['tag/FETCH_MORE_TAGS']({
              top: 50,
              reload: true,
            });
            this.isProcessingNewTag = false;
          }, 500);

        } catch (e) {
          console.log(e);
          this.$arNotification.push({ type: 'error', message: typeof(e) === 'string' ? e : "There was a problem processing your request" });
        }
      },

      async handleRemoveAction(tagToRemove) {
        if (!this.fanOid) return;

        try {
          const payload = {
            fanOids: [this.fanOid],
            tags: {'remove': [tagToRemove.name]},
          };
          await this.MASS_EDIT_FAN(payload);
          this['customer/REMOVE_FROM_CUSTOMER_TAGS'](tagToRemove.oid);
        } catch (e) {
          console.log(e);
          this.$arNotification.push({ type: 'error', message: typeof(e) === 'string' ? e : "There was a problem processing your request" });
        }
      },

      async fetchFanTags() {
        if (this.customerState.isFetchingFanTags) return;
        this['customer/RESET_CUSTOMER_TAGS']();
        await this['customer/FETCH_CUSTOMER_TAGS'](this.fanOid);
      },
    },
    mounted() {
      this.debounceFetchMoreTags = debounce((args) => {
        this['tag/FETCH_MORE_TAGS'](args);
      });
    },

    beforeDestroy() {
      this['tag/RESET_TAGS']();
    },
  };
</script>

<style lang="scss" scoped>
  .tags-list-container {
    padding: 32px;

    .top-section {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      .section-heading {
        margin-bottom:24px;
      }
    }

    .tag {
      margin-right: 4px;
      margin-top: 8px;
      font-weight: normal;
      max-width: 100%;
    }

    .add-tag-input {
      margin-bottom: 8px;
      height: 40px;
    }

  }
</style>
