<template>
  <section class="campaign-edit-details">
    <am2-import-dynamic-tags-modal
      :is-show="showImportDynamicTagsModal"
      :message-list-oid="scratchSimpleMessage.meta.messageListOid"
      channel="sms"
      @import="handleDynamicTagsImport"
      @cancel="showImportDynamicTagsModal = false"
    />

    <!-- Message To -->
    <ar-field label="To">
      <div>
        <am2-message-list-select
          ref="message-list-select"
          :value="scratchSimpleMessage.meta.messageListOid"
          :campaign-oid="scratchSimpleMessageCampaignOid"
          :event-oid="scratchSimpleMessageEventOid"
          @select="handleMessageListSelect"
          :channels="['sms']"
          v-tooltip.top="{
            content: dynamicTagsExist ? 'Changing lists will clear all dynamic tags from your message!' : null
          }"
          data-test-id="message-list-select"
        />

        <ar-snackbar
          v-if="noContacts"
          type="error"
          message="There are no valid mobile phone numbers in this list"
          :style="{ marginTop: '10px' }"
        />

        <div v-else-if="recipientCountMismatch"
             :style="{
                position: $arMediaQuery.window.minWidth('md') && showAdvancedTargetingModule ? 'absolute' : 'relative'
             }"
             class="u-display-flex u-margin-top-3 u-align-items-center">
          <ar-icon
            v-tooltip.top="{
              content: cantMessageContactsTooltipMessage
            }"
            name="tooltip-question-mark"
            class="tooltip"
            height="18px"
            width="18px"
            :style="{
              color: $arStyle.color.blueGrey600,
            }"
          />
          <ar-text
            size="xs"
            text="Why can't I message all my contacts?"
            class="u-margin-left-2"
            :style="{
              color: $arStyle.color.blueGrey600,
            }"
            weight="bold"
          />
        </div>

        <ar-snackbar
          v-if="currentSelectedMessage && currentMessageListIsForAllCampaignRegistrants"
          class="u-margin-top-5"
          type="warning"
          message="Changing from All Recipients to a Tier will disable scheduling."
        />
        <ar-state-message
          v-if="currentCampaignIsActive && currentMessageListIsForCampaignTier"
          class="u-margin-top-5"
          :text-props="{
            weight: 'bold',
          }"
          has-icon
          disable-text-color
          type="information"
          text="Note: It's recommended to only message individual tiers once the campaign is ended."
        />
      </div>
    </ar-field>

    <am2-message-list-advanced-targeting-section
      v-if="showAdvancedTargetingModule"
      class="u-margin-padding-top-1"
      channel="sms"
      show-toggle
      @updateAdvancedTargeting="handleAdvancedTargetingUpdate"
    />

    <!-- Message Body Field Wrapper -->
    <div
      class="u-position-relative"
      :style="{
        marginTop: '40px',
      }"
    >
      <div
        class="u-position-absolute u-display-flex u-justify-content-flex-end"
        :style="{ top: 0, right: 0 }"
      >
        <am2-link-button-dropdown
          :items="dynamicTagSelectItems"
          :button-props="{
            hasArrow: false,
            hasUnderline: true,
          }"
          :item-style="{
            height: '44px',
            padding: '0 20px',
          }"
          :divider-style="{
            margin: '0',
          }"
          :disabled="isListValid || !scratchSimpleMessage.meta.messageListOid"
          :align="$arMediaQuery.window.maxWidth('xs') ? 'left' : 'right'"
          placeholder="Insert dynamic tag"
          :dropdown-style="{
            padding: '0',
          }"
          @select="handleInsertDynamicTagsSelect"
          v-tooltip.top="{
            content: isListValid ? 'When you select a list with valid SMS subscribers you’ll be able to use dynamic tags.' : null
          }"
          data-test-id="insert-dynamic-tag-link"
        />
      </div>
      <ar-field
        name="message-body"
        label="Message"
        :style="{
          marginTop: '40px',
        }"
        :error-messages="[veeErrors.first('messageBody')]"
      >
        <SimpleMessageEditor
          ref="simple-message-editor"
          :dynamic-tags="dynamicTags"
          :value="scratchSimpleMessage.meta.messageBody"
          @input="handleMessageBodyInput"
          v-validate="{
            required: true,
            max: scratchSimpleMessage.meta.messageBody.length + smsMessagePreview.charactersLeft,
          }"
          data-vv-name="messageBody"
        />
        <ar-snackbar
          v-if="nonExistingTagsInSimpleMessageErrorCopy"
          type="error"
          :message="nonExistingTagsInSimpleMessageErrorCopy"
          :style="{
            marginTop: '10px',
          }"
        />
        <am2-characters-left-copy-section
          :current-length="scratchSimpleMessage.meta.messageBody.length"
          tooltip="The character count below excludes the opt out message, which gets automatically appended to your SMS."
          :max-length="scratchSimpleMessage.meta.messageBody.length + smsMessagePreview.charactersLeft"
          :template="`${smsMessagePreviewLength} characters / ${smsMessagePreviewSegmentsCount} SMS`"
          :style="{
            marginTop: '10px',
          }"
        />
      </ar-field>
    </div>

    <!-- Schefuling -->
    <ar-field
      label="Schedule?"
      :style="{
        marginTop: '20px',
      }"
    >
      <ar-checkbox
        :value="enableSchedule"
        @input="handleEnableScheduleToggle"
        label="Schedule a time to send this message"
        :disabled="currentSelectedMessageUiStatus === 'scheduled' || !currentMessageListCanBeSchedued"
        v-tooltip.top="{
          content: !currentMessageListCanBeSchedued && !enableSchedule ? 'You cannot schedule messages from campaigns' : null,
        }"
      />
      <div
        v-if="enableSchedule"
        :class="[
          'schedule-input-group',
          $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        ]"
        :style="{
          display: 'flex',
          alignItems: 'baseline',
          marginTop: '20px',
        }"
      >
        <ar-field
          label="Start time"
          :class="[
            'u-color-blueGrey700',
            'u-flex-shrink-0',
          ]"
          :style="{
            margin: '0',
          }"
          :error-messages="[veeErrors.first('scheduledAt')]"
        >
          <am2-date-time-input
            :value="scratchSimpleMessage.scheduledAt"
            @input="handleScheduledAtInput"
            :time-zone="scratchSimpleMessage.meta.presentation.timeZone"
            v-validate="'required|tzFormat|afterNow|after5MinFromNow'"
            data-vv-name="scheduledAt"
            data-vv-as="scheduled time"
          />
        </ar-field>
        <ar-divider
          v-if="$arMediaQuery.pageContent.minWidth('md')"
          :style="{
            position: 'relative',
            top: '38px',
            width: '50px',
            margin: '0 10px',
          }"
        />
        <ar-field
          class="time-zone"
          label="Timezone"
          :class="[
            'u-flex-shrink-0',
          ]"
          :style="{
            color: $arStyle.color.blueGrey700,
          }"
          :error-messages="[veeErrors.first('timeZone')]"
        >
          <am2-timezone-select
            :value="scratchSimpleMessage.meta.presentation.timeZone"
            @input="handleTimezoneInput"
            v-validate="'required'"
            data-vv-name="timeZone"
          />
        </ar-field>
      </div>
    </ar-field>
    <!-- Cost Preview -->
    <div
      :class="[
        'cost-container',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      ]"
      :style="{
        marginTop: '70px',
        marginLeft: $arMediaQuery.pageContent.maxWidth('sm') ? '0' : '-12px',
      }"
    >
      <ar-checkbox
        :value="costAccepted"
        label="I agree to charge my credit card"
        :disabled="smsCostFailedToFetch"
        @change="emitCostDecisionChangeEvent"
      />
      <div class="cost">
        <ar-text
          v-if="smsCostFailedToFetch"
          class="value"
          size="sm"
          weight="bold"
          allow-html
          :text="`<a>Error - Try again</a>`"
          v-tooltip.top="{
            content: 'There was a problem while calculating the cost of your SMS. Please try again.'
          }"
          @anchorClick="handleTryFetchingCostAgain"
        />
        <ar-text
          v-else-if="!isFetchingSmsCost"
          class="value"
          size="sm"
          weight="bold"
          :text="`${smsMessagePreview.cost.currency} ${formatCurrency(smsMessagePreview.cost.total)}`"
        />
        <am2-loading-spinner
          v-if="isFetchingSmsCost"
          :style="{
            width: '1.5rem',
            height: '1.5rem',
            marginRight: '1.25rem'
          }"
        />

        <ar-icon
          v-if="!smsCostFailedToFetch"
          v-tooltip.top="{
            content: 'This is the total cost for sending this message to all recipients'
          }"
          name="tooltip-question-mark"
          class="tooltip"
          height="20px"
          width="20px"
        />
      </div>
    </div>
  </section>
</template>

<script>
  import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import moment from 'moment';
import {
  formatCurrency,
} from '@/utils/helpers/';
import checkoutPageAccessibility from '@/mixins/checkoutPageAccessibility';
import SimpleMessageEditor from '@/components/editors/simple-message-editor';


export default {
  name: 'SMS',

  mixins: [
    checkoutPageAccessibility({ featureKeys: ['messages', 'sms'], featureName: 'SMS' }),
  ],

  components: {
    SimpleMessageEditor,
  },

  props: {
    messageList: {
      type: Object,
      default: null,
    },
    costAccepted: {
      type: Boolean,
      default: false,
    },
    noContacts: {
      type: Boolean,
      default: false,
    },
    recipientCountMismatch: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      showImportDynamicTagsModal: false,
      enableSchedule: false,
      arEnableMessageAdvancedTargeting: process.env.arEnableMessageAdvancedTargeting,
    };
  },

  computed: {
    ...mapState({
      currentCampaign: state => state.campaign.currentCampaign,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      smsMessagePreview: state => state.message.smsMessagePreview,
      scratchSimpleMessage: state => state.message.scratchSimpleMessage,
      currentMessageList: state => state.messageList.currentMessageList,
      advancedMessageListTargeting: state => state.messageList.advancedMessageListTargeting,
      isFetchingSmsCost: state => state.message.isFetchingSmsCost,
      smsCostFailedToFetch: state => state.message.smsCostFailedToFetch,
    }),
    ...mapGetters({
      scratchSimpleMessageCampaignOid: 'message/scratchSimpleMessageCampaignOid',
      scratchSimpleMessageEventOid: 'message/scratchSimpleMessageEventOid',
      currentCampaignIsActive: 'campaign/currentCampaignIsActive',
      nonExistingTagsInSimpleMessageErrorCopy: 'message/nonExistingTagsInSimpleMessageErrorCopy',
      smsMessagePreviewSegmentsCount: 'message/smsMessagePreviewSegmentsCount',
      smsMessagePreviewLength: 'message/smsMessagePreviewLength',
      currentSelectedMessageUiStatus: 'message/currentSelectedMessageUiStatus',
      currentMessageListCanBeSchedued: 'messageList/currentMessageListCanBeSchedued',
      currentMessageListIsForAllCampaignRegistrants: 'messageList/currentMessageListIsForAllCampaignRegistrants',
      currentMessageListIsForCampaignTier: 'messageList/currentMessageListIsForCampaignTier',
      getCurrentFilterExpression: 'messageList/getCurrentFilterExpression',
    }),
    // Because this is a long message, we're just putting the whole string into a computed prop.
    cantMessageContactsTooltipMessage() {
      return `Contacts within a list must be opted in to receive SMS messages. You are able to mass opt in contacts to
      SMS on the audience tab. Also ensure contacts you have added have valid mobile numbers.`;
    },
    // We don't want to show the adv targeting module on campaign sends, if no message list is selected
    // or while a draft message is loading
    showAdvancedTargetingModule() {
      if (!this.arEnableMessageAdvancedTargeting) return false;
      if (this.currentMessageListIsForCampaignTier) return false;
      if (this.currentMessageListIsForAllCampaignRegistrants) return false;
      if (this.messageOid && !this.currentSelectedMessage) return false;
      if (this.isFetchingMessage) return false;
      if (!this.scratchSimpleMessage) return false;
      if (!this.scratchSimpleMessage.meta.messageListOid) return false;
      return true;
    },
    dynamicTags() {
      return this.scratchSimpleMessage.meta.dynamicTagHeaders || [];
    },
    dynamicTagSelectItems() {
      if (this.dynamicTags.length === 0) {
        return [
          {
            type: 'action',
            name: 'Add dynamic tags',
            value: 'addDynamicTags'
          },
        ];
      } else {
        return [
          ...this.dynamicTags.map(tag => ({
            typography: {
              weight: 'bold',
            },
            name: tag,
            value: tag,
          })),
          {
            type: 'divider',
          },
          {
            type: 'action',
            name: 'Add dynamic tags',
            value: 'addDynamicTags'
          },
        ];
      }
    },
    dynamicTagsExist() {
      return this.scratchSimpleMessage.meta.dynamicTagHeaders && this.scratchSimpleMessage.meta.dynamicTagHeaders.length > 0;
    },
    isListValid() {
      return (this.noContacts && !!this.selectedFacebookMessengerPageId && !this.isFetchingFacebookPages && this.hasFetchedInitialFacebookPages) || this.currentMessageList?.statsSnapshot?.total === 0
    },
  },

  watch: {
    scratchSimpleMessage: {
      handler(val) {
        if (val.meta.presentation.timeZone || val.scheduledAt) {
          this.enableSchedule = true;
        }
      },
      immediate: true,
    },
    messageList: {
      handler(val) {
        if (val) {
          this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
            meta: {
              messageListOid: val.oid,
            }
          });
        }
      },
    },
  },

  created() {
    this.$validator.dictionary.merge({
      en: {
        custom: {
          messageListoid: {
            required: 'Please select a list.',
          },
        },
      },
    });

    this.$validator.extend('afterNow', {
      getMessage: field => `Scheduled time is invalid - time must be in the future`,
      validate: value => {
        return moment(value).isAfter(new Date());
      },
    });
    this.$validator.extend('after5MinFromNow', {
      getMessage: field => `Scheduled time is invalid - must be more than 5 minutes from now`,
      validate: value => {
        return moment(value).isAfter(moment().add(5, 'minutes'));
      },
    });

    this.$validator.localize('en', {
      custom: {
        scheduledAt: {
          required: () => 'Please enter scheduled time',
        },
        timeZone: {
          required: () => 'Please select a timezone',
        },
        messageBody: {
          max: () => 'Your message exceeds the maximum allowed character limit',
        },
      },
    });

  },

  methods: {
    ...mapActions([
      'messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT'
    ]),
    ...mapMutations([
      'message/REMOVE_DYNAMIC_TAGS_IN_SCRATCH_SIMPLE_MESSAGE',
      'message/PUT_SCRATCH_SIMPLE_MESSAGE',
      'message/SET_CSV_PREVIEW_CONTACTS',
      'messageList/SET_CURRENT_MESSAGE_LIST',
      'message/PUT_FILTERING_IN_SCRATCH_SIMPLE_MESSAGE',
    ]),
    formatCurrency(val) {
      return formatCurrency(val);
    },
    emitCostDecisionChangeEvent(val) {
      this.$emit('cost-decision-change', val);
    },
    clearScheduleTime() {
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: {
          presentation: {
            timeZone: null,
          },
        },
        scheduledAt: null,
      });
    },
    handleEnableScheduleToggle(toggle) {
      this.enableSchedule = toggle;
      if (!toggle) {
        this.clearScheduleTime();
      }
    },
    handleMessageBodyInput(val) {
      this.emitCostDecisionChangeEvent(false);
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: { messageBody: val },
      });
    },
    handleScheduledAtInput(val) {
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        scheduledAt: val,
      });
    },
    handleTimezoneInput(val) {
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: {
          presentation: {
            timeZone: val,
          },
        },
      });
    },
    handleMessageListSelect(messageList) {
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: {
          messageListOid: messageList.oid,
        },
      });
      this['message/REMOVE_DYNAMIC_TAGS_IN_SCRATCH_SIMPLE_MESSAGE']();
      this['messageList/SET_CURRENT_MESSAGE_LIST'](messageList);
      if (!this.currentMessageListCanBeSchedued) {
        this.enableSchedule = false;
        this.clearScheduleTime();
      }
    },
    handleDynamicTagsImport({ dynamicTagHeaders, dynamicTagRows, tagsResourceOid, tagMaxWidthMap }) {
      this['message/SET_CSV_PREVIEW_CONTACTS']({
        headers: dynamicTagHeaders,
        rows: dynamicTagRows,
      });
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: {
          dynamicTagHeaders,
          tagsResourceOid,
          tagMaxWidthMap,
        },
      });
      this.showImportDynamicTagsModal = false;
    },
    async handleInsertDynamicTagsSelect(item) {
      if (item.value === 'addDynamicTags') {
        this.showImportDynamicTagsModal = true;
      } else {
        this.$refs['simple-message-editor'].insertDynamicTag(item.value);
      }
    },

    // Used by parents to force a refresh of message lists, in the event that the selected list no longer exists
    triggerMessageListRefresh() {
      this.$refs['message-list-select'].fetchMessageLists();
    },

    handleAdvancedTargetingUpdate() {
      const targetingFilter = this.getCurrentFilterExpression;
      const useStatsSnapshot = this.advancedMessageListTargeting.type === 'all' ||
        !this.advancedMessageListTargeting.type ||
        !this.advancedMessageListTargeting.condition ||
        !this.advancedMessageListTargeting.values ||
        this.advancedMessageListTargeting.values.length === 0;

      this['message/PUT_FILTERING_IN_SCRATCH_SIMPLE_MESSAGE'](targetingFilter);

      if (useStatsSnapshot) {
        this.$emit('forceCostRecount');
      }

      this['messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT']({
        channel: 'sms',
        skipStatsSnapshot: !useStatsSnapshot
      });
    },

    handleTryFetchingCostAgain() {
      this.$emit('forceCostRecount');
    },
  },
};
</script>
<style lang="scss" scoped>
  .cost-container {
    padding: 15px;
    border-radius: 4px;
    background-color: #F1EDFE;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .cost {
      display: flex;
      flex-direction: row;
      align-items: center;

      .value {
        margin-right: 1em;
      }

      .tooltip {
        color: $purple500;
      }
    }

    &.sm-max {
      flex-direction: column;

      .cost {
        margin-top: 8px;
      }
    }
  }

  .schedule-input-group {
    .time-zone {
      width: calc(100% - 340px);
      margin-top: 0;
    }
    .date-time {
      width: 270px;
    }
    &.sm-max {
      flex-direction: column;

      .ar-field {
        width: 100%;
      }
      .time-zone {
        margin-top: 16px;
        margin-bottom: 0;
      }


      .divider {
        display:none;
      }
    }
  }

</style>
