<template>
<div v-if="item.config.event" class="card-sub-wrapper">
  <div class="card-sub-details-line"></div>
  <div class="sub-text-wrapper">
    <ar-text
      class="sub-text-event-name"
      size="14px"
      :text="item.config.event.name"
      weight="normal"
      multiple-lines
      :max-lines="1"
    />
    <ar-text
      class="sub-text-event-date"
      size="12px"
      :text="item.config.event['date-string']"
      weight="normal"
      multiple-lines
      :max-lines="1"
    />
  </div>
</div>
</template>
<script>
export default {
  name: 'PurchasedTicket',
  props: {
    item: {
      type: Object,
      default: null,
    }
  }
}
</script>
<style lang="scss" scoped>
.card-sub-wrapper {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  .card-sub-details-line {
    width: 7px;
    height: 11px;
    border-bottom: 1px solid $blueGrey500;
    border-left: 1px solid $blueGrey500;
    margin-left: 8px;
    margin-right: 10px;
  }

  .sub-text-wrapper {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    .sub-text-event-name {
      margin-bottom: 2px;
    }

    .sub-text-event-date {
      color: #9fa8b5;
    }
  }
}
</style>