export const automationCardType = (item) => {
  switch (item?.key) {
    case 'purchased.ticket-to-event':
      return 'purchasedTicket'
    case 'campaign.registered':
      return 'registersForCampaign'
    case 'send.sms':
      return 'sendSMS'
    case 'send.email':
      return 'sendEmail'
    case 'tag.add':
      return 'tagAdd'
    case 'if-else':
      return 'ifElse'
    case 'wait-until':
      return 'waitUntil'
    default:
      console.log('An unrecognised item or item key was received: ', item)
      return
  }
}