var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ar-datepicker"},[_c('ar-input',{class:[
      'u-width-100-percent',
      'u-height-100-percent',
      'ar-datepicker-input',
      _vm.isDatePickerOpened && 'focus' ],style:({
      maxHeight: '100%',
    }),attrs:{"value":_vm.formattedDate,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":""},nativeOn:{"click":function($event){return _vm.toggleDatePicker($event)}}}),_vm._v(" "),(_vm.hasArrow)?_c('div',{staticClass:"datepicker-arrow"},[_c('ar-icon',{attrs:{"name":"arrow","rotate":_vm.isDatePickerOpened ? 180 : 0},on:{"click":_vm.toggleDatePicker}})],1):_vm._e(),_vm._v(" "),_c('div',{ref:"invisible-modal",style:({
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100vh',
      zIndex: this.$arStyle.zIndex.globalHighest,
      display: _vm.isDatePickerOpened ? 'block' : 'none',
    }),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleInvisibleModalClick($event)}}}),_vm._v(" "),_c('div',{ref:"datepicker-wrapper",style:({
      display: 'inline-block',
      position: 'fixed',
      top: _vm.datePickerWrapperPosition.top,
      bottom: _vm.datePickerWrapperPosition.bottom,
      left: _vm.datePickerWrapperPosition.left,
      width: _vm.datePickerWrapperPosition.width,
      height: _vm.isDatePickerOpened ? _vm.datePickerWrapperPosition.height : '0',
      opacity: _vm.isDatePickerOpened ? '1' : '0.7',
      overflow: 'hidden',
      transition: 'height 0.2s, opacity 0.2s',
      zIndex: this.$arStyle.zIndex.globalHighest + 1,
    })},[_c('Datepicker',{ref:"datepicker",attrs:{"value":_vm.realDateForDatePicker,"inline":""},on:{"selected":_vm.handleDateSelect}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }