<template>
  <div class="loyalty-tier-constraint">
    <div class="loyalty-tier-dropdown-container">
      <am2-link-button-dropdown
        :item-key="constraintValue"
        :items="predicates"
        has-search
        @select="(item) => handleConstraintDropdownSelect(item)"
        @searchStringChange="handleConstraintSearch"
        :dropdown-style="{
          padding: '5px 0',
          width: '232px',
      }"
      />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'LoyaltyTierConstraint',

    props: {
      constraintValue: {
        type: [String, Number],
        default: 'all',
      },
      loyaltyMembershipOids: {
        type: Array,
        default: () => [],
      }
    },

    data() {
      return {
        candidateItems: [],
        isFetchingItems: false,
        searchString: null,
      }
    },

    computed: {
      ...mapState({
        auth: state => state.auth,
      }),
      predicates() {
        const anyTier = {
          name: "Any tier",
          key: "all",
        };
        const anyPaidTier = {
          name: "Any paid tier",
          key: "all_paid",
        };
        const anyFreeTier = {
          name: "Any free tier",
          key: "all_free"
        };
        return [
          anyTier,
          anyPaidTier,
          anyFreeTier,
          ...(this.candidateItems || []).map(item => {
            return {
              name: item.title,
              key: item.oid,
            }
          })
        ].filter( item => {
          return !this.searchString || item.name.toLowerCase().includes(this.searchString.toLowerCase())
        });
      },
    },

    watch: {
      // TODO - TEST THAT THIS DOESNT CAUSE INFINITE LOOPS WITH MULTIPLE PATREON MEMBERSHIPS!!!
      loyaltyMembershipOids: {
        handler(newVal, oldVal) {
          if (newVal[0] !== oldVal[0]) this.resetSelection();
        },
        immediate: false,
      },
    },


    created() {
      this.fetchCandidateItems();
    },

    methods: {
      resetSelection() {
        this.$emit('constraintUpdate', {
          name: "Any tier",
          key: "all",
        });
        this.fetchCandidateItems();
      },
      handleConstraintDropdownSelect(item) {
        const data = {
          key: "true_to_all",
          values: [item.key],
        };
        this.$emit('constraintUpdate', data);
      },

      handleConstraintSearch(val) {
        this.searchString = val;
      },

      async doAxiosGet(url) {
        return await this.$axios.get(url);
      },

      async fetchCandidateItems() {
        const { promoterOid } = this.auth.account;
        let url = `/promoter/${promoterOid}/promoter-integration-loyalty-program-tier`;
        if (!!this.loyaltyMembershipOids && this.loyaltyMembershipOids.length > 0 && this.loyaltyMembershipOids.length[0] !== "all") {
          const filter = encodeURIComponent(`loyaltyProgramOid = ${this.loyaltyMembershipOids[0]}`);
          url = `${url}?$filter=${filter}`;
        }
        const { data } = await this.doAxiosGet(url);
        this.candidateItems = data || [];
      },
    }


  };
</script>

<style lang="scss" scoped>
  .loyalty-tier-constraint {
    width: 100%;
    display: flex;
    flex-direction: column;

    .loyalty-tier-dropdown-container {
      justify-content: flex-end;
      display: flex;
    }


    .options-wrapper {
      margin-top: 24px;
      position: relative;

      .loading-bubble-wrapper {
        display: flex;
        justify-content: center;
      }

      .options-content {
        transition: all 0.3s;
        opacity: 1;

        &.disabled {
          opacity: 0.3;
          pointer-events: none;
        }

        .checkbox-section {
          display: flex;
          margin-bottom: 9px;
          align-items: flex-start;

          .checkbox {
            width: 20px;
          }

          .checkbox-label-section {
            cursor: pointer;
            width: calc(100% - 28px);
            margin-left: 8px;
            .checkbox-label-first-line {
              margin-top: 4px;
              display: flex;
              justify-content: space-between;
            }
          }
        }

        .show-all {
          display: inline-block;
          margin-top: 7px;
          font-size: 12px;
          line-height: 1em;
          color: $purple500;
          letter-spacing: 0;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
</style>
