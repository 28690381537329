<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      class="add-payment-method-modal"
      :is-open="isShow"
      hide-header
      hide-footer
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleClose"
    >
      <div slot="body" class="body">
        <ar-text
          size="md"
          text="Add Payment Method"
          weight="bold"
          align="center"
          class="u-margin-bottom-10"
        />
        <am2-stripe-element-form
          ref="billing-form"
          :loading="isCreatingPaymentSource"
          @submit="handlePaymentMethodSubmit"
        />
        <div
          v-if="arEnvironment === 'staging' || arEnvironment === 'development'"
          :style="{
            textAlign: 'center'
        }" >
          <ar-text
            size="sm"
            text="Test Cards"
            weight="bold"
            align="center"
            class="u-margin-y-4"
          />
          <ar-simple-button
            iconName="payment-visa"
            :iconProps="{
              name: 'payment-visa',
              height: '24px',
            }"
            text="Credit"
            type="grey"
            outlined
            :style="{padding: '0 8px'}"
            @click="() => { handleDummyCardCreation('visa') }"
          />
          <ar-simple-button
            iconName="payment-visa"
            :iconProps="{
              name: 'payment-visa',
              height: '24px',
            }"
            text="Debit"
            type="grey"
            outlined
            :style="{padding: '0 8px'}"
            @click="() => { handleDummyCardCreation('visa-debit') }"
          />

          <ar-simple-button
            iconName="payment-mastercard"
            :iconProps="{
              name: 'payment-mastercard',
              height: '24px',
            }"
            text="Credit"
            type="grey"
            outlined
            :style="{padding: '0 8px'}"
            @click="() => { handleDummyCardCreation('mastercard') }"
          />
          <ar-simple-button
            iconName="payment-mastercard"
            :iconProps="{
              name: 'payment-mastercard',
              height: '24px',
            }"
            text="Debit"
            type="grey"
            outlined
            :style="{padding: '0 8px'}"
            @click="() => { handleDummyCardCreation('mastercard-debit') }"
          />

          <ar-simple-button
            iconName="payment-american-express"
            :iconProps="{
              name: 'payment-american-express',
              height: '24px',
            }"
            type="grey"
            outlined
            text="Credit"
            :style="{padding: '0 8px'}"
            @click="() => { handleDummyCardCreation('american-express') }"
          />

          <ar-text
            size="sm"
            text="Test Errors"
            weight="bold"
            align="center"
            class="u-margin-y-4"
          />
          <ar-simple-button
            iconName="notification-general"
            :iconProps="{
              name: 'notification-general',
              height: '18px',
            }"
            type="grey"
            outlined
            text="Declined"
            :style="{padding: '0 8px'}"
            @click="() => { handleDummyCardCreation('declined') }"
          />
          <ar-simple-button
            iconName="notification-general"
            :iconProps="{
              name: 'notification-general',
              height: '18px',
            }"
            type="grey"
            outlined
            text="Fraud"
            :style="{padding: '0 8px'}"
            @click="() => { handleDummyCardCreation('fraud') }"
          />
          <ar-simple-button
            iconName="notification-general"
            :iconProps="{
              name: 'notification-general',
              height: '18px',
            }"
            type="grey"
            outlined
            text="Expired"
            :style="{padding: '0 8px'}"
            @click="() => { handleDummyCardCreation('expired') }"
          />
          <ar-simple-button
            iconName="notification-general"
            :iconProps="{
              name: 'notification-general',
              height: '18px',
            }"
            type="grey"
            outlined
            text="Bad Number"
            :style="{padding: '0 8px'}"
            @click="() => { handleDummyCardCreation('bad-number') }"
          />
          <ar-simple-button
            iconName="notification-general"
            :iconProps="{
              name: 'notification-general',
              height: '18px',
            }"
            type="grey"
            outlined
            text="Bad CVC"
            :style="{padding: '0 8px'}"
            @click="() => { handleDummyCardCreation('bad-cvc') }"
          />
        </div>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { generateRandomName, returnTestCardNumber } from '@/utils/testing';

export default {
  name: 'AddPaymentMethodModal',

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      arEnvironment: process.env.arEnvironment,
      paymentSource: null,
    };
  },
  computed: {
    ...mapState({
      isCreatingPaymentSource: state => state.payment.isCreatingPaymentSource,
    }),
  },

  methods: {
    ...mapActions([
      'payment/CREATE_PAYMENT_SOURCE'
    ]),

    getBillingFormInstance() {
      return this.$refs['billing-form'];
    },

    handleClose() {
      this.$emit('cancel')
    },

    handleDummyCardCreation(brand) {
      if(this.arEnvironment !== 'staging' && this.arEnvironment !== 'development') return;
      this.$refs['billing-form'].prefillName(generateRandomName());
      let cardNumber = returnTestCardNumber(brand);

      navigator.permissions.query({name: "clipboard-write"}).then( result => {
        if (result.state == "granted" || result.state == "prompt") {
          navigator.clipboard.writeText(cardNumber);
          this.$arNotification.push({
            type: 'success',
            message: `Card Number copied to clipboard - ${cardNumber}`,
          });
        } else {
          this.$arNotification.push({
            type: 'error',
            message: 'Failed to copy card number to clipboard',
          });
        }
      })
    },

    async handlePaymentMethodSubmit(paymentSource) {
      const newPaymentSource = await this['payment/CREATE_PAYMENT_SOURCE'](paymentSource);
      if (newPaymentSource) {
        this.$emit('create', newPaymentSource);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.add-payment-method-modal {
  .body {
    padding: 30px;
  }
}
</style>
