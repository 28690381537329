var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"dropdown-binder",attrs:{"tabindex":"0"},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleDropdown(false)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleItemEnter($event)}],"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }return _vm.onArrowDown($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }return _vm.onArrowUp($event)}]}},[_c('div',{staticClass:"u-display-flex u-align-items-center"},[_c(_vm.buttonName,_vm._b({ref:"binded-component",tag:"component",nativeOn:{"click":function($event){return _vm.handleButtonClick($event)}}},'component',_vm.buttonPropsDecorated,false))],1),_vm._v(" "),_c('div',{ref:"invisible-modal",style:({
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100vh',
      zIndex: this.$arStyle.zIndex.globalHighest,
      display: _vm.isOpen ? 'block' : 'none',
    }),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleInvisibleModalClick($event)}}}),_vm._v(" "),_c('div',{ref:"dropdown-wrapper",style:({
      display: 'inline-block',
      position: 'fixed',
      top: _vm.dialogBoxPosition.top,
      left: _vm.dialogBoxPosition.left,
      right: _vm.dialogBoxPosition.right,
      bottom: _vm.dialogBoxPosition.bottom,
      height: _vm.isOpen ? _vm.dialogBoxPosition.height : '0',
      opacity: _vm.isOpen ? '1' : '0',
      overflow: 'hidden',
      zIndex: this.$arStyle.zIndex.globalHighest + 1,
    })},[_c('Dropdown',_vm._b({ref:"dropdown",style:(_vm.dropdownStyle),attrs:{"is-open":_vm.isOpen,"divider-style":_vm.dividerStyle,"hasFixedFooter":_vm.hasFixedFooter},on:{"select":_vm.handleItemSelect,"tabSelect":_vm.handleTabSelect,"searchStringChange":_vm.handleSearchStringChange,"controlClick":_vm.handleControlClick,"close":function($event){return _vm.toggleDropdown(false)},"fixedFooterClick":function () { _vm.$emit('fixedFooterClick') }},model:{value:(_vm.arrowCounter),callback:function ($$v) {_vm.arrowCounter=$$v},expression:"arrowCounter"}},'Dropdown',_vm.dropdownPropsDecorated,false))],1)])}
var staticRenderFns = []

export { render, staticRenderFns }