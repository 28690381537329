var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-table',{ref:"table",staticClass:"subscribers-table",attrs:{"heads":_vm.decoratedHead,"rows":_vm.body,"loading":_vm.loading,"empty-text":_vm.emptyText,"enable-row-click":_vm.customerProfilesEnabled,"has-sticky-header":_vm.hasStickyHeader},on:{"sortBy":_vm.handleSortByChange,"rowClick":_vm.handleUserClick},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var ref_data = ref.data;
var avatar = ref_data.avatar;
var name = ref_data.name;
var firstName = ref_data.firstName;
var lastName = ref_data.lastName;
var emailAddress = ref_data.emailAddress;
var fanOid = ref_data.fanOid;
var index = ref.index;
var active = ref.active;
return _c('div',{staticClass:"cell"},[_c('ar-avatar',{ref:"avatar",staticClass:"avatar",style:({
        marginRight: '10px',
        cursor: _vm.customerProfilesEnabled ? 'pointer' : null,
      }),attrs:{"image-path":avatar,"name":firstName || lastName ? (firstName + " " + lastName) : emailAddress,"color":_vm.audienceSelection.partlySelectedAudienceMap[fanOid] || active ? 'white' : 'grey'}}),_vm._v(" "),_c('ar-text',{staticClass:"name-text",style:({
        cursor: _vm.customerProfilesEnabled ? 'pointer' : null,
      }),attrs:{"size":"xs","text":_vm.determineName(name, firstName, lastName, emailAddress)}})],1)}},{key:"channel",fn:function(ref){
      var ref_data = ref.data;
      var optedIntoEmail = ref_data.optedIntoEmail;
      var optedIntoSms = ref_data.optedIntoSms;
      var optedIntoFacebookMessenger = ref_data.optedIntoFacebookMessenger;
return _c('div',{staticClass:"cell channel"},[(optedIntoEmail)?_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
        content: 'Subscribed to Email'
      }),expression:"{\n        content: 'Subscribed to Email'\n      }",modifiers:{"top":true}}],staticClass:"icon",attrs:{"name":"email","width":"18.67px","color":_vm.$arStyle.color.email}}):_vm._e(),_vm._v(" "),(optedIntoSms)?_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
        content: 'Subscribed to SMS'
      }),expression:"{\n        content: 'Subscribed to SMS'\n      }",modifiers:{"top":true}}],staticClass:"icon",attrs:{"name":"sms","width":"18px","color":_vm.$arStyle.color.sms}}):_vm._e(),_vm._v(" "),(optedIntoFacebookMessenger)?_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
        content: 'Subscribed to Messenger'
      }),expression:"{\n        content: 'Subscribed to Messenger'\n      }",modifiers:{"top":true}}],staticClass:"icon",attrs:{"name":"messenger","width":"20px","color":_vm.$arStyle.color.messenger}}):_vm._e()],1)}},{key:"lastMessageTime",fn:function(ref){
      var lastMessageTime = ref.data.lastMessageTime;
return _c('div',{staticClass:"cell"},[(lastMessageTime)?_c('ar-text',{attrs:{"size":"xs","text":_vm.toDateString(lastMessageTime)}}):_c('div',{staticClass:"empty-dash"})],1)}},{key:"emailAddress",fn:function(ref){
      var data = ref.data;
      var head = ref.head;
return _c('div',{staticClass:"cell"},[_c('ar-text',{attrs:{"size":"xs","text":data[head.key]}})],1)}},{key:"text",fn:function(ref){
      var data = ref.data;
      var head = ref.head;
return _c('div',{staticClass:"cell"},[_c('ar-text',{attrs:{"size":"xs","text":data[head.key]}})],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }