var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-card-container',{staticClass:"am2-switch-field-card"},[_c('div',{class:[
      'u-display-flex' ],style:({
      flexFlow: _vm.size === 'small' ? 'column-reverse' : null,
    })},[_c('div',{class:[
        'u-flex-grow-1',
        'u-padding-right-3' ]},[_c('div',{staticClass:"u-display-flex u-flex-flow-row",style:({
          marginTop: _vm.titleMarginTop,
        })},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.blueGrey800,
          }),attrs:{"size":"xs","text":_vm.title,"weight":"bold"}}),_vm._v(" "),(_vm.hasEditLink && _vm.isToggledUp)?_c('ar-link-button',{staticClass:"u-margin-left-3",attrs:{"text":_vm.showTextField ? 'hide' : 'edit'},on:{"click":_vm.toggleShowTextField}}):_vm._e()],1),_vm._v(" "),_c('ar-text',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.subtitle),expression:"!!subtitle"}],staticClass:"u-margin-top-1",style:({
          color: _vm.$arStyle.color.blueGrey700,
        }),attrs:{"size":"xs","text":_vm.subtitle,"multiple-lines":""}})],1),_vm._v(" "),_c('am2-switch',{class:[
        _vm.size === 'small' ? 'u-margin-bottom-3' : null ],attrs:{"disabled":_vm.disabled,"value":_vm.isToggledUp},on:{"input":_vm.handleSwitch}})],1),_vm._v(" "),(_vm.displayDefaultSlot)?_c('div',{staticClass:"u-margin-top-3"},[_vm._t("default")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }