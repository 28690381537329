<template>
  <EditableFrame
    class="add-tag-wrapper"
    title="Edit Action"
    :subtitle="item.name"
    has-save-button
    @close="$emit('close')"
    @save="handleSave"
  >
    <ar-text
      class="tag-list-heading"
      size="13px"
      text="LIST"
      weight="bold"
      multiple-lines
      :max-lines="1"
      letter-spacing="1px"
    />
    <TagsDropdown v-model="selectedTag" @open="(val) => $emit('dropdown-open', val)" />
  </EditableFrame>
</template>
<script>
import TagsDropdown from './TagsDropdown.vue'
import EditableFrame from '../utils/EditableFrame.vue'
import { mapActions } from 'vuex'
export default {
  name: 'TagAdd',
  components: {
    TagsDropdown,
    EditableFrame,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selectedTag: null
    }
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    }
  },
  methods: {
    ...mapActions({
      updateActionConfig: 'automation/UPDATE_ACTION_CONFIG'
    }),
    handleSave() {
      //TODO: validate not null and block/add inactive state to save button
      let contextData = {
        config: {
          'tag-oid': this.selectedTag.oid
        },
        meta: {
          name: this.selectedTag.name,
        }
      }

      this.updateActionConfig({id: this.item.id, data: contextData})
      this.$emit('close')
    },
    setItemVals() {
      if (this.item.config?.['tag-oid']) {
        this.selectedTag = {
          id: this.item.config?.['tag-oid'],
          name: this.item.meta?.name
        }
      } else {
        this.selectedTag = null
      }
    }
  },
  mounted() {
    this.setItemVals()
  }
}
</script>
<style lang="scss" scoped>
.add-tag-wrapper {
  width: 100%;
  height: 100%;
  
  .tag-list-heading {
    margin-bottom: 14px;
  }
}
</style>