<template>
  <main class="campaign-view">
    <am2-hero-skeleton
      v-if="isFetchingCampaign"
      type="hero"
      back-path="/campaigns"
      is-campaigns
    />
    <am2-hero
      v-else-if="currentCampaign"
      :image="campaignImage"
      :title="currentCampaign.name"
      :type="currentCampaign.type"
      :timeframe="campaignTimeframe"
      :url-slug="currentCampaign.urlSlug"
      :event-oid="currentCampaign.event.oid"
      :event-name="currentCampaign.event.name"
      back-path="/campaigns"
      is-campaigns
    />

    <am2-navbar :menu="menu">
      <div slot="controls">
        <am2-loading-section
          v-if="isFetchingCampaign"
          class="buttons-section"
          :style="{
            width: '200px',
            height: '50px',
          }"
        />
        <div
          v-else
          class="buttons-section"
        >
          <ar-simple-button
            text="View Campaign"
            icon-name="preview"
            icon-distance="13px"
            :icon-props="{
              color: $arStyle.color.blueGrey700,
            }"
            type="grey"
            @click="viewCampaign(currentCampaign.urlSlug)"
          />
          <am2-expand-icon-button
            class="campaign-button"
            icon-name="result-share"
            :icon-size="{ width: '20px' }"
            :text="$arMediaQuery.pageContent.minWidth('sm') ? 'Share Results' : ''"
            @click="shareCampaign"
          />
          <am2-split-dropdown
            text="New Message"
            class="campaign-button"
            toggle-dropdown-on-button-click
            :items="messageButtonOptions"
            icon-name="dialog"
            icon-distance="12px"
            :style="{
              width: '196px',
            }"
            @select="(item) => item.action()"
          />
          <am2-campaign-dropdown
            class="campaign-button"
            kind="expand-icon-button"
            :preventExpand="!$arMediaQuery.pageContent.minWidth('sm')"
            :campaign="currentCampaign"
            @delete="handleCampaignDelect"
          />
        </div>
      </div>
    </am2-navbar>

    <nuxt-child
      :class="['child-content', $arMediaQuery.pageContent.minWidth('md') && 'md-min' ]"
      :campaign="currentCampaign"
    />
  </main>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import checkoutPageAccessibility from '@/mixins/checkoutPageAccessibility';
import { campaignBaseUri, findFirstError, campaignImage } from '@/utils/campaign';
import moment from 'moment';

export default {
  layout: 'default',
  mixins: [
    checkoutPageAccessibility({ featureKeys: ['campaigns'], featureName: 'Campaigns' }),
  ],
  scrollToTop: true,

  computed: {
    ...mapState({
      currentCampaign: state => state.campaign.currentCampaign,
      isFetchingCampaign: state => state.campaign.isFetchingCampaign,
    }),
    campaignOid() {
      return this.$route.params.oid || null;
    },

    messageButtonOptions() {
      const options = [];
      const emailEnabled = true;

      if (emailEnabled) {
        options.push({
          name: 'Email',
          icon: {
            name: 'email',
            color: this.$arStyle.color.email,
            height: '14px',
            width: '19px',
            wrapperStyle: {
              width: '19px',
              marginRight: '12px',
            }
          },
          action: () => this.handleNewEmailMessageClick(),
        });
      }

      options.push({
        name: 'SMS',
        icon: {
          name: 'sms',
          color: this.$arStyle.color.sms,
          height: '19px',
          width: '18px',
          wrapperStyle: {
            width: '19px',
            marginRight: '12px',
          }
        },
        action: () => this.handleNewSmsMessageClick(),
      });

      return options;
    },

    campaignImage() {
      if (!this.currentCampaign) {
        return null;
      }
      return campaignImage(this.currentCampaign);
    },

    menu() {
      return {
        dashboard: {
          name: 'Dashboard',
          to: `/campaigns/${this.campaignOid}/view/dashboard`,
          featureKeys: null,
        },
        registrations: {
          name: 'Registrations',
          to: `/campaigns/${this.campaignOid}/view/registrations`,
          featureKeys: null,
        },
        insights: {
          name: 'Insights',
          to: `/campaigns/${this.campaignOid}/view/insights`,
          featureKeys: ['insights'],
        }
      };
    },

    dropdown() {
      return {
        title: 'Settings',
        items: [
          {
            name: 'Edit campaign',
            action: this.openCampaignEdit
          },
          {
            name: 'Edit event',
            action: this.openEventEdit
          },
          {
            name: 'Duplicate',
            action: this.duplicateCampaign
          },
          {
            name: 'Delete',
            action: this.showCampaignDelete
          }
        ]
      };
    },

    campaignTimeframe() {
      return `${moment(this.currentCampaign.startDate).format('ddd MMM Do - ')} ${moment(this.currentCampaign.endDate).format(
        'ddd MMM Do, YYYY'
      )}`;
    }
  },

  async mounted() {
    this['campaign/FETCH_CAMPAIGN_REGISTRATION_STATS'](this.campaignOid);
    const succeed = await this['campaign/FETCH_CURRENT_CAMPAIGN'](this.campaignOid);
    if (!succeed) {
      this.$router.push({
        path: `/campaigns`
      });
    }
  },

  beforeDestroy() {
    this['campaign/RESET_CURRENT_CAMPAIGN']();
  },

  methods: {
    ...mapActions([
      'campaign/FETCH_CURRENT_CAMPAIGN',
      'campaign/FETCH_CAMPAIGN_REGISTRATION_STATS',
      'CREATE_CAMPAIGN',
      'CLONE_CAMPAIGN',
      'UPDATE_CAMPAIGN',
      'DELETE_CAMPAIGN',
      'CREATE_SOCIAL_ACCOUNTS',
      'FETCH_SHARE_ANALYTICS_LINK',
    ]),
    ...mapMutations([
      'campaign/RESET_CURRENT_CAMPAIGN',
    ]),

    async handleNewEmailMessageClick() {
      if (this.$arMediaQuery.window.maxWidth('xs')) {
        const response = await this.SHOW_CONFIRM({
          messageHtml: `You are able to create and edit emails using your desktop or tablet device.`,
          hideConfirmButton: true,
          cancelButtonText: 'Back',
        });
      } else {
        this.$router.push({
          path: `/message-center/messages/email/new/basic`,
          query: {
            campaignOid: this.campaignOid
          },
        });
      }
    },
    handleNewSmsMessageClick() {
      this.$router.push({
        path: `/message-center/messages/sms/new`,
        query: {
          campaignOid: this.campaignOid
        },
      });
    },

    async duplicateCampaign() {
      const res = await this.CLONE_CAMPAIGN(this.campaignOid);
      this.$router.push({
        path: `/campaigns/${res.oid}/view/dashboard`
      });
    },

    async deleteCampaign() {
      this.DELETE_CAMPAIGN(this.campaignOid).then(() => {
        this.$router.push({
          path: `/campaigns`
        });
      });
    },

    viewCampaign(urlSlug) {
      const destination = `${campaignBaseUri(this.currentCampaign.type)}${urlSlug}`;
      window.open(destination, '_blank');
    },

    async shareCampaign() {
      const shareLink = await this.FETCH_SHARE_ANALYTICS_LINK(this.campaignOid);
      window.open(shareLink, '_blank');
    },

    openCampaignEdit() {
      return this.$router.push({ path: `/campaigns/${this.campaignOid}/edit/details` });
    },

    openEventEdit() {
      return this.$router.push({ path: `/events/${this.currentCampaign.eventOid}/edit` })
    },

    showCampaignDelete() {
      this.showCampaignDeleteDialog = true;
    },

    hideCampaignDelete() {
      this.showCampaignDeleteDialog = false;
    },

    handleCampaignDelect() {
      this.$router.push({
        path: `/campaigns`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign-view {
  .hero-loading {
    height: 320px;
    background: $purple500;
  }
  .buttons-section {
    display: inline-flex;
    align-items: center;
    .campaign-button {
      margin-left: 7px;
    }
  }

  .child-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px 10px;

    &.md-min {
      padding: 30px;
    }
  }
}
</style>

