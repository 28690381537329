<template>
  <div class="actions-wrapper">
    <template v-for="(contextType, actionType) in options">
      <div class="actions-context-wrapper" :key="randomisedKey(actionType)">
        <div class="actions-heading-wrapper">
          <div 
            v-html="require(`~/assets/icons/${contextType.icon}.svg?raw`)"
            :class="`sidebar-icon ${contextType.icon}-icon`"
          />
          <ar-text
            class="sidebar-type-heading"
            size="13px"
            :text="`${contextType.text.toUpperCase()}`"
            weight="bold"
            multiple-lines
            :max-lines="1"
          />
        </div>
        <div class="draggable-wrapper">
          <template v-for="action in contextType.children">
            <div draggable @dragstart="(e) => startHandle(action, e)" class="action-item-wrapper sidebar-item" :key="randomisedKey(action.block.key)" :data-attr="action.block.type"  :id="addRandomId(action)">
              <div :id="action.key" class="action-draggable">
                <ar-text
                  class="action-item-text"
                  size="14px"
                  :text="action.block.name"
                  weight="normal"
                  multiple-lines
                  :max-lines="1"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { generateRandomString } from '@/utils/helpers'
export default {
  name: 'AutoActions',
  props: {
    options: {
      type: Object,
      default: null,
    }
  },
  methods: {
    startHandle(action, e) {
      e.dataTransfer.setData("box", JSON.stringify({...action, isFromSidebar: true}))
    },
    addRandomId(action) {
      let id = this.randomisedKey(action.block.key)
      action.block.id = id

      return id
    },
    randomisedKey(text) {
      return `${text}-${generateRandomString()}`
    }
  },
  mounted() {}
}
</script>
<style lang="scss" scoped>
$autoItemWidth: 325px;
.actions-context-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 100%;

  &:first-child {
    .actions-heading-wrapper {
      margin-top: 0;
    }
  }  

  .actions-heading-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 12px;
    margin-top: 20px;    

    .sidebar-icon {
      margin-right: 8px;
    }
  }

  .draggable-wrapper {
    width: 100%;

    .action-item-wrapper {
      width: 100%;
      position: relative;
      margin-bottom: 8px;
      border-radius: 3px;
      border: solid 0.2px rgba(194, 194, 194, 0.3);
      background-color: white;
      border-left: 6px solid $green500;
      padding: 14px 22px;
      cursor: pointer;
      width: 100%;

      .action-draggable {
        width: 100%;

        .action-item-text {
          pointer-events: none;
        }
      }
    }
  }
}
</style>
</script>