<template>
  <section
    :class="[
      'ar-elegant-tabs',
      layout && layout
    ]"
    @mouseover="handleMouseOverSection"
    @mouseleave="handleMouseLeaveSection"
  >
    <section
      v-for="(item, idx) of items"
      :key="idx"
      :class="['item', item.key === activeKey && 'active']"
      :style="{
        flexBasis: `${parseInt(100 / items.length, 10)}%`
      }"
      @click="handleItemSelect(item, idx)"
      :data-test-id="`elegant-tab-${item.name}`"
    >
      <ar-icon
        v-if="item.icon"
        :name="item.icon"
        :color="item.iconColor ? item.iconColor : 'black'"
        :height="item.iconHeight ? item.iconHeight : '26px'"
        :style="{
          marginRight: `8px`
        }"
      />
      <ar-text
        class="name"
        :text="item.name"
        size="xs"
        weight="bold"
      />
    </section>
  </section>
</template>

<script>
export default {
  name: 'ElegantTabs',

  props: {
    tabKey: {
      type: [String, Number],
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    layout: {
      type: String,
      default: 'compact'
    }
  },

  data() {
    return {
      activeKey: null,
      isHovered: false,
    };
  },

  methods: {
    handleMouseOverSection() {
      this.isHovered = true;
    },
    handleMouseLeaveSection() {
      this.isHovered = false;
    },
    handleItemSelect(item, idx) {
      this.activeKey = item.key;
      this.$emit('select', item, idx);
    },
  },

  watch: {
    tabKey: {
      handler(newtabKey) {
        this.activeKey = newtabKey;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-elegant-tabs {
  display: flex;
  border-radius: 6px;
  background-color: $skyBlueGrey400;
  padding: 3px;
  justify-content: space-between;

  &.wide {
    height: 40px;
  }

  &.compact {
    height: 29px;
  }

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;

    .name {
      color: $skyBlueGrey700;
    }

    &.active {
      background-color: white;
      box-shadow: 0 1px 4px rgba(black, 0.1);

      .name {
        color: $blueGrey800;
      }
    }
  }
}
</style>
