<template>
  <div :class="[
    'settings-view',
    $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
  ]">
    <div class="title-section">
      <ar-text
        size="lg"
        text="Account Settings"
      />
    </div>
    <am2-tabs
      :selected-tab-index="selectedPageItemIdx"
      :items="pageItems"
      :style="{
        marginTop: '22px',
      }"
      @select="handlePageItemSelect"
    />
    <ar-divider />
    <nuxt-child
      class="child-content"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  layout: 'default',
  data() {
    return {
      pageItems: [
        {
          title: 'Subscription',
          path: '/settings/subscriptions',
        },
        // {
        //   title: 'Profile',
        //   path: '/settings/profile',
        // },
        {
          title: 'Password',
          path: '/settings/password',
        },
        {
          title: 'Billing',
          path: '/settings/billing',
        },
        {
          title: 'Integrations',
          path: '/settings/integrations',
        },
      ],
    };
  },

  computed: {
    ...mapState({
      path: state => state.route.path,
    }),
    selectedPageItemIdx() {
      return this.pageItems.findIndex(item => item.path === this.path);
    },
  },

  async mounted() {},

  methods: {
    ...mapActions([]),

    handlePageItemSelect(tab) {
      this.$router.push(tab.path);
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-view {
  max-width: 1200px;
  padding: 50px 30px 30px;
  margin: 0 auto;

  &.md-max {
    padding: 50px 12px 12px;
  }

  .title-section {
    height: 50px;
  }
}
</style>
