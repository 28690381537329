<template>
  <!-- This page supports infinite scroll -->
  <section
    class="templates-page"
  >
    <am2-put-email-template-name-modal
      :opened="displayRenameEmailTemplateModal"
      :loading="isUpdatingSelectedEmailTemplate"
      header="Rename Email Template"
      button-copy="Save"
      :default-name="emailTemplateToRename && emailTemplateToRename.meta.name"
      :default-description="emailTemplateToRename && emailTemplateToRename.meta.description"
      @confirm="handleRenameEmailTempalteConfirm"
      @cancel="handleRenameEmailTempalteCancel"
    />
    <am2-put-email-template-name-modal
      :opened="displayNewEmailTemplateModal"
      button-copy="Next"
      @confirm="handleNewEmailTempalteConfirm"
      @cancel="handleNewEmailTempalteCancel"
    />
    <div
      :class="[
        'content',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      ]"
    >
      <am2-top
        :search="{
          action: handleTemplatesSearch,
          placeholder: 'Search templates',
          style: {
            width: searchBoxWidth,
          }
        }"
        :button="{
          text: $arMediaQuery.pageContent.minWidth('sm') ? 'New template' : 'Create',
          iconName: $arMediaQuery.pageContent.minWidth('sm') && 'circle-plus',
          iconDistance: '9px',
          iconProps: {
            color: 'white',
          },
          style: {
            width: $arMediaQuery.pageContent.minWidth('sm') ? '160px' : '112px',
          },
          action: handleCreateTemplateClick,
        }"
        :style="{
          alignItems: 'flex-start',
        }"
        title="Email templates"
      />
      <am2-tabs
        :selected-tab-index="selectedTemplateSourceIndex"
        @select="handleTemplateSourceSelect"
        :items="templateSourceItems"
        :style="{
          marginTop: '22px',
        }"
      />
      <ar-divider />

      <div
        v-if="hasNoDisplayedEmailTemplates"
        class="no-templates-section"
      >
        <am2-no-content-section
          v-if="searchString"
          header="No templates found"
          header-size="sm"
        />
        <am2-no-content-section
          v-else
          icon-name="ar-template-in-circle"
          header="Create an email template"
          button-text="New template"
          @buttonClick="handleCreateTemplateClick"
        />
      </div>
      <am2-no-content-section
        v-if="hasNoUnlayerTemplates"
        class="no-templates-section"
        icon-name="ar-template-in-circle"
        header="No templates found"
      />
      <div
        class="templates-wrapper"
      >
        <!-- Email Templates From Bucket Endpoint -->
        <am2-template-card
          v-for="emailTemplate of emailTemplates"
          :key="emailTemplate.oid"
          v-show="filterEmailTemplate(emailTemplate)"
          :class="[
            'template-card',
            $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
            $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
          ]"
          :name="emailTemplate.meta.name"
          :description="emailTemplate.meta.description"
          :template="emailTemplate.meta.template"
          :template-html="emailTemplate.meta.templateType === 'unlayer' ? getUnlayerHtml(emailTemplate.meta.template) : emailTemplate.meta.template"
          :type="emailTemplate.meta.templateType"
          :allowed-actions="['edit', 'rename', 'delete']"
          @edit="handleEmailTemplateEdit(emailTemplate)"
          @rename="handleEmailTemplateRename(emailTemplate)"
          @delete="handleEmailTemplateDelete(emailTemplate)"
          @click="handleEmailTemplateEdit(emailTemplate)"
        />
        <!-- Email Templates From Unlayer API -->
        <am2-template-card
          v-for="unlayerTemplate of unlayerTemplates"
          :key="unlayerTemplate.id"
          v-show="filterUnlayerEmailTemplate(unlayerTemplate)"
          :class="[
            'template-card',
            $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
            $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
          ]"
          :name="unlayerTemplate.name"
          :template="unlayerTemplate.design"
          :template-html="getUnlayerHtml(unlayerTemplate.design)"
          type="unlayer"
          :allowed-actions="['sendMessage', 'createTemplate']"
          @sendMessage="handleSendMessageFromUnlayerClick(unlayerTemplate)"
          @createTemplate="handleCreateTemplateFromUnlayerClick(unlayerTemplate)"
          @click="handleSendMessageFromUnlayerClick(unlayerTemplate)"
        />
        <am2-card-skeleton
          v-for="n in emailTemplatesSkeletonCardCount"
          :key="n"
          type="email-template"
          :class="[
            'template-card',
            $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
            $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
          ]"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Messages',
  layout: 'default',

  data: () => ({
    searchString: '',
    emailTemplateToRename: null,
    unlayerTemplateToCreate: null,
    displayNewEmailTemplateModal: false,
    displayRenameEmailTemplateModal: false,
    templateSourceItems: [
      {
        title: 'Your templates',
        value: 'bucket',
      },
      {
        title: 'Library',
        value: 'unlayer',
      },
    ],
    selectedTemplateSource: 'bucket',
  }),

  computed: {
    ...mapState({
      unlayerTemplates: state => state.emailTemplate.unlayerTemplates,
      isFetchingUnlayerTemplates: state => state.emailTemplate.isFetchingUnlayerTemplates,
      emailTemplates: state => state.emailTemplate.emailTemplates,
      isFetchingEmailTemplates: state => state.emailTemplate.isFetchingEmailTemplates,
      isUpdatingSelectedEmailTemplate: state => state.emailTemplate.isUpdatingSelectedEmailTemplate,
    }),
    ...mapGetters({
      getUnlayerHtml: 'emailTemplate/getUnlayerHtml',
    }),
    displayedEmailTemplatesCount() {
      return this.emailTemplates.filter(this.filterEmailTemplate).length;
    },
    displayedUnlayerTemplatesCount() {
      return this.unlayerTemplates.filter(this.filterUnlayerEmailTemplate).length;
    },
    hasNoDisplayedEmailTemplates() {
      if (this.selectedTemplateSource === 'bucket'
        && this.displayedEmailTemplatesCount === 0
        && !this.isFetchingEmailTemplates
      ) {
        return true;
      }
      return false;
    },
    hasNoUnlayerTemplates() {
      if (this.selectedTemplateSource === 'unlayer'
        && this.displayedUnlayerTemplatesCount === 0
        && !this.isFetchingUnlayerTemplates
      ) {
        return true;
      }
      return false;
    },
    selectedTemplateSourceIndex() {
      return this.templateSourceItems.findIndex(item => item.value === this.selectedTemplateSource);
    },
    emailTemplatesSkeletonCardCount() {
      if (this.selectedTemplateSource === 'bucket' && !this.isFetchingEmailTemplates) {
        return 0;
      }
      if (this.selectedTemplateSource === 'unlayer' && !this.isFetchingUnlayerTemplates) {
        return 0;
      }
      const emailTemplates = this.selectedTemplateSource === 'bucket' ? this.emailTemplates : this.unlayerTemplates;
      // Add number of skeleton cards to fill a new row the current row
      if (this.$arMediaQuery.pageContent.maxWidth('xs')) {
        return 3;
      } else if (this.$arMediaQuery.pageContent.maxWidth('md')) {
        return 6 + emailTemplates.length % 2;
      } else if (this.$arMediaQuery.pageContent.maxWidth('lg')) {
        return 9 + emailTemplates.length % 3;
      } else {
        return 12 + emailTemplates.length % 4;
      }
    },
    searchBoxWidth() {
      if (this.$arMediaQuery.pageContent.minWidth('md')) {
        return '330px';
      } else if (this.$arMediaQuery.pageContent.minWidth('sm')) {
        return 'calc(100% - 160px)'
      } else {
        return 'calc(100% - 80px)'
      }
    },
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'emailTemplate/DELETE_EMAIL_TEMPLATE',
      'emailTemplate/FETCH_ALL_EMAIL_TEMPLATES',
      'emailTemplate/FETCH_UNLAYER_TEMPLATES',
      'emailTemplate/UPDATE_EMAIL_TEMPLATE',
    ]),
    ...mapMutations([]),
    filterEmailTemplate(emailTemplate) {
      return this.selectedTemplateSource === 'bucket'
        && this.$arFuzzySearch(emailTemplate.meta.name, this.searchString || '');
    },
    filterUnlayerEmailTemplate(unlayerTemplate) {
      return this.selectedTemplateSource === 'unlayer'
        && this.$arFuzzySearch(unlayerTemplate.name, this.searchString || '');
    },
    displayTemplateSetupIsNotForMobileMessage() {
      this.SHOW_CONFIRM({
        messageHtml: `Template editing is only available on desktops and larger screens.`,
        confirmButtonText: 'Done',
        hideCancelButton: true,
      });
    },
    async askBeforeGoingToEmailMessageSetup() {
      if (this.$arMediaQuery.window.maxWidth('xs')) {
        const agreed = await this.SHOW_CONFIRM({
          messageHtml: `You are able to create and edit emails using your desktop or tablet device.`,
          hideConfirmButton: true,
          cancelButtonText: 'Back',
        });
        return agreed;
      } else {
        return true;
      }
    },
    handleTemplatesSearch(searchString) {
      this.searchString = searchString;
    },
    handleNewEmailTempalteConfirm({
      name,
      description,
    }) {
      this.$router.push({
        path: '/message-center/templates/new',
        query: {
          name,
          description,
          libraryTemplateId: this.unlayerTemplateToCreate?.id || null,
        },
      });
    },
    handleNewEmailTempalteCancel() {
      this.displayNewEmailTemplateModal = false;
      this.unlayerTemplateToCreate = null;
    },
    async handleRenameEmailTempalteConfirm({
      name,
      description,
    }) {
      const succeed = await this['emailTemplate/UPDATE_EMAIL_TEMPLATE']({
        scratchEmailTemplate: {
          oid: this.emailTemplateToRename.oid,
          meta: {
            name,
            description,
            template: this.emailTemplateToRename.meta.template,
            templateType: this.emailTemplateToRename.meta.templateType,
          },
        },
        displaySuccessMessage: false,
      });
      if (succeed) {
        this.displayRenameEmailTemplateModal = false;
      }
    },
    handleRenameEmailTempalteCancel() {
      this.displayRenameEmailTemplateModal = false;
    },
    async handleCreateTemplateClick() {
      if (this.$arMediaQuery.window.maxWidth('xs')) {
        this.displayTemplateSetupIsNotForMobileMessage();
        return;
      }
      this.displayNewEmailTemplateModal = true;
    },
    async handleEmailTemplateEdit(emailTemplate) {
      if (this.$arMediaQuery.window.maxWidth('xs')) {
        this.displayTemplateSetupIsNotForMobileMessage();
        return;
      }
      this.$router.push({
        path: `/message-center/templates/${emailTemplate.oid}/edit`,
      });
    },
    handleEmailTemplateRename(emailTemplate) {
      this.emailTemplateToRename = emailTemplate;
      this.displayRenameEmailTemplateModal = true;
    },
    async handleEmailTemplateDelete(emailTemplate) {
      const agreed = await this.SHOW_CONFIRM({
        messageHtml: `Are you sure you want to delete the email template<br><b>${emailTemplate.meta.name}</b>?`,
      });
      if (agreed) {
        this['emailTemplate/DELETE_EMAIL_TEMPLATE'](emailTemplate.oid);
      }
    },
    handleTemplateSourceSelect(templateTypeItem) {
      this.selectedTemplateSource = templateTypeItem.value;
    },
    async handleCreateTemplateFromUnlayerClick(unlayerTemplate) {
      if (this.$arMediaQuery.window.maxWidth('xs')) {
        this.displayTemplateSetupIsNotForMobileMessage();
        return;
      }
      this.unlayerTemplateToCreate = unlayerTemplate;
      this.displayNewEmailTemplateModal = true;
    },
    async handleSendMessageFromUnlayerClick(unlayerTemplate) {
      const agreed = await this.askBeforeGoingToEmailMessageSetup();
      if (agreed) {
        this.$router.push({
          path: `/message-center/messages/email/new/template`,
          query: {
            libraryTemplateId: unlayerTemplate.id,
          },
        });
      }
    },
  },

  created() {
    this['emailTemplate/FETCH_ALL_EMAIL_TEMPLATES']();
    this['emailTemplate/FETCH_UNLAYER_TEMPLATES']();
  },
};
</script>

<style lang="scss" scoped>
.templates-page {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  .content {
    width: 100%;
    margin-bottom: 50px;
    margin-top: 50px;

    &.sm-max {
      margin-top: 35px;
    }

    .no-templates-section {
      margin-top: 28px;
    }

    .templates-wrapper {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 28px;
      margin-right: -20px;

      .template-card {
        width: calc(25% - 20px);
        margin-right: 20px;
        margin-bottom: 20px;
        &.lg-max {
          width: calc(33.3% - 20px);
          margin-right: 20px;
        }

        &.md-max {
          width: calc(50% - 20px);
          margin-right: 20px;
        }

        &.xs-max {
          width: 100%;
        }
      }

    }
  }
}
</style>
