var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index-card",attrs:{"data-test-id":("campaign-card-" + _vm.campaignNameInKebabCase)}},[_c('am2-card-container',{class:['index-card--wrapper', _vm.showAs, _vm.cardSize, _vm.layout],attrs:{"layout":_vm.layout === 'customer' ? 'soft' : 'regular'}},[_c('nuxt-link',{staticClass:"card-link",attrs:{"to":{ path: ("/campaigns/" + (_vm.campaign.oid) + "/view/dashboard") }}},[(_vm.showAs === 'list')?_c('article',{staticClass:"card-list-item"},[_c('div',{class:[
          'card-image',
          _vm.layout,
          _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
          _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[_c('div',{staticClass:"campaign-list-image-wrapper"},[[(_vm.cardImage)?_c('div',{staticClass:"blurred-image-bg",style:(("background-image: url(" + _vm.cardImage + ")"))}):_vm._e()],_vm._v(" "),_c('div',{staticClass:"view-campaign-button-overlay"},[_c('button',{staticClass:"view-campaign-button"},[_vm._v("View results")])]),_vm._v(" "),(_vm.cardImage)?_c('img',{staticClass:"campaign-image",attrs:{"src":_vm.cardImage}}):_c('div',{staticClass:"campaign-tile-image-default"})],2)]),_vm._v(" "),_c('div',{class:[
            'card-content',
            _vm.cardSize,
            _vm.layout,
            _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
            _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'sm-max' ]},[_c('header',{class:[
            'card-detail',
            _vm.layout ]},[_c('ar-text',{staticClass:"card-list-heading",style:({
                marginBottom: '3px',
              }),attrs:{"size":"17px","text":_vm.title,"weight":"bold","multiple-lines":"","max-lines":1,"line-height":"25px"}}),_vm._v(" "),(_vm.campaign.event)?_c('ar-text',{staticClass:"card-list-location",attrs:{"size":"15px","text":_vm.campaign.event.location,"multiple-lines":"","max-lines":1}}):_vm._e()],1),_vm._v(" "),_c('div',{class:[
              'card-footer',
              _vm.cardSize,
              _vm.layout,
              _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
              _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
              _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
              _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'sm-max' ]},[(_vm.layout === 'promoter')?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.allRegistrations && _vm.uniqueViews),expression:"allRegistrations && uniqueViews"}],staticClass:"registrations-and-views"},[_c('div',{staticClass:"registrations"},[_c('ar-icon',{attrs:{"name":"user","height":"13px","color":_vm.$arStyle.color.blueGrey600}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.allRegistrations)+" Registrations")])],1),_vm._v(" "),_c('div',{staticClass:"views"},[_c('ar-icon',{staticClass:"views",attrs:{"name":"preview","width":"17px","color":_vm.$arStyle.color.blueGrey600}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.uniqueViews)+" Views")])],1)]):_vm._e(),_vm._v(" "),(_vm.layout === 'customer')?_c('div',{staticClass:"customer-info u-display-flex u-align-items-center"},[_c('am2-tag',{style:({
                  padding: "0 15px",
                }),attrs:{"text":(_vm.points + " " + (_vm.points == 1 ? "pt" : "pts")),"type":"green","shape":"round"}}),_vm._v(" "),(_vm.campaign.rewards.tiers.length > 0)?_c('ar-text',{style:({
                  marginLeft: '10px',
                  color: _vm.$arStyle.color.skyBlueGrey700,
                }),attrs:{"size":"xs","text":("" + (_vm.campaign.rewards.tiers[0].title))}}):_vm._e(),_vm._v(" "),(_vm.referrals > 0)?_c('div',{staticClass:"referrals"},[_c('ar-text',{style:({
                    marginLeft: '20px',
                    color: _vm.$arStyle.color.skyBlueGrey700,
                  }),attrs:{"size":"xs","text":(_vm.referrals + " " + (_vm.referrals == 1 ? "referral" : "referrals"))}})],1):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.layout === 'customer')?_c('div',{staticClass:"end"},[_c('Countdown',{staticClass:"countdown",attrs:{"date":_vm.campaign.endDate},on:{"campaign-ended":_vm.campaignEnded}})],1):_vm._e()]),_vm._v(" "),(_vm.isActive)?_c('am2-signal-tag',{staticClass:"signal-tag",style:({}),attrs:{"text":"Active"}}):_vm._e()],1)]):_c('article',{staticClass:"card-tile"},[_c('div',{staticClass:"campaign-card-image-wrapper"},[[(_vm.cardImage)?_c('div',{staticClass:"blurred-image-bg",style:(("background-image: url(" + _vm.cardImage + ")"))}):_vm._e()],_vm._v(" "),_c('div',{staticClass:"view-campaign-button-overlay"},[_c('button',{staticClass:"view-campaign-button"},[_vm._v("View results")])]),_vm._v(" "),(_vm.cardImage)?_c('img',{staticClass:"campaign-image",attrs:{"src":_vm.cardImage}}):_c('div',{staticClass:"campaign-image-default"})],2),_vm._v(" "),_c('div',{class:[
            'card-content',
            _vm.cardSize ]},[_c('header',{class:[
            'card-detail',
            _vm.layout ]},[_c('div',{class:[
                'heading-and-tag',
                _vm.cardSize ]},[_c('ar-text',{style:({
                  maxHeight: '50px',
                  overflow: 'hidden',
                }),attrs:{"size":"md","text":_vm.title,"max-lines":1,"multiple-lines":"","line-height":"25px"}})],1),_vm._v(" "),(_vm.campaign.event)?_c('ar-text',{staticClass:"card-list-location",attrs:{"size":"sm","text":_vm.campaign.event.location,"multiple-lines":"","max-lines":1}}):_vm._e()],1),_vm._v(" "),_c('div',{class:[
              'card-footer',
              _vm.cardSize ],style:({
              borderTop: _vm.layout === 'customer' ? 'none' : null,
              padding: _vm.layout === 'customer' ? 0 : null,
            })},[(_vm.layout === 'promoter')?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.allRegistrations && _vm.uniqueViews),expression:"allRegistrations && uniqueViews"}],staticClass:"registrations-and-views"},[_c('div',{staticClass:"registrations"},[_c('ar-icon',{attrs:{"name":"user","height":"13px","color":_vm.$arStyle.color.blueGrey600}}),_vm._v("\n                "+_vm._s(_vm.allRegistrations)+"  "+_vm._s(_vm.cardSize !== 'xs' ? 'Registrations' : null)+"\n              ")],1),_vm._v(" "),_c('div',{staticClass:"views"},[_c('ar-icon',{attrs:{"name":"preview","width":"17px","color":_vm.$arStyle.color.blueGrey600}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.uniqueViews)+"  "+_vm._s(_vm.cardSize !== 'xs' ? 'Views' : null))])],1)]):_vm._e()])])])]),_vm._v(" "),(_vm.layout === 'promoter')?_c('am2-campaign-dropdown',{staticClass:"campaign-dropdown",style:({
        top: _vm.showAs === 'tiles' ? '13px' : '17px',
        right: _vm.showAs === 'tiles' ? '13px' : '17px',
      }),attrs:{"kind":"menu","menu-icon-color":_vm.showAs === 'tiles' ? 'white' : null,"campaign":_vm.campaign},on:{"delete":_vm.handleCampaignDelete}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }