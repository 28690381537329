var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-dropdown-binder',{attrs:{"align":_vm.align,"button-name":"ar-link-button","pixel-shift":15,"button-props":_vm.buttonPropsDecorated,"disabled":_vm.disabled,"loading":_vm.loading,"dropdown-props":{
    items: _vm.filterItems,
    itemStyle: _vm.itemStyle,
    dividerStyle: _vm.dividerStyle,
    style: _vm.dropdownStyle,
    hasSearch: _vm.hasSearch,
    maxHeight: _vm.maxHeight,
    tabs: _vm.tabs,
    tabKey: _vm.tabKey,
    noOptionText: _vm.noOptionText,
    placeholder: _vm.searchPlaceholder,
  },"hasFixedFooter":_vm.hasFixedFooter},on:{"select":_vm.handleItemSelect,"tabSelect":_vm.handleTabSelect,"searchStringChange":_vm.handleSearchStringChange,"controlClick":_vm.handleControlClick,"fixedFooterClick":function () { _vm.$emit('fixedFooterClick') }}})}
var staticRenderFns = []

export { render, staticRenderFns }