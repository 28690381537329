var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreData),expression:"loadMoreData"}],staticClass:"registrants-container",attrs:{"infinite-scroll-disabled":"infiniteScrollDisabled","infinite-scroll-distance":"10"}},[_c('div',{directives:[{name:"ar-sticky-top",rawName:"v-ar-sticky-top",value:({
      priority: 1,
    }),expression:"{\n      priority: 1,\n    }"}],style:({
      padding: '22px 0 22px',
      backgroundColor: '#F7F9FC',
    })},[_c('am2-fans-control-section',{staticClass:"registrants-top-wrapper",attrs:{"fan-appellation":"registrant","fans-count":_vm.totalAudienceCount,"fansSearch":{
        action: _vm.serverSideSearch,
      },"loading":(!_vm.campaign || _vm.isFetchingCampaign ||_vm.isFetchingAudience) && _vm.audience.length === 0,"csvExport":{
        action: _vm.handleExportClick,
        expanded: true,
      }}})],1),_vm._v(" "),(_vm.campaign)?_c('am2-fan-table',{attrs:{"head":_vm.tableHeaders,"body":_vm.tableRows,"loading":_vm.isFetchingAudience && _vm.tableRows.length === 0,"has-index":"","empty-text":"No audience data","tableType":"audience","enable-row-click":_vm.customerProfilesEnabled,"has-sticky-header":""},on:{"sortBy":_vm.handleSortChange,"rowClick":_vm.handleRowClick}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }