var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"add-payment-method-modal",attrs:{"is-open":_vm.isShow,"hide-header":"","hide-footer":"","width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleClose}},[_c('div',{staticClass:"body",attrs:{"slot":"body"},slot:"body"},[_c('ar-text',{staticClass:"u-margin-bottom-10",attrs:{"size":"md","text":"Add Payment Method","weight":"bold","align":"center"}}),_vm._v(" "),_c('am2-stripe-element-form',{ref:"billing-form",attrs:{"loading":_vm.isCreatingPaymentSource},on:{"submit":_vm.handlePaymentMethodSubmit}}),_vm._v(" "),(_vm.arEnvironment === 'staging' || _vm.arEnvironment === 'development')?_c('div',{style:({
          textAlign: 'center'
      })},[_c('ar-text',{staticClass:"u-margin-y-4",attrs:{"size":"sm","text":"Test Cards","weight":"bold","align":"center"}}),_vm._v(" "),_c('ar-simple-button',{style:({padding: '0 8px'}),attrs:{"iconName":"payment-visa","iconProps":{
            name: 'payment-visa',
            height: '24px',
          },"text":"Credit","type":"grey","outlined":""},on:{"click":function () { _vm.handleDummyCardCreation('visa') }}}),_vm._v(" "),_c('ar-simple-button',{style:({padding: '0 8px'}),attrs:{"iconName":"payment-visa","iconProps":{
            name: 'payment-visa',
            height: '24px',
          },"text":"Debit","type":"grey","outlined":""},on:{"click":function () { _vm.handleDummyCardCreation('visa-debit') }}}),_vm._v(" "),_c('ar-simple-button',{style:({padding: '0 8px'}),attrs:{"iconName":"payment-mastercard","iconProps":{
            name: 'payment-mastercard',
            height: '24px',
          },"text":"Credit","type":"grey","outlined":""},on:{"click":function () { _vm.handleDummyCardCreation('mastercard') }}}),_vm._v(" "),_c('ar-simple-button',{style:({padding: '0 8px'}),attrs:{"iconName":"payment-mastercard","iconProps":{
            name: 'payment-mastercard',
            height: '24px',
          },"text":"Debit","type":"grey","outlined":""},on:{"click":function () { _vm.handleDummyCardCreation('mastercard-debit') }}}),_vm._v(" "),_c('ar-simple-button',{style:({padding: '0 8px'}),attrs:{"iconName":"payment-american-express","iconProps":{
            name: 'payment-american-express',
            height: '24px',
          },"type":"grey","outlined":"","text":"Credit"},on:{"click":function () { _vm.handleDummyCardCreation('american-express') }}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-y-4",attrs:{"size":"sm","text":"Test Errors","weight":"bold","align":"center"}}),_vm._v(" "),_c('ar-simple-button',{style:({padding: '0 8px'}),attrs:{"iconName":"notification-general","iconProps":{
            name: 'notification-general',
            height: '18px',
          },"type":"grey","outlined":"","text":"Declined"},on:{"click":function () { _vm.handleDummyCardCreation('declined') }}}),_vm._v(" "),_c('ar-simple-button',{style:({padding: '0 8px'}),attrs:{"iconName":"notification-general","iconProps":{
            name: 'notification-general',
            height: '18px',
          },"type":"grey","outlined":"","text":"Fraud"},on:{"click":function () { _vm.handleDummyCardCreation('fraud') }}}),_vm._v(" "),_c('ar-simple-button',{style:({padding: '0 8px'}),attrs:{"iconName":"notification-general","iconProps":{
            name: 'notification-general',
            height: '18px',
          },"type":"grey","outlined":"","text":"Expired"},on:{"click":function () { _vm.handleDummyCardCreation('expired') }}}),_vm._v(" "),_c('ar-simple-button',{style:({padding: '0 8px'}),attrs:{"iconName":"notification-general","iconProps":{
            name: 'notification-general',
            height: '18px',
          },"type":"grey","outlined":"","text":"Bad Number"},on:{"click":function () { _vm.handleDummyCardCreation('bad-number') }}}),_vm._v(" "),_c('ar-simple-button',{style:({padding: '0 8px'}),attrs:{"iconName":"notification-general","iconProps":{
            name: 'notification-general',
            height: '18px',
          },"type":"grey","outlined":"","text":"Bad CVC"},on:{"click":function () { _vm.handleDummyCardCreation('bad-cvc') }}})],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }