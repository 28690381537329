<template>
  <RendererFrame
    :is-readonly="!disableReadMode && isReadOnly"
    :title="filterCriteria.title"
    :filter-tooltip="filterTooltip"
    :subtitle="reviewConditionTitle"
    :readonly-items="readonlyItems"
    :apply-disabled="!isEditConditionComplete"
    :additional-constraints="filterCriteria.constraints"
    :condition-button-text="conditionButtonText"
    :hide-button="hideButton"
    @confirm="handleConfirmClick"
    @cancel="handleCancelClick"
    @delete="handleDeleteClick"
    @openChangeMode="isReadOnly = false"
  >
    <div slot="content" class="wrapper">
      <AdditionalConstraintRenderer
        class="mandatory-constraints u-margin-bottom-2"
        v-if="criteriaHasMandatoryConstraints"
        :filter-condition="filterCondition"
        :filter-constraints="filterCriteria.constraints"
        mandatory-constraints
        @constraintUpdate="handleConstraintUpdate"
      />
      <div v-if="!criteriaHasMandatoryConstraints || criteriaHasFilledMandatoryConstraint">
        <div class="select-section">
          <ar-simple-select
            :items="textTypeItems"
            :default-select-index="textTypeSelectedIndex"
            placeholder="Select your type..."
            class="select"
            @select="handlePredicateSelect"
            data-test-id="filter-predicate-selector"
          />
        </div>
        <div class="input-section">
          <ar-input
            ref="input"
            class="input"
            v-if="editFilterCondition.data.condition !== 'is_known' && editFilterCondition.data.condition !== 'is_unknown' && inputType === 'text'"
            :value="editFilterCondition.data.value"
            :auto-focus="shouldAutoFocus"
            :placeholder="`Enter ${filterCriteria.title.toLowerCase()}`"
            :autocomplete="generateInputAutocomplete(filterCriteria.title)"
            @input="handleInputChange"
            @enter="handleEnterPress"
            data-test-id="filter-text-input"
          />
          <ar-country-select
            v-else-if="inputType === 'country'"
            :value="editFilterCondition.data.value"
            value-type="iso"
            :style="{
              width: '100%',
              height: '42px',
            }"
            @select="handleCountrySelect"
            data-test-id="filter-country-selector"
          />
        </div>
        <AdditionalConstraintRenderer
          v-if="criteriaHasConstraints"
          class="u-margin-top-3"
          :filter-condition="filterCondition"
          :filter-constraints="filterCriteria.constraints"
          @constraintUpdate="handleConstraintUpdate"
        />
      </div>
    </div>
  </RendererFrame>
</template>

<script>
import RendererFrame from '../RendererFrame'
import AdditionalConstraintRenderer from '../RendererFrame/AdditionalConstraintRenderer'
import { clone } from '@/utils/helpers/';
import { filterCriteriaHasConstraint, filterCriteriaHasMandatoryConstraint } from '@/utils/filter';
import { debounce } from "debounce";
import { filterCriteriaMandatoryConstraintsFilled } from '~/utils/filter';

export default {
  title: 'FreeTextPicker',
  name: 'FreeTextPicker',
  components: {
    RendererFrame,
    AdditionalConstraintRenderer
  },
  props: {
    filterCriteria: {
      type: Object,
      default: () => ({}),
    },
    filterCondition: {
      type: Object,
      default: () => ({}),
    },
    conditionButtonText: {
      type: String,
      default: 'Apply filter'
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    disableReadMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isReadOnly: false,
      cachedFilterCondition: clone(this.filterCondition),
      editFilterCondition: clone(this.filterCondition),
    };
  },

  computed: {
    shouldAutoFocus() {
      return !this.disableReadMode
    },
    criteriaHasConstraints() {
      return filterCriteriaHasConstraint(this.filterCriteria);
    },
    criteriaHasMandatoryConstraints() {
      return filterCriteriaHasMandatoryConstraint(this.filterCriteria);
    },
    criteriaHasFilledMandatoryConstraint() {
      return filterCriteriaMandatoryConstraintsFilled(this.filterCriteria, this.editFilterCondition);
    },
    isEditConditionComplete() {
      return this.$arUtils.segment.isSegmentConditionComplete(this.editFilterCondition);
    },
    isCachedConditionComplete() {
      return this.$arUtils.segment.isSegmentConditionComplete(this.cachedFilterCondition)
    },
    filterTooltip() {
      return this.filterCriteria?.description;
    },
    textTypeItems() {
      return this.filterCriteria.data.predicates.map(predicate => ({
        name: predicate.title,
        value: predicate.value,
      }));
    },
    textTypeSelectedIndex() {
      for (let i = 0; i < this.filterCriteria.data.predicates.length; i += 1) {
        if (this.filterCriteria.data.predicates[i].value === this.editFilterCondition.data.condition) {
          return i;
        }
      }
      return -1;
    },
    reviewConditionTitle() {
      if (!this.editFilterCondition.data) {
        return null;
      }
      const condition = this.editFilterCondition.data.condition;
      if (condition === 'is_known' || condition === 'is_unknown') {
        return null;
      }

      const clickedPredicate = this.findPredicate(condition);
      return clickedPredicate ? clickedPredicate.title.toLowerCase() : null;
    },
    readonlyItems() {
      if (!this.editFilterCondition.data) {
        return null;
      }
      if (this.editFilterCondition.data.condition === 'is_known' || this.editFilterCondition.data.condition === 'is_unknown') {
        return [{ name: this.findPredicate(this.editFilterCondition.data.condition).title }];
      }
      return [{ name: this.editFilterCondition.data.value }];
    },
    inputType() {
      // TODO - Eventually we'll be adding a generic 'select' type here for a few other hybrid free-text/select filter types
      if (this.editFilterCondition.data.condition === 'is_exactly' && this.filterCriteria?.resource === 'country') {
        return 'country'
      }
      return 'text';
    },
  },

  async created() {
    this.isReadOnly = this.$arUtils.segment.isSegmentConditionComplete(this.editFilterCondition);

    this.debouncedReview = debounce(this.review, 500);
  },

  methods: {
    generateInputAutocomplete(title) {
      return 'filter-' + title.toLowerCase().replace(/ /g, '-');
    },
    findPredicate(condition) {
      return this.filterCriteria.data.predicates.find(
        predicate => predicate.value === condition
      );
    },
    review() {
      this.$emit('apply', clone(this.editFilterCondition));
    },
    handleDeleteClick() {
      this.$emit('delete');
    },
    handleCancelClick() {
      if (this.isCachedConditionComplete) {
        if (!this.disableReadMode) {
          this.isReadOnly = true;
          this.editFilterCondition = clone(this.cachedFilterCondition);
          this.$emit('apply', clone(this.editFilterCondition));
        } else {
          this.$emit('delete');
        }
      } else {
        this.$emit('delete');
      }
    },
    handleConfirmClick() {
      this.isReadOnly = true;
      this.cachedFilterCondition = clone(this.editFilterCondition);
    },
    handleInputChange(val) {
      this.$set(this.editFilterCondition.data, 'value', val);

      this.debouncedReview();
    },
    handleEnterPress() {
      if (!this.isEditConditionComplete || this.hideButton) {
        return;
      }
      this.handleConfirmClick();
    },
    handlePredicateSelect(item) {
      // Skip if it doesn't change
      if (this.editFilterCondition.data.condition === item.value) {
        return;
      }
      const newCondition = clone(this.editFilterCondition);
      newCondition.data.condition = item.value;

      if (item.value === 'is_known' || item.value === 'is_unknown' || item.value === 'is_exactly') {
        delete newCondition.data.value;
      }

      if (this.editFilterCondition.data.condition === 'is_exactly' && item.value !== 'is_exactly') {
        delete newCondition.data.value;
      }

      this.editFilterCondition = newCondition;

      if (this.$refs.input) {
        this.$refs.input.focus();
      }

      this.review();
    },
    handleCountrySelect(item) {
      this.$set(this.editFilterCondition.data, 'value', item.iso);
      this.debouncedReview();
    },
    handleConstraintUpdate(item) {
      const { constraint, key, values } = item;
      this.$set(this.editFilterCondition.constraints[constraint], 'condition', key);
      if (!!values) this.$set(this.editFilterCondition.constraints[constraint], 'values', values);
      this.review();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  .select-section {
    .select {
      height: 40px;
    }
  }
  .input-section {
    margin-top: 10px;

    .input {
      height: 40px;
    }
  }
}
</style>
