<template>
  <div class="item">
    <div class="channel-icon">
      <ar-icon
        :name="iconSetting.name"
        width="16px"
        :color="iconSetting.color"
      />
    </div>
    <div class="channel-stats">
      <div class="top-info" style="">
        <ar-text
          size="xs"
          :text="formatName(name)"
        />
        <div class="channel-line" />
        <ar-text
          class="channel-amount"
          size="xs"
          :text="amount"
        />
      </div>
      <div
        v-if="(link && identifier) || identifier"
        class="sub-info"
      >
        <ar-link-button
          v-if="link && identifier"
          :text="identifier"
          :text-props="{
            size: 'xxxs',
          }"
          @click="handleLinkClick"
        />
        <ar-text
          v-else
          size="xxxs"
          :text="identifier"
          :style="{
            color: '#9fa8b5',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { capitalizeFirstLetter } from '@/utils/helpers';
import { formatProviderName } from '@/utils/campaign';

export default {
  name: 'ActionField',
  props: {
    name: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    identifier: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
    // this is a string because its a formatted number e.g., "1,000", not 1000
    amount: {
      type: String,
      default: '0',
    },
  },
  data() {
    return { 
      iconMap: {
        facebook: {
          name:'facebook',
          color: this.$arStyle.color.facebook,
        },
        'facebook event': {
          name:'facebook',
          color: this.$arStyle.color.facebook,
        },
        twitter: {
          name: 'twitter',
          color: this.$arStyle.color.twitter,
        },
        messenger: {
          name: 'messenger',
          color: this.$arStyle.color.messenger,
        },
        sms: {
          name: 'sms',
          color: this.$arStyle.color.sms,
        },
        whatsapp: {
          name: 'whatsapp',
          color: this.$arStyle.color.whatsapp,
        },
        linkedin: {
          name: 'linkedin',
          color: this.$arStyle.color.linkedin,
        },
        email: {
          name: 'email',
          color: this.$arStyle.color.email,
        },
        spotify: {
          name: 'spotify',
          color: this.$arStyle.color.spotify,
        },
        instagram: {
          name: 'instagram',
          color: this.$arStyle.color.instagram,
        },
        copy: {
          name: 'copy-diagonal',
          color: this.$arStyle.color.skyBlueGrey700,
        },
        youtube: {
          name: 'youtube',
          color: this.$arStyle.color.youtube,
        },
        snapchat: {
          name: 'snapchat',
          color: this.$arStyle.color.snapchat,
        },
      },
    };
  },
  computed: {
    iconSetting() {
      if (this.target && this.target.indexOf('messenger') > -1) {
        return this.iconMap.messenger;
      }
      return this.iconMap[this.name] || {
        name: this.name,
      };
    },
  },
  methods: {
    formatName(name) {
      return formatProviderName(name);
    },
    handleLinkClick() {
      return window.open(this.link, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  min-width: 195px;

  .channel-icon {
    display: inline-flex;
    align-items: center;
    height: 14px;
  }

  .channel-stats {
    margin-left: 13px;
    width: 100%;

    .top-info {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      .channel-line {
        border-top: 1px $skyBlueGrey500 dotted;
        width: 100%;
        margin: 0 8px;
      }
    }

    .sub-info {
      margin-top: 4px;
      height: 14px;
    }
  }
}
</style>
