<template>
  <div class="sender-bubble">
    <ar-text
      size="sm"
      :text="text"
      allow-html
      multiple-lines
      line-height="24px"
      :style="{
        color: 'black',
      }"
      @anchorClick="handlePreviewAnchorClick"
    />
  </div>
</template>

<script>
export default {
  name: 'SenderBubble',

  props: {
    text: {
      type: String,
      default: null,
    },
  },

  methods: {
    handlePreviewAnchorClick(idx, anchor) {
      this.$emit('anchorClick', idx, anchor);
    },
  }

};
</script>

<style lang="scss" scoped>
.sender-bubble {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 290px;
  padding: 10px 20px;
  background: #E5E5EA;
  border-radius: 25px;

  /deep/ a {
    font-size: 16px;
    color: #3089F2;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
    content: "";
    position: absolute;
    z-index: 3;
    bottom: -2px;
    left: 4px;
    width: 26px;
    height: 20px;
    background: white;
    border-bottom-right-radius: 10px;
    -webkit-transform: translate(-30px, -2px);
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 2;
    bottom: -2px;
    left: -7px;
    height: 20px;
    border-left:20px solid #E5E5EA;
    border-bottom-right-radius: 16px 14px;
    -webkit-transform: translate(0, -2px);
  }
}
</style>

