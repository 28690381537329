<template>
  <ar-text
    class="heading"
    :size="size"
    :text="title"
    :weight="weight"
    :align="align"
    :multiple-lines="multipleLines"
  />
</template>

<script>
export default {
  name: 'Heading',
  props: {
    size: {
      type: String,
      default: 'md',
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    weight: {
      type: String,
      default: 'normal',
    },
    align: {
      type: String,
      default: 'left',
    },
    multipleLines: {
      type: Boolean,
      default: false,
    }
  },
};
</script>
