<template>
<div class="filter-options-wrapper">
  <ar-text
    class="filter-heading"
    size="14px"
    text="Filter"
    weight="normal"
    multiple-lines
    :max-lines="1"
  />
  <div class="filter-copy-wrapper">
    <ar-text
      class="filter-copy"
      size="14px"
      text="Limit to contacts that meet a specific criteria."
      weight="normal"
      multiple-lines
      :max-lines="2"
    />

    <am2-switch
      class="filter-switch"
      v-model="filterOptionLocal"
    />
  </div>
</div>
</template>
<script>
export default {
  name: 'FilterOption',
  props: {
    filterOption: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'filterOption',
    event: 'selection'
  },
  computed: {
    filterOptionLocal: {
      get: function() {
        return this.filterOption
      },
      set: function(val) {
        this.$emit('selection', val)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.filter-options-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 22px;

  .filter-heading {
    color: $blueGrey700;
    margin-bottom: 5px;
  }

  .filter-copy-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .filter-copy {
      width: 252px;
    }

    .filter-switch {
      width: 48px !important;        

      &.off {
        background: rgba(120, 120, 128, 0.16) !important;
      }
      &.on {
        background: $purple400 !important;
      }

      /deep/ .ball {
        box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.2) !important;
      }

      /deep/ .off-text {
        display: none !important;
      }
      
      /deep/ .on-text {
        display: none !important;
      }
    }
  }
}
</style>