<template>
  <section class="campaign-edit-details">
    <ar-text
      class="step-heading"
      size="xs"
      text="STEP 1"
      :style="{
        color: '#9FA8B5',
        marginBottom: '8px',
      }"
    />
    <am2-heading
      type="h1"
      size="lg"
      title="Setup campaign"
      :style="{marginBottom: '38.5px'}"
    />

    <!-- Campaign name -->
    <ar-field
      name="campaign-headline"
      label="Headline"
      :style="{ marginTop: '0' }"
      :error-messages="[
        veeErrors.first('headline'),
      ]"
    >
      <ar-input
        v-validate="'required|max:150'"
        ref="campaign-headline"
        v-model="campaignViewModel.name"
        autocomplete="camaign-headline"
        data-vv-name="headline"
        data-vv-as="headline"
        data-test-id="campaign-name"
      />
    </ar-field>

    <ar-field
      name="campaign-slug"
      :style="{ marginTop: '0' }"
      :error-messages="[
        veeErrors.first('url-slug'),
      ]"
    >
      <!-- Only for this, we validate it immediately after @input -->
      <am2-slug-input
        class="u-margin-top-4"
        v-validate="{
          required: true,
          arCampaignSlug: {
            currentSlug: this.originalCampaignUrlSlug,
            prefix: this.campaignPrefix,
          },
        }"
        data-vv-name="url-slug"
        data-vv-as="url-slug"
        data-vv-validate-on="input"
        autocomplete="slug"
        :base-url="c2ClientDomain"
        :value="campaignViewModel.urlSlug"
        @input="handleUrlSlugChange"
        :prefix="campaignPrefix"
        data-test-id="campaign-slug"
      />
    </ar-field>

    <!-- Campaign description -->

    <ar-field
      name="campaign-description"
      label="Description"
      :style="{
        marginTop: '44px',
      }"
      :error-messages="[
        veeErrors.first('description'),
      ]"
    >
      <!-- @andressa: why is an editor here and the design seems to be only a textarea with a counter? -->
      <!--         <vue-editor
        v-model="campaignViewModel.presentation.description"
        :editor-toolbar="[['bold', 'italic', 'underline', { list: 'bullet' }]]"
        :placeholder="'Briefly describe your campaign and highlight the rewards that that will drive fans to register for the presale. And don’t worry, you can always edit this later.'"
      /> -->
      <ar-textarea
        v-validate="'required'"
        v-model="campaignViewModel.presentation.description"
        :rows="4"
        :value="campaignViewModel.presentation.description"
        data-vv-name="description"
        data-test-id="campaign-description"
      />
    </ar-field>

    <ar-field
      name="registration-period-end"
      label="Registrations end"
      description="Enter when you want to stop accepting registrations."
      :style="{
        marginTop: '44px',
      }"
      :error-messages="[
        veeErrors.first('endDate'),
      ]"
    >
      <am2-date-time-input
        :class="[
          'date-time-picker',
          $arMediaQuery.window.maxWidth('xs') && 'u-width-100-percent',
        ]"
        v-model="campaignViewModel.endDate"
        :time-zone="campaignViewModel.presentation.timeZone"
        v-validate="'required|tzFormat'"
        data-vv-name="endDate"
        data-vv-as="end date"
        data-test-id="campaign-reg-end"
      />
    </ar-field>


      <!-- class="u-margin-top-3" -->
    <ar-field
      name="timezone"
      :style="{
        marginTop: '10px',
      }"
      :error-messages="[
        veeErrors.first('timezone'),
      ]"
    >
      <am2-timezone-dropdown
        v-validate="'required'"
        data-vv-name="timezone"
        :value="campaignViewModel.presentation.timeZone"
        @input="handleTimeZoneInput"
        data-test-id="campaign-timezone-link"
      />
    </ar-field>

    <!-- Presale notification date -->
    <ar-field
      v-if="hasNotifyDate"
      name="tickets-on-sale"
      :label="onSaleLabelCopy"
      :description="onSaleDescriptionCopy"
      :style="{
        marginTop: '44px',
      }"
      :error-messages="[
        veeErrors.first('notifyDate'),
      ]"
    >
      <div>
        <div class="notify-datetime-wrapper">
          <!-- v-if hack required because the DateTime picker can't be set after it is created -->
          <am2-date-time-input
            ref="notify-datetime-picker"
            v-validate="notifyDateShouldValidate"
            :disabled="!enablePresaleDate"
            v-model="campaignViewModel.notifyDate"
            :time-zone="campaignViewModel.presentation.timeZone"
            class="date-time-picker"
            data-vv-name="notifyDate"
            data-vv-as="notify date"
            data-test-id="campaign-presale-date"
          />
          <ar-link-button
            :text="enablePresaleDate ? 'Hide' : 'Show'"
            :style="{ marginLeft: '30px' }"
            @click="togglePresaleDate"
          />
        </div>
      </div>
    </ar-field>


    <!-- Campaign image -->
    <ar-field
      name="campaign-image"
      label="Image"
      :description="imageCopy"
      :style="{
        marginTop: '44px',
      }"
      :error-messages="[
        veeErrors.first('image'),
        veeErrors.first('campaignImageUploading'),
      ]"
    >
      <!-- <div>
        <p>Choose an image to be featured on the presale registration page and in the news feed when shared on social media. The best performing images include the event name, location and lineup. See <a href="#">Tips and examples</a></p>
        <span class="field-info">Dimensions: 1200x628 pixels. Format: JPG or PNG. Max file size: 1MB</span>
        <p>
          <ar-simple-button class="button--text" text="to add a video" />
        </p>
      </div> -->

      <div
        :style="{
          height: (campaignImageUrl || isUploadingCampaignImage) ? null : '0',
          overflow: 'hidden',
        }"
      >
        <div
          v-if="isUploadingCampaignImage"
          v-validate:isUploadingCampaignImage="'uploadingAssets'"
          data-vv-name="campaignImageUploading"
          data-vv-as="campaign image"
        />
        <am2-dropzone
          ref="img-dropzone"
          v-validate:campaignImage="'required'"
          data-vv-name="image"
          data-vv-as="campaign image"
          :file-type="['image/jpeg', 'image/jpg', 'image/png']"
          file-type-alias="image"
          :placeholder-icon="{
            name: 'image',
          }"
          :image-options="{
            dimensions: {
              width: 1200,
              height: 628
            }
          }"
          :ratio="0.525"
          feedback-type="image"
          :feedback="campaignImage && campaignImage.url"
          enable-ar-s3-bucket-upload
          :ar-s3-bucket-upload-options-list="[{
            assetType: 'campaign-image',
            campaignOid: editCampaign.oid,
            eventOid: null,
          }]"
          @delete="handleImageDelete"
          @uploadStart="isUploadingCampaignImage = true"
          @uploadEnd="isUploadingCampaignImage = false"
          @upload="handleImageUpload"
          data-test-id="campaign-image"
        />

        <p class="image-requirements-text">Dimensions: 1200x628 pixels. Format: JPG or PNG. Max file size: 1MB</p>
      </div>

      <div v-if="!campaignImageUrl && !isUploadingCampaignImage" class="u-display-flex">
        <ar-icon
          class="u-margin-right-2"
          name="image"
          height="21px"
          :color="$arStyle.color.blueGrey600"
        />
        <ar-link-button
          text="Upload image"
          @click="handleUploadImageClick"
          data-test-id="campaign-image-upload"
        />
      </div>
    </ar-field>

    <!-- Campaign Video -->
    <am2-field
      name="campaign-video"
      label="Video (optional)"
      description="Choose a video to be featured on the registration page."
    >
      <div
        v-if="isVideoVisible || campaignVideo || campaignViewModel.presentation.externalVideoUrl"
      >
        <!-- @andressa: check how this works (tab youtube/vimeo is a text input?) -->
        <!-- @andressa: should it be a tab component? -->

        <!-- video/quicktime -->
        <!-- video/mp4 -->

        <am2-video-uploader
          class="video-uploader"
          :video="campaignVideo"
          :external-video-url="campaignViewModel.presentation.externalVideoUrl"
          :external-video-media-type="campaignViewModel.presentation.externalVideoMediaType"
          @uploaded="handleVideoUpload"
          @loadedExternal="handleExternalVideoLoaded"
          data-test-id="campaign-video"
          @delete="handleDeleteVideo"/>
      </div>

      <div v-if="!isVideoVisible && !campaignVideo" class="u-display-flex">
        <UploadVideo
          class="u-margin-right-2"
          width="21px"
          height="21px"
        />
        <ar-link-button
          text="Upload a video"
          @click="isVideoVisible = true"
          data-test-id="campaign-video-upload"
        />
      </div>
    </am2-field>

    <!-- Campaign colours -->
    <am2-field
      name="campaign-colours"
      label="Colors"
      description="Use your own brand colors."
    >
      <div style="position: relative">
        <am2-color-input
          v-model="campaignViewModel.presentation.colorData.hex"
        />
      </div>
    </am2-field>

    <am2-field
      name="custom-html"
      label="Custom HTML"
      class="custom-html">

      <ar-link-button
        v-if="!isHTMLSnippetsVisible"
        text="Show HTML Snippets"
        @click="isHTMLSnippetsVisible = true"
      />

      <transition name="overlay-fade">
        <div v-if="isHTMLSnippetsVisible">
          <am2-field name="html-head" label="HTML Head" class="grey-text">
            <ar-textarea v-model="campaignViewModel.presentation.headHtml" :rows="5" />
          </am2-field>
          <br >
          <am2-field name="html-body" label="HTML Body" class="grey-text">
            <ar-textarea v-model="campaignViewModel.presentation.bodyHtml" :rows="5" />
          </am2-field>

          <ar-link-button
            text="Hide HTML Snippets"
            :style="{
              marginTop: '12px',
            }"
            @click="isHTMLSnippetsVisible = false"
          />
        </div>
      </transition>
    </am2-field>

    <am2-field
      name="advanced-settings"
      label="Advanced settings">
      <ar-link-button
        class="button--text advanced-settings-button"
        :text="`${isAdvancedSettingsVisible ? 'Hide' : 'Show'} advanced settings`"
        @click="isAdvancedSettingsVisible = !isAdvancedSettingsVisible"
      />
      <transition name="overlay-fade">
        <div
          v-if="isAdvancedSettingsVisible"
          class="advanced-settings-section"
        >

          <ar-text
            text="Customisations"
            size="md"
            weight="bold"
            class="u-margin-top-9 u-margin-bottom-1"
          />
          <am2-field
            name="customize-cta-button"
            label="Customize 'call-to-action' button"
            class="advanced-settings__item">
            <ar-input
              id="customize-cta-button"
              v-model="campaignViewModel.advancedSettings.customCta"
              autocomplete="custom-campaign-cta"
              :placeholder="callToActionPlaceholderCopy"
              class="input--block"
              type="text"
            />
          </am2-field>
          <am2-field
            name="default-language"
            label="Default language"
            class="advanced-settings__item"
          >
            <am2-language-select
              v-model="campaignViewModel.advancedSettings.defaultLanguage"
            />
          </am2-field>


          <ar-checkbox
            v-model="campaignViewModel.advancedSettings.noImageBackground"
            align="right"
            label="Disable background image"
            :style="{ width: '100%', marginTop: '15px' }"
          />

          <ar-checkbox
            v-model="campaignViewModel.advancedSettings.noPoints"
            align="right"
            label="Hide points from fans"
            :style="{ width: '100%', marginTop: '15px' }"
          />

          <am2-field
            name="customize-live-header"
            label="Use custom header text once event is live"
            class="advanced-settings__item">
            <ar-input
              id="customize-live-header"
              v-model="campaignViewModel.presentation.liveCustomHeader"
              :placeholder="campaignViewModel.name"
              autocomplete="live-custom-header"
              class="input--block"
              type="text"
            />
          </am2-field>

          <am2-field name="customize-live-body" label="Use custom body text once event is live" class="u-padding-top-4">
            <ar-textarea
              v-validate="'max:512'"
              data-vv-name="customLiveBody"
              data-vv-as="custom live body"
              :placeholder="campaignViewModel.presentation.description"
              v-model="campaignViewModel.presentation.liveCustomDescription"
              :rows="5"
            />
            <ar-state-message
              v-if="veeErrors.has('customSuccessMessage')"
              :style="{ marginTop: '8px' }"
              type="error"
              :text="veeErrors.first('customSuccessMessage')"
            />
          </am2-field>

          <ar-divider class="u-margin-top-8" />

          <ar-text
            text="Privacy and Compliance"
            size="md"
            weight="bold"
            class="u-margin-top-9 u-margin-bottom-1"
          />
          <am2-field
            name="privacy-policy"
            label="Add your Privacy Policy"
            class="advanced-settings__item">
            <div>
              <div class="advanced-settings__flex">
                <ar-input
                  placeholder="Company"
                  v-model="campaignViewModel.advancedSettings.privacyPolicy.companyName"
                  v-validate="'privacyPolicyUrlAlsoRequired'"
                  autocomplete="organization"
                  data-vv-name="privacyPolicyCompanyName"
                  type="text"
                  :style="{
                    width: '30%',
                  }"
                />
                <ar-input
                  placeholder="Privacy Policy URL"
                  v-model="campaignViewModel.advancedSettings.privacyPolicy.url"
                  v-validate="{
                    privacyPolicyCompanyNameAlsoRequired: true,
                    url: {
                      require_protocol: true,
                    },
                  }"
                  data-vv-name="privacyPolicyUrl"
                  autocomplete="url"
                  type="text"
                  :style="{
                    width: 'calc(70% - 10px)',
                  }"
                />
              </div>
              <ar-state-message
                v-if="veeErrors.has('privacyPolicyCompanyName')"
                :style="{ marginTop: '8px' }"
                type="error"
                :text="veeErrors.first('privacyPolicyCompanyName')"
              />
              <ar-state-message
                v-if="veeErrors.has('privacyPolicyUrl')"
                :style="{ marginTop: '8px' }"
                type="error"
                :text="veeErrors.first('privacyPolicyUrl')"
              />
            </div>
          </am2-field>
          <am2-field
            name="replace-default-terms-conditions"
            label="Upload terms &amp; conditions"
            class="advanced-settings__item">
            <am2-dropzone
              :file-type="['application/pdf']"
              :file-size="3000000"
              file-type-alias="PDF"
              :placeholder-icon="{
                name: 'upload',
              }"
              :style="{
                height: '200px',
              }"
              feedback-type="default"
              :feedback="campaignTermsFile ? 'PDF uploaded' : null"
              enable-ar-s3-bucket-upload
              :ar-s3-bucket-upload-options-list="[{
                assetType: 'campaign-terms-and-conditions',
                campaignOid: null,
                eventOid: null,
              }]"
              @delete="handleRemoveTermsData"
              @upload="handleTermsData"
            />

            <span class="field-info">Formats: .PDF. Max file size: 3mb</span>
          </am2-field>

          <am2-field
            name="tcpa-settings"
            label="SMS TCPA Notice"
            :style="{
              margin: '15px 0',
            }"
            class="advanced-settings__item">
            <div :style="{
              display: 'flex',
              justify: 'space-around',
            }">
              <ar-checkbox
                v-model="campaignViewModel.advancedSettings.tcpaSettings.enabled"
                align="right"
                label="Show"
                :style="{ width: '78px', marginRight: '22px' }"
              />
              <ar-input
                placeholder="Promoter Name"
                v-model="campaignViewModel.advancedSettings.tcpaSettings.companyName"
                :disabled="!campaignViewModel.advancedSettings.tcpaSettings.enabled"
                v-validate="{
                  required: !!campaignViewModel.advancedSettings.tcpaSettings.enabled
                }"
                data-vv-name="tcpaCompanyName"
                data-vv-as="Company Name"
                autocomplete="organization"
                type="text"
                :style="{
                width: 'calc(100% - 100px)',
              }"
              />
            </div>
            <ar-state-message
              v-if="veeErrors.has('tcpaCompanyName')"
              :style="{ marginTop: '8px' }"
              type="error"
              :text="veeErrors.first('tcpaCompanyName')"
            />
          </am2-field>


          <ar-divider class="u-margin-top-8" />

          <ar-text
            text="Success Page Settings"
            size="md"
            weight="bold"
            class="u-margin-top-9 u-margin-bottom-1"
          />

          <am2-field name="custom-success-message" label="Custom Success Message" class="u-padding-top-4">
            <ar-textarea
              v-validate="'max:512'"
              data-vv-name="customSuccessMessage"
              data-vv-as="custom success message"
              v-model="campaignViewModel.advancedSettings.customSuccessMessage"
              :rows="5"
            />
            <ar-state-message
              v-if="veeErrors.has('customSuccessMessage')"
              :style="{ marginTop: '8px' }"
              type="error"
              :text="veeErrors.first('customSuccessMessage')"
            />
          </am2-field>
          <ar-checkbox
            v-model="campaignViewModel.advancedSettings.customSuccessButtonEnabled"
            align="right"
            label="Enable custom success button"
            :style="{ width: '100%', marginTop: '15px' }"
          />
          <am2-field
            name="custom-success-button-text"
            v-if="campaignViewModel.advancedSettings.customSuccessButtonEnabled"
            label="Customize Success Button Text"
            class="advanced-settings__item">
            <ar-input
              id="custom-success-button-text"
              v-model="campaignViewModel.advancedSettings.customSuccessButtonText"
              data-vv-name="customSuccessButtonText"
              data-vv-as="custom success button text"
              autocomplete="custom-success-button-text"
              v-validate="'max:150'"
              placeholder="Improve your position"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('customSuccessButtonText')"
              :style="{ marginTop: '8px' }"
              type="error"
              :text="veeErrors.first('customSuccessButtonText')"
            />
          </am2-field>
          <am2-field
            name="custom-success-button-url"
            v-if="campaignViewModel.advancedSettings.customSuccessButtonEnabled"
            label="Customize Success Button Clickthrough"
            class="advanced-settings__item">
            <ar-input
              id="custom-success-button-url"
              v-validate="'url|max:250'"
              v-model="campaignViewModel.advancedSettings.customSuccessButtonUrl"
              data-vv-name="customSuccessButtonUrl"
              autocomplete="url"
              data-vv-as="custom success button clickthrough"
              placeholder="Your website"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('customSuccessButtonUrl')"
              :style="{ marginTop: '8px' }"
              type="error"
              :text="veeErrors.first('customSuccessButtonUrl')"
            />
          </am2-field>

          <ar-divider
            v-show="false" :style="{ margin: '32px 0 0' }" />

          <ar-text
            v-show="false"
            text="Fan Communications"
            size="md"
            weight="bold"
            class="u-margin-top-9 u-margin-bottom-1"
          />
          <ar-checkbox
            v-show="false"
            v-model="campaignViewModel.advancedSettings.email.fanConfirmationEmail"
            align="right"
            label="Send fans a confirmation email upon registration"
            :style="{ width: '100%', marginTop: '15px' }"
          />
          <ar-checkbox
            v-show="false"
            v-model="campaignViewModel.advancedSettings.email.fan24hrsToCampaignEndEmail"
            align="right"
            label="Send fans a reminder before campaign ends"
            :style="{ width: '100%', marginTop: '15px' }"
          />

          <ar-divider :style="{ margin: '20px 0 15px' }" />
        </div>
      </transition>
    </am2-field>

  </section>
</template>

<script>
import UploadVideo from '~/assets/icons/upload-video.svg?inline'
import { mapActions, mapGetters } from 'vuex';
import {
  campaignTypeToUriPrefix,
  validatorDictionary,
  copyGenerator,
} from '@/utils/campaign';
import { capitalizeFirstLetter } from '@/utils/helpers/';

import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import merge from 'deepmerge';
import moment from 'moment';

Vue.use(VueClipboard);

export default {
  name: 'EditDetails',

  components: {
    UploadVideo,
  },

  props: {
    isNew: {
      type: Boolean
    },
    editCampaign: {
      type: Object
    },
    setEditCampaign: {
      type: Function
    },
  },

  data() {
    return {
      c2ClientDomain: process.env.arCampaignClientDomain,
      originalCampaignUrlSlug: null,
      isHTMLSnippetsVisible: false,
      isAdvancedSettingsVisible: false,
      isVideoVisible: false,
      copySuccess: false,
      enablePresaleDate: true,
      campaignViewModel: {
        name: '',
        urlSlug: '',
        presentation: {
          description: '',
          headHtml: '',
          bodyHtml: '',
          colorData: {
            hex: '#7344c0',
          },
          timeZone: '',
          externalVideoUrl: '',
          externalVideoMediaType: '',
          liveCustomHeader: '',
          liveCustomDescription: '',
        },
        startDate: null,
        endDate: null,
        notifyDate: null,
        type: this.campaignType || null,
        resources: [],
      },
      isUploadingCampaignImage: false,
    };
  },

  computed: {
    callToActionPlaceholderCopy() {
      return copyGenerator('general', this.campaignViewModel.type).defaultCallToActionCopy;
    },
    hasNotifyDate() {
      return this.campaignViewModel.type === 'competition' || this.campaignViewModel.type === 'presale' || this.campaignViewModel.type === 'waitlist';
    },
    descriptionCopy() {
      return copyGenerator('details', this.campaignViewModel.type).description;
    },
    imageCopy() {
      return copyGenerator('details', this.campaignViewModel.type).image;
    },
    onSaleLabelCopy() {
      return copyGenerator('details', this.campaignViewModel.type).onSaleLabel;
    },
    onSaleDescriptionCopy() {
      return copyGenerator('details', this.campaignViewModel.type).onSaleDescription;
    },
    campaignPrefix() {
      return campaignTypeToUriPrefix(this.campaignViewModel.type) + '/';
    },
    campaignImage() {
      return this.campaignViewModel.resources.find(resource => resource.assetType === 'campaign-image');
    },
    // startDate() {
    //   return this.startDate.tz(this.campaignViewModel.presentation.timeZone);
    // },
    campaignVideo() {
      return this.campaignViewModel.resources.find(resource => resource.assetType === 'campaign-video');
    },
    campaignTermsFile() {
      return this.campaignViewModel.resources.find(
        resource => resource.assetType === 'campaign-terms-and-conditions'
      );
    },
    notifyDateShouldValidate() {
      return this.enablePresaleDate ? 'required|tzFormat|notifyDate' : ''
    },
    campaignImageUrl() {
      return this.campaignImage && this.campaignImage.url
    },
  },

  created() {
    this.$validator.dictionary.merge(validatorDictionary());
    // Special Validator checks

//    if (this.editCampaign.event.paymentInfo.type === 'premium') {
//      this.$arNotification.push({
//        type: 'success',
//        message: 'You have successfully upgraded to Premium Campaigns.\n\nOur sales team will be in touch to determine the final price for your campaign. You can still proceed to set up the campaign in the meantime.',
//        timeout: 10000,
//      });
//    }
    this.$validator.extend('notifyDate', {
      getMessage: field => `On-sale date must be after the campaign ends`,
      validate: value => {
        if (!this.campaignViewModel.endDate || this.campaignViewModel.endDate === 'Invalid date') {
          return true;
        }
        return moment(value).isAfter(this.campaignViewModel.endDate);
      },
    });

    // Logic is, if we have companyName, we check if we have URL or not
    this.$validator.extend('privacyPolicyUrlAlsoRequired', {
      getMessage: field => `You must enter a valid URL`,
      validate: value => {
        if (!value) {
          return false;
        } else {
          return this.campaignViewModel.advancedSettings.privacyPolicy.url ? true : false;
        }
      },
    });

    // Logic is, if we have url, we check if we have companyName or not
    this.$validator.extend('privacyPolicyCompanyNameAlsoRequired', {
      getMessage: field => `Please enter company name`,
      validate: value => {
        if (!value) {
          return false;
        } else {
          return this.campaignViewModel.advancedSettings.privacyPolicy.companyName ? true : false;
        }
      },
    });

    this.$validator.localize('en', {
      custom: {
        privacyPolicyUrl: {
          url: () => 'Please enter a valid url',
        },
      },
    });
  },

  mounted() {
    this.initializeCampaignViewModel();
  },

  watch: {
    campaignViewModel: {

      // Convert the PAGE specific model to Server representation of Campaign data.

      handler(value) {
        const { name, urlSlug, presentation, startDate, endDate, notifyDate, type, resources, advancedSettings } = value
        const { description, bodyHtml, headHtml, timeZone,
          externalVideoUrl, externalVideoMediaType, colorData, liveCustomHeader, liveCustomDescription } = presentation
        const color = colorData.hex
        const settings = advancedSettings
        const resourceOids = resources.reduce((accum, resource) => accum.push(resource.oid) && accum, [])

        const { noImageBackground, privacyPolicy, isClientVersion2, defaultLanguage,
          customSuccessMessage, customSuccessButtonEnabled, customSuccessButtonText, customSuccessButtonUrl,
          tcpaSettings, email, noPoints, ...restSettings } = advancedSettings
        const clientVersion = isClientVersion2 ? 2 : 1;


        let defaultStartDate = null

        if (this.isNew) {
          defaultStartDate = moment.tz(moment().format(), moment.tz.guess())
          .utc()
          .format();
        }

        const convertedCampaign = {
          name,
          urlSlug,
          startDate: endDate ? endDate : defaultStartDate,
          endDate,
          notifyDate,
          type,
          resourceOids,
          resources,
          settings: {
            clientVersion,
            privacyPolicy,
            defaultLanguage,
            tcpaSettings,
            email,
            ...restSettings
          },
          presentation: {
            description,
            bodyHtml,
            headHtml,
            timeZone,
            externalVideoUrl,
            externalVideoMediaType,
            color,
            noImageBackground,
            noPoints,
            customSuccessMessage,
            customSuccessButtonEnabled,
            customSuccessButtonText,
            customSuccessButtonUrl,
            liveCustomHeader,
            liveCustomDescription,
          },
        };

        this.setEditCampaign(convertedCampaign);
      },
      deep: true
    }
  },


  methods: {
    initializeCampaignViewModel() {
      // Build the PAGE specific model of the Campaign data.
      const { oid, name, urlSlug, presentation, startDate, endDate,
        notifyDate, type, resources, settings } = this.editCampaign;
      const { description, bodyHtml, headHtml, timeZone,
        externalVideoUrl, externalVideoMediaType, color,
        noImageBackground, noPoints, customSuccessMessage,
        customSuccessButtonEnabled,
        customSuccessButtonText,
        customSuccessButtonUrl,
        liveCustomHeader, liveCustomDescription} = presentation;
      const {
        clientVersion,
        privacyPolicy,
        tcpaSettings,
        email,
        defaultLanguage,
        ...restSettings
      } = settings;
      const colorData = { hex: color || '#7344c0' }

      this.enablePresaleDate = !!notifyDate || !oid; // by default, new campaigns should have this field enabled

      const isClientVersion2 = clientVersion === 2;
      // If it's null, make a default value for it
      const fixedPrivacyPolicy = privacyPolicy || { companyName: null, url: null };
      const fixedTcpaSettings = tcpaSettings || { enabled: false, companyName: null };
      const fixedEmail = email || {
        dailyAnalyticsEmail: false,
        fanConfirmationEmail: !!["presale", "competition", "ballot", "waitlist", "opt-in", "livestream"].indexOf(type), // TODO - Set defaults based on campaign type
        fanReferralRegisteredEmail: !!["presale", "competition", "ballot", "waitlist", "opt-in"].indexOf(type), // TODO - Set defaults based on campaign type
        fan24hrsToCampaignEndEmail: !!["presale", "competition", "ballot", "waitlist", "livestream"].indexOf(type), // TODO - Set defaults based on campaign type
        fanConfirmationSendTimeOffset: 1
      };

      const campaignViewModel = {
        name: name,
        urlSlug: urlSlug,
        currentUrlSlug: urlSlug,
        presentation: {
          description,
          headHtml,
          bodyHtml,
          timeZone,
          externalVideoUrl,
          externalVideoMediaType,
          colorData,
          liveCustomHeader,
          liveCustomDescription,
        },
        startDate,
        endDate,
        notifyDate,
        type: type,
        advancedSettings: {
          privacyPolicy: fixedPrivacyPolicy,
          tcpaSettings: fixedTcpaSettings,
          email: fixedEmail,
          // XXX/JNM/04.01.19: Some fields are in presentation or in AdvancedSetting that control how
          // elements are presented in the client -- the decision about where to put them seems arbitrary
          // and doesn't follow a pattern that i can understand, we need to rethink this a bit later.
          noImageBackground,
          noPoints,
          isClientVersion2,
          customSuccessMessage,
          customSuccessButtonEnabled,
          customSuccessButtonText,
          customSuccessButtonUrl,
          defaultLanguage: defaultLanguage || 'en-us',
          ...restSettings
        },
        resources: resources
      };

      this.originalCampaignUrlSlug = urlSlug;

      this.campaignViewModel = merge(this.campaignViewModel, campaignViewModel)
    },

    capitalizeFirstLetter(text) {
      return capitalizeFirstLetter(text);
    },

    togglePresaleDate() {
      const newEnabledState = !this.enablePresaleDate;
      this.enablePresaleDate = newEnabledState;

      if (!newEnabledState) {
        this.$set(this.campaignViewModel, 'notifyDate', null);
      }
    },

    async handleImageUpload({ arS3BucketResources }) {
      await this.handleImageDelete();
      this.campaignViewModel.resources.push(arS3BucketResources[0]);
    },

    handleUrlSlugChange(slug) {
      this.campaignViewModel.urlSlug = slug;
    },

    async handleImageDelete() {
      this.campaignViewModel.resources = this.campaignViewModel.resources.filter(
        resource =>
          // delete images
          resource.assetType !== 'campaign-image'
      );
    },

    async handleVideoUpload(data) {
      this.campaignViewModel.resources.push(data);
    },

    async handleExternalVideoLoaded(videoDetails) {
      this.campaignViewModel.presentation.externalVideoMediaType = videoDetails.type;
      this.campaignViewModel.presentation.externalVideoUrl = videoDetails.url;
    },
    // remove videos
    async handleDeleteVideo() {
      this.campaignViewModel.resources = this.campaignViewModel.resources.filter(
        resource => resource.assetType !== 'campaign-video'
      );

      this.campaignViewModel.presentation.externalVideoMediaType = ''
      this.campaignViewModel.presentation.externalVideoUrl = ''
    },

    async handleTermsData({ arS3BucketResources }) {
      const resourcesNotTermsAndConditions = this.campaignViewModel.resources.filter(
        r => r.assetType !== 'campaign-terms-and-conditions'
      );

      this.campaignViewModel.resources = resourcesNotTermsAndConditions.concat(arS3BucketResources[0]);
    },

    handleRemoveTermsData() {
      this.campaignViewModel.resources = this.campaignViewModel.resources.filter(
        r => r.assetType !== 'campaign-terms-and-conditions'
      );
    },

    hideHTMLSnippets() {
      this.isHTMLSnippetsVisible = false;
    },

    documentClick(e) {
      this.$nextTick(() => {
        const { colorpicker } = this.$refs;
        const { target } = e;
        if (colorpicker !== target && !colorpicker.$el.contains(target)) {
          this.hideColorPicker();
        }
      });
    },
    // https://stackoverflow.com/questions/4233265/contenteditable-set-caret-at-the-end-of-the-text-cross-browser

    handleUploadImageClick() {
      this.$refs['img-dropzone'].selectFile();
    },

    handleTimeZoneInput(timezone) {
      this.campaignViewModel.presentation.timeZone = timezone
    },
  },
};
</script>

<style lang="scss">
.registration-period {
  &-start,
  &-end,
  &-timezone {
    margin-top: 18px;

    .ar-field__label {
      color: $blueGrey700;
    }
  }
}

@media (max-width: 650px) {
  .ar-field.is-child-inline > .ar-field__label {
    max-width: 100%;
  }
}

@media screen and (max-width: 1497px) {
  .video-uploader {
    video {
      width: 240px;
      height: 135px;
    }
  }
}
@media screen and (max-width: 650px) {
  .ar-field.is-child-inline {
    display: flex;
    flex-direction: column;
  }
  .video-uploader {
    video {
      width: 100px;
      height: 56px;
    }
  }
}
</style>

<style lang="scss" scoped>
.grey-text {
  color: $blueGrey700;
}
.ar-field {
  p {
    margin-bottom: 15px;
  }
}
.editable-line {
  color: $blueGrey700;
  margin-top: 1em;

  input {
    color: inherit;
    margin-left: -5px;
    border: none;
    border: 1px solid transparent;
    background-color: transparent;

    &:focus {
      outline: none;
      border: 1px dotted;
    }

    &[aria-invalid="true"] {
      border-color: red;
    }
  }

  &__icon {
    cursor: pointer;
    color: $skyBlueGrey600;
    vertical-align: text-bottom;
    margin-left: -15px; // Offset the comfort setting of the autowidth
  }
}

.image-requirements-text {
  margin-top: 14px;
  color: $blueGrey600;
}

.field-info {
  color: $blueGrey600;
  display: block;
}

.campaign-colours__picker {
  position: absolute;
  margin-top: -315px;
}

.campaign-colours__current {
  height: 30px;
  width: 30px;
  border-radius: 4px;
  display: block;
}

.ar-field__body .ar-field {
  margin-top: 0;
}

.registration-period-timezone .timezone-picker {
  display: flex;
  flex-direction: column;
  width: 280px;
}
@media screen and (max-width: 650px) {
  .registration-period-timezone {
    width: 100%;
  }
  .ar-field.is-child-inline > .ar-field__body {
    max-width: 100%;
  }
}

.timezone-separator {
  position: relative;
  top: 43px;
  margin-left: 10px;
  margin-right: 10px;
  border-top: 1px solid $skyBlueGrey500;
  width: 50px;
}

@media screen and (min-width: $xl-min) {
  .registration-period-timezone {
    width: 100%;
  }
}
.advanced-settings-button {
  margin-bottom: 20px;
}
.advanced-settings-section {
  border: 1px solid $skyBlueGrey500;
  border-radius: 0.25rem;
  padding: 0.625rem 2.5rem 2.1875rem;

  .advanced-settings__item {
    margin-top: 22px;

    .advanced-settings__flex {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
  }
}
.copy-icon {
  cursor: pointer;
  vertical-align: text-bottom;
}
.copy-tick {
  color: $green500;
  vertical-align: text-bottom;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.date-time-picker {
  width: 280px;
}

.notify-enable-button {
  margin-left: 30px;
  display: block;
  align-self: center;
}
@media (max-width: 768px) {
  .notify-enable-button {
    margin-left: 30px;
    display: block;
    align-self: center;
  }
  .button:not(.button--text).is-primary,
  .button:not(.button--text).is-secondary {
    max-width: 100%;
  }
}
.notify-datetime-wrapper {
  display: flex;
  justify-content: flex-start;
}
.button--text {
  text-decoration: underline;
}
</style>
