<template>
  <div class="am2-email-editor">
    <RichTextEmailEditor
      v-if="type === 'rich-text'"
      ref="rich-text-email-editor"
      :value="richTextEmailHtml"
      :dynamic-tags="dynamicTags"
      @input="handleRichTextTemplateInput"
    />
    <UnlayerEmailEditor
      v-else-if="type === 'unlayer'"
      ref="unlayer-email-editor"
      :dynamic-tags="dynamicTags"
      :show-dynamic-tags-tool="showDynamicTagsTool"
      @outputDesign="handleUnalyerTemplateUpdate"
      @outputHtml="handleUnlayerHtmlUpdate"
      @addDynamicTagsToolClick="handleAddImportDynamicTagsToolClick"
      @addDynamicTagsToolNotFound="handleAddImportDynamicTagsToolNotFound"
    />
  </div>
</template>

<script>
import UnlayerEmailEditor from '../unlayer-email-editor';
import RichTextEmailEditor from '../rich-text-email-editor';

/**
 * This is the interface that encapsulates the logic for handling different types of emails,
 * so we don't have to worry about switch email types repeatedly.
 */
export default {
  name: 'NewEmailEditor',
  components: {
    RichTextEmailEditor,
    UnlayerEmailEditor,
  },
  props: {
    type: {
      type: String,
      validator: (val) => ['unlayer', 'rich-text', 'custom'].indexOf(val) > -1,
    },
    dynamicTags: {
      type: Array,
      default: () => [],
    },
    showDynamicTagsTool: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      richTextEmailHtml: '',
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    // Don't remove this, it's exported to parent components
    async setDesign(template) {
      await this.$nextTick();
      if (this.type === 'unlayer') {
        if (!this.$refs['unlayer-email-editor']) {
          return;
        }
        this.$refs['unlayer-email-editor'].setDesign(template);
      } else if (this.type === 'rich-text') {
        this.richTextEmailHtml = template;
        this.handleRichTextTemplateInput(template);
      }
    },
    handleUnalyerTemplateUpdate(template) {
      this.$emit('templateUpdate', template);
    },
    handleUnlayerHtmlUpdate(emailHtml) {
      this.$emit('emailHtmlUpdate', emailHtml);
    },
    handleRichTextTemplateInput(template) {
      this.$emit('templateUpdate', template);
      this.$emit('emailHtmlUpdate', template);
    },
    handleAddImportDynamicTagsToolClick() {
      this.$emit('addDynamicTagsToolClick');
    },
    handleAddImportDynamicTagsToolNotFound() {
      this.$emit('addDynamicTagsToolNotFound');
    },
  },
};
</script>

<style lang='scss' scoped>
.am2-email-editor {
  width: 100%;
  height: 100%;
}
</style>
