<template>
  <section class="signup-form-details">
    <ar-text
      size="xxs"
      text="STEP 2"
      :style="{
        color: '#9FA8B5',
      }"
      weight="bold"
    />
    <am2-heading
      class="u-margin-top-2"
      type="h1"
      size="lg"
      title="Form fields"
      multiple-lines
    />
    <!-- Form fields -->
    <ar-text
      class="u-margin-top-12"
      size="md"
      text="Select fields to display"
    />
    <am2-card-container
      :class="[
        'u-margin-top-7',
        'u-padding-y-6',
        'u-padding-x-6'
      ]"
    >
      <!-- section heading -->
      <div
        :class="[
          'u-display-flex',
          'u-align-items-center',
          'u-margin-bottom-4',
        ]"
      >
        <ar-text
          class="u-flex-grow-1"
          size="xs"
          :text="$arMediaQuery.pageContent.maxWidth('xs') ? 'Fields' : 'Registration fields'"
          weight="bold"
        />
        <ar-text
          size="xs"
          text="Optional"
          align="left"
          weight="bold"
          :style="{
            width: $arMediaQuery.pageContent.maxWidth('xs') ? '62px' : '120px',
          }"
        />
        <ar-text
          size="xs"
          text="Enabled"
          align="right"
          weight="bold"
          :style="{ width: '62px' }"
        />
      </div>
      <div
        v-for="(field, idx) of formFields"
        :key="idx"
      >
        <div
          :class="[
            'u-display-flex',
            'u-align-items-center',
          ]"
          :style="{
            height: '52px',
          }"
        >
          <ar-text
            class="u-flex-grow-1"
            size="xs"
            :text="field.name"
          />
          <ar-checkbox
            v-show="scratchSignupForm.registrations.fields[field.key]"
            :value="!scratchSignupForm.registrations.requiredFields[field.key]"
            @input="handleRequiredCheck(field.key, ...arguments)"
            :disabled="field.isMandatory || !field.enabled"
            align="left"
            :style="{
              width: $arMediaQuery.pageContent.maxWidth('xs') ? '62px' : '120px',
            }"
          />
          <am2-switch
            :value="scratchSignupForm.registrations.fields[field.key]"
            @input="handleShowCheck(field.key, ...arguments)"
            :disabled="field.isMandatory || !field.enabled"
            v-tooltip="{
              content: field.isMandatory && field.mandatoryTooltip,
            }"
          />
        </div>
        <ar-divider />
      </div>
      <div class="u-margin-top-5">
        <ar-link-button
          :text="showMoreFields ? 'See fewer fields' : 'See all'"
          @click="handleSeeMoreClick"
        />
      </div>
    </am2-card-container>
    <ar-text
      class="u-margin-top-12"
      size="md"
      text="Privacy"
    />
    <am2-switch-field-card
      :class="[
        'u-margin-top-5',
      ]"
      :style="{
        padding: '16px 24px',
      }"
      title="Enable checkboxes for GDPR/CCPA/TCPA"
      :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal'"
      :value="scratchSignupForm.settings.subscriptionPreferenceSettings.enabled"
      @input="handleSubscriptionPreferenceSettingsEableChange"
    >
      <div>
        <ar-field
          v-if="showCustomDisclaimer"
          label="Disclaimer"
        >
          <ar-input
            placeholder="I would like to receive marketing communications by"
            :value="scratchSignupForm.settings.subscriptionPreferenceSettings.disclaimer"
            @input="handleSubscriptionPreferenceSettingsDisclaimerInput"
          />
        </ar-field>
        <ar-link-button
          v-if="scratchSignupForm.settings.subscriptionPreferenceSettings.enabled && !showCustomDisclaimer"
          text="Add custom disclaimer"
          @click="toggleShowCustomDisclaimer"
        />
      </div>
    </am2-switch-field-card>
    <am2-switch-field-card
      :class="[
        'u-margin-top-5',
      ]"
      :style="{
        padding: '16px 24px',
      }"
      title="Add your Privacy Policy link"
      :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal'"
      :value="showPrivacyPolicy"
      @input="toggleShowPrivacyPolicy"
    >
      <ar-field
        :error-messages="[
          veeErrors.first('privacyPolicyCompanyName'),
          veeErrors.first('privacyPolicyUrl'),
        ]"
      >
        <div
          :class="[
            'u-display-flex',
            'u-justify-content-space-between',
            $arMediaQuery.pageContent.maxWidth('xs') && 'u-flex-flow-column',
            'u-margin-top-3'
          ]"
        >
          <ar-input
            placeholder="Company"
            :value="scratchSignupForm.settings.privacyPolicy ? scratchSignupForm.settings.privacyPolicy.companyName : null"
            @input="handlePrivacyPolicyCompanyNameInput"
            v-validate="'required|privacyPolicyUrlAlsoRequired'"
            autocomplete="organization"
            data-vv-name="privacyPolicyCompanyName"
            data-vv-as="company name"
            type="text"
            :style="{
              width: $arMediaQuery.pageContent.maxWidth('xs') ? '100%' : '30%',
            }"
          />
          <ar-input
            :class="[
              $arMediaQuery.pageContent.maxWidth('xs') && 'u-margin-top-2',
            ]"
            placeholder="Privacy Policy URL"
            :value="scratchSignupForm.settings.privacyPolicy ? scratchSignupForm.settings.privacyPolicy.url : null"
            @input="handlePrivacyPolicyUrlInput"
            v-validate="{
              required: true,
              privacyPolicyCompanyNameAlsoRequired: true,
              url: {
                require_protocol: true,
              },
            }"
            autocomplete="url"
            data-vv-name="privacyPolicyUrl"
            data-vv-as="url"
            type="text"
            :style="{
              width: $arMediaQuery.pageContent.maxWidth('xs') ? '100%' : 'calc(70% - 10px)',
            }"
          />
        </div>
      </ar-field>
    </am2-switch-field-card>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'Details',

  data() {
    return {
      showMoreFields: false,
      showPrivacyPolicy: false,
      showCustomDisclaimer: false,
    };
  },

  computed: {
    ...mapState({
      scratchSignupForm: state => state.signupForm.scratchSignupForm,
    }),
    formFields() {
      let fields = [
        {
          name: 'Email address',
          key: 'emailAddress',
          isMandatory: true, // Email is always required
          enabled: true,
          mandatoryTooltip: 'Email address is required',
        },
        {
          name: 'First name',
          key: 'firstName',
          enabled: true,
        },
        {
          name: 'Last name',
          key: 'lastName',
          enabled: true,
        },
        {
          name: 'Mobile number',
          key: 'mobileNumber',
          isMandatory: !!this.scratchSignupForm.socialActions.socialAccounts[0].sms,
          enabled: true,
          mandatoryTooltip: 'Mobile number is required to subscribe to SMS messages',
        },
      ];
      if (this.showMoreFields) {
        fields = fields.concat([
          {
            name: 'Date of birth',
            key: 'dob',
            enabled: true,
          },
          {
            name: 'Gender',
            key: 'gender',
            enabled: true,
          },
          {
            name: 'Zip/post code',
            key: 'postcode',
            enabled: true,
          },
          {
            name: 'City',
            key: 'city',
            enabled: true,
          },
          {
            name: 'State',
            key: 'state',
            enabled: true,
          },
          {
            name: 'Country',
            key: 'country',
            enabled: true,
          },
        ]);
      }
      return fields;
    },
  },

  watch: {
    scratchSignupForm: {
      handler() {
        const privacyPolicy = this.scratchSignupForm.settings.privacyPolicy || null;
        const subscriptionPreferenceSettings = this.scratchSignupForm.settings.subscriptionPreferenceSettings || null;

        if (!this.showPrivacyPolicy && privacyPolicy && (privacyPolicy.companyName || privacyPolicy.url)) {
          this.showPrivacyPolicy = true;
        }
        if (!this.showCustomDisclaimer && subscriptionPreferenceSettings && subscriptionPreferenceSettings.disclaimer) {
          this.showCustomDisclaimer = true;
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
    ]),
    ...mapMutations([
      'signupForm/PUT_SCRATCH_SIGNUP_FORM',
    ]),
    handleSeeMoreClick() {
      this.showMoreFields = !this.showMoreFields;
    },
    toggleShowCustomDisclaimer() {
      this.showCustomDisclaimer = !this.showCustomDisclaimer;
      if (!this.showCustomDisclaimer) {
        this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ settings: { subscriptionPreferenceSettings: { disclaimer: null } } });
      }
    },
    handleShowCheck(fieldKey, value) {
      const patchSignupFormData = {
        registrations: {
          fields: {
            [fieldKey]: value,
          },
          requiredFields: {},
        }
      };

      patchSignupFormData.registrations.requiredFields[fieldKey] = value;
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM'](patchSignupFormData);
    },
    handleRequiredCheck(fieldKey, isOptional) {
      const patchSignupFormData = {
        registrations: {
          fields: {},
          requiredFields: {
            [fieldKey]: !isOptional,
          },
        }
      };

      this['signupForm/PUT_SCRATCH_SIGNUP_FORM'](patchSignupFormData);
    },

    handleSubscriptionPreferenceSettingsEableChange(enabled) {
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ settings: { subscriptionPreferenceSettings: { enabled } } });
      if (!enabled) {
        this.showCustomDisclaimer = false;
        this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ settings: { subscriptionPreferenceSettings: { disclaimer: null } } });
      }
    },

    handleSubscriptionPreferenceSettingsDisclaimerInput(disclaimer) {
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ settings: { subscriptionPreferenceSettings: { disclaimer } } });
    },

    handlePrivacyPolicyCompanyNameInput(companyName) {
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ settings: { privacyPolicy: { companyName } } });
    },

    handlePrivacyPolicyUrlInput(url) {
      this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ settings: { privacyPolicy: { url } } });
    },

    toggleShowPrivacyPolicy() {
      this.showPrivacyPolicy = !this.showPrivacyPolicy;
      if (!this.showPrivacyPolicy) {
        this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({ settings: { privacyPolicy: { companyName: null, url: null } } });
      }
    },
  },

  created() {
    // Logic is, if we have companyName, we check if we have URL or not
    this.$validator.extend('privacyPolicyUrlAlsoRequired', {
      getMessage: field => `You must enter a valid URL`,
      validate: value => {
        if (!value || !this.scratchSignupForm.settings.privacyPolicy) {
          return false;
        } else {
          return !!this.scratchSignupForm.settings.privacyPolicy.url;
        }
      },
    });

    // Logic is, if we have url, we check if we have companyName or not
    this.$validator.extend('privacyPolicyCompanyNameAlsoRequired', {
      getMessage: field => `Please enter company name`,
      validate: value => {
        if (!value) {
          return false;
        } else {
          return !!this.scratchSignupForm.settings.privacyPolicy.companyName;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.signup-form-details {
}
</style>
