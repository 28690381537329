<template>
  <nav class="navigation">

    <div :class="[
      'content',
      'content--centered',
      $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
      $arMediaQuery.pageContent.minWidth('sm') && 'sm-min',
      $arMediaQuery.pageContent.minWidth('md') && 'md-min',
    ]">
      <div class="menu-tab">
        <am2-tabs
          :items="menuItems"
          :selected-tab-index="selectedMenuIndex"
          :item-style="{
            height: '48px',
          }"
          @select="handleMenuSelect"
        />
      </div>

      <div
        class="navigation__controls">
        <slot name="controls" />
      </div>

    </div>

  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Navbar',

  props: {
    menu: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),
    menuItems() {
      return Object.keys(this.menu)
        .map(menuKey => {
          const { to, name, featureKeys } = this.menu[menuKey];
          return {
            title: name,
            value: to,
            featureKeys,
          };
        }).filter(menuItem => {
          if (!menuItem.featureKeys || process.env.arEnableActivationState) {
            return true;
          } else if (this.isFeatureEnabled(menuItem.featureKeys)) {
            return true;
          } else {
            return false;
          }
        });
    },
    selectedMenuIndex() {
      return this.menuItems.findIndex(menuItem => {
        return menuItem.value === this.$route.path;
      });
    },
  },
  methods: {
    handleMenuSelect(item) {
      this.$router.push({
        path: item.value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  background-color: white;
  border-bottom: 1px solid $skyBlueGrey500;
  z-index: 50 !important;

  .menu-tab {
    display: flex;
    padding-top: 32px;
    align-self: flex-start;
    max-width: 100%;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;

    .navigation__controls {
      align-self:flex-start;
      &>div {
        padding-top:14px;
      }
    }

    &.sm-min {
      ul {
        max-width: none;
        overflow:visible;
      }
    }

    &.md-min {
      flex-direction: row;
      padding-top:0;

      .navigation__controls {
        align-self: center;
        &>div {
          padding-top:0;
        }
      }
    }

    &.xs-max {
      ul {
        li {
          margin-right: 26px;
        }
      }
    }
  }
}
.navigation-events {
  .container-fluid {
    @media all and (max-width: 1068px) {
      height: 150px !important;
      flex-direction: column !important;
      & .navigation__controls {
        position: relative;
        top: -12px;
        left: -8px;
      }
    }
  }
}
</style>
