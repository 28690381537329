import { Module } from "vuex";
import { clone, mergeObjects } from '@/utils/helpers/';
import { RootState } from '@/store/modules/types';
import { emailTemplateActions } from './actions';
import { UnlayerTemplate, EmailTemplate, EmailTemplateState, ScratchEmailTemplate, ScratchEmailTemplateChanges } from './types';
import { initScratchEmailTemplate, generateScratchEmailTemplate, getUnlayerHtml } from './utils';

export const initialEmailTemplateStates = (): EmailTemplateState => ({
  unlayerHtmlCacheMap: {},
  // Unlayer template library
  unlayerTemplates: [],
  selectedUnlayerTemplate: null,
  isFetchingUnlayerTemplates: false,
  // Email Templates
  emailTemplates: [],
  totalEmailTemplatesCount: null,
  isFetchingEmailTemplates: false,
  hasFetchEmailTemplatesFailed: false,
  hasNoMoreEmailTemplates: false,
  // Create Email Template
  isCreatingEmailTemplate: false,
  // Selected Email Template
  isFetchingSelectedEmailTemplate: false,
  isUpdatingSelectedEmailTemplate: false,
  selectedEmailTemplate: null,
  // Scratch Email Template
  scratchEmailTemplate: initScratchEmailTemplate(),
});

const emailTemplateModule: Module<EmailTemplateState, RootState> = {
  namespaced: true,
  state: initialEmailTemplateStates(),
  actions: emailTemplateActions,
  mutations: {
    SET_UNLAYER_HTML_CACHE(state, { hash, html }) {
      const newMap = clone(state.unlayerHtmlCacheMap);
      newMap[hash] = html;
      state.unlayerHtmlCacheMap = newMap;
    },
    // Unlayer template library
    SET_UNLAYER_TEMPLATES(state, unlayerTemplates: UnlayerTemplate[]) {
      state.unlayerTemplates = clone(unlayerTemplates);
    },
    RESET_SELECTED_UNLAYER_TEMPLATE(state) {
      state.selectedUnlayerTemplate = initialEmailTemplateStates().selectedUnlayerTemplate;
    },
    SET_SELECTED_UNLAYER_TEMPLATE(state, unlayerTemplate: UnlayerTemplate) {
      state.selectedUnlayerTemplate = clone(unlayerTemplate);
    },
    SET_IS_FETCHING_UNLAYER_TEMPLATES(state, isFetching: boolean) {
      state.isFetchingUnlayerTemplates = isFetching;
    },
    // Email Templates
    RESET_EMAIL_TEMPLATES(state) {
      const {
        emailTemplates,
        totalEmailTemplatesCount,
        isFetchingEmailTemplates,
        hasFetchEmailTemplatesFailed,
        hasNoMoreEmailTemplates,
      } = initialEmailTemplateStates();
      state.emailTemplates = emailTemplates;
      state.totalEmailTemplatesCount = totalEmailTemplatesCount;
      state.isFetchingEmailTemplates = isFetchingEmailTemplates;
      state.hasFetchEmailTemplatesFailed = hasFetchEmailTemplatesFailed;
      state.hasNoMoreEmailTemplates = hasNoMoreEmailTemplates;
    },
    SET_EMAIL_TEMPLATES(state, emailTemplates: EmailTemplate) {
      state.emailTemplates = clone(emailTemplates);
    },
    PATCH_IN_EMAIL_TEMPLATES(state, {
      oid,
      changes
    }: { oid: number, changes: ScratchEmailTemplateChanges }) {
      state.emailTemplates = state.emailTemplates.map(item => {
        if (item.oid !== oid) {
          return item;
        }
        return mergeObjects(item, changes);
      });
    },
    REMOVE_FROM_EMAIL_TEMPLATES(state, oid: number) {
      state.emailTemplates = state.emailTemplates.filter(item => {
        return item.oid !== oid;
      });
    },
    CONCAT_EMAIL_TEMPLATES(state, emailTemplates: EmailTemplate) {
      state.emailTemplates = clone(state.emailTemplates.concat(emailTemplates));
    },
    SET_IS_FETCHING_EMAIL_TEMPLATES(state, isFetching: boolean) {
      state.isFetchingEmailTemplates = isFetching;
    },
    SET_TOTAL_EMAIL_TEMPLATES_COUNT(state, count: number) {
      state.totalEmailTemplatesCount = count;
    },
    SET_HAS_FETCH_EMAIL_TEMPLATES_FAILED(state, hasFailed: boolean) {
      state.hasFetchEmailTemplatesFailed = hasFailed;
    },
    SET_NO_MORE_EMAIL_TEMPLATES(state, noMoreData: boolean) {
      state.hasNoMoreEmailTemplates = noMoreData;
    },
    // Create Email Template
    SET_IS_CREATING_EMAIL_TEMPLATE(state, isCreating: boolean) {
      state.isCreatingEmailTemplate = isCreating;
    },
    // Selected Email Template
    SET_IS_FETCHING_SELECTED_EMAIL_TEMPLATE(stete, isFetching: boolean) {
      stete.isFetchingSelectedEmailTemplate = isFetching;
    },
    SET_IS_UPDATING_SELECTED_EMAIL_TEMPLATE(stete, isUpdating: boolean) {
      stete.isUpdatingSelectedEmailTemplate = isUpdating;
    },
    REET_SELECTED_EMAIL_TEMPLATE(state) {
      state.selectedEmailTemplate = initialEmailTemplateStates().selectedEmailTemplate;
    },
    SET_SELECTED_EMAIL_TEMPLATE(state, emailTemplate: EmailTemplate) {
      state.selectedEmailTemplate = clone(emailTemplate);
    },
    // Scratch Email Template
    RESET_SCRATCH_EMAIL_TEMPLATE(state) {
      state.scratchEmailTemplate = initialEmailTemplateStates().scratchEmailTemplate;
    },
    SET_SCRATCH_EMAIL_TEMPLATE(state, emailTemplate: EmailTemplate) {
      state.scratchEmailTemplate = generateScratchEmailTemplate(emailTemplate);
    },
    PATCH_SCRATCH_EMAIL_TEMPLATE(state, changes: ScratchEmailTemplateChanges) {
      const newScratchSimpleMessage: ScratchEmailTemplate = clone(state.scratchEmailTemplate);
      const comingChanges: ScratchEmailTemplateChanges = clone(changes);
      // Replace template, instead of merging, we don't want to mess up with 'Unlayer template'.
      if (newScratchSimpleMessage.meta.template && comingChanges.meta.template) {
        newScratchSimpleMessage.meta.template = comingChanges.meta.template;
        delete comingChanges.meta.template;
      }
      state.scratchEmailTemplate = mergeObjects(newScratchSimpleMessage, changes);
    },
  },
  getters: {
    getUnlayerHtml(state) {
      return (design: object): string | null => {
        return getUnlayerHtml(state.unlayerHtmlCacheMap, design);
      };
    },
  },
};

export default emailTemplateModule;
