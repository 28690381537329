<template>
  <section class="customer-profile-campaigns"
           v-infinite-scroll="loadMoreCampaigns"
           infinite-scroll-disabled="infiniteScrollDisabled"
           :infinite-scroll-distance="10" >
    <div :class="[
        'campaigns-controls',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max'
      ]">
      <am2-heading
        type="h1"
        size="md"
        class="section-heading"
        title="Campaigns"
      />
      <div :class="[
        'campaigns-controls__rhs',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max'
      ]">
        <am2-search
          v-model="searchQuery"
          placeholder="Search campaigns"
          class="search-input u-margin-right-4"
          @enter="serverSideSearch"
          @clear="serverSideSearch"
        />
        <am2-button-group-tabs
          v-if="$arMediaQuery.pageContent.minWidth('sm')"
          :active-index="layout === 'list' ? 1 : 0"
          :items="groupButtonsSelectItems"
          :style="{
              width: '100px',
            }"
          @select="handleGroupButtonSelectClick"
        />
      </div>
    </div>
    <div
      :class="[
        computedLayout,
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max'
      ]"
      :style="{
        borderRadius: !isCustomerProfileFeatureEnabled ? '5px' : null,
        overflow: !isCustomerProfileFeatureEnabled ? 'hidden' : null,
        filter: isCustomerProfileFeatureEnabled ? null : 'blur(5px)',
        padding: isCustomerProfileFeatureEnabled ? 0 : '20px 20px 6px 20px',
      }"
      v-ar-feature-mask="{
        show: !isCustomerProfileFeatureEnabled,
        showUpgradeOn: 'hover',
        iconName: 'audience',
        title: lockedTitle.campaigns,
        message: 'Unlock customer profiles to see previous campaigns fans have participated in.',
      }"
    >
      <am2-campaign-card
        v-for="campaign in computedCampaigns"
        :key="campaign.oid"
        :show-as="computedLayout"
        :card-size="$arMediaQuery.pageContent.minWidth('sm') ? 'regular' : 'xs'"
        :campaign="campaign"
        :points="campaignPoints(campaign.oid)"
        :referrals="referrals(campaign.oid)"
        layout="customer"
      />
      <div
        v-if="customer && computedCampaigns.length === 0 && !isFetchingCampaigns"
        class="ar-transition-group-item u-width-100-percent"
      >
        <am2-no-content-section
          v-if="searchQuery"
          header="No campaigns found"
          header-size="sm"
        />
        <am2-no-content-section
          v-else
          :header="`${customerState.customer ? customerState.customer.name : ''} has not participated in any campaigns`"
          header-size="sm"
        />
      </div>
      <am2-card-skeleton
        v-for="n in isFetchingCampaigns ? 3 : 0"
        :key="n"
        class="base-plan-card"
        type="campaign"
        :show-as="$arMediaQuery.pageContent.minWidth('sm') ? layout : 'tiles'"
        :level="$arMediaQuery.pageContent.maxWidth('xs') || layout === 'list' ? (2 * n - 1) : (2 * Math.ceil(n / 3) - 1)"
        :style="{
            height: $arMediaQuery.pageContent.maxWidth('xs') || layout === 'tiles' ? '370px' : '165px',
            marginBottom: '10px',
          }"
      />
    </div>
  </section>
</template>

<script>
  import moment from 'moment';
  import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
  import { dummyFanCampaigns } from '@/utils/audience';

  export default {
    name: 'CustomerProfileCampaigns',

    data() {
      return {
        searchQuery: null,
        layout: 'list',
        groupButtonsSelectItems: [
          {
            iconName: 'tile-view',
            showAs: 'tiles',
          },
          {
            iconName: 'list-view',
            showAs: 'list',
          },
        ],
      }
    },

    watch: {
      customer: {
        handler(val, oldVal) {
          if (val && val.oid && !oldVal && !this.isFetchingCampaigns) {
            this.fetchData();
          }
        },
        immediate: true,
      }
    },

    computed: {
      ...mapGetters({
        isFeatureEnabled: 'auth/isFeatureEnabled',
      }),
      ...mapState({
        customerState: state => state.customer,
        customer: state => state.customer.customer,
        isFetchingCampaigns: state => state.customer.isFetchingCampaigns,
      }),

      lockedTitle() {
        return {
          "campaigns": this.customerState?.customer?.firstName ? `What campaigns has ${this.customerState?.customer?.name} participated in?` : `Campaigns`,
        }
      },

      isCustomerProfileFeatureEnabled() {
        return this.isFeatureEnabled(['audience', 'customerProfiles']);
      },

      computedLayout() {
        if (this.computedCampaigns.length === 0) return 'list';
        if (this.$arMediaQuery.pageContent.maxWidth('xs')) return 'tiles';
        return this.layout;
      },

      infiniteScrollDisabled() {
        return this.customerState.customer && ((this.customerState.actionedCampaignOids && this.customerState.customer.campaigns && this.customerState.actionedCampaignOids.length >= this.customerState.customer.campaigns.length) || this.isFetchingCampaigns || this.customerState.hasFetchCustomerCampaignsFailed);
      },

      computedCampaigns() {
        if (!this.isCustomerProfileFeatureEnabled) return dummyFanCampaigns();
        return this.customerState.fanCampaigns;
      }
    },

    async mounted() {
      this['customer/RESET_CUSTOMER_CAMPAIGNS']();
    },

    beforeDestroy() {
      this['customer/RESET_CUSTOMER_CAMPAIGNS']();
    },

    methods: {
      ...mapActions([
        'customer/FETCH_CUSTOMER_CAMPAIGNS',
      ]),
      ...mapMutations([
        'customer/RESET_CUSTOMER_CAMPAIGNS'
      ]),
      async serverSideSearch() {
        this['customer/RESET_CUSTOMER_CAMPAIGNS']();
        await this.fetchData();
      },
      handleGroupButtonSelectClick(item) {
        this.layout = item.showAs;
      },
      isCampaignActive(campaignEndDate) {
        return moment.utc(campaignEndDate).isAfter(moment.utc());
      },

      async loadMoreCampaigns() {
        await this.fetchData(true);
      },

      async fetchData(append) {
        const searchString = this.searchQuery || null;
        this['customer/FETCH_CUSTOMER_CAMPAIGNS']({searchString, append})
      },

      campaignPoints(oid) {
        return this.customer.pointsPerCampaign[oid]
      },

      referrals(oid) {
        return this.customer.referralsPerCampaign[oid]
      },
    }
  };
</script>

<style lang="scss" scoped>
  .customer-profile-campaigns {

    .campaigns-controls {
      margin: 16px 0 22px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 40px;

      &.sm-max {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
      }

      &__rhs {
        display: flex;
        flex-direction: row;

        .search-input {
          width: 270px;
        }

        &.sm-max {
          width: 100%;
          margin-top: 16px;
          .search-input {
            width: 100%;
          }
        }
      }
    }


    .tiles {
      display: grid;
      grid-template-columns: calc(33.3% - 20px) calc(33.3% - 20px) calc(33.3% - 20px);
      grid-gap: 30px;

      &.md-max {
        grid-template-columns: calc(50% - 15px) calc(50% - 15px);
      }

      &.sm-max {
        grid-gap: 12px;
        grid-template-columns: 100%;
      }

      &.xs-max {
        grid-gap: 18px;
      }

    }

    .list {
      .index-card {
        margin-bottom: 20px;
      }
    }

  }
</style>
