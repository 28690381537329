import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { isErrorStatus } from '../utils';
import { CustomApiError } from '../types';
import { Integration, SyncTask } from './types';

export default (axios: NuxtAxiosInstance) => ({
  /**
   * Fetch integrations
   */
  async fetchAll(promoterOid: number): Promise<Integration[]> {
    const { data, status } = await axios.sg.get(`/promoter/${promoterOid}/integration`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Fetch integrations error',
        message: `Error doing fetch integrations`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * Fetch integrations by app + provider
   */
  async fetchByAppProvider(promoterOid: number, app: string, provider: string): Promise<Integration[]> {
    const { data, status } = await axios.sg.get(`/promoter/${promoterOid}/integration`, {
      params: {
        $filter: `app=${app} and provider=${provider}`,
      },
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Fetch integrations by app + provider error',
        message: `Error doing fetch integrations by app + provider`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * Delete integration
   */
  async delete(promoterOid: number, oid: number): Promise<void> {
    const { status } = await axios.sg.delete(`/promoter/${promoterOid}/integration/${oid}`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Delete integrations error',
        message: `Error doing delete integration with oid ${oid}`,
        status,
      };
      throw apiError;
    }
  },
  /**
   * Start sync
   */
  async startSync(promoterOid: number, integrationOid: number): Promise<SyncTask | null> {
    const { data, status } = await axios.sg.post(`/promoter/${promoterOid}/integration/${integrationOid}/sync`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Start sync error',
        message: `Error doing start sync`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * Stop sync
   */
  async stopSync(promoterOid: number, integrationOid: number): Promise<SyncTask | null> {
    const { data, status } = await axios.sg.post(`/promoter/${promoterOid}/integration/${integrationOid}/sync-stop`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Stop sync error',
        message: `Error doing stop sync`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * Fetch sync task
   */
  async fetchSyncTask(promoterOid: number, integrationOid: number): Promise<SyncTask | null> {
    const { data, status } = await axios.sg.get(`/promoter/${promoterOid}/integration/${integrationOid}/task`, {
      params: {
        $orderby: 'sysMtime desc',
        $top: 1,
        $filter: `promoterIntegrationOid=${integrationOid}`,
      }
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Fetch sync task error',
        message: `Error doing fetch sync task`,
        status,
      };
      throw apiError;
    }
    return data[0] || null;
  },
  /**
   * A manual way of creating Memberful integration
   * @returns SyncTask - Once auth is created, Sync task is kicked off and returned
   */
  async createMemberfulIntegration(siteName: string, apiKey: string): Promise<SyncTask> {
    const { data, status } = await axios.sg.post('/simple-auth', {
      provider: 'memberful',
      siteName,
      apiKey,
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Create Memberful Integration error',
        message: `Error creating Memberful integration`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * A direct way to integration 3rd-party services
   * @returns SyncTask - Once auth is created, Sync task is kicked off and returned
   */
  async simpleAuth(username: string, password: string): Promise<SyncTask> {
    const { data, status } = await axios.sg.post('/simple-auth', {
      username,
      password,
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Simple Auth error',
        message: `Error doing simple auth`,
        status,
      };
      throw apiError;
    }
    return data;
  },
});
