import { AvailableMessageOptionsMap, CsvPreviewContact, RecipientFilter } from './types';
import moment from 'moment';
// @ts-ignore
import * as Papa from 'papaparse';
import { CURRENCY_CODES } from '~/utils/constants';
import { arDynamicTagsRegexGlobal } from '@/utils/regex/';
import { AdvancedMessageListTargeting } from '@/store/modules/messageList/types';
import { clone } from '@/utils/helpers';

export const calculateScheduleMessageCountdown = (
  message: SimpleMessage | EmailMessage,
  applicationTime: number,
): number => {
  // Has been sent, no need countdown
  if (message.started) { return 0; }
  const scheduledAtTime = moment.utc(message.scheduledAt).toDate().getTime();
  const countdownMilliSeconds = scheduledAtTime - applicationTime;
  const countdownSeconds = Math.floor(countdownMilliSeconds / 1000);

  return countdownSeconds > 0 ? countdownSeconds : 0;
}

export const generateScheduledMessageCountdownCopy = (countdown: number): string => {
  if (countdown === 0) { return 'This message has been sent'; }

  let template;
  let remainingTime;
  if (countdown < 300) {
    template = 'This message will be sent in {{time}}';
    remainingTime = countdown;
  } else {
    template = '{{time}} until the message is not editable';
    remainingTime = countdown - 300;
  }

  const days = Math.floor(remainingTime / 86400);
  remainingTime = remainingTime % 86400;
  const hours = Math.floor(remainingTime / 3600);
  remainingTime = remainingTime % 3600;
  const minutes = Math.floor(remainingTime / 60);
  remainingTime = remainingTime % 60;
  const seconds = remainingTime;

  let timecopy = '';
  if (days > 0) { timecopy += ` ${days} day${days > 1 ? 's' : ''}` }
  if (hours > 0) { timecopy += ` ${hours} hour${hours > 1 ? 's' : ''}` }
  if (minutes > 0) { timecopy += ` ${minutes} minute${minutes > 1 ? 's' : ''}` }
  if (seconds > 0) { timecopy += ` ${seconds} second${seconds > 1 ? 's' : ''}` }

  return template.replace('{{time}}', timecopy);
}

export const getMessageUiStatus = function (message: SimpleMessage | EmailMessage): MessageStatus {
  return message.meta && message.meta.uiStatus ? message.meta.uiStatus : message.status;
}

export const getAvailableMessageOptionsMap = function (message: SimpleMessage | EmailMessage) {
  const availableMessageOptionsMap: AvailableMessageOptionsMap = {
    duplicate: false,
    view: false,
    edit: false,
    archive: false,
    cancel: false,
  };

  const uiStatus = getMessageUiStatus(message);

  if (uiStatus === 'failed') {
    availableMessageOptionsMap.view = true;
    availableMessageOptionsMap.duplicate = true;
    availableMessageOptionsMap.archive = true;
  }

  if (uiStatus === 'draft') {
    availableMessageOptionsMap.duplicate = true;
    availableMessageOptionsMap.edit = true;
    availableMessageOptionsMap.archive = true;
  }

  if (uiStatus === 'archived') {
    availableMessageOptionsMap.view = true;
    availableMessageOptionsMap.duplicate = true;
  }

  if (uiStatus === 'in-progress') {
    availableMessageOptionsMap.view = true;
    availableMessageOptionsMap.duplicate = true;
  }

  if (uiStatus === 'completed') {
    availableMessageOptionsMap.view = true;
    availableMessageOptionsMap.duplicate = true;
    availableMessageOptionsMap.archive = true;
  }

  if (uiStatus === 'scheduled') {
    availableMessageOptionsMap.duplicate = true;
    availableMessageOptionsMap.archive = true;
    availableMessageOptionsMap.cancel = true;

    const countdown = calculateScheduleMessageCountdown(message, new Date().getTime());
    if (countdown > 300) {
      availableMessageOptionsMap.edit = true;
    }
  }

  if (uiStatus === 'cancelled') {
    availableMessageOptionsMap.view = true;
    availableMessageOptionsMap.duplicate = true;
    availableMessageOptionsMap.archive = true;
  }

  return availableMessageOptionsMap;
}

export const getInitialSmsPreview = function () {
  return {
    sender: '',
    body: '',
    totalRecipients: 0,
    cost: {
      currency: 'US',
      total: 0,
      recipients: 0,
      regionNotFound: 0,
    },
    charactersLeft: 1550,
    optOutMessage: '',
  };
}

export const getInitialScratchSimpleMessage = function (): ScratchSimpleMessage {
  return {
    oid: null,
    provider: null,
    meta: {
      messageListOid: null,
      initiator: {
        campaignOid: null,
      },
      messageBody: '',
      dynamicTagHeaders: [],
      tagsResourceOid: null,
      tagMaxWidthMap: {},
      presentation: {
        timeZone: null,
      },
      facebookMessenger: {
        pageId: null,
        pageName: null,
      },
      recipientFilter: null,
    },
    scheduledAt: null,
  };
}

export const getInitialScratchEmailMessage = function (): ScratchEmailMessage {
  return {
    oid: null,
    meta: {
      disableLinkTracking: false,
      email: {
        promoterPropertyOid: null,
      },
      initiator: {
        campaignOid: null,
      },
      messageListOid: null,
      messageBody: {
        previewText: null,
        subject: null,
      },
      dynamicTagHeaders: [],
      tagsResourceOid: null,
      presentation: {
        template: null,
        templateType: 'unlayer',
        timeZone: null,
      },
      recipientFilter: null,
      templateResourceOid: null,
    },
    scheduledAt: null,
    provider: 'email',
  };
}

export const getMessageCurrency = function(message:Message) {
  if (
    !message ||
    !message.statusDetails ||
    !message.statusDetails.costs ||
    (!message.statusDetails.costs.currencyCode && !message.statusDetails.costs.currency)) return null;

  let code = message.statusDetails.costs.currencyCode || message.statusDetails.costs.currency;
  if (code === 'US') code = 'USD';

  let currency = CURRENCY_CODES.find( currency => currency.code === code);
  if (!currency) return null;

  return currency;
}

export const countSmsPreviewSegments = function (tagMaxWidthMap: MessageTagMaxWidthMap, messageBody: string) {
  let dynamicTagsActionedMessage = messageBody;

  // Locally replace dynamic tags with a string with a length matching the longest item in the given column
  // Eg, if the longest first_name is 15 characters, replace {{first_name}} with 'xxxxxxxxxxxxxxx'
  if (tagMaxWidthMap) {
    Object.keys(tagMaxWidthMap).forEach( item => {
      const maxLength = tagMaxWidthMap[item] || 0;
      const dummyString = [...Array(maxLength)].map(_ => 'x').join('');
      const regexString = new RegExp('\\{\\{' + item + '\\}\\}', 'g');
      dynamicTagsActionedMessage = dynamicTagsActionedMessage.replace(regexString, dummyString);
    });
  }

  // Set number of segments to 0 if messagebody is empty
  if (dynamicTagsActionedMessage.length === 0) {
    return 0;
  }

  return dynamicTagsActionedMessage.length <= 160 ?
        1 : Math.ceil(dynamicTagsActionedMessage.length/153);
}

// Calculate SMS message length, taking into account the max size of dynamic tags and the length of short URLs
export const countSmsBodyLength = function (tagMaxWidthMap: MessageTagMaxWidthMap, messageBody: string) {
  let dynamicTagsActionedMessage = messageBody;
  if (tagMaxWidthMap) {
    Object.keys(tagMaxWidthMap).forEach( item => {
      const maxLength = tagMaxWidthMap[item] || 0;
      const dummyString = [...Array(maxLength)].map(_ => 'x').join('');
      const regexString = new RegExp('\\{\\{' + item + '\\}\\}', 'g');
      dynamicTagsActionedMessage = dynamicTagsActionedMessage.replace(regexString, dummyString);
    });
  }
  return dynamicTagsActionedMessage.length;
};

export const mergeDynamicTags = (dynamicTagsA: string[], dynamicTagsB: string[]): string[] => {
  const dynamicTags: string[] = clone(dynamicTagsA.concat(dynamicTagsB));
  return dynamicTags.filter((tag, tagIdx) => {
    return dynamicTags.indexOf(tag) === tagIdx; // This will prevent duplicated tags
  });
};

export const getNoneExistingDyanmicTags = (content: string, dynamicTags: string[]) => {
  const matchTags = content.match(arDynamicTagsRegexGlobal) || [];
  const prunedMatchTags = matchTags.map(tag => tag.replace('{{', '').replace('}}', ''));
  return prunedMatchTags.filter((tag, tagIdx) => {
    return dynamicTags.indexOf(tag) === -1
      && prunedMatchTags.indexOf(tag) === tagIdx; // This will prevent duplicated tags
  });
};

export const generateNonExistingDynamicTagsErrorMessage = (nonExistingDynamiTags: string[], contentAlias: string) => {
  if (nonExistingDynamiTags.length === 0) {
    return null;
  }
  let tagListCopy = nonExistingDynamiTags.map(tag => `<strong>"${tag}"</strong>`).join(', ');
  let copy;
  if (nonExistingDynamiTags.length === 1) {
    copy = `This dynamic tag in the ${contentAlias} does not exist in the dynamic tags file: `;
  } else {
    copy = `These dynamic tags in the ${contentAlias} do not exist in the dynamic tags file: `;
  }
  return copy + tagListCopy;
};

export const generatePreviewCountacts = (headers: string[], rows: string[]): CsvPreviewContact[] => {
  const contacts: CsvPreviewContact[] = []
  for (let i = 0; i < 5; i += 1) {
    const contactRow = rows[i];
    if (!contactRow) continue;
    if (contactRow.length === 1 && !contactRow[0]) continue;
    const contact: CsvPreviewContact = {};
    for (let j = 0; j < headers.length; j += 1) {
      contact[headers[j]] = contactRow[j];
    }
    contacts.push(contact);
  }
  return contacts;
};

// Converts a recipient filter stored within a message task into a set of targeting rules to be used in the UI
// Expects that the filter will only have one condition. This function will need to be edited if we decide to support
// multiple conditions down the line.
export const recipientFilterToTargetingRules = (recipientFilter: RecipientFilter | null): AdvancedMessageListTargeting => {
  let targeting: AdvancedMessageListTargeting = {
    type: "all",
    condition: "include",
    subCondition: null,
    values: [],
  };
  if (!recipientFilter) return targeting;

  if (recipientFilter.conditions.length === 0) return targeting;

  if (recipientFilter.conditions[0].name === "tags") {
    targeting.type = "tag"
  } else {
    // @ts-ignore
    if (recipientFilter.conditions[0].name === "messages_opened") {
        targeting.type = "messages_opened"
      } else {
      // @ts-ignore
      if (recipientFilter.conditions[0].name === "messages_clicked") {
        targeting.type = "messages_clicked"
      }
    }
  }

  if (recipientFilter.conditions[0].data.condition === "false_to_all" || recipientFilter.conditions[0].data.condition === "false_to_any") {
    targeting.condition = "exclude";
  }

  if (targeting.type === "tag") {
    targeting.subCondition = recipientFilter.conditions[0].data.condition;
  }

  targeting.values = clone(recipientFilter.conditions[0].data.values);
  return targeting;
}

export const purifyUnlayerDesign = function (parentKey: string | number | null, obj: any): void {
  if (parentKey === 'values') {
    if (obj.hasOwnProperty('meta')) {
      console.log('Found incorrect "meta" field in Unlayer design, update it to "_meta"')
      obj._meta = obj.meta;
      delete obj.meta;
    }
    if (obj.hasOwnProperty('override')) {
      console.log('Found incorrect "override" field in Unlayer design, update it to "_override"')
      obj._override = obj.override;
      delete obj.override;
    }
  }
  if (typeof obj === 'object') {
    for (let prop in obj) {
      purifyUnlayerDesign(prop, obj[prop]);
    }
  }
}
