var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"campaign-view"},[(_vm.isFetchingCampaign)?_c('am2-hero-skeleton',{attrs:{"type":"hero","back-path":"/campaigns","is-campaigns":""}}):(_vm.currentCampaign)?_c('am2-hero',{attrs:{"image":_vm.campaignImage,"title":_vm.currentCampaign.name,"type":_vm.currentCampaign.type,"timeframe":_vm.campaignTimeframe,"url-slug":_vm.currentCampaign.urlSlug,"event-oid":_vm.currentCampaign.event.oid,"event-name":_vm.currentCampaign.event.name,"back-path":"/campaigns","is-campaigns":""}}):_vm._e(),_vm._v(" "),_c('am2-navbar',{attrs:{"menu":_vm.menu}},[_c('div',{attrs:{"slot":"controls"},slot:"controls"},[(_vm.isFetchingCampaign)?_c('am2-loading-section',{staticClass:"buttons-section",style:({
          width: '200px',
          height: '50px',
        })}):_c('div',{staticClass:"buttons-section"},[_c('ar-simple-button',{attrs:{"text":"View Campaign","icon-name":"preview","icon-distance":"13px","icon-props":{
            color: _vm.$arStyle.color.blueGrey700,
          },"type":"grey"},on:{"click":function($event){return _vm.viewCampaign(_vm.currentCampaign.urlSlug)}}}),_vm._v(" "),_c('am2-expand-icon-button',{staticClass:"campaign-button",attrs:{"icon-name":"result-share","icon-size":{ width: '20px' },"text":_vm.$arMediaQuery.pageContent.minWidth('sm') ? 'Share Results' : ''},on:{"click":_vm.shareCampaign}}),_vm._v(" "),_c('am2-split-dropdown',{staticClass:"campaign-button",style:({
            width: '196px',
          }),attrs:{"text":"New Message","toggle-dropdown-on-button-click":"","items":_vm.messageButtonOptions,"icon-name":"dialog","icon-distance":"12px"},on:{"select":function (item) { return item.action(); }}}),_vm._v(" "),_c('am2-campaign-dropdown',{staticClass:"campaign-button",attrs:{"kind":"expand-icon-button","preventExpand":!_vm.$arMediaQuery.pageContent.minWidth('sm'),"campaign":_vm.currentCampaign},on:{"delete":_vm.handleCampaignDelect}})],1)],1)]),_vm._v(" "),_c('nuxt-child',{class:['child-content', _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-min' ],attrs:{"campaign":_vm.currentCampaign}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }