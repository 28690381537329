<template>
  <section class="campaign-edit-connect">
    <am2-edit-privacy-policy-modal
      :opened="displayEditPrivacyPolicyModal"
      :default-company-name="editPrivacyPolicyDefaultCompanyName"
      :default-url="editPrivacyPolicyDefaultUrl"
      @confirm="handleEditPrivacyPolicyConfirm"
      @cancel="handleEditPrivacyPolicyCancel"
    />
    <ar-text
      class="step-heading"
      size="xs"
      text="STEP 6"
      :style="{
        color: '#9FA8B5',
        marginBottom: '8px',
      }"
    />
    <am2-heading
      type="h2"
      size="lg"
      title="Social: Follow &amp; connect"
      :style="{marginBottom: '38.5px'}"
    />
    <am2-heading
      type="h3"
      size="md"
      title="Follow"
      :style="{ marginBottom: '12px' }"
    />

    <p class="large">
      Fans earn points for following you on social media. Grow your audience by adding the social accounts for your event. You can also add associated brands (e.g. Artists or Sponsors).
    </p>

    <am2-field
      v-for="(account, accountIndex) in followAccountsAndOptions"
      ref="socialAccount"
      :key="`f_${accountIndex}`"
      :name="`social-follow-channels_${accountIndex}`"
      :class="[
        'social-follow-channels',
        $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-x-3',
      ]">

      <ar-icon-button
        v-if="campaignViewModel.followAndConnect.followAccounts && campaignViewModel.followAndConnect.followAccounts.length > 1"
        class="remove-social-account"
        :icon-props="{
          name: 'discard',
          height: '14px',
        }"
        @click="campaignViewModel.followAndConnect.followAccounts.splice(accountIndex, 1)" />
      <div
        class="follow-account-label"
      >
        <div class="label-description">
          <am2-heading
            type="h4"
            size="xs"
            title="Social account name"
            weight="bold"
          />
          <ar-checkbox
            class="save-account-label"
            v-model="account.account.save"
            label="Save Account"
            align="right"
          />
        </div>
        <div>
          <span>
            Name of the event, organisers or sponsor{{ editCampaign.event ? `, e.g. ${editCampaign.event.name}` : '' }}
          </span>
        </div>
        <br>
      </div>
      <ar-auto-complete-input
        :suggestions="accountSelectItems"
        v-validate="{ required: forceAccountName[accountIndex] }"
        placeholder="Account name..."
        :value="account.account.name"
        :data-vv-name="`accountName-${accountIndex}`"
        data-vv-as="account name"
        @input="(val) => handleAccountNameSelect(val, accountIndex)"
      />
      <ar-state-message
        v-if="veeErrors.has(`accountName-${accountIndex}`)"
        type="error"
        :text="veeErrors.first(`accountName-${accountIndex}`)"
        :style="{ marginTop: '8px' }"
      />

      <div
        v-for="(followOption, index) in account.optionsVisible"
        :key="followOption.provider"
        class="social-account__item">
        <div :style="{ margin: '5px auto' }">
          <div :class="[
            'social-account__item-inner',
            currentFocusFollowOption === `socialInput-${accountIndex}-${index}` && 'social-account__item-inner-focus',
          ]">
            <ar-icon
              class="connect-icon"
              :name="followOption.iconName"
              :color="followOption.iconColor"
              height="16px"
              width="16px"
            />

            <ar-text
              class="social-account__item-label"
              size="xs"
              weight="bold"
              :text="formatName(followOption.provider)"
            />

            <ar-simple-select
              v-if="followOption.type === 'select'"
              class="select"
              hide-arrow
              :placeholder="followOption.placeholder"
              :items="followOption.items"
              :default-select-index="findSocialActionSelectIndex(followOption.items, campaignViewModel.followAndConnect.followAccounts[accountIndex][followOption.provider], followOption.defaultSelectIndex)"
              @select="handleSocialActionSelect(accountIndex, followOption.provider, 'value', ...arguments)"
              @clear="handleSocialActionClear(accountIndex, followOption.provider)"
            />
            <am2-facebook-page-select
              v-else-if="followOption.provider === 'facebook:messenger'"
              class="select"
              hide-arrow
              enable-clear
              :default-selected-page-id="campaignViewModel.followAndConnect.followAccounts[accountIndex][followOption.provider]"
              @select="handleSocialActionSelect(accountIndex, followOption.provider, 'id', ...arguments)"
              @clear="handleSocialActionClear(accountIndex, followOption.provider)"
            />
            <ar-input
              v-else
              class="input"
              v-validate="followOption.vValidateRule"
              :ref="`socialInput-${accountIndex}-${index}`"
              v-model="campaignViewModel.followAndConnect.followAccounts[accountIndex][followOption.provider]"
              :placeholder="followOption.placeholder"
              :data-vv-name="`${followOption.vValidateRule}${accountIndex}`"
              enable-clear
              @focus="currentFocusFollowOption = `socialInput-${accountIndex}-${index}`"
              @enter="currentFocusFollowOption = null"
              @blur="fieldBlurAction(followOption.provider, campaignViewModel.followAndConnect.followAccounts[accountIndex][followOption.provider], campaignViewModel.followAndConnect.followAccounts[accountIndex])"
              :style="{
                background: 'none',
              }"
            />
          </div>
          <ar-state-message
            v-show="veeErrors.has(`${followOption.vValidateRule}${accountIndex}`)"
            :text="veeErrors.first(`${followOption.vValidateRule}${accountIndex}`)"
            :style="{ marginLeft: '8px' }"
            type="error"
          />
        </div>
      </div>

      <div class="social-action-link-buttons">
        <ar-link-button
          :text="hideExtraFields ? 'Show all platforms' : 'Show fewer platforms'"
          @click="toggleExtraFields"
        />
        <ar-link-button
          text="Edit privacy policy"
          @click="handleEditPrivacyPolicyClick(accountIndex)"
        />
      </div>

    </am2-field>

    <ar-simple-button
      :style="{ width: '100%', margin: '20px 0 56px' }"
      icon-name="circle-plus"
      icon-distance="9px"
      :text="campaignViewModel.followAndConnect.followAccounts === 0 ? 'Add an account' : 'Add another account'"
      @click="addAccount"
    />

    <am2-heading
      type="h3"
      size="md"
      title="Connect"
      :style="{ marginBottom: '13px' }"
    />
    <p class="large">
      Fans earn points for connecting their social accounts. Select the social accounts fans can connect and you'll collect data including age, gender, location, number of social media followers and top artists/tracks.
    </p>

    <am2-field
      name="social-connect-channels"
      :class="[
        'social-connect-channels',
        $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-3',
      ]">
      <div
        v-for="(connect, index) in campaignViewModel.followAndConnect.connectChannels"
        :key="connect.key"
      >
        <ar-checkbox
          v-model="connect.selected"
          :icon-name="connect.iconName"
          :icon-props="{ color: connect.iconColor }"
          :label="connect.name"
          align="right"
          :style="{ width: '100%', height: '52px' }"
        />
        <ar-divider
          v-if="index !== campaignViewModel.followAndConnect.connectChannels.length - 1"
        />
      </div>
    </am2-field>

  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { generateHash, clone } from '@/utils/helpers/';
import { formatProviderName } from '@/utils/campaign';

export default {
  name: 'EditConnect',

  props: {
    editCampaign: {
      type: Object
    },
    setEditCampaign: {
      type: Function
    }
  },

  data() {
    const followOptions = [
      {
        provider: 'sms',
        vValidateRule: '',
        placeholder: 'SMS',
        iconName: 'sms',
        iconColor: this.$arStyle.color.sms,
        enabled: true,
        type: 'select',
        items: [
          {
            name: 'Enable',
            value: 'enable',
          },
          {
            name: 'Disable',
            value: undefined,
          },
        ],
        defaultSelectIndex: 1,
      },
      {
        provider: 'email',
        vValidateRule: '',
        placeholder: 'Email',
        iconName: 'email',
        iconColor: this.$arStyle.color.email,
        enabled: true,
        type: 'select',
        items: [
          {
            name: 'Enable',
            value: 'enable',
          },
          {
            name: 'Disable',
            value: undefined,
          },
        ],
        defaultSelectIndex: 1,
      },
      {
        provider: 'facebook:messenger',
        vValidateRule: '',
        placeholder: 'Facebook page',
        iconName: 'messenger',
        iconColor: this.$arStyle.color.messenger,
        enabled: process.env.arEnableFbMessenger
      },
      {
        provider: 'instagram',
        vValidateRule: 'instagramUsername',
        placeholder: 'Instagram username',
        iconName: 'instagram',
        iconColor: null,
        enabled: true,
        blurAction: input => {
          return input.indexOf('@') === 0 ? input.substring(1) : input
        },
      },
      {
        provider: 'facebook:event',
        vValidateRule: 'facebookEventUrl',
        placeholder: 'Facebook event URL',
        iconName: 'facebook-event',
        iconColor: null,
        enabled: true,
        blurAction: input => {
          const newInput = input.indexOf('http://') === 0 ? `https://${input.substring(7)}` : input
          return newInput.indexOf('https://') < 0 ? `https://${newInput}` : newInput
        },
      },
      {
        provider: 'facebook',
        vValidateRule: 'facebookUrl',
        placeholder: 'Facebook URL',
        iconName: 'facebook',
        iconColor: this.$arStyle.color.facebook,
        enabled: true,
        blurAction: input => {
          const newInput = input.indexOf('http://') === 0 ? `https://${input.substring(7)}` : input
          return newInput.indexOf('https://') < 0 ? `https://${newInput}` : newInput
        },
      },
      {
        provider: 'spotify:playlist',
        vValidateRule: 'spotifyPlaylistUrl',
        placeholder: 'Spotify playlist URL',
        iconName: 'spotify',
        iconColor: this.$arStyle.color.spotify,
        enabled: true
      },
      {
        provider: 'spotify:artist',
        vValidateRule: 'spotifyArtistUrl',
        placeholder: 'Spotify artist URL',
        iconName: 'spotify',
        iconColor: this.$arStyle.color.spotify,
        enabled: true
      },
      {
        provider: 'spotify:track',
        vValidateRule: 'spotifyTrackUrl',
        placeholder: 'Spotify track URL',
        iconName: 'spotify',
        iconColor: this.$arStyle.color.spotify,
        enabled: true
      },
      {
        provider: 'twitter',
        vValidateRule: 'twitterUsername',
        placeholder: 'Twitter username',
        iconName: 'twitter',
        iconColor: this.$arStyle.color.twitter,
        enabled: true
      },
      {
        provider: 'youtube',
        vValidateRule: 'youtubeChannelUrl',
        placeholder: 'YouTube URL',
        iconName: 'youtube',
        iconColor: this.$arStyle.color.youtube,
        enabled: true,
        blurAction: input => {
          const newInput = input.indexOf('http://') === 0 ? `https://${input.substring(7)}` : input
          return newInput.indexOf('https://') < 0 ? `https://${newInput}` : newInput
        },
      },
    ]

    return {
      displayEditPrivacyPolicyModal: false,
      editPrivacyPolicyAccountIndex: null,
      editPrivacyPolicyDefaultCompanyName: null,
      editPrivacyPolicyDefaultUrl: null,
      currentFocusFollowOption: null,
      followOptions,
      campaignViewModel: {
        followAndConnect: {
          followAccounts: [],
          connectChannels: [],
        },
        savedAccounts: [],
      },
      hideExtraFields: true,
      // forceAccountName checks to see whether each account is required to have an account name attached to it.
      // Generally, this value will be true unless the account's social fields are all empty.
      forceAccountName: {
        0: false,
      },
      mounted: false,
    };
  },

  computed: {

    accountSelectItems() {
      return this.campaignViewModel.savedAccounts.filter(a => a.name).map(a => a.name);
    },


    /* Returns Accounts and the Options which are visible within them */
    followAccountsAndOptions() {

      // Fetches all of the enabled follow options first
      const enabled = this.followOptions.filter(({enabled}) => enabled);

      // For each account, return follow options only if all fields are shown, the item position is < 5 or the item has data
      const followAndOptionsReturnData = this.campaignViewModel.followAndConnect.followAccounts.map( item => {
        let optionsVisible = enabled.filter( (enabledOption, index) => {
          return !this.hideExtraFields
            || index < 5
            || !!item[enabledOption.provider];
        });
        return {
          account: item,
          optionsVisible,
        };
      });

      return followAndOptionsReturnData;
    },
  },

  watch: {
    campaignViewModel: {
      handler(value) {
        const { followAndConnect, savedAccounts } = value
        let { connectChannels, followAccounts } = followAndConnect

        followAccounts = followAccounts
          .map((followAccount) => Object.filter(followAccount, target => target !== "" && target !== null))
          .map(followAccount => {
            const clonedFollowAccount = clone(followAccount);
            /**
             * DO NOT TAKE THIS OUT
             * We have to make sure every follow account has "key"
             */
            if (!clonedFollowAccount.key) {
              clonedFollowAccount.key = generateHash();
            }
            return clonedFollowAccount;
          });

        const connectMap = connectChannels.reduce((accum, item, index) => {
          accum[item.key] = item.selected
          return accum
        }, {})

        const socialActions = {
          connects: connectMap,
          socialAccounts: followAccounts,
        }

        this.setEditCampaign({socialActions});

        // Set force account name for each applicable accountIndex
        followAccounts.forEach( (account, accountIndex) => {
          const hasValidAccounts =
            Object.keys(account)
              .filter(item => (item !== 'name' && item !== 'key'))
              .some(key => !!account[key])
          this.forceAccountName[accountIndex] = hasValidAccounts
        })

      },
      deep: true
    }
  },

  created() {
    this.initializeCampaignViewModel();
  },

  methods: {
    ...mapActions(['FETCH_SAVED_SOCIAL_ACCOUNTS', 'DELETE_SOCIAL_ACCOUNT']),

    initializeCampaignViewModel() {
      const followAccounts = 
        this.editCampaign.socialActions &&
        this.editCampaign.socialActions.socialAccounts &&
        this.editCampaign.socialActions.socialAccounts.length > 0 ?
          this.editCampaign.socialActions.socialAccounts : [{}];
      const connects =
        this.editCampaign.socialActions &&
        this.editCampaign.socialActions.connects
          ? this.editCampaign.socialActions.connects || []
          : [];

      // Convert social action ID's to pretty URLs
      // followAccounts = followAccounts.map(account => ({
      //   facebook: account.facebook,
      //   instagram: account.instagram,
      //   'facebook:event': `https://www.facebook.com/events/${account['facebook:event']}`,
      //   youtube: account.youtube,
      //   snapchat: account.snapchat,
      //   'spotify:playlist': account['spotify:playlist'],
      //   'spotify:artist': account['spotify:artist'],
      //   'spotify:track': account['spotify:track'],
      //   twitter: account.twitter,
      //   name: account.name,
      // }));

      this.campaignViewModel.followAndConnect =
        {
          followAccounts,
          connectChannels: [
            // Temporarily remove facebook since facebook updated it's terms that we're not allowed to collect secret info
            // {
            //   name: 'Facebook',
            //   key: 'facebook',
            //   iconName: 'facebook',
            //   iconColor: this.$arStyle.color.facebook,
            //   selected: connects.facebook != null ? connects.facebook : true, // default true
            // },
            {
              name: 'Twitter',
              key: 'twitter',
              iconName: 'twitter',
              iconColor: this.$arStyle.color.twitter,
              selected: connects.twitter != null ? connects.twitter : true, // default true
            },
            // Temporarily remove facebook since facebook updated it's terms that we're not allowed to collect secret info
            // {
            //   name: 'Instagram',
            //   key: 'instagram',
            //   iconName: 'instagram',
            //   iconColor: null,
            //   selected: connects.instagram != null ? connects.instagram : true, // default true
            // },
            {
              name: 'Spotify',
              key: 'spotify',
              iconName: 'spotify',
              iconColor: this.$arStyle.color.spotify,
              selected: connects.spotify != null ? connects.spotify : true, // default true
            },
          ]
        }

      this.fetchSocialAccounts();
      this.$validator.validateAll();
    },


    fieldBlurAction(provider, value, account) {
      this.currentFocusFollowOption = null

      if (!provider || !value || !account) {
        return
      }

      const foundProvider = this.followOptions.find( element => {
        return element.provider === provider
      })

      if (foundProvider && typeof foundProvider.blurAction === 'function') {
        account[provider] = foundProvider.blurAction(value);
      }
    },

    formatName(followTarget) {
      return followTarget.indexOf(':') > -1 ?
        formatProviderName(followTarget.split(':')[1]) :
        formatProviderName(followTarget);
    },
    leftMargin(optIndex, accountIndex) {
      if (!this.$refs.socialAccount[accountIndex]) {
        return 0;
      }

      const accountInput = this.$refs[`socialInput-${accountIndex}-${optIndex}`][0].$el.querySelectorAll('input')[0];

      const measureSpans = this.$refs.socialAccount[accountIndex].$el.querySelectorAll(
        'span.measure'
      );

      const spanElm = measureSpans[optIndex]; // this gets the 'measure' span element
      spanElm.textContent = accountInput.value; // the hidden span takes the value of the input;

      return { left: `${spanElm.offsetWidth + 30}px` };
    },
    addAccount() {
      this.campaignViewModel.followAndConnect.followAccounts.push({});
    },
    async fetchSocialAccounts() {
      this.campaignViewModel.savedAccounts = await this.FETCH_SAVED_SOCIAL_ACCOUNTS();
    },
    findSocialActionSelectIndex(items, value, defaultSelectIndex) {
      const index = items.findIndex((item) => item.value === value);
      return index > -1 ? index : defaultSelectIndex;
    },
    handleSocialActionSelect(accountIndex, key, itemKey, item) {
      this.$set(this.editCampaign.socialActions.socialAccounts[accountIndex], key, item[itemKey]);
      this.$set(this.campaignViewModel.followAndConnect.followAccounts[accountIndex], key, item[itemKey]);
    },
    handleSocialActionClear(accountIndex, key) {
      this.$delete(this.editCampaign.socialActions.socialAccounts[accountIndex], key);
      this.$delete(this.campaignViewModel.followAndConnect.followAccounts[accountIndex], key);
    },
    handleAccountNameSelect(name, accountIndex) {
      const newSavedAccount = this.campaignViewModel.savedAccounts.find(fa => fa.name === name);

      if (name === '') {
        this.$set(this.campaignViewModel.followAndConnect.followAccounts, accountIndex, {
          name,
        });
      } else if (newSavedAccount) {
        this.$set(this.campaignViewModel.followAndConnect.followAccounts, accountIndex, {
          save: false,
          ...newSavedAccount.accounts,
          name,
        });
      } else {
        const previousName = this.campaignViewModel
          .followAndConnect
          .followAccounts[accountIndex]
          .name;

        const savedAccount = this.campaignViewModel
          .savedAccounts
          .find(acc => acc.name === previousName);

         if(savedAccount !== undefined) {
          this.$set(this.campaignViewModel.followAndConnect.followAccounts, accountIndex, {
            ...savedAccount.accounts,
            name,
          });
        } else {
          this.$set(this.campaignViewModel.followAndConnect.followAccounts, accountIndex, {
            ...this.campaignViewModel.followAndConnect.followAccounts[accountIndex],
            name,
          });
        }
      }

      this.$nextTick(() => {
        this.$forceUpdate(); // fill the accounts with the urls
        this.$nextTick(() => {
          this.$forceUpdate(); // get the left margin of the urls to display the tick next to the urls
        });
      });
    },
    async handleAccountDelete(index) {
      await this.DELETE_SOCIAL_ACCOUNT(this.campaignViewModel.savedAccounts[index].oid);

      this.campaignViewModel.savedAccounts.splice(index, 1);
    },
    toggleExtraFields() {
      this.hideExtraFields = !this.hideExtraFields;
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    setEditPrivacyPolicyData(accountIndex, companyName, url) {
      this.editPrivacyPolicyAccountIndex = accountIndex;
      this.editPrivacyPolicyDefaultCompanyName = companyName;
      this.editPrivacyPolicyDefaultUrl = url;
    },
    handleEditPrivacyPolicyClick(accountIndex) {
      const account = this.campaignViewModel.followAndConnect.followAccounts[accountIndex];
      if (!account.additionalInfo) {
        this.$set(this.campaignViewModel.followAndConnect.followAccounts[accountIndex], 'additionalInfo', {});
      }

      this.setEditPrivacyPolicyData(
        accountIndex,
        account.additionalInfo?.privacyPolicy?.companyName || account.name || '',
        account.additionalInfo?.privacyPolicy?.url || '',
      );
      this.displayEditPrivacyPolicyModal = true;
    },
    handleEditPrivacyPolicyConfirm(companyName, url) {
      // If both "companyName" and "url" is null, delete the "privacyPolicy"
      if (!companyName && !url) {
        this.$delete(this.campaignViewModel.followAndConnect.followAccounts[this.editPrivacyPolicyAccountIndex].additionalInfo, 'privacyPolicy');
      } else {
        this.$set(this.campaignViewModel.followAndConnect.followAccounts[this.editPrivacyPolicyAccountIndex].additionalInfo, 'privacyPolicy', {
          companyName,
          url,
        });
      }
      this.setEditPrivacyPolicyData(null, null, null);
      this.displayEditPrivacyPolicyModal = false;
    },
    handleEditPrivacyPolicyCancel() {
      this.setEditPrivacyPolicyData(null, null, null);
      this.displayEditPrivacyPolicyModal = false;
    },
  },
};
</script>

<style lang="scss">
.campaign-edit-connect {
  .add-account {
    margin: 2rem auto 4rem;
    width: 100%;
  }

  .social-connect-channels {
    border-radius: 4px;
    padding: 2rem 3rem;
    border: 1px solid $blueGrey500;
  }
  .social-account__item {
    border-bottom: 1px solid $blueGrey500;
  }
  .social-account__item-inner {
    display: flex;
    align-items: center;
    border-radius: 4px;
    max-height: 50px;

    .social-account__item-label {
      margin-left: 8px;
      width: 100px;
    }

    &:nth-child(3) {
      margin-top: 3px;
    }
    .input--block {
      width: calc(100% - 120px);
    }
    &:hover {
      background-color: #f6f9fc;
    }
    &:hover input {
      background-color: #f6f9fc;
    }
    .select {
      width: 100%;
      border: 1px solid rgba(white, 0);
      overflow:hidden;
      img {
        margin-left: 0;
      }
      &:hover {
        background-color: #f6f9fc;
      }
    }

    .input {
      border: 1px solid rgba($skyBlueGrey500, 0);
    }

    .connect-icon {
      margin-left: 10px;
    }

    &.social-account__item-inner-focus {
      background-color: white;

      .input {
        background-color: white;
      }
    }
  }

  .social-follow-channels {
    border-radius: 4px;
    padding: 2rem 3rem;
    border: 1px solid $blueGrey500;

    position: relative;

    .checkbox-section {
      align-self: baseline;
    }

    .social-action-link-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 22px;
    }
  }

  .remove-social-account {
    position: absolute;
    right: 9px;
    top: 9px;
    cursor: pointer;
    color: $blueGrey700;
  }

  .follow-account-label {
    .label-description {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 15px;
      justify-content: space-between;
    }

    .save-account-label {
      color: $blueGrey600;
    }
  }


  .add-account-icon {
    width: 20px;
    vertical-align: bottom;
    margin-right: 5px;
  }

  .measure {
    position: absolute;
    left: -9999px;
    top: -9999px;
  }
  .account-select {
    position: relative;
    ul.select__items {
      width: 100%;
    }
  }
  .button:not(.button--text).is-primary {
    max-width: 100%;
  }
}
</style>
