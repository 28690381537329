<template>
  <div class="automation-page-wrapper" @keyup.alt="handleOptionClick" tabindex="0">
    <div class="automation-left" :style="`width: ${isFullScreen ? '100%' : 'calc(100% - 360px)'}`">
      <AutomationHeader
        :is-pending-status="isPendingStatus"
      />
      <AutomationPlanner />
      <PlannerControls />
    </div>
    <AutomationSidebar />
  </div>
</template>
<script>
import AutomationHeader from './components/AutomationHeader'
import AutomationSidebar from './components/AutomationSidebar'
import AutomationPlanner from './components/AutomationPlanner'
import PlannerControls from './components/PlannerControls'
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  name: 'CreateAutomation',
  components: {
    AutomationHeader,
    AutomationSidebar,
    AutomationPlanner,
    PlannerControls,
  },
  data() {
    return {
      poller: null,
    }
  },
  watch: {
    isPendingStatus() {
      if (this.isPendingStatus) {
        this.poller = setInterval(this.checkAutomationStatusSingle, 2000)
      } else {
        clearInterval(this.poller)
      }
    },
  },
  computed: {
    ...mapState({
      isFullScreen: state => state.automation.isFullScreen,
      zoomVal: state => state.automation.zoomVal,
    }),
    ...mapGetters({
      isPendingStatus: 'automation/isPendingState'
    })
  },
  methods: {
    ...mapActions({
      clearAutomation: 'automation/CLEAR_AUTOMATION',
      fetchAndSetAutomation: 'automation/FETCH_AND_SET_AUTOMATION',
      checkAutomationStatusSingle: 'automation/FETCH_AUTOMATION_STATUS_SINGLE',
      setSidebarTab: 'automation/SET_SIDEBAR_TAB',
      setZoom: 'automation/SET_ZOOM',
      toggleFullScreen: 'automation/TOGGLE_FULL_SCREEN',
    }),
    handleOptionClick(e) {
      if (e.keyCode === 49) {
        // option or alt + 1 was pressed
        this.setSidebarTab('triggers')
      } else if (e.keyCode === 50) {
        // option or alt + 2 was pressed
        this.setSidebarTab('actions')
      } else if (e.keyCode === 69) {
        // option or alt + e was pressed
        this.zoomIn()
      } else if (e.keyCode === 87) {
        // option or alt + w was pressed
        this.zoomOut()
      } else if (e.keyCode === 82) {
        // option or alt + r was pressed
        this.toggleFullScreen()
      }
    },
    zoomIn() {
      if (this.zoomVal < 2) {
        this.setZoom(parseFloat((this.zoomVal + 0.1).toFixed(1)))
      }
    },
    zoomOut() {
      if (this.zoomVal > 0.5) {
        this.setZoom(parseFloat((this.zoomVal - 0.1).toFixed(1)))
      }
    },
  },
  async fetch() {
    let id = Number(this.$route.query?.oid )
    if (!id) return

    await this.fetchAndSetAutomation({id})
  },
  beforeRouteLeave(_, __, next) {
    clearInterval(this.poller)
    this.clearAutomation()
    next()
  }
}
</script>
<style lang="scss" scoped>
.automation-page-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;

  .automation-left {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
}
</style>