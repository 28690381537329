<template>
  <div :class="[
    'purchases-menu-wrapper',
    $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
  ]">
    <am2-heading
      type="h1"
      size="md"
      class="section-heading"
      title="Purchases"
    />
    <am2-search
      :style="{
          height: '50px',
        }"
      class="search-bar"
      placeholder="Search purchases"
      :value="searchText"
      @input="searchTextChange"
      @enter="serverSideSearch"
      @clear="serverSideSearch"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  export default {
    name: 'PurchasesControlPanel',

    props: {
      columnSettings: {
        type: Object,
        default: () => null,
      },
      purchasesSearch: {
        type: Object,
        default: () => null, // TODO - Change to null
      },
      searchText: {
        type: String,
        default: "",
      }
    },

    data: () => ({
      showColumnSwitchModal: false,
    }),

    methods: {
      searchTextChange(value) {
        this.$emit('searchTextChange', value);
      },
      serverSideSearch() {
        this.$emit('searchAction');
      },
    }
  };
</script>

<style lang="scss" scoped>
  .purchases-menu-wrapper {
    margin: 16px 0 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    .search-bar {
      width: 300px;
    }

    &.sm-max {
      height: auto;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      .search-bar {
        margin-top: 16px;
        width: 100%;
      }
    }
  }
</style>
