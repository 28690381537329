<template>

  <div
    v-infinite-scroll="loadMoreRecipients"
    infinite-scroll-disabled="disableLoadMoreRecipients"
    infinite-scroll-distance="10"
  >
  <!-- When you scroll down to the bottom of the page loadMoreRecipients will be triggered -->
    <section
      v-if="showPage"
      class="message-view"
    >
      <template>
        <am2-message-preview-modal
          :opened="displayMessagePreviewModal"
          :message="currentSelectedMessage"
          :message-list="currentMessageList"
          :provider="currentSelectedMessageProvider"
          :email-template="savedEmail"
          :message-body="currentSelectedMessageMessageBody"
          :subject="currentSelectedMessageSubject"
          :preview-text="currentSelectedMessagePreviewText"
          :scheduled-at="currentSelectedMessageScheduledAt"
          :sender-name="currentMessageSenderName"
          :facebook-page-name="currentSelectedMessageFacebookPageName"
          :facebook-page-image-src="currentSelectedMessageFacebookPageImageSrc"
          @close="displayMessagePreviewModal = false"
        />
      </template>

      <MessageViewHero
        @back-click="handleBackClick"
        @list-click="handleListClick"
        :provider-icon="providerIcon"
        :message="currentSelectedMessage"
        :title="messageTitle"
        :scheduled-at-text="scheduledAtText"
        :message-provider-text="messageProviderText"
        :message-list-name="messageListName"
        :cost-of-message="costOfMessage"
        :targeting-string="targetingString"
        :is-sms="isSms"
        :is-firefox="isFirefox"
      />

      <MessageViewNav
        :menu="menu"
        @display-message-modal="handleViewMessage"
      />

      <div v-if="clickTrackingDisabled" class="link-tracking-wrapper">
        <div class="link-tracking-container">
          <ar-snackbar
            class="u-margin-top-5"
            type="warning"
            message="Click tracking is disabled on this message"
          />
        </div>
      </div>

      <div class="sent-numbers-wrapper">
        <div class="sent-numbers-container">
          <am2-tag type="purple" :text="messageStatus" />
          <ar-text size="xs" :text="messageStatsCount" :style="{ color: $arStyle.color.skyBlueGrey700, marginLeft: '15px' }" />
        </div>
      </div>

      <MessageOverviewStats
        v-if="currentSelectedMessage"
        :selected-message="currentSelectedMessage"
        :status-details="statusDetails"
        @tab-click="handleOverviewTabClick"
      />

      <div
        v-ar-sticky-top="{
          priority: 1,
        }"
      >
        <TableControl
          :recipient-count="totalRecipientsCount"
          :dropdown-items="dropdownItems"
          @search="handleSearch"
          @clear="handleSearchClear"
        />
      </div>

      <MessageTable
        :table-headers="recipientTableHead"
        :table-data="tableData"
        :loading="isFetchingRecipients"
        :enable-row-click="customerProfilesEnabled"
        @sort="handleSortByChange"
        @row-click="handleRowClick"
      />
    </section>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import accounting from 'accounting';
import { convertToTitleCase } from '@/utils/helpers';
import { getFormattedDateTimeWithTimezone, timeago } from '@/utils/date/';
import MessageViewHero from './message-view-hero.vue'
import MessageViewNav from './message-view-nav.vue'
import MessageOverviewStats from './message-overview-stats.vue'
import TableControl from './message-table/table-control.vue'
import MessageTable from './message-table/message-table.vue'

export default {
  name: 'MessageView',
  layout: 'default',
  components: {
    MessageViewHero,
    MessageViewNav,
    MessageOverviewStats,
    TableControl,
    MessageTable,
  },
  data() {
    return {
      displayMessagePreviewModal: false,
      messageStats: null,
      isFetchingMessageStats: null,
      recipientsSelectKeys: ['fan', 'status', 'sysMtime'],
      recipientsExportSelectKeys: ['firstName','lastName','emailAddress','lastStatus','lastActivity'],
      sortRecipientBy: { key: 'status', order: 'desc' },
      recipientsStatus: null,
      searchString: null,
      isFirefox: false,
      showPage: false
    };
  },

  watch: {
    currentSelectedMessage(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.showPage = true

        if (newVal.provider === 'sms') {
          this.recipientsExportSelectKeys = ['firstName','lastName','emailAddress','lastStatus','lastActivity', 'mobileNumber'];
        } else {
          this.recipientsExportSelectKeys = ['firstName','lastName','emailAddress','lastStatus','lastActivity'];
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      currentSelectedMessageCurrency: 'message/currentSelectedMessageCurrency',
    }),
    ...mapState({
      oid: state => state.route.params.oid,
      currentMessageList: state => state.messageList.currentMessageList,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      isFetchingMessage: state => state.message.isFetchingMessage,
      savedEmail: state => state.message.savedEmail,
      messageSenders: state => state.messageSender.messageSenders,
      currentMessageSender: state => state.messageSender.currentMessageSender,
      currentCampaign: state => state.campaign.currentCampaign,

      recipients: state => state.recipient.recipients,
      isExportingListRecipients: state => state.recipient.isExportingListRecipients,
      totalRecipientsCount: state => state.recipient.totalRecipientsCount,
      isFetchingRecipients: state => state.recipient.isFetchingRecipients,
      isNoMoreRecipients: state => state.recipient.isNoMoreRecipients,
      hasFetchRecipientsFailed: state => state.recipient.hasFetchRecipientsFailed,
    }),
    currentSelectedMessageProvider() {
      return this.currentSelectedMessage?.provider;
    },
    currentSelectedMessageFacebookPageName() {
      if (this.currentSelectedMessageProvider !== 'facebook') {
        return null;
      }
      if (this.currentSelectedMessage?.meta?.facebookMessenger?.pageName) {
        return this.currentSelectedMessage?.meta?.facebookMessenger?.pageName;
      } else {
        return this.currentMessageList?.meta?.facebookMessenger?.pageName;
      }
    },
    currentSelectedMessageFacebookPageImageSrc() {
      if (this.currentSelectedMessageProvider !== 'facebook') {
        return null;
      }
      if (this.currentSelectedMessage?.meta?.facebookMessenger?.imageSrc) {
        return this.currentSelectedMessage?.meta?.facebookMessenger?.imageSrc;
      } else {
        return this.currentMessageList?.meta?.facebookMessenger?.imageSrc;
      }
    },
    currentSelectedMessageMessageBody() {
      if (this.currentSelectedMessageProvider === 'email') {
        return null;
      }
      return this.currentSelectedMessage?.meta.messageBody;
    },
    currentSelectedMessageSubject() {
      return this.currentSelectedMessage?.meta.messageBody.subject;
    },
    currentSelectedMessagePreviewText() {
      return this.currentSelectedMessage?.meta.messageBody.previewText;
    },
    currentSelectedMessageScheduledAt() {
      return this.currentSelectedMessage?.scheduledAt;
    },
    currentMessageSenderName() {
      if (this.currentMessageSender && this.currentMessageSender.additionalInfo && this.currentMessageSender.additionalInfo.senderName) {
        return this.currentMessageSender.additionalInfo.senderName;
      } else if (this.currentMessageSender && this.currentMessageSender.meta && this.currentMessageSender.meta.email && this.currentMessageSender.meta.email.senderName) {
        return this.currentMessageSender.meta.email.senderName;
      } else if (this.currentMessageList) {
        const messageListPromoterPropertyOid = this.currentMessageList && this.currentMessageList.meta.email ? this.currentMessageList.meta.email.promoterPropertyOid : null;
        const sender = this.messageSenders.find( item => item.oid === messageListPromoterPropertyOid);
        return sender && sender.additionalInfo ? sender.additionalInfo.senderName : '';
      } else {
        return null;
      }
    },
    disableLoadMoreRecipients() {
      return this.isFetchingRecipients || this.isNoMoreRecipients || this.hasFetchRecipientsFailed;
    },

    searchStringTargets() {
      if (!this.currentSelectedMessage) return null;
      return this.currentSelectedMessage.provider === 'sms' ? ['status', 'fan[firstName]', 'fan[lastName]', 'fan[emailAddress]', 'fan[mobileNumber]'] : ['status', 'fan[firstName]', 'fan[lastName]', 'fan[emailAddress]']
    },

    menu() {
      return {
        recipients: {
          name: 'Recipients',
          to: `/message-center/messages/${this.$route.params.oid}/view`,
          featureKeys: null,
        },
      };
    },

    // Items which appear in the '...' dropdown button
    dropdownItems() {
      let dropdownActions = []

      dropdownActions.push({
        "name": "Export as CSV",
        "action": this.handleExportClick
      })

      return dropdownActions
    },

    clickTrackingDisabled() {
      if (!this.currentSelectedMessage) {
        return false;
      }
      return this.currentSelectedMessage.meta.disableLinkTracking;
    },

    customerProfilesEnabled() {
      return !!process.env.arEnableCustomerProfiles;
    },

    messageListName() {
      if (!!this.currentCampaign && this.currentMessageList?.meta?.name) {
        return `${this.currentMessageList?.meta?.name} (${this.currentCampaign?.name})`;
      } else if (!!this.currentCampaign) {
        return this.currentCampaign?.name;
      }
      if (!this.currentMessageList) return '';
      if (this.currentMessageList.meta.name) return this.currentMessageList.meta.name;
      return this.currentMessageList.name
    },

    recipientTableHead() {
      const head = [
        {
          name: 'Name',
          key: 'name',
          format: 'name',
          attributeKey: 'firstName,lastName',
        },
        {
          name: 'Email',
          key: 'emailAddress',
          format: 'emailAddress',
          attributeKey: 'emailAddress',
        },
        {
          name: 'Status',
          key: 'status',
          format: 'status',
          sortKey: 'status',
          attributeKey: 'status',
        },
        {
          name: 'Last open / click',
          key: 'sysMtime',
          format: 'sysMtime',
          sortKey: 'sysMtime',
          attributeKey: 'sysMtime',
        },
      ];

      if (this.currentSelectedMessage && this.currentSelectedMessage.provider === 'sms') {
        head.splice(2, 0, {
          name: 'Mobile number',
          key: 'mobileNumber',
          format: 'text',
          attributeKey: 'mobileNumber',
        });
      }
      return head;
    },

    messageStatus() {
      if (!this.currentSelectedMessage || !this.currentSelectedMessage.status) {
        return '';
      } else if (this.currentSelectedMessage.status === 'completed') {
        return 'Sent';
      } else if (this.currentSelectedMessage.status === 'in-progress') {
        return 'Sending';
      } else {
        return convertToTitleCase(this.currentSelectedMessage.status.replace(/-/, ' '));
      }
    },

    costOfMessage() {
      const currency = this.currentSelectedMessageCurrency;
      if (!currency) return null;
      if (parseFloat(this.currentSelectedMessage.statusDetails.costs.total) === 0) return null;

      let currencyValue = parseFloat(this.currentSelectedMessage.statusDetails.costs.total);
      currencyValue = currencyValue.toFixed(currency.decimal_digits);
      return`${currency.symbol_native}${currencyValue} ${currency.code}`;
    },

    targetingString() {
      if (!this.currentSelectedMessage?.meta?.recipientFilter?.conditions) return null;
      const conditions = this.currentSelectedMessage.meta.recipientFilter.conditions;
      if (conditions.length < 1) return null;

      if (conditions[0].name === 'tags') {
        if (conditions[0].data.condition === 'true_to_all' || conditions[0].data.condition === 'true_to_any') {
          return `fans with tag${conditions[0].data.values.length === 1 ? '' : 's'}`
        } else {
          return `fans without tag${conditions[0].data.values.length === 1 ? '' : 's'}`
        }
      }

      if (conditions[0].name === 'messages_opened') {
        if (conditions[0].data.condition === 'true_to_all' || conditions[0].data.condition === 'true_to_any') {
          return 'fans who opened a specific message'
        } else {
          return 'fans who didn\'t open a specific message'
        }
      }

      if (conditions[0].name === 'messages_clicked') {
        if (conditions[0].data.condition === 'true_to_all' || conditions[0].data.condition === 'true_to_any') {
          return 'fans who clicked a specific message'
        } else {
          return 'fans who didn\'t click a specific message'
        }
      }

      return null;
    },

    messageStatTotalSuccessfulSends() {
      if (!this.currentSelectedMessage || !this.currentSelectedMessage.statusDetails) {
        return 0;
      }
      return (this.currentSelectedMessage.statusDetails.totalMessages || 0)
        - (this.currentSelectedMessage.statusDetails.bounced || 0)
        - (this.currentSelectedMessage.statusDetails.deferred || 0)
        - (this.currentSelectedMessage.statusDetails.failed || 0);
    },

    messageStatsCount() {
      if (!this.currentSelectedMessage || !this.currentSelectedMessage.statusDetails) {
        return null;
      }
      return `${accounting.formatNumber(this.messageStatTotalSuccessfulSends)} of ${accounting.formatNumber(this.currentSelectedMessage.statusDetails.totalMessages)} sent`;
    },

    senderEmailAddress() {
      // If we have a currentMessageSender, then use that property
      if (this.currentMessageSender && this.currentMessageSender.property) {
        return this.currentMessageSender.property;
      }

      // Otherwise, let's get the message sender from messageList
      if (this.messageSenders.length > 0) {
        const messageListPromoterPropertyOid = this.currentMessageList && this.currentMessageList.meta.email ? this.currentMessageList.meta.email.promoterPropertyOid : null;
        const sender = this.messageSenders.find( item => item.oid === messageListPromoterPropertyOid);
        return sender ? sender.property : '';
      }

      return '';
    },

    // Use message stats endpoint, but fall back to message->statusDetails if there are any issues
    statusDetails() {
      if (!this.messageStats && (!this.currentSelectedMessage || !this.currentSelectedMessage.statusDetails)) {
        return {};
      }

      return this.messageStats || this.currentSelectedMessage.statusDetails;
    },

    scheduledAtText() {
      if (!this?.currentSelectedMessage?.sysMtime && !this.isSms) {
        return null
      }

      let msgSentDate = new Date(this.currentSelectedMessage.sysMtime)
      return getFormattedDateTimeWithTimezone(msgSentDate)
    },

    providerIcon() {
      if (!this.currentSelectedMessage) {
        return null;
      }
      switch (this.currentSelectedMessage.provider) {
        case 'sms':
          return {
            name: 'sms',
            color: this.$arStyle.color.sms,
            width: '24px',
            height: '24px',
            padding: '14px',
          };
        case 'facebook':
          return {
            name: 'messenger',
            color: this.$arStyle.color.messenger,
            width: '28px',
            height: '28px',
            padding: '12px',
          };
        case 'email':
          return {
            name: 'email',
            color: this.$arStyle.color.email,
            width: '26px',
            height: '24px',
            padding: '14px 13px',
          };
        default:
          return null;
      }
    },

    messageTitle() {
      if (!this.currentSelectedMessage) {
        return null;
      }

      switch (this.currentSelectedMessage.provider) {
        case 'sms':
          return this.currentSelectedMessage.meta.messageBody;
        case 'facebook':
          return this.currentSelectedMessage.meta.messageBody;
        case 'email':
          return `${this.currentSelectedMessage.meta.messageBody.subject}`;
        default:
          return null;
      }
    },

    messageListFacebook() {
      if (!this.currentMessageList || !this.currentMessageList.meta || !this.currentMessageList.meta.facebookMessenger) {
        return null;
      }
      return this.currentMessageList.meta.facebookMessenger;
    },

    isSms() {
      return this.currentSelectedMessage?.provider === 'sms'
    },

    messageProviderText() {
      if (!this.currentSelectedMessage) {
        return null;
      }
      switch (this.currentSelectedMessage.provider) {
        case 'sms':
          return null
        case 'email':
          return this.senderEmailAddress;
        case 'facebook':
          return this.messageListFacebook ? this.messageListFacebook.pageName : null;
        default:
          console.log('an unknown message provider type was encountered: ', this.currentSelectedMessage.provider)
          return '';
      }
    },

    tableData() {
      return this.recipients.map(recipient => ({
        firstName: recipient.fan.firstName,
        lastName: recipient.fan.lastName,
        emailAddress: recipient.fan.emailAddress,
        mobileNumber: recipient.fan.mobileNumber,
        status: convertToTitleCase(recipient.status ? recipient.status.replace(/-/, ' ') : ''),
        sysMtime: timeago(recipient.sysMtime),
        oid: recipient.fan.oid,
      }));
    },
  },

  async created() {
    this['campaign/RESET_CURRENT_CAMPAIGN']();
    const succeed = await this['message/FETCH_MESSAGE'](this.oid);
    if (succeed && !!this.currentSelectedMessage) {
      this.fetchMessageStats(this.oid);
      this.reloadRecipients();
      if (this.currentSelectedMessage?.meta?.initiator?.campaignOid) {
        await this.fetchMessageTierList(this.currentSelectedMessage.meta.messageListOid);
        this['campaign/FETCH_CURRENT_CAMPAIGN'](this.currentSelectedMessage.meta.initiator.campaignOid);
      } else {
        await this.fetchMessageList(this.currentSelectedMessage.meta.messageListOid);
      }

      if (this.currentSelectedMessage.meta.email && this.currentSelectedMessage.meta.email.promoterPropertyOid) {
        await this.fetchMessageSender(this.currentSelectedMessage.meta.email.promoterPropertyOid);
      } else if (this.currentMessageList && this.currentMessageList.meta && this.currentMessageList.meta.email && this.currentMessageList.meta.email.promoterPropertyOid) {
        await this.fetchMessageSender(this.currentMessageList.meta.email.promoterPropertyOid);
      }
      if (!this.currentMessageSender && this.messageSenders.length === 0) {
        this['messageSender/FETCH_MESSAGE_SENDERS']();
      }
      if (this.currentSelectedMessage.provider === 'email') {
        this['message/FETCH_SAVED_EMAIL_TEMPLATE'](this.currentSelectedMessage.meta.templateResourceOid);
      }
      if (this.currentSelectedMessage.provider === 'sms') {
        this.recipientsExportSelectKeys = ['firstName','lastName','emailAddress','lastStatus','lastActivity', 'mobileNumber'];
      } else {
        this.recipientsExportSelectKeys = ['firstName','lastName','emailAddress','lastStatus','lastActivity'];
      }
    }

    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
      this.isFirefox = true
    }
  },

  beforeDestroy() {
    this['message/RESET_CURRENT_SELECTED_MESSAGE']();
    this['campaign/RESET_CURRENT_CAMPAIGN']();
    this['recipient/RESET_RECIPIENTS']();
  },

  methods: {

    ...mapActions([
      'recipient/EXPORT_MESSAGE_RECIPIENTS_CSV',
      'message/FETCH_MESSAGE',
      'message/FETCH_SAVED_EMAIL_TEMPLATE',
      'FETCH_MESSAGE_STATS_BY_OID',
      'messageList/FETCH_CURRENT_MESSAGE_LIST',
      'recipient/FETCH_MESSAGE_RECIPIENTS',
      'messageSender/FETCH_CURRENT_MESSAGE_SENDER',
      'messageSender/FETCH_MESSAGE_SENDERS',
      'campaign/FETCH_CURRENT_CAMPAIGN',
    ]),

    ...mapMutations([
      'message/RESET_CURRENT_SELECTED_MESSAGE',
      'campaign/RESET_CURRENT_CAMPAIGN',
      'recipient/RESET_RECIPIENTS',
    ]),

    handleExportClick() {
      if (this.isExportingListRecipients) { return }

      this['recipient/EXPORT_MESSAGE_RECIPIENTS_CSV']({
        selectKeys: this.recipientsExportSelectKeys,
        taskOid: this.oid,
        orderBy: this.sortRecipientBy,
        searchString: this.searchString,
      })
    },

    handleBackClick() {
      this.$router.push("/message-center/messages")
    },

    async fetchMessageRecipients (recipientsStatus, fetchCount = true, orderBy = null, searchString = null, searchStringTargets = null, reload = false) {

      await this['recipient/FETCH_MESSAGE_RECIPIENTS']({
        messageTaskOid: this.oid,
        selectKeys: this.recipientsSelectKeys,
        status: recipientsStatus,
        fetchCount,
        orderBy,
        searchString,
        searchStringTargets,
        reload
      });
    },

    async reloadRecipients() {
      let recipientsStatus;
      if(this.recipientsStatus === 'sent') {
        recipientsStatus = ['sent', 'opened', 'clicked'];
      } else if (this.recipientsStatus === 'opened' && this.currentSelectedMessage.provider !== 'sms' ) {
        recipientsStatus = ['opened', 'clicked'];
      } else if (this.recipientsStatus === 'failed' && this.currentSelectedMessage.provider === 'email') {
        recipientsStatus = ['failed', 'deferred'];
      } else {
        recipientsStatus = [this.recipientsStatus];
      }

      this.fetchMessageRecipients(recipientsStatus, true, this.sortRecipientBy, this.searchString, this.searchStringTargets, true);
    },
    async loadMoreRecipients() {
      let recipientsStatus;
      if(this.recipientsStatus === 'sent') {
        recipientsStatus = ['sent', 'opened', 'clicked'];
      } else if (this.recipientsStatus === 'opened' && this.currentSelectedMessage.provider !== 'sms' ) {
        recipientsStatus = ['opened', 'clicked'];
      } else if (this.recipientsStatus === 'failed' && this.currentSelectedMessage.provider === 'email') {
        recipientsStatus = ['failed', 'deferred'];
      } else {
        recipientsStatus = [this.recipientsStatus];
      }
      this.fetchMessageRecipients(recipientsStatus, true, this.sortRecipientBy, this.searchString, this.searchStringTargets, false);
    },
    async fetchMessageStats(oid) {
      try {
        this.isFetchingMessageStats = true;
        const messageStats = await this.FETCH_MESSAGE_STATS_BY_OID({oid});
        if (messageStats) {
          this.messageStats = messageStats;
        }
      } catch (e) {
        console.error(e);
        this.$arNotification.push({ type: 'error', message: 'Failed to fetch message stats' });
      } finally {
        this.isFetchingMessageStats = false;
      }
    },
    async fetchMessageList(oid) {
      await this['messageList/FETCH_CURRENT_MESSAGE_LIST']({oid});
      return true;
    },
    async fetchMessageTierList(oid) {
      await this['messageList/FETCH_CURRENT_MESSAGE_LIST']({oid, fetchDeleted: true});
      return true;
    },
    fetchMessageSender(oid) {
      this['messageSender/FETCH_CURRENT_MESSAGE_SENDER']({
        oid,
        allowDeleted: true,
      });
    },
    handleOverviewTabClick(item) {
      this.recipientsStatus = item.key

      this.reloadRecipients()
      this.fetchMessageStats(this.oid)
      this.fetchMessageList(this.currentSelectedMessage.meta.messageListOid)
      if (this.currentSelectedMessage.meta?.email) {
        this.fetchMessageSender(this.currentSelectedMessage.meta.email.promoterPropertyOid)
      }
    },
    handleSortByChange(sortBy) {
      this.sortRecipientBy = sortBy;
      this.reloadRecipients();
    },
    handleViewMessage() {
      this.displayMessagePreviewModal = true
    },

    handleListClick() {
      if (!!this.currentSelectedMessage?.meta?.initiator?.campaignOid) {
        this.$router.push({
          path: `/campaigns/${this.currentSelectedMessage.meta.initiator.campaignOid}/view/dashboard`,
        });
        return;
      }
      if (!!this.currentSelectedMessage?.meta?.initiator?.eventOid) {
        this.$router.push({
          path: `/events/${this.currentSelectedMessage.meta.initiator.eventOid}/view/sales`,
        });
        return;
      }

      if (!this.currentMessageList) return;

      this.$router.push({
        path: `/message-center/lists/${this.currentMessageList.oid}/contacts`,
      });
    },


    handleRowClick(target) {
      if (this.customerProfilesEnabled && target && target.oid) {
        this.$router.push({
          path: `/audience/${target.oid}/view/overview`,
        });
      }
    },

    handleSearch(searchString) {
      this.searchString = searchString
      this.reloadRecipients(searchString)
    },
    handleSearchClear() {
      this.searchString = ''
      this.reloadRecipients()
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '../../../../assets/styles/base/mixins';

@mixin flex-row {
  display: flex;
  flex-flow: row nowrap;
}

.message-view {
  padding-bottom: 100px;

  .message-tabs-wrapper {
    @include flex-row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    background-color: white;
    border-bottom: 1px solid $skyBlueGrey500;

    .message-tabs-container {
      width: 80%;
      height: 100%;
      @include flex-row;
      align-items: center;
      justify-content: space-between;

      .tabs-left-container {
        height: 100%;
        @include flex-row;
        align-items: center;
        justify-content: flex-start;

        .tab-wrapper {
          height: 100%;
          @include flex-row;
          align-items: center;
          justify-content: center;
          margin-right: 30px;
          cursor: pointer;
          color: $blueGrey700;

          .tab-btn {
            background: white;
            border: none;
          }
        }

        .tab-wrapper:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .link-tracking-wrapper {
    width: 100%;
    @include flex-row;
    align-items: center;
    justify-content: center;

    .link-tracking-container {
      width: 90%;
      max-width: 1200px;
    }
  }

  .sent-numbers-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: all 0.3s;

    .sent-numbers-container {
      width: 90%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      max-width: 1200px;
      padding: 30px 0;
    }
  }
}

.fade-enter {
  opacity: 0;
  visibility: hidden;
}
.fade-enter-active {
  transition: opacity 0.4s ease;
}
/* .fade-leave {
  } */
.fade-leave-active {
  transition: 0.2s opacity ease;
  opacity: 0;
  visibility: hidden
}
</style>
