<template>
  <portal
    to="modal"
    :disabled="!opened"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="opened"
      header="Edit Email Template"
      width="100%"
      height="100%"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleModalClose"
      class="email-editor-modal"
    >
      <div slot="body" class="body">
        <EmailEditor
          ref="email-editor"
          class="email-editor"
          :type="templateType"
          :show-dynamic-tags-tool="false"
          @templateUpdate="handleDesignOutput"
          @emailHtmlUpdate="handleHtmlOutput"
        />
      </div>
      <div slot="footer" class="footer">
        <ar-simple-button
          text="Cancel"
          outlined
          :style="{
            width: '140px',
          }"
          @click="handleCancelClick"
        />
        <ar-simple-button
          text="Done"
          :style="{
            width: '140px',
          }"
          @click="handleDoneClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import EmailEditor from '@/components/editors/email-editor';

export default {
  name: 'EmailEditorModal',
  components: {
    EmailEditor,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    templateType: {
      type: String,
      default: 'unlayer',
      validator: (val) => ['unlayer', 'rich-text', 'custom'].indexOf(val) > -1,
    },
    defaultTemplate: {
      type: [Object, String],
      default: null,
    },
  },
  data() {
    return {
      template: null,
      emailHtml: null,
    };
  },
  computed: {
  },

  watch: {
    opened(newVal) {
      if (newVal) {
        this.initEmailEditor();
      }
    },
  },

  methods: {
    async initEmailEditor() {
      await this.$nextTick();
      if (this.defaultTemplate) {
        this.$refs['email-editor'].setDesign(this.defaultTemplate);
      }
    },
    handleDesignOutput(template) {
      this.template = template;
    },
    handleHtmlOutput(emailHtml) {
      this.emailHtml = emailHtml;
    },
    handleCancelClick() {
      this.$emit('cancel');
    },
    handleDoneClick() {
      this.$emit('update', this.template, this.emailHtml);
    },
    handleModalClose() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.email-editor-modal {
  .body {
    width: 100%;
    height: 100%;
    .email-editor {
      width: 100%;
      height: 100%;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding: 23px 29px;
  }
}
</style>
