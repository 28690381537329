<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="event-merge-modal"
      :header="`Merge ${ sourceEvent ? sourceEvent.name : 'this event' }`"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleModalClose"
    >
      <!-- BODY -->
      <div slot="body" class="body">
        <div class="event-container">
          <div>
            <div v-if="isSourceEventBrite" class="body-header">
              <ar-icon
                class="header-icon"
                name="eventbrite"
                :color="$arStyle.color.eventbrite"
                height="17px"
                width="11px" />
              Eventbrite Event
            </div>
            <div v-else class="body-header">
              <ar-icon
                class="header-icon"
                name="ar-logo"
                height="17px"
                width="19px" />
              Audience Republic Event
            </div>
            <div class="event-card">
              <div class="event-img">
                <img :src="sourceEventImage" alt="source-event-img">
              </div>
              <div class="event-text">
                <div class="name">{{ sourceEvent && sourceEvent.name }}</div>
                <div class="extra-info">{{ sourceEvent && sourceEvent.location }}, {{ sourceEventPrettyDate }}</div>
              </div>
            </div>

          </div>

          <div style="padding-top: 20px" >
            <ar-icon
              name="merge-arrow"
              class="icon merge-arrow"
            />
          </div>

          <div>
            <div v-if="isSourceEventBrite" class="body-header">
              <ar-icon
                class="header-icon"
                name="ar-logo"
                height="17px"
                width="19px" />
              Audience Republic Event
            </div>
            <div v-else class="body-header">
              <ar-icon
                class="header-icon"
                name="eventbrite"
                :color="$arStyle.color.eventbrite"
                height="17px"
                width="11px" />
              Eventbrite Event
            </div>
            <div class="event-card with-search">
              <div v-if="targetEvent" class="event-img">
                <img :src="targetEventImage" alt="target-event-img">
              </div>
              <div v-else class="empty-state">
                <div class="event-icon-circle">
                  <ar-icon
                    name="calendar"
                    width="25px"
                    height="25px"
                    :color="!isSourceEventBrite ? '#f6682e' : '#7344c0'"
                  />
                </div>
              </div>

              <ar-simple-select
                placeholder="Search events"
                class="search-input"
                enable-filter
                @select="selectTargetEvent"
                :items="availableEvents"
                hide-arrow
              />
            </div>
          </div>
        </div>
        <div class="postamble">
          <p v-if="isSourceEventBrite">
            Have a duplicate event come in from Eventbrite? No problem, you can merge this with the existing event in Audience Republic. After merging, your ticket sales, attendees and campaigns will appear in one single event.
          </p>
          <p v-else>
            Sales and attendee data will be added to your event. All existing campaign information from Audience Republic will be kept.
          </p>

          <div class="warning-note">
            <ar-icon
              name="alert-exclamation-mark"
              class="warning-exclamation-mark"
              height="20px"
              width="20px"
            />

            <div>
              <strong>Important note: </strong> this cannot be undone so proceed with caution!
            </div>
          </div>
        </div>
      </div>

      <!-- FOOTER -->
      <div
        slot="footer"
        class="footer"
      >
        <ar-simple-button
          text="Merge event"
          :loading="isMergingEvents"
          @click="beginMerge"
          :disabled="sourceEvent === null || targetEvent === null"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { capitalizeFirstLetter } from '@/utils/helpers';
import moment from 'moment';

export default {
  name: 'MergeEventModal',

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    sourceEvent: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      targetEvent: null,
    };
  },
  computed: {
    ...mapState({
      events: state => state.event.events,
      isMergingEvents: state => state.event.isMergingEvents,
    }),
    ...mapGetters({
      eventHasIntegration: 'event/eventHasIntegration',
    }),
    isSourceEventBrite() {
      return this.eventHasIntegration(this.sourceEvent, 'eventbrite');
    },
    sourceEventPrettyDate() {
      if (!this.sourceEvent) {
        return;
      }
      const start = moment.utc(this.sourceEvent.startDate).tz(this.sourceEvent.timeZone);
      return start.format('ddd Do MMM, YYYY');
    },
    sourceEventImage() {
      if (!this.sourceEvent) {
        return null;
      }
      const eventImage = this.sourceEvent.resources.find(resource => resource.assetType === 'event-image');

      return eventImage ? eventImage.url : '/images/placeholders/image-default.png';
    },
    targetEventImage() {
      const eventImage = this.targetEvent.resources.find(resource => resource.assetType === 'event-image');

      return eventImage ? eventImage.url : '/images/placeholders/image-default.png';
    },
    availableEvents() {
      if (this.isSourceEventBrite) {
        return this.events.filter(r => {
          return !this.eventHasIntegration(r, 'eventbrite');
        });
      } else {
        return this.events.filter(r => {
          return this.eventHasIntegration(r, 'eventbrite');
        });
      }
    }
  },

  watch: {
    isShow(val) {
      if (val) {
        this.fetchEvents();
      }
    },
  },

  methods: {
    ...mapActions([
      'event/FETCH_MORE_EVENTS',
      'event/MERGE_EVENTS',
    ]),
    ...mapMutations([
      'event/RESET_EVENTS',
    ]),
    async beginMerge() {
      const succeed = await this['event/MERGE_EVENTS']({
        sourceEvent: this.sourceEvent,
        targetEvent: this.targetEvent,
      });

      if (succeed) {
        this.$emit('merge');
      }
    },
    selectTargetEvent(event) {
      this.targetEvent = event;
    },
    fetchEvents() {
      this['event/FETCH_MORE_EVENTS']({
        top: 'all',
        reload: true,
      });
    },
    handleModalClose() {
      this.targetEvent = null;
      this.$emit('cancel');
    },
  },

  beforeDestroy() {
    this['event/RESET_EVENTS']();
  },
};
</script>

<style lang="scss" scoped>
.event-merge-modal {
  .body {
    background-color: #F6F8FA;
    padding: 35px 80px;
    .event-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 19px;

      .body-header {
        font-weight: bold;
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        line-height: 0;

        .header-icon {
          margin-right: 11px;
        }
      }

      .icon {
        &.merge-arrow {
          color: $blueGrey700;
        }
      }

      .event-card {
        background: #FFFFFF;
        border: 1px solid $blueGrey500;
        border-radius: 4px;
        width: 300px;
        height: 250px;

        .event-img {
          height: 165px;
          width: 298px;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            display: block;
          }
        }

        .event-text {
          padding: 20px 15px;

          div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          div.name {
            font-size: 16px;
            color: #43516B;
          }

          div.extra-info {
            color: #8E97A6;
          }
        }

        .empty-state {

          .event-icon-circle {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            background-color: $skyBlueGrey400;
            margin-top: 49px;

            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        &.with-search {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        }

        .search-input {
          align-self: flex-end;
          margin: 15px;
          width: calc(100% - 30px);
        }
      }
    }

    .postamble {
      p {
        line-height: 20px;
        color: $blueGrey800;
      }
    }

    .warning-note {
      margin-top: 14px;
      display: flex;
      align-items: center;

      .warning-exclamation-mark {
        margin-right: 8px;
      }
    }

    .important-icon {
      color: #F6F8FA;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $orange500;
    }
  }

  .footer {
    display: flex;
    flex-direction: row-reverse;
    padding: 30px;
  }
}
</style>
