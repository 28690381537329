<template>

  <ar-modal
    :is-open="isVisible"
    class="import-data-modal"
    :header="header"
    :has-back-link="step > 1 || (step === 1 && isMassImport)"
    :hide-footer="step === 1"
    width="590px"
    :mobile="$arMediaQuery.window.maxWidth('xs')"
    @close="onClose"
    @back="previousStep"
  >
    <!-- BODY -->
    <div
      v-if="step===0"
      slot="body"
      :class="[
        'import-data-modal__wrapper',
        'generic',
        $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
      ]">
      <div
        v-for="(option, index) in massImportOptions"
        :key="index"
        :class="[
          'mass-import-option',
          $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
        ]"
        @click="() => selectType(option)"
      >
        <div :class="[
          'icon-container',
          $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
        ]">
          <ar-icon
            v-if="option === 'events and orders'"
            name="calendar-ticket-combined"
            :width="$arMediaQuery.pageContent.maxWidth('xs') ? '30px' : '38px'"
            :height="$arMediaQuery.pageContent.maxWidth('xs') ? '24px' : '31px'"
            :style="{
              position: 'relative',
              top: $arMediaQuery.pageContent.maxWidth('xs') ? '2px' : '3px',
              left: $arMediaQuery.pageContent.maxWidth('xs') ? '2px' : '3px',
            }"
            color="#7344c0"
          />
          <ar-icon
            v-else-if="option === 'events'"
            name="calendar"
            :width="$arMediaQuery.pageContent.maxWidth('xs') ? '20px' : '25px'"
            :height="$arMediaQuery.pageContent.maxWidth('xs') ? '20px' : '25px'"
            color="#7344c0"
          />
          <ar-icon
            v-else
            name="ticket"
            :width="$arMediaQuery.pageContent.maxWidth('xs') ? '24px' : '30px'"
            :height="$arMediaQuery.pageContent.maxWidth('xs') ? '24px' : '30px'"
            color="#7344c0"
          />
        </div>
        <div :class="[
          'mass-import-option__description',
          $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-right-7',
        ]">
          <p class="heading">Import {{ option }}</p>
          <p class="body">Import multiple {{ option }} from your ticketing provider</p>
        </div>
        <div :class="$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max'">
          <span>&#10095;</span>
        </div>
      </div>
    </div>
    <div
      v-else-if="step===1"
      slot="body"
      :class="[
        'import-data-modal__wrapper',
        $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
      ]">
      <p class="import-data-modal__wrapper_preamble">
        Upload {{
          type === 'audience' || type === 'orders' ? 'orders' :
          type === 'events' ? 'multiple events' : 'multiple events and orders'
        }} from your ticketing provider.
      </p>

      <am2-dropzone
        class="u-margin-top-5"
        :file-type="['text/csv']"
        :file-size="50000000"
        file-type-alias="CSV file"
        :placeholder-icon="{
          name: 'upload',
          width: '16px',
          height: '16px',
          color: $arStyle.color.purple500,
          wrapperStyle: {
            background: mouseOverCsvDropdown ? `white` : $arStyle.color.skyBlueGrey400,
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: mouseOverCsvDropdown ? '2px solid white' : null,
          },
        }"
        :ratio="0.525"
        @upload="handleCSVUploaded"
        @mouseover.native="mouseOverCsvDropdown = true"
        @mouseleave.native="mouseOverCsvDropdown = false"
      />
    </div>
    <div v-else-if="step===2" slot="body">
      <div
        :class="[
          'mapping-instructions',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-3'
        ]">
        <p>
          Map the fields to import into Audience Republic from your CSV file
        </p>
        <p class="mapping-instructions__subtitle">
          * indicates a compulsory field
        </p>
      </div>
      <am2-tabs
        v-if="type === 'events and orders'"
        class="tabs"
        :items="[{
          id: 'events',
          title: 'Event information',
        }, {
          id: 'orders',
          title: 'Order information'
        }]"
        @select="changeMapping"
      />
      <div
        :class="[
          'import-data-modal__wrapper',
          $arMediaQuery.window.maxWidth('xs') && 'xs-max',
          type === 'events and orders' ? 'eventsAndOrders' : 'notEventAndOrders',
        ]"
      >
        <p class="column-text">
          <span>Audience Republic</span><span>CSV File</span>
        </p>

        <div v-if="type === 'events and orders'" class="form">
          <div v-for="(arTableHeader, index) in visibleHeaderSelections" :key="index" class="form-row">
            <p class="col-xs-12 col-sm-5 form-row__label">{{ `${arTableHeader.value}${arTableHeader.required ? '*' : ''}` }}
              <ar-icon
                v-if="arTableHeader.hint && $arMediaQuery.window.minWidth('sm')"
                v-tooltip.top="{
                  content: arTableHeader.hint,
                }"
                name="tooltip-question-mark"
                class="icon"
                height="15px"
                width="15px"
              /></p>

            <div class="col-xs-12 col-sm-7 form-row__input" >
              <!--
                RK/NOTE: the currency code doesn't go in the mapping,
                therefore it is easier to put it in after the appropriate
                field instead of using the same logic as the other mappings
              -->
              <ar-simple-select
                v-if="arTableHeader.value === 'Currency'"
                enable-filter
                class="selector"
                :items="currencyOptions"
                placeholder="Please select..."
                enable-clear
                :default-select-index="selectedCurrencyIndex"
                @select="selectCurrency"
                @clear="() => selectCurrency(null)"
              />

              <am2-timezone-select
                v-else-if="arTableHeader.value === 'Timezone'"
                v-model="selectedTimezone"
                placeholder="Please select..."
                class="selector"
              />

              <ar-simple-select
                v-else
                class="selector"
                enable-filter
                :items="csvHeadersOptions(arTableHeader.mappedTo)"
                placeholder="Please select..."
                enable-clear
                :default-select-index="arTableHeader.mappedTo === null ? null : 0"
                @select="val => setMapping(val, arTableHeader)"
                @clear="() => setMapping({ index: null }, arTableHeader)"
              />

              <div v-if="arTableHeader.error" class="error">
                {{ arTableHeader.error }}
              </div>
            </div>
          </div>
        </div>

        <div class="form" v-else>
          <div v-for="(arTableHeader, index) in arToPromoterMap" :key="index" class="form-row">
            <p class="col-xs-12 col-sm-5 form-row__label">{{ `${arTableHeader.value}${arTableHeader.required ? '*' : ''}` }}
              <ar-icon
                v-if="arTableHeader.hint && $arMediaQuery.window.minWidth('sm')"
                v-tooltip.top="{
                  content: arTableHeader.hint,
                }"
                name="tooltip-question-mark"
                class="icon"
                height="15px"
                width="15px"
              /></p>

            <div class="col-xs-12 col-sm-7 form-row__input">
              <!--
                RK/NOTE: the currency code doesn't go in the mapping,
                therefore it is easier to put it in after the appropriate
                field instead of using the same logic as the other mappings
              -->
              <ar-simple-select
                v-if="arTableHeader.value === 'Currency'"
                class="selector"
                enable-filter
                :items="currencyOptions"
                :default-select-index="selectedCurrencyIndex  "
                placeholder="Please select..."
                enable-clear
                @select="selectCurrency"
                @clear="() => selectCurrency(null)"
              />

              <am2-timezone-select
                v-else-if="arTableHeader.value === 'Timezone'"
                v-model="selectedTimezone"
                placeholder="Please select..."
                class="selector"
              />

              <ar-simple-select
                v-else
                class="selector"
                enable-filter
                :items="csvHeadersOptions(arTableHeader.mappedTo)"
                placeholder="Please select..."
                enable-clear
                :default-select-index="arTableHeader.mappedTo === null ? null : 0"
                @select="val => setMapping(val, arTableHeader)"
                @clear="() => setMapping({index: null}, arTableHeader)"
              />

              <div v-if="arTableHeader.error" class="error">
                {{ arTableHeader.error }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="step===3"
      slot="body"
      :class="[
        'import-data-modal__wrapper',
        $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
      ]">
      <p class="column-text">
        <span>Audience Republic</span><span>CSV File</span>
      </p>

      <p class="import-data-modal__wrapper__import-message">
        {{ importCompleteMessage }}
      </p>
    </div>

    <!-- FOOTER -->
    <div
      v-if="step >= 2"
      slot="footer"
      class="import-data-modal__footer"
    >
      <!--
        NOTE/RK: Bring this back when website copy is sorted.
        <div v-if="step===1">
          <div v-if="isMassImport" style="text-align: center">
            <a
              href="/events/new"
            >
              Manually create an event
            </a>
          </div>
          <div v-else>
            <p class="header">Want to easily import your sales data?</p>
            <p class="body">Our integrations help you seamlessly sync your ticket sales data.
              <a
                target="_blank"
                href="https://audiencerepublic.com/audience-manager/audience/">
                Learn more
              </a>
            </p>
          </div>
        </div>
      -->
      <div class="footer-container" v-if="step===2">
        <div>
          <div v-if="error" class="error-message" v-html="error"/>
          <div :class="[
            'footer-controls',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-3',
          ]">
            <div :class="[
              'ticketing-provider',
              $arMediaQuery.window.maxWidth('xs') && 'xs-max',
            ]" style="display: flex;">
              <div v-if="selectedProvider" class="tick-container">
                <ar-icon
                  name="check"
                  height="9px"
                  color="white"
                />
              </div>
              <ar-text
                size="xs"
                text="Ticketing provider:"
                :style="{
                  marginLeft: $arMediaQuery.window.maxWidth('xs') ? '5px' : '10px',
                  marginBottom: $arMediaQuery.window.maxWidth('xs') ? '12px' : '0',
                }"
              />
              <ProviderChooser
                class="provider-chooser"
                :items="availableProviders"
                @select="selectProvider"
                @create-provider="createProvider"
                :default-selected-index="selectProviderIndex"
              />
            </div>
            <ar-simple-button
              text="Import"
              :loading="isImportingData"
              @click="beginImport"
              :style="{ float: 'right' }"
            />
          </div>
        </div>
      </div>
      <div class="footer-container" v-else-if="step===3">
        <p v-if="error">
          {{ error }}
        </p>
        <ar-simple-button
          text="Close"
          @click="onClose"
          :style="{ float: 'right' }"
        />
      </div>
    </div>
  </ar-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CURRENCY_CODES, initialAudienceCSVMap, initialEventsCSVMap } from '~/utils/constants';
import { capitalizeFirstLetter } from '@/utils/helpers';
import moment from 'moment';
import ProviderChooser from './ProviderChooser';

// XXX/RK/27-05-2019
//
// NOTE: we're using moment's strict parsing mode to
// ensure that the dates in the promoter's CSV are compatible
// with the date-parsing on the server. Strict mode is very
// picky by nature; more so than our serverside clj-time parser.
//
// One of the major "gotcha's" is single-digit values. E.g., if
// a column value for a date has day "8" instead of "08",
// `moment` would consider that date invalid even though the server
// can process a date-string like 8/12/2000 perfectly fine as
// the 8th of December, 2000.
//
// So when adding formats to this list, ensure that you've accounted for
// single-digit values for hour, day, and month when applicable.
const allowedDateFormats = [
  "DD/MM/YYYY HH:mm:ss",
  "DD/MM/YYYY HH:mm",
  "DD/MM/YYYY H:mm",
  "D/MM/YYYY H:mm",
  "D/M/YYYY H:mm",
  "DD/MM/YY HH:mm",
  "DD/MM/YY H:mm",
  "D/M/YY HH:mm",
  "D/M/YY H:mm",
  "YYYY-MM-DD HH:mm:ss.sss",
  "MMM DD, YYYY [at] hh:mm a",
  "MMM D, YYYY [at] hh:mm a",
  "MMM DD, YYYY [at] h:mm a",
  "MMM D, YYYY [at] h:mm a",
  "YYYY-MM-DD HH:mm:ssZ",
  "YYYY-MM-DDTHH:mm:ssZ",
  "YYYY/MM/dd HH:mm:ssZ",
  "YYYY/MM/dd",
  "MM/DD/YY",
  "M/D/YY",
  "M/D/YYYY",
  "MM/DD/YYYY",
  "DD/MM/YY",
  "D/M/YY",
  "D/M/YYYY",
  "DD/MM/YYYY",
  "MM-DD-YY",
  "M-D-YY",
  "M-D-YYYY",
  "MM-DD-YYYY",
  "DD-MM-YY",
  "D-M-YY",
  "D-M-YYYY",
  "DD-MM-YYYY",
  "YYYY-MM-DD[T]HH:mm:ss",
  "YYYY-MM-DD HH:mm:ss",
  "M/D/YYYY h:mm:ss a",
  "M/DD/YYYY h:mm:ss a",
  "MM/D/YYYY h:mm:ss a",
  "MM/DD/YYYY h:mm:ss a",
  "M/D/YYYY hh:mm:ss a",
  "M/DD/YYYY hh:mm:ss a",
  "MM/D/YYYY hh:mm:ss a",
  "MM/DD/YYYY hh:mm:ss a",
  "D/M/YYYY h:mm:ss a",
  "DD/M/YYYY h:mm:ss a",
  "D/MM/YYYY h:mm:ss a",
  "DD/MM/YYYY h:mm:ss a",
  "D/M/YYYY hh:mm:ss a",
  "DD/M/YYYY hh:mm:ss a",
  "D/MM/YYYY hh:mm:ss a",
  "DD/MM/YYYY hh:mm:ss a",
];

export default {
  name: 'ImportEventDataModal',

  components: {
    ProviderChooser,
  },

  data() {
    return {
      step: 1,
      type: 'audience',
      activeMapping: 'events',
      arToPromoterMap: initialAudienceCSVMap(),
      selectedCurrency: null,
      selectedTimezone: null,
      csvHeaders: [],
      csvRows: [],
      currencyOptions: CURRENCY_CODES.map(c => ({ name: c.code })),
      error: '',
      // NOTE/RK: things below here used to be props
      isVisible: false,
      eventOid: null,
      isMassImport: false,
      isImportingData: false,
      // providers
      isProviderChooserOpen: false,
      availableProviders: [],
      selectedProvider: null,
      mouseOverCsvDropdown: false,
      onConfirm: () => {},
      onClose: () => {},
    };
  },
  computed: {
    header() {
      if (this.step === 0) {
        return 'Mass import';
      }
      switch (this.type) {
        case 'audience':
          return 'Import ticket sales';
        case 'events':
          return 'Import events';
        case 'orders':
          return 'Import orders';
        case 'events and orders':
          return 'Import events & orders';
        default:
          return 'Mass import';
      }
    },
    importCompleteMessage() {
      return this.$store.state.importMessage;
    },
    massImportOptions: () => ['events', 'orders', 'events and orders'],
    selectProviderIndex() {
      // NOTE/RK: don't do `index = null;`
      // doing `index = -1` avoids having to do a null check
      // in the return statement since null >= 0 is true
      let index = -1;
      if(this.selectedProvider) {
        index = this.availableProviders.findIndex(
          a => a.name.toLowerCase() === this.selectedProvider.toLowerCase()
        );
      }

      return index >= 0 ? index : null;
    },
    selectedCurrencyIndex() {
      if (this.selectedCurrency === null) { return null; }
      const currencyCode = this.selectedCurrency.name;
      const index = this.currencyOptions.findIndex(c => c.name === currencyCode);
      return index === -1 ? null : index;
    },
    visibleHeaderSelections() {
      return this.arToPromoterMap.filter(mapHeader =>
        mapHeader.relatesTo === this.activeMapping
      );
    },
  },

  watch: {
    isMassImport(val) {
      if (val) {
        this.step = 0;
        this.type = 'mass';
      } else {
        this.type = 'audience';
      }
    },
  },
  created() {
    this.arToPromoterMap = initialAudienceCSVMap();

    window.addEventListener('arModalOpen', this.handleModalOpen);
  },

  beforeDestroy() {
    window.removeEventListener('arModalOpen', this.handleModalOpen);
  },

  methods: {
    ...mapActions([
      'IMPORT_EVENT_DATA',
      'OPEN_FAILED_IMPORT_MODAL',
      'FETCH_CUSTOM_PROVIDERS',
      'CREATE_CUSTOM_PROVIDER'
    ]),
    handleModalOpen({ detail: { action, payload } }) {
      if (action !== 'OPEN_IMPORT_EVENT_DATA_MODAL') return;

      this.startModalAction(payload);
    },
    respondModalAction(payload) {
      window.dispatchEvent(new CustomEvent('arModalResponse', { detail: { action: 'OPEN_IMPORT_EVENT_DATA_MODAL', payload } }));
    },
    async startModalAction({ eventOid, prefetch, isMassImport }) {
      await this.getProviders();
      this.isVisible = true;
      this.eventOid = eventOid || null;
      this.isMassImport = isMassImport || false;

      if (prefetch) {
        this.step = 2;
        this.csvHeaders = prefetch.headers;
        this.csvRows = prefetch.body;
        this.type = 'audience';
      } else if (this.isMassImport) {
        this.step = 0;
      } else {
        this.step = 1;
      }
      this.onConfirm = () => {
        this.isVisible = false;
        this.respondModalAction(true);
      };
      this.onClose = () => {
        this.type = this.isMassImport ? 'mass' : 'audience';
        this.step = this.isMassImport ? 0 : 1;
        this.csvHeaders = [];
        this.csvRows = [];
        this.isVisible = false;
        this.activeMapping = 'events';
        this.arToPromoterMap = initialAudienceCSVMap();
        this.selectedCurrency = null;
        this.selectedProvider = null;
        this.error = '';

        this.respondModalAction(false);
      };
    },
    async getProviders() {
      this.availableProviders = (await this.FETCH_CUSTOM_PROVIDERS()).map(p => {
        return {
          name: capitalizeFirstLetter(p.provider),
          key: p.provider.toLowerCase(),
          value: p.oid,
          columnMapping: p.meta.columnMap || null,
        };
      });
    },
    handleCSVUploaded({ additionalInfo: { headers, body } }) {
      this.csvHeaders = headers;
      this.csvRows = body;
      this.step = 2;
    },
    changeMapping(tabItem) {
      this.activeMapping = tabItem.id;
    },
    toggleProviderChooser() {
      this.isProviderChooserOpen = !this.isProviderChooserOpen;
    },
    previousStep() {
      this.step -= 1;
    },
    selectType(type) {
      this.type = type;
      if (this.type === 'events') {
        this.arToPromoterMap = initialEventsCSVMap();
      } else if (this.type === 'orders') {
        this.arToPromoterMap = initialAudienceCSVMap();
        this.arToPromoterMap.push({
          value: 'Event ID',
          required: true,
          serverHeaderName: 'eventId',
          mappedTo: null,
          columnType: 'string',
        });
      } else if (this.type === 'events and orders') {
        const interimArToPromoterMap = initialEventsCSVMap().map(e => {
          e.relatesTo = 'events';
          return e;
        });

        this.arToPromoterMap = interimArToPromoterMap.concat(
          initialAudienceCSVMap().map(a => {
            a.relatesTo = 'orders';
            return a;
          })
        );
      }

      this.step = 1;
    },
    selectProvider(providerObj) {
      this.selectedProvider = providerObj.name.toLowerCase();

      // RK/NOTE:
      // We don't want to prefill the column mappings for now.
      // if (
      //   providerObj.columnMapping &&
      //   this.arToPromoterMap.every(a => a.mappedTo === null || a.mappedTo === undefined)
      // ) {
      //   // columnMaps are stored as kv pairs where k is an index and v is a server column name
      //   const mapping = providerObj.columnMapping;
      //   for (var columnIndex in mapping) {
      //     const serverHeaderName = mapping[columnIndex];
      //     const arMappingIndex = this.arToPromoterMap.findIndex(
      //       a => a.serverHeaderName === serverHeaderName
      //     );

      //     if (arMappingIndex >= 0) {
      //       this.arToPromoterMap[arMappingIndex].mappedTo = parseInt(columnIndex);
      //     }
      //   }
      // }
    },
    selectCurrency(val) {
      this.selectedCurrency = val;
    },
    setMapping(val, arTableHeader) {
      const arTableHeaderIndex = this.arToPromoterMap.indexOf(arTableHeader);
      this.arToPromoterMap[arTableHeaderIndex].mappedTo = val.index;
      this.arToPromoterMap[arTableHeaderIndex].error = undefined;
    },
    csvHeadersOptions(selectedHeaderIndex) {
      if (selectedHeaderIndex !== null) {
        return [
          {
            name: this.csvHeaders[selectedHeaderIndex],
            index: selectedHeaderIndex,
          },
        ];
      }

      return this.csvHeaders
        .map((c, index) => ({
          name: c,
          index,
        })).filter(c => {
          return this.arToPromoterMap.find(arpm => arpm.mappedTo === c.index) === undefined;
        });
    },
    clientToServerMapping(mapping) {
      const cleanedColumnMap = {};

      this.arToPromoterMap.filter(a => a.mappedTo !== null).forEach(a => {
        cleanedColumnMap[a.mappedTo] = a.serverHeaderName;
      });

      return cleanedColumnMap;
    },
    async createProvider(provider) {
      const currentMapping = this.clientToServerMapping();
      const payload = {
        provider: provider.toLowerCase(),
        mapping: currentMapping
      };

      const result = await this.CREATE_CUSTOM_PROVIDER(payload);
      if (result) {
        this.availableProviders.push({
          name: capitalizeFirstLetter(result.provider),
          key: result.provider.toLowerCase(),
          value: result.oid,
          columnMapping: result.meta.columnMap || null,
        });

        this.selectedProvider = result.provider;
      }
    },
    csvValidationCheck(mapping) {
      const csvLength = this.csvRows.length;
      const checkTheseRows = [
        this.csvRows[0],
        this.csvRows[Math.floor(csvLength/2)],
        this.csvRows[csvLength - 1]
      ];

      const checkType = mapping.columnType || 'string';
      let predicate = row => true;
      switch (checkType) {
        case 'email':
          predicate = row => {
            const index = mapping.mappedTo;

            // this is the email regex used in campaign server
            const emailRegex = /.+\@.+\..+/;
            return !emailRegex.test(row[index]);
          };
          break;
        case 'number':
          predicate = row => {
            const index = mapping.mappedTo;
            return isNaN(parseFloat(row[index]));
          };
          break;
        case 'datetime':
          predicate = row => {
            const index = mapping.mappedTo;
            if (!mapping.required && !row[index]) {
              return false;
            }

            // all valid formats allowed by campaign server
            return !moment(
              row[index],
              allowedDateFormats,
              true
            ).isValid();
          };
          break;
        default:
          predicate = row => undefined;
          break;
      }

      const failingRow = checkTheseRows.find(predicate);
      if(failingRow !== undefined) {
        return failingRow[mapping.mappedTo];
      } else {
        return null;
      }
    },
    async beginImport() {
      const incompleteRows = [];
      this.arToPromoterMap.forEach((a, index) => {
        if (a.required && (a.mappedTo === null || a.mappedTo === -1)) {
          incompleteRows.push(index);
        }
      });

      let flagMissingField = false;
      if (this.selectedProvider === null) {
        this.error = 'Please select a ticketing provider';
        flagMissingField = true;
      } else if (this.type !== 'events' && this.selectedCurrency === null) {
        this.error = 'Please select a currency';
        flagMissingField = true;
      } else if (this.csvRows.length === 0) {
        this.error = 'Your CSV has no rows';
      }


      if (incompleteRows.length > 0 || flagMissingField) {
        let mapWithErrors = this.arToPromoterMap.map((a, index) => {
          if (incompleteRows.includes(index)) {
            a.error = 'Hold up! This field is required.';
          }
          return a;
        });

        this.arToPromoterMap = mapWithErrors;
      } else {

        let validationFailures = '';

        // Pre-validation check.
        this.arToPromoterMap.forEach(mapping => {
          if (
            mapping.mappedTo !== null &&
            this.csvValidationCheck(mapping) !== null
          ) {
            mapping.error = `Your CSV has an invalid value for this column.`;
            validationFailures += `The ${mapping.value} column has an invalid value: ${this.csvValidationCheck(mapping)} <br/>`;
          }
        });

        if (this.arToPromoterMap.some(a => a.error)) {
          this.error = validationFailures;
          return;
        }

        let serverImportType = 'event';

        if (this.isMassImport) {
          switch (this.type) {
            case 'orders':
              serverImportType = 'audience';
              break;
            case 'events':
              serverImportType = 'event';
              break;
            default:
              serverImportType = 'event-and-audience';
              break;
          }
        }

        const payload = {
          csvHeaders: this.csvHeaders,
          isMassImport: this.isMassImport,
          columnMap: this.clientToServerMapping(),
          rows: this.csvRows,
          importType: serverImportType,
          eventOid: this.eventOid,
          provider: this.selectedProvider,
          currencyCode: this.isMassImport && serverImportType === 'event' ? null : this.currencyOptions[this.selectedCurrencyIndex].name,
          timeZone: this.selectedTimezone,
        };

        try {
          this.isImportingData = true;
          const response = await this.IMPORT_EVENT_DATA(payload);
          this.onConfirm();
          scroll(0, 0);  //back to top of page to view the notification

          const notificationType = response.type === 'ACCEPTED' ? 'success' :
                                   response.type === 'PARTIAL' ? 'warning' : 'error';

          const notificationPayload = {
            type: notificationType,
            message: response.message,
            link: response.type === 'PARTIAL' ? 'Click here for more info' : null,
            timeout: 5000,
          };

          if (response.type === 'PARTIAL') {
            const answer = await this.$arNotification.push(notificationPayload);

            if (answer) {
              this.OPEN_FAILED_IMPORT_MODAL(response.failedRows);
            }
          } else {
            this.$arNotification.push(notificationPayload);
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.isImportingData = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.import-data-modal {

  .mapping-instructions {
    background-color: $skyBlueGrey300;
    padding: 20px;

    &__subtitle {
      font-size: 12px;
      color: $blueGrey700;
    }
  }

  .tabs {
    padding: 0 20px;
    background-color: $skyBlueGrey300;
  }

  &__wrapper_preamble {
    text-align: center;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    padding: 23px 28px 28px;
    overflow: auto;

    &.xs-max {
      padding: 23px 12px 28px;
    }

    &.eventsAndOrders {
      max-height: calc(70vh - 120px);
      &.xs-max {
        max-height: calc(70vh - 55px);
      }
    }

    &.notEventsAndOrders {
      max-height: calc(70vh - 90px);
      &.xs-max {
        max-height: calc(70vh - 35px);
      }
    }

    &.generic {
      padding: 0px;
    }

    > .mass-import-option {
      min-height: 86px;
      border-top: 1px solid $blueGrey500;
      padding: 1.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;

      &.xs-max {
        padding: 24px 12px;
      }

      > div {
        padding: 0.75rem;

        > p.heading {
          color: #43516b;
          font-size: 16px;
        }

        > p.body {
          color: #8e97a6;
        }

        &:last-child {
          position: absolute;
          right: 24px;

          &.xs-max {
            right: 12px;
          }
        }
      }

      &:hover {
        cursor: pointer;
        background-color: darken(white, 2%);
      }

    }

    p.column-text {
      color: $blueGrey700;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 13px;
      border-bottom: 1px solid $skyBlueGrey500;
      padding-bottom: 13px;
      span:last-child {
        float: right;
      }
    }

    &__import-message {
      font-size: 1.2em;
      padding-top: 1em;
    }

    &_preamble {
      margin-bottom: 1em;
    }

    .form {
      padding-bottom: 28px;
      .form-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: space-between;
        align-items: center;
        margin-top: 0.75em;

        &__label {
          font-weight: bold;
          width: 40%;
          position:relative;

          .ar-icon-wrapper {
            position: absolute;
            right: 8px;
            margin-bottom: 0;
            bottom: 8px;
          }
        }

        &__input {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 60%;

          .selector {
            width: 100%;
          }

          .error {
            color: $red500;
          }
        }

        > :first-child {
          flex: 1;
        }
        > :nth-child(2) {
          flex: 3;
        }

        .secondary-dropdown {
          margin-left: 15px;
        }
      }
    }
  }

  &__footer {
    .footer-container {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      background-color: $skyBlueGrey300;
      position:relative;
      z-index:$zIndexHighest;

      .footer-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-height: 50px;
        padding: 28px 28px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      p {
        text-align: center;
        color: $blueGrey800;

        &.header {
          font-family: Graphik-Medium;
          font-size: 16px;
          line-height: 25px;
          letter-spacing: 0;
        }

        &.body {
          font-size: 14px;
          letter-spacing: 0;
        }
      }

      div.error-message {
        border: 1px solid $orange500;
        background: $orange-lite;
        transition: all 0.3s ease;
        color: #42516b;
        border-radius: 5px;
        padding: 12px 20px;
        text-align: left;
        margin: 0 28px 0 28px;
      }

      .tick-container {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: $green500;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__type,
  &__action,
  &__get-started {
    width: 50%;
  }

  &__type,
  &__action,
  &__get-started {
    padding: 25px 35px;
  }

  &__type {
    background-color: $skyBlueGrey300;
    height: 100%;
  }

  &__button {
    width: 100%;
    margin-top: 42px;
  }

  &__checkbox {
    margin-top: 15px;
  }
  &__footer-wrapper {
    display: flex;
    height: 50px;
    box-shadow: 0 3px 10px;
    padding: 50px;
    align-items: center;
    justify-content: flex-end;
  }

  &__eventbrite {
    text-decoration: underline;
    margin-top: 15px;
  }

  div.ticketing-provider {
    display: flex;
    align-items: center;

    &.xs-max {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .provider-chooser {
      height: 14px;
      margin-left: 5px;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  .icon-container {
    background: $skyBlueGrey400;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width:60px;
    min-height:60px;

    &.xs-max {
      width: 44px;
      height: 44px;
      min-width:44px;
      min-height:44px;
    }
  }
}
</style>
