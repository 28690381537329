var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'no-content-section-wrapper',
    _vm.type ]},[_c('div',{staticClass:"top-right-section"},[_vm._t("top-right")],2),_vm._v(" "),(_vm.iconName)?_c('ar-icon',_vm._b({attrs:{"name":_vm.iconName}},'ar-icon',_vm.iconPropsDecorated,false)):_vm._e(),_vm._v(" "),(_vm.header)?_c('ar-text',{ref:"header",style:({
      marginTop: _vm.iconName ? '24px' : null,
    }),attrs:{"text":_vm.header,"allow-html":"","multiple-lines":"","size":_vm.headerSize,"align":"center"}}):_vm._e(),_vm._v(" "),(_vm.body)?_c('ar-text',{ref:"body",style:({
      color: _vm.$arStyle.color.skyBlueGrey700,
      marginTop: '13px'
    }),attrs:{"text":_vm.body,"allow-html":"","multiple-lines":"","size":"xs","align":"center"},on:{"anchorClick":function (idx) { return _vm.handleTypographyAnchorClick('body', idx); }}}):_vm._e(),_vm._v(" "),(_vm.buttonText)?_c('ar-simple-button',_vm._b({staticClass:"u-margin-top-7",style:({
      marginTop: _vm.body ? '30px' : '41px',
    }),attrs:{"text":_vm.buttonText},on:{"click":_vm.handleButtonClick}},'ar-simple-button',_vm.decoratedButtonProps,false)):_vm._e(),_vm._v(" "),(_vm.$slots.footer)?_c('div',{staticClass:"footer-section"},[_vm._t("footer")],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }