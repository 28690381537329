<template>
  <div class="message-view-stats-wrapper">
    <ar-text
      class="excluded-text u-margin-bottom-7"
      size="sm"
      v-if="rejectedMessagesCount > 0"
      allow-html
      multiple-lines
      :text="rejectedMessagesText"
    />
    <am2-overview-tabs
      :items="overviewStats"
      class="overview-section"
      @select="handleTabClick"
    />
  </div>
</template>
<script>
import accounting from 'accounting';
export default {
  name: 'MessageOverviewStats',
  props: {
    selectedMessage: {
      type: Object,
      default: null
    },
    statusDetails: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      messageStats: null
    }
  },
  methods: {
    handleTabClick(statItem) {
      this.$emit('tab-click', statItem)
    }
  },
  computed: {
    messageStatSpamRate() {
      if(!this.statusDetails) return null
      if(this.messageStatTotalSuccessfulSends < 1) return '0%'
      let rate = ((this.statusDetails.markedSpam || 0) * 100) / this.messageStatTotalSuccessfulSends
      if (rate === 0) {
        return '0%'
      } else if (rate < 1) {
        return `${rate.toPrecision(2)}%`
      } else if (rate > 99 && rate < 100) {
        return `99%`
      } else {
        return `${Math.round(rate)}%`
      }
    },

    // At some point in the future, we'll have more reasons for rejection other than 'bounced'. But for now, let's use that.
    rejectedMessagesCount() {
      if (!this.selectedMessage.statusDetails || !this.selectedMessage.statusDetails.rejectedRecipients) return 0;
      const bounced = this.selectedMessage.statusDetails.rejectedRecipients.bounced;
      return bounced || 0;
    },

    // At some point in the future, we'll have more reasons for rejection other than 'bounced'. But for now, let's use that.
    rejectedMessagesText() {
      return `We have excluded sending to <strong>${accounting.format(this.rejectedMessagesCount)}</strong> contact${this.rejectedMessagesCount === 1 ? '' : 's'} because they have bounced previously.`;
    },

       // Bounces, plus deferred plus regular old fails
    messageStatFailRate() {
      if(!this.statusDetails) return null
      if(this.messageStatTotalSuccessfulSends < 1) return '0%'
      let deferredAndFailed = (this.statusDetails.deferred || 0 ) + (this.statusDetails.failed || 0)
      let rate = (deferredAndFailed * 100) / this.selectedMessage.statusDetails.totalMessages
      if (rate === 0) {
        return '0%'
      } else if (rate < 1) {
        return `${rate.toPrecision(2)}%`
      } else if (rate > 99 && rate < 100) {
        return `99%`
      } else {
        return `${Math.round(rate)}%`
      }
    },

    messageStatUnsubscribeRate() {
      if(!this.statusDetails) return null
      if(this.messageStatTotalSuccessfulSends < 1) return '0%'
      let rate = ((this.statusDetails.unsubscribed || 0) * 100) / this.messageStatTotalSuccessfulSends
      if (rate === 0) {
        return '0%'
      } else if (rate < 1) {
        return `${rate.toPrecision(2)}%`
      } else if (rate > 99 && rate < 100) {
        return `99%`
      } else {
        return `${Math.round(rate)}%`
      }
    },

     // Bounces, plus deferred
    messageStatBounceRate() {
      if(!this.statusDetails) return null;
      if(this.messageStatTotalSuccessfulSends < 1) return '0%'
      let bouncedAndDeferred = (this.statusDetails.bounced || 0) + (this.statusDetails.deferred || 0 )
      let rate = (bouncedAndDeferred * 100) / this.selectedMessage.statusDetails.totalMessages
      if (rate === 0) {
        return '0%'
      } else if (rate < 1) {
        return `${rate.toPrecision(2)}%`
      } else if (rate > 99 && rate < 100) {
        return `99%`
      } else {
        return `${Math.round(rate)}%`
      }
    },

    messageStatClickRate() {
      // The CTR for SMS is clicks / recipients
      // The CTR for email/fbm is clicks / opens
      if(!this.statusDetails) return null;
      if(this.messageStatTotalSuccessfulSends < 1) return '0%'
      let rate
      if (this.selectedMessage?.provider === 'sms') {
        rate = ((this.statusDetails.clicked || 0) * 100) / this.messageStatTotalSuccessfulSends
      } else {
        let opensAndClicks = (this.statusDetails.opened || 0) + (this.statusDetails.clicked || 0)
        rate = opensAndClicks > 0 ? ((this.statusDetails.clicked || 0) * 100) / opensAndClicks : 0;
      }

      if (rate === 0) {
        return '0%'
      } else if (rate < 1) {
        return `${rate.toPrecision(2)}%`
      } else if (rate > 99 && rate < 100) {
        return `99%`
      } else {
        return `${Math.round(rate)}%`
      }
    },

    messageStatTotalSuccessfulSends() {
      if (!this.selectedMessage || !this.selectedMessage.statusDetails) {
        return 0
      }
      return (this.selectedMessage.statusDetails.totalMessages || 0)
        - (this.selectedMessage.statusDetails.bounced || 0)
        - (this.selectedMessage.statusDetails.deferred || 0)
        - (this.selectedMessage.statusDetails.failed || 0)
    },

    // Clicks and opens
    messageStatOpenRate() {
      if(!this.statusDetails) return null
      if(this.messageStatTotalSuccessfulSends < 1) return '0%'
      let opensAndClicks = (this.statusDetails.opened || 0) + (this.statusDetails.clicked || 0)
      let rate = (opensAndClicks * 100) / this.messageStatTotalSuccessfulSends
      if (rate === 0) {
        return '0%';
      } else if (rate < 1) {
        return `${rate.toPrecision(2)}%`
      } else if (rate > 99 && rate < 100) {
        return `99%`
      } else {
        return `${Math.round(rate)}%`
      }
    },

    overviewStats() {
      let stats = [
        {
          name: 'All Recipients',
          value: this.statusDetails && this.statusDetails.totalMessages,
          hoverValue: null,
          key: null,
        },
      ];

      if (this.selectedMessage && this.selectedMessage.provider === 'email') {
        stats = [
          ...stats,
          // The number of opens is a combination of clicks and opens
          // because one needs to open an email in order to click a link.
          //
          // Notice how email stats have 5 'stats' whereas the others have only 4.
          // This is by design because email has a lot more possible stats available.
          // Though the bar looks a little more crowded, having 5 stats showing is not
          // detrimental to the 'look' of the overview bar. Responsivity is fine as well.
          {
            name: 'Opened',
            value: this.statusDetails && ((this.statusDetails.opened || 0) + (this.statusDetails.clicked || 0)),
            hoverValue: this.messageStatOpenRate,
            key: 'opened',
          },
          {
            name: 'Clicked',
            value: this.statusDetails && this.statusDetails.clicked || 0,
            hoverValue: this.messageStatClickRate,
            key: 'clicked',
          },
          {
            name: 'Bounced',
            value: this.statusDetails && this.statusDetails.bounced || 0,
            hoverValue: this.messageStatBounceRate,
            key: 'bounced',
          },
          {
            name: 'Unsubscribed',
            value: this.statusDetails && this.statusDetails.unsubscribed || 0,
            hoverValue: this.messageStatUnsubscribeRate,
            key: 'unsubscribed',
          },
          // {
          //   name: 'Marked as Spam',
          //   value: this.statusDetails && this.statusDetails.markedSpam || 0,
          //   hoverValue: this.messageStatSpamRate,
          //   key: 'markedSpam',
          // },
          {
            name: 'Failed',
            value: this.statusDetails && ((this.statusDetails.deferred || 0 ) + (this.statusDetails.failed || 0)),
            hoverValue: this.messageStatFailRate,
            key: 'failed',
          },
        ];
      } else if (this.selectedMessage && this.selectedMessage.provider === 'facebook') {
        stats = [
          ...stats,
          // The number of opens is a combination of clicks and opens
          // because one needs to open an email in order to click a link
          {
            name: 'Sent',
            value: this.statusDetails && ((this.statusDetails.sent || 0) + (this.statusDetails.opened || 0) + (this.statusDetails.clicked || 0)),
            hoverValue: null,
            key: 'sent',
          },
          {
            name: 'Opened',
            value: this.statusDetails && ((this.statusDetails.opened || 0) + (this.statusDetails.clicked || 0)),
            hoverValue: this.messageStatOpenRate,
            key: 'opened',
          },
          {
            name: 'Clicks',
            value: this.statusDetails && this.statusDetails.clicked || 0,
            hoverValue: this.messageStatClickRate,
            key: 'clicked',
          },
          {
            name: 'Failed',
            value: this.statusDetails && this.statusDetails.failed || 0,
            hoverValue: this.messageStatFailRate,
            key: 'failed',
          },
        ];
      } else {
        stats = [
          ...stats,
          // Not showing "Sent" here because issues with Twilio webhooks can cause this number to be incorrect.
          // And that leads to all sorts of problems for the Customer Success team.
          {
            name: 'Clicks',
            value: this.statusDetails && this.statusDetails.clicked || 0,
            hoverValue: this.messageStatClickRate,
            key: 'clicked',
          },
          {
            name: 'Unsubscribed',
            value: this.statusDetails && this.statusDetails.unsubscribed || 0,
            hoverValue: this.messageStatUnsubscribeRate,
            key: 'unsubscribed',
          },
          {
            name: 'Failed',
            value: this.statusDetails && this.statusDetails.failed || 0,
            hoverValue: this.messageStatFailRate,
            key: 'failed',
          },
        ];
      }
      return stats
    },
  }
}
</script>
<style lang="scss" scoped>
  .message-view-stats-wrapper {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    min-height: 110px;

    .excluded-text {
      width: 90%;
      max-width: 1200px;
    }

    .overview-section {
      width: 90%;
      max-width: 1200px;
      border-radius: 5px;
    }

  }
</style>
