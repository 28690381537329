<template>
  <main class="ar-edit-layout">
    <div
      ref="page-content"
      class="page-content"
    >
      <nuxt />
    </div>
    <ModalsExpansion>
      <portal-target name="modal" />
      <portal-target name="higher-order-modal" />
    </ModalsExpansion>
  </main>
</template>

<script>
import ModalsExpansion from '@/layouts/expansions/modals-expansion';

export default {
  name: 'EditLayout',

  middleware: 'authenticated',

  components: {
    ModalsExpansion,
  },

  created() {
    window.addEventListener('resize', this.updateMediaQuery);
    this.updateMediaQuery({
      windowWidth: window.innerWidth,
      pageContentWidth: window.innerWidth,
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.updateMediaQuery);
  },

  methods: {
    updateMediaQuery() {
      this.$updateArMediaQuery({
        windowWidth: window.innerWidth,
        pageContentWidth: window.innerWidth,
      });
    },
    dispatchResizeEvent() {
      window.dispatchEvent(new Event('resize'));
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-edit-layout {
  position: relative;
  display: flex;

  .page-content {
    flex-grow: 1;
    min-width: 0;
  }
}
</style>
