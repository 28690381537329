var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-card-container',{class:[
    'ar-upgrade-plan-section-wrapper',
    _vm.design
  ]},[_c('ar-icon',{attrs:{"name":_vm.featureData.iconName,"color":_vm.$arStyle.color.purple500,"wrapper-style":{
      display: 'inline-flex',
      alignItem: 'center',
      justifyContent: 'center',
      width: _vm.design === 'row' ? '50px' : '60px',
      height: _vm.design === 'row' ? '50px' : '60px',
      boxShadow: _vm.design === 'column' ? ("0 0 8px 0 " + (_vm.$arStyle.color.blueGrey500)) : null,
      borderRadius: '50%',
      background: _vm.design === 'row' ? _vm.$arStyle.color.skyBlueGrey300 : '#FFF',
    }}}),_vm._v(" "),_c('div',{staticClass:"text-section"},[_c('ar-text',{class:[
        'title',
        _vm.design === 'column' && 'u-margin-top-5 u-margin-bottom-3' ],attrs:{"size":"md","multiple-lines":"","text":_vm.featureData.title,"align":_vm.design === 'row' ? 'left' : 'center',"weight":"bold"}}),_vm._v(" "),_c('ar-text',{class:[
        'description',
        _vm.design === 'column' && 'u-margin-bottom-4' ],style:({
        color: _vm.design === 'column' ? _vm.$arStyle.color.skyBlueGrey800 : null,
      }),attrs:{"size":"xs","text":_vm.featureData.description,"align":_vm.design === 'row' ? 'left' : 'center',"multiple-lines":"","allow-html":"","line-height":"25px"}})],1),_vm._v(" "),_c('div',{staticClass:"button-section"},[_c('ar-simple-button',{attrs:{"type":"green","icon-name":"lock","icon-props":{
        width: '14px',
      },"text":"Upgrade now"},on:{"click":_vm.handleUpgradeButtonClick}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }