<template>
  <am2-card-container
    class="am2-switch-field-card"
  >
    <div
      :class="[
        'u-display-flex',
      ]"
      :style="{
        flexFlow: size === 'small' ? 'column-reverse' : null,
      }"
    >
      <div
        :class="[
          'u-flex-grow-1',
          'u-padding-right-3',
        ]"
      >
        <div
          class="u-display-flex u-flex-flow-row"
          :style="{
            marginTop: titleMarginTop,
          }"
        >
          <ar-text
            size="xs"
            :text="title"
            weight="bold"
            :style="{
              color: $arStyle.color.blueGrey800,
            }"
          />
          <ar-link-button
            v-if="hasEditLink && isToggledUp"
            class="u-margin-left-3"
            :text="showTextField ? 'hide' : 'edit'"
            @click="toggleShowTextField"
          />
        </div>
        <ar-text
          v-show="!!subtitle"
          size="xs"
          :text="subtitle"
          multiple-lines
          class="u-margin-top-1"
          :style="{
            color: $arStyle.color.blueGrey700,
          }"
        />
      </div>
      <am2-switch
        :class="[
          size === 'small' ? 'u-margin-bottom-3' : null,
        ]"
        :disabled="disabled"
        @input="handleSwitch"
        :value="isToggledUp"
      />
    </div>
    <div v-if="displayDefaultSlot" class="u-margin-top-3">
      <slot />
    </div>
  </am2-card-container>
</template>



<script>
export default {
  name: 'TextFieldSwitchCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasEditLink: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: null
    },
    size: {
      type: String,
      default: 'normal',
      validator: (val) => ['small', 'normal'].indexOf(val) > -1,
    },
  },
  
  data() {
    return {
      isToggledUp: this.value,
      showTextField: false,
    }
  },

  watch: {
    value(newValue) {
      this.isToggledUp = newValue;
    },
  },

  computed: {
    displayDefaultSlot() {
      if (this.hasEditLink) {
        return !!this.$slots.default && this.isToggledUp && this.showTextField;
      } else {
        return !!this.$slots.default && this.isToggledUp;
      }
    },
    titleMarginTop() {
      if (this.title && !this.subtitle) {
        return '8px';
      }
      return null;
    },
  },

  methods: {
    handleSwitch(enabled) {
      this.isToggledUp = enabled;
      this.$emit('input', enabled);

      if (this.isToggledUp) {
        this.showTextField = false;
      }
    },

    toggleShowTextField() {
      this.showTextField = !this.showTextField;
    },
  },
}
</script>

<style lang="scss">
.am2-switch-field-card {
  padding: 16px 20px;
}
</style>