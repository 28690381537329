<template>
  <portal
    to="modal"
    :disabled="!opened"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="opened"
      :header="header"
      hide-footer
      width="1200px"
      height="100%"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleModalClose"
      class="message-preview-modal"
    >
      <div slot="body" class="body">
        <am2-elegant-tabs
          v-if="hasModeTabs"
          class="tabs"
          :items="modeTabs"
          :tab-key="mode"
          @select="handleModeSelect"
        />
        <div
          v-if="mode === 'mobile'"
          class="mobile-preview-section"
        >
          <am2-mobile-container
            :has-footer="false"
            size="fullHeight"
          >
            <am2-email-preview
              opened
              :email-template="emailHtml"
              :sender-name="senderName"
              :subject="subject"
              :preview-text="previewText"
              :scheduled-at="scheduledAt"
            />
          </am2-mobile-container>
        </div>
        <div
          v-if="mode === 'desktop'"
          class="desktop-preview-section"
        >
          <am2-email-preview
            :mobile="false"
            opened
            :email-template="emailHtml"
            :sender-name="senderName"
            :subject="subject"
            :preview-text="previewText"
            :scheduled-at="scheduledAt"
          />
        </div>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
export default {
  name: 'EmailPreviewModal',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    emailHtml: {
      type: String,
      default: null,
    },
    hasModeTabs: {
      type: Boolean,
      default: true,
    },
    senderName: {
      type: String,
      default: null,
    },
    subject: {
      type: String,
      default: null,
    },
    previewText: {
      type: String,
      default: null,
    },
    scheduledAt: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      mode: 'desktop',
      modeTabs: [
        {
          name: 'Desktop',
          key: 'desktop',
        },
        {
          name: 'Mobile',
          key: 'mobile',
        },
      ],
    };
  },
  computed: {
    header() {
      if (this.mode === 'mobile') {
        return 'Mobile preview';
      } else {
        return 'Desktop preview';
      }
    },
  },

  watch: {
    opened(newVal) {
      if (newVal) {
        this.mode = 'desktop';
      }
    },
  },

  methods: {
    handleModalClose() {
      this.$emit('close');
    },
    handleModeSelect(tabItem) {
      this.mode = tabItem.key;
    },
  },
};
</script>

<style lang="scss" scoped>
.message-preview-modal {
  .body {
    position: relative;
    height: 100%;
    overflow: hidden;
    .tabs {
      position: absolute;
      top: 13px;
      right: 26px;
      width: 192px;
    }

    .mobile-preview-section {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 60px 0;
      height: 100%;
    }

    .desktop-preview-section {
      padding: 12px 0 0;
      height: 100%;
    }
  }
}
</style>
