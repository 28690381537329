<template>
  <AuthenticateFrame
    hasGoBackLink
    @previousStep="goBackToLogin"
  >
    <ar-text
      text="Reset password"
      size="lg"
      multiple-lines
      :style="{
        marginBottom: '40px',
      }"
    />
    <form @submit.prevent="sendForgotPasswordEmail">
      <ar-input
        v-validate="'required|email'"
        v-model="emailAddress"
        autocomplete="email"
        data-vv-name="emailAddress"
        placeholder="Email address"
        :has-error="veeErrors.has('emailAddress')"
      />
      <ar-state-message
        v-show="veeErrors.has('emailAddress')"
        type="error"
        :text="veeErrors.first('emailAddress')"
        :style="{ marginTop: '8px' }"
      />
      <ar-simple-button
        :loading="isSendingResetPasswordEmail"
        type="gradient-purple"
        shape="pill"
        :text="nextSendResetPasswordEmailRetryCountdown > 0 ? `${nextSendResetPasswordEmailRetryCountdown} seconds remaining` : 'Send Reset Instructions'"
        :disabled="nextSendResetPasswordEmailRetryCountdown > 0"
        :style="{
          marginTop: '40px',
          width: '100%',
        }"
      />
    </form>
  </AuthenticateFrame>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import AuthenticateFrame from './authenticate-frame';

export default {
  name: 'forgot-password',
  layout: 'split',
  components: {
    AuthenticateFrame,
  },

  data() {
    return {
      emailAddress: '',
    };
  },
  computed: {
    ...mapState({
      emailAddressInQueryString: state => state.route.query.emailAddress,
      isSendingResetPasswordEmail: state => state.auth.isSendingResetPasswordEmail,
      storedEmailAddress: state => state.auth.storedEmailAddress,
    }),
    ...mapGetters({
      nextSendResetPasswordEmailRetryCountdown: 'auth/nextSendResetPasswordEmailRetryCountdown',
    }),
  },
  created() {
    // Update layout options
    this['layout/SWITCH_SPLIT_BACKGROUND_IMAGE'](1);

    if (this.emailAddressInQueryString) {
      this.emailAddress = this.emailAddressInQueryString;
    } else if (this.storedEmailAddress) {
      this.emailAddress = this.storedEmailAddress;
      this['auth/SET_STORED_EMAIL_ADDRESS'](null);
    }

    this.$validator.dictionary.merge({
      en: {
        custom: {
          emailAddress: {
            required: 'The email address field is required',
            email: 'Please enter a valid email address',
          },
        },
      },
    });
  },
  methods: {
    ...mapActions([
      'auth/SEND_RESET_PASSWORD_EMAIL',
    ]),
    ...mapMutations([
      'layout/SWITCH_SPLIT_BACKGROUND_IMAGE',
      'auth/SET_STORED_EMAIL_ADDRESS',
    ]),
    goBackToLogin() {
      this.$router.push({
        path: '/authenticate/login',
      })
    },
    async sendForgotPasswordEmail() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }

      this['auth/SEND_RESET_PASSWORD_EMAIL'](this.emailAddress);
    },
  },
};
</script>

<style lang="scss" scoped>
.authenticate-signup {
}
</style>
