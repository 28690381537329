var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"automation-heading-container"},[_c('div',{staticClass:"heading-and-button-wrapper"},[_c('div',{staticClass:"automation-heading-wrapper"},[_c('ar-text',{staticClass:"automation-heading",attrs:{"size":"lg","text":"Automation"}}),_vm._v(" "),_c('AutomationListingHeadingDropdown',{attrs:{"options":_vm.options},model:{value:(_vm.selectedListTypeLocal),callback:function ($$v) {_vm.selectedListTypeLocal=$$v},expression:"selectedListTypeLocal"}})],1),_vm._v(" "),_c('ar-simple-button',{staticClass:"automation-create-button",style:({
        width: '175px',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 18px 8px 15px'
      }),attrs:{"text":"New automation","icon-name":"circle-plus","icon-props":{
        height: '22px'
      }},on:{"click":_vm.handleAutomationCreate}})],1),_vm._v(" "),_c('ar-divider',{staticClass:"automation-listing-divider",style:({
      marginTop: '20px',
      marginBottom: _vm.$arMediaQuery.pageContent.maxWidth('md') ? '7px' : '28px',
      width: '100%',
    })})],1)}
var staticRenderFns = []

export { render, staticRenderFns }