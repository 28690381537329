<template>
  <section class="insights-page-wrapper">
    <!-- <am2-audience-filter-section
      title="Insights"
      disable-review
      @renameFilter="renameFilter"
      @applyFilter="handleFilterApply"
    /> -->
    <am2-unlock-prompt
      v-if="!isInsightsEnabled"
      :title="$arMediaQuery.pageContent.minWidth('sm') ? `Unlock Insights` : `Insights`"
      :subtitle="$arMediaQuery.pageContent.minWidth('sm') ? `Powerful insights about your audience, to help you increase ticket sales. <a href='https://audiencerepublic.com/insights' target='_blank' style='color: #FFF;'>Learn more</a>` : `Powerful insights about your audience. <a href='https://audiencerepublic.com/insights' target='_blank' style='color: #FFF;'>Learn more</a>`"
      :button-text="$arMediaQuery.pageContent.minWidth('sm') ? `Upgrade Plan` : `Upgrade`"
      @click="goToPlans"
      :size="$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'medium'"
      v-ar-sticky-top="{
        priority: 1,
      }"
    />
    <div
      :class="{
        content: true,
        ['insights-page-content']: true,
      }"
    >
      <div :class="[
        'insights-page-top-wrapper',
      ]">
        <div class="insights-page-top-left-section">
          <ar-text
            size="lg"
            text="Insights"
          />
          <am2-segment-dropdown
            :size="$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'large'"
            :style="{
              marginTop: '8px',
            }"
          />
        </div>
        <ar-divider
          :style="{
            marginTop: '29px',
            marginBottom: '20px',
          }"
        />
        <div
          v-if="showFilterButton"
          class="insights-page-filter-button-section"
        >
          <!-- Button to open filter sidebar -->
          <div class="insights-page-circle-hooker">
            <div v-if="numberOfFilterConditions" class="insights-page-circle">
              <span>{{ prunedScratchSegment.filter.conditions.length  }}</span>
            </div>
            <ar-simple-button
              :icon-props="{
                height: '16px',
              }"
              text="Filter"
              icon-name="filter-alt"
              :type="(displaySegmentDrawer || numberOfFilterConditions) ? 'purple' : 'grey'"
              outlined
              class="filter-btn"
              @click="switchFilterSidebar"
            />
          </div>
        </div>
      </div>
      <am2-insights-section
        feature-type="audience-insights"
        insight-type="filter"
        enable-see-all-table-route-change
        class="u-margin-top-5 u-margin-bottom-7"
        @seeAllToggle="handleSeeAllToggle"
      />
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import checkoutPageAccessibility from '@/mixins/checkoutPageAccessibility';
import { clone } from '@/utils/helpers/';

export default {
  name: 'Insights',
  layout: 'default',
  mixins: [
    checkoutPageAccessibility({ featureKeys: ['insights'], featureName: 'Insights', skip: process.env.arEnableActivationState }),
  ],

  data: () => ({
    fetchingInsightsAttempCount: 0,
    insights: null,
    isFetchingInsights: false,
    fetchInsightsError: false,
    seeAllDataKey: null,
    showFilterButton: true,
  }),

  computed: {
    ...mapState({
      displaySegmentDrawer: state => state.layout.displaySegmentDrawer,
    }),
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      prunedScratchSegment: 'segment/prunedScratchSegment',
    }),
    isInsightsEnabled() {
      return this.isFeatureEnabled(['insights']);
    },
    numberOfFilterConditions() {
      if (!this.prunedScratchSegment) return 0;

      return this.prunedScratchSegment.filter.conditions.length;
    },
  },

  beforeDestroy() {
    this.isFetchingInsights = true;
    this.insights = null;
  },

  methods: {
    ...mapMutations([
      'layout/TOGGLE_SEGMENT_DRAWER',
    ]),

    async goToPlans() {
      this.$router.push({
        path: `/settings/subscriptions`,
      });
    },

    handleSeeAllToggle(isOpen) {
      this.showFilterButton = !isOpen;
    },

    switchFilterSidebar() {
      this['layout/TOGGLE_SEGMENT_DRAWER']();
    },
  },
};
</script>


<style lang="scss" scoped>
.insights-page-wrapper {
  .filter-btn {
    height: 40px;
  }
  .insights-page-content {
    transition: 0.3s all;
    margin-left: 0;
    width: 100%;

    .insights-page-top-wrapper {
      margin-top: 50px;

      .insights-page-top-left-section {
        position: relative;
        top: -1px;
        display: flex;
        flex-flow: column;
        width: 100%;
      }


      .insights-page-filter-button-section {
        display: inline-block;

        .insights-page-filter-button {
          width: 40px;
          height: 40px;
          border-radius: 3px;
          background: $skyBlueGrey400;
          border: none;
          cursor: pointer;
          outline: none;
        }

        .insights-page-circle-hooker {
          position: relative;
          .insights-page-circle {
            position: absolute;
            top: 0;
            right: 0;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 15px;
            height: 15px;
            border-radius: 7.5px;
            background: $purple500;
            transform: translateX(50%) translateY(-50%);
            color: white;
            z-index: $zIndexHigh;

            span {
              position: relative;
              font-size: 10px;
              line-height: 10px;
              top: -1px;
            }
          }
        }
      }
    }
  }
}
</style>
