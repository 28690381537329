import { clone, generateHash } from '@/utils/helpers';
import { SignupFormCampaign, ScratchSignupFormCampaign } from '@/types/resources/signupFormCampaign';
import { MessageList } from '@/api/message-lists/types';

export const initializeScratchSignupForm = function (): ScratchSignupFormCampaign {
  return { // It's 100% same as the real campaign object returned from backend, we have some attributes that just to make front-end's life easier
    name: 'Subscribe for updates',
    promoterOid: null,
    startDate: null,
    endDate: null,
    urlSlug: '',
    messageListOid: null,
    type: 'opt-in',
    defaults: {},
    presentation: {
      noImageBackground: false,
      successMessage: null,
      description: null,
      externalVideoUrl: null,
      externalVideoMediaType: null,
      color: '#7344c0',
      headHtml: null,
      bodyHtml: null,
      metaTitle: null,
      metaDescription: null,
    },
    settings: {
      customCta: null,
      privacyPolicy: {
        companyName: null,
        url: null,
      },
      defaultLanguage: null,
      subscriptionPreferenceSettings: {
        enabled: false,
        disclaimer: null,
      },
    },
    resourceOids: [],
    resources: [],
    registrations: {
      providers: {
        email: true,
        spotify: false,
        facebook: false
      },
      fields: {
        emailAddress: true,
        firstName: true,
        lastName: true,
        mobileNumber: true,
        messenger: true,
        dob: false,
        postcode: false,
        city: false,
        state: false,
        country: false,
      },
      requiredFields: {
        emailAddress: true,
        firstName: true,
        lastName: true,
        mobileNumber: true,
        messenger: false,
        dob: false,
        postcode: false,
        city: false,
        state: false,
        country: false,
      },
    },
    socialActions: {
      shares: {
        twitter: false,
        facebook: false,
        linkedin: false
      },
      invites: {
        sms: false,
        copy: false,
        email: false,
        whatsapp: false,
        instagram: false,
        messenger: false
      },
      connects: {
        spotify: false,
        twitter: false
      },
      socialAccounts: [
        {
          version: undefined,
          key: generateHash(),
          sms: 'enable',
          email: 'enable',
        },
      ],
    },
  }
};

/**
 * Help you fill some possibly missing fields in ScratchSignupFormCampaign,
 * so you don't need to worry too much about them.
 * @param signupForm 
 */
export const fillScratchSignupForm = function (signupForm: ScratchSignupFormCampaign) {
  const scratchSignupForm: ScratchSignupFormCampaign = clone(signupForm);
  if (!scratchSignupForm.settings.subscriptionPreferenceSettings) {
    scratchSignupForm.settings.subscriptionPreferenceSettings = {
      enabled: false,
      disclaimer: null,
    }
  }
  if (!scratchSignupForm.settings.privacyPolicy) {
    scratchSignupForm.settings.privacyPolicy = {
      companyName: null,
      url: null,
    }
  }
  if (scratchSignupForm.socialActions?.socialAccounts?.[0] && !scratchSignupForm.socialActions.socialAccounts[0].key) {
    scratchSignupForm.socialActions.socialAccounts[0].key = generateHash();
  }
  return scratchSignupForm;
}

export const patchOptinActionInScratchSignup = function (
  scratchSignupForm: ScratchSignupFormCampaign,
  optinType: 'sms' | 'email' | 'facebook:messenger',
  value: any,
): ScratchSignupFormCampaign | null {
  const newScratchSignupForm: ScratchSignupFormCampaign = clone(scratchSignupForm);
  if (!newScratchSignupForm?.socialActions?.socialAccounts || !newScratchSignupForm.registrations.requiredFields) {
    return null;
  }
  if (value) {
    newScratchSignupForm.socialActions.socialAccounts[0][optinType] = value;
  } else {
    // Delete social action if not value 
    delete newScratchSignupForm.socialActions.socialAccounts[0][optinType];
  }
  // Has to make mobileNumber mandatory if SMS is enabled
  if (optinType === 'sms' && value === 'enable') {
    newScratchSignupForm.registrations.fields.mobileNumber = true;
    newScratchSignupForm.registrations.requiredFields.mobileNumber = true;
  }
  // Has to make emailAddress mandatory if Email Address is enabled
  if (optinType === 'email' && value === 'enable') {
    newScratchSignupForm.registrations.fields.emailAddress = true;
    newScratchSignupForm.registrations.requiredFields.emailAddress = true;
  }
  if (optinType === 'facebook:messenger') {
    // @ts-ignore TODO - just remove this
    newScratchSignupForm.registrations.fields.messenger = value ? value : null;
  }
  return newScratchSignupForm;
};

export const patchMessageListInScratchSignupForm = function (
  scratchSignupForm: ScratchSignupFormCampaign,
  messageList: MessageList,
) {
  const newScratchSignupForm: ScratchSignupFormCampaign = clone(scratchSignupForm);
  if (!newScratchSignupForm.socialActions?.socialAccounts?.[0]) {
    return;
  }
  newScratchSignupForm.messageListOid = messageList.oid;

  // You can optin fans to SMS & Email without any requisite
  // Email - Skip
  const emailEnabled = messageList.email;
  if (!emailEnabled) {
    delete newScratchSignupForm.socialActions.socialAccounts[0].email;
  }
  // SMS - Skip
  const smsEnabled = messageList.sms;
  if (!smsEnabled) {
    delete newScratchSignupForm.socialActions.socialAccounts[0].sms;
  }

  // Facebook Messenger
  const fbPageId = messageList.facebookMessenger && messageList.meta.facebookMessenger && messageList.meta.facebookMessenger.pageId;
  if (fbPageId) {
    /**
     * Backward compatible
     * If you did enable messenger before by using "registrations -> fields -> messenger",
     * we enable 'facebook:messenger' action for you
     */
    // @ts-ignore TODO - just remove this
    if (newScratchSignupForm.registrations.fields.messenger) {
      newScratchSignupForm.socialActions.socialAccounts[0]['facebook:messenger'] = fbPageId;
    }
  } else {
    // Disable Messenger anyway if there's no fbPageId
    delete newScratchSignupForm.socialActions.socialAccounts[0]['facebook:messenger'];
    // @ts-ignore TODO - just remove this
    newScratchSignupForm.registrations.fields.messenger = false;
  }
  return newScratchSignupForm;
};
