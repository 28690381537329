<template>
  <section
    :class="[
      'wrapper',
      'content',
      'content--centered',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
    ]">
    <am2-step-link
      class="step-back-link"
      text="Back to integrations"
      @click="handleBackLinkClick" />
    <div
      :class="[
        'title-section',
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      ]">
      <div class="title-section-left">
        <am2-icon-title-section
          title="Stripe"
          description="Automatically sync your customers and transactions from Stripe"
          :icon-props="{
            name: 'stripe',
            color: $arStyle.color.stripe,
            height: '46px',
            width: '40px',
          }"
        />
      </div>
      <div class="title-section-right" v-if="!isFetchingStripeIntegration && integrationsSummary">
        <am2-integration-account-select
          v-if="!isFetchingStripeIntegration && stripeIntegration && currentIntegration"
          :default-selected-integration-oid="currentIntegration.oid"
          :available-integrations="integrationsSummary"
          @select="handleIntegrationChange"
          @new-account="handleAddNewIntegration"
          :style="{ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }"
        />
        <am2-expand-button-dropdown
          align="left"
          :button-props="{ iconName: 'settings' }"
          :items="[
            {
              name: 'Open Stripe',
              value: 'goToStripeSite',
            },
            {
              name: 'Reconnect Integration',
              value: 'reconnectIntegration',
            },
            {
              name: 'Remove Account',
              value: 'removeIntegration',
            },
          ]"
          @select="handleSettingOptionSelect" />
      </div>
    </div>


    <section v-if="isAddingStripeIntegration || isFetchingStripeIntegration" class="integration-loading-container">
      <am2-loading-bubble />
    </section>
    <section v-else>
      <section v-if="integrationFailed" class="integration-failed-message">
        <ar-snackbar
          type="warning"
          message="Your Stripe connection is no longer valid. This means we can't sync your member data. <a>Please reconnect your account</a>"
          @anchorClick="handleReconnectIntegration"
          :style="{ width: '100%' }"
        />
      </section>

      <section v-else>
        <am2-card-container
          class="sync-block-container"
        >
          <div v-if="latestTaskStatus === 'in-progress'" class="sync-block">
            <am2-loading-bubble
              class="sync-animation u-margin-bottom-5"
            />
            <div class="text-block">
              <am2-heading
                class="header"
                :size="$arMediaQuery.pageContent.minWidth('sm') ? 'lg' : 'md'"
                type="h2"
                :title="latestTaskStatus === 'in-progress' ? `Syncing orders from Stripe` : 'Your Stripe account is currently connected'"/>
              <div class="button-block">
                <ar-simple-button
                  text="Stop sync"
                  outlined
                  class="button sync-btn u-margin-top-3"
                  @click="handleStopSync" />
              </div>
            </div>
          </div>
          <div v-else class="sync-block">
            <div class="tick-container">
              <ar-icon
                name="check"
                class="tick-icon"
                width="18"
                height="17"
              />
            </div>
            <div class="text-block u-margin-top-8">
              <am2-heading
                class="header"
                size="md"
                type="h2"
                :title="latestTaskStatus === 'in-progress' ? `Syncing orders from Stripe` : 'Your Stripe account is currently connected'"/>
              <div class="button-block u-margin-top-9">
                <ar-simple-button
                  text="Sync now"
                  class="button sync-btn"
                  :loading="latestTaskStatus === 'in-progress'"
                  @click="handleStartSync" />
              </div>
              <div v-if="hasSyncedBefore" class="last-sync-time">
                <ar-text
                  :text="`Last synced ${timeSinceLastSync}`"
                  size="xxs"
                  :style="{
                    color: '#8E97A7',
                  }"
                />
              </div>
            </div>
          </div>
        </am2-card-container>
      </section>
    </section>

  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: 'StripeIntegration',
  layout: 'default',

  data: () => ({
    currentlySelectedIntegrationIndex: 0,
    syncStatusPoller: null,
  }),

  computed: {
    ...mapState({
      stripeIntegration: state => state.stripeIntegration.integration,
      isFetchingStripeIntegration: state => state.stripeIntegration.isFetchingIntegration,
      hasFetchedStripeIntegration: state => state.stripeIntegration.hasFetchedIntegration,
      isAddingStripeIntegration: state => state.stripeIntegration.isAddingIntegration,
      isDeletingStripeIntegration: state => state.stripeIntegration.isDeletingIntegration,
      isReconnectingStripeIntegration: state => state.stripeIntegration.isReconnectingIntegration,
      isFetchingTasks: state => state.stripeIntegration.isFetchingTasks,
      tasks: state => state.stripeIntegration.tasks,
      latestTaskStatus: state => state.stripeIntegration.latestTaskStatus,
    }),

    stripeIntegrationEnabled() {
      return process.env.arEnableStripeIntegration;
    },

    latestTask() {
      if (!this.tasks || this.tasks.length < 1) return null;
      return this.tasks[0];
    },

    integrationsSummary() {
      if (!this.stripeIntegration) return null;
      return this.stripeIntegration
        .filter(i => i.status !== 'failed')
        .map(i => ({
          oid: i.oid,
          name: i.integration?.user?.settings?.dashboard?.displayName,
          email: i.integration?.user?.settings?.dashboard?.displayName,
          imageSrc: null,
        }));
    },

    currentIntegration() {
      if (this.currentlySelectedIntegrationIndex === null) return null;
      if (!this.stripeIntegration || this.stripeIntegration.length < 1 || !this.stripeIntegration[this.currentlySelectedIntegrationIndex]) return null;
      return this.stripeIntegration[this.currentlySelectedIntegrationIndex];
    },

    integrationFailed() {
      if (!this.currentIntegration) return false;
      return this.currentIntegration.status === 'failed';
    },

    blockTitle() {
      return this.isSyncing ? `Syncing X of Y members from Stripe` : 'Your Stripe account is currently connected';
    },

    isSyncing() {
      if (!this.currentIntegration || !this.hasExistingTask) {
        return false;
      }
      return this.latestTaskStatus === 'in-progress';
    },

    hasSyncedBefore() {
      return (
        this.latestTask && this.latestTask.status === 'completed'
      );
    },

    timeSinceLastSync() {
      let sinceNow = "";

      if (this.hasSyncedBefore) {
        sinceNow = moment.utc(
          this.latestTask.sysMtime
        ).fromNow();
      }

      return sinceNow;
    }
  },

  async mounted() {
    if (!this.stripeIntegrationEnabled) {
      this.handleBackLinkClick();
    }
    this.fetchInitialData();
  },

  beforeDestroy() {
    this['stripeIntegration/RESET_INTEGRATION']();

    if (this.syncStatusPoller) {
      clearInterval(this.syncStatusPoller);
    }
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'stripeIntegration/FETCH_INTEGRATION',
      'stripeIntegration/ADD_INTEGRATION',
      'stripeIntegration/DELETE_INTEGRATION',
      'stripeIntegration/RECONNECT_INTEGRATION',
      'stripeIntegration/SYNC_START',
      'stripeIntegration/SYNC_STOP',
      'stripeIntegration/FETCH_TASKS',
      'CONNECT_TO_INTEGRATION',
    ]),
    ...mapMutations([
      'stripeIntegration/RESET_INTEGRATION',
    ]),
    async fetchInitialData() {
      if (this.isFetchingStripeIntegration || this.hasFetchedIntegration) return;
      await this['stripeIntegration/FETCH_INTEGRATION']();

      if (this.currentIntegration?.oid && !this.isFetchingTasks) {
        this['stripeIntegration/FETCH_TASKS']({
          oid: this.currentIntegration.oid,
          orderby: "sysMtime desc",
          filter: {
            name: "event-data-synch",
            promoterIntegrationOid: this.currentIntegration.oid,
          }
        });
      }
    },

    async updateData() {
      await this['stripeIntegration/FETCH_TASKS']({
        orderby: "sysMtime desc",
        top: 1,
        oid: this.currentIntegration.oid,
      });

      if (this.currentIntegration && this.hasExistingTask && this.latestTaskStatus !== "in-progress") {
        window.clearInterval(this.syncStatusPoller);
        this.syncStatusPoller = null;
      }
    },

    hasExistingTask() {
      return this.latestTask !== null;
    },

    handleBackLinkClick() {
      this.$router.push({ path: '/settings/integrations' });
    },

    handleIntegrationChange(integration, index) {
      this.currentlySelectedIntegrationIndex = index;
    },

    async handleAddNewIntegration() {
      this.isConnecting = true;

      try {
        const res = await this.CONNECT_TO_INTEGRATION({
          provider: 'stripe',
          app: 'stripe'
        });
        this.$router.push({
          path: `/settings/integrations/stripe`,
        });
        this.isConnecting = false;
        this.fetchIntegration('stripe', 'stripe'); // TODO - Once we integrate the connect methods into the modules, we wont need to do this
      } catch (e) {
        // If it's closed by user, then it's all good
        if (e.name === 'USER_CLOSE') {
          return;
        }

        this.$arNotification.push({ type: 'error', message: `Failed to connect to Stripe: ${e.message}` });
        throw e;
      } finally {
        this.isConnecting = false;
      }
    },

    handleReconnectIntegration() {
      this['stripeIntegration/RECONNECT_INTEGRATION']({oid: this.currentIntegration.oid});
    },

    async handleStartSync() {
      this['stripeIntegration/SYNC_START']({oid: this.currentIntegration.oid});
      this.syncStatusPoller = setInterval(this.updateData, 3000);
    },

    handleStopSync() {
      this['stripeIntegration/SYNC_STOP']({oid: this.currentIntegration.oid});
      window.clearInterval(this.syncStatusPoller);
      this.syncStatusPoller = null;
    },

    async handleSettingOptionSelect(item) {
      if (item.value === 'removeIntegration') {
        const answer = await this.SHOW_CONFIRM({
          messageHtml: 'Removing the Stripe integration will stop syncing your orders and customer data from Stripe.',
          confirmButtonText: 'Remove integration',
          iconName: 'alert-question-mark',
          title: 'Are you sure?',
        });
        if (answer) {
          try {
            await this['stripeIntegration/DELETE_INTEGRATION']({oid: this.currentIntegration.oid});
            if (this.stripeIntegration.length > 1) {
              this.currentlySelectedIntegrationIndex = 0;
            } else {
              this.$router.push({ path: '/settings/integrations' });
            }
          } catch (e) {
            this.$arNotification.push({ type: 'error', message: 'Failed to remove integration' });
          }
        }
      } else if (item.value === 'reconnectIntegration') {
        this.handleReconnectIntegration();
      } else if (item.value === 'goToStripeSite') {
        if (!this.currentIntegration) return;
        var win = window.open('https://dashboard.stripe.com/', '_blank');
        win.focus();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.wrapper {

  &.sm-max {
    padding: 0 24px;
  }
  &.xs-max {
    padding: 0 12px;
  }


  .step-back-link {
    margin-top: 48px;
  }

  .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;

    .title-section-left {
      display: inline-flex;
      align-items: flex-start;

      .tag {
        position: relative;
        margin-left: 10px;
        top: 4px;
      }
    }

    .title-section-right {
      display: inline-flex;
      align-items: center;

      .select {
        width: 300px;
        margin-right: 10px;
      }
    }

    &.md-max {
      flex-direction: column;
      align-items: flex-start;

      .title-section-right {
        margin-top:16px;
      }
    }
  }


  .sync-block-container {
    background-color: #FFF;
    margin-top:64px;

    .sync-block {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 85px;
      padding-bottom: 70px;
      flex-direction: column;

      .tick-container {
        height: 44px;
        width: 44px;
        border-radius: 50%;
        background-color: $green500;
        display: flex;
        justify-content: center;
        align-items: center;
        .tick-icon {
          color: #FFF;
        }
      }

      .sync-animation {
        color: $green500;
      }

      .button-block {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding: 0 12px;

        .button {
          margin-top: 10px;
          width: 300px;
          max-width:100%;
        }
      }

      .last-sync-time {
        margin-top: 12px;
        font-size: 14px;
        color: #8E97A7;
        line-height: 25px;
      }

      .text-block {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 100%;

        .header {
          color: $blueGrey800;
          margin-bottom: 3px;
        }
      }
    }
  }
}
</style>
