<template>
  <section>
    <am2-message-sender-modal
      :is-show="fieldBeingEdited === 'message-sender-address'"
      :message-sender="currentMessageSender"
      :enable-delete="false"
      mode="edit"
      auto-focus-on="business-address"
      @update="fieldBeingEdited = null"
      @cancel="fieldBeingEdited = null"
    />
    <am2-heading
      type="h1"
      size="md"
      title="Summary"
    />
    <!-- Summary Card -->
    <am2-card-container class="u-padding-x-7 u-padding-y-8 u-margin-top-8">
      <!-- Subject Field -->
      <ar-field
        class="u-margin-bottom-3"
        label="Subject"
        slot-margin="6px"
        :error-messages="[veeErrors.first('subject')]"
      >
        <!-- Used for validting value, not visible to users -->
        <ar-input
          v-show="false"
          :value="scratchEmailMessage.meta.messageBody.subject"
          v-validate="'required'"
          data-vv-name="subject"
        />
        <!-- Editing Mode -->
        <div
          v-show="displaySubjectField"
          class="u-display-flex u-align-items-center"
          :style="{
            height: '36px',
          }"
        >
          <ar-input
            ref="subject-input"
            v-model="scratchSubject"
            @enter="handleSubjectSave"
            autocomplete="subject"
            :style="{
              height: '100%',
            }"
          />
          <ar-simple-button
            class="u-margin-left-2"
            text="Save"
            :disabled="!scratchSubject"
            :style="{
              width: '78px',
              height: '100%',
            }"
            @click="handleSubjectSave"
          />
        </div>
        <!-- Readonly Mode -->
        <div
          v-show="!displaySubjectField"
          :style="{
            height: '36px',
          }"
        >
          <div class="u-display-flex u-align-items-center">
            <ar-text
              class="u-flex-grow-1"
              size="xs"
              :text="scratchEmailMessage.meta.messageBody.subject"
              multiple-lines
            />
            <ar-link-button
              class="u-margin-left-2"
              text="edit"
              @click="handleEditSubjectClick"
            />
          </div>
        </div>
      </ar-field>

      <ar-divider />

      <!-- Preview Text -->
      <ar-field
        class="u-margin-top-8 u-margin-bottom-3"
        label="Preview text"
        slot-margin="6px"
      >
        <!-- Editing Mode -->
        <div
          v-show="displayPreviewTextField"
          class="u-display-flex u-align-items-center"
          :style="{
            height: '36px',
          }"
        >
          <ar-input
            ref="preview-text-input"
            v-model="scratchPreviewText"
            autocomplete="previewText"
            :style="{
              height: '100%',
            }"
            @enter="handlePreviewTextSave"
          />
          <ar-simple-button
            class="u-margin-left-2"
            text="Save"
            :style="{
              width: '78px',
              height: '100%',
            }"
            @click="handlePreviewTextSave"
          />
        </div>
        <!-- Readonly Mode -->
        <div
          v-show="!displayPreviewTextField"
          :style="{
            height: '36px',
          }"
        >
          <div class="u-display-flex u-align-items-center">
            <ar-text
              class="u-flex-grow-1"
              size="xs"
              :text="scratchEmailMessage.meta.messageBody.previewText"
              multiple-lines
            />
            <ar-link-button
              class="u-margin-left-2"
              text="edit"
              @click="handleEditPreviewTextClick"
            />
          </div>
        </div>
      </ar-field>

      <ar-divider />

      <!-- Message List -->
      <ar-field
        class="u-margin-top-8 u-margin-bottom-3"
        label="To"
        slot-margin="6px"
        :error-messages="[veeErrors.first('messageListoid')]"
      >
        <!-- Used for validting value, not visible to users -->
        <ar-input
          v-show="false"
          :value="scratchEmailMessage.meta.messageListOid"
          v-validate="'required'"
          data-vv-name="messageListoid"
          data-vv-as="message list"
        />
        <!-- Editing Mode -->
        <div
          v-show="displayMessageListField"
          class="u-display-flex u-align-items-center"
          :style="{
            height: '50px',
          }"
        >
          <am2-message-list-select
            ref="message-list-select"
            class="u-flex-grow-1"
            :channels="['email']"
            :value="scratchEmailMessage.meta.messageListOid"
            :campaign-oid="scratchEmailMessageCampaignOid"
            :event-oid="scratchEmailMessageEventOid"
            :style="{
              height: '100%',
            }"
            @select="handleMessageListSelect"
            @close="handleMessageListSelectClose"
          />
        </div>
        <!-- Readonly Mode -->
        <div
          v-show="!displayMessageListField"
          :style="{
            height: hasAudienceFilter ? '68px' : '36px',
          }"
        >
          <div class="u-display-flex u-align-items-center">
            <ar-text
              class="u-flex-grow-1"
              size="xs"
              :text="messageListPreviewCopy"
              multiple-lines
              allow-html
            />
            <ar-link-button
              v-if="!hasAudienceFilter"
              class="u-margin-left-2"
              text="edit"
              @click="handleEditMessageListClick"
            />
          </div>
          <div class="u-display-flex u-align-items-center u-margin-top-1">
            <ar-text
              class="u-flex-grow-1"
              size="xs"
              :text="messageFilterCopy"
              multiple-lines
              allow-html
            />
            <ar-link-button
              v-if="hasAudienceFilter"
              class="u-margin-left-2"
              text="edit"
              @click="handleEditFilteredMessageListClick"
            />
          </div>
        </div>
      </ar-field>
      <ar-snackbar
        v-if="currentMessageListHasNoEmailContacts"
        class="u-width-100-percent u-margin-bottom-4"
        type="error"
        message="There are no valid email addresses in this list."
      />
      <ar-snackbar
        v-if="currentSelectedMessage && currentMessageListIsForAllCampaignRegistrants"
        class="u-width-100-percent u-margin-bottom-4"
        type="warning"
        message="Changing from all registrants to a tier will disable scheduling."
      />
      <ar-snackbar
        v-if="currentCampaignIsActive && currentMessageListIsForCampaignTier"
        class="u-width-100-percent u-margin-bottom-4"
        type="warning"
        message="Note: It's recommended to only message individual tiers once the campaign is ended."
      />

      <ar-divider />

      <!-- Message Sender -->
      <ar-field
        class="u-margin-top-8 u-margin-bottom-3"
        label="From"
        slot-margin="6px"
        :error-messages="[veeErrors.first('messageSender')]"
      >
        <!-- Used for validting value, not visible to users -->
        <ar-input
          v-show="false"
          :value="scratchEmailMessage.meta.email && scratchEmailMessage.meta.email.promoterPropertyOid"
          v-validate="'required'"
          data-vv-name="messageSender"
          data-vv-as="message sender"
        />
        <!-- Editing Mode -->
        <div
          v-show="displayMessageSenderField"
          class="u-display-flex u-align-items-center"
          :style="{
            height: '50px',
          }"
        >
          <am2-message-sender-select
            class="u-flex-grow-1"
            ref="message-sender-select"
            :value="scratchEmailMessage.meta.email && scratchEmailMessage.meta.email.promoterPropertyOid"
            :style="{
              height: '100%',
            }"
            @select="handleMessageSenderSelect"
            @clear="handleMessageSenderClear"
            @close="handleMessageSenderClose"
          />
        </div>
        <!-- Readonly Mode -->
        <div
          v-show="!displayMessageSenderField"
          :style="{
            height: '36px',
          }"
        >
          <div class="u-display-flex u-align-items-center">
            <ar-text
              class="u-flex-grow-1"
              size="xs"
              :text="messageSenderSummary.from"
              multiple-lines
              allow-html
            />
            <ar-link-button
              class="u-margin-left-2"
              text="edit"
              @click="handleEditMessageSenderClick"
            />
          </div>
        </div>
      </ar-field>
      <ar-snackbar
        v-if="currentMessageSenderNotVerified"
        class="u-width-100-percent u-margin-bottom-4"
        type="error"
        message="Please verify this email address. <a>Resend verification email</a>."
        @anchorClick="handleResendVerificationEmailAnchorClick"
      />

      <ar-divider
        v-if="scratchEmailMessage.meta.email.promoterPropertyOid"
      />

      <!-- Message Sender: Address -->
      <!-- Only display this when message sender is selected -->
      <ar-field
        v-if="scratchEmailMessage.meta.email.promoterPropertyOid"
        class="u-margin-top-8"
        label="Address"
        slot-margin="6px"
      >
        <div class="u-margin-top-3">
          <!-- Readonly Mode -->
          <div
            :style="{
              height: '36px',
            }"
          >
            <div class="u-display-flex u-align-items-center">
              <ar-text
                class="u-flex-grow-1"
                size="xs"
                :text="messageSenderSummary.address"
                multiple-lines
                allow-html
              />
              <ar-link-button
                class="u-margin-left-2"
                text="edit"
                @click="handleEditMessageSenderAddressClick"
              />
            </div>
          </div>
        </div>
      </ar-field>
    </am2-card-container>

    <!-- Link Tracking Card -->
    <am2-card-container
      class="u-display-flex u-align-items-center u-justify-content-space-between u-padding-y-6 u-padding-x-7 u-margin-top-4"
    >
      <div class="u-display-flex u-align-items-center">
        <ar-text
          size="xs"
          weight="bold"
          text="Link Click Tracking"
        />
        <ar-icon
          name="tooltip-question-mark"
          class="u-margin-left-2"
          :color="$arStyle.color.skyBlueGrey600"
          v-tooltip.top="{
            content: 'When link click tracking is turned off your message will be delivered faster, but you won\'t be able to track clicks for this message.',
          }"
        />
      </div>
      <am2-switch
        :value="!!!scratchEmailMessage.meta.disableLinkTracking"
        @input="handleLinkTrackingToggle"
      />
    </am2-card-container>

    <!-- Schedule Card -->
    <am2-card-container class="u-padding-y-6 u-padding-x-7 u-margin-y-4">
      <div class="u-display-flex u-align-items-center u-justify-content-space-between">
        <div>
          <ar-text
            size="xs"
            weight="bold"
            text="Schedule Message"
          />
        </div>
        <am2-switch
          :value="enableSchedule"
          @input="handleEnableScheduleToggle"
          :disabled="currentSelectedMessageUiStatus === 'scheduled' || !currentMessageListCanBeSchedued"
          v-tooltip.top="{
            content: !currentMessageListCanBeSchedued && !enableSchedule ? 'You cannot schedule messages from campaigns' : null,
          }"
        />
      </div>
      <div
        v-if="enableSchedule"
        class="u-margin-top-6"
        :style="{
          display: 'flex',
          alignItems: 'baseline',
        }"
      >
        <div class="u-flex-grow-1">
          <am2-date-time-input
            :value="scratchEmailMessage.scheduledAt"
            @input="handleScheduledAtInput"
            :time-zone="scratchEmailMessage.meta.presentation.timeZone"
            has-label
            v-validate="'required|tzFormat|afterNow|after5MinFromNow'"
            data-vv-name="scheduledAt"
            data-vv-as="scheduled time"
            :style="{
              width: '100%',
            }"
          />
          <ar-state-message
            v-if="veeErrors.has('scheduledAt')"
            :style="{ marginTop: '8px' }"
            type="error"
            :text="veeErrors.first('scheduledAt')"
          />
        </div>
        <ar-divider
          :style="{
            position: 'relative',
            top: '38px',
            width: '20px',
            flexShrink: '0',
            margin: '0 10px',
          }"
        />
        <div
          :style="{
            width: '205px',
            flexShrink: '0',
          }"
        >
          <ar-text
            class="u-margin-bottom-3"
            size="xs"
            text="Timezone"
            :style="{
              color: $arStyle.color.blueGrey700,
            }"
          />
          <am2-timezone-select
            :value="scratchEmailMessage.meta.presentation.timeZone"
            @input="handleTimezoneInput"
            v-validate="'required'"
            data-vv-name="timeZone"
            data-vv-as="timezone"
          />
          <ar-state-message
            v-if="veeErrors.has('timeZone')"
            :style="{ marginTop: '8px' }"
            type="error"
            :text="veeErrors.first('timeZone')"
          />
        </div>
      </div>
    </am2-card-container>
    <!-- Disable Default Footer -->
    <am2-card-container
      v-if="hasDefaultDynamicTags"
      class="u-display-flex u-align-items-center u-justify-content-space-between u-padding-y-6 u-padding-x-7 u-margin-top-4"
    >
      <div class="u-display-flex u-align-items-center">
        <ar-text
          size="xs"
          weight="bold"
          text="Default Email Footer"
        />
      </div>
      <am2-switch
        :value="!scratchEmailMessage.meta.presentation.hideDefaultEmailFooter"
        @input="handleHideDefaultEmailFooterToggle"
      />
    </am2-card-container>
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import moment from 'moment';
import accounting from 'accounting';

export default {
  name: 'Schedule',

  data() {
    return {
      fieldBeingEdited: null,
      scratchSubject: null,
      scratchPreviewText: null,
      enableSchedule: false,
    };
  },

  computed: {
    ...mapState({
      currentCampaign: state => state.campaign.currentCampaign,
      scratchEmailMessage: state => state.message.scratchEmailMessage,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      currentMessageSender: state => state.messageSender.currentMessageSender,
      currentMessageList: state => state.messageList.currentMessageList,
      advancedMessageListTargeting: state => state.messageList.advancedMessageListTargeting,
      filteredRecipientListCount: state => state.messageList.filteredRecipientListCount,
    }),
    ...mapGetters({
      hasDefaultDynamicTags: 'message/hasDefaultDynamicTags',
      scratchEmailMessageCampaignOid: 'message/scratchEmailMessageCampaignOid',
      scratchEmailMessageEventOid: 'message/scratchEmailMessageEventOid',
      currentCampaignIsActive: 'campaign/currentCampaignIsActive',
      currentSelectedMessageUiStatus: 'message/currentSelectedMessageUiStatus',
      currentMessageListHasNoEmailContacts: 'messageList/currentMessageListHasNoEmailContacts',
      currentMessageSenderNotVerified: 'messageSender/currentMessageSenderNotVerified',
      currentMessageListCanBeSchedued: 'messageList/currentMessageListCanBeSchedued',
      currentMessageListIsForAllCampaignRegistrants: 'messageList/currentMessageListIsForAllCampaignRegistrants',
      currentMessageListIsForCampaignTier: 'messageList/currentMessageListIsForCampaignTier',
      getCurrentFilterExpression: 'message/getCurrentFilterExpression',
    }),
    messageListPreviewCopy() {
      let messageListString = this.currentMessageList?.meta?.name || this.currentMessageList?.name;
      if (!this.hasAudienceFilter) {
        messageListString = `${messageListString} <span style="color: ${this.$arStyle.color.skyBlueGrey700}">(${accounting.formatNumber(this.recipientsCount)} recipient${this.recipientsCount === 1 ? '' : 's'})</span>`;
      }
      return messageListString;
    },
    messageFilterCopy() {
      if (!this.hasAudienceFilter) return ``;
      return `Filtered <span style="color: ${this.$arStyle.color.skyBlueGrey700}">(${this.filteredRecipientListCount} recipient${this.filteredRecipientListCount === 1 ? '' : 's'})</span>`;
    },
    hasAudienceFilter() {
      return !!this.getCurrentFilterExpression;
    },
    messageSenderSummary() {
      return {
        from: `${this.currentMessageSender?.additionalInfo?.senderName || ''} <span style="color: ${this.$arStyle.color.skyBlueGrey700}">(${this.currentMessageSender?.property})</span>`,
        address: `${this.currentMessageSender?.additionalInfo?.businessAddress || ''}`,
      }
    },
    recipientsCount() {
      if (!this.currentMessageList) {
        return 0;
      }
      if (!this.currentMessageList.statsSnapshot) {
        return 0;
      }
      if (this.campaignOid) {
        return this.currentMessageList.statsSnapshot.total;
      }
      return this.currentMessageList.statsSnapshot.email ? this.currentMessageList.statsSnapshot.email : 0;
    },

    campaignOid() {
      return this.scratchEmailMessage.meta?.initiator?.campaignOid;
    },

    displaySubjectField() {
      return this.fieldBeingEdited === 'subject' || !this.scratchEmailMessage.meta.messageBody.subject;
    },

    displayPreviewTextField() {
      return this.fieldBeingEdited === 'preview-text';
    },

    displayMessageListField() {
      return this.fieldBeingEdited === 'message-list' || !this.scratchEmailMessage.meta.messageListOid;
    },

    displayMessageSenderField() {
      return this.fieldBeingEdited === 'message-sender' || !this.scratchEmailMessage.meta.email.promoterPropertyOid;
    },
  },

  watch: {
    scratchEmailMessage: {
      handler(val) {
        if (val.meta.presentation.timeZone || val.scheduledAt) {
          this.enableSchedule = true;
        }
      },
      immediate: true,
    },
  },

  created() {
    this.$validator.extend('afterNow', {
      getMessage: field => `Scheduled time is invalid - time must be in the future`,
      validate: value => {
        return moment(value).isAfter(new Date());
      },
    });
    this.$validator.extend('after5MinFromNow', {
      getMessage: field => `Scheduled time is invalid - must be more than 5 minutes from now`,
      validate: value => {
        return moment(value).isAfter(moment().add(5, 'minutes'));
      },
    });
    this.$validator.localize('en', {
      custom: {
        scheduledAt: {
          required: () => `Please enter scheduled time`,
        },
        messageListoid: {
          required: () => 'Please select a list',
        },
      },
    });
  },

  methods: {
    ...mapActions([
      'messageSender/RESEND_EMAIL_VERIFICATION',
    ]),
    ...mapMutations([
      'message/PATCH_SCRATCH_EMAIL_MESSAGE',
      'message/REMOVE_DYNAMIC_TAGS_IN_SCRATCH_EMAIL_MESSAGE',
      'messageList/SET_CURRENT_MESSAGE_LIST',
      'messageSender/SET_CURRENT_MESSAGE_SENDER',
      'messageSender/RESET_CURRENT_MESSAGE_SENDER',
    ]),
    // Manually hide fields
    hideFields() {
      this.fieldBeingEdited = null;
    },
    clearScheduleTime() {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          presentation: {
            timeZone: null,
          },
        },
        scheduledAt: null,
      });
    },
    // Subject Field
    handleEditSubjectClick() {
      this.fieldBeingEdited = 'subject';
      this.scratchSubject = this.scratchEmailMessage.meta.messageBody.subject;

      this.$nextTick(() => {
        this.$refs['subject-input'].focus();
      });
    },
    handleSubjectSave() {
      if (!this.scratchSubject) {
        this.$refs['subject-input'].focus();
        return;
      }
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          messageBody: {
            subject: this.scratchSubject,
          },
        },
        name: this.scratchSubject,
      });
      this.fieldBeingEdited = null;
      this.scratchSubject = null;
    },
    // Preview Text Field
    handleEditPreviewTextClick() {
      this.fieldBeingEdited = 'preview-text';
      this.scratchPreviewText = this.scratchEmailMessage.meta.messageBody.previewText;

      this.$nextTick(() => {
        this.$refs['preview-text-input'].focus();
      });
    },
    handlePreviewTextSave() {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          messageBody: {
            previewText: this.scratchPreviewText,
          },
        },
      });
      this.fieldBeingEdited = null;
      this.scratchPreviewText = null;
    },
    // Message List Field
    handleEditMessageListClick() {
      this.fieldBeingEdited = 'message-list';

      this.$nextTick(() => {
        this.$refs['message-list-select'].openDropdown();
      });
    },
    handleEditFilteredMessageListClick() {
      this.$router.push({
        path: `basic`,
        query: this.query,
      });
    },
    handleMessageListSelectClose() {
      // No value, keep editing
      if (!this.scratchEmailMessage.meta.messageListOid) {
        return;
      }
      this.fieldBeingEdited = null;
    },
    handleMessageListSelect(messageList) {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          messageListOid: messageList.oid,
        },
      });
      // The old dynamic tags file was mapped to old list, so we have to clean it up
      this['message/REMOVE_DYNAMIC_TAGS_IN_SCRATCH_EMAIL_MESSAGE']();
      this['messageList/SET_CURRENT_MESSAGE_LIST'](messageList);
      if (!this.currentMessageListCanBeSchedued) {
        this.enableSchedule = false;
        this.clearScheduleTime();
      }
    },
    // Message Sender Field
    handleEditMessageSenderClick() {
      this.fieldBeingEdited = 'message-sender';

      this.$nextTick(() => {
        this.$refs['message-sender-select'].openDropdown();
      });
    },
    handleMessageSenderClose() {
      this.fieldBeingEdited = null;
    },
    handleMessageSenderClear() {
      this['messageSender/RESET_CURRENT_MESSAGE_SENDER']();
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          email: {
            promoterPropertyOid: null,
          },
        },
      });
    },
    handleMessageSenderSelect(sender) {
      this['messageSender/SET_CURRENT_MESSAGE_SENDER'](sender);
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          email: {
            promoterPropertyOid: sender.oid,
          },
        },
      });
    },
    // Message Sender Address Field
    handleEditMessageSenderAddressClick() {
      this.fieldBeingEdited = 'message-sender-address';
    },
    handleEnableScheduleToggle(toggle) {
      this.enableSchedule = toggle;
      if (!toggle) {
        this.clearScheduleTime();
      }
    },
    handleLinkTrackingToggle() {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          disableLinkTracking: !!!this.scratchEmailMessage.meta.disableLinkTracking,
        },
      });
    },
    handleHideDefaultEmailFooterToggle() {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          presentation: {
            hideDefaultEmailFooter: !this.scratchEmailMessage.meta.presentation.hideDefaultEmailFooter,
          },
        },
      });
    },
    handleScheduledAtInput(scheduledAt) {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        scheduledAt,
      });
    },
    handleTimezoneInput(timeZone) {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          presentation: {
            timeZone,
          },
        },
      });
    },
    handleResendVerificationEmailAnchorClick(anchorIdx) {
      if (anchorIdx === 0) {
        this['messageSender/RESEND_EMAIL_VERIFICATION'](this.currentMessageSender.oid);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
