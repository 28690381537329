<template>
  <div
    :class="[
      'am2-date-time-input',
      'u-display-inline-flex',
      'u-align-items-center',
    ]"
  >
    <div
      class="u-flex-grow-2 u-flex-shrink-0"
      :style="{
        width: size === 'small' ? '135px' : '178px',
      }"
    >
      <ar-text
        v-if="hasLabel"
        class="u-margin-bottom-3"
        size="xs"
        text="Date"
        :style="{
          color: $arStyle.color.blueGrey700,
        }"
      />
      <am2-datepicker
        :value="dateTimeViewModel.date"
        :disabled="disabled"
        :format="size === 'small' ? 'MMM Do, YYYY' : 'MMMM Do, YYYY'"
        :style="{
          height: '50px',
          width: '100%',
        }"
        @input="handleDateChange"
        data-test-id="date-time-input-date-picker"
      />
    </div>
    <div
      class="u-margin-left-2 u-flex-grow-1 u-flex-shrink-0"
      :style="{
        width: '120px',
      }"
    >
      <ar-text
        v-if="hasLabel"
        class="u-margin-bottom-3"
        size="xs"
        text="Time"
        :style="{
          color: $arStyle.color.blueGrey700,
        }"
      />
      <ar-input
        placeholder="e.g. 12:00pm"
        :value="dateTimeViewModel.time"
        :disabled="disabled"
        :style="{
          height: '50px',
          width: '100%',
        }"
        autocomplete="time"
        @input="handleTimeChange"
        @blur="handleTimeInputBlur"
        data-test-id="date-time-input-time-input"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'DateTimeInput',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
    timeZone: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'medium',
      validator: (val) => ['small', 'medium'].indexOf(val) !== -1,
    },
    hasLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateTimeViewModel: {},
    };
  },
  watch: {
    timeZone(val) {
      this.updateTimeZone(val);
    },
    value(newValue, oldValue) {
      if (!!newValue) {
        this.setDate(newValue);
      } else {
        this.nullifyDate()
      }
    },
  },
  mounted() {
    this.setDate(this.value);
  },
  methods: {
    updateTimeZone(timeZone) {
      if (!this.dateTimeViewModel.date || !this.dateTimeViewModel.time) {
        return;
      }

      let date;
      if (timeZone) {
        date = moment.tz(`${this.dateTimeViewModel.date} ${this.dateTimeViewModel.time}`, 'YYYY-MM-DD hh:mma', timeZone);
      } else {
        date = moment(`${this.dateTimeViewModel.date} ${this.dateTimeViewModel.time}`, 'YYYY-MM-DD hh:mma', true);
      }

      this.setDate(date.utc().format());
      this.sendInputChange();
    },
    // Skip validation and set the values to null
    nullifyDate() {
      this.dateTimeViewModel = {
        date: null,
        time: null,
      }
    },
    setDate(val) {
      if (!val || val === 'Invalid date') {
        return;
      }
      let datetimeInUtc = moment.utc(val);
      if (!datetimeInUtc._isValid) {
        return;
      }

      let datetimeInLocale;
      if (this.timeZone) {
        datetimeInLocale = datetimeInUtc.tz(this.timeZone);
      } else {
        datetimeInLocale = datetimeInUtc.local();
      }

      this.dateTimeViewModel = {
        date: datetimeInLocale.format('YYYY-MM-DD'),
        time: datetimeInLocale.format('hh:mma'),
      };
    },
    sendInputChange() {
      if (!this.dateTimeViewModel.date && !this.dateTimeViewModel.time) { // If both of them are null
        this.$emit('input', null);
      } else if (!this.dateTimeViewModel.date || !this.dateTimeViewModel.time) { // If one of them is null
        this.$emit('input', 'Invalid date');
      } else if (this.dateTimeViewModel.date && this.dateTimeViewModel.time) { // If both of them exist
        let date;
        if (this.timeZone) {
          date = moment.tz(`${this.dateTimeViewModel.date} ${this.dateTimeViewModel.time}`, 'YYYY-MM-DD hh:mma', this.timeZone);
        } else {
          date = moment(`${this.dateTimeViewModel.date} ${this.dateTimeViewModel.time}`, 'YYYY-MM-DD hh:mma', true);
        }
        this.$emit('input', date.utc().format());
      }
    },
    handleTimeChange(time) {
      /**
       * Please update whole object instead of single value in the object,
       * otherwise vue doesn't know it chagnes
       **/
      this.dateTimeViewModel = {
        ...this.dateTimeViewModel,
        time,
      };
    },
    handleTimeInputBlur() {
      if (!this.dateTimeViewModel.time) {
        this.sendInputChange();
        return;
      }
      this.dateTimeViewModel.time = moment(this.dateTimeViewModel.time, 'hh:mma').format('hh:mma');
      if (this.dateTimeViewModel.time === 'Invalid date') {
        this.dateTimeViewModel.time = moment().format('hh:mma');
      }
      this.sendInputChange();
    },
    handleDateChange(date) {
      /**
       * Please update whole object instead of single value in the object,
       * otherwise vue doesn't know it chagnes
       **/
      this.dateTimeViewModel = {
        ...this.dateTimeViewModel,
        date,
      };
      this.sendInputChange();
    },
  },
};
</script>

<style lang="scss" scoped>
.am2-date-time-input {
  width: 306px;
}
</style>
