<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="order-details-modal"
      :header="title"
      width="600px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div slot="body" class="body">
        <div class="order-header">
          <div class="header-left">
            <div class="header-icon">
              <ar-icon
                name="eventbrite"
                :color="$arStyle.color.eventbrite"
                height="15px"
                width="15px"
              />
            </div>
            <div class="header-title u-margin-left-3">
              <ar-text
                class="u-margin-top-1"
                v-if="orderData"
                :text="`${orderData.provider[0].toUpperCase()}${orderData.provider.substring(1)}`"
                size="xs"
                weight="bold"
              />
              <ar-text
                class="u-margin-top-2 event-title"
                :text="currentEvent ? currentEvent.name : ''"
                size="xs"
                :style="{
                  color: '#8492A6'
                }"
              />
            </div>
          </div>
          <div class="header-right">
            <ar-text
              class="u-margin-top-1"
              v-if="orderData"
              :text="`Order #${orderData.orderId}`"
              size="xs"
            />
            <ar-text
              class="u-margin-top-2"
              v-if="orderData"
              :text="formatDate(orderData.orderDate)"
              size="xxs"
              :style="{
                color: '#8492A6'
              }"
            />
          </div>
        </div>
        <div :class="[
          'modal-content',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
          <am2-order-items-table
            v-if="orderData"
            :style="{ height: 'auto' }"
            :data="orderData.sourceInfo"
            :orderId="orderData.orderId"
            is-value-currency
            :column-names="['Item','Price', 'Total']"
            empty-text="No data is available"
          />
          <div class="order-data-total-wrapper u-margin-top-4 u-margin-bottom-4">
              <ar-text
                size="xs"
                text="Total"
                weight="bold"
              />
              <ar-text
                v-if="orderData"
                size="xs"
                :text="`${orderData.orderCosts.gross.currency} ${orderData.orderCosts.gross.displayValue}`"
                weight="bold"
              />
          </div>
          <div class="order-status-wrapper">
            <div
              v-if="orderData"
              :class="[
                `order-status`,
                orderData && `order-${orderData.status}`
              ]">
              <ar-text
                size="xxs"
                :text="formatStatus(orderData.status)"
                :style="{
                  color: getColorBasedOnStatus(orderData.status)
                }"
                weight="bold"
              />
            </div>
          </div>
        </div>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
  import moment from 'moment';
  import { mapActions, mapMutations, mapState } from 'vuex';

  export default {
    name: 'OrderDetailsModal',
    props: {
      title: {
        type: String,
        default: 'Tags',
      },
      isShow: {
        type: Boolean,
        default: false,
      },
      orderData: {
        type: Object,
        default: () => {},
      },
    },

    computed: {
      ...mapState({
        currentEvent: state => state.event.currentEvent,
      }),
    },

    methods: {
      formatStatus(status) {
        let formattedStatus;
        if (status === 'deleted') {
          formattedStatus = 'Deleted';
        } else if (status === 'refunded') {
          formattedStatus = 'Refunded';
        } else if (status === 'completed') {
          formattedStatus = 'Completed';
        } else if (status === 'partial-refund') {
          formattedStatus = 'Partial Refund';
        } else if (status === 'transferred') {
          formattedStatus = 'Transferred';
        } else if (status === 'paid') {
          formattedStatus = 'Paid';
        } else {
          formattedStatus = 'Pending';
        }

        return formattedStatus;
      },

      formatDate(orderDate) {
        return moment.utc(orderDate).format('MMMM Do YYYY, h:mma (z)');
      },

      getColorBasedOnStatus(status) {
        let color;
        if (status === 'refunded' || status === 'partial-refund') {
          color = '#FFA95D';
        } else if (status === 'completed' || status === 'paid') {
          color = this.$arStyle.color.green500;
        } else { // if (status === 'deleted' || status === 'transferred' || status === 'pending') 
          color = this.$arStyle.color.skyBlueGrey700;
        }

        return color;
      },

      async handleCloseClick() {
        this.$emit('close');
      },
    },
};
</script>

<style lang="scss" scoped>
.order-details-modal {
  .order-header {
    background-color: #F6F8FA;
    width: 100%;
    display: flex;
    padding: 30px;
    border-bottom: 1px solid #DCE0E4;
    border-bottom: 1px solid #DCE0E4;

    .header-left {
      width: 50%;
      display: flex;
      max-width: 50%;

      .header-title {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: calc(100% - 40px);
      }

      .event-title {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .header-right {
      width: 50%;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }

    .header-icon {
      min-width: 30px;
      height: 30px;
      background-color: #FFFFFF;
      border: 1px solid #DCDEE4;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
    }
  }
  .header {
    font-size: 20px;
  }

  .modal-content {
    padding: 29px 30px;

    .order-data-total-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .order-status-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;

      .order-status {
        background-color: $skyBlueGrey400;
        padding: 5px 10px;
        height: 30px;
        border-radius: 15px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &.order-completed, &.order-paid {
          background-color: $green200;
        }

        &.order-refunded, &.order-partial-refund {
          background-color: $orange200;
        }
      }
    }
  }
}
</style>
