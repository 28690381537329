<template>
  <section
    class="signup-forms-page-wrapper"
    v-infinite-scroll="loadMoreSignupForms"
    infinite-scroll-disabled="infiniteScrollDisabled"
    :infinite-scroll-distance="10"
  >
    <am2-confirm-signup-form-creation-modal />
    <div
      :class="{
        content: true,
        ['content']: true,
      }"
    >
      <am2-top
        :button="{
          action: handleFormCreate,
          text: $arMediaQuery.pageContent.minWidth('sm') ? 'Create form' : 'Create',
          iconName: 'circle-plus',
          iconDistance: '9px',
          iconProps: {
            color: 'white',
          },
          style: {
            width: $arMediaQuery.pageContent.minWidth('sm') ? '160px' : '112px',
          },
        }"
        :search="{
          action: serverSideSearch,
          placeholder: 'Search signup forms',
          value: searchString,
          style: {
            width: $arMediaQuery.pageContent.minWidth('md') ? '330px' : '100%'
          }
        }"
        title="Signup Forms"
        :class="[
          'top-wrapper',
          $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        ]" />
      <ar-divider
        v-if="$arMediaQuery.pageContent.minWidth('md')"
        :style="{
          marginTop: '35px',
          marginBottom: '28px',
        }"
      />
      <div
        v-if="!isFetchingSignupForms && signupForms.length === 0"
      >
        <am2-no-content-section
          v-if="searchString"
          header="No forms found"
          header-size="sm"
        />
        <am2-no-content-section
          v-else
          icon-name="ar-form-in-circle"
          header="Create a signup form"
          body="Use signup forms to capture subscribers across email and SMS"
          button-text="Create form"
          @buttonClick="handleFormCreate"
        />
      </div>
      <div
        v-else
        :class="[
          'u-display-flex',
          'u-justify-content-space-between',
          'u-flex-wrap-wrap',
          $arMediaQuery.pageContent.maxWidth('md') ? '' : 'u-flex-flow-column',
        ]"
      >
        <am2-signup-form-card
          v-for="signupForm of signupForms"
          :key="signupForm.oid"
          :signup-form="signupForm"
          :size="$arMediaQuery.pageContent.maxWidth('md') ? 'tile' : 'list'"
          :style="{
            marginBottom: '20px',
            width: $arMediaQuery.pageContent.only('md') ? 'calc(50% - 10px)' : '100%',
          }"
          @clone="handleSignupFormClone"
        />
        <am2-card-skeleton
          v-for="n in skeletonCardsCount"
          :key="n"
          type="signup-form"
          :level="2 * n - 1"
          :show-as="$arMediaQuery.pageContent.maxWidth('md') ? 'tiles' : 'list'"
          :style="{
            width: $arMediaQuery.pageContent.only('md') ? 'calc(50% - 10px)' : '100%',
            marginBottom: '20px',
            border: '1px solid #EDF1F5',
          }"
        />
      </div>
    </div>
  </section>
</template>

<script>

  import { mapActions, mapMutations, mapState } from 'vuex';

  export default {
    name: 'MessageListForms',

    data() {
      return {
        searchString: '',
      }
    },

    computed: {
      ...mapState({
        signupForms: state => state.signupForm.signupForms,
        isNoMoreSignupForms: state => state.signupForm.isNoMoreSignupForms,
        hasFetchSignupFormsFailed: state => state.signupForm.hasFetchSignupFormsFailed,
        isFetchingSignupForms: state => state.signupForm.isFetchingSignupForms,
      }),
      infiniteScrollDisabled() {
        return this.isNoMoreSignupForms || this.isFetchingSignupForms || this.hasFetchSignupFormsFailed;
      },
      skeletonCardsCount() {
        if (!this.isFetchingSignupForms) {
          return 0;
        } else {
          return this.signupForms.length === 0 ? 5 : 1;
        }
      },
    },

    methods: {
      ...mapActions([
        'signupForm/FETCH_SIGNUP_FORMS',
      ]),

      ...mapMutations([
        'signupForm/RESET_SIGNUP_FORMS',
      ]),

      reloadSignupForms() {
        this['signupForm/FETCH_SIGNUP_FORMS']({
          append: false,
          searchString: this.searchString,
        });
      },

      loadMoreSignupForms() {
        this['signupForm/FETCH_SIGNUP_FORMS']({
          append: true,
          searchString: this.searchString,
        });
      },

      handleFormCreate() {
        this.$router.push({
          path: `/message-center/signup-forms/new/basic`,
        });
      },

      handleSignupFormClone() {
        this.searchString = '';
        this.reloadSignupForms();
      },

      async serverSideSearch(text) {
        this.searchString = text;
        this.reloadSignupForms();
      },
    },

    created() {
      this.reloadSignupForms();
    },

    beforeDestroy() {
      this['signupForm/RESET_SIGNUP_FORMS']();
    },
  };
</script>

<style scoped lang="scss">
  .signup-forms-page-wrapper {
    display: flex;
    .content {
      transition: 0.3s all;
      margin-left: 0;
      width: 100%;

      .top-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 50px 0 35px;

        &.sm-max {
          margin: 35px 0 22px;
          flex-direction: column;
          justify-content: unset;
          align-items: flex-start;
        }
      }
    }
  }
</style>
