<template>
  <section
    :class="[
      'wrapper',
      'content',
      'content--centered',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
    ]">
    <am2-step-link
      class="step-back-link"
      text="Back to integrations"
      @click="handleBackLinkClick" />
    <div
      :class="[
        'title-section',
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      ]">
      <div class="title-section-left">
        <am2-icon-title-section
          title="Zoom"
          description="Automatically sync your events & attendees from Zoom"
          :icon-props="{
            name: 'zoom',
            color: $arStyle.color.zoom,
            height: '46px',
            width: '40px',
          }"
        />
      </div>
      <div class="title-section-right" v-if="!isFetchingZoomIntegration && integrationsSummary">
        <am2-integration-account-select
          v-if="!isFetchingZoomIntegration && zoomIntegration && currentIntegration"
          :default-selected-integration-oid="currentIntegration.oid"
          :available-integrations="integrationsSummary"
          @select="handleIntegrationChange"
          @new-account="handleAddNewIntegration"
          :style="{ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }"
        />
        <am2-expand-button-dropdown
          align="left"
          :button-props="{ iconName: 'settings' }"
          :items="[
            {
              name: 'Open Zoom',
              value: 'goToZoomSite',
            },
            {
              name: 'Reconnect Integration',
              value: 'reconnectIntegration',
            },
            {
              name: 'Remove Account',
              value: 'removeIntegration',
            },
          ]"
          @select="handleSettingOptionSelect" />
      </div>
    </div>


    <section v-if="isAddingZoomIntegration || isFetchingZoomIntegration" class="integration-loading-container">
      <am2-loading-bubble />
    </section>
    <section v-else>
      <section v-if="integrationFailed" class="integration-failed-message">
        <ar-snackbar
          type="warning"
          message="Your Zoom connection is no longer valid. This means we can't sync your event & attendees data. <a>Please reconnect your account</a>"
          @anchorClick="handleReconnectIntegration"
          :style="{ width: '100%' }"
        />
      </section>

      <section v-else>
        <am2-card-container
          class="sync-block-container"
        >
          <div v-if="latestTaskStatus === 'in-progress'" class="sync-block">
            <am2-loading-bubble
              class="sync-animation"
            />
            <div class="text-block">
              <am2-heading
                class="header"
                size="md"
                type="h2"
                :title="syncingTaskStatusCopy"
              />
              <div class="button-block">
                <ar-simple-button
                  text="Stop sync"
                  outlined
                  class="button sync-btn"
                  @click="handleStopSync" />
              </div>
            </div>
          </div>
          <div v-else class="sync-block">
            <div
              v-if="latestTaskStatus === 'completed'"
              class="tick-container"
              :style="{
                backgroundColor: $arStyle.color.green500,
              }"
            >
              <ar-icon
                name="check"
                class="tick-icon"
                width="18"
                height="17"
              />
            </div>
            <div
              v-else
              class="tick-container"
              :style="{
                backgroundColor: $arStyle.color.purple500,
              }"
            >
              <ar-icon
                name="exclamation-mark"
                class="tick-icon"
                height="25"
              />
            </div>
            <div
              class="text-block"
              :style="{
                marginTop: '41px',
              }"
            >
              <am2-heading
                class="header"
                size="md"
                type="h2"
                :title="syncingTaskStatusCopy"
              />
              <div
                class="button-block"
              >
                <ar-simple-button
                  :text="syncButtonCopy"
                  :loading="latestTaskStatus === 'in-progress'"
                  class="button sync-btn"
                  @click="handleStartSync" />
              </div>
              <div v-if="hasSyncedBefore" class="last-sync-time">
                <ar-text
                  :text="`Last synced ${timeSinceLastSync}`"
                  size="xxs"
                  :style="{
                    color: '#8E97A7',
                  }"
                />
              </div>
            </div>
          </div>
        </am2-card-container>
      </section>
    </section>

  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: 'ZoomIntegration',
  layout: 'default',

  data: () => ({
    currentlySelectedIntegrationIndex: 0,
    syncStatusPoller: null,
  }),

  computed: {
    ...mapState({
      zoomIntegration: state => state.zoomIntegration.integration,
      isFetchingZoomIntegration: state => state.zoomIntegration.isFetchingIntegration,
      hasFetchedZoomIntegration: state => state.zoomIntegration.hasFetchedIntegration,
      isAddingZoomIntegration: state => state.zoomIntegration.isAddingIntegration,
      isDeletingZoomIntegration: state => state.zoomIntegration.isDeletingIntegration,
      isReconnectingZoomIntegration: state => state.zoomIntegration.isReconnectingIntegration,
      isFetchingTasks: state => state.zoomIntegration.isFetchingTasks,
      tasks: state => state.zoomIntegration.tasks,
      latestTaskStatus: state => state.zoomIntegration.latestTaskStatus,
    }),

    zoomIntegrationEnabled() {
      return process.env.arEnableZoomIntegration;
    },

    latestTask() {
      if (!this.tasks || this.tasks.length < 1) return null;
      return this.tasks[0];
    },

    syncButtonCopy() {
      if (this.latestTaskStatus === 'in-progress') {
        return 'Sync now';
      } else {
        return 'Sync again';
      }
    },

    syncingTaskStatusCopy() {
      switch (this.latestTaskStatus) {
        case 'in-progress':
          return this.hasAnyEvents ? `Syncing ${this.latestTask.statusDetails.completedEventsCount} of ${this.latestTask.statusDetails.totalEvents} events from Zoom` : `Syncing events from Zoom`;
        case 'pending':
          return 'The events syncing is still pending';
        case 'completed':
          return 'Your Zoom account is currently connected';
        case 'cancelled':
          return 'Your Zoom account connection is cancelled';
        case 'failed':
          return this.latestTask.statusDetails.lastError;
        default:
          return 'The Zoom account is currently connected';
      }
    },

    integrationsSummary() {
      if (!this.zoomIntegration) return null;
      return this.zoomIntegration
        .filter(i => i.status !== 'failed')
        .map(i => ({
          oid: i.oid,
          name: i.integration?.user?.email,
          email: i.integration?.user?.email,
          imageSrc: null,
        }));
    },

    currentIntegration() {
      if (this.currentlySelectedIntegrationIndex === null) return null;
      if (!this.zoomIntegration || this.zoomIntegration.length < 1 || !this.zoomIntegration[this.currentlySelectedIntegrationIndex]) return null;
      return this.zoomIntegration[this.currentlySelectedIntegrationIndex];
    },

    integrationFailed() {
      if (!this.currentIntegration) return false;
      return this.currentIntegration.status === 'failed';
    },

    isSyncing() {
      if (!this.currentIntegration || !this.hasExistingTask) {
        return false;
      }
      return this.latestTaskStatus === 'in-progress';
    },

    hasSyncedBefore() {
      return (
        this.latestTask && this.latestTask.status === 'completed'
      );
    },

    hasAnyEvents() {
      return this.latestTask && this.latestTask.statusDetails.totalEvents && this.latestTask.statusDetails.totalEvents > 0;
    },

    timeSinceLastSync() {
      let sinceNow = "";

      if (this.hasSyncedBefore) {
        sinceNow = moment.utc(
          this.latestTask.sysMtime
        ).fromNow();
      }

      return sinceNow;
    }
  },

  async mounted() {
    if (!this.zoomIntegrationEnabled) {
      this.handleBackLinkClick();
    }
    this.fetchInitialData();
  },

  beforeDestroy() {
    this['zoomIntegration/RESET_INTEGRATION']();

    if (this.syncStatusPoller) {
      clearInterval(this.syncStatusPoller);
    }
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'zoomIntegration/FETCH_INTEGRATION',
      'zoomIntegration/ADD_INTEGRATION',
      'zoomIntegration/DELETE_INTEGRATION',
      'zoomIntegration/RECONNECT_INTEGRATION',
      'zoomIntegration/SYNC_START',
      'zoomIntegration/SYNC_STOP',
      'zoomIntegration/FETCH_TASKS',
      'CONNECT_TO_INTEGRATION',
    ]),
    ...mapMutations([
      'zoomIntegration/RESET_INTEGRATION',
    ]),
    async fetchInitialData() {
      if (this.isFetchingZoomIntegration || this.hasFetchedIntegration) return;
      await this['zoomIntegration/FETCH_INTEGRATION']();

      if (this.currentIntegration?.oid && !this.isFetchingTasks) {
        this['zoomIntegration/FETCH_TASKS']({
          oid: this.currentIntegration.oid,
          orderby: "sysMtime desc",
          filter: {
            name: "event-data-synch",
            promoterIntegrationOid: this.currentIntegration.oid,
          }
        });
      }
    },

    async updateData() {
      await this['zoomIntegration/FETCH_TASKS']({
        orderby: "sysMtime desc",
        top: 1,
        oid: this.currentIntegration.oid,
      });

      if (this.currentIntegration && this.hasExistingTask && this.latestTaskStatus !== "in-progress") {
        window.clearInterval(this.syncStatusPoller);
        this.syncStatusPoller = null;
      }
    },

    hasExistingTask() {
      return this.latestTask !== null;
    },
    
    handleBackLinkClick() {
      this.$router.push({ path: '/settings/integrations' });
    },

    handleIntegrationChange(integration, index) {
      this.currentlySelectedIntegrationIndex = index;
    },

    async handleAddNewIntegration() {

      try {
        const res = await this.CONNECT_TO_INTEGRATION({ 
          provider: 'zoom',
          app: 'zoom'
        });
        this['zoomIntegration/FETCH_INTEGRATION']();
      } catch (e) {
        // If it's closed by user, then it's all good
        if (e.name === 'USER_CLOSE') {
          return;
        }

        this.$arNotification.push({ type: 'error', message: `Failed to connect to Zoom: ${e.message}` });
        throw e;
      }
    },

    handleReconnectIntegration() {
      this['zoomIntegration/RECONNECT_INTEGRATION']({oid: this.currentIntegration.oid});
    },

    async handleStartSync() {
      this['zoomIntegration/SYNC_START']({oid: this.currentIntegration.oid});
      this.syncStatusPoller = setInterval(this.updateData, 3000);
    },

    handleStopSync() {
      this['zoomIntegration/SYNC_STOP']({oid: this.currentIntegration.oid});
      window.clearInterval(this.syncStatusPoller);
      this.syncStatusPoller = null;
    },

    async handleSettingOptionSelect(item) {
      if (item.value === 'removeIntegration') {
        const answer = await this.SHOW_CONFIRM({
          messageHtml: 'Removing the Zoom integration will stop syncing your orders and customer data from Zoom.',
          confirmButtonText: 'Remove integration',
          iconName: 'alert-question-mark',
          title: 'Are you sure?',
        });
        if (answer) {
          try {
            await this['zoomIntegration/DELETE_INTEGRATION']({oid: this.currentIntegration.oid});
            if (this.zoomIntegration.length > 1) {
              this.currentlySelectedIntegrationIndex = 0;
            } else {
              this.$router.push({ path: '/settings/integrations' });
            }
          } catch (e) {
            this.$arNotification.push({ type: 'error', message: 'Failed to remove integration' });
          }
        }
      } else if (item.value === 'reconnectIntegration') {
        this.handleReconnectIntegration();
      } else if (item.value === 'goToZoomSite') {
        if (!this.currentIntegration) return;
        var win = window.open('https://zoom.us/meeting', '_blank');
        win.focus();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.wrapper {

  &.sm-max {
    padding: 0 24px;
  }
  &.xs-max {
    padding: 0 12px;
  }

  .integration-loading-container {
    display: flex;
    justify-content: center;
    margin-top: 65px;
  }

  .step-back-link {
    margin-top: 48px;
  }

  .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;

    .title-section-left {
      display: inline-flex;
      align-items: flex-start;

      .tag {
        position: relative;
        margin-left: 10px;
        top: 4px;
      }
    }

    .title-section-right {
      display: inline-flex;
      align-items: center;

      .select {
        width: 300px;
        margin-right: 10px;
      }
    }

    &.md-max {
      flex-direction: column;
      align-items: flex-start;

      .title-section-right {
        margin-top:16px;
      }
    }
  }


  .sync-block-container {
    background-color: #FFF;
    margin-top:64px;

    .sync-block {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 76px;
      padding-bottom: 76px;
      flex-direction: column;

      .tick-container {
        height: 44px;
        width: 44px;
        border-radius: 50%;
        background-color: $green500;
        display: flex;
        justify-content: center;
        align-items: center;
        .tick-icon {
          color: #FFF;
        }
      }

      .sync-animation {
        color: $purple500;
        margin-bottom: 63px;
      }

      .button-block {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding: 0 12px;
        margin-top: 44px;

        .button {
          width: 300px;
          max-width:100%;
        }
      }

      .last-sync-time {
        margin-top: 25px;
        font-size: 14px;
        color: #8E97A7;
        line-height: 25px;
      }

      .text-block {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 100%;

        .header {
          color: $blueGrey800;
          margin-bottom: 3px;
        }
      }
    }
  }
}
</style>
