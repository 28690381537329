<template>
  <section>
    <am2-top-create
      v-if="isAdminAccount"
      :title="isNewAccount ? 'Create new account' : 'Edit account'"
      exit-path="/admin/accounts"
      :loading="isFetchingAccount"
    />
    <div
      v-if="isAdminAccount"
      class="wrapper"
    >
      <am2-loading-section
        v-if="isFetchingAccount"
        :style="{
          height: '100%',
        }"
      />
      <div
        v-else
        class="inner"
      >
        <am2-heading
          type="h1"
          size="lg"
          :title=" !isNewAccount ? 'Edit account' : 'Create account'"
          :style="{ marginBottom: '38.5px' }"
        />

        <am2-field v-if="!isNewAccount" name="account-first-name" label="Account Oid">
          <ar-text
            :text="editAccount.oid"
            size="xs"
          />
        </am2-field>

        <!-- First name -->
        <am2-field name="account-first-name" label="First Name">
          <div>
            <ar-input
              v-validate.disable="'required'"
              id="account-first-name"
              ref="account-first-name"
              v-model="editAccount.firstName"
              autocomplete="given-name"
              name="account-first-name"
              data-vv-as="first name"
              placeholder="First name"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('account-first-name')"
              type="error"
              :text="veeErrors.first('account-first-name')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>

        <!-- Last name -->
        <am2-field name="account-last-name" label="Last Name">
          <div>
            <ar-input
              v-validate.disable="'required'"
              id="account-last-name"
              ref="account-last-name"
              v-model="editAccount.lastName"
              autocomplete="family-name"
              name="account-last-name"
              data-vv-as="last name"
              placeholder="Last name"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('account-last-name')"
              type="error"
              :text="veeErrors.first('account-last-name')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>

        <!-- Email -->
        <am2-field name="account-email-address" label="Email Address">
          <div>
            <ar-input
              v-validate.disable="'required|email'"
              id="account-email-address"
              ref="account-email-address"
              v-model="editAccount.emailAddress"
              autocomplete="email"
              name="account-email-address"
              data-vv-as="email address"
              placeholder="Email Address"
              class="input--block"
              type="text"
              :disabled="!isNewAccount"
            />
            <ar-state-message
              v-if="veeErrors.has('account-email-address')"
              type="error"
              :text="veeErrors.first('account-email-address')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>

        <!-- Password -->
        <am2-field v-if="isNewAccount" name="account-password" label="Password (Optional)">
          <div>
            <ar-snackbar
              v-if="isNewAccount"
              type="warning"
              message="A random password will be generated for you if you don't enter one"
              :style="{
                width: '500px',
                marginBottom: '10px',
              }"
            />
            <ar-input
              v-validate="'arPasswordLength'"
              id="account-password"
              ref="account-password"
              v-model="editAccount.password"
              name="account-password"
              autocomplete="new-password"
              data-vv-as="password"
              placeholder="Password"
              class="input--block"
              type="password"
              text-secure
            />
            <ar-state-message
              v-if="veeErrors.has('account-password')"
              type="error"
              :text="veeErrors.first('account-password')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>


        <!-- Promoters -->
        <am2-field name="account-promoters" label="Promoters which this account can masquerade as (Optional)">
          <div v-if="editAccount.masqueradeAsOids ? editAccount.masqueradeAsOids.includes(-418) : false">
            This person can access <b>all</b> other promoters.
          </div>
          <div v-else>
            <am2-promoter-select
              @select="handleMasqueradablePromoterAdd"
              :style="{ width: '500px', marginTop: '10px 0' }"
            />
            <div class="tag-container">
              <div
                v-for="(promoter) in masqueraderPromoterAccounts"
                class="tag"
                :key="promoter.masqueradeAsOids"
              >
                <ar-text
                  size="sm"
                  :text="promoter.emailAddress"
                  class="name"
                />
                <div
                  class="delete-btn"
                  @click.stop.prevent="() => removeMasqueradablePromoter(promoter.oid)"
                >
                  <ar-icon
                    class="icon"
                    name="cross"
                    height="10px"
                    width="10px"
                    stroke-width="4"
                    stroke-linecap="round"
                  />
                </div>
              </div>

            </div>
          </div>
        </am2-field>
        <am2-field name="account-promoters" label="Associated Promoter (Optional)">
          <ar-snackbar
            v-if="isNewAccount"
            type="warning"
            message="If you don't select it, then this account will be a brand new account without any campaigns, events, audience, etc"
            :style="{
              width: '500px',
            }"
          />
          <am2-promoter-select
            v-if="isNewAccount"
            v-model="editAccount.promoterOid"
            name="promoterOid"
            :style="{ width: '500px', marginTop: '10px' }"
          />
          <am2-card-container
            v-if="promoterAccountOwner"
            class="owner-promoter-section"
          >
            <ar-text
              class="title"
              text="Promoter oid"
              size="xs"
              weight="bold"
            />
            <ar-text
              class="value"
              :text="promoterAccountOwner.oid"
              size="xs"
            />
            <ar-text
              class="title"
              text="Name"
              size="xs"
              weight="bold"
            />
            <ar-text
              class="value"
              :text="promoterAccountOwner.name"
              size="xs"
            />
            <ar-text
              class="title"
              text="Email address"
              size="xs"
              weight="bold"
            />
            <ar-text
              class="value"
              :text="promoterAccountOwner.emailAddress"
              size="xs"
            />
          </am2-card-container>
        </am2-field>
      </div>
      <nav class="navigation">
        <ar-simple-button
          v-if="isNewAccount"
          text="Create new account"
          :loading="isCreatingAccount"
          @click="handleCreateNewAccountClick"
          :style="{ width: '100%' }"
        />
        <ar-simple-button
          v-else
          text="Edit account"
          :loading="isUpdatingAccount"
          @click="handleEditAccountClick"
          :style="{ width: '100%' }"
        />
      </nav>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';

export default {
  name: 'StaffAccountModify',
  layout: 'edit',

  data: () => ({
    editAccount: {
      oid: null,
      firstName: null,
      lastName: null,
      emailAddress: null,
      masqueradeAsOids: [],
      promoterOid: null,
      password: null,
    },
    isFetchingAccount: true,
    isCreatingAccount: false,
    isUpdatingAccount: false,
  }),

  computed: {
    ...mapState({
      masqueraderPromoterAccounts: state => state.admin.masqueraderPromoterAccounts,
      promoterAccountOwner: state => state.admin.promoterAccountOwner,
      promoterAccounts: state => state.admin.promoterAccounts,
    }),
    ...mapGetters({
      isAdminAccount: 'auth/isAdminAccount',
    }),
    isNewAccount() {
      return this.editAccount.oid === null;
    },
  },

  async mounted() {
    this.resetStates();
    if (!this.isAdminAccount) {
      this.$router.push({
        path: '/audience',
      });
    }

    const accountOid = this.$route.params.oid;

    if (accountOid) {
      let data;
      try {
        this.isFetchingAccount = true;
        data = await this.FETCH_PROMOTER_ACCOUNT(accountOid);

        this.editAccount.oid = data.oid;
        this.editAccount.firstName = data.firstName;
        this.editAccount.lastName = data.lastName;
        this.editAccount.emailAddress = data.emailAddress;
        this.editAccount.masqueradeAsOids = data.masqueradeAsOids || [];
        this.editAccount.promoterOid = data.promoterOid;

        this['admin/FETCH_MASQUERADER_PROMOTER_ACCOUNTS'](this.editAccount.masqueradeAsOids);
        this['admin/FETCH_PROMOTER_ACCOUNT_OWNDER'](this.editAccount.promoterOid);
      } catch(e) {
        this.$arNotification.push({ type: 'error', message: 'Failed to fetch account' });
      } finally {
        this.isFetchingAccount = false;
      }
    } else {
      this.isFetchingAccount = false;
    }
  },

  beforeDestroy() {
    this.resetStates();
  },

  methods: {
    ...mapActions([
      'admin/FETCH_PROMOTER_ACCOUNT_OWNDER',
      'admin/FETCH_MASQUERADER_PROMOTER_ACCOUNTS',
      'FETCH_PROMOTER_ACCOUNT',
      'CREATE_STAFF_ACCOUNT',
      'UPDATE_PROMOTER_ACCOUNT',
      'SHOW_CONFIRM',
    ]),

    ...mapMutations([
      'admin/ADD_TO_MASQUERADER_PROMOTER_ACCOUNTS',
      'admin/REMOVE_FROM_MASQUERADER_PROMOTER_ACCOUNTS',
      'admin/RESET_MASQUERADER_PROMOTER_ACCOUNTS',
      'admin/RESET_PROMOTER_ACCOUNT_OWNER',
    ]),

    resetStates() {
      this['admin/RESET_MASQUERADER_PROMOTER_ACCOUNTS']();
      this['admin/RESET_PROMOTER_ACCOUNT_OWNER']();
    },

    handleMasqueradablePromoterAdd(promoter) {
      const index = this.editAccount.masqueradeAsOids.indexOf(promoter.oid);
      if (index === -1 && promoter.oid != -418) {
        this.editAccount.masqueradeAsOids.push(promoter.oid);
        this['admin/ADD_TO_MASQUERADER_PROMOTER_ACCOUNTS'](promoter);
      }
    },

    removeMasqueradablePromoter(promoterOid) {
      const index = this.editAccount.masqueradeAsOids.indexOf(promoterOid);
      if (index >= 0) {
        this.editAccount.masqueradeAsOids.splice(index, 1);
        this['admin/REMOVE_FROM_MASQUERADER_PROMOTER_ACCOUNTS'](promoterOid);
      }
    },

    async handleCreateNewAccountClick() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }

      try {
        this.isCreatingAccount = true;
        let payload = {
          firstName: this.editAccount.firstName,
          lastName: this.editAccount.lastName,
          emailAddress: this.editAccount.emailAddress,
          password: this.editAccount.password,
          masqueradeAsOids: this.editAccount.masqueradeAsOids,
        };
        if (this.editAccount.promoterOid) {
          payload.promoterOid = this.editAccount.promoterOid;
        }
        await this.CREATE_STAFF_ACCOUNT(payload);

        this.$arNotification.push({ type: 'success', message: `Successfully created ${this.firstName}'s account`});
        this.$router.push({ path: `/admin/accounts` });
      } catch (e) {
        console.error(e);
        if (e.response && e.response.status === 409) {
          this.$arNotification.push({
            type: 'error',
            message: 'There is already an account with this email address',
          });
        } else {
          this.$arNotification.push({
            type: 'error',
            message: 'Failed to create account',
          });
        }
      } finally {
        this.isCreatingAccount = false;
      }
    },

    async handleEditAccountClick() {
      try {
        this.isUpdatingAccount = true;
        await this.UPDATE_PROMOTER_ACCOUNT({
          oid: this.editAccount.oid,
          firstName: this.editAccount.firstName,
          lastName: this.editAccount.lastName,
          masqueradeAsOids: this.editAccount.masqueradeAsOids,
          promoterOid: this.editAccount.promoterOid,
        });

        this.$arNotification.push({ type: 'success', message: `Successfully updated account`});
        this.$router.push({ path: `/admin/accounts` });
      } catch (e) {
        console.error(e);
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to create account',
        });
      } finally {
        this.isUpdatingAccount = true;
      }
    },

  }
};
</script>
<style lang="scss" scoped>

.wrapper {
  display: flex;
  padding: 90px 0 0 0;
  height: 100vh;
  flex-direction: column;
  background: #fff;

  .inner {
      flex-grow: 1;
      padding: 50px 80px;
      height: calc(100% - 110px);
      overflow-y: auto;
      .grey-text {
          color: $blueGrey700;
        }
        .ar-field {
          p {
            margin-bottom: 15px;
          }
        }
      .image-requirements-text {
        margin-top: 14px;
        color: $blueGrey600;
      }
      .image-icon {
        color: $blueGrey700;
      }

      .owner-promoter-section {
        margin-top: 10px;
        padding: 20px;
        background-color: $skyBlueGrey500;

        .title {
          margin-bottom: 10px;
        }
        .value {
          margin-bottom: 20px;
          color: #54657E;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .tag-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        .tag {
          // layout
          display: flex;
          align-items: center;
          justify-content: space-between;

          color: white;
          background-color: $purple500;
          border-radius: 100px;
          height: 30px;
          padding: 0 6px 0 15px;
          margin: 5px 5px 5px 0;

          .name {
            max-width: 250px;
            color: white;
            margin-right: 10px;
          }

          .delete-btn {
            background-color: $purple400;
            border-radius: 50%;
            height: 20px;
            width: 20px;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            &:hover {
              cursor: pointer;
              background-color: $purple600;
            }

            .cross {
              color: white;
            }
          }
        }
      }
    }
    .navigation {
      width: 100%;
      z-index: $zIndexRegular;
      height: 110px;
      padding: 30px 95px 30px 80px;
      border-top: 1px solid $skyBlueGrey500;
      background-color: white;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
}

</style>
