<template>
  <RendererFrame
    :is-readonly="!disableReadMode && isReadOnly"
    :title="filterCriteria.title"
    :filter-tooltip="filterTooltip"
    :readonly-items="readonlyItems"
    :apply-disabled="!isEditConditionComplete"
    :additional-constraints="filterCriteria.constraints"
    :condition-button-text="conditionButtonText"
    :hide-button="hideButton"
    @confirm="handleConfirmClick"
    @cancel="handleCancelClick"
    @delete="handleDeleteClick"
    @openChangeMode="isReadOnly = false"
  >
    <div slot="content" class="select-picker">
      <AdditionalConstraintRenderer
        class="mandatory-constraints u-margin-bottom-2"
        v-if="criteriaHasMandatoryConstraints"
        :filter-condition="filterCondition"
        :filter-constraints="filterCriteria.constraints"
        mandatory-constraints
        @constraintUpdate="handleConstraintUpdate"
      />
      <div v-if="!criteriaHasMandatoryConstraints || criteriaHasFilledMandatoryConstraint">
        <ar-simple-select
          :items="selectItems"
          :default-select-index="selectedItemIndex"
          :placeholder="`Select...`"
          class="select"
          @select="handleSelectClick"
          data-test-id="filter-select-input"
        />
        <AdditionalConstraintRenderer
          v-if="criteriaHasConstraints"
          class="u-margin-top-3"
          :filter-condition="filterCondition"
          :filter-constraints="filterCriteria.constraints"
          @constraintUpdate="handleConstraintUpdate"
        />
      </div>
    </div>
  </RendererFrame>
</template>

<script>
import { clone } from '@/utils/helpers';
import { filterCriteriaHasConstraint, filterCriteriaHasMandatoryConstraint, filterCriteriaMandatoryConstraintsFilled } from '@/utils/filter';
import RendererFrame from '../RendererFrame'
import AdditionalConstraintRenderer from '../RendererFrame/AdditionalConstraintRenderer'

export default {
  title: 'RadioPicker',
  name: 'SelectPicker', 
  components: {
    RendererFrame,
    AdditionalConstraintRenderer,
  },
  props: {
    filterCriteria: {
      type: Object,
      default: () => ({}),
    },
    filterCondition: {
      type: Object,
      default: () => ({}),
    },
    conditionButtonText: {
      type: String,
      default: 'Apply filter'
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    disableReadMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isReadOnly: false,
      cachedFilterCondition: clone(this.filterCondition),
      editFilterCondition: clone(this.filterCondition),
    };
  },
  computed: {
    criteriaHasConstraints() {
      return filterCriteriaHasConstraint(this.filterCriteria);
    },
    criteriaHasMandatoryConstraints() {
      return filterCriteriaHasMandatoryConstraint(this.filterCriteria);
    },
    criteriaHasFilledMandatoryConstraint() {
      return filterCriteriaMandatoryConstraintsFilled(this.filterCriteria, this.editFilterCondition);
    },
    filterTooltip() {
      return this.filterCriteria?.description;
    },
    isEditConditionComplete() {
      return this.$arUtils.segment.isSegmentConditionComplete(this.editFilterCondition);
    },
    isCachedConditionComplete() {
      return this.$arUtils.segment.isSegmentConditionComplete(this.cachedFilterCondition);
    },
    expectedOptionTitle() {
      const clickedOption = this.filterCriteria.data.find(option => option.value === this.editFilterCondition.data);
      return clickedOption ? clickedOption.title : null;
    },
    selectItems() {
      return this.filterCriteria.data.map(option => ({
        name: option.title,
        class: 'bold',
        value: option.value,
      }));
    },
    selectedItemIndex() {
      for (let i = 0; i < this.filterCriteria.data.length; i += 1) {
        if (this.filterCriteria.data[i].value === this.editFilterCondition.data) {
          return i;
        }
      }
      return -1;
    },
    readonlyItems() {
      if (!this.editFilterCondition.data) {
        return null;
      }
      return [{ name: this.expectedOptionTitle }];
    },
  },

  async created() {
    this.isReadOnly = this.$arUtils.segment.isSegmentConditionComplete(this.editFilterCondition);
  },

  methods: {
    filterItems(query) {
      return this.selectItems.filter(item => item.name.indexOf(query) > -1);
    },
    review() {
      this.$emit('apply', this.editFilterCondition);
    },
    handleConfirmClick() {
      this.isReadOnly = true;
      this.cachedFilterCondition = clone(this.editFilterCondition);
    },
    handleDeleteClick() {
      this.$emit('delete');
    },
    handleCancelClick() {
      if (this.isCachedConditionComplete) {
        if (!this.disableReadMode) {
          this.isReadOnly = true;
          this.editFilterCondition = clone(this.cachedFilterCondition);
          this.$emit('apply', this.editFilterCondition);
        } else {
          this.$emit('delete');
        }
      } else {
        this.$emit('delete');
      }
    },
    handleSelectClick(select) {
      if (this.editFilterCondition.data === select.value) {
        return;
      }
      this.$set(this.editFilterCondition, 'data', select.value);
      this.review();
    },
    handleConstraintUpdate(item) {
      const { constraint, key, values } = item;
      this.$set(this.editFilterCondition.constraints[constraint], 'condition', key);
      if (!!values) this.$set(this.editFilterCondition.constraints[constraint], 'values', values);
      this.review();
    },
  },
};
</script>

<style lang="scss" scoped>
.select-picker {
  .select {
    height: 40px;
  }
}
</style>
