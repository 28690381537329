<template>
  <section
    :class="['events-wrapper', $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']"
    v-infinite-scroll="loadMoreEvents"
    infinite-scroll-disabled="infiniteScrollDisabled"
    :infinite-scroll-distance="10"
  >
    <am2-unlock-prompt
      v-if="!isFeatureEnabled"
      :title="$arMediaQuery.pageContent.minWidth('sm') ? `Unlock Events` : `Events`"
      :subtitle="$arMediaQuery.pageContent.minWidth('sm') ? `See all your events from the different ticketing platforms you use. <a href='https://audiencerepublic.com/events' target='_blank' style='color: #FFF;'>Learn more</a>` : `See all your events from different ticketing platforms. <a href='https://audiencerepublic.com/events' target='_blank' style='color: #FFF;'>Learn more</a>`"
      :button-text="$arMediaQuery.pageContent.minWidth('sm') ? `Upgrade Plan` : `Upgrade`"
      @click="goToPlans"
      :size="$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'medium'"
      v-ar-sticky-top="{
        priority: 1,
      }"
    />

    <div
      class="events"
      :class="[
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      ]"
    >
      <div
        :class="[
          'events-page-top',
          $arMediaQuery.window.minWidth('md') && 'window-md-min',
        ]"
      >
        <am2-top
          :dropdown="dropdown"
          :button="{
            action: () => { navigateToNewEventCreation() },
            text: $arMediaQuery.pageContent.minWidth('sm') ? 'Create event' : 'Create',
            iconName: 'circle-plus',
            iconDistance: '9px',
            type: 'split',
            classes: ['top-create-button'],
            options: [
              {name: 'Mass import', action: () => { toggleMassImport() }},
            ],
          }"
          :search="{ action: serverSideSearch, value: queryParams.searchString, placeholder: searchPlaceholderText }"
          title="Events"
          :class="[
            'message-listing-hero-container',
            $arMediaQuery.window.minWidth('md') && 'window-md-min',
          ]"
          :dropdown-style="{ width: '240px' }"
        />
      </div>

      <!-- TEMPORARILY HIDE TICKET STATS BAR AND SHOW HORIZONTAL LINE -->
      <ar-divider
        :style="{
          marginTop: $arMediaQuery.window.minWidth('md') ? '30px' : '22px',
        }"
      />
      <am2-card-container v-if="false">
        <am2-overview-bar
          v-if="$arMediaQuery.pageContent.minWidth('sm')"
          :values="[
            {
              label: 'Ticket Sales',
              type: 'CURRENCY',
              amount: promoterStats && promoterStats.ticketSales,
              percentChange: promoterStats && promoterStats.percentTicketSalesIncrease,
            },
            {
              label: 'Tickets Sold',
              type: 'NUMBER',
              amount: promoterStats && promoterStats.ticketNumber,
              percentChange: promoterStats && promoterStats.percentTicketNumberIncrease,
            },
            {
              label: 'Upcoming Events',
              type: 'NUMBER',
              amount: promoterStats && promoterStats.upcoming.events,
            },
          ]"
          :dropdown="dropdownStatsFilter"
          :loading="isFetchingPromoterStats"
          title="Ticket sales"
          extracontent
        >
          <template slot="extracontent_graph">
            <div>
              <am2-multiple-line-charts
                title="Total Ticket Sales"
                hide-title
                hide-switch-buttons
                :loading="isFetchingPromoterStats"
                :topic-index="0"
                :topics="ticketSalesTimeSeriesData.topics"
                :chart-datas="ticketSalesTimeSeriesData.chartDatas"
                :style="{
                  height: '400px',
                }"
              />
            </div>
          </template>
        </am2-overview-bar>
      </am2-card-container>

      <div
        v-if="!noFilteredEvents"
        :class="[
          'events-controls',
          $arMediaQuery.pageContent.maxWidth('sm') && 'md-max',
          $arMediaQuery.pageContent.maxWidth('xs') && 'sm-max',
          $arMediaQuery.window.minWidth('md') && 'window-md-min',
        ]"
      >
        <div class="overall-details"><span v-if="eventsCount">Showing <b>{{ filteredEvents.length }}</b> {{ filteredEvents.length > 1 ? ' events' : ' event'}}</span></div>
        <div :class="['events-controls__subcontrol', $arMediaQuery.pageContent.maxWidth('sm') && 'md-max', $arMediaQuery.pageContent.maxWidth('xs') && 'sm-max']">
          <ar-simple-select
            class="order-dropdown"
            :items="dropdownOrderByItems.items"
            :default-select-index="eventOrderSelectedIndex"
            :style="{
              width: '200px',
            }"
            :dropdown-style="{
              padding: '8px 0'
            }"
            @select="handleDropdownOrderByItemsSelect"
          />

          <am2-button-group-tabs
            v-if="$arMediaQuery.pageContent.minWidth('sm')"
            :active-index="queryParams.showAs === 'list' ? 1 : 0"
            :items="groupButtonsSelectItems"
            :style="{
              width: '100px',
            }"
            @select="handleGroupButtonSelectClick"
            data-test-id="list-type-tabs"
          />
        </div>
      </div>

      <div
        :style="{
          marginTop: $arMediaQuery.pageContent.minWidth('sm') ? '28px' : '22px',
        }"
        class="events-container"
        :class="queryParams.showAs === 'tiles' ? 'tiles' : 'list'"
      >
        <am2-no-content-section
          v-if="noFilteredEvents && hasSearchConditions"
          header="No events found"
          header-size="sm"
          :style="{
            width: '100%',
          }"
        />
        <div
          v-else-if="noFilteredEvents"
          class="no-events-wrapper"
        >
          <am2-no-content-section
            :class="[
              'no-event-section',
              $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
              $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
            ]"
            icon-name="ar-form-in-circle"
            header="Create RSVP page"
            body="Capture registrations for your physical or virtual event"
            button-text="Create RSVP Page"
            :button-props="{
              iconName: null,
            }"
            @buttonClick="navigateToNewEventCreation"
          >
            <am2-tag
              class="free-tag"
              slot="top-right"
              text="Free"
              text-size="xxxs"
              shape="rectangle"
              type="dark-green"
            />
            <div class="footer-section" slot="footer">
              <div
                :class="[
                  'imgs-wrapper',
                  $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
                ]"
              >
                <div class="img-wrapper"><img src="~/assets/images/third-party-logos/zoom.png" alt="Zoom"></div>
                <div class="img-wrapper clubhouse"><span class="emoji">👋</span><img src="~/assets/images/third-party-logos/clubhouse.png" alt="Clubhouse"></div>
                <div class="img-wrapper vimeo"><img src="~/assets/images/third-party-logos/vimeo.png" alt="Vimeo"></div>
                <div class="img-wrapper"><img src="~/assets/images/third-party-logos/youtube.png" alt="Youtube"></div>
                <div class="img-wrapper"><img src="~/assets/images/third-party-logos/twitch.png" alt="Twitch"></div>
              </div>
            </div>
          </am2-no-content-section>
          <am2-no-content-section
            :class="[
              'no-event-section',
              $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
              $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
            ]"
            icon-name="ar-event-in-circle"
            header="Connect your ticketing platforms"
            body="Sync all your events, attendees & orders so they’re in one place"
            button-text="Setup integrations"
            :button-props="{
              iconName: null,
            }"
            @buttonClick="navigateToIntegrations"
          >
            <am2-tag
              slot="top-right"
              text="Free"
              text-size="xxxs"
              shape="rectangle"
              type="dark-green"
              :style="{
                width: '45px',
              }"
            />
          </am2-no-content-section>
        </div>
        <div
          v-else
          :class="[
            'cards-container',
            $arMediaQuery.pageContent.minWidth('sm') ? queryParams.showAs : 'tiles',
            $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
            $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
          ]"
        >
          <am2-event-card
            v-for="event in filteredEvents"
            :key="event.oid"
            :event="event"
            :show-as="setShowAs"
            @delete="handleDeleteEvent"
            @merge="handleEventMerge"
            @ticketSalesDelete="handleTicketSalesDelete"
            :integration-is-possible="eventCanMerge(event)"
          />
          <am2-card-skeleton
            v-for="n in isFetchingEvents ? 6 : 0"
            :key="n"
            class="base-plan-card"
            :class="[
              $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            ]"
            type="event"
            :show-as="$arMediaQuery.pageContent.minWidth('sm') ? queryParams.showAs : 'tiles'"
            :level="$arMediaQuery.pageContent.maxWidth('xs') || queryParams.showAs === 'list' ? (2 * n - 1) : (2 * Math.ceil(n / 3) - 1)"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import accounting from 'accounting';
import { isSyncing, isMerging } from '@/utils/event';
import { clone } from '@/utils/helpers';
import { setInterval, clearInterval } from 'timers';
import merge from 'deepmerge';

const defaultQueryParams = {
  orderBy: 'datecreated',
  filter: 'all',
  showAs: 'tiles',
  top: 12
}

// helper anonymous function for deepmerge so arrays aren't combined
// when doing an object merge
const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

export default {
  title: 'Events',
  layout: 'default',

  data() {
    return {
      isFetchingPromoterStats: false,
      promoterStats: null,
      events: [],
      eventsCount: 0,
      eventTicketStatsTimePeriod: 7,
      isFetchingEvents: false,
      showNotification: false,
      genericImport: false,
      searchString: '',
      skip: 0,
      queryParams: defaultQueryParams,
      eventOrderByItems:
        [
          {
            name: 'Recently created',
            key: 'datecreated',
            action: (item, index) => this.setEventsOrderBy(item.key, index),
          },
          {
            name: 'Event date (recent first)',
            key: 'eventstartdateasc',
            action: (item, index) => this.setEventsOrderBy(item.key, index),
          },
          {
            name: 'Event date (old first)',
            key: 'eventstartdatedesc',
            action: (item, index) => this.setEventsOrderBy(item.key, index),
          },
          {
            name: 'Alphabetical order',
            key: 'alphabetically',
            action: (item, index) => this.setEventsOrderBy(item.key, index),
          },
        ],
      eventPoller: null,
      groupButtonsSelectItems: [
        {
          iconName: 'tile-view',
          showAs: 'tiles',
        },
        {
          iconName: 'list-view',
          showAs: 'list',
        },
      ],
    }
  },

  created() {
    this['segment/RESET_SCRATCH_SEGMENT']();
  },

  computed: {
    setShowAs() {
      return this.$arMediaQuery.pageContent.maxWidth('xs') ? 'tiles' : this.queryParams.showAs
    },
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      eventHasIntegration: 'event/eventHasIntegration',
    }),
    noFilteredEvents() {
      return !this.isFetchingEvents && this.filteredEvents.length === 0;
    },

    hasSearchConditions() {
      return this.queryParams.filter !== 'all' || !!this.queryParams.searchString;
    },

    // TODO - Confirm if we need this computed prop
    eventsCountCopy() {
      return accounting.format(this.eventsCount);
    },

    searchPlaceholderText() {
      return this.eventsCount && this.eventsCount !== 1 ? `Search ${this.formattedTotalEventsCount} events` : `Search events`;
    },

    renderedEventsCount() {
      return this.events.length;
    },


    formattedTotalEventsCount() {
      return this.eventsCount ? accounting.format(this.eventsCount) : 0;
    },

    ticketSalesTimeSeriesData() {
      if (!this.promoterStats) {
        return {};
      }

      const hasChartDatas = this.promoterStats.ticketSalesTimeSeries && this.promoterStats.ticketSalesTimeSeries.length !== 0;
      let defaultDatas = [];

      // No data ? let's build our default state data and render the chart
      if (!hasChartDatas) {
        const defaultStartTime = moment(this.promoterStats.startDate).toDate().getTime();
        for (let i = 0; i < 7; i += 1) {
          defaultDatas.push({ ts: defaultStartTime + (i * 86400 * 1000), value: 0 });
        }
      }

      // Add day data with 0 until we get as many datas as this.eventTicketStatsTimePeriod
      const chartDatas = hasChartDatas ? this.promoterStats.ticketSalesTimeSeries : defaultDatas;
      if (chartDatas.length < this.eventTicketStatsTimePeriod) {
        const firstDateTime = moment(chartDatas[0].ts).toDate().getTime();
        const numberOfDatasMissing = this.eventTicketStatsTimePeriod - chartDatas.length;
        for (let i = 0; i < numberOfDatasMissing; i += 1) {
          chartDatas.unshift({ ts: firstDateTime - ((i + 1) * 86400 * 1000), value: 0 })
        }
      }

      return {
        topics: [
          {
            name: 'Sales',
            valueGetters:[{
              name: 'Default',
              getter: ({ ts, value }) => (
                [moment(ts).toDate().getTime(), value]
              ),
            }],
            format: '${value}',
            yAxisLabelFormatter(value) {
              if (!hasChartDatas || value < 100) { // Might get small value if it's not in unit of cent
                return `${accounting.formatMoney(value, { precision: 0 })}`;
              }
              let dollars = value / 100; // Might get small value if it's not in unit of cent

              if (dollars >= 1000000) {
                return `${accounting.formatMoney(dollars / 1000000, { precision: dollars % 1000000 === 0 ? 0 : 1 })}m`;
              } else if (dollars >= 1000) {
                return `${accounting.formatMoney(dollars / 1000, { precision: dollars % 1000 === 0 ? 0 : 1  })}k`;
              }
              return accounting.formatMoney(dollars, { precision: 0 })
            },
            yAxisMinRange: 20,
            xAxisLabelFormatter(value) {
              return moment(value).format("D MMM");
            },
            hasNavigator: false,
            hasYLabel: true,
            hasDataLabels: false,
            toolkitLabelFormatter({ ts }) {
              return `Total sales at ${moment(ts).format('D. MMM')}`;
            },
            toolkitValueFormatter({ value }) {
              const valueText = Number.isInteger(value) ? accounting.formatMoney(value / 100, { precision: 0 }) : '';
              return `${valueText}`
            },
          },
        ],
        chartDatas,
      };
    },

    infiniteScrollDisabled() {
      return this.events.length === this.eventsCount;
    },

    eventOrderSelectedIndex() {
      return this.eventOrderByItems.findIndex(item => item.key === (this.$route.query.orderBy || 'datecreated'));
    },

    eventsUndergoingActions () {
      return this.events.some(e =>
        this.eventHasIntegration(e, 'eventbrite') &&
        (isSyncing(e) || isMerging(e))
      );
    },

    dropdown() {
      return {
        title: 'All events',
        activeItemKey: this.queryParams.filter || 'all',
        items: [
          {
            name: 'All events',
            key: 'all',
            action: () => this.setEventFilter('all'),
          },
          {
            name: 'Upcoming events',
            key: 'upcoming',
            action: () => this.setEventFilter('upcoming'),
          },
          {
            name: 'Past events',
            key: 'previous',
            action: () => this.setEventFilter('previous'),
          },
          {
            type: 'divider',
            key: 'divider'
          },
          {
            name: 'Eventbrite',
            key: 'eventbrite',
            action: () => this.setEventFilter('eventbrite'),
          },
          {
            name: 'Zoom',
            key: 'zoom',
            action: () => this.setEventFilter('zoom'),
          },
          {
            name: 'Universe',
            key: 'universe',
            action: () => this.setEventFilter('universe'),
          },
          {
            name: 'Moshtix',
            key: 'moshtix',
            action: () => this.setEventFilter('moshtix'),
          },
          {
            name: 'Eventix',
            key: 'eventix',
            action: () => this.setEventFilter('eventix'),
          },
        ],
      };
    },

    dropdownOrderByItems() {
      const currentOrderByItem = this.dropDownOrderBySelectedItem()

      return {
        title: currentOrderByItem.name,
        items: this.eventOrderByItems,
      };
    },

    dropdownStatsFilter() {
      return {
        title: 'Timeframe',
        filterKey: this.eventTicketStatsTimePeriod,
        items: [
          {
            name: 'Last 7 days',
            key: 7,
            action: () => this.setPromoterStatsFilter(7),
          },
          {
            name: 'Last 14 days',
            key: 14,
            action: () => this.setPromoterStatsFilter(14),
          },
          {
            name: 'Last 30 days',
            key: 30,
            action: () => this.setPromoterStatsFilter(30),
          },
          {
            name: 'Last 60 days',
            key: 60,
            action: () => this.setPromoterStatsFilter(60),
          },
          {
            name: 'Last 90 days',
            key: 90,
            action: () => this.setPromoterStatsFilter(90),
          },
        ],
      };
    },

    filteredEvents() {
      if (this.filter === 'upcoming') {
        return this.events.filter(event => moment(event.startDate).isAfter(moment(new Date().toISOString())))
      } else if (this.filter === 'previous') {
        return this.events.filter(event => moment(moment(new Date().toISOString())).isAfter(event.startDate))
      } else if (this.filter === 'eventbrite') {
        return this.events.filter(event => this.eventHasIntegration(event, 'eventbrite'))
      } else if (this.filter === 'zoom') {
        return this.events.filter(event => this.eventHasIntegration(event, 'zoom'))
      } else if (this.filter === 'universe') {
        return this.events.filter(event => this.eventHasIntegration(event, 'universe'))
      } else if (this.filter === 'moshtix') {
        return this.events.filter(event => this.eventHasIntegration(event, 'moshtix'))
      } else if (this.filter === 'eventix') {
        return this.events.filter(event => this.eventHasIntegration(event, 'eventix'))
      } else {
        return this.events;
      }
    },
  },

  async mounted() {
    if (Object.keys(this.$route.query).length > 0) {
      this.queryParams = { ...this.$route.query };

      // any top value less than our default may cause an
      // unnecessary amount of extra FETCH_EVENTS actions to
      // be called; or worse, none at all.
      if (this.$route.query.top < defaultQueryParams.top) {
        this.queryParams.top = defaultQueryParams.top;
      }
    }

    this.fetchPromoterTicketStats({});
    await this.fetchEvents({});
  },

  beforeDestroy() {
    if(this.eventPoller !== null) {
      clearInterval(this.eventPoller);
    }
  },

  methods: {
    ...mapActions([
      'FETCH_EVENTS',
      'FETCH_PROMOTER_TICKET_STATS',
      'OPEN_IMPORT_EVENT_DATA_MODAL',
    ]),

    ...mapMutations([
      'segment/RESET_SCRATCH_SEGMENT',
    ]),

    setQueryParams(newParams) {
      let newQueryParams = clone({
       ...this.queryParams,
       ...newParams
      });

      for(let k in newQueryParams) {
        if(!newQueryParams[k]) {
          delete newQueryParams[k]
        }
      }

      this.queryParams = newQueryParams
        
      // the below catch err stops a navigation
      // console error being thrown in the terminal.
      // this is a bug with vue router that is
      // currently being fixed. (Mar '21)g
      this.$router.push({
        query: {
          ...this.queryParams
        }
      }).catch(err => {})
    },

    eventCanMerge (event) {
      // Since not all eb events are in view, just use this as the condition for merging.
      //
      // TODO: We should add some vuex getters to determine whether or not a promoter has
      // connected to some integrations. This could be done by adding an 'integrations'
      // key to the session API endpoint response which would contain a list of all
      // connected integrations.
      return !(isSyncing(event) || isMerging(event));
    },

    async fetchEvents({ append = false, skip = undefined, inPlaceUpdate = false }) {
      if (this.isFetchingEvents)
        return;

      this.isFetchingEvents = true;

      const { rows, count } = await this.FETCH_EVENTS({
        ...this.queryParams,
        ...skip ? { skip } : null
      });

      const events = rows;
      const eventsCount = count;

      if (append) {
        this.events = [...this.events, ...events];
      } else if (inPlaceUpdate) {
        // Instead of doing a straight rewrite of the events state,
        // we should be doing in-place merges. This will prevent
        // vue from destroying entire objects unnecessarily.

        // Object destruction causes unnecessary re-rendering of elements
        // and jumpy scrolling.
        events.forEach((event, index) => {
          const stateEventIndex = this.events.findIndex(renderedEvent => renderedEvent.oid === event.oid);

          if (stateEventIndex === -1) {
            this.events.splice(index, 0, event);
          } else {
            this.events[stateEventIndex] = merge(
              this.events[stateEventIndex],
              event,
              { arrayMerge: overwriteMerge }
            );
          }
        });

        // remove all the events that DON'T appear in our FETCH_EVENTS response
        const stateEventOids = this.events.map(e => e.oid);
        const removeTheseOids = stateEventOids.filter(oid => events.findIndex(apiEvent => apiEvent.oid === oid) === -1);
        removeTheseOids.forEach(oid => {
          const stateEventIndex = this.events.findIndex(renderedEvent => renderedEvent.oid === oid);
          this.events.splice(stateEventIndex, 1);
        });
      } else {
        this.events = [];
        this.events = events;
      }

      this.eventsCount = eventsCount;

      this.isFetchingEvents = false;

      if (this.eventPoller !== null && !this.eventsUndergoingActions) {
        clearInterval(this.eventPoller);
        this.eventPoller = null;
      } else if (this.eventPoller === null && this.eventsUndergoingActions) {
        // this condition can happen from a
        // REFRESH action fired from a different component
        this.pollEvents();
      }
    },

    async fetchPromoterTicketStats({ timePeriod }) {
      try {
        this.isFetchingPromoterStats = true;
        this.promoterStats = await this.FETCH_PROMOTER_TICKET_STATS({ timePeriod });
      } catch(e) {
        console.error(e);
        this.$arNotification.push({ type: 'error', message: 'Failed to fetch ticket stats.' });
      } finally {
        this.isFetchingPromoterStats = false;
      }
    },

    // Remember to reload the events after you change the filter type
    setEventFilter(filter) {
      this.setQueryParams({
        filter
      });

      this.filter = filter;
      this.fetchEvents({});
    },

    pollEvents() {
      if (this.eventPoller) {
        clearInterval(this.eventPoller);
      }

      this.eventPoller = setInterval(() => {
        this.fetchEvents({ append: false, skip: undefined, inPlaceUpdate: true });
      }, 3000);
    },

    async handleDeleteEvent(eventOid) {
      const deletedEventIndex = this.events.findIndex(e => e.oid === eventOid);

      // NOTE: eventOid will ALWAYS be in the events list since this event handler
      //       is triggered by a node in the events list. Also count will never be 0
      //       because then this handler will never be rendered on the page.
      this.events.splice(deletedEventIndex, 1);
      this.eventsCount -= 1;
    },

    handleEventMerge() {
      this.fetchEvents({});
    },

    handleTicketSalesDelete() {
      this.setQueryParams({
        top: null,
      });
      this.fetchEvents({});
    },

    dropDownOrderBySelectedItem() {
      return this.eventOrderByItems.filter(item => item.key === this.queryParams.orderBy)[0];
    },

    handleDropdownOrderByItemsSelect(item) {
      this.setEventsOrderBy(item.key);
    },

    async setEventsOrderBy(orderBy, index) {

      this.setQueryParams({
        orderBy
      });

      this.fetchEvents({});
    },

    async loadMoreEvents() {
      await this.fetchEvents({ append: true, skip: this.events.length });
    },

    async serverSideSearch(searchString) {

      this.setQueryParams({
        top: null,
        searchString,
      });

      await this.fetchEvents({})
    },

    navigateToNewEventCreation() {
      this.$router.push({ path: '/events/new' });
    },

    navigateToIntegrations() {
      this.$router.push({ path: '/settings/integrations' });
    },

    async setPromoterStatsFilter(timePeriod) {
      this.eventTicketStatsTimePeriod = timePeriod;
      this.fetchPromoterTicketStats({ timePeriod });
    },

    toggleMassImport() {
      this.OPEN_IMPORT_EVENT_DATA_MODAL({
        isMassImport: true,
      });
    },

    handleGroupButtonSelectClick(item) {
      this.setQueryParams({
        showAs: item.showAs,
      });
    },

    async goToPlans() {
      this.$router.push({
        path: `/settings/subscriptions`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.events-wrapper {
  margin: 0 auto;
  max-width: 1260px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 0 30px 100px;

  &.sm-max {
    padding: 0 30px 30px;
  }
  
  .events {
    width: 100%;
  }

  .events-container {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  .no-events-wrapper {
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: -20px;

    .no-event-section {
      width: calc(50% - 20px);
      margin-right: 20px;
      margin-bottom: 20px;

      &.lg-max {
        width: calc(100% - 20px);
      }

      &.xs-max {
        min-width: unset;
      }

      .free-tag {
        width: 45px;
      }

      .footer-section {
        max-width: 450px;
        margin: 0 auto;

        .imgs-wrapper {
          display: flex;
          justify-content: center;
          margin-right: -28px;
          margin-bottom: -15px;
          &.xs-max {
            flex-wrap: wrap;

            .img-wrapper {
              margin-bottom: 15px;
            }
          }
          .img-wrapper {
            position: relative;
            opacity: 0.2;
            margin-right: 28px;
  
            &.clubhouse {
              display: flex;
              align-items: center;
              position: relative;
              top: -5px;
  
              .emoji {
                position: relative;
                margin-right: 5px;
                top: -2px;
              }
            }
  
            &.vimeo {
              top: -3px;
            }
          }
        }
      }
    }
  }

  .cards-container.tiles {
    display: grid;
    grid-template-columns: 380px 380px 380px;
    grid-gap: 30px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;

    @media screen and (max-width: 1350px) {
      grid-template-columns: 380px 380px;
      grid-gap: 30px;
    }

    &.md-max {
      grid-template-columns: 360px 360px;
      grid-gap: 20px 12px;

      .event-card {
        min-width: 360px;
        width: 360px;
      }

      .base-plan-card {
        width: 360px;
      }
    }

    &.sm-max {
      grid-gap: 12px;
      grid-gap: 20px;
      grid-template-columns: 100%;
    }

    &.xs-max {
      grid-gap: 18px;
      grid-template-columns: 100%;
    }

    .base-plan-card {
      width: 380px;
    }
  }

  .cards-container.list {
    width: 100%;

    /deep/ .event-card {
      margin-bottom: 10px;
      width: 100%;
    }

    .base-plan-card {
      height: 100px;
      margin-bottom: 10px;

      &.md-max {
        height: 112px;
      }
    }
  }

  .is-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .events {
    &-page-top {
      margin: 30px 0 12px;
      // We want to use the media query here because we don't want the margin to change when filter pane is opened
      &.window-md-min {
        margin: 50px 0 15px 0;
      }
    }
    &-controls {
      display: flex;
      justify-content: space-between;
      margin-top: 4px;

      // We want to use the media query here because we don't want the margin to change when filter pane is opened
      &.window-md-min {
        margin-top: 30px;
      }
    
      &.md-max {
        margin-top: 0px;
        margin-bottom: 20px;
      }

      & .dropdown {
        flex: 2 1 auto;
        text-align: right;
        margin-right: 8px;
      }
      .overall-details {
        position: relative;
        font-weight: 500;
        margin-top: 10px;
      }

      &__subcontrol {
        display: inherit;
        justify-content: inherit;

        .order-dropdown {
          background: white;
          width: 260px;
          margin-right: 8px;
          border: 1px solid $blueGrey500;
        }

        &.sm-max {
          .order-dropdown {
            width: 100%;
            margin-right: 0;
          }
        }

        &.md-max {
          margin-top:10px;
        }
      }

      &.md-max {
        flex-direction:column;
        .overall-details {
          margin-top: 12px;
        }
      }
      &.sm-max {
        .overall-details {
          margin-top: 6px;
        }
      }
    }
    &-wrapper {
      margin-top: 27px;
    }
  }
  .loading-section {
    position: relative;
    &.hide {
      opacity: 0;
    }

    .loading-icon {
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
}

.message-listing-hero-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start !important;
  justify-content: space-between;
  margin-bottom: 20px !important;
}
</style>
