<template>
  <div class="sms-recipient-dropdown-wrapper">
    <DropdownButton
      data-attr="trigger-register-campaign-dropdown"
      :options="options"
      v-model="selected"
    />
  </div>
</template>
<script>
import DropdownButton from '../utils/DropdownButton.vue'
import { mapActions } from 'vuex'
export default {
  name: 'CampaignsDropdown',
  components: {
    DropdownButton
  },
  props: {
    selectedCampaign: {
      type: Object,
      default: null
    }
  },
  model: {
    prop: 'selectedCampaign',
    event: 'selection'
  },
  data() {
    return {
      options: null,
      selectedOption: { key: 'any', name: 'Any campaign' },
    }
  },
  watch: {
    selectedCampaign: {
      immediate: true,
      handler() {
        if (this.selectedCampaign) {
          this.selectedOption = this.selectedCampaign
        }
      }
    }
  },
  computed: {
    selected: {
      get: function() {
        return this.selectedOption
      },
      set: function(val) {
        this.$emit('selection', val)
      }
    },
  },
  methods: {
    ...mapActions({
      fetchCampaigns: 'FETCH_CAMPAIGNS',
    }),
    handleSelected(option) {
      this.$emit('selection', option)
      this.selectedOption = option
    },
    async fetchDropdownCampaigns() {
      let { rows } = await this.fetchCampaigns({})
      
      this.options = rows.map(item => {
        return {
          oid: item.oid,
          name: item.name,
        }
      })
    }
  },
  mounted() {
    this.fetchDropdownCampaigns()
  }
}
</script>