<template>
  <am2-link-button-dropdown
    :items="decoratedItems"
    :item-key="selectKey"
    has-search
    placeholder="Select provider"
    :dropdown-style="{
      width: '280px',
    }"
    search-placeholder="Search or add provider"
    @select="handleItemSelect"
    @searchStringChange="handleSearchStringChange"
  />
</template>
<script>
export default {
  name: 'ProviderChooser',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    defaultSelectedIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      searchString: '',
    };
  },
  computed: {
    selectKey() {
      if (this.defaultSelectedIndex === -1) {
        return null;
      }
      if (!this.items[this.defaultSelectedIndex]) {
        return null;
      }
      return this.items[this.defaultSelectedIndex].key;
    },
    decoratedItems() {
      const hasExactMatchItem = this.items.some(item => {
        return item.name.toLowerCase() === this.searchString.toLowerCase();
      });
      const filteredItems = this.items.filter(item => {
        return item.name.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1;
      });
      if (!hasExactMatchItem && this.searchString) {
        return [
          {
            type: 'action',
            name: `Create ${this.searchString}`,
            value: 'createProvider',
          },
          ...filteredItems,
        ];
      } else {
        return filteredItems;
      }
    },
  },
  methods: {
    handleSearchStringChange(newSearchString) {
      this.searchString = newSearchString;
    },
    createProvider(name) {
      this.dialogOpen = false;
      this.$emit('create-provider', name);
    },
    handleItemSelect(item, index) {
      if (item.type === 'action') {
        if (item.value === 'createProvider') {
          this.createProvider(this.searchString);
        }
      } else {
        this.dialogOpen = false;
        this.$emit('select', item, index);
      }
    },
  },
}
</script>

