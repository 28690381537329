/**
 * FETCH_EVENTS
 *
 * @param { object } context - Store context (state, rootstate, getters, commit, dispatch)
 * @param { object } payload
 * @param { number } payload.top
 * @param { number } payload.skip
 * @param { string } payload.searchString
 */

const defaultSelectString = 'name,description,campaigns,meta,capacity,location,startDate,endDate,timeZone,tourOid,presentation,resources,ticket-stats,paymentInfo,provider'

export async function FETCH_EVENTS({ state, commit }, { orderBy = null, top = 12, skip = 0, searchString = '', filter = '', selectString =  defaultSelectString}) {
  if (!state.auth.account) { return null; }
  const { promoterOid } = state.auth.account;

  let url = `/promoter/${promoterOid}/event?$select=${selectString}&$top=${top}&$skip=${skip}&$count=true`;

  const filterCriteria = ['userDefined'];
  if (searchString) {
    filterCriteria.push(
      encodeURIComponent(`name ILIKE "%${searchString}%"`)
    );
  }

  switch (filter) {
    case 'eventbrite':
      filterCriteria.push('provider=eventbrite');
      break;
    default:
      break;
  }

  if (filterCriteria.length > 0) {
    url += `&$filter=${filterCriteria.join(' AND ')}`;
  }

  switch (orderBy) {
    case 'datecreated': url += '&$orderby=sysCtime desc'; break;
    case 'eventstartdateasc': url += '&$orderby=startDate asc'; break;
    case 'eventstartdatedesc': url += '&$orderby=startDate desc'; break;
    case 'alphabetically': url += '&$orderby=name asc'; break;
    default: url += `&$orderby=oid desc`
  }

  try {
    const {
      data: { rows, count },
    } = await this.$axios.get(url);

    if (!rows.length) {
      commit('event/SET_HAS_NO_MORE_EVENTS', true)
    }

    return {
      rows,
      count,
    };
  } catch (error) {
    console.error('There was an error fetching all the events in FETCH_EVENTS: ', error);
  }
}
