import { AutomationResetState, UITrigger, UIAction, AutomationBox } from "./types";

export function depthFirstSearch(obj: AutomationBox, id: number | string): AutomationBox | null {
  if (obj.block!.id === id) {
    return obj
  }

  if (obj.children) {
    let i = obj.children.length
    while (i--) {
      let found = depthFirstSearch(obj.children[i], id)
      if (found) return found
    }
  }
  return null
}

// TODO: some of these functions may not be needed

export function getAutomationAction(actions: any[], id: number | string) {
  let found = null
  let i = actions.length
  while (i--) {
    let k = actions[i].length
    while (k--) {
      if (!actions[i][k].block) {
        continue
      } else {
        if (actions[i][k].block.id === id) {
          found = actions[i][k]
          return found
        }
      }
    }
  }

  return found
}

export function getActionBlock(boxes: any, id: number) {
  let found
  let i = boxes.length
  while (i--) {
    let j = boxes[i].length
    while (j--) {
      if (!boxes[i][j].id) {
        continue
      }
      if (boxes[i][j].block.id === id) {
        found = boxes[i][j].block
        return found
      }
    }
  }
  return found
}

export function getActionBox(boxes: any[], id: number) {
  let found = null
  let i = boxes.length
  while (i--) {
    if (boxes[i].id.self === id) {
      found = boxes[i]
      return found
    }
    let j = boxes[i].children.length
    while (j--) {
      found = depthFirstBoxSearch(boxes[i].children[j], id)
    }
  }

  return found
}

function depthFirstBoxSearch(boxObj: AutomationBox, id: number): AutomationBox | null {
  if (boxObj.id.self === id) {
    return boxObj
  }

  if (!!boxObj.children.length) {
    let i = boxObj.children.length
    while (i--) {
      let found = depthFirstBoxSearch(boxObj.children[i], id)
      if (found) return found
    }
  }

  return null
}

export async function getTriggers(data: any, promoterOid: number): Promise<any> {
  let api = window.$nuxt.$api
  let triggers: any = []
  let arr = Object.entries(data)
  let count = 0

  const addTriggers = new Promise((resolve, _) => {
    arr.forEach(async (val, _, array) => {

      try {
        let item: any = val[1]
        let trigger = defaultTrigger()
        trigger.id = item.oid,
          trigger.key = item.type.split('.').slice(1).join('.'),
          trigger.config = item.config

        switch (item.type) {
          case 'trigger.campaign.registered':
            trigger.name = 'Registers for campaign'
            trigger.icon = 'target'
            if (item.config?.['campaign-oid']) {
              let resp = await api.campaign.fetchCampaignForAutomation(promoterOid, item.config['campaign-oid'])
              trigger.meta = {
                name: resp.name
              }
            }
            triggers.push(trigger)

            break
          default:
            console.log('An unrecognised trigger type was received:', val)
            break
        }
      } catch (error) {
        console.log('Error creating trigger from api response:', error)
      } finally {
        count += 1
        if (count === array.length) resolve(true)
      }
    })
  })

  await addTriggers
  return triggers
}

export async function formattedAPIBox(box: any, promoterOid: number): Promise<any> {
  let api = window.$nuxt.$api
  let actionBox: AutomationBox

  try {
    let block = box.block
    let action = {
      ...defaultAction(),
      id: block.oid,
      key: block.type.split('.').slice(1).join('.'),
      config: block.config,
      parentOutBoxId: block.parentOutBoxId,
    }

    switch (block.type) {
      case 'action.tag.add':
        action.name = 'Add a Tag'
        action.icon = 'message'
        if (block.config?.['tag-oid']) {
          let tagResp = await api.tags.fetchTagByOid(promoterOid, block.config['tag-oid'])
          if (!!tagResp.length) {
            action.meta = {
              name: tagResp[0].name
            }
          }
        }
        break
      case 'action.send.sms':
        action.name = 'Send SMS'
        action.icon = 'message'
        break
      case 'action.send.email':
        action.name = 'Send email'
        action.icon = 'message'
        if (block.config?.['promoter-property-oid']) {
          const messageSender = await api.messageSenders.get(promoterOid, block.config['promoter-property-oid']);
          action.meta.messageSender = messageSender;
        }
        if (block.config?.['bucket-oid']) {
          const bucket = await api.buckets.fetch(block.config['bucket-oid']);
          action.meta.bucket = bucket;
        }
        break
      case 'control.if-else':
        action.name = 'If/Else'
        action.icon = 'branch'
        if (block.config?.['filter-group-oid']) {
          if (!block.config?.frontend?.segment) {
            let { filter } = await api.automation.getFilterGroup(promoterOid, block.config['filter-group-oid'])
            action.meta.filter = filter.conditions[0]
          }
        }
        break
      case 'control.wait-until':
        action.name = 'Wait until'
        action.icon = 'clock'
        if (action.config?.['delay-sec']) {
          action.meta = action.config.frontend
        }
        break
      default:
        action.icon = 'message'
        console.log('An unrecognised action type was received from the API: ', block)
    }

    actionBox = {
      ...box,
      block: action
    }

    return actionBox

  } catch (error) {
    console.log('Error creating action from api response:', error)
  }
}

export async function getActions(boxes: any, promoterOid: number, api: any): Promise<any> {
  let actions: any = []
  let count = 0

  const addActions = new Promise((resolve, _) => {
    boxes.forEach(async (item: any, _: any, array: any) => {

      try {
        let block = item.block
        let action = defaultAction()
        action.id = block.oid,
          action.key = block.type.split('.').slice(1).join('.')
        action.config = block.config
        action.parentOutBoxId = block.parentOutBoxId

        switch (block.type) {
          case 'action.tag.add':
            action.name = 'Add a Tag'
            action.icon = 'message'
            if (block.config?.['tag-oid']) {
              let tagResp = await api.tags.fetchTagByOid(promoterOid, block.config['tag-oid'])
              if (!!tagResp.length) {
                action.meta = {
                  name: tagResp[0].name
                }
              }
            }
            break
          case 'action.send.sms':
            action.name = 'Send SMS'
            action.icon = 'message'
            break
          case 'action.send.email':
            action.name = 'Send email'
            action.icon = 'message'
            break
          case 'control.if-else':
            action.name = 'If/Else'
            action.icon = 'branch'
            break
          default:
            action.icon = 'message'
            console.log('An unrecognised action type was received from the API: ', block)
        }

        actions.push({
          ...item,
          block: action
        })
      } catch (error) {
        console.log('Error creating action from api response:', error)
      } finally {
        count += 1
        if (count === array.length) resolve(true)
      }
    })
  })

  await addActions
  return actions
}
export function defaultAction(): UIAction {
  return {
    id: null,
    key: null,
    name: null,
    type: 'action',
    icon: null,
    parentOutBoxId: null,
    config: {},
    meta: {},
  }
}

export function defaultTrigger(): UITrigger {
  return {
    id: null,
    key: null,
    name: null,
    type: 'trigger',
    icon: null,
    config: {},
    meta: {},
  }
}

export function automationResetState(): AutomationResetState {
  return {
    id: null,
    name: null,
    status: null,
    rootBoxId: null,
    createdAt: null,
    triggers: {
      boxId: null,
      items: [],
    },
    actions: [],
    editableItem: null,
    loading: false,
    newActionIsDragging: false,
    dragOverId: null,
    draggedBox: null,
    selectedSidebarTab: 'triggers',
    zoomVal: 1,
    scrollVals: null,
    isScrolling: null,
    isFullScreen: false,
    actionConfigHasUpdated: null,
  }
}

export const runPromises = async (promiseObject: Object) => {
  let keys: any[] = []
  let promises: any[] = []

  Object.entries(promiseObject).forEach(([key, val]) => {
    keys.push(key)
    promises.push(val)
  })

  let data = await Promise.all(promises)
  let merged = data.reduce((obj, value, index) => ({ ...obj, [keys[index]]: value }), {})

  return merged
}