<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="ar-direct-purchase-modal"
      hide-header
      hide-footer
      width="400px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-5',
        ]"
      >
        <div
          :class="[
            'u-display-flex',
            'u-justify-content-center',
          ]"
        >
          <ar-icon
            v-bind="planIconProps"
          />
        </div>
        <ar-divider
          class="u-margin-y-8"
        />
        <div
          :class="[
            'title-section',
            'u-display-flex',
            'u-justify-content-space-between',
            'u-align-items-center',
          ]"
        >
          <ar-text
            size="md"
            :text="paymentPlanName"
            multiple-lines
            class="plan-name"
          />
          <ar-text
            size="md"
            :text="paymentPlanPrice"
          />
        </div>
        <div
          :class="[
            'u-display-flex',
            'u-justify-content-space-between',
            'u-align-items-center',
            'u-margin-top-1',
          ]"
        >
          <ar-text
            size="xs"
            :text="paymentPlanCaption"
            multiple-lines
            :style="{
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <ar-text
            size="xs"
            :text="priceModelCopy"
            :style="{
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
        </div>
        <ar-divider
          class="u-margin-y-8"
        />
        <ar-simple-button
          type="gradient-purple"
          text="Buy Now"
          class="u-width-100-percent"
          :loading="isPurchasingPlan"
          :disabled="isAddingPaymentSource"
          @click="handleBuyNowClick"
        />
        <am2-payment-source-section
          class="u-margin-top-6"
          :payment-source-puid="paymentSourcePuid"
          add-payment-source-on="expansion"
          @select="handlePaymentSourceSelect"
          @addPaymentSourceToggle="handleAddPaymentSourceToggle"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import accounting from 'accounting';
import { hexToRGBA } from '@/utils/helpers/';

export default {
  name: 'ChangePaymentSourceModal',

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    paymentPlan: {
      type: Object,
      default: () => null,
    },
    paymentSourcePuid: {
      type: String,
      default: null,
    },
    planQuantity: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      paymentSourceOid: null,
      isAddingPaymentSource: false,
      isPurchasingPlan: false,
    }
  },
  computed: {
    paymentPlanName() {
      if (!this.paymentPlan) return null;
      return this.paymentPlan.additionalInfo.invoiceName || this.paymentPlan.name || null;
    },
    paymentPlanPrice() {
      if (!this.paymentPlan) {
        return null;
      }
      let price;
      if (this.paymentPlan.type === 'base-or-add-on') {
        let targetTier;
        if (!this.planQuantity) {
          targetTier = this.paymentPlan.additionalInfo.tiers[0];
        } else {
          targetTier = this.paymentPlan.additionalInfo.tiers.filter(({ startingUnit, endingUnit }) => {
            return this.planQuantity >= startingUnit && this.planQuantity <= endingUnit;
          })[0];
        }

        price =  targetTier ? targetTier.price / 100 : 0;
      } else {
        price = this.paymentPlan.additionalInfo.price / 100;
      }
      return accounting.formatMoney(price, null, 0);
    },
    paymentPlanCaption() {
      return this.paymentPlan && this.paymentPlan.type === 'base-or-add-on' ?
        `Up to ${accounting.formatNumber(this.planQuantity)} contacts` : null;
    },
    priceModelCopy() {
      return this.paymentPlan ? `Per ${this.paymentPlan.billingPeriod} (${this.paymentPlan.currency})` : null;
    },
    planIconProps() {
      return {
        name: this.paymentPlan && this.paymentPlan.additionalInfo.metaData.presentation ? this.paymentPlan.additionalInfo.metaData.presentation.iconName : null,
        width: '30px',
        height: '30px',
        wrapperStyle: {
          display: 'inline-flex',
          alignItem: 'center',
          justifyContent: 'center',
          width: '60px',
          height: '60px',
          border: `1px solid ${this.$arStyle.color.skyBlueGrey400}`,
          boxShadow: `0 0 15px 0 ${hexToRGBA(this.$arStyle.color.blueGrey800, 0.1)}`,
          borderRadius: '50%',
          background: 'white',
        },
      };
    },
  },

  watch: {
    isShow: {
      handler(val) {
        if (val) {
          this.isAddingPaymentSource = false;
        }
      },
    },
  },

  created() {
    window.addEventListener('arModalOpen', this.handleModalOpen);
  },

  beforeDestroy() {
    window.removeEventListener('arModalOpen', this.handleModalOpen);
  },

  methods: {
    ...mapActions([
      'CREATE_PAYMENT_SUBSCRIPTION',
    ]),
    handlePaymentSourceSelect({ puid, oid }) {
      this.paymentSourcePuid = puid;
      this.paymentSourceOid = oid;
    },
    handleAddPaymentSourceToggle(val) {
      this.isAddingPaymentSource = val;
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
    async handleBuyNowClick() {
      try {
        this.isPurchasingPlan = true;
        await this.CREATE_PAYMENT_SUBSCRIPTION({
          planOid: this.paymentPlan.oid,
          paymentSourceOid: this.paymentSourceOid,
          planQuantity: this.planQuantity,
        });

        this.$arNotification.push({
          type: 'success',
          message: `Successfully subscribe to ${this.paymentPlanName}`,
        });
        this.$emit('purchase');
      } catch (error) {
        console.error(error);
        if(error.response && error.response.status === 422) {
          this.$arNotification.push({
            type: 'error',
            message: 'Your selected payment method has been declined. Please update it and try again',
          });
        } else {
          this.$arNotification.push({
            type: 'error',
            message: `Failed to subscribe to ${this.paymentPlanName}`,
          });
        }

      } finally {
        this.isPurchasingPlan = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-direct-purchase-modal {
  .modal-body {
    padding: 30px;

    .title-section {
      .plan-name {
        max-width: 50%;
      }
    }
  }
}
</style>
