import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { Campaign, CampaignRegistrationStats } from '@/types/resources/campaign';
import { isErrorStatus } from '../utils';
import { CustomApiError } from '../types';

export default (axios: NuxtAxiosInstance) => ({
  /**
   * Fetch Campaigns by Oids
   * @param promoterAccountOids
   * @returns
   */
  async fetchByOids(promoterOid: number, oids: number[]): Promise<Campaign[] | null> {
    if (oids.length === 0) {
      return [];
    }
    const { status, data } = await axios.get(`/promoter/${promoterOid}/campaign`, {
      params: {
        $select: 'name,type,notifyDate,startDate,endDate,timeZone,urlSlug,eventOid,event,rewards,summaryStatsSnapshot,presentation,resources,registrations,settings,socialActions',
        $top: 'all',
        $orderby: 'oid desc',
        $filter: `(oid=${oids.join(' OR oid=')}) AND type != "opt-in" AND type != "rsvp"`,
      },
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Fetch Camapigns with oids error',
        message: `Error fetching Camapigns with oids ${oids.join(', ')}`,
        status,
      };
      throw apiError;
    }
    return data;
  },

  async search(
    promoterOid: number,
    {
      top = 10,
      skip = 0,
      searchString = null,
      orderby = 'oid desc',
      select = 'name,type,notifyDate,startDate,endDate,timeZone,urlSlug,eventOid,event,rewards,summaryStatsSnapshot,presentation,resources,registrations,settings,socialActions',
    }: { top: number, skip: number, searchString: string | null, select: string, orderby?: string  },
  ): Promise<Campaign[]> {
    let filterString = 'type != "opt-in" AND type != "rsvp"';
    if (searchString) {
      filterString = `${filterString} AND name ILIKE "%${searchString}%"`;
    }
    const { data, status }: { data: Campaign[], status: number } = await axios.get(`/promoter/${promoterOid}/campaign`, {
      params: {
        $select: select,
        $top: top,
        $skip: skip,
        $orderby: orderby,
        $filter: filterString,
      },
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Fetch All Campaigns error',
        message: `Error fetching all Campaigns`,
        status,
      };
      throw apiError;
    }
    return data;
  },


  async fetchCampaignForAutomation(promoterOid: number, oid: number): Promise<any> {
    const { data, status } = await axios.get(`/promoter/${promoterOid}/campaign/${oid}`, {
      params: {
        $select: 'name',
      },
    })
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Fetch Camapign for Automation error',
        message: `Error fetching Camapigns for Automation with promoterOid ${promoterOid},\n
                  and campaign oid ${oid}.`,
        status,
      };
      throw apiError;
    }

    return data
  },

  async fetchCampaignRegistrationStats(promoterOid: number, oid: number): Promise<CampaignRegistrationStats | null> {
    if(isNaN(oid)) {
      console.error('FETCH_CAMPAIGN_REGISTRATIONS called with NaN campaignOid');
      return null;
    }

    const { data } = await axios.get(
      `/promoter/${promoterOid}/campaign-registration-stats-realtime/${oid}`
    );

    return data;
  }
});
