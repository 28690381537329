var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('RendererFrame',{attrs:{"is-readonly":!_vm.disableReadMode && _vm.isReadOnly,"title":_vm.filterCriteria.title,"filter-tooltip":_vm.filterTooltip,"subtitle":_vm.expectedConditionTitle,"readonly-items":_vm.readonlyItems,"apply-disabled":!_vm.isEditConditionComplete,"additional-constraints":_vm.filterCriteria.constraints,"condition-button-text":_vm.conditionButtonText,"hide-button":_vm.hideButton},on:{"confirm":_vm.handleConfirmClick,"cancel":_vm.handleCancelClick,"delete":_vm.handleDeleteClick,"openChangeMode":function($event){_vm.isReadOnly = false}}},[_c('div',{staticClass:"numeric-picker",attrs:{"slot":"content"},slot:"content"},[(_vm.criteriaHasMandatoryConstraints)?_c('AdditionalConstraintRenderer',{staticClass:"mandatory-constraints u-margin-bottom-2",attrs:{"filter-condition":_vm.filterCondition,"filter-constraints":_vm.filterCriteria.constraints,"mandatory-constraints":""},on:{"constraintUpdate":_vm.handleConstraintUpdate}}):_vm._e(),_vm._v(" "),(!_vm.criteriaHasMandatoryConstraints || _vm.criteriaHasFilledMandatoryConstraint)?_c('div',[_c('div',{staticClass:"select-section"},[_c('ar-simple-select',{staticClass:"select",attrs:{"items":_vm.numericTypeItems,"default-select-index":_vm.numericTypeSelectedIndex,"placeholder":("Select the " + (_vm.filterCriteria.title)),"data-test-id":"filter-predicate-selector"},on:{"select":_vm.handleNumericTypeSelect}})],1),_vm._v(" "),_c('div',{staticClass:"input-section"},[(_vm.editFilterCondition.data.condition === 'is_between')?_c('div',{staticClass:"two-input-section"},[_c('div',{staticClass:"small-input"},[(_vm.filterCriteria.type === 'currency')?_c('div',{staticClass:"currency"},[_c('ar-text',{attrs:{"text":"$","size":"xs"}})],1):_vm._e(),_vm._v(" "),_c('ar-input',{staticClass:"input",style:({
                borderTopLeftRadius: _vm.filterCriteria.type === 'currency' ? 0 : null,
                borderBottomLeftRadius: _vm.filterCriteria.type === 'currency' ? 0 : null,
              }),attrs:{"auto-focus":_vm.shouldAutoFocus,"value":_vm.filterCriteria.type === 'currency' ? _vm.editFilterCondition.data.values[0] / 100 : _vm.editFilterCondition.data.values[0],"placeholder":_vm.inputPlaceholder,"type":"number","min":0,"data-test-id":"filter-currency-input-1"},on:{"input":function($event){
              var i = arguments.length, argsArray = Array(i);
              while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleInputChange.apply(void 0, [ 0 ].concat( argsArray ))},"enter":_vm.handleEnterPress}})],1),_vm._v(" "),_c('div',{staticClass:"small-input"},[(_vm.filterCriteria.type === 'currency')?_c('div',{staticClass:"currency"},[_c('ar-text',{attrs:{"text":"$","size":"xs"}})],1):_vm._e(),_vm._v(" "),_c('ar-input',{staticClass:"input",style:({
                borderTopLeftRadius: _vm.filterCriteria.type === 'currency' ? 0 : null,
                borderBottomLeftRadius: _vm.filterCriteria.type === 'currency' ? 0 : null,
              }),attrs:{"value":_vm.filterCriteria.type === 'currency' ? _vm.editFilterCondition.data.values[1] / 100 : _vm.editFilterCondition.data.values[1],"placeholder":_vm.inputPlaceholder,"auto-focus":_vm.shouldAutoFocus,"type":"number","min":0,"data-test-id":"filter-currency-input-2"},on:{"input":function($event){
              var i = arguments.length, argsArray = Array(i);
              while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleInputChange.apply(void 0, [ 1 ].concat( argsArray ))},"enter":_vm.handleEnterPress}})],1)]):(_vm.editFilterCondition.data.condition !== 'is_known' && _vm.editFilterCondition.data.condition !== 'is_unknown')?_c('div',{staticClass:"big-input"},[(_vm.filterCriteria.type === 'currency')?_c('div',{staticClass:"currency"},[_c('ar-text',{attrs:{"text":"$","size":"xs"}})],1):_vm._e(),_vm._v(" "),_c('ar-input',{staticClass:"input",style:({
            borderTopLeftRadius: _vm.filterCriteria.type === 'currency' ? 0 : null,
            borderBottomLeftRadius: _vm.filterCriteria.type === 'currency' ? 0 : null,
          }),attrs:{"auto-focus":_vm.shouldAutoFocus,"value":_vm.filterCriteria.type === 'currency' ? _vm.editFilterCondition.data.values[0] / 100 : _vm.editFilterCondition.data.values[0],"placeholder":_vm.inputPlaceholder,"type":"number","data-test-id":"filter-currency-input"},on:{"input":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleInputChange.apply(void 0, [ 0 ].concat( argsArray ))},"enter":_vm.handleEnterPress}})],1):_vm._e()]),_vm._v(" "),(_vm.criteriaHasConstraints)?_c('AdditionalConstraintRenderer',{staticClass:"optional-constraints",attrs:{"filter-condition":_vm.filterCondition,"filter-constraints":_vm.filterCriteria.constraints},on:{"constraintUpdate":_vm.handleConstraintUpdate}}):_vm._e()],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }