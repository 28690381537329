<template>
  <MessageNav :menu="menu">
    <template slot="buttons">
      <am2-loading-section
        v-if="isFetchingMessageList"
        slot="controls"
        :class="$arMediaQuery.pageContent.maxWidth('sm') && 'md-max'"
        :style="{
          width: '128px',
          height: '50px',
        }"
      />
      <ar-simple-button
        v-else
        text="View message"
        :loading="isFetchingSavedEmailTemplate"
        :disabled="!currentSelectedMessage"
        data-test-id="view-message-button"
        @click="handleViewMessage"
      />
    </template> 
  </MessageNav>
</template>
<script>
import { mapState } from 'vuex';
import MessageNav from './message-nav.vue'
export default {
  name: 'MessageViewNav',
  components: {
    MessageNav
  },
  props: {
    menu: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      displayMessagePreviewModal: false
    }
  },
  computed: {
    ...mapState({
      isFetchingMessageList: state => state.messageList.isFetchingMessageList,
      isFetchingSavedEmailTemplate: state => state.message.isFetchingSavedEmailTemplate,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
    })
  },
  methods: {
    handleViewMessage() {
      this.$emit('display-message-modal')
    },
  }
}
</script>
