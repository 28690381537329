/**
 * FETCH_PROMOTER_TICKET_STATS
 *
 * @param { object } context - Store context (state, rootstate, getters, commit, dispatch)
 * @param { object } payload
 * @param { number } payload.timePeriod - number of days for range
 */
export async function FETCH_PROMOTER_TICKET_STATS({ state, commit }, { timePeriod = 7 }) {
  if (!state.auth.account) { return null; }
  const { promoterOid } = state.auth.account;

  const url = `/promoter/${promoterOid}/ticket-stats?$filter=timePeriod=${timePeriod}`;

  try {
    const { data } = await this.$axios.get(url);
    let stats = data;

    // this is needed until we can $filter by datetime
    if (Array.isArray(data)) {
      data.sort((a, b) => {
        if (a.oid < b.oid) {
          return -1;
        }

        if (a.oid > b.oid) {
          return 1;
        }

        return 0;
      });

      [stats] = data;
    }

    return stats;

  } catch (error) {
    console.error(
      'There was an error fetching all the events in UPDATE_PROMOTER_TICKET_STATS: ',
      error
    );
  }
}
