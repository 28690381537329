<template>
  <div>
    <!-- A modal for changing the columns in the table -->
    <am2-multi-select-modal
      v-if="columnSettings"
      title="Edit columns"
      :is-show="showColumnSwitchModal"
      :items="columnSettings.availableColumns"
      :value="columnSettings.selectedColumnKeys"
      @input="handleMultiSelectModalConfirm"
      @close="showColumnSwitchModal = false"
    />

    <div class="table-control-section">
      <!-- Displays a count of total items in the table, and the options button -->
      <div
        v-if="showCountAndOptionsBar"
        class="bar count-and-options-bar">
        <ar-text
          v-if="initialLoadFinished"
          size="sm"
          allow-html
          :text="fansCountText"
        />
        <am2-simple-button-dropdown
          v-if="dropdownItems.length > 0"
          class="dropdown-button"
          :button-props="{
              text: '...',
              type: 'grey',
              outlined: true,
              style: { height: '40px', width: '100%' }
            }"
          :items="dropdownItems"
          :dropdown-max-height="$arMediaQuery.window.maxWidth('xs') ? '50vh' : 'calc(100vh - 207px)'"
          :dropdown-style="{
              width: '200px',
            }"
          @select="actionSelect"
        />
      </div>


      <!-- Displays a filter button, a search bar, and the options button -->
      <div
        v-if="showFilterAndSearchBar"
        :class="[
          'bar',
          'filter-and-search-bar',
          $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        ]">

        <div class="circle-hooker item"
          v-if="showFilterButton"
        >
          <div v-if="filterIsNotEmpty" class="circle">
            <span>{{ prunedScratchSegment.filter.conditions.length  }}</span>
          </div>
          <!--
            v-if="showFilterButton" -->
          <ar-simple-button
            icon-name="filter-alt"
            side-length="40px"
            :icon-props="{
              height: '16px',
            }"
            text="Filter"
            :type="filterIsNotEmpty ? 'purple' : 'grey'"
            outlined
            class="filter-btn"
            :style="{
              height: '40px',
            }"
            @click="switchFilterSidebar"
            data-test-id="audience-table-filter-button"
          />
        </div>

        <am2-search
          v-if="fansSearch"
          class="item fans-search"
          :placeholder="searchPlaceholder"
          v-model="searchText"
          :style="{
            height: '40px',
          }"
          @enter="handleFansSearchEnter"
          @clear="handleFansSearchClear"
        />
        <am2-simple-button-dropdown
          v-if="dropdownItems.length > 0 && !showCountAndOptionsBar"
          class="item dropdown-button"
          :button-props="{
              type: 'grey',
              outlined: true,
              style: {
                height: '40px',
                width: '100%'
              },
              iconName: 'ellipsis',
              iconDistance: '0px',
              iconProps: {
                height: '4px',
                width: '18px',
                color: $arStyle.color.skyBlueGrey800,
              },
              customStyleAttributes: {
                padding: '0 10px',
              },
            }"
          :items="dropdownItems"
          :dropdown-max-height="$arMediaQuery.window.maxWidth('xs') ? '50vh' : 'calc(100vh - 207px)'"
          :dropdown-style="{
              width: '180px',
            }"
          @select="actionSelect"
        />
      </div>


      <!-- Displays the options set from the filter. Currently TBA -->
      <div
        v-if="showFilterOptionsBar && filterBlocks.length > 0"
        ref="filter-options-bar"
        class="bar filter-options-bar">
        <div
          class="scroller"
          ref="scroller"
          :style="{
            right: `${filterScrollerPosition}px`,
          }"
        >
          <am2-tag
            v-for="(block, blockIndex) in filterBlocks"
            class="filter-block"
            :key="blockIndex"
            :type="block.type === 'condition' ? 'purple' : 'grey'"
            shape="rectangle"
            :has-cross="block.type === 'condition'"
            :remove-button-style="{
              background: 'none',
              color: block.type === 'condition' ? $arStyle.color.purple500 : $arStyle.color.blueGrey700,
            }"
            :style="{
              padding: block.type === 'logic' ? '6px 8px' : null,
              cursor: block.type === 'logic' ? 'pointer' : null,
            }"
            @remove="() => handleRemoveFilter(block, blockIndex)"
            @click.native="() => handleLogicClick(block, blockIndex)"
            :text="block.text"
          />
        </div>
        <div
          v-if="filterScrollerPosition > 0"
          class="scroller-handle handle-left"
          @click="handleScrollerLeft"
        >
          <ar-icon
            name="slender-arrow"
            width="14px"
            :color="$arStyle.color.blueGrey500"
            :rotate="180"
          />
        </div>
        <div
          v-if="filterScrollerShowRightArrow"
          class="scroller-handle handle-right"
          @click="handleScrollerRight"
        >
          <ar-icon
            name="slender-arrow"
            width="14px"
            :color="$arStyle.color.blueGrey500"
            class="handle-arrow"
          />
        </div>
      </div>


      <!-- Displays options which interact with the selected items in the table -->
      <div
        v-if="showCheckedRowsOptionsBar"
        :class="[
          'bar',
          'checked-rows-options-bar',
          tickedFansCount !== 0 && 'is-visible',
          ]">

        <div class="u-display-flex">
          <div
            @click="handleSelectAllClick"
            class="selected-count-container u-margin-right-2">
            <ar-checkbox
              class="u-margin-right-2"
              ref="semiCheckbox"
              :isSemi="tickedFansCount !== totalAudienceCount && tickedFansCount > 0"
              :style="{
                pointerEvents: 'none',
              }"
              :value="useScratchSegment"
            />
            <ar-text
              :text="selectedCountText"
              size="xs"
            />
          </div>

          <am2-simple-button-dropdown
            v-if="checkedRowActions.length > 0 && $arMediaQuery.pageContent.maxWidth('md')"
            class="item dropdown-button u-margin-right-2"
            :button-props="{
              type: 'grey',
              outlined: true,
              text: $arMediaQuery.window.maxWidth('xs') ? 'Action' : 'Actions',
              textWeight: 'normal',
              style: { height: '30px', width: '100%' },
              iconName: 'arrow',
              iconSide: 'right',
              iconProps: {
                width: '9px',
                height: '5px',
                color: $arStyle.color.skyBlueGrey800,
              },
            }"
            :items="checkedRowActions"
            align="right"
            :dropdown-max-height="$arMediaQuery.window.maxWidth('xs') ? '50vh' : 'calc(100vh - 207px)'"
            :dropdown-style="{
              width: '210px',
            }"
            @select="actionSelect"
          />
          <div v-else>
            <ar-simple-button
              text="Add to list"
              outlined
              :style="{
                height: '30px',
              }"
              @click="handleAddFanToMessageList"
            />
            <am2-simple-button-dropdown
              :button-props="{
                text: 'Tags',
                outlined: true,
                style: {
                  height: '30px',
                }
              }"
              :dropdown-style="{
                width: '132px',
              }"
              :items="[
                {
                  name: 'Add',
                  action: handleTagAdd
                },
                {
                  name: 'Remove',
                  action: handleTagRemove
                }
              ]"
              @select="actionSelect"
            />
            <ar-simple-button
              text="Edit"
              outlined
              :style="{
                height: '30px',
              }"
              @click="handleFanEdit"
            />
            <ar-simple-button
              text="Delete"
              outlined
              :style="{
                height: '30px',
              }"
              @click="handleFanDelete"
            />
          </div>
        </div>


        <ar-link-button
          :text="$arMediaQuery.window.maxWidth('xs') ? 'Deselect' : 'Clear Selection'"
          has-underline
          color="purple"
          @click="handleDeselectAll"
        />
      </div>
    </div>
  </div>
</template>

<script>
/*
  This section sits on top of a table, and provides options, information and actions for interacting with the table
  in ways beyond regular table headers
 */
import { mapGetters, mapMutations, mapState } from 'vuex';
import accounting from 'accounting';

export default {
  name: 'TableControlSection',

  props: {
    showCountAndOptionsBar: { // This bar will show a count of selected items and the options button
      type: Boolean,
      default: false,
    },
    showFilterAndSearchBar: { // This bar will show a filter button, a search bar and the options button
      type: Boolean,
      default: false,
    },
    showFilterOptionsBar: { // This bar will show the filter options
      type: Boolean,
      default: false,
    },
    showCheckedRowsOptionsBar: { // This bar will show options for dealing with checked items in the table
      type: Boolean,
      default: false,
    },
    showFilterButton: { // Flag for showing the button which pops out the filter panel
      type: Boolean,
      default: true,
    },

    columnSettings: { // Object which manages columns
      type: Object,
      default: () => null,
    },
    csvExport: { // Object for managing CSV exporting
      type: Object,
      default: () => null,
    },
    fanAppellation: { // The type of fan (contact, registrant, etc)
      type: String,
      default: 'contact',
    },
    fansSearch: { // Object for managing the search capabilities
      type: Object,
      default: () => null,
    },
  },


  data: () => ({
    showColumnSwitchModal: false, // This modal, if shown, allows promoters to change columns in the table
    searchText: null, // Text to appear in search bar
    initialLoadFinished: false, // Will be permanently set to true once the loading prop is set to true
    filterScrollerPosition: 0,
    filterScrollerShowRightArrow: false,
  }),

  watch: {
    loading: {
      handler(val) {
        if (val !== true && !this.initialLoadFinished) {
          this.initialLoadFinished = true;
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      prunedScratchSegment: 'segment/prunedScratchSegment',
      getSegmentConditionExpression: 'segment/getSegmentConditionExpression',
      partlySelectedAudience: 'audience/partlySelectedAudience',
    }),
    ...mapState({
      displaySegmentDrawer: state => state.layout.displaySegmentDrawer,
      totalAudienceCount: state => state.audience.totalAudienceCount,
      totalOrdersCount: state => state.orders.totalOrdersCount,
      useScratchSegment: state => state.audience.audienceSelection.useScratchSegment,
    }),

    tickedFansCount() {
      if (this.useScratchSegment) {
        if (this.fanAppellation == "order") {
          return this.totalOrdersCount;
        } else {
          return this.totalAudienceCount;
        }
      }
      return this.partlySelectedAudience.length;
    },

    selectedCountText() {
      return this.$arMediaQuery.window.maxWidth('xs') ? accounting.formatNumber(this.tickedFansCount) : `${accounting.formatNumber(this.tickedFansCount)} selected`;
    },

    // Cleans up the fans count into a nice, human-readable string
    fansCountText() {
      const audienceCount =  (this.fanAppellation == "order") ? this.totalOrdersCount : this.totalAudienceCount

      if (!audienceCount || audienceCount === 0) {
        return `${this.fanAppellation}s`
      }
      if (!audienceCount === 1) {
        return `1 ${this.fanAppellation}`
      }
      return `${accounting.formatNumber(audienceCount)} ${this.fanAppellation}s`
    },

    // Provides a context-appropriate placeholder for the search bar
    searchPlaceholder() {
      if (!this.fanAppellation || this.$arMediaQuery.window.maxWidth('xs')) {
        return 'Search';
      }
      return `Search ${this.fansCountText}`
    },

    filterIsNotEmpty() {
      return this.prunedScratchSegment && this.prunedScratchSegment.filter.conditions.length !== 0;
    },

    // Items which appear in the '...' dropdown button
    dropdownItems() {
      const dropdownActions = [];
      if (this.csvExport && !this.csvExport.disabled && this.totalAudienceCount > 0) {
        dropdownActions.push({
          "name": "Export as CSV",
          "action": this.handleExportClick
        });
      }
      if(this.columnSettings && !this.columnSettings.disabled) {
        dropdownActions.push({
          "name": "Edit Columns",
          "action": this.handleSettingsClick
        });
      }
      return dropdownActions;
    },

    checkedRowActions() {
      const checkedRowActions = [
        {
          name: this.tickedFansCount === 1 ? "Add contacts to list" : "Add contact to list",
          action: this.handleAddFanToMessageList
        },
        {
          name: "Add tag",
          action: this.handleTagAdd
        },
        {
          name: "Remove tag",
          action: this.handleTagRemove
        },
        {
          name: this.tickedFansCount === 1 ? "Edit contact" : "Mass edit contacts",
          action: this.handleFanEdit
        },
        {
          name: this.tickedFansCount === 1 ? "Delete contact" : "Delete contacts",
          action: this.handleFanDelete,
          typography: {
            style: {
              color: this.$arStyle.color.red500,
            },
          }
        },
      ];
      return checkedRowActions;
    },

    filterBlocks() {
      if (!this.prunedScratchSegment) return [];

      let blocks = [];
      const filterConditionBlocks = this.prunedScratchSegment.filter.conditions.map(condition => ({
        ...condition,
        text: this.getSegmentConditionExpression(condition),
        type: 'condition',
      }));
      const filterLogicBlocks = this.prunedScratchSegment.filter.logic.map(logic => ({
        ...logic,
        text: logic,
        type: 'logic',
      }));

      for (let i = 0; i < filterConditionBlocks.length; i += 1) {
        blocks.push(filterConditionBlocks[i]);
        if (filterLogicBlocks[i]) { blocks.push(filterLogicBlocks[i]); }
      }

      return blocks;
    },
  },

  updated() {
    this.checkRightScrollArrowVisibility();
  },

  methods: {
    ...mapMutations([
      'layout/TOGGLE_SEGMENT_DRAWER',
      'segment/TOGGLE_SCRATCH_SEGMENT_LOGIC',
      'segment/DELETE_SCRATCH_SEGMENT_CONDITION',
      'audience/SELECT_ALL_AUDIENCE',
      'audience/CLEAR_ALL_SELECTED_AUDIENCE',
    ]),

    checkRightScrollArrowVisibility() {

      if (!this.getScrollerComponent()) return;
      if (!this.getFilterOptionsBarComponent()) return;

      const scrollerWidth = this.getScrollerComponent().offsetWidth;
      const filterOptionsBarWidth = this.getFilterOptionsBarComponent().offsetWidth;
      const leftModifier = filterOptionsBarWidth * 0.75;

      if (scrollerWidth <= filterOptionsBarWidth) {
        this.filterScrollerShowRightArrow = false;
      } else if (scrollerWidth - (this.filterScrollerPosition + filterOptionsBarWidth) > 0) {
        this.filterScrollerShowRightArrow = true;
      }
    },

    getScrollerComponent() {
      return this.$refs.scroller;
    },

    getFilterOptionsBarComponent() {
      return this.$refs['filter-options-bar'];
    },

    // Shows or hides the filter sidebar
    switchFilterSidebar() {
      this['layout/TOGGLE_SEGMENT_DRAWER']();
    },

    actionSelect(item) {
      item.action();
    },

    // Triggers a search from the searchbar
    handleFansSearchEnter() {
      this.fansSearch.action(this.searchText);
    },

    // Triggers an empty search from the searchbar (effectively a clear)
    handleFansSearchClear() {
      this.fansSearch.action('');
    },

    // Triggers an export of the currently visible audience
    handleExportClick() {
      this.csvExport.action();
    },

    // Shows the column-changing modal
    handleSettingsClick() {
      this.showColumnSwitchModal = true;
    },

    // Sets which columns are visible after selecting them in the modal
    handleMultiSelectModalConfirm(selectedColumnKeys) {
      this.showColumnSwitchModal = false;
      this.columnSettings.action(selectedColumnKeys);
    },

    // Opens the mass edit modal to add tags for the selected item
    handleTagAdd() {
      this.$emit('fansEditTags', 'add');
    },

    // Opens the mass edit modal to remove tags for the selected item
    handleTagRemove() {
      this.$emit('fansEditTags', 'remove');
    },

    // Opens the mass edit modal to edit the fan
    handleFanEdit() {
      this.$emit('fansEdit');
    },

    // Opens the fan delete modal
    handleFanDelete() {
      this.$emit('fansDelete');
    },

    // Opens the modal for adding a fan to a list
    handleAddFanToMessageList() {
      this.$emit('fansAddToMessageList');
    },

    handleDeselectAll() {
      this['audience/CLEAR_ALL_SELECTED_AUDIENCE']();
    },
    handleSelectAllClick() {
      if (this.useScratchSegment) {
        this['audience/CLEAR_ALL_SELECTED_AUDIENCE']();
      } else {
        this['audience/SELECT_ALL_AUDIENCE']();
      }
    },

    // Toggles and/or values in logic
    handleLogicClick(block, idx) {
      if (block.type !== 'logic') return;
      const logicIndex = Math.floor(idx/2);

      this['segment/TOGGLE_SCRATCH_SEGMENT_LOGIC'](logicIndex);
    },

    handleRemoveFilter(block, idx) {
      if (block.type !== 'condition') return;
      const conditionIndex = Math.ceil(idx / 2);

      this['segment/DELETE_SCRATCH_SEGMENT_CONDITION'](conditionIndex);
    },

    handleScrollerLeft() {
      const filterOptionsBarWidth = this.getFilterOptionsBarComponent().offsetWidth;
      const leftModifier = filterOptionsBarWidth * 0.75;

      this.filterScrollerPosition =
        this.filterScrollerPosition - leftModifier < 0 ?
          0 :
          this.filterScrollerPosition - leftModifier;

      this.filterScrollerShowRightArrow = true;
    },

    handleScrollerRight() {
      const scrollerWidth = this.getScrollerComponent().offsetWidth;
      const filterOptionsBarWidth = this.getFilterOptionsBarComponent().offsetWidth;
      const leftModifier = filterOptionsBarWidth * 0.75;

      if (scrollerWidth < filterOptionsBarWidth) {
        this.filterScrollerShowRightArrow = false;
      } else if (this.filterScrollerPosition + leftModifier > scrollerWidth - filterOptionsBarWidth) {
        this.filterScrollerPosition = scrollerWidth - filterOptionsBarWidth;
        this.filterScrollerShowRightArrow = false;
      } else {
        this.filterScrollerPosition = this.filterScrollerPosition + leftModifier;
        this.filterScrollerShowRightArrow = true;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.table-control-section {
  /*overflow: hidden;*/

  .bar {
    display: flex;
    align-content: center;
    background-color: #FFF;
    border: 1px solid #DCE0E4;
    border-bottom:none;

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      overflow:hidden;
    }

    &:last-child {
      border-bottom: 1px solid $skyBlueGrey500;
    }
  }

  .filter-and-search-bar {
    padding: 17px;
    display: flex;
    justify-content: space-between;

    .item {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    &.sm-max {
      .item {
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .fans-search {
      width: 100%;
      flex-grow: 1;
    }

    .circle-hooker {
      position: relative;
      .circle {
        position: absolute;
        top: 0;
        right: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        border-radius: 7.5px;
        background: $purple500;
        transform: translateX(50%) translateY(-50%);
        color: white;
        z-index: $zIndexHigh;

        span {
          position: relative;
          font-size: 10px;
          line-height: 10px;
          top: -1px;
        }
      }
    }
  }

  .filter-options-bar {
    position: relative;
    width: 100%;
    z-index:$zIndexRegular;
    padding: 12px 14px;
    overflow: hidden;

    .scroller {
      position: relative;
      top: 0;
      white-space: nowrap;
      transition: 0.25s;
    }

    .scroller-handle {
      position: absolute;
      top: 0;
      width: 24px;
      height: 100%;
      z-index: $zIndexHigh;
      display: flex;
      align-content: center;
      justify-content: center;
      background-color: #FFF;
      transition: background-color 0.125s;
      cursor: pointer;

      &:hover {
        background-color: $blueGrey400;
      }

      &.handle-left {
        left: 0;
      }
      &.handle-right {
        right: 0;
      }
    }

    .filter-block {
      margin-right: 8px;
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 64px;
      }
    }
  }

  .checked-rows-options-bar {
    justify-content: space-between;
    padding: 0 24px;
    position: absolute;
    width: 100%;
    z-index:$zIndexHigh;
    overflow:hidden;
    max-height: 0;
    transition: max-height 0.125s;
    &.is-visible {
      max-height: 52px;
      padding: 10px 14px 10px 9px;
    }
    &:not(.is-visible) {
      border-bottom: none;
    }
    .selected-count-container {
      cursor: pointer;
      background-color: $skyBlueGrey300;
      overflow: hidden;
      border-radius: 5px;
      display: inline-flex;
      padding: 8px;
    }
  }
}
</style>
