<template>
  <section>
    <am2-top-create
      ref="campaign-top"
      title="Create a campaign"
      exit-path="/campaigns" />

    <div :class="[
      'wrapper',
      $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      $arMediaQuery.pageContent.minWidth('lg') && 'lg-min',
    ]">
      <div class="left">
        <div class="inner">
          <nuxt-child
            @change="handleKeySelection"
            @new-event="handleCreateNewEvent"
          />
        </div>
        <nav class="navigation">
          <ar-simple-button
            v-if="previousPage"
            outlined
            text="Back"
            @click="handleBackStep"
            :style="{ width: '120px' }"
          />
          <ar-simple-button
            v-if="skipEventSelection"
            :disabled="!(selectedCampaignType && selectedEventOid)"
            text="Setup the campaign"
            @click="handleNextStep"
            :style="{ width: '100%' }"
          />
          <ar-simple-button
            v-else-if="hasNextPage"
            :disabled="!selectedCampaignType"
            text="Continue"
            @click="handleNextStep"
            :style="{ width: '100%' }"
          />
          <ar-simple-button
            v-else
            :disabled="!selectedEventOid"
            text="Setup the campaign"
            @click="handleNextStep"
          />
        </nav>
      </div>
      <div
        class="right"
        :style="{
          'minWidth': sidebarWidth
        }"
      >
        <div v-if="!selectedCampaignType" :class="[
          'get-started',
        ]">
          <am2-heading
            type="h1"
            size="lg"
            title="First, let's get you set up."
            :style="{ marginBottom: '6px' }"
          />
          <am2-heading
            type="h2"
            size="sm"
            title="Pick a campaign template to get you started"
          />
        </div>
        <div v-else class="campaign-preview">
          <div class="campaign-preview__header" v-if="!!campaignTypeObject.name && !!campaignTypeObject.subtitle">
            <p class="campaign-preview__title">{{ campaignTypeObject.name }}</p>
            <p class="campaign-preview__subtitle">{{ campaignTypeObject.subtitle }}</p>
          </div>

          <p>{{ campaignTypeObject.description }}</p>
          <br>
          <div
            v-for="(feature, index) in campaignTypeObject.features"
            :key="index"
            class="campaign-preview__feature"
          >
            <ar-icon
              class="tick"
              name="check"
              height="12px"
            />
            <span>
              {{ feature }}
            </span>
          </div>

          <hr v-if="!!campaignTypeObject.link" />
          <a v-if="!!campaignTypeObject.link" :href="campaignTypeObject.link">Learn more about the {{ campaignTypeObject.name }} </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import checkoutPageAccessibility from '@/mixins/checkoutPageAccessibility';
// import IconPresale from '@/components/icons/svg-icons/icon-presale';
import moment from 'moment';

export default {
  name: 'CampaignSetup',
  layout: 'edit',
  mixins: [
    checkoutPageAccessibility({ featureKeys: ['campaigns'], featureName: 'Campaigns' }),
  ],

  data: () => ({
    steps: ['type', 'event'],
    upgradeEventPath: '/campaigns/new/upgrade',
    detailsPath: '/campaigns/new/details',
    selectedCampaignType: null,
    selectedEventOid: null,
    skipEventSelection: null,
    campaignTypes: [
      {
        name: 'Presale Registration',
        key: 'presale',
        title: 'Presale campaign',
        // icon: IconPresale,
        subtitle: 'Make your presale go viral',
        link: 'https://audiencerepublic.com/campaigns/presale/',
        description:
          'Fans register for presale access, and earn points for sharing, inviting their friends to register, and following you on social media. Reward the highest point scorers with cheaper presale tickets, free tickets or a prize. When registrations end, those who registered are sent their links to purchase presale tickets.',
        features: [
          'Get up to 263% more presale registrations',
          'Sell more tickets through building your audience',
          'Collect valuable data & insights'
        ]
      }, {
        name: 'Ballot',
        key: 'ballot',
        title: 'Ballot campaign',
        // icon: IconPresale,
        subtitle: 'Leverage high demand events to build your audience',
        link: 'https://audiencerepublic.com/campaigns/ballot/',
        description:
          'Reward your top supporters by accelerating their place in line to buy tickets. Fans earn points for sharing, inviting their friends to register for the ballot, and following you on social media. The top point scorers are invited to buy tickets first.',
        features: ['Sell more tickets, faster', 'Build your audience', 'Collect powerful insights']
      }, {
        name: 'Competition',
        key: 'competition',
        title: 'Competition campaign',
        // icon: IconPresale,
        subtitle: 'Make your competitions go viral and build your audience',
        link: 'https://audiencerepublic.com/campaigns/competition/',
        description:
          'Put up a great prize, with fans earning points for sharing, inviting their friends to enter, and for following you on social media.  ',
        features: ['Sell more tickets, faster', 'Build your audience', 'Collect powerful insights']
      },
      {
        name: 'Wait List',
        key: 'waitlist',
        title: 'Waiting list campaign',
        // icon: IconPresale,
        subtitle: 'Fans can join the waiting list for any extra tickets that become available',
        link: 'https://audiencerepublic.com/campaigns/waiting-list/',
        description:
          'Fans earn points for sharing, inviting their friends, and following you on social media. Reward the highest point scorers with cheaper presale tickets, free tickets or a prize.',
        features: ['Sell more tickets, faster', 'Build your audience', 'Collect powerful insights']
      },
      {
        name: 'Livestream',
        key: 'livestream',
        title: 'Live stream campaign',
        // icon: IconPresale,
        subtitle: '',
        link: null,
        description:
          null,
        features: []
      },
      {
        name: 'Donate',
        key: 'donate',
        title: 'Donate campaign',
        // icon: IconPresale,
        subtitle: '',
        link: null,
        description:
          null,
        features: []
      },
      {
        name: 'Pre-Order',
        key: 'preorder',
        title: 'Pre-Order campaign',
        // icon: IconPresale,
        subtitle: '',
        link: null,
        description:
          null,
        features: []
      }
    ],
    sidebarWidth: '',
  }),

  async beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    const sessionCampaign = sessionStorage.getItem('_ar_promoter_new_campaign');
    if(sessionCampaign && to.path !== this.detailsPath) {
      const response = await this.SHOW_CONFIRM({ messageHtml: 'Are you sure you want to leave?<br />Unsaved changes will be lost.' });
      if(response) {
        sessionStorage.removeItem('_ar_promoter_new_campaign');
        next();
      } else {
        next(false)
      }
    } else {
      next();
    }


    window.removeEventListener("resize", this.resizeListener);
  },

  destroy () {
    window.removeEventListener("resize", this.resizeListener);
  },

  computed: {
    ...mapState({
      query: state => state.route.query,
      path: state => state.route.path,
    }),
    template() {
      return this.query.type || 'blank';
    },
    currentPage() {
      const routeParts = this.$route.path.split('/');
      return routeParts[routeParts.length - 1];
    },
    currentPageIndex() {
      return this.steps.indexOf(this.currentPage);
    },
    hasNextPage(){
      return !!this.nextPage;
    },
    previousPage() {
      return this.steps[this.currentPageIndex - 1];
    },
    nextPage() {
      return this.steps[this.currentPageIndex + 1];
    },
    campaignTypeObject(){
      if (this.selectedCampaignType) {
        return this.campaignTypes.find(c => c.key === this.selectedCampaignType);
      } else {
        return null;
      }
    },
  },

  created() {
    this.selectedCampaignType = this.query.type;
    this.selectedEventOid = this.query.eventOid;
    this.skipEventSelection = this.query.skipEventSelection;
    window.addEventListener("resize", this.resizeListener);
    this.checkSidebarWidth()
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
    ]),

    selectEvent(eventOid) {
      this.selectedEventOid = eventOid;
    },

    async handleNextStep() {
      let path = this.detailsPath;

      if (this.skipEventSelection && this.selectedCampaignType && this.selectedEventOid) {
        this.$router.push({
          path: path,
          query: {
            eventOid: this.selectedEventOid,
            type: this.selectedCampaignType,
          },
        });
      } else if (this.path === '/campaigns/setup/event') {
        this.$router.push({
          path: path,
          query: {
            eventOid: this.selectedEventOid,
            type: this.selectedCampaignType,
          },
        });
      } else if (this.path === '/campaigns/setup/type') {
        this.$router.push({
          path: this.nextPage,
          query: {
            eventOid: this.selectedEventOid,
            type: this.selectedCampaignType,
          },
        });
      }
    },

    handleBackStep() {
      if (this.previousPage) {
        this.$router.push({
          path: this.previousPage,
          query: this.query,
        });
      }
    },

    datePeriod(event) {
      let startDate = moment(event.startDate).format('DD MMM YYYY');
      const endDate = moment(event.endDate).format('DD MMM YYYY');

      if (startDate === endDate) {
        return `${startDate}`;
      } else {
        if (startDate.substring(7) === endDate.substring(7)){
          startDate = startDate.substring(0, 6);
        }

        return `${startDate} - ${endDate}`;
      }
    },

    eventImage(event) {
      const eventImage = event.resources.find(resource => resource.assetType === 'event-image');

      return eventImage ? eventImage.url : '/images/placeholders/image-default.png';
    },

    handleKeySelection(theChange) {
      if (theChange.type) {
        this.selectedCampaignType = theChange.type;
        this.$router.replace({
          path: this.path,
          query: {
            ...this.query,
            type: this.selectedCampaignType,
          },
        });
      }

      if (theChange.eventOid) {
        this.selectedEventOid = theChange.eventOid;
        this.$router.replace({
          path: this.path,
          query: {
            ...this.query,
            eventOid: this.selectedEventOid,
          },
        });
      }
    },

    handleCreateNewEvent() {
      if (this.query.type) {
        this.$router.replace({
          path: `/events/new`,
          query: {
            campaignType: this.query.type
          },
        });
      }
    },
    
    checkSidebarWidth() {
      if (window.innerWidth > 990 && window.innerWidth < 1350) {
        this.sidebarWidth = `${(window.innerWidth - 768)}px`
      } else if (window.innerWidth >= 1350) {
        this.sidebarWidth = `582px`
      }
    },
    resizeListener(e) {
      this.checkSidebarWidth()
    }

  }
};
</script>
<style lang="scss" scoped>
$campaign-md-max: 860px;

.wrapper {

  display: flex;
  padding: 90px 0 0 0;
  height: 100vh;

  .left {

    display: flex;
    flex-direction: column;
    max-width: $lg-min;
    min-width: $md-min;
    width: 100%;
    height: 100%;
    background: #fff;

    .inner {

      flex-grow: 1;
      padding: 50px 80px;
      height: calc(100% - 110px);
      overflow-y: auto;

    }

    .navigation {
      position: relative;
      width: 100%;
      z-index: $zIndexHighest;
      height: 110px;
      padding: 30px 80px;
      border-top: 1px solid $skyBlueGrey500;
      background-color: white;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
  }

  .right {
    max-width: $md-min;
    min-width: 582px;
    padding: 50px;
  }

  &.md-max {
    padding: 68px 0 0 0;
    flex-direction: column;
    .left {
      max-width: 100vw;
      min-width: 100vw;
      min-height: 100%;
      .inner {
        padding: 30px 12px;
        height:100%;
      }
      .navigation {
        height: 76px;
        padding: 10px 12px;
        position:fixed;
        bottom:0;
      }
    }
    .right {
      padding: 30px 12px 106px;
      max-height: none;
      min-width: unset;
    }
  }

}

.campaign-preview {
  max-width: $md-min;
  hr {
    margin: 24px 0px;
    border-top: 1px solid $skyBlueGrey500;
  }

  &__header {
    margin-bottom: 24px;
  }

  &__title {
    color: $blueGrey800;
    font-weight: bold;
    font-size: 20px;
  }

  &__subtitle {
    color: $blueGrey700;
    font-size: 16px;
  }

  &__feature {
    .tick {
      color: $green500;
      margin-right: 8px;
    }
  }
}


</style>
