var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ar-select',{ref:"select",attrs:{"enable-filter":"","items":_vm.computedEvents,"default-select-index":_vm.selectedEventIndex,"default-filter-string":_vm.searchString,"placeholder":_vm.eventPlaceholder,"filter-placeholder":_vm.filterPlaceholder,"disabled":_vm.disabled},on:{"select":_vm.handleSelect,"filterStringInput":_vm.handleFilterStringInput,"clear":function () { _vm.$emit('clear') },"close":function () { _vm.$emit('close') }},scopedSlots:_vm._u([{key:"selected-item",fn:function(selectedItemScope){return [(selectedItemScope.item)?_c('div',{style:({
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      padding: '0 14px',
    }),attrs:{"data-test-id":("event-" + (selectedItemScope.item.meta && selectedItemScope.item.meta.name ? selectedItemScope.item.meta.name : selectedItemScope.item.name))}},[_c('ar-text',{style:({
        color: _vm.$arStyle.color.skyBlueGrey800,
      }),attrs:{"size":"xs","text":selectedItemScope.item.meta && selectedItemScope.item.meta.name ? selectedItemScope.item.meta.name : selectedItemScope.item.name}})],1):_c('div',{style:({
      display: 'flex',
      height: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '0 14px',
    })},[_c('ar-text',{style:({
        color: _vm.$arStyle.color.blueGrey600,
      }),attrs:{"size":"xs","text":_vm.eventPlaceholder}})],1)]}},{key:"item",fn:function(itemScope){return [_c('div',{style:({
      display: 'flex',
      alignItems: 'center',
      height: '55px',
      padding: '0 15px',
      cursor: 'pointer',
      background: itemScope.active ? _vm.$arStyle.color.purple100 : null,
      borderBottom: '1px solid #dcdee4',
    }),attrs:{"data-test-id":("event-" + (itemScope.item.meta && itemScope.item.meta.name ? itemScope.item.meta.name : itemScope.item.name))}},[_c('ar-text',{style:({
        width: 'auto',
        color: itemScope.active ? '#7344c0' : '#43516b',
      }),attrs:{"size":"xs","text":itemScope.item.meta && itemScope.item.meta.name ? itemScope.item.meta.name : itemScope.item.name,"weight":itemScope.selected ? 'bold' : 'normal'}})],1)]}}])},[_vm._v(" "),_vm._v(" "),_c('template',{slot:"no-item"},[_c('div',{style:({
      display: 'flex',
      alignItems: 'center',
      height: '55px',
      padding: '0 15px',
    })},[_c('ar-text',{attrs:{"size":"xs","text":"No available events"}})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }