<template>
  <EditableFrame
    class="purchased-ticket-options-wrapper"
    title="Edit Trigger"
    :subtitle="item.name"
    has-save-button
    @close="$emit('close')"
    @save="handleSave"
  >
    <ar-text
      class="option-heading"
      size="13px"
      text="EVENT"
      weight="bold"
      multiple-lines
      :max-lines="1"
      letter-spacing="1px"
    />

    <EventsDropdown
      v-model="selectedEvent"
      :input-text="eventsSearchText"
      @input-text-update="updateEventText"
    />

    <template v-if="selectedEvent && !!eventsSearchText.length">
      <TicketTypeFilter
        v-model="selectedTypeFilter"
        :type-text="typeFilterText"
        @type-text-update="updateTypeFilterText"
      />
    </template>

    <ar-text
      class="options-heading"
      size="13px"
      text="OPTIONS"
      weight="bold"
      multiple-lines
      :max-lines="1"
      letter-spacing="1px"
    />

    <FrequencyOption v-model="freqOption" />
    <FilterOption v-model="filterOption" />
  </EditableFrame>
</template>
<script>
import EventsDropdown from './EventsDropdown'
import TicketTypeFilter from './TicketTypeFilter'
import FrequencyOption from '../utils/FrequencyOption.vue'
import FilterOption from '../utils/FilterOption.vue'
import EditableFrame from '../utils/EditableFrame.vue'
import { mapActions } from 'vuex'
export default {
  name: 'PurchasedTicket',
  components: {
    EventsDropdown,
    TicketTypeFilter,
    FrequencyOption,
    FilterOption,
    EditableFrame,
  },
  props: {
    item: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      eventsSearchText: '',
      selectedEvent: null,
      freqOption: false,
      filterOption: false,
      selectedTypeFilter: { key: 'contains', name: 'Contains' },
      typeFilterText: '',
    }
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    },
    selectedEvent() {
      this.eventsSearchText = this.selectedEvent && this.selectedEvent.name
    },
  },
  methods: {
    ...mapActions({
      updateContextData: 'automation/UPDATE_CONTEXT_DATA'
    }),
    updateTypeFilterText(val) {
      this.typeFilterText = val
    },
    updateEventText(val) {
      this.eventsSearchText = val
    },
    handleSave() {
      let contextData = {
        event: this.selectedEvent,
        'type-filter': {
          key: this.selectedTypeFilter.key,
          text: this.typeFilterText
        },
        'limit-frequency': this.freqOption,
      }
      this.updateContextData({id: this.item.id, data: contextData, type: this.item.type})
      this.$emit('close')
    },
    capitalizedFirstLetter([ first, ...rest ]) {
      return first.toUpperCase() + rest.join('')
    },
    setItemVals() {
      this.selectedEvent = this.item.config?.event

      this.freqOption = this.item.config?.['limit-frequency']
      if (this.item.config?.['type-filter']) {
        this.selectedTypeFilter = {
          key: this.item.config?.['type-filter'].key,
          name: this.capitalizedFirstLetter(this.item.config?.['type-filter'].key),
        }
        this.typeFilterText = this.item.config?.['type-filter'].text
      }
    }
  },
  mounted() {
    this.setItemVals()
  }
}
</script>
<style lang="scss" scoped>
.purchased-ticket-options-wrapper {
  width: 100%;

  .option-heading {
    margin-bottom: 14px;
  }

  .options-heading {
    margin-top: 52px;
    margin-bottom: 14px;
  }
}
</style>