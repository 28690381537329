<template>
  <div class="fan-table-wrapper">
    <am2-table
      ref="table"
      :heads="decoratedHead"
      :rows="data"
      :tick-all-rows="audienceSelection.useScratchSegment"
      :ticked-rows-map="audienceSelection.partlySelectedAudienceMap"
      :locked="locked"
      :loading="loading"
      :disable-sorting="disableSorting"
      :default-sort-by="defaultSortBy"
      :enable-row-tick="enableRowTick"
      :enable-row-click="enableRowClick"
      @sortBy="handleSortByChange"
      @rowTick="handleRowTick"
      @tickAllRows="handleTickAllRows"
      @rowClick="handleRowClick"
      @cellMouseEnter="handleCellMouseEnter"
      @cellMouseLeave="handleCellMouseLeave"
      @columnResize="handleColumnResize"
      class="fan-table"
      @tableMouseLeave="handleTableMouseLeave"
      :has-sticky-header="hasStickyHeader"
      :has-control-section="hasControlSection"
    >
      <div
        slot="name"
        slot-scope="{ data: { avatar, name, firstName, lastName, emailAddress, oid }, index, active }"
        class="cell cell-left"
        :data-test-id="`zoom-attendance-${index}-name`"
      >
        <ar-text
          v-if="hasIndex"
          size="xs"
          :text="index + 1"
          :style="{
            marginRight: '10px',
          }"
        />
        <ar-avatar
          :ref="`avatar-${index}`"
          :image-path="avatar || null"
          :name="(name || firstName) ? (name || `${firstName} ${lastName}`) : emailAddress"
          :style="{
            marginRight: '10px',
            cursor: customerProfilesEnabled ? 'pointer' : null,
          }"
          :color="audienceSelection.partlySelectedAudienceMap[oid] || active ? 'white' : 'grey'"
          @click.native="handleUserClick(`/audience/${oid}/view/overview`)"
        />
        <ar-text
          class="name-text"
          size="xs"
          allow-html
          :text="determineName(name, firstName, lastName, emailAddress)"
          :style="{
            cursor: customerProfilesEnabled ? 'pointer' : null,
          }"
          multiple-lines
          :max-lines="1"
          @click.native="handleUserClick(`/audience/${oid}/view/overview`)"
        />
      </div>
      <div
        slot="number"
        slot-scope="{ data, head }"
        class="cell"
        :style="{
          justifyContent: 'flex-end',
        }"
      >
        <ar-text
          size="xs"
          :text="renderRow(data[head.key], 'number')"
        />
      </div>
      <div
        slot="text"
        slot-scope="{ data, head }"
        class="cell"
        :style="{
          justifyContent: 'flex-end',
        }"
      >
        <ar-text
          size="xs"
          :text="data[head.key]"
        />
      </div>
      <div
        slot="emailAddress"
        slot-scope="{ data: { sourceRow } }"
        class="cell cell-left"
        :data-test-id="`zoom-attendance-${index}-email-address`"
      >
        <ar-text
          size="xs"
          :text="sourceRow ? sourceRow.email : null"
        />
      </div>
    </am2-table>
  </div>
</template>

<script>
import accounting from 'accounting';
import moment from 'moment';
import { mapState, mapMutations } from 'vuex';
import { timeago, generateDateWithoutTime } from '@/utils/date/';
import { countryNameMap } from '@/utils/countries';
import { capitalizeFirstLetter } from '@/utils/helpers';
import { sanitizeHtmlChild } from '@/utils/html-element/';

export default {
  name: 'ZoomAttendanceTable',

  props: {
    head: {
      type: Array,
      default: () => [],
    },
    body: {
      type: Array,
      default: () => [],
    },
    locked: {
      type: Boolean,
      default: false,
    },
    useDummyFans: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disableSorting: {
      type: Boolean,
      default: false,
    },
    enableRowTick: {
      type: Boolean,
      default: false,
    },
    enableRowClick: {
      type: Boolean,
      default: false,
    },
    defaultSortBy: {
      type: Object,
      default: () => null,
    },
    hasIndex: {
      type: Boolean,
      default: false,
    },
    hasStickyHeader: {
      type: Boolean,
      default: false,
    },
    customColumnWidths: {
      type: Object,
      default: () => {},
    },
    hasControlSection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countryNameMap,
    };
  },
  computed: {
    ...mapState({
      audienceSelection: state => state.audience.audienceSelection,
    }),
    data() {
      return this.body;
    },
    customerProfilesEnabled() {
      return !!process.env.arEnableCustomerProfiles;
    },
    decoratedHead() {
      return this.head.map((item, index) => {
        const storedWidth = this.customColumnWidths?.columns && this.customColumnWidths.columns.length > 0 && this.customColumnWidths.columns.find( stored => stored.column === item.key);
        let width;
        
        if (storedWidth) {
          width = storedWidth.width;
        } else if (item.key === 'name') {
          width = 160;
        } else if (item.key === 'emailAddress') {
          width = 160;
        } else if (item.key === 'mobileNumber') {
          width = 80;
        } else {
          width = 170;
        }
        
        return {
          ...item,
          width,
          align: (index > 0 && item.key !== 'emailAddress') ? 'right' : 'left',
        }
      });
    },
  },

  methods: {
    ...mapMutations([
      'audience/SELECT_A_FAN_FROM_AUDIENCE',
      'audience/DESELECT_A_FAN_FROM_AUDIENCE',
      'audience/SELECT_ALL_AUDIENCE',
      'audience/CLEAR_ALL_SELECTED_AUDIENCE',
    ]),
    determineName(name, fName, lName, email) {
      let finalName;
      if (name || fName) {
        finalName = name || `${fName} ${lName}`;
      } else {
        finalName = email;
      }
      return sanitizeHtmlChild(finalName || '');
    },
    formatDateModified(text) {
      return generateDateWithoutTime(text);
    },
    capitalizeFirstLetter(text) {
      return capitalizeFirstLetter(text);
    },
    getCountryName(country) {
      return country ? (this.countryNameMap[country.toLowerCase()] || country) : null;
    },
    renderRow(value, format) {
      switch (format) {
        case 'currency':
          return accounting.formatMoney(value / 100);
        case 'number':
          return accounting.formatNumber(value);
        default:
          return value;
      }
    },
    transformTimestringToDate(dob) {
      const date = moment(dob);
      if (date._isValid) {
        return date.format('DD-MM-YYYY');
      } else {
        return null;
      }
    },
    calculateTimeElapsed(date) {
      return timeago(date);
    },
    handleRowTick(fan, ticked) {
      if (ticked) {
        this['audience/SELECT_A_FAN_FROM_AUDIENCE'](fan.oid);
      } else {
        this['audience/DESELECT_A_FAN_FROM_AUDIENCE'](fan.oid);
      }
    },
    handleTickAllRows(isTicked) {
      if (isTicked) {
        this['audience/SELECT_ALL_AUDIENCE']();
      } else {
        this['audience/CLEAR_ALL_SELECTED_AUDIENCE']();
      }
    },
    handleRowClick(row, index) {
      this.$emit('rowClick', row, index);
    },

    handleSortByChange(sortBy) {
      this.$emit('sortBy', sortBy);
    },

    handleCellMouseEnter(head) {
      this.$emit('cellMouseEnter', head);
    },

    handleCellMouseLeave(head) {
      this.$emit('cellMouseLeave', head);
    },

    handleTableMouseLeave() {
      this.$emit('tableMouseLeave');
    },

    handleColumnResize(columnsData) {
      const columns = columnsData.map( (col, idx) => {
        return {
          column: this.decoratedHead[idx].key,
          width: col,
        }
      });
      this.$emit('columnResize', columns);
    },

    handleUserClick(link) {
      if (!link || !this.customerProfilesEnabled) return;
      this.$router.push(link);
    },
  },
};
</script>

<style lang="scss" scoped>

  .fan-table-wrapper {
    .fan-table {
      .cell {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 10px 12px;
        justify-content: flex-end;

        &-left {
          justify-content: flex-start;
        }
      }
      .name-text {
        max-width: 100%;
        overflow: hidden;

        /deep/ a {
          color: $blueGrey800;
        }
      }
    }
  }

  .th {
    padding: 0 12px !important;
  }
</style>
