<template>
  <div class="message-list-advanced-targeting-section">
    <div v-if="showToggle"
         class="toggle-module u-margin-top-3">
      <ar-link-button
        :text="toggleText"
        has-underline
        @click="handleToggle"
        data-test-id="message-list-targeting-toggle"
      />
    </div>
    <div v-if="showToggle && toggle"
         class="targeting-module u-padding-6 u-margin-top-5">
      <ar-field
        label="Filter"
        :style="{}">
        <ar-simple-select
          class="select-filter-type"
          :items="filterTypeItems"
          :default-select-index="selectedTypeIndex"
          data-test-id="message-targeting-type-select"
          @select="handleTypeSelect" />
      </ar-field>

      <!-- ADVANCED FILTERING BY TAG -->
      <div
        v-if="displayedSubsection === 'tag'"
        :class="['subsection',
               'tag-subsection',
               $arMediaQuery.pageContent.minWidth('md') && 'md-min',
               $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']"
      >
        <ar-field
          class="field-condition"
          label="Send only to people"
          :style="{
            minWidth: '184px'
          }">
          <ar-simple-select
            class="select-filter-type"
            :items="tagSelectConditionItems"
            :default-select-index="selectedConditionIndex"
            data-test-id="message-targeting-condition-select"
            @select="handleConditionSelect" />
        </ar-field>

        <ar-field
          label="Tag"
          class="field-value u-width-100-percent">
          <am2-fan-tag-input
            class="tag-input"
            placeholder="Select tag..."
            v-model="selectedTags"
            :allow-custom-input="false"
            :show-tag-count="false"
            :style="{
              backgroundColor:' #FFF',
              paddingTop: '5px',
              paddingBottom: '6px'
            }"
            @input="handleTagInput"
            data-test-id="message-targeting-tag-input"
          />
          <am2-link-button-dropdown
            :items="tagSubdropdownItems"
            :item-key="selectedSubConditionKey"
            :style="{
              marginTop: '8px',
              float: 'right',
            }"
            :dropdown-style="{

            }"
            @select="handleTagSubdropdownItemSelect"
            data-test-id="message-targeting-tag-subcondition-select"
          />
        </ar-field>
      </div>

      <!-- ADVANCED FILTERING BY SEGMENT -->
      <div
        v-if="displayedSubsection === 'segment'"
        :class="['subsection',
               'segment-subsection',
               $arMediaQuery.pageContent.minWidth('md') && 'md-min',
               $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']"
      >
        <ar-field
          class="field-condition"
          label="Send only to people"
          :style="{
            minWidth: '184px'
          }">
          <ar-simple-select
            class="select-filter-type"
            :items="segmentSelectConditionItems"
            :default-select-index="selectedConditionIndex"
            @select="handleConditionSelect"
            data-test-id="message-targeting-condition-select" />
        </ar-field>

        <ar-field
          label="Segment"
          class="field-value u-width-100-percent">
          <ar-simple-select
            :items="savedSegmentList"
            class="u-margin-top-3 segment-select u-width-100-percent"
            :default-select-index="selectedSegmentIndex"
            @select="handleSegmentSelect"
            enable-filter
            placeholder="Select segment..."
            data-test-id="message-targeting-segment-select"
          />
        </ar-field>
      </div>

      <!-- ADVANCED FILTERING BY OPENED/NOT OPENED MESSAGES -->
      <div
        v-if="displayedSubsection === 'messages_opened'"
        :class="['subsection',
               'message-subsection',
               $arMediaQuery.pageContent.minWidth('md') && 'md-min',
               $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']"
      >
        <ar-field
          class="field-condition"
          label="Send only to people"
          :style="{
            minWidth: '184px'
          }">
          <ar-simple-select
            class="select-filter-type"
            :items="openedSelectConditionItems"
            :default-select-index="selectedConditionIndex"
            @select="handleConditionSelect"
            data-test-id="message-targeting-condition-select" />
        </ar-field>

        <ar-field
          label="Message"
          class="field-value u-width-100-percent">
          <am2-message-select
            :messages="messagesListOpened"
            class="u-margin-top-3 segment-select u-width-100-percent"
            :value="messageOid"
            :search-string="messageSearchString"
            @filterStringInput="handleMessageFilterStringInput"
            @select="handleMessageSelect"
            data-test-id="message-targeting-message-select" />
        </ar-field>
      </div>

      <!-- ADVANCED FILTERING BY CLICKED/NOT CLICKED MESSAGES -->
      <div
        v-if="displayedSubsection === 'messages_clicked'"
        :class="['subsection',
               'message-subsection',
               $arMediaQuery.pageContent.minWidth('md') && 'md-min',
               $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']"
      >
        <ar-field
          class="field-condition"
          label="Send only to people"
          :style="{
            minWidth: '184px'
          }">
          <ar-simple-select
            class="select-filter-type"
            :items="clickedSelectConditionItems"
            :default-select-index="selectedConditionIndex"
            @select="handleConditionSelect"
            data-test-id="message-targeting-condition-select" />
        </ar-field>

        <ar-field
          label="Message"
          class="field-value u-width-100-percent">
          <am2-message-select
            :messages="messagesListClicked"
            class="u-margin-top-3 segment-select u-width-100-percent"
            :value="messageOid"
            :search-string="messageSearchString"
            @filterStringInput="handleMessageFilterStringInput"
            @select="handleMessageSelect"
            data-test-id="message-targeting-message-select" />
        </ar-field>
      </div>

      <!-- ADVANCED FILTERING BY PURCHASED TICKET TO EVENT / DIDN'T PURCHASE TICKET TO EVENT -->
      <div
        v-if="displayedSubsection === 'events'"
        :class="['subsection',
               'message-subsection',
               $arMediaQuery.pageContent.minWidth('md') && 'md-min',
               $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']"
      >
        <ar-field
          class="field-condition"
          label="Send only to people"
          :style="{
            minWidth: '184px'
          }">
          <ar-simple-select
            class="select-filter-type"
            :items="eventSelectConditionItems"
            :default-select-index="selectedConditionIndex"
            @select="handleConditionSelect"
            data-test-id="message-targeting-condition-select" />
        </ar-field>

        <ar-field
          label="Event"
          class="field-value u-width-100-percent">
          <am2-event-select
            class="u-margin-top-3 segment-select u-width-100-percent"
            :value="eventOid"
            :search-string="eventSearchString"
            @filterStringInput="handleEventFilterStringInput"
            @select="handleEventSelect"
            data-test-id="message-targeting-event-select" />
        </ar-field>
      </div>

      <!-- ADVANCED FILTERING BY REGISTERED TO CAMPAIGN / DIDN'T REGISTER TO CAMPAIGN -->
      <div
        v-if="displayedSubsection === 'campaigns'"
        :class="['subsection',
               'message-subsection',
               $arMediaQuery.pageContent.minWidth('md') && 'md-min',
               $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']"
      >
        <ar-field
          class="field-condition"
          label="Send only to people"
          :style="{
            minWidth: '184px'
          }">
          <ar-simple-select
            class="select-filter-type"
            :items="campaignSelectConditionItems"
            :default-select-index="selectedConditionIndex"
            @select="handleConditionSelect"
            data-test-id="message-targeting-condition-select" />
        </ar-field>

        <ar-field
          label="Campaign"
          class="field-value u-width-100-percent">
          <am2-campaign-select
            class="u-margin-top-3 segment-select u-width-100-percent"
            :value="campaignOid"
            :search-string="campaignSearchString"
            @filterStringInput="handleCampaignFilterStringInput"
            @select="handleCampaignSelect"
            data-test-id="message-targeting-campaign-select" />
        </ar-field>
      </div>




      <div class="recipients-count u-margin-top-6">
        <am2-loading-spinner
          v-if="isFetchingFilteredRecipientListCount && currentAdvancedTargetingIsValid"
          :style="{
            width: '1.5em',
            height: '1.5em'
          }"
        />
        <ar-text
          v-else
          class="recipients-text"
          size="md"
          data-test-id="message-targeting-recipient-count"
          :text="recipientCountText"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
  import { clone } from '@/utils/helpers/';
  import accounting from 'accounting';
  import { recipientFilterToTargetingRules } from '~/store/modules/message/utils';
  import { debounce } from "debounce";

  export default {
    name: 'MessageListAdvancedTargetingSection',

    props: {
      channel: {
        type: String,
        required: true,
        validator: (val) => ['email', 'sms', 'facebookMessenger'].indexOf(val) > -1,
      },
      showToggle: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        selectedTags: [],
        filterGroupOid: null,
        messageOid: null,
        eventOid: null,
        campaignOid: null,
        messageSearchString: null,
        eventSearchString: null,
        campaignSearchString: null,
        toggle: null,
        messageSearchDebouncing: false,
      }
    },

    watch: {
      currentMessageList(val, oldVal) {
        if (oldVal !== val) this['messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT']({ channel: this.channel });
      },
      toggle(val, oldVal) {
        if (val === false && oldVal === true) {
          this.resetValues();
        }
      },
    },

    computed: {
      ...mapState({
        promoterOid: state => state.auth.account.promoterOid,
        scratchEmailMessage: state => state.message.scratchEmailMessage,
        scratchSimpleMessage: state => state.message.scratchSimpleMessage,
        currentSelectedMessage: state => state.message.currentSelectedMessage,
        tagState: state => state.tag,
        savedSegmentList: state => state.segment.savedSegmentList,
        messages: state => state.message.messages,
        advancedMessageListTargeting: state => state.messageList.advancedMessageListTargeting,
        currentMessageList: state => state.messageList.currentMessageList,
        filteredRecipientListCount: state => state.messageList.filteredRecipientListCount,
        isFetchingFilteredRecipientListCount: state => state.messageList.isFetchingFilteredRecipientListCount,
        filteredRecipientListCountAccuracy: state => state.messageList.filteredRecipientListCountAccuracy,
      }),
      ...mapGetters({
        currentAdvancedTargetingIsValid: 'messageList/currentAdvancedTargetingIsValid',
      }),

      toggleText() {
        if (this.toggle) return "Hide advanced targeting";
        return "Show advanced targeting";
      },

      // The number of recipients in the message list, after advanced targeting has been applied
      recipientsCount() {
        return this.filteredRecipientListCount || 0;
      },

      selectedType() {
        return this.advancedMessageListTargeting.type || 'all';
      },

      selectedSubConditionKey() {
        return this.advancedMessageListTargeting.subCondition;
      },

      selectedTypeIndex() {
        return this.filterTypeItems.findIndex( item => {
          return item.key === this.selectedType
        })
      },

      selectedConditionIndex() {
        if (this.advancedMessageListTargeting.condition === 'include') return 0;
        return 1;
      },

      recipientCountText() {
        if (this.isFetchingFilteredRecipientListCount) return 'Calculating recipients';
        if (this.currentMessageList && Object.keys(this.currentMessageList.statsSnapshot).length === 0 ) {
          return "Calculating recipients";
        }
        if (this.recipientsCount === 1) return "1 recipient";
        if (this.recipientsCount === 0) return "No matching recipients";
        let estText = this.filteredRecipientListCountAccuracy === 'estimate' ? ' estimated ' : ' ';
        return `${accounting.format(this.recipientsCount)}${estText}recipients`
      },

      // Keeping 'segment' and 'recent_send' commented out for now, as adding them will
      // require additional work
      filterTypeItems() {
        return [
          {
            name: 'Send to entire list',
            key: 'all',
          },
          {
            name: 'Tag',
            key: 'tag',
          },
          {
            name: 'Opened message / Didn\'t open message',
            key: 'messages_opened',
          },
          {
            name: 'Clicked message / Didn\'t click message',
            key: 'messages_clicked',
          },
          {
            name: 'Purchased ticket to / Didn\'t purchase ticket to',
            key: 'events',
          },
          {
            name: 'Registered to campaign / Didn\'t register to campaign',
            key: 'campaigns',
          },

          // {
          //   name: 'Segment',
          //   key: 'segment',
          // },
          // {
          //   name: 'Exclude based on number of recent messages',
          //   key: 'recent_send',
          // },
        ];
      },

      displayedSubsection() {
        switch(this.selectedTypeIndex) {
          case 1:
            return "tag";
          case 2:
            return "messages_opened";
          case 3:
            return "messages_clicked";
          case 4:
            return "events";
          case 5:
            return "campaigns";
          case 0:
          default:
            return false;
        }
      },

      tagSelectConditionItems() {
        return [
          {
            name: 'With tag',
            key: 'include',
          },
          {
            name: 'Without tag',
            key: 'exclude',
          },
        ]
      },

      segmentSelectConditionItems() {
        return [
          {
            name: 'In segment',
            key: 'include',
          },
          {
            name: 'Not in segment',
            key: 'exclude',
          },
        ]
      },

      openedSelectConditionItems() {
        return [
          {
            name: 'Opened message',
            key: 'include',
          },
          {
            name: 'Didn\'t open message',
            key: 'exclude',
          },
        ]
      },

      clickedSelectConditionItems() {
        return [
          {
            name: 'Clicked message',
            key: 'include',
          },
          {
            name: 'Didn\'t click message',
            key: 'exclude',
          },
        ]
      },

      eventSelectConditionItems() {
        return [
          {
            name: 'Purchased to',
            key: 'include',
          },
          {
            name: 'Didn\'t purchase',
            key: 'exclude',
          },
        ]
      },

      campaignSelectConditionItems() {
        return [
          {
            name: 'Registered to',
            key: 'include',
          },
          {
            name: 'Didn\'t register',
            key: 'exclude',
          },
        ]
      },

      tagSubdropdownItems() {
        if (this.selectedConditionIndex === 0) {
          return [
            {
              name: "Any of the tags",
              key: "true_to_any"
            },
            {
              name: "All of the tags",
              key: "true_to_all"
            },
          ];
        }

        return [
          {
            name: "Without any of the tags",
            key: "false_to_any"
          },
          {
            name: "Without all of the tags",
            key: "false_to_all"
          },
        ];
      },

      selectedSegmentIndex() {
        return this.savedSegmentList.findIndex(({ value }) => {
          return value === this.filterGroupOid;
        });
      },

      selectedMessageIndex() {
        if (this.selectedType === 'messages_opened') {
          return this.messagesListOpened.findIndex(({ value }) => {
            return value === this.messageOid;
          })
        } else if (this.selectedType === 'messages_clicked') {
          return this.messagesListClicked.findIndex(({ value }) => {
            return value === this.messageOid;
          })
        }
        return -1;

      },

      messagesListOpened() {
        return this.messages
          .filter(item => item.provider !== 'sms')
          .map( item => {
          let name = item.name;
          if (item?.meta?.messageBody?.subject) {
            name = item.meta.messageBody.subject;
          } else if (item?.meta?.messageBody) {
            name = item.meta.messageBody;
          }
          return {
            value: item.oid,
            name
          }
        });
      },

      messagesListClicked() {
        return this.messages.map( item => {
          let name = item.name;
          if (item?.meta?.messageBody?.subject) {
            name = item.meta.messageBody.subject;
          } else if (item?.meta?.messageBody) {
            name = item.meta.messageBody;
          }
          return {
            value: item.oid,
            name
          }
        });
      },
    },

    mounted() {
      this.initializeValues();
    },

    created() {
      this.messageSearchDebouncing = debounce((event) => {
        this.loadMessages();
      }, 250);
    },

    methods: {
      ...mapActions([
        'segment/FETCH_SEGMENTS',
        'message/FETCH_MORE_MESSAGES',
        'messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT',
      ]),
      ...mapMutations([
        'tag/RESET_TAGS',
        'messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING',
        'messageList/SET_FILTERED_RECIPIENT_LIST_COUNT',
        'messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING',
      ]),

      async initializeValues() {
        await this['messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING']();
        this['segment/FETCH_SEGMENTS']();
        this['messageList/SET_FILTERED_RECIPIENT_LIST_COUNT'](0);
        this.loadMessages();

        let targetingVals = null;

        if (this.currentSelectedMessage?.meta?.recipientFilter &&
           (!this.scratchEmailMessage?.meta?.recipientFilter && !this.scratchEmailMessage?.oid) &&
           (!this.scratchSimpleMessage?.meta?.recipientFilter && !this.scratchSimpleMessage?.oid)
        ) {
          targetingVals = recipientFilterToTargetingRules(this.currentSelectedMessage.meta.recipientFilter);
        } else {
          if (this.channel === 'email') {
            targetingVals = recipientFilterToTargetingRules(this.scratchEmailMessage?.meta?.recipientFilter || null);
          } else {
            targetingVals = recipientFilterToTargetingRules(this.scratchSimpleMessage?.meta?.recipientFilter || null);
          }
        }


        if (targetingVals.values.length > 0 && ['messages_opened', 'messages_clicked'].indexOf(targetingVals.type) > -1) {
          this.messageOid = targetingVals.values[0]
        }
        if (targetingVals.values.length > 0 && targetingVals.type === 'events') {
          this.eventOid = targetingVals.values[0]
        }
        if (targetingVals.values.length > 0 && targetingVals.type === 'campaigns') {
          this.campaignOid = targetingVals.values[0]
        }

        if (!!targetingVals) {
          if (this.showToggle) this.toggle = true;
          this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](targetingVals);
          this.fetchTagsFromOids();
        }

        this['messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT']({ channel: this.channel });
      },

      async loadMessages() {
        await this['message/FETCH_MORE_MESSAGES']({
          status: 'completed',
          search: this.messageSearchString,
          reload: true,
          top: 50,
        });
      },

      resetValues() {
        this['messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING']();
        this.selectedTags = [];
        this.filterGroupOid = null;
        this.messageOid = null;
        this.messageSearchString = null;
        this.eventSearchString = null;
        this.campaignSearchString = null;
        this.$emit('updateAdvancedTargeting');
      },

      async fetchTagsFromOids() {
        if (this.advancedMessageListTargeting.type !== 'tag') return;
        if (this.advancedMessageListTargeting.values.length === 0) return;
        let url = `/promoter/${this.promoterOid}/tag?$count=true&$orderby=count%20desc&$filter=oid=`;
        url = `${url}${this.advancedMessageListTargeting.values.join(' OR oid=')}`;
        const { data: { count, rows } } = await this.$axios.get(url);
        const items = rows || [];
        this.selectedTags = items.map( item => item.name);
      },

      handleTypeSelect(name, idx) {
        const newAdvancedMessageListTargeting = clone(this.advancedMessageListTargeting);
        newAdvancedMessageListTargeting.type = name?.key;
        newAdvancedMessageListTargeting.condition = 'include';
        newAdvancedMessageListTargeting.subCondition =  name?.key === 'tag' ? 'true_to_all' : null;
        newAdvancedMessageListTargeting.values = [];
        this.selectedTags = [];
        this.filterGroupOid = null;
        this.messageOid = null;
        this.messageSearchString = null;
        this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](newAdvancedMessageListTargeting);
        this.$emit('updateAdvancedTargeting');
      },

      handleConditionSelect(name, idx) {
        const newAdvancedMessageListTargeting = clone(this.advancedMessageListTargeting);
        newAdvancedMessageListTargeting.condition = name?.key;
        if (newAdvancedMessageListTargeting.type === 'tag') {
          const currentSubCondition = newAdvancedMessageListTargeting.subCondition;
          if (name?.key === 'include' && currentSubCondition === 'false_to_all') {
            newAdvancedMessageListTargeting.subCondition = 'true_to_all'
          } else if (name?.key === 'include' && currentSubCondition === 'false_to_any') {
            newAdvancedMessageListTargeting.subCondition = 'true_to_any'
          } else if (name?.key === 'exclude' && currentSubCondition === 'true_to_all') {
            newAdvancedMessageListTargeting.subCondition = 'false_to_all'
          } else if (name?.key === 'exclude' && currentSubCondition === 'true_to_any') {
            newAdvancedMessageListTargeting.subCondition = 'false_to_any'
          }
        }
        this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](newAdvancedMessageListTargeting);
        this.$emit('updateAdvancedTargeting');
      },

      handleSegmentSelect(item) {
        this.filterGroupOid = item.value;
        const newAdvancedMessageListTargeting = clone(this.advancedMessageListTargeting);
        newAdvancedMessageListTargeting.values = [item.value];
        this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](newAdvancedMessageListTargeting);
        this.$emit('updateAdvancedTargeting');
      },

      handleMessageSelect(item) {
        this.messageOid = item.value;
        const newAdvancedMessageListTargeting = clone(this.advancedMessageListTargeting);
        newAdvancedMessageListTargeting.values = [item.value];
        this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](newAdvancedMessageListTargeting);
        this.$emit('updateAdvancedTargeting');
      },

      handleEventSelect(item) {
        this.eventOid = item.value;
        const newAdvancedMessageListTargeting = clone(this.advancedMessageListTargeting);
        newAdvancedMessageListTargeting.values = [item.value];
        this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](newAdvancedMessageListTargeting);
        this.$emit('updateAdvancedTargeting');
      },

      handleCampaignSelect(item) {
        this.campaignOid = item.value;
        const newAdvancedMessageListTargeting = clone(this.advancedMessageListTargeting);
        newAdvancedMessageListTargeting.values = [item.value];
        this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](newAdvancedMessageListTargeting);
        this.$emit('updateAdvancedTargeting');
      },

      // Items comes back as a string of names, whereas we need to pass the server a set of OIDs
      // So we'll need to extract the OID values from the vuex state, using the names we have available
      async handleTagInput(items) {
        this.selectedTags = items;
        const newAdvancedMessageListTargeting = clone(this.advancedMessageListTargeting);
        newAdvancedMessageListTargeting.values = await this.fetchTagOids(items);
        this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](newAdvancedMessageListTargeting);
        this.$emit('updateAdvancedTargeting');
      },


      async fetchTagOids(tagNames) {
        const filterString = `name = "${tagNames.join('" OR name = "')}"`;

        const res = await this.$axios.get(`/promoter/${this.promoterOid}/tag`, {
          params: {
            $count: true,
            $select: 'oid,name',
            $orderby: 'favourite desc',
            $top: 'all',
            $skip: 0,
            $filter: filterString,
          },
        });

        const rows = res?.data?.rows || [];
        return rows.map(item => item.oid);
      },

      handleTagSubdropdownItemSelect(item) {
        const tagSubdropdownKey = item.key;
        const newAdvancedMessageListTargeting = clone(this.advancedMessageListTargeting);
        newAdvancedMessageListTargeting.subCondition = tagSubdropdownKey;
        this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](newAdvancedMessageListTargeting);
        this.$emit('updateAdvancedTargeting');
      },

      handleToggle() {
        this.toggle = !this.toggle;
      },

      handleMessageFilterStringInput(val) {
        this.messageSearchString = val;
      },

      handleEventFilterStringInput(val) {
        this.eventSearchString = val;
      },

      handleCampaignFilterStringInput(val) {
        this.campaignSearchString = val;
      },
    }

  };
</script>

<style lang="scss" scoped>
 .message-list-advanced-targeting-section {

   .toggle-module {
     display: flex;
     width: 100%;
     justify-content: flex-end;
   }

   .targeting-module {
     background-color: $skyBlueGrey300;


     .subsection {
       margin-top: 22px;

       &.md-min {
         display: flex;
         flex-direction: row;

         .field-value {
           margin-left: 20px;
           max-width: calc(100% - 204px);
         }
       }

       &.sm-max {
         .field-value {
           margin-top: 20px;
         }
       }
     }

     .recipients-count {
       display: flex;
       flex-direction: row;
       justify-content: center;

       .recipients-text {
         color: $blueGrey700;
       }
     }
   }

 }
</style>
