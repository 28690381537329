import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { isErrorStatus } from '../utils';
import { CustomApiError } from '../types';

export default (axios: NuxtAxiosInstance) => ({
  /**
   * Get the Tags of the promoter with the given promoterOid
   * @param promoterOid 
   * @returns
   */
  async get(promoterOid: number, { top = 'all' }): Promise<Tag[]> {
    const { data, status } = await axios.get(`/promoter/${promoterOid}/tag?$count=true&top=${top}&$orderby=count%20desc`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Get Tags error',
        message: `Error getting Tags from promoter with oid of ${promoterOid}`,
        status,
      };
      throw apiError;
    }
    return data.rows;
  },

  async fetchTagByOid(promoterOid: number, tagOid: number): Promise<Tag[]> {
    const { data, status } = await axios.get(`/promoter/${promoterOid}/tag?$filter=oid=${tagOid}`)
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Get Tag error',
        message: `Error fetching Tag by Oid, with a promoter with oid of ${promoterOid},\n
                  and tagOid of ${tagOid}.`,
        status,
      }
      throw apiError
    }

    return data
  }
});