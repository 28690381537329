var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.opened}},[_c('ar-modal',{staticClass:"select-email-template-modal",attrs:{"is-open":_vm.opened,"header":"Select a template","width":"1200px","height":"100%","hide-footer":"","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{ref:"body-section",staticClass:"body-section",style:({
        minHeight: _vm.$arMediaQuery.window.maxWidth('xs') ? '100%' : '500px',
      }),attrs:{"slot":"body"},slot:"body"},[_c('am2-tabs',{attrs:{"selected-tab-index":_vm.selectedTemplateSourceIndex,"items":_vm.templateSourceItems},on:{"select":_vm.handleTemplateSourceSelect}}),_vm._v(" "),_c('ar-divider'),_vm._v(" "),(_vm.hasNoEmailTemplates)?_c('am2-no-content-section',{style:({
          marginTop: '22px',
        }),attrs:{"header":"No templates found","header-size":"sm"}}):_vm._e(),_vm._v(" "),_c('div',{class:[
          'templates-wrapper',
          _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[_vm._l((_vm.emailTemplates),function(emailTemplate){return _c('am2-template-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTemplateSource === 'bucket'),expression:"selectedTemplateSource === 'bucket'"}],key:emailTemplate.oid,ref:"template-card",refInFor:true,class:[
            'template-card',
            _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
            _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"name":emailTemplate.meta.name,"description":emailTemplate.meta.description,"template":emailTemplate.meta.template,"template-html":_vm.getEmailTemplateHtml(emailTemplate),"type":emailTemplate.meta.templateType,"has-dropdown":false,"disabled":!_vm.getEmailTemplateHtml(emailTemplate)},on:{"click":function($event){_vm.handleTemplateCardClick(emailTemplate, _vm.getEmailTemplateHtml(emailTemplate))}}})}),_vm._v(" "),_vm._l((_vm.unlayerTemplates),function(emailTemplate){return _c('am2-template-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTemplateSource === 'unlayer'),expression:"selectedTemplateSource === 'unlayer'"}],key:emailTemplate.id,ref:"template-card",refInFor:true,class:[
            'template-card',
            _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
            _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"name":emailTemplate.name,"template":emailTemplate.design,"template-html":_vm.getUnlayerHtml(emailTemplate.design),"type":"unlayer","has-dropdown":false,"disabled":!_vm.getUnlayerHtml(emailTemplate.design)},on:{"click":function($event){_vm.handleUnlayerTemplateCardClick(emailTemplate, _vm.getUnlayerHtml(emailTemplate.design))}}})}),_vm._v(" "),_vm._l((_vm.skeletonCardNumber),function(n){return _c('am2-card-skeleton',{key:n,class:[
            'template-card',
            _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
            _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"type":"email-template"}})})],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }