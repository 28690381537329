<template>
  <ar-modal
    :is-open="showWarning"
    class="warning-modal"
    hide-header
    :hide-footer="!hasFooter"
    width="590px"
    :has-cross-icon="hasCrossIcon"
    :mobile="$arMediaQuery.window.maxWidth('xs')"
    @close="handleCloseClick"
  >
    <div
      slot="body"
      class="warning-modal-body"
    >
      <div
        class="exclamation-mark"
      >
        <ar-icon
          v-bind="iconPropsComputed"
        />
      </div>
      <ar-text
        v-if="title"
        class="warning-modal-title"
        :text="title"
        multiple-lines
        weight="bold"
        align="center"
        size="md"
      />
      <ar-text
        class="warning-modal-message"
        size="xs"
        :text="messageHtml"
        allow-html
        multiple-lines
        line-height="25px"
        :style="{
          marginBottom: messageHtml && !hideConfirmButton ? '25px' : '8px',
        }"
        align="center"
      />
      <div
        v-if="validation"
        class="validation-section"
      >
        <label class="label">{{ validation.question }}</label>
        <ar-input
          v-model="validationAnswer"
          :placeholder="validation.answer"
          class="input"
          data-test-id="warning-modal-validation-input"
        />
      </div>
      <div
        v-else
        class="warning-modal-buttons-wrapper"
        :style="{
          marginBottom: '55px',
        }"
      >
        <ar-simple-button
          v-if="!hideConfirmButton"
          :loading="loading"
          @click="handleConfirmClick"
          v-bind="confirmButtonPropsComputed"
        />
        <ar-link-button
          v-if="!loading && !hideCancelButton"
          v-bind="cancelButtonPropsComputed"
          :style="{ marginTop: '19px' }"
          @click="handleCloseClick"
        />
      </div>
    </div>
    <div v-if="validation" slot="footer" class="footer-section">
      <ar-simple-button
        :loading="loading"
        :disabled="!isValidationConfirmButtonValid"
        @click="handleConfirmClick"
        v-bind="confirmButtonPropsComputed"
      />
      <ar-simple-button
        v-if="!loading && !hideCancelButton"
        :disabled="loading"
        @click="handleCloseClick"
        :style="{ width: '108px', marginLeft: '7px' }"
        v-bind="cancelButtonPropsComputed"
      />
    </div>
  </ar-modal>
</template>

<script>

export default {
  data() {
    return {
      loading: false,
      iconName: null,
      iconColor: null,
      showWarning: false,
      messageHtml: null,
      title: null,
      validation: null,
      validationAnswer: null,
      confirmButtonText: null,
      cancelButtonText: null,
      hideConfirmButton: false,
      hideCancelButton: false,
      hasCrossIcon: false,
      iconProps: null,
      confirmButtonProps: null,
      cancelButtonProps: null,
      onConfirm: () => {},
      onClose: () => {}
    };
  },

  computed: {
    iconPropsComputed() {
      return {
        name: 'alert-exclamation-mark',
        height: '70px',
        color: this.$arStyle.color.purple500,
        wrapperStyle: {
          borderRadius: '50%',
          width: '70px',
          height: '70px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        ...this.iconProps,
      }
    },

    confirmButtonPropsComputed() {
      return {
        text: "Confirm",
        style: {
          width: '250px'
        },
        ...this.confirmButtonProps,
      }
    },

    cancelButtonPropsComputed() {
      return {
        text: this.cancelButtonText || 'Cancel',
        type: "grey",
        ...this.cancelButtonProps,
      }
    },

    hasFooter() {
      if (this.validation) {
        return true;
      } else {
        return false;
      }
    },
    isValidationConfirmButtonValid() {
      if (this.loading) {
        return false;
      }

      if (this.validation.answer !== this.validationAnswer) {
        return false;
      }

      return true;
    },
  },

  created() {
    window.addEventListener('arModalOpen', this.handleModalOpen);
  },

  beforeDestroy() {
    window.removeEventListener('arModalOpen', this.handleModalOpen);
  },

  methods: {
    handleModalOpen({ detail: { action, payload } }) {
      if (action !== 'SHOW_CONFIRM') return;

      this.startModalAction(payload);
    },
    respondModalAction(payload) {
      window.dispatchEvent(new CustomEvent('arModalResponse', { detail: { action: 'SHOW_CONFIRM', payload } }));
    },
    startModalAction({
      iconProps,
      confirmButtonProps,
      cancelButtonProps,
      messageHtml,
      iconName,
      title,
      validation,
      confirmButtonText,
      cancelButtonText,
      // If you need some action to be completed before we close the modal
      asyncCompleteFunction,
      hideConfirmButton,
      hideCancelButton,
      hasCrossIcon,
    }) {
      this.iconProps = iconProps || {};
      this.cancelButtonProps = cancelButtonProps || {};
      this.confirmButtonProps = confirmButtonProps || {};


      // Temporarily here for backwards compatibility - remove later
      if (iconName) this.iconProps.name = iconName;
      if (confirmButtonText) this.confirmButtonProps.text = confirmButtonText;

      this.messageHtml = messageHtml;
      this.iconName = iconName;
      this.title = title;
      this.validation = validation;
      this.confirmButtonText = confirmButtonText;
      this.cancelButtonText = cancelButtonText;
      this.hideConfirmButton = hideConfirmButton;
      this.hideCancelButton = hideCancelButton;
      this.hasCrossIcon = hasCrossIcon || false;
      this.showWarning = true;

      this.onConfirm = async () => {
        this.validationAnswer = null;
        if (asyncCompleteFunction) {
          try {
            this.loading = true;
            await asyncCompleteFunction();
          } catch (e) {
            return;
          } finally {
            this.loading = false;
          }
        }
        this.showWarning = false;
        this.respondModalAction(true);
      };
      this.onClose = () => {
        this.validationAnswer = null;
        this.showWarning = false;
        this.respondModalAction(false);
      }
    },
    handleConfirmClick() {
      this.onConfirm();
    },
    handleCloseClick() {
      this.onClose();
    },
  },
}
</script>

<style lang="scss" scoped>
.warning-modal {
  .warning-modal-body {
    .exclamation-mark {
      display: flex;
      justify-content: center;
      margin-bottom: 19px;
      margin-top: 60px;
    }
    .warning-modal-title {
      margin: 0 60px 16px;
    }
    .warning-modal-message {
      margin: 0 60px;
    }
    .validation-section {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 20px;
      background: #F6F8FA;
      border-top: 1px solid $skyBlueGrey600;

      .label {
        font-size: 14px;
        color: $blueGrey800;
        margin-bottom: 18px;
      }

      .input {
        width: 80%;
        max-width: 300px;
      }
    }
    .warning-modal-buttons-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .warning-modal-confirm-button {
        background: #F6F8FA;
      }
      .warning-modal-cancel-text {
        color: $purple500;
        font-size: 14px;
        line-height: 1em;
        cursor: point;
        text-decoration: underline;
        margin-top: 19px;
        letter-spacing: 0;
        text-align: center;
        cursor: pointer;

        &:hover {
          color: $purple600;
        }
      }
    }
  }
  .footer-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
