var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"ar-split-layout"},[(this.$arMediaQuery.window.minWidth('sm'))?_c('div',{class:[
      'left-section' ]},[_c('div',{staticClass:"background-image",style:({
        backgroundImage: ("url(" + _vm.backgroundImage + ")"),
        backgroundRepeat: 'no-repeat',
        backgroundSize: _vm.backgroundSize,
      })})]):_vm._e(),_vm._v(" "),_c('div',{ref:"page-content",class:[
      'page-content',
      this.$arMediaQuery.window.maxWidth('sm') && 'full-width' ]},[_c('nuxt')],1),_vm._v(" "),_c('div',{staticClass:"logo-wrapper",style:({
      background: this.$arMediaQuery.window.maxWidth('sm') ? 'rgba(256, 256, 256, 0.9)' : null,
      position: this.$arMediaQuery.window.maxWidth('sm') ? 'absolute' : 'fixed',
    })},[_c('ar-icon',{attrs:{"name":!this.$arMediaQuery.window.maxWidth('sm') ? 'ar-logo-mono' : 'ar-logo',"width":"35px","height":"31px","color":"white"}})],1),_vm._v(" "),_c('ModalsExpansion',[_c('portal-target',{attrs:{"name":"modal"}}),_vm._v(" "),_c('portal-target',{attrs:{"name":"higher-order-modal"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }