<template>
  <div class="event-type-filter-wrapper">
      <ar-text
        class="type-heading"
        size="13px"
        text="TICKET TYPE"
        weight="bold"
        multiple-lines
        :max-lines="1"
        letter-spacing="1px"
      />
      <div class="type-options-wrapper">
        <div class="type-container">
          <div class="type-filter-wrapper" :class="{ active: typeFilterOpen }" @click="toggleTypeFilter">
            <ar-text
              class="selected-type-name"
              size="14px"
              :text="selectedTypeFilter.name"
              weight="normal"
              multiple-lines
              :max-lines="1"
              line-height="20px"
            />
            <ArrowHead class="type-filter-dropdown-arrowhead" height="8px" width="10px" fill="#8492a6" />
          </div>
          <div class="type-filter-input-wrapper">
            <input
              class="type-filter-input"
              ref="typeFilterInput"
              placeholder="Start typing..."
              v-model="filterText"
            />
          </div>
        </div>
        <div v-if="typeFilterOpen" class="type-filter-dropdown-wrapper">
          <template v-for="(filter, index) in typeFilters">
            <ar-text
              :key="index"
              class="type-filter-option"
              :class="{ selected: selectedTypeFilter.key === filter.key }"
              size="14px"
              :text="filter.name"
              weight="normal"
              multiple-lines
              :max-lines="1"
              line-height="20px"
              @click.native="selectTypeFilter(filter)"
            />
          </template>
        </div>
      </div>
  </div>
</template>
<script>
import ArrowHead from '~/assets/icons/arrow-head.svg?inline'
export default {
  name: 'TicketTypeFilter',
  components: {
    ArrowHead,
  },
  props: {
    selectedTypeFilter: {
      type: Object,
      default: null,
    },
    typeText: {
      type: String,
      default: null,
    }
  },
  model: {
    prop: 'selectedTypeFilter',
    event: 'selection',
  },
  data() {
    return {
      typeFilterOpen: false,
      typeFilters: [
        {
          key: 'contains',
          name: 'Contains',
        },
        {
          key: 'exactly',
          name: 'Exactly',
        },
      ],
    }
  },
  computed: {
    filterText: {
      get: function() {
        return this.typeText
      },
      set: function(val) {
        this.$emit('type-text-update', val)
      }
    },
  },
  methods: {
    selectTypeFilter(filter) {
      this.$emit('selection', filter)
      this.typeFilterOpen = false
    },

    toggleTypeFilter() {
      this.typeFilterOpen = !this.typeFilterOpen
    },
  }
}
</script>
<style lang="scss" scoped>
.type-heading {
  margin-top: 28px;
  margin-bottom: 14px;
}
.type-options-wrapper {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  position: relative;

  .type-container {
    width: 100%;
    height: 46px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    .type-filter-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      width: 112px;
      border-radius: 4px;
      border: solid 1px $blueGrey500;
      background-color: #fff;
      height: 100%;
      padding-left: 14px;
      padding-right: 14px;
      cursor: pointer;

      &.active {
        .type-filter-dropdown-arrowhead {
          transform: rotate(180deg);
        }
      }

    }

    .type-filter-input-wrapper {
      width: 194px;
      border-radius: 4px;
      border: solid 1px $blueGrey500;
      background-color: #fff;
      height: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      padding-left: 10px;
      padding-right: 10px;

      input {
        width: 100%;
        outline: none;
        border: none;
        padding-bottom: 4px;
        font-family: Graphik;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.43;
        color: $blueGrey800;
      }
    }
  }

  .type-filter-dropdown-wrapper {
    width: 112px;
    padding: 10px 0;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 $shadow;
    border: solid 1px #d4d8e1;
    background-color: #fff;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    position: absolute;
    top: 50px;
    z-index: 10;

    .type-filter-option {
      width: 100%;
      padding: 8px 0 8px 13px;
      cursor: pointer;

      &:hover {
        background-color: #f2edff;
      }

      &.selected {
        color: $purple500;
      }
    }
  }
}
</style>