<template>
  <div class="am2-simple-message-editor">
    <ProseMirrorEditor
      ref="prose-mirror-editor"
      class="prose-mirror-editor"
      :content="editorHtml"
      :schema="schema"
      :extensions="extensions"
      :key-map="keyMap"
      :content-transformer="contentTransformer"
      :content-restorer="contentRestorer"
      @update="handleContentUpdate"
    />
  </div>
</template>

<script>
import ProseMirrorEditor from '@/components/editors/prose-mirror-editor';
import DynamicTag from '@/components/editors/prose-mirror-editor/extensions/dynamic-tag-extension';
import Breaker from './extensions/breaker-extension';
import { escapeHtmlSyntax, unEscapeHtmlSyntax } from '@/utils/html-element/';
import { arDynamicTagsRegexGlobal } from '@/utils/regex/';

export default {
  name: 'RichTextEmailEditor',
  components: {
    ProseMirrorEditor,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    dynamicTags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      editorHtml: this.value,
      schema: {
        nodes: {
          text: {
            group: 'inline',
          },
          doc: {
            content: 'inline*'
          },
        },
        marks: {},
      },
      keyMap: {},
    };
  },
  computed: {
    extensions() {
      return [
        new DynamicTag({
          tagNames: this.dynamicTags,
        }),
        new Breaker(),
      ];
    },
  },
  watch: {
    value(newVal) {
      this.editorHtml = newVal;
    },
  },
  methods: {
    // This method is used by paraents
    insertDynamicTag(tagName) {
      this.$refs['prose-mirror-editor'].runCommand('dynamicTag', tagName);
    },
    // Transform plain text message to html, so our editor recognize it.
    contentTransformer(content) {
      return escapeHtmlSyntax(content)
        .replace(arDynamicTagsRegexGlobal, '<dynamic-tag tag-name="$1"></dynamic-tag>')
        .replace(/\n/g, '<br>');
    },
    contentRestorer(content) {
      const message = content
        .replace(/<dynamic-tag tag-name=\"(.*?)\"><\/dynamic-tag>/g, '{{$1}}')
        .replace(/<br>/g, '\n');
      return unEscapeHtmlSyntax(message);
    },
    handleContentUpdate(content) {
      this.editorHtml = content;
      this.$emit('input', content);
    },
  },
};
</script>

<style lang='scss' scoped>
.am2-simple-message-editor {
  position: relative;

  .prose-mirror-editor {
    white-space: pre-line;
    height: 120px;
    font-size: 14px;
    line-height: 25px;
  }
}
</style>
