var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bar-container"},[(_vm.title)?_c('div',{staticClass:"bar-container__row"},[_c('div',{class:[
      'bar-container__row-header',
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
      _vm.layout ]},[_c('am2-heading',{style:({
          marginRight: '12px',
        }),attrs:{"type":"h1","size":"md","title":_vm.title}}),_vm._v(" "),(_vm.dropdown)?_c('div',{staticClass:"dropdown"},[(_vm.dropdown && _vm.layout === 'old-style')?_c('am2-link-button-dropdown',{attrs:{"item-key":_vm.dropdown.filterKey,"items":_vm.dropdown.items},on:{"select":function (item) { return item.action(); }}}):_vm._e(),_vm._v(" "),(_vm.dropdown && _vm.layout === 'box-layout')?_c('ar-simple-select',{attrs:{"items":_vm.dropdown.items,"default-select-index":_vm.dropdownIndex || _vm.dropdownIndex === 0 ? _vm.dropdownIndex : 1,"placeholder":"Date range","align":"right","dropdown-width":_vm.dropdownWidth},on:{"select":function (item, key) { return _vm.handleSelect(item, key); }}}):_vm._e()],1):_vm._e()],1)]):_vm._e(),_vm._v(" "),_c('div',{class:[
      'bar-container__row-content',
      _vm.layout,
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max', _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max'
    ]},_vm._l((_vm.values),function(v,idx){return _c('div',{key:v.index,class:[
        'bar-block',
        _vm.layout,
        v.layout && v.layout
      ],style:({
        cursor: !!v.clickAction ? 'pointer' : null,
      }),attrs:{"data-test-id":"overview-bar-values"},on:{"click":function($event){!!v.clickAction ? v.clickAction() : null},"mouseenter":function($event){_vm.activeHover = idx},"mouseleave":function($event){_vm.activeHover = null}}},[_c('am2-heading',{style:({
          color: '#8e97a6',
          lineHeight: '20px',
          marginTop: '6px',
        }),attrs:{"type":"h2","size":_vm.layout === 'box-layout' ? 'xxs' : 'xs',"title":v.label,"data-test-id":("overview-bar-heading-" + idx)}}),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: v.tooltip || null,
          show: _vm.activeHover === idx,
          trigger: 'manual',
        }),expression:"{\n          content: v.tooltip || null,\n          show: activeHover === idx,\n          trigger: 'manual',\n        }",modifiers:{"top":true}}],class:[
          'bar-block__body',
          _vm.layout,
          _vm.blurValues && 'blurred fake-values' ]},[(_vm.loading)?_c('div',{staticClass:"bar-block-loading-section"},[_c('am2-typography-skeleton',{style:({
              marginTop: _vm.layout === 'box-layout' ? '7px' : '10px',
              width: '56px',
              marginBottom: _vm.layout === 'box-layout' ? '7px' : null,
            }),attrs:{"size":"md"}})],1):(v.type === 'CURRENCY')?_c('div',{staticClass:"sales-container"},[_c('div',{staticClass:"sales-plain",attrs:{"data-test-id":("overview-sales-plain-" + idx)}},[_vm._v("\n            $"+_vm._s(_vm.amountInDollarsLocal(v.amount))+"\n          ")]),_vm._v(" "),(v.showDecimal !== false)?_c('div',{staticClass:"sales-cents",attrs:{"data-test-id":("overview-sales-cents-" + idx)}},[_vm._v("\n            "+_vm._s(_vm.centsRemainingLocal(v.amount))+"\n          ")]):_vm._e()]):(v.type === 'NUMBER')?_c('div',{attrs:{"data-test-id":("overview-number-" + idx)}},[_vm._v("\n          "+_vm._s(_vm.formatIntegerLocal(v.amount))+"\n        ")]):_c('div',{attrs:{"data-test-id":("overview-default-total-" + idx)}},[_vm._v("\n          "+_vm._s(_vm.formatIntegerLocal(v.amount))+"\n          "),(v.maximum)?_c('span',{staticClass:"over-total"},[_vm._v("/ "+_vm._s(_vm.formatIntegerLocal(v.maximum)))]):_vm._e()]),_vm._v(" "),(!_vm.loading && v.percentChange !== undefined)?_c('am2-tendency-tag',{attrs:{"value":v.percentChange,"data-test-id":("overview-percentage-change-" + idx)}}):_vm._e()],1),_vm._v(" "),(v.type === 'PROGRESSION')?_c('am2-gauge',{staticClass:"sales-gauge",attrs:{"data-test-id":("overview-sakes-gauge-" + idx),"value":parseInt(v.amount),"max":parseInt(v.maximum)}}):_vm._e()],1)}),0),_vm._v(" "),(_vm.extracontent)?_c('div',[_c('transition',{attrs:{"name":"slide"}},[(_vm.isOpen)?_c('div',{staticClass:"bar-container__row-graph"},[_vm._t("extracontent_graph")],2):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"puller bar-container__row",on:{"click":_vm.toggleOpening}},[_c('ar-icon',{attrs:{"name":"chevron","rotate":_vm.isOpen ? 180 : 0}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }