<template>
  <am2-table
    ref="message-table"
    class="message-table"
    :heads="decoratedHead"
    :rows="tableRows"
    :loading="loading"
    :row-height="74"
    empty-text="No messages available"
    enable-row-click
    :has-sticky-header="hasStickyHeader"
    @rowClick="handleMessageRowClick"
    custom-head-padding="0 22px"
  >
    <div
      slot="message"
      slot-scope="{ data }"
      class="message-cell"
      :style="{
        padding: $arMediaQuery.pageContent.minWidth('sm') ? '17px 14.5px 16.5px 22px' : '10px 14.5px 10px 22px',
      }"
    >
      <div class="type-container">
        <ar-icon
          class="icon"
          :name="getIcon(data.provider).name"
          :color="getIcon(data.provider).color"
          width="19px"
        />
      </div>
      <div class="body-container">
        <ar-text
          v-if="data.provider === 'email'"
          :class="['main', getMessageUiStatus(data) === 'completed' && 'sent']"
          size="xs"
          :text="data.meta && data.meta.messageBody ? data.meta.messageBody.subject : null"
          weight="bold"
        />
        <ar-text
          v-else
          :class="['main', getMessageUiStatus(data) === 'completed' && 'sent']"
          size="xs"
          :text="data.meta ? data.meta.messageBody : null"
          weight="bold"
        />
        <ar-text
          class="subtitle"
          size="xxxs"
          :text="data.lastActionTimeText"
          v-tooltip.top="{
            content: data.lastActionTimeTooltip ? data.lastActionTimeTooltip : null
          }"
          multiple-lines
          :max-lines="1"
          line-height="16px"
        />
      </div>
      <div
        class="status-action-container"
      >
        <div class="status">
          <div
            v-if="data.uiStatus === 'in-progress' && data.statusDetails"
            class="progress"
          >
            {{ sentPercentage(data.statusDetails) }}%
            <am2-gauge
              class="gauge"
              fill-color="#7344c0"
              :value="numSent(data.statusDetails)"
              :max="data.statusDetails.totalMessages"
            />
          </div>
          <div>
            <am2-tag
              :text="generateStatusCopy(data.uiStatus)"
              :type="statusToTagColor(data.uiStatus)"
              :style="{
                border: '1px solid white'
              }"
            />
          </div>
        </div>
        <am2-icon-button-dropdown
          v-if="!data.hideActions"
          :icon-props="{
            name: 'menu',
          }"
          :items="messageOptions(data)"
          @select="(item) => handleMessageOptionSelect(item.eventName, data)"
        />
      </div>
    </div>
    <div
      slot="opens"
      slot-scope="{ data, head }"
      :class="[
        'percentage-cell',
        $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
      ]"
    >
      <div class="percentage-cell__container">
        <template v-if="data[head.key] > 0 && data.provider !== 'sms'">
          <ar-text
            class="value inactive-only"
            size="xs"
            :text="`${data[head.key]}%`"
            align="right"
            weight="bold"
          />
          <ar-text
            class="value active-only"
            size="xs"
            :text="data.opensRaw"
            align="right"
            weight="bold"
          />
          <ar-text
            class="subtitle"
            size="xs"
            :text="head.name"
            align="right"
          />
        </template>
        <span v-else class="empty-dash" />
      </div>
    </div>
    <div
      slot="clicks"
      slot-scope="{ data, head }"
      :class="[
        'percentage-cell',
        $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
      ]"
    >
      <div class="percentage-cell__container">
        <template v-if="data[head.key] > 0">
          <ar-text
            class="value inactive-only"
            size="xs"
            :text="`${data.clicks}%`"
            align="right"
            weight="bold"
          />
          <ar-text
            class="value active-only"
            size="xs"
            :text="`${data.clicksRaw}`"
            align="right"
            weight="bold"
          />
          <ar-text
            class="subtitle"
            size="xs"
            :text="head.name"
            align="right"
          />
        </template>
        <span v-else class="empty-dash" />
      </div>
    </div>
  </am2-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import accounting from 'accounting';
import { convertToTitleCase } from '@/utils/helpers';
import { generateMessageCenterDateCopy } from '@/utils/date/';

export default {
  name: 'MessagesTable',

  props: {
    head: {
      type: Array,
      default: () => [],
    },
    messages: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasStickyHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      enableEmailSending: process.env.arEnableEmailSending,
    };
  },
  computed: {
    ...mapGetters({
      getMessageUiStatus: 'message/getMessageUiStatus',
      getAvailableMessageOptionsMap: 'message/getAvailableMessageOptionsMap',
    }),
    decoratedHead() {
      return this.head.map(item => {
        let align;
        let width;
        if (item.key === 'message') {
          width = 660;
          align = 'left';
        } else {
          align = 'right';
        }

        return {
          ...item,
          width,
          align,
        }
      });
    },
    tableRows() {
      return this.messages.map(m => {
        const { opened = 0, clicked = 0, bounced = 0, deferred = 0, failed = 0, totalMessages } = m.statusDetails;
        const successfulSends = (totalMessages || 0)
          - (bounced || 0)
          - (deferred || 0)
          - (failed || 0);
        let lastActionTimeText;

        let usersTimeZone = null;
        try {
          usersTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch(e) {
          //
        }

        const uiStatus = this.getMessageUiStatus(m);
        const timezone = m.meta && m.meta.presentation ? m.meta.presentation.timeZone : null;

        // Only use 'last updated time' when the message is a draft
        let lastActionTimeTooltip = null;
        if (uiStatus === 'completed' || uiStatus === 'in-progress') {
          const startedTime = m.started || m.scheduledAt || m.sysCtime;
          lastActionTimeText = `${this.actionStartingWord(uiStatus)} ${generateMessageCenterDateCopy(startedTime, (timezone || usersTimeZone))}`;
          lastActionTimeTooltip = timezone && timezone !== usersTimeZone ? `${generateMessageCenterDateCopy(startedTime)} local time` : null;
        } else if (uiStatus === 'scheduled') {
          lastActionTimeText = `${this.actionStartingWord(uiStatus)} ${generateMessageCenterDateCopy(m.scheduledAt, (timezone || usersTimeZone))}`;
          lastActionTimeTooltip = timezone && timezone !== usersTimeZone ? `${generateMessageCenterDateCopy(m.scheduledAt)} local time` : null;
        } else {
          lastActionTimeText = `${this.actionStartingWord(uiStatus)} ${generateMessageCenterDateCopy(m.sysMtime, (timezone || usersTimeZone))}`;
          lastActionTimeTooltip = timezone && timezone !== usersTimeZone ? `${generateMessageCenterDateCopy(m.sysMtime)} local time` : null;
        }

        if (m?.meta?.senderFriendlyName) {
          lastActionTimeText += ` from ${m.meta.senderFriendlyName}`;
        }

        let hideActions = false;
        if (m.provider === 'email' && !this.enableEmailSending) {
          hideActions = true;
        }

        // clicks should count as opens because one needs to open a message before
        // they can click a link inside a message
        // click rate should be a % of opens, rather than sends.
        const opensCount = opened + clicked;
        let opens = successfulSends > 0 ? ((100*(opensCount))/successfulSends) : 0;
        if (opens < 1) {
          opens = opens.toFixed(2);
        } else if (opens > 99 && opens < 100) {
          opens = 99;
        } else {
          opens = Math.round(opens);
        }

        // The CTR for SMS is clicks / recipients
        // The CTR for email is clicks / opens
        let clicks = null;
        if (m.provider === 'sms') {
          clicks = successfulSends > 0 ? ((100*clicked)/successfulSends) : 0;
        } else {
          clicks = opensCount > 0 ? ((100*clicked)/opensCount) : 0;
        }

        if (clicks < 1) {
          clicks = clicks.toFixed(2);
        } else if (clicks > 99 && clicks < 100) {
          clicks = 99;
        } else {
          clicks = Math.round(clicks);
        }

        return {
          ...m,
          uiStatus,
          opens: opens,
          opensRaw: opensCount,
          clicks: clicks,
          clicksRaw: clicked,
          lastActionTimeText,
          lastActionTimeTooltip,
          hideActions,
        }
      });
    },
  },

  methods: {
    ...mapActions([
      'message/ARCHIVE_MESSAGE',
      'message/CANCEL_MESSAGE',
      'message/CLONE_MESSAGE',
      'SHOW_CONFIRM',
      'OPEN_CANCELLATION_MODAL',
    ]),
    getIcon(provider) {
      if (provider === 'facebook') {
        return {
          name: 'messenger',
          color: this.$arStyle.color.messenger,
        };
      } else if (provider === 'sms') {
        return {
          name: 'sms',
          color: this.$arStyle.color.sms,
        };
      } else {
        // Email
        return {
          name: 'email',
          color: this.$arStyle.color.email,
        };
      }
    },
    numSent(details) {
      if (details.totalMessages) {
        const sent = details.sent || 0;
        const delivered = details.delivered || 0;
        const bounced = details.bounced || 0;
        const failed = details.failed || 0;
        const clicked = details.clicked || 0;
        const opened = details.opened || 0;
        const spam = details.markedSpam || 0;
        const unsubscribed = details.unsubscribed || 0;
        const deferred = details.deferred || 0;

        return sent + delivered + bounced + failed + opened + clicked + spam + unsubscribed + deferred;
      } else {
        return 0;
      }
    },
    sentPercentage(details) {
      if (details.totalMessages) {
        return Math.min(
          Math.round((this.numSent(details)/details.totalMessages)*100),
          100
        );
      } else {
        return 0;
      }
    },

    generateStatusCopy(word) {
      if (word === 'completed') {
        return 'Sent';
      } else if (word === 'in-progress') {
        return 'Sending';
      } else {
        return convertToTitleCase(word);
      }
    },

    statusToTagColor(status){
      switch (status.toLowerCase()) {
        case 'in-progress':
          return 'purple';
        case 'completed':
          return 'green';
        default:
          return 'grey';
      }
    },
    actionStartingWord(status) {
      if (status ===  'draft') {
        return 'Edited';
      } else if (status ===  'scheduled') {
        return 'Scheduled';
      } else if (status ===  'archived') {
        return 'Archived';
      } else if (status ===  'cancelled') {
        return 'Cancelled';
      } else if (status ===  'failed') {
        return 'Failed';
      } else {
        return 'Sent';
      }
    },

    messageOptions(message) {
      const availableMessageOptionsMap = this.getAvailableMessageOptionsMap(message);
      const options = [{
        name: 'Edit',
        eventName: 'edit',
      }];

      if (message.provider !== 'facebook') {
        options.push({
          name: 'Duplicate',
          eventName: 'duplicate',
        });
      }

      options.push({
          name: 'View',
          eventName: 'view',
        },
        {
          name: 'Archive',
          eventName: 'archive',
        },
        {
          name: 'Cancel',
          eventName: 'cancel',
          typography: {
            style: {
              color: this.$arStyle.color.red500,
            },
          },
        });

      return options.filter(option => availableMessageOptionsMap[option.eventName]);
    },

    async goToMessageEdit(message) {
      if (message.provider === 'sms') {
        this.$router.push(`/message-center/messages/sms/${message.oid}/edit`);
      } else if (message.provider === 'facebook') {
        this.$arNotification.push({ type: 'warning', message: 'Facebook Messenger is no longer available' });
      } else if (message.provider === 'email') {
        if(this.$arMediaQuery.window.maxWidth('xs')) {
          const response = await this.SHOW_CONFIRM({
            messageHtml: `You are able to create and edit emails using your desktop or tablet device.`,
            hideConfirmButton: true,
            cancelButtonText: 'Back',
          });
        } else {
          this.$router.push(`/message-center/messages/email/${message.oid}/edit/basic`);
        }
      }
    },

    goToMessageView(message) {
      this.$router.push(`/message-center/messages/${message.oid}/view`);
    },

    async duplicateMessage(message) {
      await this['message/CLONE_MESSAGE'](message.oid);
      this.$emit('cloneMessage');
    },

    async archiveMessage(message) {
      const messageTaskOid = message.oid;
      this['message/ARCHIVE_MESSAGE'](messageTaskOid);
    },

    async cancelMessage(message) {
      const ans = await this.OPEN_CANCELLATION_MODAL({
        title: `Are you sure you want to cancel this message?`,
        showCancellationReasonTextarea: false,
        confirmButtonProps: {
          text: `Cancel message`,
          type: 'red',
          style: {
            width: 'auto',
            borderRadius: '7px',
          }
        },
      });

      if(ans) {
        const messageTaskOid = message.oid;
        await this['message/CANCEL_MESSAGE'](messageTaskOid);
        this.$emit('cancelMessage');
      }
    },

    handleMessageRowClick(message) {
      const availableOptionsMap = this.getAvailableMessageOptionsMap(message);
      if (availableOptionsMap.edit) {
        this.goToMessageEdit(message);
      } else if (availableOptionsMap.view) {
        this.goToMessageView(message);
      }
    },

    handleMessageOptionSelect(event, message) {
      if (event === 'view') {
        this.goToMessageView(message);
      } else if (event === 'edit') {
        this.goToMessageEdit(message);
      } else if (event === 'duplicate') {
        this.duplicateMessage(message);
      } else if (event === 'archive') {
        this.archiveMessage(message);
      } else if (event === 'cancel') {
        this.cancelMessage(message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.message-table {
  .message-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &:hover {
      .type-container {
        box-shadow: 0px 0px 1px rgba(0,0,0,0.5);
      }
    }

    .type-container {
      border: 2px #EBEBEB solid;
      border-radius: 20px;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 23px;
      background-color: white;
      flex-shrink: 0;

      .icon {
        position: relative;
        left: 0.5px;
      }
    }

    .status-action-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      .status {
        display: flex;
        flex-direction: row;
        margin-right: 4px;

        .progress {
          text-align: right;
          margin-right: 14px;

          .gauge {
            width: 80px;
          }
        }
      }
    }
  }

  // TODO - Need a better solution than referencing the row within the parent.
  /deep/.tr {
    .percentage-cell {
      &__container {
        .active-only {
          display: none;
        }
        .inactive-only {
          display: block;
        }
      }
    }
    &:hover {
      .percentage-cell {
        &__container {
          .active-only {
            display: block;
          }
          .inactive-only {
            display: none;
          }
        }
      }
    }
  }

  .body-container {
    flex-grow: 1;
    font-weight: bold;
    overflow: hidden;
    padding-right: 16px;

    .main {
      color: rgba-to-rgb(rgba($blueGrey800, 0.5));

      &.sent {
        color: $blueGrey800;
      }
    }

    .subtitle {
      color: rgba-to-rgb(rgba($blueGrey800, 0.5));
      margin-top: 5px;
    }

  }

  .percentage-cell {
    padding: 25.5px 25.5px 16.5px 25.5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &__container {
      width: 100%;
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .value {
      margin-bottom: 6px;
    }

    .subtitle {
      color: rgba-to-rgb(rgba($blueGrey800, 0.5));
    }

    .empty-dash {
      align-self: flex-end;
      width: 14px;
      border-bottom: 1px solid $blueGrey600;
    }
  }
}
</style>
