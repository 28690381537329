<template>
  <am2-card-container
    :class="[
      'ar-campaign-card-skeleton',
      showAs,
    ]"
  >
    <ar-skeleton class="left-section" :level="level" />
    <div class="right-section">
      <div class="text-bars">
        <am2-typography-skeleton
          class="text-skeleton upper"
          size="md"
          :level="level + 2"
          :style="{
            maxWidth: showAs === 'tiles' ? '400px' : '200px',
          }"
        />
        <am2-typography-skeleton
          class="text-skeleton lower"
          size="md"
          :level="level + 2"
          :style="{
            maxWidth: '250px',
          }"
        />
      </div>
      <div class="meta-bars-skeleton">
        <am2-typography-skeleton
          class="text-skeleton"
          size="xxxs"
          :level="level + 2"
          :style="{
            maxWidth: '110px',
          }"
        />
        <am2-typography-skeleton
          :class="[
            'text-skeleton',
            'u-margin-left-2',
          ]"
          size="xxxs"
          :level="level + 2"
          :style="{
            maxWidth: '60px',
          }"
        />
      </div>
    </div>
  </am2-card-container>
</template>

<script>
export default {
  name: 'CampaignCardSkeleton',

  props: {
    showAs: {
      type: String,
      default: 'list',
      validator: (value) =>
        ['list', 'tiles'].indexOf(value) !== -1,
    },
    level: {
      type: Number,
      default: 1,
      validator: (val) => val > 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-campaign-card-skeleton {
  display: flex;
  padding: 20px 22px;

  .right-section {
    display: flex;
    flex-flow: column;
    padding-left: 20px;
  }

  &.list {
    flex-flow: row;
    align-items: center;

    padding: 0;
    .left-section {
      width: 100%;
      border-radius: 0;
      height: 100%;
      max-width: 368px;

      @media (max-width: $lg-min) {
        max-width: 250px;
      }
    }

    .right-section {
      justify-content: space-between;
      height: 100%;
      width: 100%;
      padding: 32px;

      .text-bars {
        .text-skeleton {
          height: 20px;

          &.upper {
            width: 200px;
          }

          &.lower {
            width: 250px;
            margin-top: 8px;
          }
        }
      }

      .meta-bars-skeleton {
        display: flex;
        flex-direction: row;
        // @media (max-width: $lg-min) {
        //   justify-content: space-between;
        // }

        // .text-skeleton {
        //   width: 100%;
        // }
      }
    }
  }

  &.tiles {
    flex-flow: column;
    align-items: center;
    padding: 0;
    justify-content: space-between;

    .text-skeleton {
      height: 20px;

      &.upper {
        width: calc(100% - 50px);
      }

      &.lower {
        width: 100%;
        margin-top: 8px;
      }
    }

    .left-section {
      width: 100%;
      padding-bottom: 50%;
      border-radius:0;
    }

    .right-section {
      margin-top: 8px;
      padding: 24px 0;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      .text-bars {
        width: 100%;
        padding: 0 20px;
      }

      .meta-bars-skeleton {
        width: 100%;
        padding: 12px 20px 0;
        display: flex;
        flex-flow: row;

        .text-skeleton {
          width: 100%;
        }
      }
    }
  }

}
</style>
