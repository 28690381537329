<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="custom-field-modal"
      :header="title"
      width="600px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div slot="body" class="body">
        <div :class="[
          'modal-content',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
          <div
            v-if="hasRSVPEvent || type == 'signup-form'"
            class="tabs"
          >
            <am2-tabs
              :items="tabItems"
              @select="handleMenuSelect"
            />
          </div>
          <div v-show="currentTab === 'general'">

            <div class="u-display-flex u-margin-bottom-3">
              <ar-text
                size="xs"
                text="Tag associated contacts"
                weight="bold"
                :style="{
                  color: $arStyle.color.blueGrey800,
                }"
              />
              <ar-icon
                v-tooltip.top="{
                  content: `Automatically tag all contacts that ${this.type == 'event' ? 'are attendees of this event' : 'registered to this form'}.`,
                }"
                name="tooltip-question-mark"
                class="u-margin-left-2"
                height="15px"
                width="15px"
                :color="$arStyle.color.purple500"
              />
            </div>
            <ar-field
              :error-messages="[veeErrors.first('custom-field-label')]"
            >
              <am2-fan-tag-input
                placeholder="Enter tag"
                e2e-test-id="fan-tag"
                v-model="newFanTags"
              />
            </ar-field>

            <div
              v-if="type == 'event'"
            >
              <div
                class="u-display-flex u-margin-top-8 u-margin-bottom-3"
              >
                <ar-text
                  size="xs"
                  text="Subscribe associated contacts to list"
                  weight="bold"
                  :style="{
                    color: $arStyle.color.blueGrey800,
                  }"
                />
                <ar-icon
                  v-tooltip.top="{
                    content: `Automatically subscribe all ${this.type == 'event' ? 'attendees of this event' : 'contacts that registered to this form'} to a list.`,
                  }"
                  name="tooltip-question-mark"
                  class="u-margin-left-2"
                  height="15px"
                  width="15px"
                  :color="$arStyle.color.purple500"
                />
              </div>
              <ar-field
                :error-messages="[veeErrors.first('list')]"
              >
                <am2-message-list-select
                  :value="subscribeToMessageList ? subscribeToMessageList.messageListOid : null"
                  e2e-test-id="message-list"
                  data-vv-name="list"
                  data-vv-as="list"
                  :channels="['sms', 'email', 'facebookMessenger']"
                  @select="handleMessageListSelect"
                />
                <div
                  v-if="messageListInEvent"
                  :class="[
                    'channels-select-section',
                    'u-margin-top-2',
                  ]"
                >
                  <ar-text
                    size="xs"
                    text="Select enabled channels for this list:"
                    multiple-lines
                  />
                  <div
                    :class="[
                      'u-margin-top-3',
                      'u-display-flex',
                      $arMediaQuery.pageContent.maxWidth('sm') && 'u-flex-flow-column',
                      $arMediaQuery.pageContent.maxWidth('sm') ? 'u-align-items-flex-start' : 'u-align-items-center',
                    ]"
                  >
                    <ar-checkbox
                      name="message-list-email"
                      v-model="subscribeToMessageList.email"
                      :disabled="!messageListInEvent.email"
                      v-tooltip="{
                        content: !messageListInEvent.email ? 'Email is not enabled for this list. Enable in List Settings.' : null,
                      }"
                      label="Email"
                      icon-name="email"
                      :icon-props="{
                        width: '18px',
                        color: $arStyle.color.email,
                      }"
                    />
                    <ar-checkbox
                      name="message-list-sms"
                      :class="[
                        $arMediaQuery.pageContent.minWidth('md') && 'u-margin-left-12',
                        $arMediaQuery.pageContent.maxWidth('sm') && 'u-margin-top-2',
                      ]"
                      v-model="subscribeToMessageList.sms"
                      :disabled="!messageListInEvent.sms"
                      v-tooltip="{
                        content: !messageListInEvent.sms ? 'SMS is not enabled for this list. Enable in List Settings.' : null,
                      }"
                      label="SMS"
                      icon-name="sms"
                      :icon-props="{
                        width: '18px',
                        color: $arStyle.color.sms,
                      }"
                    />
                  </div>
                </div>
              </ar-field>
            </div>
          </div>
          <div
            v-if="hasRSVPEvent || type == 'signup-form'"
          >
            <div v-show="currentTab === 'rsvp'">
              <ar-field
                :error-messages="[veeErrors.first('event-name')]"
              >
                <div class="u-display-flex u-margin-bottom-3">
                  <ar-text
                    size="xs"
                    text="Call To Action Button"
                    weight="bold"
                    :style="{
                      color: $arStyle.color.blueGrey800,
                    }"
                  />
                  <ar-icon
                    v-tooltip.top="{
                      content: `Customize the call to action button on the ${this.type == 'event' ? 'RSVP page' : 'Signup form'}.`,
                    }"
                    name="tooltip-question-mark"
                    class="u-margin-left-2"
                    height="15px"
                    width="15px"
                    :color="$arStyle.color.purple500"
                  />
                </div>
                <ar-input
                  :value="customCta"
                  @input="handleCTAButtonNameInput"
                  autocomplete="cta-button-name"
                  data-vv-name="cta-button-name"
                  data-vv-as="call to action button name"
                  :placeholder="customCtaPlaceholder"
                  type="text"
                />
                <ar-field
                  class="u-margin-top-8"
                  label="Language"
                >
                  <am2-language-select
                    :value="selectedLanguage"
                    @input="handleDefaultLanguageInput"
                  />
                </ar-field>
              </ar-field>

              <ar-text
                size="xs"
                text="Other options"
                weight="bold"
                class="u-margin-top-8"
                :style="{
                  color: $arStyle.color.blueGrey800,
                }"
              />

              <am2-switch-field-card
                class="u-margin-top-3"
                title="Add your Privacy Policy link"
                subtitle="Display cookies banner & additional checkboxes"
                :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal'"
                :value="showPrivacyPolicy"
                @input="toggleShowPrivacyPolicy"
              >
                <ar-field
                  :error-messages="[
                    veeErrors.first('privacyPolicyCompanyName'),
                    veeErrors.first('privacyPolicyUrl'),
                  ]"
                >
                  <div
                    v-if="showPrivacyPolicy"
                    :class="[
                      'u-display-flex',
                      'u-justify-content-space-between',
                      $arMediaQuery.pageContent.maxWidth('xs') && 'u-flex-flow-column',
                      'u-margin-top-3'
                    ]"
                  >
                    <ar-input
                      placeholder="Company"
                      :value="privacyPolicyCompanyName"
                      @input="handlePrivacyPolicyCompanyNameInput"
                      v-validate="'required|privacyPolicyUrlAlsoRequired'"
                      autocomplete="organization"
                      data-vv-name="privacyPolicyCompanyName"
                      data-vv-as="company name"
                      type="text"
                      :style="{
                        width: $arMediaQuery.pageContent.maxWidth('xs') ? '100%' : '30%',
                      }"
                    />
                    <ar-input
                      :class="[
                        $arMediaQuery.pageContent.maxWidth('xs') && 'u-margin-top-2',
                      ]"
                      :value="privacyPolicyUrl"
                      placeholder="Privacy Policy URL"
                      @input="handlePrivacyPolicyUrlInput"
                      v-validate="{
                        required: true,
                        privacyPolicyCompanyNameAlsoRequired: true,
                        url: {
                          require_protocol: true,
                        },
                      }"
                      autocomplete="url"
                      data-vv-name="privacyPolicyUrl"
                      data-vv-as="url"
                      type="text"
                      :style="{
                        width: $arMediaQuery.pageContent.maxWidth('xs') ? '100%' : 'calc(70% - 10px)',
                      }"
                    />
                  </div>
                </ar-field>
              </am2-switch-field-card>
              <am2-switch-field-card
                class="u-margin-top-5"
                title="Custom Confirmation Message"
                subtitle="Add your own custom message to the confirmation page"
                :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal'"
                :value="!!this.successMessage"
                @input="updateCustomConfirmationMessageEnabled"
              >
                <ar-textarea
                  :rows="4"
                  :value="successMessage"
                  :placeholder="successMessagePlaceholder"
                  @input="updateCustomConfirmationMessage"
                />
              </am2-switch-field-card>
            </div>
            <div v-show="currentTab === 'pixels'">
              <am2-switch-field-card
                v-if="isEventRSVPEnabled"
                class="u-margin-top-5"
                title="Facebook"
                subtitle="Add Facebook retargeting pixel"
                :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal'"
                @input="updateFacebookEnabled"
                :value="this.facebookPixel ? this.facebookPixel.enabled : null"
              >
                <ar-field
                  :error-messages="[veeErrors.first('facebookPixel')]"
                >
                  <ar-input
                    name="facebookPixel"
                    data-vv-name="facebookPixel"
                    :value="this.facebookPixel ? this.facebookPixel.id : null"
                    @input="updateFacebook"
                    placeholder="e.g. 123456789012345"
                    v-validate="'facebookPixelId|required'"
                  />
                </ar-field>
              </am2-switch-field-card>
              <am2-switch-field-card
                v-if="isEventRSVPEnabled"
                class="u-margin-top-5"
                title="Google Tag Manager"
                subtitle="Add Google Tag Manager App ID"
                :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal'"
                @input="updateGoogleTagManagerEnabled"
                :value="this.googleTagManager.enabled"
              >
                <ar-field
                  :error-messages="[veeErrors.first('googleTagManager')]"
                >
                  <ar-input
                    name="googleTagManager"
                    data-vv-name="googleTagManager"
                    :value="this.googleTagManager.id"
                    @input="updateGoogleTagManager"
                    placeholder="e.g. GTM-1234567"
                    v-validate="'googleTagManagerId|required'"
                  />
                </ar-field>
              </am2-switch-field-card>
              <am2-switch-field-card
                v-if="isEventRSVPEnabled"
                class="u-margin-top-5"
                title="Google Analytics"
                subtitle="Add Google Anaytics App ID"
                :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal'"
                @input="updateGoogleAnalyticsEnabled"
                :value="this.googleAnalytics.enabled"
              >
                <ar-field
                  :error-messages="[veeErrors.first('googleAnalytics')]"
                >
                  <ar-input
                    name="googleAnalytics"
                    data-vv-name="googleAnalytics"
                    :value="this.googleAnalytics.id"
                    @input="updateGoogleAnalytics"
                    placeholder="e.g. UA-1234567-89, G-1234567-89"
                    v-validate="'googleAnalyticsId|required'"
                  />
                </ar-field>
              </am2-switch-field-card>
              <am2-switch-field-card
                class="u-margin-top-5"
                title="Custom"
                subtitle="Add custom HTML/JavaScript"
                :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal'"
                @input="handleCustomHTMLEnable"
                :value="customHTML.enabled"
              >
                <div>
                  <ar-field
                    v-show="this.customHTML.enabled"
                  >
                    <ar-field
                      class="u-margin-top-3"
                      label="HTML Head"
                    >
                      <ar-textarea
                        :value="this.customHTML.head"
                        @input="updateHTMLHead"
                        :rows="5"
                      />
                    </ar-field>
                    <ar-field
                      class="u-margin-top-3"
                      label="HTML Body"
                    >
                      <ar-textarea
                        :value="this.customHTML.body"
                        @input="updateHTMLBody"
                        :rows="5"
                      />
                    </ar-field>
                  </ar-field>
                </div>
              </am2-switch-field-card>
            </div>

            <div v-show="currentTab === 'notifications' && type != 'signup-form'">
              <am2-switch-field-card
                class="u-margin-top-5"
                title="Confirmation Email"
                subtitle="Email sent when user register for event"
                :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal'"
                :value="true"
                disabled
              >
                <!-- <ar-textarea
                  v-model="fanConfirmationEmailCopy"
                  :rows="4"
                /> -->
              </am2-switch-field-card>
              <am2-switch-field-card
                v-for="(reminder, reminderIdx) of reminders"
                :key="reminderIdx"
                class="u-margin-top-5"
                title="Reminder"
                :subtitle="generateReminderCopy(reminder)"
                has-edit-link
                :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal'"
                :value="reminder.enabled"
                disabled
              >
                <ar-textarea
                  v-model="reminder.text"
                  :rows="4"
                />
              </am2-switch-field-card>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" :class="[
        'footer',
        $arMediaQuery.window.maxWidth('xs') && 'u-padding-3',
      ]">
        <ar-simple-button
          text="Save"
          @click="handleConfirmClick"
          :style="{ width: '160px' }"
          data-test-id="advanced-settings-modal-save-button"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex';

  export default {
    name: 'AdvancedSettingsModal',
    props: {
      title: {
        type: String,
        default: 'Tags',
      },
      removeAction: {
        type: Function,
        default: null,
      },
      fanOid: {
        type: Number,
        default: null,
      },
      inputStyles: {
        type: Object,
        default: () => {},
      },
      loading: {
        type: Boolean,
        default: false,
      },
      isShow: {
        type: Boolean,
        default: false,
      },
      hasRSVPEvent: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: "event",
        validator: (val) => ['event', 'signup-form'].indexOf(val) > -1,
      }
    },
    data() {
      return {
        // Event RSVP Form-related
        isEventRSVPEnabled: process.env.arEnableEventRSVP,
        newFanTags: [],
        currentTab: 'general',
        showPrivacyPolicy: false,
        showCustomDisclaimer: false,
        showCustomHTMLFields: false,
        selectedLanguage: null,
        customCta: null,
        privacyPolicyCompanyName: null,
        privacyPolicyUrl: null,
        successMessage: null,
        facebookPixel: {
          enabled: false,
          id: null,
        },
        googleTagManager: {
          enabled: false,
          id: null,
        },
        googleAnalytics: {
          enabled: false,
          id: null,
        },
        customHTML: {
          enabled: false,
          head: null,
          body: null,
        },
        subscribeToMessageList: {
          messageListOid: null,
          email: false,
          sms: false,
          facebookMessenger: false,
        },
        reminders: [],
      }
    },

    watch: {
      isShow(val) {
        if (val) {
          if (this.isEventRSVPEnabled) {
            this.currentTab = 'general';
          } else {
            this.currentTab = 'rsvp';
          }
        }
      },
      scratchEvent: {
        handler: function(event) {
          if (this.type == "event" && event && event.presentation.settings) {
            if (event.presentation.settings.assignTags) {
              this.newFanTags = event.presentation.settings.assignTags;
            }
            if (event.presentation.settings.subscribeToMessageList) {
              this.subscribeToMessageList = event.presentation.settings.subscribeToMessageList;
            }
          }
        },
        immediate: true,
      },
      scratchEventRSVPForm: {
        handler: function (eventRSVPForm) {
          if (this.type == "event" && eventRSVPForm && eventRSVPForm.settings) {
            if (eventRSVPForm.settings.defaultLanguage) {
              this.selectedLanguage = eventRSVPForm.settings.defaultLanguage;
            }

            if (eventRSVPForm.settings.customCta) {
              this.customCta = eventRSVPForm.settings.customCta;
            }

            if (eventRSVPForm.settings.privacyPolicy && (!!eventRSVPForm.settings.privacyPolicy.url)) {
              this.showPrivacyPolicy = true;
              this.privacyPolicyCompanyName = eventRSVPForm.settings.privacyPolicy.companyName;
              this.privacyPolicyUrl = eventRSVPForm.settings.privacyPolicy.url;
            }

            if (eventRSVPForm.presentation.successMessage) {
              this.successMessage = eventRSVPForm.presentation.successMessage;
            }

            if (eventRSVPForm.presentation.bodyHtml || eventRSVPForm.presentation.headHtml) {
              this.customHTML = {
                enabled: true,
                head: eventRSVPForm.presentation.headHtml,
                body: eventRSVPForm.presentation.bodyHtml,
              };
            }

            if (eventRSVPForm.settings.pixels) {
              if (eventRSVPForm.settings.pixels.facebookPixel) {
                this.facebookPixel = eventRSVPForm.settings.pixels.facebookPixel;
              }
              if (eventRSVPForm.settings.pixels.googleTagManager) {
                this.googleTagManager = eventRSVPForm.settings.pixels.googleTagManager;
              }
              if (eventRSVPForm.settings.pixels.googleAnalytics) {
                this.googleAnalytics = eventRSVPForm.settings.pixels.googleAnalytics;
              }
            }

            if (eventRSVPForm.settings.reminders) {
              this.reminders = eventRSVPForm.settings.reminders;
            }
          }
        },
        immediate: true,
      },
      scratchSignupForm: {
        handler: function (signupForm) {
          if (this.type == "signup-form" && signupForm && signupForm.settings) {
            if (signupForm.settings.assignTags) {
              this.newFanTags = signupForm.settings.assignTags;
            }

            this.customCta = signupForm.settings.customCta;
            this.selectedLanguage = signupForm.settings.defaultLanguage;

            if (signupForm.settings.privacyPolicy && (!!signupForm.settings.privacyPolicy.url)) {
              this.showPrivacyPolicy = true;
              this.privacyPolicyCompanyName = signupForm.settings.privacyPolicy.companyName;
              this.privacyPolicyUrl = signupForm.settings.privacyPolicy.url;
            }

            if (signupForm.presentation.successMessage) {
              this.successMessage = signupForm.presentation.successMessage;
            }

            if (signupForm.presentation.bodyHtml || signupForm.presentation.headHtml) {
              this.customHTML = {
                enabled: true,
                head: signupForm.presentation.headHtml,
                body: signupForm.presentation.bodyHtml,
              };
            }

            if (signupForm.settings.pixels) {
              this.facebookPixel = signupForm.settings.pixels.facebookPixel;
              this.googleTagManager = signupForm.settings.pixels.googleTagManager;
              this.googleAnalytics = signupForm.settings.pixels.googleAnalytics;
            }

            if (signupForm.settings.reminders) {
              this.reminders = signupForm.settings.reminders;
            }
          }
        },
        immediate: true,
      }
    },

    created() {

      this.$validator.extend('privacyPolicyUrlAlsoRequired', {
        getMessage: field => `You must enter a valid URL`,
        validate: value => {
          if (!value) {
            return false;
          } else {
            return this.privacyPolicyUrl ? true : false;
          }
        },
      });

      // Logic is, if we have url, we check if we have companyName or not
      this.$validator.extend('privacyPolicyCompanyNameAlsoRequired', {
        getMessage: field => `Please enter company name`,
        validate: value => {
          if (!value) {
            return false;
          } else {
            return this.privacyPolicyCompanyName ? true : false;
          }
        },
      });
      },

    computed: {
      ...mapState({
        scratchEvent: state => state.event.scratchEvent,
        scratchEventRSVPForm: state => state.eventRSVPForm.scratchEventRSVPForm,
        messageListInEvent: state => state.event.messageListInEvent,
        scratchAdvancedSettings: state => state.event.scratchAdvancedSettings,
        scratchSignupForm: state => state.signupForm.scratchSignupForm,
      }),

      tabItems() {
        let tabs = [];
        if (this.isEventRSVPEnabled) {
          tabs.push({
            value: 'general',
            title: 'General',
          })
        }
        tabs = tabs.concat([
          {
            value: 'rsvp',
            title: this.type == "signup-form" ? 'Settings' : 'RSVP Page',
          },
          {
            value: 'pixels',
            title: 'Pixels',
          }
        ]);
        if (this.type === 'event') {
          tabs.push({
            value: 'notifications',
            title: 'Notifications',
          });
        }
        return tabs;
      },

      customCtaPlaceholder() {
        if (this.type === 'event') {
          if (this.scratchEvent.location) {
            return 'Join Event '
          } else {
            return 'RSVP';
          }
        } else {
          return 'Subscribe';
        }
      },

      successMessagePlaceholder() {
        if (this.type === 'event') {
          if (this.scratchEvent.location) {
            return `You have successfully registered for the event. You'll receive a confirmation email shortly.`;
          } else {
            return `You have successfully registered for the event. You'll receive an email with instructions to join the online event.`;
          }
        } else {
          return `Your subscription has been confirmed. You've been added to our list and will hear from us soon.`;
        }
      },
    },

    methods: {
      ...mapActions([
      ]),

      ...mapMutations([
        'event/SET_MESSAGE_LIST_IN_ADVANCED_SETTINGS',
        'event/SET_TAGS_IN_ADVANCED_SETTINGS',
        'event/RESET_SCRATCH_ADVANCED_SETTINGS',
        'event/PATCH_SCRATCH_ADVANCED_SETTINGS_TO_EVENT',
        'event/SET_MESSAGE_LIST_IN_EVENT',
        'eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM',
        'signupForm/PUT_SCRATCH_SIGNUP_FORM',
      ]),

      generateReminderCopy(reminder) {
        const unitCopy = reminder.value === 1 ? reminder.unit.substring(0, reminder.unit.length - 1) : reminder.unit;
        return `Email sent ${reminder.value} ${unitCopy} before event starts`;
      },

      handleSavingToEvent() {
        // Assigning to Event for General tab fields
        this['event/SET_TAGS_IN_ADVANCED_SETTINGS'](this.newFanTags);
        this['event/SET_MESSAGE_LIST_IN_ADVANCED_SETTINGS'](this.subscribeToMessageList);
        this['event/PATCH_SCRATCH_ADVANCED_SETTINGS_TO_EVENT']();

        if (this.hasRSVPEvent) {
          this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({
            presentation: {
              successMessage: this.successMessage,
              headHtml: this.customHTML.head,
              bodyHtml: this.customHTML.body,
            },
            settings: {
              reminders: this.reminders,
              customCta: this.customCta,
              defaultLanguage: this.selectedLanguage,
              privacyPolicy: this.showPrivacyPolicy ? {
                url: this.privacyPolicyUrl,
                companyName: this.privacyPolicyCompanyName,
              } : null,
              pixels: {
                facebookPixel: this.facebookPixel,
                googleTagManager: this.googleTagManager,
                googleAnalytics: this.googleAnalytics,
              }
            }
          });
        }
      },

      handleSavingToSignupForm() {
        this['signupForm/PUT_SCRATCH_SIGNUP_FORM']({
          presentation: {
            successMessage: this.successMessage,
            headHtml: this.customHTML.head,
            bodyHtml: this.customHTML.body,
          },
          settings: {
            reminders: this.reminders,
            assignTags: this.newFanTags,
            customCta: this.customCta,
            defaultLanguage: this.selectedLanguage,
            privacyPolicy: this.showPrivacyPolicy ? {
              url: this.privacyPolicyUrl,
              companyName: this.privacyPolicyCompanyName,
            } : null,
            pixels: {
              facebookPixel: this.facebookPixel,
              googleTagManager: this.googleTagManager,
              googleAnalytics: this.googleAnalytics,
            }
          }
        });
      },

      async handleConfirmClick() {
        const isValid = await this.$validator.validateAll();
        if(!isValid) {
          this.$arNotification.push({
            type: 'error',
            message: `There were some errors in saving your details. Please review the messages.`,
          });
          return;
        }

        if (this.type == "event") {
          this.handleSavingToEvent();
        } else if (this.type == "signup-form") {
          this.handleSavingToSignupForm();
        }

        this.$emit('input');
      },
      async handleCloseClick() {
        this['event/RESET_SCRATCH_ADVANCED_SETTINGS'](this.newFanTags);
        this.$emit('close');
      },

      handleMessageListSelectClose() {
        // No value, keep editing
        if (!this.subscribeToMessageList.messageListOid) {
          return;
        }
        this.fieldBeingEdited = null;
      },

      handleMessageListSelect(messageList) {
        this['event/SET_MESSAGE_LIST_IN_EVENT'](messageList);
        this.subscribeToMessageList = {
          messageListOid: messageList.oid,
          email: false,
          sms: false,
          facebookMessenger: false,
        }
      },

      handleMenuSelect(item) {
        this.currentTab = item.value
      },

      toggleShowPrivacyPolicy() {
        this.showPrivacyPolicy = !this.showPrivacyPolicy;
      },

      handlePrivacyPolicyCompanyNameInput(companyName) {
        this.privacyPolicyCompanyName = companyName;
      },

      handlePrivacyPolicyUrlInput(url) {
        this.privacyPolicyUrl = url;
      },

      handleDefaultLanguageInput(defaultLanguage) {
        this.selectedLanguage = defaultLanguage;
      },

      handleCTAButtonNameInput(name) {
        this.customCta = name;
      },

      updateCustomConfirmationMessage(text) {
        this.successMessage = text
      },

      updateCustomConfirmationMessageEnabled(enabled) {
        if (!enabled) {
          this.successMessage = null
        }
      },

      updateFacebookEnabled(enabled) {
        if (!this.facebookPixel) {
          return;
        }

        this.facebookPixel.enabled = enabled;

        if (!enabled) {
          this.facebookPixel.id = null;
        }
      },

      updateFacebook(id) {
        this.facebookPixel.id = id
      },

      updateGoogleTagManagerEnabled(enabled) {
        this.googleTagManager.enabled = enabled;

        if (!enabled) {
          this.googleTagManager.id = null;
        }
      },

      updateGoogleTagManager(id) {
        this.googleTagManager.id = id
      },

      updateGoogleAnalyticsEnabled(enabled) {
        this.googleAnalytics.enabled = enabled;

        if (!enabled) {
          this.googleAnalytics.id = null;
        }
      },

      updateGoogleAnalytics(id) {
        this.googleAnalytics.id = id
      },

      updateCustomHTML(object) {
        this.customHTML = object
      },

      updateHTMLHead(input) {
        this.customHTML.head = input
      },

      updateHTMLBody(input) {
        this.customHTML.body = input
      },

      handleCustomHTMLEnable() {
        this.customHTML.enabled = !this.customHTML.enabled
        if (!this.customHTML.enabled) {
          this.customHTML.head = null
          this.customHTML.body = null
        }
      },
    },
    mounted() {
      if (this.scratchAdvancedSettings) {
        this.newFanTags = this.scratchAdvancedSettings.assignTags;
      }
    },

    beforeDestroy() {
    },
};
</script>

<style lang="scss" scoped>
.custom-field-modal {
  .header {
    font-size: 20px;
  }
  .body {
    .description {
      padding: 26px;
      background: $skyBlueGrey300;
      border-bottom: 1px solid $skyBlueGrey500;
      color: $blueGrey800;
      text-align: center;
    }

    .modal-content {
       padding: 29px 30px;
       .tabs {
          border-bottom: 1px solid $skyBlueGrey500;
          margin-bottom: 30px;
       }
      .checkbox-section {
        border: 1px solid $skyBlueGrey500;
        border-radius: 4px;
        margin-top: 27px;
        .checkbox {
          display: flex;
          align-items: center;
          height: 51px;

          &:not(:last-child){
            border-bottom: 1px solid $skyBlueGrey500;
          }
          padding: 0 15px;

          .label {
            line-height: 1;
            margin-left: 10px;
          }
        }
      }
    }

    .list-group-item {
      display: flex;
      margin-bottom: 10px;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 25px;
  }
}
</style>
